<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { MaskedTextBox } from "@progress/kendo-vue-inputs";
import { DateTime } from "luxon";
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { v4 as uuidv4 } from "uuid";
import type { DataAttribute, DataException, DataObject } from "~/model";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";
import type { TagMetadata } from "~/store/useProject";
import type { AttributeMaskedTextOption } from "~/components/dataForm/attribute-maskedtext-option";
import appStore from "~/store";
import { parseLocalDate } from "~/utils/date";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  dataExceptions: {
    type: Object as PropType<DataException[]>,
    required: false,
    default: () => {
      return [] as DataException[];
    },
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
  maskedTextOptions: {
    type: Object as PropType<AttributeMaskedTextOption>,
    required: false,
    default: () => {
      return {} as AttributeMaskedTextOption;
    },
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const dateValue = computed(() => {
  if (props.attribute?.dateValue) {
    const date = parseLocalDate(props.attribute.dateValue);
    if (props.maskedTextOptions?.isMaskedText) {
      return date.toFormat("MM/dd/yy");
    } else {
      return date.toJSDate();
    }
  } else {
    return undefined;
  }
});

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);

function onMaskInput(event) {
  const dateValue = validateDate(event.value);
  emit("update", {
    ...props.attribute,
    value: dateValue,
    dateValue,
  });
  dataViewHelper.updated();
}

function validateDate(date) {
  const exceptionMessage = `Invalid date format for ${props.tagMetadata?.taxon.label}`;
  const formattedDateTime = DateTime.fromFormat(date, "MM/dd/yy", { zone: "utc" });

  if (formattedDateTime.invalidReason === "unparsable") {
    appStore.workspaceStore.removeException(props.attribute, exceptionMessage);
    return;
  }

  if (formattedDateTime.isValid) {
    appStore.workspaceStore.removeException(props.attribute, exceptionMessage);
    return formattedDateTime.toISO();
  }

  const uuid = uuidv4();
  const newException = {
    message: exceptionMessage,
    severity: "ERROR",
    open: true,
    uuid,
  };
  appStore.workspaceStore.mergeException(props.attribute, newException, props.dataObject);

  return undefined;
}

function onChangeInput(event) {
  const dateValue = event.value !== undefined ? event.value.toISOString() : null;
  emit("update", {
    ...props.attribute,
    value: dateValue,
    dateValue,
  });
  dataViewHelper.updated();
}

const valid = computed(() => {
  return props.dataExceptions?.length === 0;
});

function focus() {
  dataViewHelper.focus();
  emit("focus");
}

const { style, conditionalValidationIcon } = storeToRefs(dataViewHelper);
</script>

<template>
  <div @click="focus">
    <MaskedTextBox
      v-if="maskedTextOptions?.isMaskedText"
      :id="`kdx-attribute-${props.attribute.uuid}`"
      v-model="dateValue"
      :style="style"
      :mask="maskedTextOptions?.maskedText"
      format="MM/dd/yy"
      class="kodexa-input"
      :tabindex="props.tabIndex"
      :disabled="props.dataObject?.documentFamily.locked === true"
      :valid="valid"
      @focus="focus"
      @blur="dataViewHelper.blur()"
      @change="onMaskInput"
    />
    <DatePicker
      v-else
      :id="`kdx-attribute-${props.attribute.uuid}`"
      v-model="dateValue"
      class="kodexa-input"
      format="MM/dd/yy"
      :disabled="props.dataObject?.documentFamily.locked === true"
      :tabindex="props.tabIndex"
      :valid="valid"
      @focus="focus"
      @blur="dataViewHelper.blur()"
      @change="onChangeInput"
    >
      <template #prefix>
        <MaterialDesignIcon
          v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon"
          :color="conditionalValidationIcon.color"
        />
      </template>
    </DatePicker>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-sm text-gray-700 mb-1
}

.kodexa-input {
  @apply w-full appearance-none rounded-md border border-gray-300 h-9
  px-2 py-1 shadow-sm placeholder:text-gray-400 sm:text-sm
}

.kodexa-input:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
}

.kodexa-input:focus {
  @apply border-blue-500 ring-1 ring-blue-500
}
</style>
