<script setup lang="ts">
import {Button, Toolbar} from "@progress/kendo-vue-buttons";
import {Grid, GridNoRecords} from "@progress/kendo-vue-grid";
import type {GridColumnProps} from "@progress/kendo-vue-grid/dist/npm/interfaces/GridColumnProps";
import * as dayjs from "dayjs";

import relativeTime from "dayjs/plugin/relativeTime";
import {storeToRefs} from "pinia";
import {ref} from "vue";

import {filterIcon, arrowRotateCwIcon} from "@progress/kendo-svg-icons";
import type {User} from "~/model";
import {useListPlatformUsers} from "~/api/users/users";
import KodexaUserAvatar from "~/components/kodexa-user-avatar.vue";

dayjs.extend(relativeTime);

const openEditUser = ref(false);
const selectedUser = ref<User>();

// Setup our grid helpers
const gridHelper = createGridHelper("platform-users");
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListPlatformUsers(gridQuery.value);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements ?? 0);
  }
});

const columns: GridColumnProps[] = [
  {
    title: "Image",
    field: "image",
    cell: "image",
    width: "100px",
  },
  {
    title: "First Name",
    field: "firstName",
  },
  {
    title: "Last Name",
    field: "lastName",
  },
  {
    title: "Roles",
    field: "userRoles",
    filterable: false,
    sortable: false,
    cell: "roles",
  },
  {
    title: "Email Address",
    field: "email",
  },
];

watch(openEditUser, (newValue) => {
  if (!newValue) {
    refetch();
  }
});

function openEditUserModal(event) {
  openEditUser.value = true;
  selectedUser.value = event.dataItem;
}
</script>

<template>
  <div class="mx-4 mt-2">
    <Toolbar class="border-0 bg-white">
      <Button :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()" />
      <Button :svg-icon="arrowRotateCwIcon" title="refresh" :togglable="false" @click="refetch()" />
    </Toolbar>
    <Grid
      style="height: calc(100vh - 250px);"
      :data-items="data ? data.content : []"
      :pageable="pageSettings"
      :loader="isLoading"
      :sort="sort"
      :skip="pageSettings.skip as number"
      :total="pageSettings.total as number"
      :page-size="pageSettings.take as number"
      :columns="columns"
      :filterable="showFilter"
      :filter="filter"
      :resizable="true" :sortable="true"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
      @rowclick="openEditUserModal($event)"
    >
      <GridNoRecords>
        <KodexaGridNoRecords
          :error="error" :is-loading="isLoading" :is-error="isError"
          no-records-message="No users found"
        />
      </GridNoRecords>
      <template #roles="{ props }">
        <td>
          <span v-for="role in props.dataItem.security?.roles" class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ role.replace("_"," ") }}</span>
        </td>
      </template>
      <template #image="{ props }">
        <td>
          <KodexaUserAvatar :user="props.dataItem" object-type="user" size="12" @click="openEditUserModal(props)" />
        </td>
      </template>
      <template #lastConnectedTemplate="{ props }">
        <td>
          <KodexaDateTimeView v-if="props.dataItem.lastConnected" :date="props.dataItem.lastConnected" />
        </td>
      </template>
    </Grid>
  </div>
    <EditUser v-if="selectedUser" :key="selectedUser.id" v-model="openEditUser" :user="selectedUser" />

</template>

<style scoped>
</style>
