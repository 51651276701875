<script setup lang="ts">
import { getDocumentFamilySteps } from "~/api/document-families/document-families";
import appStore from "~/store";
import type { ProcessingStep } from "~/model";
import { log } from "~/utils/logger";

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
  kioskMode: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const steps = ref([] as ProcessingStep[]);

const documentFamilyId = ref("");

async function fetchSteps() {
  try {
    const view = appStore.workspaceStore.views.find(view => view.id === props.viewId);
    if (view) {
      const useDocumentView = createDocumentViewerStore(view.documentFamilyId);
      documentFamilyId.value = view.documentFamilyId;
      const kddb = useDocumentView?.getKddbDocument();
      if (kddb) {
        log.info("Loading from KDDB");
        steps.value = await kddb.getSteps();
      } else {
        log.info("Loading from Document Family");
        steps.value = await getDocumentFamilySteps(view.documentFamilyId);
      }
    } else {
      console.warn(`View with ID ${props.viewId} not found.`);
    }
  } catch (error) {
    console.error("Error fetching steps:", error);
  }
}

onMounted(() => {
  fetchSteps();
});
</script>

<template>
  <div style="height: calc(100vh - 13rem); overflow-y: scroll; width: 100%; position: relative">
    <KodexaProcessingGraphFlow v-if="steps.length > 0" :key="documentFamilyId" :steps="steps" :document-family-id="documentFamilyId" />
  </div>
</template>

<style scoped>
</style>
