<script setup lang="ts">
import { Editor, } from "@progress/kendo-vue-editor";
import { defaultMarkdownParser, defaultMarkdownSerializer } from "prosemirror-markdown";

const props = defineProps({
  modelValue: {
    type: String,
    required: true,
  },
  tools: {
    type: Array,
    required: false,
    default: () => {
      return ["Bold", "Italic", "Link", "Unlink", "InsertImage", "FormatBlock", "Undo", "Redo", "OrderedList", "UnorderedList"];
    },
  },
});

const emit = defineEmits(["update:modelValue"]);

const localValue = computed(() => {
  return defaultMarkdownParser.parse(props.modelValue);
});

function onChange(event) {
  const content = defaultMarkdownSerializer.serialize(event.value);
  emit("update:modelValue", content);
}
</script>

<template>
  <Editor
    :value="localValue"
    :tools="tools"
    :content-style="{
      height: '325px',
    }"
    @change="onChange"
  />
</template>

<style scoped>

</style>
