import {BaseErrorListener, CharStream, CommonTokenStream} from "antlr4ng";
import Decimal from "decimal.js";
import {add, isValid, parse} from "date-fns";
import {KodexaFormulaLexer} from "./resources/KodexaFormulaLexer";
import type {
  AddContext,
  ArgumentListContext,
  ArrayAccessContext,
  ArrayLiteralContext,
  AttributePropertyContext,
  AttributeReferenceContext,
  DivContext,
  EqualContext,
  FormulaContext,
  FunctionContext,
  GreaterThanContext,
  GreaterThanEqualContext,
  LessThanContext,
  LessThanEqualContext,
  MulContext,
  NegativeNumberContext,
  NotEqualContext,
  PositiveNumberContext,
  PositiveOrNegativeNumberContext,
  StringLiteralContext,
  SubContext,
} from "./resources/KodexaFormulaParser";
import {KodexaFormulaParser,} from "./resources/KodexaFormulaParser";
import {KodexaFormulaVisitor} from "./resources/KodexaFormulaVisitor";
import type {DataAttribute, DataObject, DocumentFamily, Taxon, Taxonomy} from "~/model";
import {getAttributeValueByTaxonType} from "~/components/util/attribute-utils";
import {log} from "~/utils/logger";
import {toZonedTime} from "date-fns-tz";

class FormulaEvaluationContext {
  dataObject: DataObject | undefined;
  taxonomy: Taxonomy | undefined;
  taxon: Taxon | undefined;
  formulaService: KodexaFormulaService | undefined;
  dataObjects: DataObject[] | undefined;
}

export class RelativeAttribute {
  id: string | undefined;
  label: string | undefined;
  color: string | undefined;
  externalName: string | undefined;
  children: RelativeAttribute[] | undefined;
  group: boolean | undefined;
}

function arrayNormalize(arr: any[]) {
  if (!Array.isArray(arr)) {
    arr = [arr];
  }

  // Flatten the array
  arr = arr.flat(Number.POSITIVE_INFINITY);
  return arr;
}

export const supportedFunctions = {
  concat: (args: any[]) => {
    return args.map((arg) => {
      return (`${arg}`);
    }).join("");
  },
  isnull: (args: any[]) => {
    return args[0] === null || args[0] === undefined || args[0] === "null" || args[0] === "undefined";
  },
  sum: (args: any[]) => {
    return new Decimal(args.reduce((acc, val) => {
      if (Number.isNaN(val)) {
        return acc;
      } else {
        if (val) {
          return acc.add(val);
        } else {
          return acc;
        }
      }
    }, new Decimal(0)));
  },
  average: (args: any[]) => {
    if (args.length === 0) {
      return Number.NaN;
    }
    return new Decimal(callFunction("sum", args)).dividedBy(new Decimal(args.length));
  },
  count: (args: any[]) => {
    return args.length;
  },
  max: (args: any[]) => {
    if (args.length === 0) {
      return 0;
    }
    return Math.max(...args);
  },
  min: (args: any[]) => {
    if (args.length === 0) {
      return 0;
    }
    return Math.min(...args);
  },
  if: (args: any[]) => {
    return args[0] ? args[1] : args[2];
  },
  lowercase: (args: any[]) => {
    return args.map((arg) => {
      return (`${arg}`).toLowerCase();
    });
  },
  uppercase: (args: any[]) => {
    return args.map((arg) => {
      return (`${arg}`).toUpperCase();
    });
  },
  datemath: (args: [string | Date, string, number]) => {
    const [originalDate, temporalUnit, number] = args;

    // Parse the date if it's a string, or use it directly if it's already a Date object
    const date = originalDate instanceof Date ? originalDate : parse(originalDate, "yyyy-MM-dd", new Date());

    if (!isValid(date)) {
      throw new Error("Invalid date format");
    }

    // Convert to UTC to avoid timezone issues
    const utcDate = toZonedTime(date, "UTC");
    const offset = Number(number);
    // Ensure time is set to 00:00:00
    const offsetDate = new Date(Date.UTC(utcDate.getUTCFullYear(), utcDate.getUTCMonth(), utcDate.getUTCDate()));

    let newDate;
    switch (temporalUnit) {
      case "days":
        newDate = add(offsetDate, { days: offset });
        break;
      case "weeks":
        newDate = add(offsetDate, { weeks: offset });
        break;
      case "months":
        newDate = add(offsetDate, { months: offset });
        break;
      case "years":
        newDate = add(offsetDate, { years: offset });
        break;
      default:
        throw new Error("Invalid temporal unit");
    }

    // Convert back to local time zone if needed, here kept in UTC
    return new Date(Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate()));
  },
  stddeviation: (args: any[]) => {
    if (args.length === 0) {
      return Number.NaN;
    }

    const mean = callFunction("average", args);
    const squaredDifferences = args.map((arg) => {
      return new Decimal(arg).minus(mean).pow(2);
    });
    const sum = callFunction("sum", squaredDifferences);
    return sum.dividedBy(new Decimal(args.length - 1)).sqrt();
  },
  ceil: (args: any[]) => {
    return new Decimal(callFunction("sum", args[0])).ceil();
  },
  floor: (args: any[]) => {
    return new Decimal(callFunction("sum", args[0])).floor();
  },
  round: (args: any[]) => {
    return new Decimal(callFunction("sum", args[0])).round();
  },
  abs: (args: any[]) => {
    return new Decimal(callFunction("sum", args[0])).abs();
  },
  trim: (args: any[]) => {
    return args.map((arg) => {
      return (`${arg}`).trim();
    });
  },
  substring: (args: any[]) => {
    return (`${args[0]}`).substring(args[1], args[2]);
  },
  length: (args: any[]) => {
    return (`${args[0]}`).length;
  },
  contains: (args: any[]) => {
    return (`${args[0]}`).includes(args[1]);
  },
  startswith: (args: any[]) => {
    return (`${args[0]}`).startsWith(args[1]);
  },
  endswith: (args: any[]) => {
    return (`${args[0]}`).endsWith(args[1]);
  },
  replace: (args: any[]) => {
    return (`${args[0]}`).replace(args[1], args[2]);
  },
  split: (args: any[]) => {
    return (`${args[0]}`).split(args[1]);
  },
  sumifs: (args: any[]) => {
    // This is an implementation of the sumifs function from Excel
    // The first argument is the range to sum
    // The second argument is the criteria range

    const range = args[0];
    const criteriaRange = args[1];
    const criteria = args[3];
    let sum = 0;
    for (let i = 0; i < criteriaRange.length; i++) {
      let match = true;
      for (let j = 0; j < criteria.length; j++) {
        if (criteriaRange[i] !== criteria[j]) {
          match = false;
          break;
        }
      }
      if (match) {
        sum += range[i];
      }
    }
    return sum;
  },
  countifs: (args: any[]) => {
    // This is an implementation of the countifs function from Excel
    // The first argument is the range to count
    // The second argument is the criteria range

    const criteriaRange = args[1];
    const criteria = args.slice(2);
    for (let i = 0; i < criteriaRange.length; i++) {
      let match = true;
      for (let j = 0; j < criteria.length; j++) {
        if (criteriaRange[i] !== criteria[j]) {
          match = false;
          break;
        }
      }
    }
  },
};

function callFunction(name: string, args: any[]) {
  if (!supportedFunctions[name.toLowerCase()]) {
    throw new SyntaxError(`Function ${name} could not be found`);
  }
  return supportedFunctions[name.toLowerCase()](arrayNormalize(args));
}

interface DebugInfo {
  rule: string;
  function: string;
  text: string;
  taxon: Taxon | undefined;
  result: any;
}

export class DebugInfoNode {
  debugInfo: DebugInfo;
  children: DebugInfoNode[];

  constructor(debugInfo: DebugInfo) {
    this.debugInfo = debugInfo;
    this.children = [];
  }
}

export class KodexasFormulaEvaluator extends KodexaFormulaVisitor<any> {
  private formulaEvaluationContext: FormulaEvaluationContext;
  private debugInfoTree: DebugInfoNode | null = null;
  private debugInfoStack: DebugInfoNode[] = [];
  private currentAttribute: DataAttribute | undefined;

  constructor(formulaEvaluationContext: FormulaEvaluationContext) {
    super();
    this.formulaEvaluationContext = formulaEvaluationContext;
  }

  private captureDebugInfo(ctx: any, result: any, taxon: Taxon | undefined = undefined) {
    const debugInfo: DebugInfo = {
      rule: ctx.constructor.name,
      taxon: {
        name: taxon?.name,
        path: taxon?.path,
        label: taxon?.label,
      },
      text: ctx.getText(),
      result,
    };
    const node = new DebugInfoNode(debugInfo);

    if (this.debugInfoStack.length === 0) {
      this.debugInfoTree = node;
    } else {
      const parent = this.debugInfoStack[this.debugInfoStack.length - 1];
      parent.children.push(node);
    }

    this.debugInfoStack.push(node);

    return node;
  }

  private finishCurrentDebugNode() {
    this.debugInfoStack.pop();
  }

  getDebugInfoTree(): DebugInfoNode | null {
    return this.debugInfoTree;
  }

  resolveValue(value: any) {
    // If we have a list of values then we want to turn them into decimal and sum
    // them
    if (Array.isArray(value)) {
      return callFunction("sum", value);
    } else {
      // if the value is a number make it a decimal
      if (typeof value === "number") {
        return new Decimal(value);
      }
      return value;
    }
  }

  visitAdd = (ctx: AddContext) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const left = this.resolveValue(this.visit(ctx.expression(0)));
    const right = this.resolveValue(this.visit(ctx.expression(1)));

    if (left instanceof Decimal && right instanceof Decimal) {
      const result = left.add(right);
      debugInfo.debugInfo.result = result;
      this.finishCurrentDebugNode();
      return result;
    }
    const result = left + right;
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitSub = (ctx: SubContext) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const left = this.resolveValue(this.visit(ctx.expression(0))); // Fallback to 0 if the result is falsy
    const right = this.resolveValue(this.visit(ctx.expression(1))); // Fallback to 0 if the result is falsy
    const result = left - right;
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitDiv = (ctx: DivContext) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const left = this.visit(ctx.expression(0)) ?? 0; // Fallback to 0 if the result is falsy
    const right = this.visit(ctx.expression(1)) ?? 0; // Fallback to 0 if the result is falsy
    const result = left / right;
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitFormula = (ctx: FormulaContext) => {
    return this.visit(ctx.expression());
  };

  visitStringLiteral = (ctx: StringLiteralContext) => {
    const result = ctx.STRING().getText().substring(1, ctx.STRING().getText().length - 1);
    this.captureDebugInfo(ctx, result);
    this.finishCurrentDebugNode();
    return result;
  };

  visitArgumentList = (ctx: ArgumentListContext) => {
    return ctx.expression().map(exp => this.visit(exp));
  };

  visitArrayAccess = (ctx: ArrayAccessContext) => {
    const debugInfo = this.captureDebugInfo(ctx, null);
    const array = this.visit(ctx.expression(0));
    const index = this.visit(ctx.expression(1));
    const result = array[index];
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitArrayLiteral = (ctx: ArrayLiteralContext) => {
    return ctx.argumentList();
  };

  visitPositiveNumber = (ctx: PositiveNumberContext) => {
    const result = new Decimal(ctx.NUMBER().getText());
    this.captureDebugInfo(ctx, result);
    this.finishCurrentDebugNode();
    return result;
  };

  visitPositiveOrNegativeNumber = (ctx: PositiveOrNegativeNumberContext) => {
    const result = new Decimal(ctx.number().getText());
    this.captureDebugInfo(ctx, result);
    this.finishCurrentDebugNode();
    return result;
  };

  visitNegativeNumber = (ctx: NegativeNumberContext) => {
    const result = new Decimal(ctx.NUMBER().getText()).neg();
    this.captureDebugInfo(ctx, result);
    this.finishCurrentDebugNode();
    return result;
  };

  visitMul = (ctx: MulContext) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const left = this.resolveValue(this.visit(ctx.expression(0))); // Fallback to 0 if the result is falsy
    const right = this.resolveValue(this.visit(ctx.expression(1))); // Fallback to 0 if the result is falsy
    if (left instanceof Decimal && right instanceof Decimal) {
      const result = left.mul(right);
      this.captureDebugInfo(ctx, result);
      this.finishCurrentDebugNode();
      return result;
    }
    const result = left * right;
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitLessThan = (ctx: LessThanContext) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const left = this.resolveValue(this.visit(ctx.expression(0))); // Fallback to 0 if the result is falsy
    const right = this.resolveValue(this.visit(ctx.expression(1))); // Fallback to 0 if the result is falsy
    if (left instanceof Decimal && right instanceof Decimal) {
      const result = left.lt(right);
      this.captureDebugInfo(ctx, result);
      this.finishCurrentDebugNode();
      return result;
    }
    const result = left < right;
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitLessThanEqual = (ctx: LessThanEqualContext) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const left = this.resolveValue(this.visit(ctx.expression(0))); // Fallback to 0 if the result is falsy
    const right = this.resolveValue(this.visit(ctx.expression(1))); // Fallback to 0 if the result is falsy
    if (left instanceof Decimal && right instanceof Decimal) {
      const result = left.lte(right);
      this.captureDebugInfo(ctx, result);
      this.finishCurrentDebugNode();
      return result;
    }
    const result = left <= right;
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitGreaterThan = (ctx: GreaterThanContext) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const left = this.visit(ctx.expression(0)) ?? 0; // Fallback to 0 if the result is falsy
    const right = this.visit(ctx.expression(1)) ?? 0; // Fallback to 0 if the result is falsy
    // If left and right are both Decimal use the .eq
    if (left instanceof Decimal && right instanceof Decimal) {
      const result = left.gt(right);
      debugInfo.debugInfo.result = result;
      this.finishCurrentDebugNode();
      return result;
    } else {
      const result = left > right;
      debugInfo.debugInfo.result = result;
      this.finishCurrentDebugNode();
      return result;
    }
  };

  visitGreaterThanEqual = (ctx: GreaterThanEqualContext) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const left = this.resolveValue(this.visit(ctx.expression(0))); // Fallback to 0 if the result is falsy
    const right = this.resolveValue(this.visit(ctx.expression(1))); // Fallback to 0 if the result is falsy
    // If left and right are both Decimal use the .eq
    if (left instanceof Decimal && right instanceof Decimal) {
      const result = left.gte(right);
      debugInfo.debugInfo.result = result;
      this.finishCurrentDebugNode();
      return result;
    } else {
      const result = left >= right;
      debugInfo.debugInfo.result = result;
      this.finishCurrentDebugNode();
      return result;
    }
  };

  visitEqual = (ctx: EqualContext) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const left = this.resolveValue(this.visit(ctx.expression(0))); // Fallback to 0 if the result is falsy
    const right = this.resolveValue(this.visit(ctx.expression(1)));
    // If left and right are both Decimal use the .eq
    if (left instanceof Decimal && right instanceof Decimal) {
      const result = left.eq(right);
      debugInfo.debugInfo.result = result;
      this.finishCurrentDebugNode();
      return result;
    } else {
      const result = (left === right);
      debugInfo.debugInfo.result = result;
      this.finishCurrentDebugNode();
      return result;
    }
  };

  visitUnaryMinus = (ctx: any) => {
    // Visit the expression directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const value = this.resolveValue(this.visit(ctx.expression())); // Fallback to 0 if the result is falsy
    const result = -value;
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitLogicalNot = (ctx: any) => {
    // Visit the expression directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const value = this.resolveValue(this.visit(ctx.expression())); // Fallback to 0 if the result is falsy
    const result = !value;
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitTrueLiteral = (ctx: any) => {
    const result = true;
    this.captureDebugInfo(ctx, result);
    this.finishCurrentDebugNode();
    return result;
  };

  visitFalseLiteral = (ctx: any) => {
    const result = false;
    this.captureDebugInfo(ctx, result);
    this.finishCurrentDebugNode();
    return result;
  };

  toBool = (a: any) => {
    return Boolean(a).valueOf();
  };

  visitLogicalAnd = (ctx: any) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const left = this.resolveValue(this.visit(ctx.expression(0))); // Fallback to 0 if the result is falsy
    const right = this.resolveValue(this.visit(ctx.expression(1))); // Fallback to 0 if the result is falsy
    const result = this.toBool(left) && this.toBool(right);
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitLogicalOr = (ctx: any) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const result = this.toBool(this.visit(ctx.expression(0))) || this.toBool(this.visit(ctx.expression(1)));
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitNotEqual = (ctx: NotEqualContext) => {
    // Visit the left and right expressions directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const left = this.visit(ctx.expression(0));
    const right = this.visit(ctx.expression(1));

    // If left and right are both Decimal use the .eq
    if (left instanceof Decimal && right instanceof Decimal) {
      const result = !left.eq(right);
      debugInfo.debugInfo.result = result;
      this.finishCurrentDebugNode();
      return result;
    } else {
      const result = this.visit(ctx.expression(0)) !== this.visit(ctx.expression(1));
      debugInfo.debugInfo.result = result;
      this.finishCurrentDebugNode();
      return result;
    }
  };

  visitParens = (ctx: any) => {
    // Visit the expression directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const result = this.visit(ctx.expression());
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitNot = (ctx: any) => {
    // Visit the expression directly
    const debugInfo = this.captureDebugInfo(ctx, null);
    const result = !this.toBool(this.visit(ctx.expression()));
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitFunction = (ctx: FunctionContext) => {
    // Visit the function name
    const functionName = ctx.functionCall().FUNCTION_NAME().getText();
    // Visit the argument list
    const args = [] as any[];
    const debugInfo = this.captureDebugInfo(ctx, null);
    debugInfo.debugInfo.function = functionName;

    if (ctx.functionCall().argumentList()?.children) {
      for (const argTree of ctx.functionCall().argumentList()?.children || []) {
        const argVal = this.visit(argTree);
        if (argVal !== null) {
          args.push(argVal);
        }
      }
    }

    const result = callFunction(functionName, args);
    debugInfo.debugInfo.result = result;
    this.finishCurrentDebugNode();
    return result;
  };

  visitAttributeProperty = (ctx: AttributePropertyContext) => {
    const propertyName = ctx.getText().substring(1);
    const attribute = this.currentAttribute;

    const getNestedProperty = (obj: any, path: string) => {
      return path.split(".").reduce((acc, part) => acc && acc[part], obj);
    };

    if (attribute && attribute.dataFeatures) {
      const result = getNestedProperty(attribute.dataFeatures, propertyName);
      this.captureDebugInfo(ctx, result);
      this.finishCurrentDebugNode();
      return result;
    } else {
      this.captureDebugInfo(ctx, undefined);
      this.finishCurrentDebugNode();
      return undefined;
    }
  };

  visitAttributeReference = (ctx: AttributeReferenceContext) => {
    const attributePath = ctx.IDENTIFIER().getText().substring(1, ctx.IDENTIFIER().getText().length - 1);
    const relatedExpression = ctx.expression();

    if (!this.formulaEvaluationContext.formulaService) {
      throw new Error("No formula service in context");
    }

    const resolveAttributePath = (parts: string[], dataObject: any, relatedExpression: any) => {
      if (!dataObject) {
        throw new Error("Data object is null or undefined");
      }

      const dataObjectExternalName = this.formulaEvaluationContext.formulaService?.pathToExternalNameMap.get(dataObject.path as string);
      const pathPart = parts.shift(); // Remove the first element from parts

      if (parts.length === 0) {
        // Base case: no more parts to process
        const attributeTaxonPath = this.formulaEvaluationContext.formulaService?.externalNameMap.get(`${dataObjectExternalName}/${pathPart}`);
        const attributeTaxon = this.formulaEvaluationContext.formulaService?.pathToTaxonMap.get(attributeTaxonPath as string);

        log.info(`Processing ${dataObjectExternalName}/${pathPart}`);
        if (attributeTaxon && attributeTaxon.valuePath === "FORMULA") {
          log.info(`Evaluating formula ${dataObjectExternalName}/${pathPart}`);
          const result = this.formulaEvaluationContext.formulaService?.evaluateFormula(attributeTaxon.semanticDefinition as string, attributeTaxon, dataObject, this.formulaEvaluationContext.dataObjects);
          if (result) {
            this.captureDebugInfo(ctx, result.result, attributeTaxon);
            this.finishCurrentDebugNode();
          }
          return result;
        } else {
          // We need to determine if we have an expression on the attribute reference, if we do we need to apply
          // it
          if (relatedExpression) {
            // We load up the current attributes, so that the expression will be able to reference them
            // then we will pick up the attributes after the expression has been evaluated
            const finalAttributes = [] as DataAttribute[];
            const attributes = dataObject.attributes?.filter(attr => attr.tag === attributeTaxon?.name) || [];
            log.info("Applying expression to attribute reference to determine if it is still valid");
            for (const attr of attributes) {
              this.currentAttribute = attr;
              const result = this.visit(relatedExpression);
              log.info(`Expression result for ${dataObjectExternalName}/${pathPart} is ${result}`);
              if (result) {
                finalAttributes.push(this.currentAttribute);
              }
            }

            const result = finalAttributes.map(attr => getAttributeValueByTaxonType(attr));
            this.captureDebugInfo(ctx, finalAttributes, attributeTaxon);
            this.finishCurrentDebugNode();
            return { result, debugInfo: [] };
          } else {
            const attributes = dataObject.attributes?.filter(attr => attr.tag === attributeTaxon?.name) || [];
            const result = attributes.map(attr => getAttributeValueByTaxonType(attr));
            this.captureDebugInfo(ctx, attributes, attributeTaxon);
            this.finishCurrentDebugNode();
            return { result, debugInfo: [] };
          }
        }
      } else {
        // Recursive case: more parts to process
        log.info(`Processing ${dataObjectExternalName}/${pathPart}`);
        if (this.formulaEvaluationContext.formulaService?.externalNameMap.has(`${dataObjectExternalName}/${pathPart}`)) {
          const taxonPath = this.formulaEvaluationContext.formulaService.externalNameMap.get(`${dataObjectExternalName}/${pathPart}`);
          const taxon = this.formulaEvaluationContext.formulaService.pathToTaxonMap.get(taxonPath as string);

          if (!taxon) {
            throw new Error(`Taxon ${taxonPath} could not be found`);
          }

          if (taxon.valuePath === "FORMULA") {
            const result = this.formulaEvaluationContext.formulaService.evaluateFormula(taxon.semanticDefinition as string, taxon, dataObject, this.formulaEvaluationContext.dataObjects);
            this.captureDebugInfo(ctx, result, taxon);
            this.finishCurrentDebugNode();
            return result;
          } else {
            // Find the next child data object
            const dataObjectPath = this.formulaEvaluationContext.formulaService?.externalNameMap.get(`${dataObjectExternalName}/${pathPart}`);
            log.info(`Checking ${this.formulaEvaluationContext.dataObjects?.length} data objects for children of ${dataObjectExternalName}/${pathPart}`);
            const childDataObjects = this.formulaEvaluationContext.dataObjects?.filter(child => child.path === dataObjectPath && child.parentId === dataObject.id);

            const results: any[] = [];
            log.info(`Found ${childDataObjects?.length} children for path ${dataObjectExternalName}/${pathPart} (${dataObjectPath})`);
            for (const childDataObject of (childDataObjects || [])) {
              const childResult = resolveAttributePath([...parts], childDataObject, relatedExpression); // Pass a copy of parts
              if (childResult) {
                results.push(childResult);
              }
            }
            // We need to combine all these results into a single result and add all the debug info
            const result = results.map(r => r.result);
            return { result };
          }
        } else {
          throw new Error(`Attribute ${dataObjectExternalName}/${pathPart} could not be found`);
        }
      }
    };

    const parts = attributePath.split("/");
    const results = resolveAttributePath(parts, this.formulaEvaluationContext.dataObject, relatedExpression);
    log.info(`Attribute reference ${attributePath} resolved to ${results?.result}`);
    if (results?.result?.length === 1) {
      return results.result[0];
    } else {
      return results?.result;
    }
  };
}

class FormulaError {
  public message: string;
  public startIndex: number;
  public stopIndex: number;
  public token: any;
  public line: number;
  public charPositionInLine: number;

  constructor(message: string, startIndex: number, stopIndex: number, token: any, line: number, charPositionInLine: number) {
    this.message = message;
    this.startIndex = startIndex;
    this.stopIndex = stopIndex;
    this.token = token;
    this.line = line;
    this.charPositionInLine = charPositionInLine;
  }
}

export class ParsingErrorListener extends BaseErrorListener {
  public errors: any[] = [];

  syntaxError(recognizer, offendingSymbol, line, column, msg, e) {
    this.errors.push(new FormulaError(msg, offendingSymbol.startIndex, offendingSymbol.stopIndex, offendingSymbol.text, line, column));
  }
}

export class FormulaSyntaxError implements Error {
  message: string;
  name: string;
  errors: any[];

  constructor(syntaxErrorInFormula: string, parserErrors: any[]) {
    this.message = syntaxErrorInFormula;
    this.name = "FormulaSyntaxError";
    this.errors = parserErrors;
  }
}

export class KodexaFormulaService {
  taxonomy: Taxonomy;
  dataObjects: DataObject | undefined;
  documentFamily: DocumentFamily | undefined;
  parsedFormulaCache = new Map<string, any>();
  externalNameMap: Map<string, string>;
  pathToTaxonMap: Map<string, Taxon>;
  pathToExternalNameMap: Map<string, string>;

  constructor(taxonomy: Taxonomy) {
    this.taxonomy = taxonomy;

    // This is a map of external names to taxon paths
    this.externalNameMap = new Map<string, string>();
    this.pathToTaxonMap = new Map<string, Taxon>();
    this.pathToExternalNameMap = new Map<string, string>();
    if (taxonomy.taxons) {
      this.buildExternalNameMap(taxonomy.taxons);
    }
  }

  buildExternalNameMap(taxons: Taxon[], parentPath: string | undefined = undefined) {
    for (const taxon of taxons) {
      this.pathToTaxonMap.set(taxon.path, taxon);
      const externalNamePath = parentPath ? `${parentPath}/${taxon.externalName}` : taxon.externalName;
      this.externalNameMap.set(externalNamePath, taxon.path);
      this.pathToExternalNameMap.set(taxon.path, externalNamePath);
      if (taxon.children && taxon.group) {
        this.buildExternalNameMap(taxon.children, externalNamePath);
      }
    }
  }

  parseFormula(input: string) {
    if (!input || input.trim().length === 0) {
      return null;
    }

    if (this.parsedFormulaCache.has(input)) {
      return this.parsedFormulaCache.get(input);
    }

    const inputStream = CharStream.fromString(input);
    const lexer = new KodexaFormulaLexer(inputStream);
    const tokenStream = new CommonTokenStream(lexer);
    const parser = new KodexaFormulaParser(tokenStream);

    const errorListener = new ParsingErrorListener();
    parser.addErrorListener(errorListener);
    const result = parser.formula();

    if (errorListener.errors.length > 0) {
      throw new FormulaSyntaxError(`Syntax error in formula "${input}"`, errorListener.errors);
    } else {
      this.parsedFormulaCache.set(input, result);
      return result;
    }
  }

  evaluateFormula(input: string, baseTaxon: Taxon | undefined = undefined, dataObject: DataObject | undefined = undefined, dataObjects: DataObject[] | undefined = undefined) {
    try {
      const tree = this.parseFormula(input);

      if (!tree) {
        return { result: undefined, debugInfo: [] };
      }

      const formulaEvaluationContext = new FormulaEvaluationContext();
      formulaEvaluationContext.dataObject = dataObject;
      formulaEvaluationContext.taxonomy = this.taxonomy;
      formulaEvaluationContext.taxon = baseTaxon;
      formulaEvaluationContext.formulaService = this;
      formulaEvaluationContext.dataObjects = dataObjects;

      log.info(`Created formula evaluation context with ${dataObjects?.length} data objects`);
      const evaluator = new KodexasFormulaEvaluator(formulaEvaluationContext);
      const result = evaluator.visit(tree);

      if (result instanceof Decimal) {
        return { result: result.toNumber(), debugInfo: evaluator.getDebugInfoTree() };
      }

      return { result, debugInfo: evaluator.getDebugInfoTree() };
    } catch (e) {
      console.trace(e);
      log.error(`Error evaluating formula ${input}: ${e}`);
      return { result: undefined, debugInfo: [] };
    }
  }

  availableAttributes() {
    return Array.from(this.externalNameMap.keys());
  }

  getParentTaxon(taxon: Taxon, taxons: Taxon[], parent: Taxon | undefined = undefined): Taxon | undefined {
    for (const child of taxons) {
      if (child.path === taxon.path) {
        return parent;
      } else {
        const result = this.getParentTaxon(taxon, child.children || [], child);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  }

  buildExternalNameMapForTaxon(taxons: Taxon[], parentPath: string | undefined = undefined) {
    const names = [] as string[];
    for (const taxon of taxons) {
      const externalNamePath = parentPath ? `${parentPath}/${taxon.externalName}` : taxon.externalName;
      names.push(`{${externalNamePath}}`);
      if (taxon.children && taxon.group) {
        names.push(...this.buildExternalNameMapForTaxon(taxon.children, externalNamePath));
      }
    }
    return names;
  }

  getRelativeAttributes(taxon: Taxon) {
    const parent = this.getParentTaxon(taxon, this.taxonomy.taxons);
    if (parent?.children) {
      return this.buildExternalNameMapForTaxon(parent.children);
    }
    return [];
  }

  getRelativeAttributesAsTree(taxon: Taxon, parentExternalName: string | undefined = undefined): RelativeAttribute[] {
    const relativeAttributes = [] as RelativeAttribute[];
    if (!parentExternalName) {
      const parent = this.getParentTaxon(taxon, this.taxonomy.taxons);
      parent?.children?.forEach((child) => {
        // We want to walk this tree and build a tree of the relative attributes
        // which have their external name in them
        if (child.group) {
          const relativeAttribute = {
            id: child.id,
            label: child.label,
            color: child.color,
            externalName: parentExternalName ? `${parentExternalName}/${child.externalName}` : child.externalName,
            children: [] as RelativeAttribute[],
            expanded: true,
            group: true,
          } as RelativeAttribute;

          child.children?.map((child) => {
            const relativeChilds = this.getRelativeAttributesAsTree(child, relativeAttribute.externalName);
            relativeAttribute.children.push(...relativeChilds);
          });
          relativeAttributes.push(relativeAttribute);
        } else {
          const relativeAttribute = {
            id: child.id,
            label: child.label,
            color: child.color,
            externalName: parentExternalName ? `${parentExternalName}/${child.externalName}` : child.externalName,
            children: [] as RelativeAttribute[],
            expanded: false,
            group: false,
          } as RelativeAttribute;
          relativeAttributes.push(relativeAttribute);
        }
      });
    } else {
      const relativeAttribute = {
        id: taxon.id,
        label: taxon.label,
        color: taxon.color,
        expanded: false,
        externalName: parentExternalName ? `${parentExternalName}/${taxon.externalName}` : taxon.externalName,
        children: [],
        group: false,
      } as RelativeAttribute;

      taxon.children?.map((child) => {
        const relativeChilds = this.getRelativeAttributesAsTree(child, relativeAttribute.externalName, taxon.externalName);
        relativeAttribute.children.push(...relativeChilds);
      });
      relativeAttributes.push(relativeAttribute);
    }

    return relativeAttributes;
  }
}
