<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { Execution } from "~/model";
import appStore from "~/store";

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
  kioskMode: {
    type: Boolean,
    default: false,
    required: false,
  },
});

defineEmits(["close"]);

const useExecutionStore = createExecutionStore(props.viewId);
const { orderedExecutions, executionView, completed } = storeToRefs(useExecutionStore);

const currentState = computed(() => {
  if (orderedExecutions.value.filter((execution: Execution) => execution.status === "RUNNING").length > 0) {
    return "Running";
  } else if (orderedExecutions.value.filter((execution: Execution) => execution.status === "PENDING").length > 0 || !completed.value) {
    return "Waiting to start";
  } else {
    return "Completed";
  }
});

function close() {
  appStore.workspaceStore.removeViewById(props.viewId);
}

function cancel() {
  for (const execution of orderedExecutions.value) {
    appStore.projectStore.cancelAssistantExecution(execution);
  }
}
</script>

<template>
  <div style="height: calc(100vh - 165px)">
    <div class="p-5 lg:flex">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
          {{ currentState }} {{ executionView.title }}
        </h2>
        <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div class="mt-2 flex items-center text-sm text-gray-500">
            {{ orderedExecutions.length }} executions
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500" />
          <div class="mt-2 flex items-center text-sm text-gray-500" />
          <div class="mt-2 flex items-center text-sm text-gray-500" />
        </div>
      </div>
      <div class="mt-5  lg:ml-4 lg:mt-0">
        <KodexaButton v-if="!completed" id="cancelExecution" type="danger" icon="cancel" @click="cancel">
          Cancel
        </KodexaButton>
        <KodexaButton v-if="completed" id="closeExecutionView" type="secondary" icon="close" @click="close">
          Close
        </KodexaButton>
      </div>
    </div>
    <KodexaArticle class="px-5 py-1" article-id="9038516" text="Learn more about the execution view" :slide="false" />
    <div v-if="orderedExecutions">
      <KodexaExecution v-for="execution in orderedExecutions" :key="execution.id" :execution="execution" />
    </div>
  </div>
</template>
