/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  DocumentFamily
} from '../../model/documentFamily'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListWorkspaceDocumentFamiliesParams
} from '../../model/listWorkspaceDocumentFamiliesParams'
import type {
  ListWorkspacesParams
} from '../../model/listWorkspacesParams'
import type {
  PageDocumentFamily
} from '../../model/pageDocumentFamily'
import type {
  PageWorkspace
} from '../../model/pageWorkspace'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import type {
  Workspace
} from '../../model/workspace'
import type {
  WorkspaceUpdate
} from '../../model/workspaceUpdate'
import type {
  WorkspaceUpdateResponse
} from '../../model/workspaceUpdateResponse'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Update multiple objects in the workspace
 */
export const updateWorkspaceObjects = (
    workspaceId: MaybeRef<string>,
    workspaceUpdate: MaybeRef<WorkspaceUpdate>,
 options?: SecondParameter<typeof customAxios>,) => {
      workspaceId = unref(workspaceId);
workspaceUpdate = unref(workspaceUpdate);
      
      return customAxios<WorkspaceUpdateResponse>(
      {url: `/api/workspaces/${workspaceId}/update`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: workspaceUpdate
    },
      options);
    }
  


export const getUpdateWorkspaceObjectsMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkspaceObjects>>, TError,{workspaceId: string;data: WorkspaceUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateWorkspaceObjects>>, TError,{workspaceId: string;data: WorkspaceUpdate}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWorkspaceObjects>>, {workspaceId: string;data: WorkspaceUpdate}> = (props) => {
          const {workspaceId,data} = props ?? {};

          return  updateWorkspaceObjects(workspaceId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateWorkspaceObjectsMutationResult = NonNullable<Awaited<ReturnType<typeof updateWorkspaceObjects>>>
    export type UpdateWorkspaceObjectsMutationBody = WorkspaceUpdate
    export type UpdateWorkspaceObjectsMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateWorkspaceObjects = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkspaceObjects>>, TError,{workspaceId: string;data: WorkspaceUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateWorkspaceObjectsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a resource with the provided ID
 */
export const getWorkspace = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Workspace>(
      {url: `/api/workspaces/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetWorkspaceQueryKey = (id: MaybeRef<string>,) => {
    return ['api','workspaces',id] as const;
    }

    
export const getGetWorkspaceQueryOptions = <TData = Awaited<ReturnType<typeof getWorkspace>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkspace>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetWorkspaceQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWorkspace>>> = ({ signal }) => getWorkspace(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWorkspace>>, TError, TData> 
}

export type GetWorkspaceQueryResult = NonNullable<Awaited<ReturnType<typeof getWorkspace>>>
export type GetWorkspaceQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetWorkspace = <TData = Awaited<ReturnType<typeof getWorkspace>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWorkspace>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWorkspaceQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateWorkspace = (
    id: MaybeRef<string>,
    workspace: MaybeRef<Workspace>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
workspace = unref(workspace);
      
      return customAxios<Workspace>(
      {url: `/api/workspaces/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: workspace
    },
      options);
    }
  


export const getUpdateWorkspaceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkspace>>, TError,{id: string;data: Workspace}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateWorkspace>>, TError,{id: string;data: Workspace}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWorkspace>>, {id: string;data: Workspace}> = (props) => {
          const {id,data} = props ?? {};

          return  updateWorkspace(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateWorkspaceMutationResult = NonNullable<Awaited<ReturnType<typeof updateWorkspace>>>
    export type UpdateWorkspaceMutationBody = Workspace
    export type UpdateWorkspaceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateWorkspace = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWorkspace>>, TError,{id: string;data: Workspace}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateWorkspaceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteWorkspace = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/workspaces/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteWorkspaceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkspace>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWorkspace>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWorkspace>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteWorkspace(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteWorkspaceMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWorkspace>>>
    
    export type DeleteWorkspaceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteWorkspace = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWorkspace>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteWorkspaceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listWorkspaces = (
    params?: MaybeRef<ListWorkspacesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageWorkspace>(
      {url: `/api/workspaces`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListWorkspacesQueryKey = (params?: MaybeRef<ListWorkspacesParams>,) => {
    return ['api','workspaces', ...(params ? [params]: [])] as const;
    }

    
export const getListWorkspacesQueryOptions = <TData = Awaited<ReturnType<typeof listWorkspaces>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListWorkspacesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listWorkspaces>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListWorkspacesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listWorkspaces>>> = ({ signal }) => listWorkspaces(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listWorkspaces>>, TError, TData> 
}

export type ListWorkspacesQueryResult = NonNullable<Awaited<ReturnType<typeof listWorkspaces>>>
export type ListWorkspacesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListWorkspaces = <TData = Awaited<ReturnType<typeof listWorkspaces>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListWorkspacesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listWorkspaces>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListWorkspacesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createWorkspace = (
    workspace: MaybeRef<Workspace>,
 options?: SecondParameter<typeof customAxios>,) => {
      workspace = unref(workspace);
      
      return customAxios<Workspace>(
      {url: `/api/workspaces`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: workspace
    },
      options);
    }
  


export const getCreateWorkspaceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWorkspace>>, TError,{data: Workspace}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createWorkspace>>, TError,{data: Workspace}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createWorkspace>>, {data: Workspace}> = (props) => {
          const {data} = props ?? {};

          return  createWorkspace(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateWorkspaceMutationResult = NonNullable<Awaited<ReturnType<typeof createWorkspace>>>
    export type CreateWorkspaceMutationBody = Workspace
    export type CreateWorkspaceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateWorkspace = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWorkspace>>, TError,{data: Workspace}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateWorkspaceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the paginated list of the document families associated with this workspace
 */
export const listWorkspaceDocumentFamilies = (
    workspaceId: MaybeRef<string>,
    params?: MaybeRef<ListWorkspaceDocumentFamiliesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      workspaceId = unref(workspaceId);
params = unref(params);
      
      return customAxios<PageDocumentFamily>(
      {url: `/api/workspaces/${workspaceId}/documentFamilies`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListWorkspaceDocumentFamiliesQueryKey = (workspaceId: MaybeRef<string>,
    params?: MaybeRef<ListWorkspaceDocumentFamiliesParams>,) => {
    return ['api','workspaces',workspaceId,'documentFamilies', ...(params ? [params]: [])] as const;
    }

    
export const getListWorkspaceDocumentFamiliesQueryOptions = <TData = Awaited<ReturnType<typeof listWorkspaceDocumentFamilies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(workspaceId: MaybeRef<string>,
    params?: MaybeRef<ListWorkspaceDocumentFamiliesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listWorkspaceDocumentFamilies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListWorkspaceDocumentFamiliesQueryKey(workspaceId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listWorkspaceDocumentFamilies>>> = ({ signal }) => listWorkspaceDocumentFamilies(workspaceId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(workspaceId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listWorkspaceDocumentFamilies>>, TError, TData> 
}

export type ListWorkspaceDocumentFamiliesQueryResult = NonNullable<Awaited<ReturnType<typeof listWorkspaceDocumentFamilies>>>
export type ListWorkspaceDocumentFamiliesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListWorkspaceDocumentFamilies = <TData = Awaited<ReturnType<typeof listWorkspaceDocumentFamilies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 workspaceId: MaybeRef<string>,
    params?: MaybeRef<ListWorkspaceDocumentFamiliesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listWorkspaceDocumentFamilies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListWorkspaceDocumentFamiliesQueryOptions(workspaceId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Add a document family association to this workspace
 */
export const addDocumentFamilyToWorkspace = (
    workspaceId: MaybeRef<string>,
    documentFamily: MaybeRef<DocumentFamily>,
 options?: SecondParameter<typeof customAxios>,) => {
      workspaceId = unref(workspaceId);
documentFamily = unref(documentFamily);
      
      return customAxios<Workspace>(
      {url: `/api/workspaces/${workspaceId}/documentFamilies`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: documentFamily
    },
      options);
    }
  


export const getAddDocumentFamilyToWorkspaceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addDocumentFamilyToWorkspace>>, TError,{workspaceId: string;data: DocumentFamily}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addDocumentFamilyToWorkspace>>, TError,{workspaceId: string;data: DocumentFamily}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addDocumentFamilyToWorkspace>>, {workspaceId: string;data: DocumentFamily}> = (props) => {
          const {workspaceId,data} = props ?? {};

          return  addDocumentFamilyToWorkspace(workspaceId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddDocumentFamilyToWorkspaceMutationResult = NonNullable<Awaited<ReturnType<typeof addDocumentFamilyToWorkspace>>>
    export type AddDocumentFamilyToWorkspaceMutationBody = DocumentFamily
    export type AddDocumentFamilyToWorkspaceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useAddDocumentFamilyToWorkspace = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addDocumentFamilyToWorkspace>>, TError,{workspaceId: string;data: DocumentFamily}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getAddDocumentFamilyToWorkspaceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForWorkspace = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/workspaces/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForWorkspaceQueryKey = (id: MaybeRef<string>,) => {
    return ['api','workspaces',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForWorkspaceQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForWorkspace>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForWorkspace>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForWorkspaceQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForWorkspace>>> = ({ signal }) => getChangeSequenceForWorkspace(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForWorkspace>>, TError, TData> 
}

export type GetChangeSequenceForWorkspaceQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForWorkspace>>>
export type GetChangeSequenceForWorkspaceQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForWorkspace = <TData = Awaited<ReturnType<typeof getChangeSequenceForWorkspace>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForWorkspace>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForWorkspaceQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Remove a document family association from this workspace
 */
export const removeDocumentFamilyFromWorkspace = (
    workspaceId: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      workspaceId = unref(workspaceId);
documentFamilyId = unref(documentFamilyId);
      
      return customAxios<Workspace>(
      {url: `/api/workspaces/${workspaceId}/documentFamilies/${documentFamilyId}`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveDocumentFamilyFromWorkspaceMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeDocumentFamilyFromWorkspace>>, TError,{workspaceId: string;documentFamilyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeDocumentFamilyFromWorkspace>>, TError,{workspaceId: string;documentFamilyId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeDocumentFamilyFromWorkspace>>, {workspaceId: string;documentFamilyId: string}> = (props) => {
          const {workspaceId,documentFamilyId} = props ?? {};

          return  removeDocumentFamilyFromWorkspace(workspaceId,documentFamilyId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RemoveDocumentFamilyFromWorkspaceMutationResult = NonNullable<Awaited<ReturnType<typeof removeDocumentFamilyFromWorkspace>>>
    
    export type RemoveDocumentFamilyFromWorkspaceMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useRemoveDocumentFamilyFromWorkspace = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeDocumentFamilyFromWorkspace>>, TError,{workspaceId: string;documentFamilyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getRemoveDocumentFamilyFromWorkspaceMutationOptions(options);

      return useMutation(mutationOptions);
    }
    