<script lang="ts" setup>
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import appStore from "~/store";
import type { Workspace } from "~/model";

const { currentWorkspace, availablePanels } = storeToRefs(appStore.workspaceStore);

const localWorkspace: Ref<undefined | Workspace> = ref(undefined);

watch (currentWorkspace, (value) => {
  localWorkspace.value = value;
}, { immediate: true });

watch (localWorkspace, (value) => {
  if (value) {
    appStore.workspaceStore.updateCurrentWorkspace(value);
  }
}, { deep: true });
</script>

<template>
  <div class="flex flex-col border-r bg-gray-50">
    <div v-if="localWorkspace" class="mb-2 flex-1 bg-gray-50 px-4 py-3">
      <KodexaTextInput v-model="localWorkspace.name" label="Name" name="name" />
      <KodexaTextArea v-model="localWorkspace.description" name="description" label="Description" class="mt-1" />
      <KodexaTextArea
        v-model="localWorkspace.workspaceStorage.overview" label="Overview"
        name="overview"
        class="mt-1"
        hint="You can use markdown in your overview"
        :rows="15"
      />

      <div class="mt-2" style="overflow-y: scroll !important; height: calc(100vh - 15rem)">
        <p>Select the panels you wish to have available in this workspace</p>

        <div class="mt-2 grid grid-cols-2 gap-4 p-2">
          <KodexaCheckbox
            v-for="panel in availablePanels" :key="panel.id" v-model="localWorkspace.workspaceStorage.availablePanels[panel.id]"
            :name="panel.id"
            :label="panel.name"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
