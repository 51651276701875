<script lang="ts" setup>
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps({
  keyName: {
    type: String,
    required: true,
  },
  node: {
    type: Object as PropType<Node>,
  },
});

const { currentWorkspaceId } = storeToRefs(appStore.workspaceStore);

function openPage(page) {

  if (!appStore.workspaceStore.sidecarPanelOpen) {
    appStore.workspaceStore.toggleSidecar();
  }
  nextTick(() => {
    const useSidecar = createSidecar(currentWorkspaceId.value);
    useSidecar.focusPage(props.node?.data.documentFamilyId, page);
  });
}
</script>

<template>
  <div class="button-container">
    <div v-for="page in props.node.data.raw.page_numbers" :key="page" class="button-wrapper">
      <KodexaButton name="openPrompt" size="small" style="width: 180px" icon="open-in-new" @click="openPage(page)">
        Open Page {{ page + 1 }}
      </KodexaButton>
    </div>
  </div>
</template>

<style scoped>
.button-container {
  display: block;
}

.button-wrapper {
  margin-bottom: 10px; /* Optional: space between buttons */
}
</style>
