<script lang="ts" setup>
import type { PropType } from "vue";
import type { Project, ProjectStatus } from "~/model";
import { getColorByBgColor } from "~/utils/colors";
import { updateHandler } from "~/utils/error-handler";
import { removeStatus, updateStatus } from "~/api/projects/projects";

const props = defineProps({
  project: {
    type: Object as PropType<Project>,
    required: true,
  },
  saveValue: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["updated"]);

const editing = ref(false);

const selectedProjectStatus = ref<ProjectStatus | undefined>(props.project?.status);

watch(() => props.project?.status, (newValue) => {
  selectedProjectStatus.value = newValue;
}, { immediate: true });

async function saveChanges() {
  await updateHandler(updateStatus(props.project.id as string, selectedProjectStatus.value as ProjectStatus), "Status updated");
}

async function updated(projectStatus) {
  selectedProjectStatus.value = projectStatus;
  // if we have save value flag, we will be saving the value to the server,
  // otherwise we will just emit the updated event
  if (props.saveValue) {
    await saveChanges();
  }
  editing.value = false;
  emit("updated", selectedProjectStatus.value);
}

async function clear() {
  selectedProjectStatus.value = undefined;
  editing.value = false;

  if (props.saveValue) {
    await updateHandler(removeStatus(props.project.id as string), "Status cleared");
  }
  emit("updated", undefined);
}
</script>

<template>
  <div>
    <div v-if="editing">
      <KodexaProjectStatusDropdown
        :key="project.id"
        v-model="selectedProjectStatus" :project="project" @updated="updated" @clear="clear"
      />
    </div>
    <div
      v-else-if="selectedProjectStatus" id="currentProjectStatus"
      style="height: 87px; display: flex; align-items: center;"
      @click="editing = true"
    >
      <span :style="{ 'background-color': selectedProjectStatus.color, 'color': getColorByBgColor(selectedProjectStatus.color) }" class="text-md inline-flex items-center rounded-md px-2 font-medium">{{ project.status.status }}</span>
    </div>
    <div
      v-else id="editProjectStatus" style="height: 87px; display: flex; align-items: center;"
      @click="editing = true"
    >
      <div>-</div>
    </div>
  </div>
</template>
