<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, CloudModel, ContentObject, Option } from "~/model";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import { listCloudModels } from "~/api/cloud-models/cloud-models";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const {
  isShown,
  localValue,
} = useConfigurationBase(props, emit);

const cloudModels = ref([] as CloudModel[]);
const loading = ref(true);

listCloudModels({ pageSize: 50, filter: "name is not null and embedding" }).then(
  (response) => {
    cloudModels.value = response.content;
    loading.value = false;
  },
  (error) => {
    loading.value = false;
    console.error("Error loading cloud models", error);
  },
);

const hint = computed(() => {
  let myHint;
  cloudModels.value.forEach((item) => {
    if (item.modelId === localValue.value) {
      myHint = item.description;
    }
  });
  return myHint;
});
</script>

<template>
  <div v-if="isShown">
    <KodexaDropDown
      v-if="cloudModels.length > 0"
      v-model="localValue"
      :name="item.name"
      :loading="loading"
      :label="item.label" :items="cloudModels" text-field="name" value-field="modelId"
    />
    <p v-if="hint" id="hint" class="mt-2 text-sm text-gray-500">
      {{ hint }}
    </p>
  </div>
</template>

<style>

</style>
