<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import appStore from "~/store";
import type { DocumentFamily } from "~/model";
import { RefHelper } from "~/utils/ref-utils";
import { addLabelToDocumentFamily, removeLabelFromDocumentFamily } from "~/api/stores/stores";
import { log } from "~/utils/logger";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: true,
  },
  labelSize: {
    type: String,
    required: false,
    default: "medium",
  },
});
const emit = defineEmits(["updated"]);
const reactiveDocumentFamily = ref(props.documentFamily);

const { labels } = storeToRefs(appStore.organizationStore);

function addTag(tag) {
  log.info(`Adding tag to document family ${tag.label}`);
  const refHelper = new RefHelper(props.documentFamily?.storeRef);
  addLabelToDocumentFamily(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), props.documentFamily.id as string, { label: tag.label }).then(() => {
    emit("updated");
  });
}

function removeTag(tag) {
  log.info(`Removing tag from document family ${tag.label}`);
  const refHelper = new RefHelper(props.documentFamily?.storeRef);
  removeLabelFromDocumentFamily(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), props.documentFamily.id as string, { label: tag }).then(() => {
    emit("updated");
  });
}
</script>

<template>
  <div style="width: 100%">
    <KodexaTagify
      :key="reactiveDocumentFamily.id"
      :tags="reactiveDocumentFamily.labels" :available-tags="labels" :size="labelSize" @add-tag="addTag"
      @remove-tag="removeTag"
    />
  </div>
</template>

<style scoped>
</style>
