<script setup lang="ts">
import { storeToRefs } from "pinia";
import type {PropType, Ref} from "vue";
import type { Assistant, ContentObject, Option } from "~/model";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import appStore from "~/store";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const {
  localValue,
} = useConfigurationBase(props, emit);

const height = computed(() => {
  return props.item.properties?.height || "300px";
}) as Ref<string>;

const language = computed(() => {
  return props.item.properties?.language || "python";
}) as Ref<string>;
</script>

<template>
  <div>
    <KodexaCodeEditor v-model="localValue" :language="language" :style="{height: height}"  />
  </div>
</template>

<style scoped>
</style>
