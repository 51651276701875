import * as pdfjsLib from "pdfjs-dist";
import type {
  DocumentInitParameters,
  PDFDataRangeTransport,
  PDFDocumentLoadingTask,
} from "pdfjs-dist/types/src/display/api";
import { log } from "~/utils/logger";

pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdfjs/pdf.worker.mjs";

if (typeof window === "undefined" || !("Worker" in window)) {
  throw new Error("Web Workers not supported in this environment.");
}

export function createLoadingTask(src: string | URL | Uint8Array | PDFDataRangeTransport | DocumentInitParameters): PDFDocumentLoadingTask {
  try {
    return pdfjsLib.getDocument(src);
  } catch (error) {
    log.error(`Unable to load pdfjs: ${error}`);
    throw error;
  }
}
