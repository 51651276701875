/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CloudModel
} from '../../model/cloudModel'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListCloudModelsParams
} from '../../model/listCloudModelsParams'
import type {
  PageCloudModel
} from '../../model/pageCloudModel'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getCloudModel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<CloudModel>(
      {url: `/api/cloudModels/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetCloudModelQueryKey = (id: MaybeRef<string>,) => {
    return ['api','cloudModels',id] as const;
    }

    
export const getGetCloudModelQueryOptions = <TData = Awaited<ReturnType<typeof getCloudModel>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCloudModel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetCloudModelQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCloudModel>>> = ({ signal }) => getCloudModel(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCloudModel>>, TError, TData> 
}

export type GetCloudModelQueryResult = NonNullable<Awaited<ReturnType<typeof getCloudModel>>>
export type GetCloudModelQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetCloudModel = <TData = Awaited<ReturnType<typeof getCloudModel>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCloudModel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetCloudModelQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateCloudModel = (
    id: MaybeRef<string>,
    cloudModel: MaybeRef<CloudModel>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
cloudModel = unref(cloudModel);
      
      return customAxios<CloudModel>(
      {url: `/api/cloudModels/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: cloudModel
    },
      options);
    }
  


export const getUpdateCloudModelMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCloudModel>>, TError,{id: string;data: CloudModel}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateCloudModel>>, TError,{id: string;data: CloudModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCloudModel>>, {id: string;data: CloudModel}> = (props) => {
          const {id,data} = props ?? {};

          return  updateCloudModel(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateCloudModelMutationResult = NonNullable<Awaited<ReturnType<typeof updateCloudModel>>>
    export type UpdateCloudModelMutationBody = CloudModel
    export type UpdateCloudModelMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateCloudModel = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCloudModel>>, TError,{id: string;data: CloudModel}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateCloudModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteCloudModel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/cloudModels/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteCloudModelMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCloudModel>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteCloudModel>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCloudModel>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteCloudModel(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteCloudModelMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCloudModel>>>
    
    export type DeleteCloudModelMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteCloudModel = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCloudModel>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteCloudModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listCloudModels = (
    params?: MaybeRef<ListCloudModelsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageCloudModel>(
      {url: `/api/cloudModels`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListCloudModelsQueryKey = (params?: MaybeRef<ListCloudModelsParams>,) => {
    return ['api','cloudModels', ...(params ? [params]: [])] as const;
    }

    
export const getListCloudModelsQueryOptions = <TData = Awaited<ReturnType<typeof listCloudModels>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListCloudModelsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCloudModels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListCloudModelsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listCloudModels>>> = ({ signal }) => listCloudModels(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listCloudModels>>, TError, TData> 
}

export type ListCloudModelsQueryResult = NonNullable<Awaited<ReturnType<typeof listCloudModels>>>
export type ListCloudModelsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListCloudModels = <TData = Awaited<ReturnType<typeof listCloudModels>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListCloudModelsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listCloudModels>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListCloudModelsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createCloudModel = (
    cloudModel: MaybeRef<CloudModel>,
 options?: SecondParameter<typeof customAxios>,) => {
      cloudModel = unref(cloudModel);
      
      return customAxios<CloudModel>(
      {url: `/api/cloudModels`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cloudModel
    },
      options);
    }
  


export const getCreateCloudModelMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCloudModel>>, TError,{data: CloudModel}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createCloudModel>>, TError,{data: CloudModel}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCloudModel>>, {data: CloudModel}> = (props) => {
          const {data} = props ?? {};

          return  createCloudModel(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateCloudModelMutationResult = NonNullable<Awaited<ReturnType<typeof createCloudModel>>>
    export type CreateCloudModelMutationBody = CloudModel
    export type CreateCloudModelMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateCloudModel = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCloudModel>>, TError,{data: CloudModel}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateCloudModelMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForCloudModel = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/cloudModels/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForCloudModelQueryKey = (id: MaybeRef<string>,) => {
    return ['api','cloudModels',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForCloudModelQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForCloudModelQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>> = ({ signal }) => getChangeSequenceForCloudModel(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>, TError, TData> 
}

export type GetChangeSequenceForCloudModelQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>>
export type GetChangeSequenceForCloudModelQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForCloudModel = <TData = Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForCloudModel>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForCloudModelQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



