<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import {storeToRefs} from "pinia";
import type {PropType} from "vue";
import type {Assistant, ContentObject, Option} from "~/model";
import {useConfigurationBase} from "~/components/options/configuration-option-utils";
import appStore from "~/store";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const {
  localValue,
} = useConfigurationBase(props, emit);

const numberValue = computed({
  get: () => {
    return localValue.value === null ? "" : localValue.value;
  },
  set: (value) => {
    localValue.value = value === "" ? null : value;
  },
});
</script>

<template>
    <KodexaNumericInput
      :id="`option-${item.name}`" :disabled="disabled"
      v-model="numberValue" :name="`option-${item.name}`"
      :label="item.label ? item.label : item.name"
    />
</template>

<style scoped>
</style>
