<script setup lang="ts">
const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  issues: {
    type: Array,
    required: false,
    default: () => [],
  },
});
</script>

<template>
  <div class="rounded-md bg-red-50 p-4">
    <div class="flex">
      <div class="shrink-0">
        <MaterialDesignIcon name="error" class="h-5 w-5 text-red-400" aria-hidden="true" />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">
          {{ props.message }}
        </h3>
        <div v-if="props.issues.length > 0" class="mt-2 text-sm text-red-700">
          <ul role="list" class="list-disc space-y-1 pl-5">
            <li v-for="(issue, idx) in props.issues" :key="idx">
              {{ issue }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
