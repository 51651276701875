<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option } from "~/model";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    values: {
      type: Array as PropType<any>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const {
  isShown,
  localValue,
} = useConfigurationBase(props, emit);

const hint = computed(() => {
  let myHint;
  props.values.forEach((item) => {
    if (item.value === localValue.value) {
      myHint = item.hint;
    }
  });
  return myHint;
});
</script>

<template>
  <div v-if="isShown">
    <KodexaDropDown
      v-model="localValue"
      :name="item.name"
      :label="item.label" :items="values" text-field="label" value-field="value"
    />
    <p v-if="hint" id="hint" class="mt-2 text-sm text-gray-500">
      {{ hint }}
    </p>
  </div>
</template>

<style>

</style>
