<script lang="ts" setup>
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { Handle, Position } from "@vue-flow/core";
import appStore from "~/store";
import { DataFlowNode, Taxonomy } from "~/model";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["selected", "selectedStep"]);

const { taxonomies } = storeToRefs(appStore.projectStore);

const taxonomy = computed(() => {
  return taxonomies.value.find((taxonomy: Taxonomy) => props.node && taxonomy.ref === props.node.id?.split("//")[1]);
});
</script>

<template>
  <div @click="emit('selected')">
    <img
      style="float:left" class="ml-2 mr-3 h-16 rounded-md"
      src="/assets/icon-taxonomy.png" alt="Data Definition"
    >
    <div class="pt-4 text-gray-700" style="width: 250px">
      {{ taxonomy?.name || 'Data Definition' }}
    </div>
    <div class="pl-2 text-xs text-gray-500" style="width: 250px">
      Data Definition
    </div>
  </div>
  <Handle id="resourceConnection" type="source" :position="Position.Top" />
</template>

<style scoped>

</style>
