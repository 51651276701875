<script setup lang="ts">
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { filterIcon } from "@progress/kendo-svg-icons";
import type { PropType, Ref } from "vue";
import { computed, ref, watch } from "vue";
import type {DashboardWidget, Kiosk} from "~/model";
import appStore from "~/store";
import { useListKiosks } from "~/api/kiosks/kiosks";
import ProjectNewKiosk from "~/components/dashboard/widgets/project/project-new-kiosk.vue";
import ProjectEditKiosk from "~/components/dashboard/widgets/project/project-edit-kiosk.vue";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
  },
});

const { project } = storeToRefs(appStore.projectStore);

const gridHelper = createGridHelper(`project-kiosks-${project.value.id}`, {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  isError,
  data,
  refetch,
} = useListKiosks(computed(() => ({
  ...gridQuery.value,
})));

watch(project, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`project.id: '${project.value.id}'`);
  }
}, { immediate: true });

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const columns = [
  {
    title: "Name",
    field: "name",
    cell: "name",
  },
];

const showCreateKiosk = ref(false);
const selectedKiosk: Ref<Kiosk | undefined> = ref(undefined);
const showSelectedKiosk = ref(false);

function openNewKiosk() {
  showCreateKiosk.value = true;
}

function editKiosk(kiosk: Kiosk) {
  selectedKiosk.value = kiosk;
  showSelectedKiosk.value = true;
}

async function handleNewKiosk() {
  await refetch();
  showCreateKiosk.value = false;
}

const gridStyle = computed(() => {
  if (props.widget?.properties?.style !== undefined) {
    return props.widget?.properties?.style;
  } else {
    const rowSpan = props.widget?.properties?.defaultPosition?.rowSpan || 6;
    const height = (rowSpan * 85) - 130;
    return {
      height: `${height}px`,
    };
  }
});
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <Toolbar class="border-0 bg-white">
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()" />
        <KodexaArticle class="mt-2 ml-2" article-id="9875074" text="Learn more about kiosks" />
        <ToolbarSpacer />
        <KodexaButton id="createNewKiosk" icon="plus" @click="openNewKiosk">
          New Kiosk
        </KodexaButton>
      </Toolbar>
      <Grid
        style="height: calc(100vh - 15rem);"
        :style="gridStyle"
        :data-items="data?.content || []"
        :loader="isLoading"
        :pageable="pageSettings"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords :is-loading="isLoading" :is-error="isError" />
        </GridNoRecords>
        <template #name="{ props }">
          <td>
            <div class="flex items-center">
              <div
                class="flex-0 cursor-pointer font-bold text-gray-900 hover:text-blue-600"
                @click="editKiosk(props.dataItem)"
              >
                {{ props.dataItem?.name }}
              </div>
            </div>
          </td>
        </template>
      </Grid>
      <ProjectNewKiosk v-model="showCreateKiosk" @refetch="handleNewKiosk" />
      <ProjectEditKiosk
        v-if="showSelectedKiosk"
        :key="selectedKiosk?.id"
        v-model="showSelectedKiosk"
        :kiosk="selectedKiosk"
        @refetch="refetch"
      />
    </div>
  </div>
</template>
