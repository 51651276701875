/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CheckoutRequest
} from '../../model/checkoutRequest'
import type {
  CheckoutSession
} from '../../model/checkoutSession'
import type {
  RetrieveSessionParams
} from '../../model/retrieveSessionParams'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


export const prepareCheckout = (
    checkoutRequest: MaybeRef<CheckoutRequest>,
 options?: SecondParameter<typeof customAxios>,) => {
      checkoutRequest = unref(checkoutRequest);
      
      return customAxios<CheckoutSession>(
      {url: `/api/payments/prepareCheckout`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: checkoutRequest
    },
      options);
    }
  


export const getPrepareCheckoutMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof prepareCheckout>>, TError,{data: CheckoutRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof prepareCheckout>>, TError,{data: CheckoutRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof prepareCheckout>>, {data: CheckoutRequest}> = (props) => {
          const {data} = props ?? {};

          return  prepareCheckout(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type PrepareCheckoutMutationResult = NonNullable<Awaited<ReturnType<typeof prepareCheckout>>>
    export type PrepareCheckoutMutationBody = CheckoutRequest
    export type PrepareCheckoutMutationError = ErrorType<unknown>

    export const usePrepareCheckout = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof prepareCheckout>>, TError,{data: CheckoutRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getPrepareCheckoutMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const retrieveSession = (
    params: MaybeRef<RetrieveSessionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<CheckoutSession>(
      {url: `/api/payments/session`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getRetrieveSessionQueryKey = (params: MaybeRef<RetrieveSessionParams>,) => {
    return ['api','payments','session', ...(params ? [params]: [])] as const;
    }

    
export const getRetrieveSessionQueryOptions = <TData = Awaited<ReturnType<typeof retrieveSession>>, TError = ErrorType<unknown>>(params: MaybeRef<RetrieveSessionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof retrieveSession>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getRetrieveSessionQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof retrieveSession>>> = ({ signal }) => retrieveSession(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof retrieveSession>>, TError, TData> 
}

export type RetrieveSessionQueryResult = NonNullable<Awaited<ReturnType<typeof retrieveSession>>>
export type RetrieveSessionQueryError = ErrorType<unknown>

export const useRetrieveSession = <TData = Awaited<ReturnType<typeof retrieveSession>>, TError = ErrorType<unknown>>(
 params: MaybeRef<RetrieveSessionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof retrieveSession>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRetrieveSessionQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



