/*
 * Copyright (C) 2024 Kodexa Inc - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited.
 * Proprietary and confidential.
 */

import { pack, unpack } from "msgpackr";
import initSqlJs from "sql.js";
import type { ILogObj } from "tslog";
import { Logger } from "tslog";
import { v4 } from "uuid";
import type { TagMetadata } from "~/store/useProject";
import { log } from "~/utils/logger";
import type {FeatureSet, NodeFeatures, ProcessingStep, Taxon} from "~/model";

export interface TagGroup {
  tag: string;
  page: ContentNode | undefined;
  tagUuid: string;
  nodes: ContentNode[];
  metadata: TagMetadata | undefined;
  taxon: Taxon | undefined;
  text: string | undefined;
}

export interface SelectedTag extends TagMetadata {
  name: string;
  uuid: string;
  path: string;
  data: object;
  feature: object;
}

export interface TagData {
  cellIndex?: string;
  groupUuid?: string;
}

export interface ModelInsight {
  name: string;
}

export class ContentFeature {
  public featureType: string;
  public name: string;
  public value: any = {};
  public single = true;

  constructor(featureType: string, name: string) {
    this.featureType = featureType;
    this.name = name;
    this.value = {};
    this.single = true;
  }
}

export class ContentNode {
  private readonly kddbDocument: KddbDocument;
  readonly uuid: string;
  private _features: ContentFeature[] | undefined = undefined;
  private _content: string | undefined = undefined;
  private _children: ContentNode[] | undefined = undefined;
  private _parentId: string | undefined = undefined;
  private _parent: ContentNode | undefined = undefined;
  nodeType: string | undefined = undefined;
  private readonly _contentParts: any[];
  public index: number;

  constructor(kddbDocument: KddbDocument, uuid: string, rawObj: any = undefined, parent: ContentNode | undefined = undefined) {
    this.kddbDocument = kddbDocument;
    this.uuid = uuid;

    this._features = undefined;
    this._content = undefined;
    this._children = undefined;

    if (parent) {
      this._parentId = parent.uuid;
      this._parent = parent;
    } else {
      if (rawObj) {
        this._parentId = rawObj.pid;
      }
    }

    this.nodeType = undefined;
    this._contentParts = [];
    this.index = 0;

    if (this.kddbDocument && this.uuid) {
      this.__load_node(rawObj);
    }
  }

  getDocument(): KddbDocument {
    return this.kddbDocument;
  }

  getChildLines(): ContentNode[] {
    const lines: ContentNode[] = [];
    this.getChildren().forEach((child) => {
      if (child.nodeType === "line") {
        lines.push(child);
      } else {
        lines.push(...child.getChildLines());
      }
    });
    return lines;
  }

  deleteFeature(feature: ContentFeature): void {
    const featureType = this.kddbDocument.getFeatureTypeId(`${feature.featureType}:${feature.name}`);
    const deleteFeature = this.kddbDocument.db.prepare("DELETE FROM ft where cn_id=:nodeId and f_type=:featureType");
    deleteFeature.run({
      $nodeId: this.uuid,
      $featureType: featureType,
    });
    deleteFeature.free();
  }

  getTagGroups(tagMetadataMap: Map<string, TagMetadata>): TagGroup[] {
    const tagInstances: Map<string, TagGroup> = new Map();
    this.getTagFeatures().forEach((tag) => {
      const tagInstance = `${tag.name}:${tag.value[0].uuid}`;
      if (!tagInstances.has(tagInstance) && tag.value[0].uuid) {
        const nodes = this.kddbDocument.findNodesByTagUuid(tag.value[0].uuid);
        const newGroup = {
          tag: tag.name,
          page: this.getPage(this),
          tagUuid: tag.value[0].uuid,
          nodes: this.kddbDocument.getSpatiallySortedNodes(nodes),
          metadata: undefined,
          taxon: undefined,
        } as TagGroup;
        newGroup.metadata = tagMetadataMap.get(tag.name);
        newGroup.text = newGroup.nodes.map(node => node.getContent()).join(" ");
        if (newGroup.metadata) {
          newGroup.taxon = newGroup.metadata.taxon;
        }
        tagInstances.set(tagInstance, newGroup);
      }
    });

    return Array.from(tagInstances.values());
  }

  getOverlayParents(nodes: ContentNode[]): ContentNode[] {
    const nodeIds = nodes.map(node => node.uuid);
    const parentIds = this.getParentIds().filter(id => nodeIds.includes(id));
    return nodes.filter(node => parentIds.includes(node.uuid));
  }

  getParentIds(): string[] {
    let ids: string[] = [];
    const parent = this.getParent();
    if (parent) {
      ids.push(parent.uuid);
      ids = ids.concat(parent.getParentIds());
    }
    return ids;
  }

  getParents(): ContentNode[] {
    let contentNodes: ContentNode[] = [];
    const parent = this.getParent();
    if (parent) {
      contentNodes.push(parent);
      contentNodes = contentNodes.concat(parent.getParents());
    }
    return contentNodes;
  }

  getParent(): ContentNode | undefined {
    if (this._parent !== undefined) {
      return this._parent;
    }

    if (this._parentId !== undefined) {
      this._parent = this.kddbDocument.getContentNode(this._parentId);
      return this._parent;
    } else {
      return undefined;
    }
  }

  getContentParts(): any[] {
    return this._contentParts || [];
  }

  getContent(): string {
    return this._content || "";
  }

  getChildren(force = false): ContentNode[] {
    if (this._children === undefined || force) {
      this._children = [];
      const loadCn = this.kddbDocument.db.prepare("select * from cn where pid = $nodeId order by idx");
      loadCn.bind({ $nodeId: this.uuid });
      while (loadCn.step()) {
        const rawCn = loadCn.getAsObject();
        this._children.push(this.kddbDocument.getContentNode(rawCn.id, rawCn, this));
      }
      loadCn.free();
    }
    return this._children;
  }

  __load_node(rawObj: any): void {
    let rawCn: any = null;
    if (rawObj === undefined) {
      const loadCn = this.kddbDocument.db.prepare("select * from cn where id = $nodeId");
      rawCn = loadCn.getAsObject({ $nodeId: this.uuid });
      loadCn.free();
    } else {
      rawCn = rawObj;
    }

    if (rawCn === null) {
      throw new Error(`Node ${this.uuid} not found`);
    }

    this.index = rawCn.idx;
    this._parentId = rawCn.pid;
    this.nodeType = this.kddbDocument._nTypeLookup.get(rawCn.nt);

    const loadContent = this.kddbDocument.db.prepare("select * from cnp where cn_id = $nodeId order by pos asc");
    loadContent.bind({ $nodeId: this.uuid });

    this._content = undefined;
    while (loadContent.step()) {
      const rawCnp = loadContent.getAsObject();
      if (rawCnp.content !== null) {
        this._contentParts.push(rawCnp.content);
        if (this._content === undefined) {
          this._content = rawCnp.content;
        } else {
          this._content = `${this._content} ${rawCnp.content}`;
        }
      } else {
        this._contentParts.push(rawCnp.content_idx);
      }
    }
    loadContent.free();
  }

  __replaceFeatures() {
    this.kddbDocument.db.run("delete from ft where cn_id=?", [this.uuid]);

    if (this._features) {
      this._features.forEach((feature) => {
        const tagUuid = feature.featureType === "tag" ? feature.value[0].uuid : null;
        this.kddbDocument.db.run("insert into ft (cn_id, f_type, binary_value, single, tag_uuid) values (?,?,?,?,?)", [this.uuid, this.kddbDocument.__getFLookupId(feature), pack(feature.value), feature.single, tagUuid]);
      });
    }
    this._features = undefined;
    this.getFeatures();
  }

  getAllContent(): string {
    const allContent: string[] = [];
    allContent.push(this._content || "");
    const children = this.getChildren();
    if (children !== undefined) {
      children.forEach((child) => {
        allContent.push(child.getAllContent());
      });
    }
    return this.nodeType === "content-area" ? allContent.join("\n") : allContent.join(" ");
  }

  getTag(tagName: string): any | undefined {
    let tagFeature: ContentFeature | undefined;
    const features = this.getFeatures();
    if (features) {
      features.forEach((feature) => {
        if (feature.featureType === "tag" && (feature.name === tagName)) {
          tagFeature = feature;
        }
      });
    }

    if (tagFeature === undefined) {
      return tagFeature;
    }

    return tagFeature.value;
  }

  getFeatures(): ContentFeature[] {
    if (this._features !== undefined) {
      return this._features;
    } else {
      this._features = [];
      const loadFeatures = this.kddbDocument.db.prepare("select * from ft where cn_id = $nodeId");
      loadFeatures.bind({ $nodeId: this.uuid });
      while (loadFeatures.step()) {
        const featureRaw = loadFeatures.getAsObject();
        const featureNameType = this.kddbDocument._fTypeLookup.get(featureRaw.f_type);

        if (featureNameType !== undefined) {
          const newFeature = new ContentFeature(featureNameType.split(":")[0], featureNameType.split(":")[1]);
          newFeature.value = unpack(featureRaw.binary_value);
          newFeature.single = (featureRaw.single === 1);
          this._features.push(newFeature);
        }
      }
      loadFeatures.free();
      return this._features;
    }
  }

  getFeature(featureType: string, name: string): ContentFeature | undefined {
    let featureHit: ContentFeature | undefined;
    this.getFeatures().forEach((feature) => {
      if (feature.featureType === featureType && feature.name === name) {
        featureHit = feature;
      }
    });
    return featureHit;
  }

  tag(tagName: string, tagUuid: string, data = {}, confidence = 1, owner_uri: string | undefined = undefined, value: string | undefined = undefined, cellIndex: string | undefined = undefined, groupUuid: string | undefined = undefined) {
    if (tagName === null || tagName === undefined) {
      throw new Error("Invalid tag name (null or undefined)");
    }
    log.info(`Adding tag ${tagName}`);

    if (this.getTag(tagName) === undefined) {
      const newFeature = new ContentFeature("tag", tagName);
      newFeature.value = [{
        uuid: tagUuid,
        confidence,
        data,
        owner_uri,
        value,
        group_uuid: groupUuid,
        cell_index: cellIndex,
      }];
      newFeature.single = true;
      if (this._features === undefined) {
        this._features = [];
      }

      this._features.push(newFeature);
      this.__replaceFeatures();
    } else {
      log.warn(`Tag ${tagName} already exists`);
    }
  }

  untag(tagName: string) {
    let idx = 0;
    this.getFeatures().forEach((feature) => {
      if (feature.featureType === "tag" && feature.name === tagName) {
        if (this._features === undefined) {
          this._features = [];
        }
        this._features.splice(idx, 1);
        this.__replaceFeatures();
      } else {
        idx = idx + 1;
      }
    });
  }

  /**
   * Retrieves the tags from either the child documents or the features of this document.
   * @param {boolean} includeChildren - Specifies whether to include the child documents.
   * @return {Set<string>} - A set of tags found in the document.
   */
  getTags(includeChildren = true) {
    const tags: string[] = [];
    if (includeChildren) {
      this.kddbDocument._fTypeLookup.forEach((value: any) => {
        if (value.startsWith("tag:")) {
          tags.push(value.split(":")[1]);
        }
      });
    } else {
      this.getFeatures().forEach((feature) => {
        if (feature.featureType === "tag") {
          tags.push(feature.name);
        }
      });
    }

    return new Set(tags);
  }

  /**
   * Retrieves the tag features from the list of all features.
   *
   * @return {Array} An array of tag features.
   */
  getTagFeatures() {
    return this.getFeatures().filter(feature => feature.featureType === "tag");
  }

  /**
   * Retrieves the nearest page or worksheet node in the content hierarchy, relative to the given current node.
   *
   * @param {ContentNode} [currentNode] - The current node to start searching from. Defaults to the current instance if not provided.
   * @returns {ContentNode | undefined} - The nearest page or worksheet node, or undefined if not found.
   */
  getPage(currentNode: ContentNode = this): ContentNode | undefined {
    if (currentNode.nodeType === "page" || currentNode.nodeType === "worksheet" || currentNode.getParent() === undefined) {
      return currentNode;
    } else {
      const parent = currentNode.getParent();
      if (parent === undefined) {
        return undefined;
      } else {
        return this.getPage(parent);
      }
    }
  }

  /**
   * Retrieves the closest line node from the given content node.
   *
   * @param {ContentNode} [currentNode] - The content node to start searching from.
   * @returns {ContentNode|undefined} - The closest line node, or undefined if not found.
   */
  getLine(currentNode: ContentNode = this): ContentNode | undefined {
    if (currentNode.nodeType === "line" || currentNode.getParent() === undefined) {
      return currentNode;
    } else {
      const parent = currentNode.getParent();
      if (parent === undefined) {
        return undefined;
      } else {
        return this.getLine(parent);
      }
    }
  }
}

/**
 * Represents a line content.
 * @interface
 */
export interface LineContent {
  content: string;
  uuid: string;
}

export class KddbDocument {
  readonly db: any;
  private version: string;
  readonly _fTypeLookup: Map<number, string> = new Map();
  readonly _nTypeLookup: Map<number, string> = new Map();
  public metadata: any = {};
  public contentNode: ContentNode | undefined;
  private uuid: any;
  private mixins: string[] = [];
  private labels: string[] = [];
  private source: any = {};
  private log: Logger<ILogObj> = new Logger();
  private nodeMap: Map<string, ContentNode> = new Map();

  destroy(): void {
    this.db.close();
  }

  constructor(db: any) {
    this.db = db;
    this.version = "6.0.0";
    if (this.db !== null) {
      this.__load_lookups();
      this.__load_document();
    } else {
      this.metadata = {};
      this.contentNode = undefined;
      this.uuid = v4();
      this.mixins = [];
      this.labels = [];
      this.source = {};
      this.version = "4.0.1";
    }

    this.db.exec(`CREATE TABLE IF NOT EXISTS content_exceptions
                         (
                             id
                             integer
                             primary
                             key,
                             tag
                             text,
                             message
                             text,
                             exception_details
                             text,
                             group_uuid
                             text,
                             tag_uuid
                             text,
                             exception_type
                             text,
                             severity
                             text,
                             node_uuid
                             text
                         )`);

    this.db.exec(`CREATE TABLE IF NOT EXISTS model_insights
                         (
                             id
                             integer
                             primary
                             key,
                             model_insight
                             text
                         );`);
  }

  getLines(): LineContent[] {
    const loadCn = this.db.prepare(`
      with recursive cns (lvl, id, idx, nt, cpidx, content) as (select 0               as lvl,
                                                                       cn.id           as id,
                                                                       cn.idx          as idx,
                                                                       cn.nt           as nt,
                                                                       cnp.content_idx as cpidx,
                                                                       cnp.content     as content
                                                                from cn
                                                                       left outer join
                                                                     cnp on cnp.cn_id = cn.id
                                                                where pid is null
                                                                union all
                                                                select cns.lvl + 1,
                                                                       cn.id,
                                                                       cn.idx,
                                                                       cn.nt           as nt,
                                                                       cnp.content_idx as cpidx,
                                                                       cnp.content     as content
                                                                from cns
                                                                       join
                                                                     cn on cn.pid = cns.id
                                                                       left outer join
                                                                     cnp on cnp.cn_id = cn.id
                                                                order by cns.lvl + 1 desc, cpidx)
      select id,
             lvl,
             nt,
             content
      from cns;
    `);

    // We need to work out the node type for a line
    // then we will break these out into a separate array
    try {
      const lineType = this.getNodeTypeId("line");
      const contentLines: LineContent[] = [];
      let lastLineUuid;
      let currentContent = "";
      while (loadCn.step()) {
        const rawCn = loadCn.getAsObject();
        if (rawCn.nt === lineType) {
          if (lastLineUuid) {
            contentLines.push({
              content: currentContent,
              uuid: lastLineUuid,
            } as LineContent);
          }
          lastLineUuid = rawCn.id;
          currentContent = "";
        } else {
          if (rawCn.content) {
            currentContent = `${currentContent} ${rawCn.content}`;
          }
        }
      }
      loadCn.free();
      return contentLines;
    } catch (e) {
      log.info("No lines found");
      return [];
    }
  }

  getSpatiallySortedNodes(nodes: ContentNode[]): ContentNode[] {
    return [...nodes].sort(
      (a, b) => {
        if (!a.getFeature("spatial", "bbox")) {
          return 1;
        }

        const bboxA = a.getFeature("spatial", "bbox");
        const bboxB = b.getFeature("spatial", "bbox");

        if (!bboxA || !bboxB) {
          return 1;
        }

        if (bboxA.value[0][1] === bboxB.value[0][1]) {
          return bboxA.value[0][0] - bboxB.value[0][0];
        }

        // We include a 5 pixel buffer to account for slight differences in the bounding box
        return bboxB.value[0][1] > bboxA.value[0][1] - 5 ? 1 : -1;
      },
    );
  }

  getNodeText(nodes: ContentNode[]): string {
    const allContent: string[] = [];
    this.getSpatiallySortedNodes(nodes).forEach((node) => {
      allContent.push(node.getContent());
    });
    return allContent.join(" ");
  }

  buildFeatureSet(): FeatureSet {
    const taggedNodes = this.getTaggedNodes() || [];
    const tagFeatureSet = {
      nodeFeatures: [],
    } as FeatureSet;

    // We need to re-organize the tagged instances to group them
    // the

    taggedNodes.forEach((taggedNode) => {
      const nodeFeature = {
        nodeUuid: taggedNode.uuid,
        features: [],
      } as NodeFeatures;
      taggedNode.getFeatures().forEach((feature) => {
        if (feature.featureType === "tag") {
          if (!nodeFeature.features) {
            nodeFeature.features = [];
          }
          nodeFeature.features.push(feature);
        }
      });

      if (!tagFeatureSet.nodeFeatures) {
        tagFeatureSet.nodeFeatures = [];
      }
      tagFeatureSet.nodeFeatures.push(nodeFeature);
    });

    return tagFeatureSet;
  }

  getContentExceptions(): any[] {
    const exceptions = [] as any[];
    const loadCn = this.db.prepare("select * from content_exceptions");
    while (loadCn.step()) {
      const rawCn = loadCn.getAsObject();
      exceptions.push(rawCn);
    }
    loadCn.free();
    return exceptions;
  }

  getTaggedNodes(tagPrefix: string[] = []): ContentNode[] {
    const start = new Date().getTime();

    let query = "select * from cn where id in (select cn_id from ft where f_type in (select id from f_type where name like 'tag:%'))";
    if (tagPrefix.length > 0) {
      const tagLikeClause = tagPrefix.map(tag => `(name like 'tag:${tag}%')`).join(" or ");
      query = `select *
               from cn
               where id in (select cn_id
                            from ft
                            where f_type in (select id from f_type where ${tagLikeClause}))`;
    }

    this.log.debug(`Loading tagged nodes with query: ${query}`);
    const loadCn = this.db.prepare(query);
    const nodes: ContentNode[] = [];
    while (loadCn.step()) {
      const rawCn = loadCn.getAsObject();
      nodes.push(this.getContentNode(rawCn.id, rawCn));
    }
    loadCn.free();
    const elapsed = new Date().getTime() - start;
    this.log.debug(`${nodes.length} tagged nodes in ${elapsed}ms`);
    return nodes;
  }

  getNodeTypeId(nodeType: string): number {
    for (const [key, value] of this._nTypeLookup.entries()) {
      if (value === nodeType) {
        return key as number;
      }
    }
    throw new Error(`Unknown node type: ${nodeType}`);
  }

  getFeatureTypeId(featureType: string): number {
    for (const [key, value] of this._fTypeLookup.entries()) {
      if (value === featureType) {
        return key as number;
      }
    }
    throw new Error(`Unknown feature type: ${featureType}`);
  }

  // Ensure the 'steps' table exists in the database.
  async ensureStepsTableExists(db: any): Promise<void> {
    await db.exec("CREATE TABLE IF NOT EXISTS steps (obj BLOB)");

    // Check if the table has any rows, if not, insert an initial empty row
    const result = await db.exec("SELECT COUNT(*) AS count FROM steps");
    const count = result[0].values[0][0];

    if (count === 0) {
      const emptyData = pack([]); // Serialize empty array as MsgPack
      await db.exec("INSERT INTO steps (obj) VALUES (?)", [emptyData]);
    }
  }

  async updateStepsTable(db: any, packedData: Uint8Array): Promise<void> {
    await db.exec("UPDATE steps SET obj = ? WHERE rowid = 1", [packedData]);
  }

  async getStepsTableData(db: any): Promise<Uint8Array | null> {
    const result = await db.exec("SELECT obj FROM steps WHERE rowid = 1");
    if (result.length > 0 && result[0].values.length > 0) {
      const data = result[0].values[0];
      return data[0] as Uint8Array;
    }
    return null;
  }

  async getSteps(): Promise<ProcessingStep[]> {
    await this.ensureStepsTableExists(this.db);
    const data = await this.getStepsTableData(this.db);
    return data ? unpack(data) : [];
  }

  getNodesByTag(tag: string): ContentNode[] {
    const loadCn = this.db.prepare("select * from cn where id in (select cn_id from ft where f_type in (select id from f_type where name like $tagName))");
    loadCn.bind({ $tagName: `tag:${tag}` });
    const nodes: ContentNode[] = [];
    while (loadCn.step()) {
      const rawCn = loadCn.getAsObject();
      nodes.push(this.getContentNode(rawCn.id, rawCn));
    }
    loadCn.free();
    return nodes;
  }

  findNodesByTagUuid(tagUuid: string): ContentNode[] {
    if (!tagUuid) {
      this.log.error("findNodesByTagUuid called with no tagUuid");
      return [];
    }

    const loadCn = this.db.prepare("select * from cn where id in (select cn_id from ft where tag_uuid=$tag_Uuid)");
    loadCn.bind({ $tag_Uuid: tagUuid });
    const nodes: ContentNode[] = [];
    while (loadCn.step()) {
      const rawCn = loadCn.getAsObject();
      nodes.push(this.getContentNode(rawCn.id, rawCn));
    }
    loadCn.free();
    return nodes;
  }

  findNodeByUUID(uuid: string): ContentNode | undefined {
    if (!uuid) {
      return undefined;
    }
    const loadCn = this.db.prepare("select * from cn where id=$nodeUuid");
    loadCn.bind({ $nodeUuid: uuid });
    let node: ContentNode | undefined;
    while (loadCn.step()) {
      const rawCn = loadCn.getAsObject();
      node = this.getContentNode(rawCn.id, rawCn);
    }
    loadCn.free();
    return node;
  }

  getTagGroups(tagMetadataMap: Map<string, TagMetadata>): TagGroup[] {
    const tagInstances: Map<string, TagGroup> = new Map();
    this.getTaggedNodes().forEach((node) => {
      node.getTagFeatures().forEach((tag) => {
        const tagInstance = `${tag.name}:${tag.value[0].uuid}`;
        if (!tagInstances.has(tagInstance)) {
          const nodes = this.findNodesByTagUuid(tag.value[0].uuid);
          const newGroup = {
            tag: tag.name,
            page: node.getPage(node),
            tagUuid: tag.value[0].uuid,
            nodes: this.getSpatiallySortedNodes(nodes),
            metadata: undefined,
            taxon: undefined,
          } as TagGroup;
          newGroup.metadata = tagMetadataMap.get(tag.name);
          if (newGroup.metadata) {
            newGroup.taxon = newGroup.metadata.taxon;
          }
          tagInstances.set(tagInstance, newGroup);
        }
      });
    });

    return Array.from(tagInstances.values());
  }

  __load_lookups() {
    const fTypeLookup = this.db.prepare("select * from f_type");
    while (fTypeLookup.step()) {
      const fTypeObj = fTypeLookup.getAsObject();
      this._fTypeLookup.set(fTypeObj.id, fTypeObj.name);
    }
    fTypeLookup.free();

    const nTypeLookup = this.db.prepare("select * from n_type");
    while (nTypeLookup.step()) {
      const nTypeObj = nTypeLookup.getAsObject();
      this._nTypeLookup.set(nTypeObj.id, nTypeObj.name);
    }
    nTypeLookup.free();
  }

  __getFLookupId(feature: ContentFeature): number {
    const featureTypeName = `${feature.featureType}:${feature.name}`;
    try {
      return this.getFeatureTypeId(featureTypeName);
    } catch (e) {
      this.db.run("insert into f_type (name) values (?)", [featureTypeName]);
      const id = this.db.exec("select last_insert_rowid();")[0].values[0][0] as number;
      this._fTypeLookup.set(id, featureTypeName);
      return id;
    }
  }

  updateMetadata() {
    const metadata = {
      uuid: this.uuid,
      metadata: this.metadata,
      labels: this.labels,
      source: this.source,
      mixins: this.mixins,
      version: this.version,
    };

    this.db.run("update metadata set metadata = ? where id = 1", [pack(metadata)]);
  }

  __load_document() {
    const stmt = this.db.prepare("select * from metadata");
    stmt.step();
    const metadata: any = unpack(stmt.getAsObject().metadata);
    stmt.free();
    this.uuid = metadata.uuid;
    this.metadata = metadata.metadata;
    this.labels = metadata.labels;
    this.source = metadata.source;
    this.mixins = metadata.mixins;
    this.version = metadata.version;

    // Find any old tags that don't have a tagUuid
    const cleanUp = this.db.prepare("select distinct(f_type) from ft where tag_uuid is null and f_type in (select id from f_type where name like 'tag:%')");
    while (cleanUp.step()) {
      const fTypeObject = cleanUp.getAsObject();

      const newUuid = `${v4()}`;
      this.db.run("update ft set tag_uuid = ? where f_type=? and tag_uuid is null", [newUuid, fTypeObject.f_type]);
    }
    cleanUp.free();
    const stmt2 = this.db.prepare("select * from cn where pid is null");
    if (stmt2.step()) {
      const rawCn = stmt2.getAsObject();
      this.contentNode = this.getContentNode(rawCn.id);
    }
    stmt2.free();
  }

  toKDDB(): any {
    this.updateMetadata();
    return this.db.export();
  }

  close() {
    this.db.close();
  }

  static async fromBlob(kddbBlob: Blob): Promise<KddbDocument> {
    const [SQL, arrayBytes] = await Promise.all([initSqlJs({
      // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
      // You can omit locateFile completely when running in node
      locateFile: (file) => {
        return `/sqljs/${file}`;
      },
    }), kddbBlob.arrayBuffer()]);
    const db = new SQL.Database(new Uint8Array(arrayBytes));
    return new KddbDocument(db);
  }

  getNumPages(): number {
    return this.getNodeCountByType("page") as number;
  }

  getModelInsights(): ModelInsight[] {
    const modelInsights = [] as any[];
    try {
      const loadCn = this.db.prepare("select model_insight from model_insights");
      while (loadCn.step()) {
        const rawCn = loadCn.getAsObject();
        modelInsights.push(JSON.parse(rawCn.model_insight) as ModelInsight);
      }
      loadCn.free();
    } catch (e) {
      // No model insights
    }
    return modelInsights;
  }

  getPage(page: number): ContentNode {
    if (!this.contentNode) {
      throw new Error("No content node found");
    }
    const pages = this.contentNode.getChildren();
    this.log.info(`Getting page ${page + 1} of ${pages.length} pages`);
    if (page < pages.length) {
      return pages[page];
    } else {
      throw new Error(`Page ${page + 1} does not exist`);
    }
  }

  getNodesByType(nodeType: string): ContentNode[] {
    const nodeTypeId = this.getNodeTypeId(nodeType);
    const nodeLoader = this.db.prepare(`select *
                                        from cn
                                        where nt = ${nodeTypeId}`);
    const nodes: ContentNode[] = [];
    while (nodeLoader.step()) {
      const rawCn = nodeLoader.getAsObject();
      nodes.push(this.getContentNode(rawCn.id, rawCn));
    }
    nodeLoader.free();
    return nodes;
  }

  getNodeById(nodeId: string): ContentNode {
    const nodeLoader = this.db.prepare(`select *
                                        from cn
                                        where id = ${nodeId}`);
    nodeLoader.step();
    const rawCn = nodeLoader.getAsObject();
    nodeLoader.free();
    return this.getContentNode(rawCn.id, rawCn);
  }

  getNodeCountByType(nodeType: string): any {
    const nodeTypeId = this.getNodeTypeId(nodeType);
    return this.db.exec("select count(1) as count from cn where nt = ?", [nodeTypeId])[0].values[0][0] as number;
  }

  toBlob(): Blob {
    return new Blob([this.toKDDB()], { type: "octet/stream" });
  }

  public getContentNode(uuid: any, rawCn: any | undefined = undefined, parentContentNode: ContentNode | undefined = undefined): ContentNode {
    if (this.nodeMap.has(uuid)) {
      return this.nodeMap.get(uuid) as ContentNode;
    } else {
      const cn = new ContentNode(this, uuid, rawCn, parentContentNode);
      this.nodeMap.set(uuid, cn);
      return cn;
    }
  }
}
