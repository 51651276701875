/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListProjectTagsParams
} from '../../model/listProjectTagsParams'
import type {
  PageProjectTag
} from '../../model/pageProjectTag'
import type {
  ProjectTag
} from '../../model/projectTag'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getProjectTag = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProjectTag>(
      {url: `/api/projectTags/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectTagQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projectTags',id] as const;
    }

    
export const getGetProjectTagQueryOptions = <TData = Awaited<ReturnType<typeof getProjectTag>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectTag>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProjectTagQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectTag>>> = ({ signal }) => getProjectTag(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectTag>>, TError, TData> 
}

export type GetProjectTagQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectTag>>>
export type GetProjectTagQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetProjectTag = <TData = Awaited<ReturnType<typeof getProjectTag>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectTag>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectTagQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateProjectTag = (
    id: MaybeRef<string>,
    projectTag: MaybeRef<ProjectTag>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
projectTag = unref(projectTag);
      
      return customAxios<ProjectTag>(
      {url: `/api/projectTags/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: projectTag
    },
      options);
    }
  


export const getUpdateProjectTagMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectTag>>, TError,{id: string;data: ProjectTag}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProjectTag>>, TError,{id: string;data: ProjectTag}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProjectTag>>, {id: string;data: ProjectTag}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProjectTag(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateProjectTagMutationResult = NonNullable<Awaited<ReturnType<typeof updateProjectTag>>>
    export type UpdateProjectTagMutationBody = ProjectTag
    export type UpdateProjectTagMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateProjectTag = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectTag>>, TError,{id: string;data: ProjectTag}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateProjectTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteProjectTag = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/projectTags/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProjectTagMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectTag>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectTag>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectTag>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteProjectTag(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectTagMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectTag>>>
    
    export type DeleteProjectTagMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteProjectTag = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectTag>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteProjectTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listProjectTags = (
    params?: MaybeRef<ListProjectTagsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageProjectTag>(
      {url: `/api/projectTags`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListProjectTagsQueryKey = (params?: MaybeRef<ListProjectTagsParams>,) => {
    return ['api','projectTags', ...(params ? [params]: [])] as const;
    }

    
export const getListProjectTagsQueryOptions = <TData = Awaited<ReturnType<typeof listProjectTags>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListProjectTagsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjectTags>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListProjectTagsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProjectTags>>> = ({ signal }) => listProjectTags(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProjectTags>>, TError, TData> 
}

export type ListProjectTagsQueryResult = NonNullable<Awaited<ReturnType<typeof listProjectTags>>>
export type ListProjectTagsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListProjectTags = <TData = Awaited<ReturnType<typeof listProjectTags>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListProjectTagsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjectTags>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListProjectTagsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createProjectTag = (
    projectTag: MaybeRef<ProjectTag>,
 options?: SecondParameter<typeof customAxios>,) => {
      projectTag = unref(projectTag);
      
      return customAxios<ProjectTag>(
      {url: `/api/projectTags`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: projectTag
    },
      options);
    }
  


export const getCreateProjectTagMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectTag>>, TError,{data: ProjectTag}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProjectTag>>, TError,{data: ProjectTag}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProjectTag>>, {data: ProjectTag}> = (props) => {
          const {data} = props ?? {};

          return  createProjectTag(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateProjectTagMutationResult = NonNullable<Awaited<ReturnType<typeof createProjectTag>>>
    export type CreateProjectTagMutationBody = ProjectTag
    export type CreateProjectTagMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateProjectTag = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectTag>>, TError,{data: ProjectTag}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateProjectTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForProjectTag = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/projectTags/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForProjectTagQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projectTags',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForProjectTagQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForProjectTag>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProjectTag>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForProjectTagQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForProjectTag>>> = ({ signal }) => getChangeSequenceForProjectTag(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProjectTag>>, TError, TData> 
}

export type GetChangeSequenceForProjectTagQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForProjectTag>>>
export type GetChangeSequenceForProjectTagQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForProjectTag = <TData = Awaited<ReturnType<typeof getChangeSequenceForProjectTag>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProjectTag>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForProjectTagQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



