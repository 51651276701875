<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import type { DataAttribute, DataException, DataObject } from "~/model";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";
import type { TagMetadata } from "~/store/useProject";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  dataExceptions: {
    type: Object as PropType<DataException[]>,
    required: false,
    default: () => {
      return [] as DataException[];
    },
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const decimalValue = computed({
  get() {
    return props.attribute.decimalValue;
  },
  set(value) {
    emit("update", {
      ...props.attribute,
      decimalValue: value,
    });
  },
});

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);
const valid = computed(() => {
  return props.dataExceptions?.length === 0;
});

function focus() {
  nextTick(() => {
    const numericTextBox = document.querySelector(`#kdx-attribute-${props.attribute.uuid}`);
    if (numericTextBox) {
      numericTextBox.select();
    }
  });
  dataViewHelper.focus();
  emit("focus");
}

const { style, conditionalValidationIcon } = storeToRefs(dataViewHelper);
</script>

<template>
  <div @click="focus">
    <NumericTextBox
      :id="`kdx-attribute-${props.attribute.uuid}`"
      v-model="decimalValue"
      :style="style"
      :tabindex="props.tabIndex"
      :valid="valid"
      :disabled="props.dataObject?.documentFamily.locked === true"
      :fill-mode="null"
      rounded="medium"
      :spinners="false"
      :step="0"
      input-prefix="prefix"
      @focus="focus"
      @blur="dataViewHelper.blur()"
    >
      <template #prefix>
        <MaterialDesignIcon v-if="conditionalValidationIcon.icon" class="px-1" :name="conditionalValidationIcon.icon" :color="conditionalValidationIcon.color" />
      </template>
    </NumericTextBox>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-sm text-gray-700 mb-1
}

.kodexa-input {
  @apply w-full appearance-none rounded-md border border-gray-300
  px-0 py-0 shadow-sm placeholder:text-gray-400 sm:text-sm
}

.kodexa-input:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
}
.kodexa-input:focus {
  @apply border-blue-500 ring-1 ring-blue-500
}
</style>
