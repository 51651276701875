<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import type { PropType } from "vue/dist/vue";
import type { Ref } from "vue";
import appStore from "~/store";
import { log } from "~/utils/logger";
import type { SelectedTag } from "~/components/document/document";
import type { Taxon } from "~/model";

const props = defineProps({
  selectedTaxonTag: {
    type: Object as PropType<SelectedTag>,
    required: false,
  },
});

const emit = defineEmits(["closed", "update:selectedTaxonTag"]);

const {
  taxonomies,
  projectLoading,
} = storeToRefs(appStore.projectStore);

const taxonomyRef = ref(undefined);
const cachedTaxonomy = ref(undefined);
const refresher = ref(uuidv4());
const selectedTaxonId = ref(undefined);
const selectedTaxon: Ref<Taxon | undefined> = ref(undefined);

// We have to deep watch the taxonomies to make sure we update the selected taxonomy
// when the taxonomies change
watch([taxonomies, taxonomyRef], () => {
  if (taxonomyRef.value === undefined && taxonomies.value && taxonomies.value.length > 0) {
    taxonomyRef.value = taxonomies.value[0].ref;
  }
  if (taxonomyRef.value) {
    cachedTaxonomy.value = taxonomies.value.find((taxonomy: any) => taxonomy.ref === taxonomyRef.value);
    refresher.value = uuidv4();
  } else {
    cachedTaxonomy.value = undefined;
    selectedTaxonId.value = undefined;
  }
}, { deep: true, immediate: true });

const selectedTaxonomy = computed({
  get: () => {
    if (refresher.value) {
      return cachedTaxonomy.value;
    } else {
      return undefined;
    }
  },
  set: (value: any) => {
    log.info("Updating taxonomy in workspace");
    appStore.workspaceStore.updateTaxonomy(value);
  },
});

watch(() => [selectedTaxonId, props.selectedTaxonTag], () => {
  log.info(`Selected taxon id changed ${selectedTaxonId.value}`);
  if (selectedTaxonId.value && selectedTaxonomy.value) {
    log.info(`Finding selected taxon by id ${selectedTaxonId.value} and taxonomy ${selectedTaxonomy.value.ref}`);
    selectedTaxon.value = appStore.projectStore.findSelectedTaxonById(selectedTaxonId.value, selectedTaxonomy.value.ref);
    log.info(`Selected taxon ${selectedTaxon.value?.id}`);
  } else if (props.selectedTaxonTag) {
    selectedTaxon.value = appStore.projectStore.findSelectedTaxonById(props.selectedTaxonTag.taxon.id, props.selectedTaxonTag.taxonomy.ref);
  } else {
    selectedTaxon.value = undefined;
  }
}, { deep: true, immediate: true });

watch(selectedTaxon, (value) => {
  log.info(`Selected taxon changed ${selectedTaxon.value}`);
  if (value) {
    appStore.projectStore.findSelectedTaxonById(selectedTaxonId.value, selectedTaxonomy.value.ref, value);
  }
}, { deep: true });

function taxonSelected(taxon: any) {
  selectedTaxonId.value = taxon.id;
}

function returnToTaxonomy() {
  log.info(`Returning to taxonomy ${selectedTaxonId.value}`);
  selectedTaxonId.value = undefined;
  if (props.selectedTaxonTag) {
    emit("update:selectedTaxonTag", undefined);
  }
  refresher.value = uuidv4();
  log.info(`Refresher updated ${refresher.value}`);
}

function deleteSelected() {
  if (selectedTaxon.value && selectedTaxonomy.value && selectedTaxon.value) {
    appStore.workspaceStore.addUpdatedTaxonomyRef(selectedTaxonomy.value.ref as string);

    // We need to find the parent of the selected taxon
    const parent = appStore.projectStore.findParentTaxonFromTaxons(selectedTaxon.value.id, selectedTaxonomy.value.taxons);
    if (parent?.id === selectedTaxon.value?.id) {
      // We are deleting the root taxon
      appStore.projectStore.deleteTaxonFromTaxonomy(selectedTaxonomy.value.ref as string, undefined, selectedTaxon.value.id);
      selectedTaxonId.value = undefined;
    } else {
      appStore.projectStore.deleteTaxonFromTaxonomy(selectedTaxonomy.value.ref as string, parent?.id, selectedTaxon.value.id);
      selectedTaxonId.value = undefined;
    }
  }
}

function deleteTaxon(taxon: Taxon) {

  log.info(`Deleting taxon ${taxon.id}`);
  appStore.workspaceStore.addUpdatedTaxonomyRef(selectedTaxonomy.value.ref as string);

  if (selectedTaxon.value && selectedTaxon.value.id === taxon.id) {
    selectedTaxonId.value = undefined;
  }

  // We need to find the parent of the selected taxon
  const parent = appStore.projectStore.findParentTaxonFromTaxons(taxon.id, selectedTaxonomy.value.taxons);
  if (parent?.id === selectedTaxon.value?.id) {
    // We are deleting the root taxon
    appStore.projectStore.deleteTaxonFromTaxonomy(selectedTaxonomy.value.ref as string, undefined, taxon.id);
  } else {
    appStore.projectStore.deleteTaxonFromTaxonomy(selectedTaxonomy.value.ref as string, parent?.id, taxon.id);
  }
}

const isTaggable = computed(() => {
  return selectedTaxon.value && selectedTaxon.value.valuePath !== "METADATA" && selectedTaxon.value.valuePath !== "FORMULA" && !selectedTaxon.value.group;
});
</script>

<template>
  <div class="bg-gray-50">
    <div class="flex flex-col border-r bg-gray-50">
      <div class="mb-2 flex-1 px-2 py-3 pl-4">
        <div v-show="selectedTaxon === undefined" class="flex-1 bg-gray-50">
          <KodexaDropDown
            v-model="taxonomyRef" :items="taxonomies" :loading="projectLoading" class="mt-4"
            name="taxonomies" text-field="name" value-field="ref"
          />
          <div style="overflow-y: scroll !important; height: calc(100vh - 15rem)">
            <TaxonomyTree v-if="selectedTaxonomy" :taxonomy="selectedTaxonomy" @select-taxon="taxonSelected" @delete-taxon="deleteTaxon" />
          </div>
        </div>
        <div v-if="selectedTaxon !== undefined" class="flex-1 bg-gray-50 py-3" style="height: calc(100vh - 12rem); overflow: auto">
          <div>
            <div class="flex">
              <button class="mt-6 text-gray-400 hover:text-gray-500" type="button" @click="returnToTaxonomy">
                <span class="sr-only">Return to Taxonomy</span>
                <MaterialDesignIcon aria-hidden="true" class="ml-2 size-6" name="chevron-left" />
              </button>
              <div class="mx-4 mt-3 ">
                <div class="text-lg">
                  {{ selectedTaxon.label }}
                </div>
                <div class="text-sm text-gray-500">
                  {{ selectedTaxonomy.name }}
                </div>
              </div>
              <div class="absolute right-8 mt-6">
                <MaterialDesignIcon
                  v-if="isTaggable"
                  :style="{
                    color: selectedTaxon.color,
                  }" name="tag" size="24"
                />
              </div>
            </div>
            <TaxonDetails :key="selectedTaxon.id" v-model="selectedTaxon" :taxonomy="selectedTaxonomy" :default-tab="0" @delete="deleteSelected" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
