<script setup lang="ts">
import type { PropType } from "vue";
import type { Message, MessageBlock } from "~/model";

const props = defineProps({
  message: {
    type: Object as PropType<Message>,
    required: true,
  },
  messageBlock: {
    type: Object as PropType<MessageBlock>,
    required: true,
  },
});
</script>

<template>
  <div class="my-2">
    <div v-if="!messageBlock.properties.markdown">
      {{ props.messageBlock?.properties?.text }}
    </div>
    <div v-else>
      <KodexaMarkdown :content="props.messageBlock?.properties?.text" :show-clipboard="true" />
    </div>
  </div>
</template>

<style scoped>
</style>
