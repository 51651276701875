<script setup lang="ts">
import type { PropType } from "vue";
import type { Channel, Message, MessageBlock } from "~/model";

const props = defineProps({
  message: {
    type: Object as PropType<Message>,
    required: true,
  },
  channel: {
    type: Object as PropType<Channel>,
    required: false,
  },
  messageBlock: {
    type: Object as PropType<MessageBlock>,
    required: true,
  },
});
</script>

<template>
  <div>
    <div class="w-100 my-2 ml-1 flex items-center space-x-2">
      <div class="inline-flex size-2 animate-twPing rounded-full bg-sky-400 opacity-75 animate-slower animate-infinite" />
      <div class="font-md ml-3">
        {{ props.messageBlock?.properties?.text }}
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
