<script setup lang="ts">
import type { PropType } from "vue";
import type { Message, MessageBlock } from "~/model";

const props = defineProps({
  message: {
    type: Object as PropType<Message>,
    required: true,
  },
  messageBlock: {
    type: Object as PropType<MessageBlock>,
    required: true,
  },
});

const progressStyle = computed(() => {
  const width = (props.messageBlock.properties.value / props.messageBlock.properties.max) * 100;
  return { width: width + '%' };
});

const percentageComplete = computed(() => {
  return Math.round((props.messageBlock.properties.value / props.messageBlock.properties.max) * 100);
});
</script>

<template>
  <div class="my-2">
    <!-- Display status message if available -->
    <div v-if="messageBlock.properties?.text" class="mb-1">
      {{ messageBlock.properties?.text}}
    </div>
    <!-- Progress Bar Container -->
    <div class="w-full bg-gray-200 rounded-full dark:bg-gray-700">
      <!-- Progress Bar Indicator -->
      <div
        class="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
        :style="progressStyle">
        {{ percentageComplete }}%
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
