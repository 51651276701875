<script setup lang="ts">
import { storeToRefs } from "pinia";
import appStore from "~/store";
import {DatePicker} from "@progress/kendo-vue-dateinputs";
import {customAxios} from "~/api/custom-axios";

const {
  currentOrganization,
} = storeToRefs(appStore.organizationStore);

const supportExpires = computed({
  get: () => {
    return new Date(currentOrganization.value.supportAccessExpiresAt);
  },
  set: (value) => {
    currentOrganization.value.supportAccessExpiresAt = value;
  },
});

const localOrganization = computed(() => {
  return currentOrganization.value;
});

watch(() => localOrganization.value.supportAccess, (newValue) => {
  if (newValue) {
    // Set the support access expires to 3 days in the future
    const date = new Date();
    date.setDate(date.getDate() + 3);
    localOrganization.value.supportAccessExpiresAt = new Date();
  }
});

const today = new Date();

async function openPortal() {
  const portalUrl = await customAxios({
    url: "/api/payments/createCustomerPortalSession/"+currentOrganization.value.id,
    method: "GET",
  });

  // The response is a URL so lets open it in a new tab
  window.open(portalUrl, "_blank");
}
</script>

<template>
  <div>
    <div class="lg:grid lg:grid-cols-12 lg:gap-x-5 ">
      <div class="space-y-6 sm:px-6 lg:col-span-12 lg:px-0">
        <div>

            <div class="space-y-6 bg-white px-4 py-2" style="height: calc(100vh - 250px)">
              <KodexaArticle class="mb-2 mt-7" article-id="8729056" text="Learn more about organizations" :slide="false" />

              <div class="grid grid-cols-12 gap-0">
                <div class="col-span-6">
                  <KodexaTextInput
                    v-model="localOrganization.name"
                    name="organizationName"
                    type="text"
                    autocomplete="project-name"
                    label="Organization Name"
                  />
                </div>
                <div class="col-span-6" />
                <div class="col-span-12">
                  <p class="mt-3 text-sm text-gray-500">
                    Internally this organization uses the identifier @{{ localOrganization.slug }}
                  </p>
                </div>
                <div class="col-span-9 mt-3">
                  <KodexaTextArea
                    v-model="localOrganization.description"
                    name="organizationDescription"
                    type="text"
                    :rows="8"
                    autocomplete="organization-description"
                    label="Organization Description"
                  />
                </div>
                <div class="col-span-9 mt-3">
                  <div class="col-span-9 mt-6">
                    <div class="p-4 border rounded-lg bg-gray-50">
                      <p class="mt-2 text-sm text-gray-500">
                        We use Stripe for billing and subscription management. You can manage your billing information, download invoices, and update your payment method by clicking the button below.
                      </p>
                      <p>
                      <!-- Conditional Date Control for Support Access Expiration -->
                      <KodexaButton type="secondary" @click="openPortal" id="manageBilling" class="my-6" icon="cash-register">
                        Manage Billing
                      </KodexaButton>
                      </p>
                    </div>
                  </div>

                </div>

                <!-- Information Box for Support Access -->
                <div class="col-span-9 mt-6">
                  <div class="p-4 border rounded-lg bg-gray-50">
                    <KodexaCheckbox v-model="localOrganization.supportAccess" name="supportAccess" label="Allow Support Access" />
                    <p class="mt-2 text-sm text-gray-500">
                      When you have enabled support access, Kodexa support resources will be able to see your projects. This access is controlled by the date set below.
                    </p>
                    <!-- Conditional Date Control for Support Access Expiration -->
                    <div v-if="localOrganization.supportAccess" class="mt-4">
                      <label for="accessExpires" class="block text-sm font-medium text-gray-700">Access Expires On</label>
                      <DatePicker id="expiryDate" v-model="supportExpires" :min="today" class="w-40"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
