<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { Checkbox } from "@progress/kendo-vue-inputs";
import type { PropType } from "vue";
import type { DataAttribute, DataObject } from "~/model";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";
import type { TagMetadata } from "~/store/useProject";
import {storeToRefs} from "pinia";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const booleanValue = computed({
  get() {
    return props.attribute.booleanValue;
  },
  set(value) {
    emit("update", {
      ...props.attribute,
      value,
      booleanValue: value,
    });
  },
});

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);
const valid = computed(() => {
  return props.attribute?.dataExceptions?.length === 0;
});

function focus() {
  dataViewHelper.focus();
  emit("focus");
}
const { style, conditionalValidationIcon } = storeToRefs(dataViewHelper);

</script>

<template>
  <div @click="focus" class="flex justify-center mt-1">
    <KodexaCheckbox
      :name="`kdx-attribute-${props.attribute?.uuid}`"
      :id="`kdx-attribute-${props.attribute?.uuid}`"
      v-model="booleanValue"
      :tabindex="props.tabIndex"
      :style="style"
      :disabled="props.dataObject?.documentFamily.locked === true"
      :valid="valid"
      @focus="focus"
      @blur="dataViewHelper.blur()"
    />
  </div>
</template>

<style scoped>
</style>
