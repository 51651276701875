<script setup lang="ts">
import { storeToRefs } from "pinia";
import { filterIcon } from "@progress/kendo-svg-icons";
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { v4 as uuidv4 } from "uuid";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { useListAssistantDefinitions } from "~/api/assistants/assistants";
import { useListStores } from "~/api/stores/stores";
import { useListTaxonomies } from "~/api/taxonomies/taxonomies";
import { createGridHelper } from "~/store/useGridHelper";
import { log } from "~/utils/logger";
import appStore from "~/store";
import { useListDataForms } from "~/api/data-forms/data-forms";
import { useListDashboards } from "~/api/dashboards/dashboards";
import { updateHandler } from "~/utils/error-handler";
import { addAssistant } from "~/api/projects/projects";
import type { Assistant, SlugBasedMetadata } from "~/model";
import KodexaConfirm from "~/components/kodexa-confirm.vue";

const props = defineProps({
  resourceType: String,
  emitOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["close", "add"]);

const reactiveResourceType = ref(props.resourceType);
const reactiveEmitOnly = ref(props.emitOnly);

const { currentOrganization } = storeToRefs(appStore.organizationStore);
const { project, projectDirty } = storeToRefs(appStore.projectStore);

const baseFilter = computed(() => {
  log.info(`Getting base filter for ${reactiveResourceType.value}`);
  if (reactiveResourceType.value === "models") {
    return `(organization.id:'${currentOrganization.value.id}' or organization.slug:'kodexa') and storeType: 'MODEL'`;
  } else if (reactiveResourceType.value === "dataStores") {
    return `(organization.id:'${currentOrganization.value.id}' or organization.slug:'kodexa') and storeType: 'TABLE'`;
  } else if (reactiveResourceType.value === "documentStores") {
    return `(organization.id:'${currentOrganization.value.id}' or organization.slug:'kodexa') and storeType: 'DOCUMENT'`;
  } else {
    return `(organization.id:'${currentOrganization.value.id}' or (organization.slug:'kodexa' and template: true))`;
  }
});

const gridHelper = computed(() => {
  return createGridHelper(`${reactiveResourceType.value}-resource-${currentOrganization.value.slug}-list`, {
    pageSize: 10,
    query: "",
    page: 1,
    filter: "",
    sort: "template:desc",
  }, baseFilter.value);
});

const computedQuery = computed({
  get() {
    return gridHelper.value.query;
  },
  set(value) {
    gridHelper.value.setQuery(value);
  },
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper.value);

const getResourceEndpoint = computed(() => {
  log.info("Getting resource endpoint for", reactiveResourceType.value);
  if (reactiveResourceType.value === "assistants") {
    return useListAssistantDefinitions(gridQuery);
  } else if (reactiveResourceType.value === "documentStores" || reactiveResourceType.value === "dataStores" || reactiveResourceType.value === "models") {
    return useListStores(gridQuery);
  } else if (reactiveResourceType.value === "taxonomies") {
    return useListTaxonomies(gridQuery);
  } else if (reactiveResourceType.value === "dataForms") {
    return useListDataForms(gridQuery);
  } else {
    return useListDashboards(gridQuery);
  }
});

// Query
const {
  isLoading,
  isError,
  data,
  error,
} = getResourceEndpoint.value;

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.value.setTotal(newValue.totalElements);
    gridHelper.value.saveState();
  }
});

const columns = computed(() => {
  if (reactiveResourceType.value === "assistants") {
    return [
      { title: "Name", field: "name", cell: "name" },
      {
        title: " ",
        cell: "actions",
        filterable: false,
        sortable: false,
        width: "150px",
        columnMenu: false,
      },
    ];
  } else {
    return [
      { title: "Name", field: "name", cell: "name" },
      { title: " ", field: "template", cell: "template" },
      {
        title: " ",
        cell: "actions",
        filterable: false,
        sortable: false,
        width: "150px",
        columnMenu: false,
      },
    ];
  }
});

const resourceName = computed(() => {
  if (reactiveResourceType.value === "assistants") {
    return "Assistants";
  } else if (reactiveResourceType.value === "documentStores") {
    return "Document Stores";
  } else if (reactiveResourceType.value === "dataStores") {
    return "Data Stores";
  } else if (reactiveResourceType.value === "models") {
    return "Models";
  } else if (reactiveResourceType.value === "taxonomies") {
    return "Data Definitions";
  } else if (reactiveResourceType.value === "dataForms") {
    return "Data Forms";
  } else if (reactiveResourceType.value === "dashboards") {
    return "Dashboards";
  }
});

async function addResourceFromProps(resource: SlugBasedMetadata) {

  if (reactiveResourceType.value === "assistants") {
    if (projectDirty.value) {
      const confirmLostChanges = createConfirmDialog(KodexaConfirm);
      const result = await confirmLostChanges.reveal({
        icon: "alert-circle-outline",
        title: "Unsaved Changes",
        message: "You have unsaved changes, if you continue they will be lost!",
        notes: "Changes to resources will require the project to reload. Do you want to discard the changes, or stay in the project to save them",
        confirmText: "Discard Changes & Add Assistant",
        confirmIcon: "alert-circle-outline",
        cancelText: "Return to Data Flow",
        cancelIcon: "close",
        type: "danger",
      });

      if (result.isCanceled) {
        return false;
      }
    }
  }

  if (reactiveEmitOnly.value) {
    log.info("Emitting add event for resource");
    emit("add", resource);
    emit("close");
    return;
  }

  if (resource.type === "assistant") {
    const assistant = {
      name: resource.name,
      assistantDefinitionRef: resource.ref,
    } as Assistant;
    await updateHandler(addAssistant(project.value.id, assistant), "Added assistant to project");
  } else {
    if (resource.template) {
      // We need to create our new resource based on this resource
      const newResource = JSON.parse(JSON.stringify(resource));
      delete newResource.template;
      newResource.orgSlug = currentOrganization.value.slug;
      newResource.slug = `${project.value.id}-${resource.type}-${uuidv4()}`;
      newResource.ref = undefined;
      await updateHandler(appStore.projectStore.createResource(newResource, resource), "Created resource in project");
    } else {
      await updateHandler(appStore.projectStore.linkResource(resource), "Linked resource to project");
    }
  }
  appStore.projectStore.reloadProject();
  emit("close");
}
</script>

<template>
  <div class="m-2">
    <Toolbar class="mb-1 border-0 bg-white">
      <label for="query" class="sr-only">Query</label>
      <KodexaTextInput
        id="filterProjects"
        v-model="computedQuery" :show-clear="true" type="text" name="filterProjects"
        class="-ml-1 block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" :placeholder="`Filter ${resourceName}`"
      />
      <Button
        class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()"
      />
      <KodexaArticle class="ml-2 mt-2" article-id="8729166" :text="`Learn more about ${resourceName}`" :slide="true" />
      <ToolbarSpacer />
    </Toolbar>
    <Grid
      class="h-full"
      style="height: 550px"
      :data-items="data?.content || []"
      :pageable="pageSettings"
      :loader="isLoading"
      :sort="sort"
      scrollable="scrollable"
      :skip="pageSettings.skip as number"
      :total="pageSettings.total as number"
      :page-size="pageSettings.take as number"
      :take="pageSettings.take as number"
      :columns="columns"
      :filterable="showFilter"
      :filter="filter"
      :resizable="true"
      :sortable="true"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <GridNoRecords>
        <KodexaGridNoRecords
          :error="error" :is-loading="isLoading" :is-error="isError"
          no-records-message="No resources found"
        />
      </GridNoRecords>
      <template #name="{ props }">
        <td>
          <div class="flex items-center">
            <div class="ml-1">
              <span class="wrap-text text-lg font-bold text-gray-900">{{ props.dataItem.name }}</span>
              <div class="text-xs text-gray-700">
                {{ props.dataItem.description }}
              </div>
            </div>
          </div>
        </td>
      </template>
      <template #template="{ props }">
        <td>
          <span v-if="props.dataItem.template" class="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700">Template</span>
        </td>
      </template>
      <template #actions="{ props }">
        <td>
          <KodexaButton
            id="addResource"
            size="small"
            class="m-4"
            :icon="props.dataItem.template ? 'plus' : 'link'"
            @click="addResourceFromProps(props.dataItem)"
          >
            {{ props.dataItem.template ? "Create" : "Link" }}
          </KodexaButton>
        </td>
      </template>
    </Grid>
  </div>
</template>
