import type { DurationObjectUnits } from "luxon";
import { DateTime, Duration } from "luxon";

export function toHumanDuration(start: DateTime, end: DateTime): string {
  // Better Duration.toHuman support https://github.com/moment/luxon/issues/1134
  const duration = end
    .diff(start)
    .shiftTo("days", "hours", "minutes", "seconds")
    .toObject();

  if ("seconds" in duration && duration.seconds) {
    duration.seconds = Math.round(duration.seconds);
  }

  const cleanedDuration = Object.fromEntries(
    Object.entries(duration).filter(([_k, v]) => v !== 0),
  ) as DurationObjectUnits;

  if (Object.keys(cleanedDuration).length === 0) {
    cleanedDuration.seconds = 0;
  }

  return Duration.fromObject(cleanedDuration).toHuman();
}

export function parseLocalDate(dateString: string | Date): DateTime {
  // if the date is a date object, convert it to a string
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  let stringDate;
  if (dateString instanceof Date) {
    stringDate = dateString.toISOString();
  } else {
    stringDate = dateString;
  }
  // take the input format 2023-05-01T00:00:00.000Z and build a date object
  const dateObject = {
    year: Number.parseInt(stringDate.substring(0, 4)),
    month: Number.parseInt(stringDate.substring(5, 7)),
    day: Number.parseInt(stringDate.substring(8, 10)),
  };

  return DateTime.fromObject(dateObject, { zone: timeZone });
}

export function parseLocalDateTime(dateString: string | Date): DateTime {
  let stringDate;
  if (dateString instanceof Date) {
    stringDate = dateString.toISOString();
  } else {
    stringDate = dateString;
  }
  // take the input format 2023-05-01T00:00:00.000Z and build a date object
  const dateObject = {
    year: Number.parseInt(stringDate.substring(0, 4)),
    month: Number.parseInt(stringDate.substring(5, 7)),
    day: Number.parseInt(stringDate.substring(8, 10)),
    hour: Number.parseInt(stringDate.substring(11, 13)),
    minute: Number.parseInt(stringDate.substring(14, 16)),
    second: Number.parseInt(stringDate.substring(17, 19)),
  };

  return DateTime.fromObject(dateObject, { zone: "utc" });
}
