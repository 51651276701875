<script lang="ts" setup>
import {Combobox, ComboboxOption, ComboboxOptions} from "@headlessui/vue";
import {storeToRefs} from "pinia";
import appStore from "~/store";
import type {Guidance, GuidanceSet} from "~/model";

defineProps({
  noClose: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { user } = storeToRefs(appStore.userStore);

const {
  guidance,
} = storeToRefs(appStore.projectStore);

function reload() {
  appStore.projectStore.reloadGuidance();
}

const selectedGuidance = ref<Guidance | null>(null);
const showGuidanceEditor = ref(false);
const selectedGuidanceSet = ref<GuidanceSet | null>(null);

function editGuidance(guidanceInstance: Guidance, guidanceSet: GuidanceSet) {
  selectedGuidance.value = guidanceInstance;
  selectedGuidanceSet.value = guidanceSet;
  showGuidanceEditor.value = true;
}
</script>

<template>
  <div>
    <div class="flex flex-col border-r bg-gray-50">
      <div class="mb-2 flex-1 bg-gray-50 px-4 py-3">
        <Combobox>
          <div class="mt-1 flex w-full">
            <div class="flex-1 bg-gray-50" style="height: calc(100vh - 15rem); overflow: auto">
              <KodexaButton icon="refresh" size="small" type="secondary" @click="reload">
                Reload
              </KodexaButton>
              <span class="ml-2">{{ Array.from(guidance.values()).length }} available guidance set</span>

              <ComboboxOptions
                v-if="guidance" class="scroll-py-10 scroll-pb-2 space-y-4 p-4 pb-2"
                static
              >
                <li>
                  <ul class="-mx-4 text-sm text-gray-700">
                    <ComboboxOption
                      v-for="guidanceSet in Array.from(guidance.values())" :key="guidanceSet.ref"
                    >
                      <p class="text-xl font-semibold mb-1">{{ guidanceSet.name }}</p>
                      <p class="text-xs text-gray-600 mb-1" v-if="user.showDeveloperTools">{{ guidanceSet.ref }}</p>
                      <KodexaButton
                        icon="plus" size="small" type="secondary"
                        @click="appStore.projectStore.addGuidance(guidanceSet)"
                      >
                        Add Guidance to Set
                      </KodexaButton>
                      <div
                        v-for="(guidanceInstance, idx) in guidanceSet.guidance" :key="guidanceInstance.id"
                        class="mx-1 mt-2"
                      >
                        <div class="guidance-container">
                          <p class="mb-1 mt-2 font-medium">{{ guidanceInstance.name || 'Unknown Guidance' }}</p>
                          <div class="buttons-container">
                            <KodexaButton
                              icon="pencil" size="small" type="secondary"
                              @click="editGuidance(guidanceInstance, guidanceSet)"
                            >
                              Edit
                            </KodexaButton>
                            <KodexaButton
                              icon="delete" size="small" type="danger" class="ml-1"
                              @click="appStore.projectStore.deleteGuidanceInstance(guidanceInstance, guidanceSet.ref)"
                            >
                              Delete
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                      <div>
                        <hr class="my-3 h-px border-0 bg-gray-200 dark:bg-gray-700">
                      </div>
                    </ComboboxOption>
                  </ul>
                </li>
              </ComboboxOptions>
            </div>
          </div>
        </Combobox>
      </div>
    </div>
    <KodexaGuidancePopup
      v-if="showGuidanceEditor" v-model="showGuidanceEditor" :guidance="selectedGuidance"
      :guidance-set="selectedGuidanceSet"
    />
  </div>
</template>

<style scoped>
.guidance-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons-container {
  display: flex;
  align-items: center;
}
</style>
