// Generated from resources/KodexaFormula.g4 by ANTLR 4.13.1

import { AbstractParseTreeVisitor } from "antlr4ng";


import { FormulaContext } from "./KodexaFormulaParser.js";
import { AddContext } from "./KodexaFormulaParser.js";
import { AttributePropContext } from "./KodexaFormulaParser.js";
import { SubContext } from "./KodexaFormulaParser.js";
import { NotEqualContext } from "./KodexaFormulaParser.js";
import { AttributeRefContext } from "./KodexaFormulaParser.js";
import { MulContext } from "./KodexaFormulaParser.js";
import { ParensContext } from "./KodexaFormulaParser.js";
import { GreaterThanEqualContext } from "./KodexaFormulaParser.js";
import { PositiveOrNegativeNumberContext } from "./KodexaFormulaParser.js";
import { UnaryMinusContext } from "./KodexaFormulaParser.js";
import { LessThanEqualContext } from "./KodexaFormulaParser.js";
import { LogicalOrContext } from "./KodexaFormulaParser.js";
import { FalseLiteralContext } from "./KodexaFormulaParser.js";
import { LogicalNotContext } from "./KodexaFormulaParser.js";
import { DivContext } from "./KodexaFormulaParser.js";
import { FunctionContext } from "./KodexaFormulaParser.js";
import { LessThanContext } from "./KodexaFormulaParser.js";
import { ArrayAccessContext } from "./KodexaFormulaParser.js";
import { StringLiteralContext } from "./KodexaFormulaParser.js";
import { TrueLiteralContext } from "./KodexaFormulaParser.js";
import { ArrayLitContext } from "./KodexaFormulaParser.js";
import { GreaterThanContext } from "./KodexaFormulaParser.js";
import { EqualContext } from "./KodexaFormulaParser.js";
import { LogicalAndContext } from "./KodexaFormulaParser.js";
import { PositiveNumberContext } from "./KodexaFormulaParser.js";
import { NegativeNumberContext } from "./KodexaFormulaParser.js";
import { ArrayLiteralContext } from "./KodexaFormulaParser.js";
import { ArgumentListContext } from "./KodexaFormulaParser.js";
import { FunctionCallContext } from "./KodexaFormulaParser.js";
import { AttributeReferenceContext } from "./KodexaFormulaParser.js";
import { AttributePropertyContext } from "./KodexaFormulaParser.js";


/**
 * This interface defines a complete generic visitor for a parse tree produced
 * by `KodexaFormulaParser`.
 *
 * @param <Result> The return type of the visit operation. Use `void` for
 * operations with no return type.
 */
export class KodexaFormulaVisitor<Result> extends AbstractParseTreeVisitor<Result> {
    /**
     * Visit a parse tree produced by `KodexaFormulaParser.formula`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitFormula?: (ctx: FormulaContext) => Result;
    /**
     * Visit a parse tree produced by the `Add`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitAdd?: (ctx: AddContext) => Result;
    /**
     * Visit a parse tree produced by the `AttributeProp`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitAttributeProp?: (ctx: AttributePropContext) => Result;
    /**
     * Visit a parse tree produced by the `Sub`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitSub?: (ctx: SubContext) => Result;
    /**
     * Visit a parse tree produced by the `NotEqual`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitNotEqual?: (ctx: NotEqualContext) => Result;
    /**
     * Visit a parse tree produced by the `AttributeRef`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitAttributeRef?: (ctx: AttributeRefContext) => Result;
    /**
     * Visit a parse tree produced by the `Mul`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitMul?: (ctx: MulContext) => Result;
    /**
     * Visit a parse tree produced by the `Parens`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitParens?: (ctx: ParensContext) => Result;
    /**
     * Visit a parse tree produced by the `GreaterThanEqual`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitGreaterThanEqual?: (ctx: GreaterThanEqualContext) => Result;
    /**
     * Visit a parse tree produced by the `PositiveOrNegativeNumber`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitPositiveOrNegativeNumber?: (ctx: PositiveOrNegativeNumberContext) => Result;
    /**
     * Visit a parse tree produced by the `UnaryMinus`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitUnaryMinus?: (ctx: UnaryMinusContext) => Result;
    /**
     * Visit a parse tree produced by the `LessThanEqual`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitLessThanEqual?: (ctx: LessThanEqualContext) => Result;
    /**
     * Visit a parse tree produced by the `LogicalOr`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitLogicalOr?: (ctx: LogicalOrContext) => Result;
    /**
     * Visit a parse tree produced by the `FalseLiteral`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitFalseLiteral?: (ctx: FalseLiteralContext) => Result;
    /**
     * Visit a parse tree produced by the `LogicalNot`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitLogicalNot?: (ctx: LogicalNotContext) => Result;
    /**
     * Visit a parse tree produced by the `Div`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitDiv?: (ctx: DivContext) => Result;
    /**
     * Visit a parse tree produced by the `Function`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitFunction?: (ctx: FunctionContext) => Result;
    /**
     * Visit a parse tree produced by the `LessThan`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitLessThan?: (ctx: LessThanContext) => Result;
    /**
     * Visit a parse tree produced by the `ArrayAccess`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitArrayAccess?: (ctx: ArrayAccessContext) => Result;
    /**
     * Visit a parse tree produced by the `StringLiteral`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitStringLiteral?: (ctx: StringLiteralContext) => Result;
    /**
     * Visit a parse tree produced by the `TrueLiteral`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitTrueLiteral?: (ctx: TrueLiteralContext) => Result;
    /**
     * Visit a parse tree produced by the `ArrayLit`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitArrayLit?: (ctx: ArrayLitContext) => Result;
    /**
     * Visit a parse tree produced by the `GreaterThan`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitGreaterThan?: (ctx: GreaterThanContext) => Result;
    /**
     * Visit a parse tree produced by the `Equal`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitEqual?: (ctx: EqualContext) => Result;
    /**
     * Visit a parse tree produced by the `LogicalAnd`
     * labeled alternative in `KodexaFormulaParser.expression`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitLogicalAnd?: (ctx: LogicalAndContext) => Result;
    /**
     * Visit a parse tree produced by the `PositiveNumber`
     * labeled alternative in `KodexaFormulaParser.number`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitPositiveNumber?: (ctx: PositiveNumberContext) => Result;
    /**
     * Visit a parse tree produced by the `NegativeNumber`
     * labeled alternative in `KodexaFormulaParser.number`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitNegativeNumber?: (ctx: NegativeNumberContext) => Result;
    /**
     * Visit a parse tree produced by `KodexaFormulaParser.arrayLiteral`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitArrayLiteral?: (ctx: ArrayLiteralContext) => Result;
    /**
     * Visit a parse tree produced by `KodexaFormulaParser.argumentList`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitArgumentList?: (ctx: ArgumentListContext) => Result;
    /**
     * Visit a parse tree produced by `KodexaFormulaParser.functionCall`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitFunctionCall?: (ctx: FunctionCallContext) => Result;
    /**
     * Visit a parse tree produced by `KodexaFormulaParser.attributeReference`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitAttributeReference?: (ctx: AttributeReferenceContext) => Result;
    /**
     * Visit a parse tree produced by `KodexaFormulaParser.attributeProperty`.
     * @param ctx the parse tree
     * @return the visitor result
     */
    visitAttributeProperty?: (ctx: AttributePropertyContext) => Result;
}

