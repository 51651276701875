<script setup lang="ts">
import type { PropType, Ref } from "vue";
import { Grid } from "@progress/kendo-vue-grid";
import appStore from "~/store";
import { storeToRefs } from "pinia";
import { log } from "~/utils/logger";
import { Column, QueryRequest, QueryResponse, useLlmQuery } from "~/store/useQuery";
import { DashboardWidget, Store } from "~/model";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
  refresher: {
    type: Number,
    required: false,
    default: 1,
  },
});

const emit = defineEmits(["update-widget", "update-properties"]);

const loading = ref(false);
const data = ref(undefined) as Ref<QueryResponse | undefined>;
const error = ref(undefined);
const loadingMessage = ref("Analyzing Data Store");
const columns = ref([]) as Ref<Array<Column>>;
const responseMessage = ref("");

const {currentOrganization} = storeToRefs(appStore.organizationStore);

const {contentTaxonomies, dataStores} = storeToRefs(appStore.projectStore);

const pageSettings = ref({
  pageSize: 10,
  skip: 0,
  pageSizes: [5, 10, 20],
});

const firstTime = ref(true);
const lastQuery = ref("");

watch(() => props.refresher, () => {

  if (!props.widget?.properties?.query) {
    log.warn("No query set for widget")
    return;
  }

  loading.value = true;
  const query = props.widget?.properties?.query as string;
  let hql = props.widget?.properties?.hql as string;
  if (firstTime.value) {
    lastQuery.value = query;
  }

  if (lastQuery.value !== query) {
    log.info("Query has changed, clearing hql");
    const widgetCopy = JSON.parse(JSON.stringify(props.widget));
    widgetCopy.properties.hql = ""
    hql = "";
    emit("update-properties", widgetCopy.properties);
    lastQuery.value = query;
  }

  firstTime.value = false;

  const platformQuery = {
    queryType: "NATURAL_LANGUAGE",
    query: query,
  } as QueryRequest;

  if (hql && hql !== "") {
    platformQuery.query = hql;
    platformQuery.queryType = "HQL";
  }

  const dataSource = props.widget?.properties?.dataSource as string;

  if (dataSource === "extracted") {
    useLlmQuery().dataStoreQuery(dataStores.value[0] as Store, platformQuery).then((result: QueryResponse) => {
      data.value = result;
      columns.value = result.columns;
      const widgetCopy = JSON.parse(JSON.stringify(props.widget));
      widgetCopy.properties.columns = result.columns;
      widgetCopy.properties.hql = result.hql;
      emit("update-properties", widgetCopy.properties);
      loading.value = false;
    }).catch((e) => {
      console.log("Got error", e);
      error.value = e;
      loading.value = false;
    });
  }

  if (dataSource === "organization") {
    useLlmQuery().organizationQuery(platformQuery).then((result: QueryResponse) => {
      data.value = result;
      columns.value = result.columns;
      const widgetCopy = JSON.parse(JSON.stringify(props.widget));
      widgetCopy.properties.columns = result.columns;
      widgetCopy.properties.hql = result.hql;
      emit("update-properties", widgetCopy.properties);
      loading.value = false;
    }).catch((e) => {
      error.value = e;
      loading.value = false;
    });
  }

}, {
  immediate: true,
});

const gridStyle = computed(() => {
  if (props.widget?.properties?.style !== undefined) {
    return props.widget?.properties?.style;
  } else {
    const rowSpan = props.widget?.properties?.defaultPosition.rowSpan || 6;
    const height = (rowSpan * 85) - 130;
    return {
      "height": `${height}px`,
    };
  }
});
</script>

<template>
  <div v-if="loading">
    <div class="text-center">
      <KodexaSmallLoader class="mb-6 mt-10"/>
      <h2 class="text-base font-semibold leading-6 text-gray-900">
        {{ loadingMessage }}
      </h2>
      <p class="mt-1 px-10 text-sm text-gray-500">
        Please wait...
      </p>
    </div>
  </div>
  <div v-else-if="!loading && !error && widget.properties.query" class="mx-2" style="height: 320px">
    <div class="text-center">
      <h2 class="text-base font-semibold text-gray-900">
        {{ widget?.properties?.title || 'No Title' }}
      </h2>
    </div>

    <Grid v-if="columns?.length > 0"
          :columns="Array.from(columns.values())"
          :style="gridStyle"
          :loader="loading"
          :total="data?.totalElements" :data-items="data?.content" :resizable="true" :sortable="true" class="mt-4">
    </Grid>
    <div v-else class="mt-10">
      <div class="text-center">
        <MaterialDesignIcon name="database-alert-outline" class="mb-6 mt-10 h-12 w-12 text-gray-400" aria-hidden="true"/>
        <h2 class="text-base font-semibold leading-6 text-gray-700">
          The query returned no results
        </h2>
        <p class="mt-1 px-10 text-sm text-gray-500">
          {{ responseMessage }}
        </p>
      </div>
    </div>
  </div>
  <div v-else-if="error" class="flex items-center justify-center">
    <div class="text-center">
      <MaterialDesignIcon name="information" class="mb-6 mt-10 h-12 w-12 text-gray-400" aria-hidden="true"/>
      <h2 class="text-base font-semibold leading-6 text-gray-900">
        An Error Occurred
      </h2>
      <p class="mt-1 px-10 text-sm text-gray-500">
        {{ error }}
      </p>
    </div>
  </div>
  <div v-else class="flex items-center justify-center">
    <div class="text-center">
      <MaterialDesignIcon name="information" size="18" class="mb-6 mt-10 text-gray-400" aria-hidden="true"/>
      <h2 class="text-base font-semibold leading-6 text-gray-900">
        Missing Set-up
      </h2>
      <p class="mt-1 px-10 text-sm text-gray-500">
        Configure this widget by setting the query
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>
