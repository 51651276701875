<script lang="ts" setup>
import type { PropType } from "vue";
import type { Node } from "@vue-flow/core";
import { Handle, Position } from "@vue-flow/core";
import { getColorByBgColor } from "~/utils/colors";
import KodexaGraphDefault from "~/components/processingGraph/presentations/kodexa-graph-default.vue";
import KodexaGraphPrompt from "~/components/processingGraph/presentations/kodexa-graph-prompt.vue";
import KodexaGraphJson from "~/components/processingGraph/presentations/kodexa-graph-json.vue";
import KodexaGraphResponse from "~/components/processingGraph/presentations/kodexa-graph-response.vue";
import KodexaGraphPagenumbers from "~/components/processingGraph/presentations/kodexa-graph-pagenumbers.vue";
import KodexaGraphStatus from "~/components/processingGraph/presentations/kodexa-graph-status.vue";
import KodexaGraphMessage from "~/components/processingGraph/presentations/kodexa-graph-message.vue";

const props = defineProps({
  node: {
    type: Object as PropType<Node>,
  },
  showRaw: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["selected", "selectedStep", "selected-step"]);

const presentationComponents = {
  default: KodexaGraphDefault,
  prompt: KodexaGraphPrompt,
  schema: KodexaGraphJson,
  response: KodexaGraphResponse,
  llm_response: KodexaGraphResponse,
  page_numbers: KodexaGraphPagenumbers,
  final_llm_response: KodexaGraphJson,
  external_data: KodexaGraphJson,
};

const data = computed(() => {
  // Filter the label out of the node data
  const { label, ...rest } = props.node?.data.clean;

  // Make sure that all the values in rest are strings
  for (const key in rest) {
    if (typeof rest[key] === "undefined" || rest[key] === null) {
      continue;
    }

    if (!(key in presentationComponents) && !props.showRaw) {
      delete rest[key];
      continue;
    }

    if (typeof rest[key] !== "string") {
      rest[key] = JSON.stringify(rest[key]);
    }
  }
  return rest;
});
</script>

<template>
  <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
    <li :key="node.id" class="overflow-hidden rounded-xl border border-gray-200 w-full" style="width: 380px">
      <div
        class="flex items-center gap-x-4 border-b border-gray-900/5 p-6"
        :style="{ backgroundColor: node.data?.raw?.color || '#f8fafc', color: getColorByBgColor(node.data?.raw?.color || '#f8fafc') }"
      >
        <div class="text-sm font-medium leading-6 text-gray-900">
          <h3 class="break-words" style="overflow-wrap: break-word; word-wrap: break-word;" :style="{ color: getColorByBgColor(node.data?.raw?.color || '#f8fafc') }">
            {{ node?.data.clean.label }}
          </h3>
          <p class="truncate text-sm break-words" style="overflow-wrap: break-word; word-wrap: break-word;" :style="{ color: getColorByBgColor(node.data?.raw?.color || '#f8fafc') }">
            {{ node?.data.clean.subtitle }}
          </p>
        </div>
      </div>
      <div class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
        <KodexaGraphStatus v-if="node.data?.raw && 'status' in node.data?.raw" :node="node" />
        <KodexaGraphMessage v-if="node.data?.raw && 'message' in node.data?.raw" :node="node" />
        <div v-for="(value, key) in data" :key="key" class="flex justify-between gap-x-4 py-3">
          <dd class="flex items-start gap-x-2">
            <Component :is="presentationComponents[node?.data?.presentationMetadata?.key || key] || KodexaGraphDefault" :key-name="key" :node="node" />
            <KodexaGraphDefault v-if="props.showRaw" :key-name="key" :node="node" />
          </dd>
        </div>
      </div>
    </li>
  </ul>
  <Handle id="consumes" type="target" :position="Position.Left" />
  <Handle id="publishes" type="source" :position="Position.Right" />
</template>

<style scoped>

</style>
