<script lang="ts" setup>
import { Combobox, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import type { DataForm } from "~/model";
import appStore from "~/store";
import {stringify} from "yaml";
import {language} from "../../../../../../../dist/assets/swift-j9Ctmaa7";

defineEmits(["closed"]);

const { project, allDataForms } = storeToRefs(appStore.projectStore);
const { user } = storeToRefs(appStore.userStore);

const rawQuery = ref("");
const isLoading = ref(false);

const filteredDataForms = computed(() => {
  return allDataForms.value.filter((df: DataForm) => {
    return df.name.toLowerCase().includes(rawQuery.value.toLowerCase());
  });
});

function openDataForm(dataForm: DataForm) {
  appStore.workspaceStore.addDataForm(dataForm);
}

function editDataForm(dataForm: DataForm) {
  // Convert the data form to YAML and open it in a text viewer
  const yamlString = stringify(dataForm);
  appStore.workspaceStore.createTextViewer(`Data Form: ${dataForm.name}`, yamlString, undefined, "yaml", dataForm.ref);
}
</script>

<template>
  <div>
    <div class="flex flex-col border-r bg-gray-50">
      <div class="mb-2 flex-1 px-4 py-3">
        <Combobox>
          <div class="mt-4 bg-gray-50">
            <KodexaTextInput
              v-model="rawQuery"
              name="filter"
              placeholder="Filter..."
            />
          </div>

          <div class="flex-1 bg-gray-50 py-3" style="height: calc(100vh - 15rem); overflow: auto">
            <KodexaArticle article-id="9118450" text="Learn more about Data Forms" :slide="false" />
            <p v-if="!isLoading" class="mx-1 mt-3">
              Found {{ filteredDataForms.length }} forms
            </p>
            <KodexaSmallLoader v-if="isLoading" class="mt-6" />
            <ComboboxOptions
              class="scroll-py-10 scroll-pb-2 space-y-4 p-4 pb-2"
              static
              style="height: calc(100vh - 270px);"
            >
              <li v-if="filteredDataForms.length > 0">
                <ul class="-mx-4 text-sm text-gray-700">
                  <ComboboxOption
                    v-for="filteredDataForm in filteredDataForms" :key="filteredDataForm.ref" v-slot="{ active }"
                    as="template"
                  >
                    <li
                      :class="[active && 'bg-gray-100']" class="flex cursor-default select-none rounded-xl p-3"
                    >
                      <div
                        class="flex size-10 flex-none items-center justify-center rounded-lg bg-blue-400 text-white"
                      >
                        <MaterialDesignIcon name="formTextbox" size="32" @click="openDataForm(filteredDataForm)" />
                      </div>
                      <div class="ml-4 flex-auto">
                        <p :class="[active ? 'text-gray-900' : 'text-gray-700']" class="text-sm font-medium" @click="openDataForm(filteredDataForm)">
                          {{ filteredDataForm.name }}
                        </p>
                        <p :class="[active ? 'text-gray-700' : 'text-gray-500']" class="text-sm mt-2" @click="openDataForm(filteredDataForm)">
                          {{ filteredDataForm.description }}
                        </p>
                        <p v-if="user.showDeveloperTools" class="text-xs text-gray-500">
                          <KodexaButton
                            class="mt-2"
                            icon="pencil"
                            size="small"
                            type="secondary"
                            @click="editDataForm(filteredDataForm)"
                          >
                            Edit
                          </KodexaButton>
                        </p>
                      </div>
                    </li>
                  </ComboboxOption>
                </ul>
              </li>
            </ComboboxOptions>

            <div v-if="rawQuery === '?'" class="px-6 py-14 text-center text-sm sm:px-14">
              <MaterialDesignIcon aria-hidden="true" class="size-5 text-gray-400" name="help" />
              <p class="mt-4 font-semibold text-gray-900">
                Help with Data Forms
              </p>
              <p class="mt-2 text-gray-500">
                Use this tool to quickly search for documents in any of the stores that are associated with the project
                workspace you are currently in.
              </p>
            </div>

            <div
              v-if="rawQuery !== '' && rawQuery !== '?' && !isLoading && data.content.length === 0"
              class="px-6 py-14 text-center text-sm sm:px-14"
            >
              <MaterialDesignIcon aria-hidden="true" class="mx-auto size-6 text-gray-400" name="alertBox" />
              <p class="mt-4 font-semibold text-gray-900">
                No data forms found
              </p>
              <p class="mt-2 text-gray-500">
                We could not find anything with that term. Please try again.
              </p>
            </div>
          </div>
        </Combobox>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
