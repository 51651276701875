<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option } from "~/model";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const itemValue = computed({
  get: () => {
    const wrappedValues = props.modelValue !== null && props.modelValue !== undefined && props.modelValue[props.item.name] !== undefined && props.modelValue[props.item.name] !== null ? props.modelValue[props.item.name] : [];
    return wrappedValues.map((value: any) => {
      const wrapper = {} as any;
      if (value === "") {
        value = [];
      }
      wrapper[props.item.name] = value;
      return wrapper;
    });
  },
  set: () => {
    // Do nothing
  },
});

function emitArrayUpdate(itemCopy: any) {
  const newValue = { ...props.modelValue };
  newValue[props.item.name] = itemCopy.map((val: any) => val ? val[props.item.name] : undefined);
  newValue[props.item.name].forEach((v: any, idx: number) => {
    // Note that we can end up with strings in lists and primitives
    // so we only want to set the idx if it is an object
    if (v !== null && typeof v === "object") {
      v.idx = idx;
    }
  });
  emit("update:modelValue", newValue);
}

const move = function (arr: any[], old_index: number | string, new_index: number | string): any[] {
  new_index = Number.parseInt(new_index as string, 10);
  old_index = Number.parseInt(old_index as string, 10);
  while (old_index < 0) {
    old_index += arr.length;
  }
  while (new_index < 0) {
    new_index += arr.length;
  }
  if (new_index >= arr.length) {
    let k = new_index - arr.length;
    while ((k--) + 1) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

const stepUp = function (index: number | string) {
  index = Number.parseInt(index as string, 10);
  const itemCopy = [...itemValue.value];
  move(itemCopy, index, index - 1);
  emitArrayUpdate(itemCopy);
};

const stepDown = function (index: number | string) {
  index = Number.parseInt(index as string, 10);
  const itemCopy = [...itemValue.value];
  move(itemCopy, index, index + 1);
  emitArrayUpdate(itemCopy);
};

const updateValue = function (index: string | number, value: any) {
  const itemCopy = [...itemValue.value];
  if (value && value[props.item.name]) {
    itemCopy[index] = value;
    emitArrayUpdate(itemCopy);
  }
};

const addValue = function () {
  const itemCopy = [...itemValue.value];
  const newEntry = {} as any;
  newEntry[props.item.name] = "";
  itemCopy.push(newEntry);
  emitArrayUpdate(itemCopy);
};

const removeByIndex = function (index: number) {
  const itemCopy = [...itemValue.value];
  itemCopy.splice(index, 1);
  emitArrayUpdate(itemCopy);
};

const listItem = computed(() => {
  const listItem = { ...props.item } as Option;

  if ("showIf" in listItem) {
    delete listItem.showIf;
  }

  if (props.item.listType) {
    listItem.type = props.item.listType;
  }
  delete listItem.listType;
  return listItem;
});
</script>

<template>
  <div v-if="item">
    <div><strong>{{ item.listLabel ? item.listLabel : item.label }}</strong></div>
    <div>{{ item.listDescription ? item.listDescription : item.description }}</div>
    <div style="float:right; margin-top: -27px">
      <MaterialDesignIcon name="plus" size="16" color="blue" class="mt-2" @click="addValue" />
    </div>
    <div v-for="(entryValue, index) in itemValue" :key="index" class="mt-1" style="width: 100%">
      <!-- We will want to have a general way to handle a list - this means - use the normal component - with an add / remove and a way of rendering the values -->
      <div style="float: right; margin-top: -16px">
        <MaterialDesignIcon
          v-if="index > 0"
          v-tooltip="'Move Up'" name="upArrow" size="16" color="blue" class="mt-2"
          @click="stepUp(index)"
        />
        <MaterialDesignIcon
          v-if="index + 1 < itemValue.length"
          v-tooltip="'Move Down'" name="downArrow" size="16" color="blue" class="mt-2"
          @click="stepDown(index)"
        />

        <MaterialDesignIcon v-tooltip="'Delete'" name="delete" color="red" class="text-red-600" size="16" tooltip="Delete" style="width: 20px;" @click="removeByIndex(index)" />
      </div>

      <div class="mt-8" />
      <ConfigurationOption
        v-model="itemValue[index]" :content-object="contentObject" :errors="errors" :item="listItem"
        :assistant="assistant" style="margin-top:-15px"
        :disabled="disabled" @update:model-value="updateValue(index, $event)"
      />
    </div>
  </div>
</template>

<style scoped>

</style>
