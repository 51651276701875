<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

const props = defineProps({
  exceptionDetails: {
    type: Object,
    required: true,
  },
});
const emit = defineEmits(["confirm", "cancel"]);
const open = ref(true);
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 950px; height: 720px"
              class="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  @click="open = false"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" @click="emit('cancel')" />
                </button>
              </div>
              <DialogTitle class="flex text-lg font-medium leading-6 text-gray-900">
                <MaterialDesignIcon name="alertBox" class="size-6 text-red-400" aria-hidden="true" />
                <span class="ml-2">{{ exceptionDetails.message }}</span>
              </DialogTitle>
              <div class="mt-3 text-sm sm:flex sm:items-start">
                {{ exceptionDetails.errorMessage }}
              </div>
              <div v-if="exceptionDetails && exceptionDetails.help" class="mt-5">
                <KodexaCodeEditor v-model="exceptionDetails.help" :options="{ readOnly: true, language: 'python' }" style="width: 100%; height: 600px" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
