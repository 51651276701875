<script lang="ts" setup>
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { Handle, Position } from "@vue-flow/core";
import appStore from "~/store";
import type { Assistant, DataFlowNode } from "~/model";
import { log } from "~/utils/logger";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["selected", "selectedStep", "selected-step"]);

const { assistants } = storeToRefs(appStore.projectStore);

const assistant = computed(() => {
  return assistants.value.find((assistant: Assistant) => props.node && assistant.id === props.node.id?.split("//")[1]);
});

const { user } = storeToRefs(appStore.userStore);

const {
  modelStores,
  modelStoreMap,
} = storeToRefs(appStore.projectStore);

const pipelineSteps = computed(() => {
  // Does the assistant have a pipeline - if so we need to add all the nodes in from the pipeline and
  // the relationships from their options

  if (assistant.value?.definition?.options) {
    const pipeline = assistant.value.definition.options.find(option => option.type === "pipeline");
    if (pipeline && assistant.value.options[pipeline.name]) {
      if ("steps" in assistant.value.options[pipeline.name]) {
        const steps: any[] = [];
        assistant.value.options[pipeline.name].steps.forEach((step) => {
          if (step.ref) {
            const model = modelStoreMap.value.get(step.ref);
            if (model) {
              steps.push({
                ref: model.ref,
                imageUrl: model.imageUrl ? model.imageUrl.replace("https://images.kodexa.com", "/assets") : "/assets/ai-brain.png",
                model,
                options: step.options,
              });
            }
          }
        });
        return steps;
      }
    } else {
      return [];
    }
  }
  return [];
});

const showAddModel = ref(false);

async function addModel(model) {
  log.info("Adding model");
  if (assistant.value?.definition?.options) {
    const pipeline = assistant.value.definition.options.find(option => option.type === "pipeline");
    if (pipeline && assistant.value.options[pipeline.name]) {
      log.info("Found pipeline");
      if ("steps" in assistant.value.options[pipeline.name]) {
        log.info("Found steps");
        await appStore.projectStore.loadModel(model.ref, assistant.value);
        assistant.value.options[pipeline.name].steps.push({
          ref: model.ref,
          options: {},
          stepType: "MODEL",
        });
      }
    }
    appStore.projectStore.addToAssistantsToUpdate(assistant.value);
  }
}
</script>

<template>
  <div @click="emit('selected')">
    <img
      style="float:left" class="ml-2 mr-3 h-16 rounded-md"
      :src="assistant?.definition.imageUrl || '/assets/ai-brain.png'" alt=""
    >
    <div class="px-4 pt-2 text-gray-700" style="width: 250px">
      {{ assistant?.name || 'Assistant' }}
    </div>
    <div class="px-2 text-xs text-gray-500" style="width: 250px">
      Assistant
    </div>
  </div>
  <div v-if="pipelineSteps !== undefined" class="mt-4 px-2">
    <ol class="items-center sm:flex">
      <li v-for="(step, idx) in pipelineSteps" :key="idx" class="relative mb-6 sm:mb-0">
        <div class="flex items-center" @click="emit('selected-step', step, idx, assistant)">
          <VMenu>
            <div class="z-10 flex h-6 w-6 shrink-0 items-center justify-center rounded-full text-blue-700">
              <MaterialDesignIcon name="creation" size="18" />
            </div>
            <template #popper>
              <div class="w-96 p-2">
                <div class="text-base font-semibold text-gray-900">
                  <div v-tooltip="step.model.ref" class="focus:outline-none">
                    <!-- Extend touch target to entire panel -->
                    <span class="absolute inset-0" aria-hidden="true" />
                    {{ step.model.name }}
                  </div>
                </div>
                <p v-if="user.showDeveloperTools" class="text-sm text-gray-500">
                  {{ step.model.ref }}
                </p>
                <p class="mt-2 text-sm text-gray-500">
                  {{
                    step.model.description
                  }}
                </p>
                <div class="" />
              </div>
            </template>
          </VMenu>
          <div v-if="idx < step.length - 1" class="hidden h-0.5 w-full bg-gray-200 dark:bg-gray-700 sm:flex" />
        </div>
      </li>
      <li class="relative mb-6 ml-2 sm:mb-0">
        <MaterialDesignIcon v-tooltip="`Add new step`" name="plus" size="18" @click="showAddModel = true" />
        <ProjectAddModel v-model="showAddModel" @close="showAddModel = false" @add="addModel" />
      </li>
    </ol>
  </div>
  <Handle id="resourceConnection" type="source" :position="Position.Left" />
  <Handle id="publishes" type="source" :position="Position.Bottom" />
  <Handle id="consumes" type="target" :position="Position.Top" />
</template>

<style scoped>

</style>
