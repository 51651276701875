import { notify } from "notiwind";
import { defineStore } from "pinia";
import type { Ref } from "vue";
import { KddbDocument } from "~/components/document/document";
import appStore from "~/store/index";
import { log } from "~/utils/logger";
import type {
  Execution,
  Session,
} from "~/model";
import {
  ExecutionStatus,
} from "~/model";
import { getExecution } from "~/api/executions/executions";
import { getExecutionContentObject } from "~/api/sessions/sessions";
import { customAxios } from "~/api/custom-axios";

export function createExecutionStore(newExecutionId: string) {
  return defineStore(`execution-store-${newExecutionId}`, () => {
    log.info("Creating execution view store");
    const executionId = ref(newExecutionId);
    const executions: Ref<Map<string, Execution>> = ref(new Map());
    const kddbDocument: Ref<KddbDocument | undefined> = ref(undefined);
    const completed = ref(false);
    const error: Ref<string | undefined> = ref(undefined);
    const session = ref<Session | undefined>(undefined);

    const executionView = computed(() => {
      if (executionId.value) {
        return appStore.workspaceStore.getViewById(executionId.value);
      } else {
        return undefined;
      }
    });

    function poll(execution: Execution, hasDocument = false) {
      if (!execution || !execution.id) {
        log.error("No execution found, or no id");
        throw new Error("No execution found, or no id");
      }

      getExecution(execution.id).then((updatedExecution) => {
        log.info(`Execution ${updatedExecution.id} updated to ${updatedExecution.status}`);
        if (updatedExecution.id != null) {
          executions.value.set(updatedExecution.id, updatedExecution);
          if (updatedExecution.status === ExecutionStatus.SUCCEEDED || updatedExecution.status === ExecutionStatus.FAILED) {
            // TODO - this is a hack, we need to figure out how to get the child executions from
            //        openapi and then we can fix this
            log.info(`Execution completed ${updatedExecution.status}, polling child executions`);
            if (updatedExecution.childExecutions && updatedExecution.childExecutions.length > 0) {
              updatedExecution.childExecutions.forEach((childExecution) => {
                poll(childExecution, hasDocument);
              });
            } else {
              log.info("No child executions, polling complete");
              if (updatedExecution.status === ExecutionStatus.FAILED) {
                notify({
                  group: "error",
                  title: "Assistant failed",
                }, 500);
                completed.value = true;
                error.value = "Assistant Failed";
                return;
              } else {
                notify({
                  group: "generic",
                  title: "Assistant completed successfully",
                }, 500);
              }

              if (updatedExecution && updatedExecution.pipeline?.steps && updatedExecution.pipeline.steps.length > 0 && hasDocument) {
                const finalContentObjectId = updatedExecution.pipeline.steps[updatedExecution.pipeline.steps.length - 1].outputId;
                log.info(`Final content object id ${finalContentObjectId}`);
                if (finalContentObjectId && session.value && session.value.id) {
                  log.info("Loading final content object");

                  // TODO we need to change the security on this endpoint to allow for JWT access
                  getExecutionContentObject(session.value.id, updatedExecution.id, finalContentObjectId).then((contentObject) => {
                    KddbDocument.fromBlob(contentObject).then((doc) => {
                      kddbDocument.value = doc;
                      completed.value = true;
                    });
                  });
                } else {
                  log.error("No final content object found");
                  execution.exceptionDetails = {
                    message: "No final content object found",
                  };
                  error.value = "No final content object found";
                  completed.value = true;
                }
              } else {
                log.info("No pipeline or steps found");
                completed.value = true;
              }
            }
          } else {
            log.info("Execution not complete, polling again in 1 second");
            setTimeout(() => {
              poll(execution, hasDocument);
            }, 1000);
          }
        } else {
          throw new Error("New execution does not have an id");
        }
      });
    }

    function executeAssistantEvent(project, assistant, document, eventType, options, contentObjectId = undefined, documentFamilyRef = undefined) {
      return new Promise((resolve, reject) => {
        if (project.id && assistant.id) {
          log.info(`Executing assistant event [${eventType}]`);
          try {
            let hasDocument = false;
            const formData = new FormData();

            if (document) {
              const blob = document.toBlob();
              const file = new File([blob], "document.pdf", { type: blob.type }); // Adjust the filename and type as necessary
              formData.append("document", file);
              hasDocument = true;
              contentObjectId = undefined;
            } else if (contentObjectId) {
              hasDocument = true;
            }

            const params = {} as any;
            params.eventType = eventType;
            params.options = JSON.stringify(options);
            if (contentObjectId) {
              params.contentObjectId = contentObjectId;
            }
            if (documentFamilyRef) {
              params.documentFamilyRef = documentFamilyRef;
            }

            log.info("Calling process assistant event");
            customAxios({ url: `/api/projects/${project.id}/assistants/${assistant.id}/events`, method: "POST", data: formData, params })
              .then((response) => {
                const newExecution = response as Execution;
                log.info(`New execution ${newExecution.id} created for assistant event ${eventType}`);
                session.value = newExecution.session;
                if (newExecution.id != null) {
                  executions.value.set(newExecution.id, newExecution);
                  poll(newExecution, hasDocument || contentObjectId !== undefined);
                  resolve(newExecution);
                } else {
                  reject(new Error("New execution does not have an id"));
                }
              })
              .catch((e) => {
                reject(new Error("Error processing assistant event", { cause: e }));
              });
          } catch (e) {
            reject(new Error(`Error executing assistant event ${eventType}`, { cause: e }));
          }
        } else {
          reject(new Error("Project and assistant must be saved before running an assistant"));
        }
      });
    }

    const orderedExecutions = computed(() => {
      const executionArray = Array.from(executions.value.values());
      executionArray.sort((a, b) => {
        if (a.createdOn && b.createdOn) {
          return new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime();
        } else {
          return 0;
        }
      });
      return executionArray;
    });

    return {
      executionId,
      executeAssistantEvent,
      executions,
      orderedExecutions,
      kddbDocument,
      completed,
      executionView,
      error,
    };
  })();
}
