<script setup lang="ts">
import { useTimeoutPoll } from "@vueuse/core";
import { DateTime } from "luxon";
import { parseLocalDateTime } from "~/utils/date";

const props = defineProps({
  date: [String, Date],
  ago: {
    type: Boolean,
    default: false,
  },
});

const computedDateTime = computed(() => {
  return props.date ? parseLocalDateTime(props.date) : "";
});

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const units: Intl.RelativeTimeFormatUnit[] = [
  "year",
  "month",
  "week",
  "day",
  "hour",
  "minute",
];

const lastUpdated = ref(DateTime.local({ zone: timeZone }));

const timeAgo = computed(() => {
  if (props.date === undefined) {
    return "";
  }

  const date = parseLocalDateTime(props.date);
  const diff = date.diff(lastUpdated.value).shiftTo(...units);
  const unit = units.find(unit => diff.get(unit) !== 0) || "second";

  const relativeFormatter = new Intl.RelativeTimeFormat("en", {
    numeric: "auto",
  });
  try {
    const result = relativeFormatter.format(Math.trunc(diff.as(unit)), unit);

    // If the first character of result is a letter then we need to make it uppercase
    if (result[0].match(/[a-z]/i)) {
      return result[0].toUpperCase() + result.slice(1);
    } else {
      return result;
    }
  } catch (e) {
    return "";
  }
});

function refresh() {
  lastUpdated.value = DateTime.local({ zone: timeZone });
}

const {
  resume,
} = useTimeoutPoll(refresh, 30000);

resume();
</script>

<template>
  <span v-if="!ago">
    {{ computedDateTime }}
  </span>
  <span v-else>
    {{ timeAgo }}
  </span>
</template>

<style scoped>

</style>
