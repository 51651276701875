import {useStorage} from "@vueuse/core";
import Axios, {AxiosError, AxiosRequestConfig} from 'axios';
import {InternalAxiosRequestConfig} from "axios";
import appStore from "~/store";
import { auth0 } from "~/main";
import { log } from "~/utils/logger";

export const AXIOS_INSTANCE = Axios.create();

export async function authHeader(): Promise<{Authorization?: string}> {
  const jwtToken = useStorage("userJwtToken", "");

  if (!auth0) {
    log.warn("auth0 not initialized");
    return {};
  }

  return auth0.getAccessTokenSilently()
    .then((token) => {
      if (token) {
        jwtToken.value = token;
        return {Authorization: `Bearer ${token}`};
      } else {
        return {};
      }
    })
    .catch(() => {
      return {Authorization: `Bearer ${jwtToken.value}`};
    });
}

AXIOS_INSTANCE.interceptors.request.use(
  (config: InternalAxiosRequestConfig): any => {
    return authHeader().then((header) => {
      if (header.Authorization) {
        config.headers.Authorization = header.Authorization;
      }

      return config;
    });
  },
  (error: any) => {
    appStore.platformStore.captureException(error);
    return Promise.reject(error)
  },
);

AXIOS_INSTANCE.interceptors.response.use((response) => {
  return response;
}, (error) => {
  appStore.platformStore.captureException(error);
  return Promise.reject(error)
})

// add a second `options` argument here if you want to pass extra options to each generated query
export const customAxios = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<T> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  }).then(({data}) => data);

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled');
  };

  return promise;
};

// In some case with react-query and swr you want to be able to override the return error type so you can also do it here like this
export type ErrorType<Error> = AxiosError<Error>;
