<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option } from "~/model";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import appStore from "~/store";
import { useListDocumentFamilies } from "~/api/document-families/document-families";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const { documentStores } = storeToRefs(appStore.projectStore);

const {
  localValue,
} = useConfigurationBase(props, emit);

const filter = ref(`store.id in [${documentStores.value.map(store => `'${store._id}'`).join(",")}]`);
const gridQuery = computed(() => {
  return {
    page: 1,
    pageSize: 10,
    filter: filter.value,
    sort: "",
  };
});

// Query
const {
  isLoading,
  data,
  refetch,
} = useListDocumentFamilies(gridQuery);

watch(localValue, (value) => {
  // Lets filter the document families based on the stores in this project
  filter.value = `store.id in [${documentStores.value.map(store => `'${store._id}'`).join(",")}] and path~~'*${value}*'`;
  refetch();
});

function selected(value) {
  localValue.value = `${value.item.storeRef}/${value.item.id}`;
}
</script>

<template>
  <div>
    <KodexaAutocompleteInput v-model="localValue" text-field="path" :name="item.name" :items="data?.content || []" :label="item.label" :loadin="isLoading" :description="item.description" @select="selected" />
  </div>
</template>

<style>

</style>
