<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import imageUrl from "~/images/kodexa-large-pict-only.png";
import type { LoadingEvent } from "~/store/usePlatform";
import appStore from "~/store";
import { log } from "~/utils/logger";

defineProps({});
const emit = defineEmits(["confirm", "cancel"]);
const open = ref(true);
const version = import.meta.env.VITE_APP_VERSION || "[Internal]";
const chartUiVersion = inject("chartUiVersion");
const platformVersion = inject("platformVersion");

async function clearCache() {
  localStorage.clear();
  const loadingEvent = {
    id: "readload-1",
    title: "Reloading Application",
    progressMessage: "If this takes too long please reload this page.",
  } as LoadingEvent;
  appStore.platformStore.addLoadingEvent(loadingEvent);
  if (window.chrome) {
    // For Chrome and Edge
    const promises: Promise<boolean>[] = [];
    caches.keys().then((names) => {
      for (const name of names) {
        promises.push(caches.delete(name));
      }
    });

    log.info("Clearing cache");

    // wait until all promises are resolved
    await Promise.all(promises);
    log.info("Cache cleared");

    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        log.info("Unregistering service worker");
        registration.unregister();
      }
    });
  } else {
    // For other browsers, you can't clear cache programmatically
    console.warn("Cache clearing is not supported in this browser.");
    // We are going to force it?
    log.info("Clearing cache");

    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name);
      }
    });

    log.info("Cache cleared");

    navigator.serviceWorker.getRegistrations().then((registrations) => {
      log.info("Unregistering service worker");

      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
}

const kodexaVersion = computed(() => {
  return chartUiVersion ? `${version}/${chartUiVersion}` : version;
});

const { platformOverview } = storeToRefs(appStore.platformStore);
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  @click="open = false"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" size="16" aria-hidden="true" @click="emit('cancel')" />
                </button>
              </div>
              <div class="sm:flex sm:items-start">
                <div
                  class="mx-auto mt-6 flex h-12 w-16 shrink-0 items-center justify-center rounded-full sm:mx-0 "
                >
                  <img
                    class="-ml-6 block"
                    :src="imageUrl" alt="Kodexa"
                  >
                </div>
                <div class="text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <DialogTitle as="h1" class="mt-6 text-2xl font-semibold text-gray-900 ">
                    Kodexa Studio
                  </DialogTitle>
                  <div class="mt-4 h-60">
                    <p class="mt-7 text-sm text-gray-500">
                      Kodexa Studio is an Enterprise platform that allows you to build, manage and deploy
                      document automation workflows.
                    </p>

                    <p class="mt-2 text-xs text-gray-500">
                      This product contains software developed by the Apache Software Foundation.
                    </p>
                    <p class="mt-2 text-sm text-gray-500">
                      Release Name: {{ platformOverview.release }}
                    </p>
                    <p class="mt-2 text-xs text-gray-500">
                      UI Build: {{ kodexaVersion }}
                    </p>
                    <p class="mt-2 text-xs text-gray-500">
                      Server Build: {{ platformOverview.version }}
                    </p>
                    <p class="mt-10 text-xs text-gray-500">
                      © 2024 Kodexa, Inc. All rights reserved.
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-8 sm:flex sm:flex-row-reverse">
                <KodexaButton
                  id="closeBtn"
                  type="secondary"
                  class="mr-2"
                  icon="close"
                  @click="emit('cancel')"
                >
                  Close
                </KodexaButton>
                <KodexaButton
                  id="clearCacheBtn"
                  type="secondary"
                  class="mr-2"
                  icon="cloud-refresh-outline"
                  @click="clearCache"
                >
                  Clear Cache and Reload
                </KodexaButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
