import { defineStore, storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import type { Ref } from "vue";
import appStore from "~/store/index";
import { customAxios } from "~/api/custom-axios";
import type { ActiveSelection } from "~/store/useWorkspace";
import { log } from "~/utils/logger";
import { buildSimplifiedDataObjects } from "~/utils/data";
import type { Channel, FeatureSet, Message } from "~/model";
import { createMessage, deleteMessage, getChannel, updateMessage, useListMessages } from "~/api/channel/channel";

export interface MessageContext {

  activeFeatures?: FeatureSet;
  contentObjectId?: string;
  storeRef?: string;
  documentFamilyId?: string;
  selectedNodeUuids?: string[];
  page?: number;
  activeData?: any;
  messageTemplate?: any;

}

export function createChannelStore(openChannelId: string) {
  return defineStore(`channel-${openChannelId}`, () => {
    const channelId = ref(openChannelId);
    const channel: Ref<null | Channel> = ref(null);
    const channelLoading = ref(false);
    const {
      isRefetching,
      data,
      refetch,
      status,
    } = useListMessages({
      filter: `channel.id: '${channelId.value}'`,
      sort: "createdOn:desc",
      pageSize: 10,
    }, {
      query: {
        enabled: true,
        refetchInterval: 1000,
        refetchIntervalInBackground: true,
        staleTime: 1000,
      },
    });

    const messages = computed(() => {
      const rawMessages = data.value?.content || [];

      // We add the channel back into the messages for now
      return rawMessages.map((message) => {
        return { ...message, channel: channel.value };
      });
    });

    getChannel(channelId.value).then((c) => {
      channel.value = c;
    });

    const { user } = storeToRefs(appStore.userStore);

    async function sendMessage(message: Message) {
      await createMessage(message);
      await refetch();
    }

    async function updateMessageContent(message: Message) {
      channelLoading.value = true;
      await updateMessage(message.id as string, message);
      await refetch();
      channelLoading.value = false;
    }

    function getContext(activeSelection: ActiveSelection) {
      if (activeSelection && activeSelection.viewId) {
        const documentViewStore = createDocumentViewerStore(activeSelection.viewId as string);
        log.info(`Found view to create message context with id ${activeSelection.viewId}`);

        if (!documentViewStore) {
          return undefined;
        }

        const {
          documentFamily,
          page,
          selectionContext,
        } = storeToRefs(documentViewStore);

        if (documentFamily && documentFamily.value) {
          // We have a document open, let's send the message with the context
          // (if we have a document open

          // Removed activeFeatures: kddbDocument.value ? kddbDocument.value?.buildFeatureSet() : undefined
          // due to event size
          return {
            documentFamilyId: documentFamily.value.id,
            page: page.value,
            selectedNodeUuids: selectionContext.value.selectedNodes.map(node => node.uuid),
            contentObjectId: documentViewStore.getLatestContentObject()?.id,
            storeRef: documentFamily.value.storeRef,
          } as MessageContext;
        } else {
          log.info("No document family found");
          return undefined;
        }
      } else {
        // get the active view from the workspace and use that
        const { activeView } = storeToRefs(appStore.workspaceStore);
        log.info(`Active view is ${activeView.value?.id}`);
        if (activeView.value) {
          log.info(`Active view is ${activeView.value?.viewType}`);
          if (activeView.value.viewType === "document") {
            const documentViewStore = createDocumentViewerStore(activeView.value.id as string);
            log.info(`Found view to create message context with id ${activeView.value.id}`);
            const {
              documentFamily,
              page,
              selectionContext,
            } = storeToRefs(documentViewStore as any);

            if (documentFamily.value) {
              // We have a document open, let's send the message with the context
              // (if we have a document open

              // Removed activeFeatures: kddbDocument.value ? kddbDocument.value?.buildFeatureSet() : undefined
              // due to event size
              return {
                documentFamilyId: documentFamily.value.id,
                page: page.value,
                selectedNodeUuids: selectionContext.value.selectedNodes.map(node => node.uuid),
                contentObjectId: documentViewStore?.getLatestContentObject()?.id,
                storeRef: documentFamily.value.storeRef,
              } as MessageContext;
            } else {
              log.info("No document family found");
              return undefined;
            }
          } else if (activeView.value?.viewType === "dataForm") {
            const dataFormView = createDataFormViewerStore(activeView.value.id as string);
            const context = {
              activeData: buildSimplifiedDataObjects(dataFormView.dataObjects),
            } as MessageContext;
            return context;
          }
        }
      }
    }

    async function sendTextMessage(messageString: string, context: MessageContext | undefined = undefined) {
      const message = {
        uuid: uuidv4(),
        messageType: "TEXT",
        content: messageString,
        user: user.value,
        channel: {
          id: channelId.value,
        },
        context,
      } as Message;

      await createMessage(message);
      await refetch();
    }

    async function deleteChannelMessage(message: Message) {
      if (!message.id) {
        return;
      }

      channelLoading.value = true;

      // Remove the message from the list
      await deleteMessage(message.id);
      await refetch();
      channelLoading.value = false;
    }

    async function clearChannelMessages() {
      channelLoading.value = true;
      await customAxios({
        url: `/api/channels/${channelId.value}/messages`,
        method: "DELETE",
      });
      await refetch();
      channelLoading.value = false;
    }

    async function sendFeedback(message: Message, feedbackOption: any) {
      if (!message.context) {
        message.context = {};
      }

      message.context.feedbackOption = feedbackOption;
      await updateMessageContent(message);
    }

    return {
      channelId,
      sendTextMessage,
      sendMessage,
      messages,
      deleteChannelMessage,
      channel,
      getContext,
      clearChannelMessages,
      sendFeedback,
      status,
      isRefetching,
    };
  })();
}
