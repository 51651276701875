<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import appStore from "~/store";
import type { DataFormViewer } from "~/store/useWorkspace";
import type { TagMetadata } from "~/store/useProject";
import type { DashboardWidget } from "~/model";

const props = defineProps<{
  widget: {
    type: DashboardWidget;
    required: false;
  };
}>();

const { tagMetadataMap, dataStores } = storeToRefs(appStore.projectStore);

const dataStore = computed(() => {
  return dataStores.value.find((dataStore: any) => {
    return dataStore.ref === props.widget?.properties?.dataStoreRef;
  });
});

const groupTaxons = computed(() => {
  return Array.from(tagMetadataMap.value.values()).filter((tagMetadata: any) => {
    return tagMetadata.taxon.group && tagMetadata.taxonomy.taxonomyType === "CONTENT" && !tagMetadata.taxon.path.includes("/")
  });
});

const dataFormViewer = {
  id: uuidv4(),
  name: "Project Data",
  dataFormRef: "",
  isSidecar: false,
  documentFamilyIds: [],
  ephemeral: true,
  properties: {},
  viewType: "dataForm",
} as DataFormViewer;

appStore.workspaceStore.addView(dataFormViewer);

const currentTaxon = ref<TagMetadata | undefined>(undefined);

watch(groupTaxons, () => {
  if (groupTaxons.value.length > 0) {
    currentTaxon.value = groupTaxons.value[0];
  }
}, { immediate: true, deep: true });
</script>

<template>
  <div>
    <div v-if="dataStore">
      <ul class="-mb-px ml-5 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
        <li
          v-for="groupTaxon in groupTaxons"
          :key="groupTaxon.taxon.id"
          class="mr-2"
          @click="currentTaxon = groupTaxon"
        >
          <a
            class="text-md"
            :class="currentTaxon?.taxon.id === groupTaxon.taxon.id ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
          >
            {{ groupTaxon.label }}
          </a>
        </li>
        <li
          class="mr-2"
          @click="currentTaxon = undefined"
        >
          <a
            class="text-md"
            :class="currentTaxon === undefined ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
          >
            Download
          </a>
        </li>
      </ul>
      <KodexaDataObjectGridContainer
        v-if="currentTaxon" :view-id="dataFormViewer.id" :data-store="dataStore"
        :group-taxon-metadata="currentTaxon" class="mt-1 px-4"
      />
      <div v-else>
        <KodexaButton>
          Download Excel
        </KodexaButton>
      </div>
    </div>
    <div v-else>
      <div class="mt-10 flex flex-col items-center justify-center">
        <MaterialDesignIcon name="database" size="48" class="text-gray-400" />
        <p class="mt-2 text-sm text-gray-500">
          Select a data store to view the data, it is either
          not set of the store no longer exists.
        </p>
        <p class="mt-2 text-sm text-gray-500">
          {{ props.widget?.properties?.dataStoreRef }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
