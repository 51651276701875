<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import appStore from "~/store";
import type { Task } from "~/model";
import { createTask } from "~/api/activity/activity";
import { log } from "~/utils/logger";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "task-created"]);
const reactiveModel = ref(props.modelValue);
const {
  project,
  contentTaxonomies,
  documentStores,
} = storeToRefs(appStore.projectStore);

const newTask = ref({} as Task);

function defaultTask() {
  log.info("Defaulting task");
  newTask.value = {
    title: "New Task",
    project: project.value,
    description: "",
    metadata: {
      fieldValues: {},
      fields: [
        {
          name: "customerEmail",
          type: "string",
          label: "Customer Email",
        },
        {
          name: "customerName",
          type: "string",
          label: "Customer Name",
        },
      ],
    },
  } as Task;
}

watch(reactiveModel, () => {
  defaultTask();
}, { immediate: true });

function close() {
  emit("update:modelValue", false);
  defaultTask();
}

const selectedTaxons = ref({} as Record<string, boolean>);

const rootTaxons = computed(() => {
  // We want to get all the root taxons from the content taxonomies
  const rootTaxons: any[] = [];
  if (!contentTaxonomies.value) {
    return [];
  }

  contentTaxonomies.value.forEach((taxonomy) => {
    // add all the taxons
    taxonomy.taxons.forEach((taxon) => {
      rootTaxons.push({ taxon, taxonomy });
    });
  });

  return rootTaxons;
});

function create() {
  // We need to look at the selected taxons
  // and create the data requirements
  const taskChecklist = [];
  for (const [key, value] of Object.entries(selectedTaxons.value)) {
    if (value) {
      rootTaxons.value.forEach((group) => {
        const taxon = group.taxon;
        if (taxon.path === key) {
          taskChecklist.push({
            name: taxon.name,
            description: taxon.description,
            taxonPath: taxon.path,
            taxonomyReference: group.taxonomy.ref,
          });
        }
      });
    }
  }
  newTask.value.metadata.checkItems = taskChecklist;

  createTask(newTask).then(() => {
    emit("update:modelValue", false);
    emit("task-created");
    defaultTask();
  });
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="emit('update:modelValue', false)">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 80%; height: 700px"
              class="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  @click="emit('update:modelValue', false)"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <DialogTitle class="flex text-lg font-medium leading-6 text-gray-900">
                <div class="sm:flex sm:items-center sm:justify-between">
                  <div class="sm:flex sm:space-x-5">
                    <div class="shrink-0" />
                    <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                      <p class="text-lg font-bold text-gray-900 sm:text-2xl">
                        New Task
                      </p>
                      <p class="text-sm text-gray-600">
                        Create a new task in the project {{ project.name }}
                      </p>
                    </div>
                  </div>
                </div>
              </DialogTitle>
              <div class="min-h-0 flex-1 overflow-y-auto">
                <!-- Divider container -->
                <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0" style="height: 500px; overflow-y: scroll">
                  <!-- Task Title -->
                  <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label
                        for="task-title"
                        class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                      >Title</label>
                    </div>
                    <div class="sm:col-span-2">
                      <KodexaTextInput id="task-title" v-model="newTask.title" name="name" />
                    </div>
                  </div>
                  <!-- Task Description -->
                  <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label
                        for="task-title"
                        class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                      >Description</label>
                    </div>
                    <div class="sm:col-span-2">
                      <KodexaTextArea id="task-description" v-model="newTask.description" name="description" :rows="5" />
                    </div>
                  </div>
                  <!-- Store ID -->
                  <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                    <div>
                      <label for="storeId" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Document Store</label>
                    </div>
                    <div v-if="documentStores" class="sm:col-span-2">
                      <KodexaDropDown
                        id="storeRef"
                        v-model="newTask.metadata.storeRef"
                        name="storeRef"
                        :items="documentStores"
                        value-field="ref"
                        text-field="name"
                      />
                    </div>
                  </div>

                  <div v-if="newTask.metadata !== undefined" class="p-4">
                    <div class="text-lg font-bold text-gray-900">
                      Additional Fields
                    </div>
                    <div v-for="field in newTask.metadata.fields" :key="field.name">
                      <ConfigurationOption v-model="newTask.metadata.fieldValues" :item="field" />
                    </div>
                  </div>
                  <div class="p-4">
                    <div class="text-lg font-bold text-gray-900">
                      Data Requirements
                    </div>
                    <div v-for="taxon in rootTaxons" :key="taxon.id">
                      <KodexaCheckbox v-model="selectedTaxons[taxon.taxon.path]" :name="taxon.taxon.name" :label="taxon.taxon.label" />
                    </div>
                  </div>
                </div>
                <div>
                  <Toolbar class="border-0 bg-white">
                    <ToolbarSpacer />
                    <KodexaButton id="cancel" icon="close" @click="close">
                      Cancel
                    </KodexaButton>
                    <KodexaButton id="createTask" icon="plus" @click="create">
                      Create Task
                    </KodexaButton>
                  </Toolbar>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
