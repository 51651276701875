/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  BaseAccessToken
} from '../../model/baseAccessToken'
import type {
  CreateNewAccessTokenParams
} from '../../model/createNewAccessTokenParams'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  GetRecentEntitiesParams
} from '../../model/getRecentEntitiesParams'
import type {
  Membership
} from '../../model/membership'
import type {
  PageUserRecent
} from '../../model/pageUserRecent'
import type {
  Team
} from '../../model/team'
import type {
  TermsProperties
} from '../../model/termsProperties'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import type {
  User
} from '../../model/user'
import type {
  UserRecent
} from '../../model/userRecent'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get my user profile information
 */
export const getMe = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<User>(
      {url: `/api/account/me`, method: 'GET', signal
    },
      options);
    }
  

export const getGetMeQueryKey = () => {
    return ['api','account','me'] as const;
    }

    
export const getGetMeQueryOptions = <TData = Awaited<ReturnType<typeof getMe>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetMeQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getMe>>> = ({ signal }) => getMe(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData> 
}

export type GetMeQueryResult = NonNullable<Awaited<ReturnType<typeof getMe>>>
export type GetMeQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetMe = <TData = Awaited<ReturnType<typeof getMe>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getMe>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetMeQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update my profile information
 */
export const updateMe = (
    user: MaybeRef<NonReadonly<User>>,
 options?: SecondParameter<typeof customAxios>,) => {
      user = unref(user);
      
      return customAxios<User>(
      {url: `/api/account/me`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: user
    },
      options);
    }
  


export const getUpdateMeMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMe>>, TError,{data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateMe>>, TError,{data: NonReadonly<User>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMe>>, {data: NonReadonly<User>}> = (props) => {
          const {data} = props ?? {};

          return  updateMe(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateMeMutationResult = NonNullable<Awaited<ReturnType<typeof updateMe>>>
    export type UpdateMeMutationBody = NonReadonly<User>
    export type UpdateMeMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateMe = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMe>>, TError,{data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateMeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Accept the terms of service with the version
 */
export const acceptTerms = (
    termsProperties: MaybeRef<TermsProperties>,
 options?: SecondParameter<typeof customAxios>,) => {
      termsProperties = unref(termsProperties);
      
      return customAxios<TermsProperties>(
      {url: `/api/account/acceptTerms`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: termsProperties
    },
      options);
    }
  


export const getAcceptTermsMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptTerms>>, TError,{data: TermsProperties}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof acceptTerms>>, TError,{data: TermsProperties}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof acceptTerms>>, {data: TermsProperties}> = (props) => {
          const {data} = props ?? {};

          return  acceptTerms(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AcceptTermsMutationResult = NonNullable<Awaited<ReturnType<typeof acceptTerms>>>
    export type AcceptTermsMutationBody = TermsProperties
    export type AcceptTermsMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useAcceptTerms = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof acceptTerms>>, TError,{data: TermsProperties}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getAcceptTermsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get recent activity entities
 */
export const getRecentEntities = (
    params?: MaybeRef<GetRecentEntitiesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageUserRecent>(
      {url: `/api/account/recent`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetRecentEntitiesQueryKey = (params?: MaybeRef<GetRecentEntitiesParams>,) => {
    return ['api','account','recent', ...(params ? [params]: [])] as const;
    }

    
export const getGetRecentEntitiesQueryOptions = <TData = Awaited<ReturnType<typeof getRecentEntities>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<GetRecentEntitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecentEntities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetRecentEntitiesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecentEntities>>> = ({ signal }) => getRecentEntities(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getRecentEntities>>, TError, TData> 
}

export type GetRecentEntitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getRecentEntities>>>
export type GetRecentEntitiesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetRecentEntities = <TData = Awaited<ReturnType<typeof getRecentEntities>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<GetRecentEntitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getRecentEntities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetRecentEntitiesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Add recent activity for user
 */
export const updateRecentEntity = (
    userRecent: MaybeRef<UserRecent>,
 options?: SecondParameter<typeof customAxios>,) => {
      userRecent = unref(userRecent);
      
      return customAxios<UserRecent>(
      {url: `/api/account/recent`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: userRecent
    },
      options);
    }
  


export const getUpdateRecentEntityMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRecentEntity>>, TError,{data: UserRecent}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateRecentEntity>>, TError,{data: UserRecent}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRecentEntity>>, {data: UserRecent}> = (props) => {
          const {data} = props ?? {};

          return  updateRecentEntity(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateRecentEntityMutationResult = NonNullable<Awaited<ReturnType<typeof updateRecentEntity>>>
    export type UpdateRecentEntityMutationBody = UserRecent
    export type UpdateRecentEntityMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateRecentEntity = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRecentEntity>>, TError,{data: UserRecent}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateRecentEntityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create new personal access token
 */
export const createNewAccessToken = (
    params: MaybeRef<CreateNewAccessTokenParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      params = unref(params);
      
      return customAxios<BaseAccessToken>(
      {url: `/api/account/createToken`, method: 'POST',
        params: unref(params)
    },
      options);
    }
  


export const getCreateNewAccessTokenMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNewAccessToken>>, TError,{params: CreateNewAccessTokenParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createNewAccessToken>>, TError,{params: CreateNewAccessTokenParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNewAccessToken>>, {params: CreateNewAccessTokenParams}> = (props) => {
          const {params} = props ?? {};

          return  createNewAccessToken(params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateNewAccessTokenMutationResult = NonNullable<Awaited<ReturnType<typeof createNewAccessToken>>>
    
    export type CreateNewAccessTokenMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateNewAccessToken = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNewAccessToken>>, TError,{params: CreateNewAccessTokenParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateNewAccessTokenMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get teams for user
 */
export const getTeams = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Team[]>(
      {url: `/api/account/teams`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTeamsQueryKey = () => {
    return ['api','account','teams'] as const;
    }

    
export const getGetTeamsQueryOptions = <TData = Awaited<ReturnType<typeof getTeams>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTeamsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTeams>>> = ({ signal }) => getTeams(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData> 
}

export type GetTeamsQueryResult = NonNullable<Awaited<ReturnType<typeof getTeams>>>
export type GetTeamsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetTeams = <TData = Awaited<ReturnType<typeof getTeams>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTeams>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTeamsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get my memberships
 */
export const getAccountMemberships = (
    
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      
      
      return customAxios<Membership[]>(
      {url: `/api/account/memberships`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAccountMembershipsQueryKey = () => {
    return ['api','account','memberships'] as const;
    }

    
export const getGetAccountMembershipsQueryOptions = <TData = Awaited<ReturnType<typeof getAccountMemberships>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountMemberships>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAccountMembershipsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAccountMemberships>>> = ({ signal }) => getAccountMemberships(requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAccountMemberships>>, TError, TData> 
}

export type GetAccountMembershipsQueryResult = NonNullable<Awaited<ReturnType<typeof getAccountMemberships>>>
export type GetAccountMembershipsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetAccountMemberships = <TData = Awaited<ReturnType<typeof getAccountMemberships>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAccountMemberships>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAccountMembershipsQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



