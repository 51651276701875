import Decimal from "decimal.js";
import {DateTime} from "luxon";
import {v4 as uuidv4} from "uuid";
import appStore from "~/store";
import {DataAttribute, DataException, DataObject} from "~/model";

function findAttributes(dataObject: DataObject, attributePath: string, allDataObjects: DataObject[]): DataAttribute[] {
  const stack = [dataObject];
  const visited = new Set<DataObject>();
  const finalAttributes: DataAttribute[] = [];
  while (stack.length > 0) {
    const current = stack.pop();
    if (!current) {
      break;
    }
    visited.add(current);
    const attributes = current.attributes?.filter((attribute) => {
      return attribute.path === attributePath;
    });
    if (attributes) {
      finalAttributes.push(...attributes);
    }

    const childDataObjects = allDataObjects.filter((childDataObject) => {
      return childDataObject.parent?.id === current.id;
    });

    for (const child of childDataObjects) {
      if (!visited.has(child)) {
        stack.push(child);
      }
    }
  }

  // Get the unique attributes
  return finalAttributes.filter((attribute, index, self) => {
    return index === self.findIndex(t => (
      t.uuid === attribute.uuid
    ));
  });
}

/**
 * Used for finding the dataException for a dataObject or dataAttribute based on the exceptionMessage
 * TODO: Change exceptionMessage to exceptionTypeId
 * @param exceptionMessage: string of exception message
 * @param dataObject: DataObject
 * @param dataAttribute: DataAttribute
 * @param getFromDataAttribute: Boolean (default to false)
 */
function getDataException(exceptionMessage: string, dataObject: DataObject,
                          dataAttribute: DataAttribute | undefined = undefined,
                          getFromDataAttribute = false): DataException | undefined {
  function findException(exceptionMessage: string, dataExceptions: DataException[]): DataException | undefined {
    for (const dataException of dataExceptions) {
      if (dataException.message === exceptionMessage) {
        return dataException;
      } else if (dataException.message.includes(exceptionMessage)) {
        return dataException;
      }
    }
    return undefined;
  }

  if (getFromDataAttribute) {
    if (dataAttribute?.dataExceptions) {
      return findException(exceptionMessage, dataAttribute.dataExceptions);
    }
  } else {
    if (dataObject.dataExceptions) {
      return findException(exceptionMessage, dataObject.dataExceptions);
    }
  }
}

function findDataAttribute(dataObject: DataObject, attributePath: string): DataAttribute | undefined {
  if (!dataObject.attributes) {
    return undefined;
  }

  const dataAttribute: DataAttribute | undefined = dataObject.attributes.find(attribute =>
    attribute.path === attributePath);

  return dataAttribute;
}

abstract class CassValidators {
  callConfigure(dataObject: DataObject, allDataObject: DataObject[]) {
    Decimal.set({rounding: 2});
    const taxonComponents = this.getTaxonComponents(dataObject, allDataObject);

    this.configure(taxonComponents, dataObject, allDataObject);
  }

  getTaxonComponents(dataObject, allDataObject): Map<string, BillTaxonComponent> {
    const path = dataObject.path;
    if (path === "Bill") {
      return new Map([
        ["InvoiceNumber", new BillTaxonComponent(`${path}/InvoiceNumber`, "Invoice Number", false, dataObject, allDataObject)],
        ["InvoiceDate", new BillTaxonComponent(`${path}/InvoiceDate`, "Invoice Date", false, dataObject, allDataObject)],
        ["DueDate", new BillTaxonComponent(`${path}/DueDate`, "Due Date", false, dataObject, allDataObject)],
        ["AccountNumber", new BillTaxonComponent(`${path}/AccountNumber`, "Account Number", false, dataObject, allDataObject)],
        ["TotalBilledAmount", new BillTaxonComponent(`${path}/TotalBilledAmount`, "Total Billed Amount", false, dataObject, allDataObject)],
        ["TotalNewCharges", new BillTaxonComponent(`${path}/TotalNewCharges`, "Total New Charges", false, dataObject, allDataObject)],
        ["BalanceCarryForward", new BillTaxonComponent(`${path}/BalanceCarryForward`, "Balance Carry Forward", false, dataObject, allDataObject)],
        ["PreviousBilledAmount", new BillTaxonComponent(`${path}/PreviousBilledAmount`, "Previous Billed Amount", false, dataObject, allDataObject)],
        ["PaymentsReceived", new BillTaxonComponent(`${path}/PaymentsReceived`, "Payments Received", true, dataObject, allDataObject)],
        ["CreditAdjustments", new BillTaxonComponent(`${path}/CreditAdjustments`, "Credit Adjustments", false, dataObject, allDataObject)],
        ["LateFee", new BillTaxonComponent(`${path}/LateFee`, "Late Fee", false, dataObject, allDataObject)],
        ["Deposit", new BillTaxonComponent(`${path}/Deposit`, "Deposit", false, dataObject, allDataObject)],
        ["DepositInterest", new BillTaxonComponent(`${path}/DepositInterest`, "Deposit Interest", false, dataObject, allDataObject)],
      ]);
    } else if (path === "Bill/BillService") {
      return new Map([
        ["ServiceType", new BillTaxonComponent(`${path}/ServiceType`, "Service Type", false, dataObject, allDataObject)],
        ["ServiceBeginDate", new BillTaxonComponent(`${path}/ServiceBeginDate`, "Service Begin Date", false, dataObject, allDataObject)],
        ["ServiceEndDate", new BillTaxonComponent(`${path}/ServiceEndDate`, "Service End Date", false, dataObject, allDataObject)],
        ["ServicePeriodDays", new BillTaxonComponent(`${path}/ServicePeriodDays`, "Service Period Days", false, dataObject, allDataObject)],
        ["SupplierName", new BillTaxonComponent(`${path}/SupplierName`, "Supplier Name", false, dataObject, allDataObject)],
        ["SupplierId", new BillTaxonComponent(`${path}/SupplierId`, "Supplier Id", false, dataObject, allDataObject)],
        ["ServicePeriod", new BillTaxonComponent(`${path}/ServicePeriod`, "Service Period", false, dataObject, allDataObject)],
      ]);
    } else if (path === "Bill/BillService/BillReading") {
      return new Map([
        ["MeterNumber", new BillTaxonComponent(`${path}/MeterNumber`, "Meter Number", false, dataObject, allDataObject)],
        ["ReadingType", new BillTaxonComponent(`${path}/ReadingType`, "Reading Type", false, dataObject, allDataObject)],
        ["MeterType", new BillTaxonComponent(`${path}/MeterType`, "Meter Type", false, dataObject, allDataObject)],
        ["ReadBeginDate", new BillTaxonComponent(`${path}/ReadBeginDate`, "Read Begin Date", false, dataObject, allDataObject)],
        ["ReadEndDate", new BillTaxonComponent(`${path}/ReadEndDate`, "Read End Date", false, dataObject, allDataObject)],
        ["BillingDays", new BillTaxonComponent(`${path}/BillingDays`, "Billing Days", false, dataObject, allDataObject)],
        ["BeginRead", new BillTaxonComponent(`${path}/BeginRead`, "Begin Read", false, dataObject, allDataObject)],
        ["EndRead", new BillTaxonComponent(`${path}/EndRead`, "End Read", false, dataObject, allDataObject)],
        ["ReadDifference", new BillTaxonComponent(`${path}/ReadDifference`, "Read Difference", false, dataObject, allDataObject)],
        ["ReadMultiplier", new BillTaxonComponent(`${path}/ReadMultiplier`, "Read Multiplier", false, dataObject, allDataObject)],
        ["ReadPowerFactor", new BillTaxonComponent(`${path}/ReadPowerFactor`, "Read Power Factor", false, dataObject, allDataObject)],
        ["ReadQuantity", new BillTaxonComponent(`${path}/ReadQuantity`, "Read Quantity", false, dataObject, allDataObject)],
        ["ReadQuantityUom", new BillTaxonComponent(`${path}/ReadQuantityUom`, "Read Quantity Uom", false, dataObject, allDataObject)],
        ["ServiceRate", new BillTaxonComponent(`${path}/ServiceRate`, "Service Rate", false, dataObject, allDataObject)],
      ]);
    }

    return new Map<string, BillTaxonComponent>();
  }

  callValidation(dataObject: DataObject) {
    this.validate(dataObject);
  }

  callMissingExceptions(dataObject: DataObject): string[] {
    return this.validatedException(dataObject);
  }

  abstract getTaxonPath(): string;

  abstract configure(taxonComponents: Map<string, BillTaxonComponent>, dataObject: DataObject, allDataObject: DataObject[]): void;

  abstract validate(dataObject: DataObject): void;

  abstract validatedException(dataObject: DataObject): string[];
}

class BillTaxonComponent {
  public taxon: string;
  public label: string;
  public attribute: DataAttribute | undefined;
  public value: Decimal | undefined;
  public requiredComponent: RequiredComponent | undefined;

  constructor(taxon: string, label: string, absoluteValue: boolean, dataObject: DataObject, allDataObject: DataObject[]) {
    this.taxon = taxon;
    this.label = label;
    this.attribute = findDataAttribute(dataObject, taxon);
    if (this.attribute) {
      if (this.attribute.decimalValue !== undefined && this.attribute.decimalValue !== null) {
        this.value = absoluteValue ? new Decimal(this.attribute.decimalValue).absoluteValue() : new Decimal(this.attribute.decimalValue);
      } else {
        this.value = undefined;
      }
    } else {
      this.value = undefined;
    }
  }

  setRequiredComponent(requiredComponent: RequiredComponent) {
    this.requiredComponent = requiredComponent;
  }
}

export class BillChargesValidator extends CassValidators {
  private billTaxonComponent: BillTaxonComponent | undefined;
  private billChargeComponent: Map<string, BillChargesComponent> | undefined;
  protected toleranceAmount = new Decimal(0.02);
  protected description = `Charges DO NOT match with tolerance of ${this.toleranceAmount.toString()}`;
  private partialExceptionUpdate: Partial<DataException> = {open: true, message: "", exceptionDetails: ""};

  getTaxonPath(): string {
    return "Bill";
  }

  configure(taxonComponents: Map<string, BillTaxonComponent>, dataObject, allDataObject) {
    this.billTaxonComponent = taxonComponents.get("TotalNewCharges");
    this.billChargeComponent = new Map([
      ["BillChargeAmount", new BillChargesComponent(dataObject, "Bill/BillCharge", allDataObject)],
      ["BillServiceChargeAmount", new BillChargesComponent(dataObject, "Bill/BillService/BillServiceCharge", allDataObject)],
    ]);
  }

  validate(dataObject: DataObject) {
    let totalSum = new Decimal(0);
    let componentMath = "";
    const totalTaxonAmount = this.billTaxonComponent?.value ? this.billTaxonComponent.value : new Decimal(0);
    this.billChargeComponent?.forEach((value) => {
      value.billCharges.forEach((billCharge) => {
        const chargeAmount = billCharge.chargeAmountAttribute?.decimalValue ? new Decimal(billCharge.chargeAmountAttribute.decimalValue as number) : new Decimal(0);
        const descriptionLabel = billCharge.descriptionAttribute?.stringValue || "------------";
        totalSum = totalSum.plus(chargeAmount);
        componentMath += `\n\t+ $${chargeAmount} ${descriptionLabel}`;
      });
    });
    const totalDifference = totalSum.minus(totalTaxonAmount);
    const dataException = getDataException(this.description.substring(0, this.description.length - 8), dataObject, this.billTaxonComponent?.attribute, true);
    const fromDataObjectException = getDataException(this.description.substring(0, this.description.length - 8), dataObject);
    if (fromDataObjectException) {
      // appStore.workspaceStore.removeException([], this.description, dataObject, true);
      fromDataObjectException.open = false;
    }
    if (totalDifference.abs().lessThanOrEqualTo(this.toleranceAmount)) {
      if (dataException) {
        this.partialExceptionUpdate = {
          open: false,
          exceptionDetails: dataException.exceptionDetails,
          message: dataException.message,
        };
        appStore.workspaceStore.updateException(dataObject, this.partialExceptionUpdate, dataException.uuid, this.billTaxonComponent?.attribute, true);
      }
    } else {
      const notes = `Total amount $${totalTaxonAmount.toString()} is different by $${totalDifference.toString()}:\n${componentMath}\n\n\t= $${totalSum.toString()} `;
      this.createMergeException(this.billTaxonComponent?.attribute, notes, this.description, dataObject, dataException);
    }
  }

  createMergeException(attribute, exceptionDetails, description, dataObject, dataException: DataException | undefined): void {
    if (!attribute) {
      return;
    }
    if (dataException) {
      this.partialExceptionUpdate = {
        open: true,
        message: description,
        exceptionDetails,
      };
      appStore.workspaceStore.updateException(dataObject, this.partialExceptionUpdate, dataException.uuid, attribute, true);
    } else {
      const uuid = uuidv4();
      const newException = {
        message: description,
        exceptionDetails,
        severity: "ERROR",
        open: true,
        uuid,
      };
      appStore.workspaceStore.mergeException(attribute, newException, dataObject);
    }
  }

  validatedException(dataObject: DataObject): string[] {
    const exceptionUuids: string[] = [];
    const exception = getDataException(this.description.substring(0, this.description.length - 8), dataObject, this.billTaxonComponent?.attribute, true);
    if (exception) {
      exceptionUuids.push(exception.uuid as string);
    }
    return exceptionUuids;
  }
}

class BillChargesComponent {
  public billCharges: BillChargesInterface[];

  constructor(dataObject, taxon, allDataObject) {
    this.billCharges = [];
    const dataAttributes = findAttributes(dataObject, `${taxon}/ChargeAmount`, allDataObject);
    for (const attribute of dataAttributes) {
      const parentDataObject = appStore.workspaceStore.findDataObjectByAttributeUuid(attribute.uuid);
      const descriptionAttribute = parentDataObject.attributes.find(a => a.path === `${taxon}/ChargeDescription`);
      const chargeQuantityAttribute = parentDataObject.attributes.find(a => a.path === `${taxon}/ChargeQuantity`);
      const chargeQuantityUomAttribute = parentDataObject.attributes.find(a => a.path === `${taxon}/ChargeQuantityUom`);
      const chargeUnitPriceAttribute = parentDataObject.attributes.find(a => a.path === `${taxon}/ChargeUnitPrice`);

      this.billCharges.push({
        chargeAmountAttribute: attribute,
        parentDataObject,
        descriptionAttribute,
        chargeQuantityAttribute,
        chargeQuantityUomAttribute,
        chargeUnitPriceAttribute,
      });
    }
  }
}

interface BillChargesInterface {
  chargeAmountAttribute: DataAttribute | undefined;
  descriptionAttribute: DataAttribute | undefined;
  chargeQuantityAttribute: DataAttribute | undefined;
  chargeQuantityUomAttribute: DataAttribute | undefined;
  chargeUnitPriceAttribute: DataAttribute | undefined;
  parentDataObject: DataAttribute | undefined;
}

export class BillServiceValidator extends CassValidators {
  private billServiceTaxonComponent: Map<String, BillTaxonComponent> | undefined;
  private billServiceChargesComponent: BillChargesComponent | undefined;

  constructor() {
    super();
  }

  getTaxonPath(): string {
    return "Bill/BillService";
  }

  configure(taxonComponents: Map<string, BillTaxonComponent>, dataObject, allDataObject) {
    this.billServiceTaxonComponent = taxonComponents;
    const billServiceChargeDataObjects = allDataObject.filter((currDataObject) => {
      return dataObject.uuid === currDataObject.parent?.uuid && currDataObject.path === "Bill/BillService/BillServiceCharge";
    });

    this.billServiceChargesComponent = new BillChargesComponent(dataObject, "Bill/BillService/BillServiceCharge", billServiceChargeDataObjects);
  }

  validate(dataObject: DataObject) {
    this.validateServiceType(dataObject);
    this.validateRequiredAttributes(dataObject);
    this.validateBilledUsageDemand(dataObject);
    this.validateServiceDates(dataObject);
  }

  validateRequiredAttributes(dataObject: DataObject) {
    this.billServiceTaxonComponent?.forEach((item) => {
      if (item.label === "Service Type" || item.label === "Service Period") {
        return;
      }
      const convertedTagString = item.label.replaceAll(" ", "_").toLowerCase();
      item.setRequiredComponent({requiredAttribute: false, description: `Missing ${convertedTagString}`});
      checkRequiredAttribute(item, dataObject);
    });
  }

  validateServiceType(dataObject: DataObject) {
    const serviceType = this.billServiceTaxonComponent?.get("ServiceType");
    if (serviceType) {
      serviceType.setRequiredComponent({requiredAttribute: true, description: "Missing service_type"});
      checkRequiredAttribute(serviceType, dataObject);
    }
  }

  validateServiceDates(dataObject) {
    if (!this.billServiceTaxonComponent) {
      return;
    }
    const {ServiceBeginDate, ServiceEndDate, ServicePeriodDays} = Object.fromEntries(this.billServiceTaxonComponent);
    validateReadDates(ServiceBeginDate, ServiceEndDate, ServicePeriodDays, dataObject);
    validateEarlyDates(ServiceBeginDate, ServiceEndDate, dataObject);
    validateTodayDateReadDate(ServiceBeginDate, dataObject, "Service Begin Date cannot be more than 180 days from today.", 180, true);
  }

  validateBilledUsageDemand(dataObject: DataObject) {
    this.billServiceChargesComponent?.billCharges.forEach((value) => {
      if (!value.descriptionAttribute?.stringValue
        || !["BILLED USAGE", "BILLED DEMAND"].includes(value.descriptionAttribute.stringValue.toUpperCase())) {
        return;
      }
      if (value.descriptionAttribute.stringValue.toUpperCase() === "BILLED DEMAND") {
        validateUOM(value?.chargeQuantityUomAttribute, ["KW", "KVA", "MW"],
          "Invalid charge quantity uom for Billed Demand", dataObject);
      } else {
        validateUOM(value?.chargeQuantityUomAttribute, ["BTU", "CCF", "CF", "DCF", "DECA", "DTH",
            "GJ", "GKWH", "HCF", "HCFT", "KBTU", "M3", "MCF", "MMB", "THMS"],
          "Invalid charge quantity uom for Billed Usage", dataObject);
      }
      if (!value.chargeQuantityUomAttribute?.stringValue || value.chargeAmountAttribute?.decimalValue === undefined || value.chargeQuantityAttribute?.value === undefined) {
        return;
      }

      const chargeAmount = new Decimal(value.chargeAmountAttribute.decimalValue);
      if (!chargeAmount.isZero()) {
        return;
      }

      const exceptionMessage = value.descriptionAttribute.stringValue.toUpperCase() === "BILLED DEMAND" ? "Missing Billed Dem" : "Missing Billed Usa";
      const dataException = getDataException(exceptionMessage, dataObject);

      if (dataException) {
        const partialDataException: Partial<DataException> = {
          open: false,
          message: dataException.message,
          exceptionDetails: dataException.exceptionDetails,
        };
        appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
      }
    });
  }

  validatedException(dataObject: DataObject): string[] {
    const exceptionUuids: string[] = [];
    const missingException = ["Missing service_type", "Missing service_begin_date", "Missing service_end_date", "Missing service_period_days", "Missing Billed Demand", "Missing Billed Usage"];
    // TODO clean this up
    // Get all the dataExceptions based on the messages
    if (dataObject.dataExceptions) {
      for (const dataException of dataObject.dataExceptions) {
        if (missingException.includes(dataException.message)) {
          exceptionUuids.push(dataException.uuid as string);
        } else if (dataException.message.includes("date is after end")) {
          exceptionUuids.push(dataException.uuid as string);
        } else if (dataException.message.includes("does not equal number")) {
          exceptionUuids.push(dataException.uuid as string);
        } else if (dataException.message.includes("charge quantity uom")) {
          exceptionUuids.push(dataException.uuid as string);
        }
      }

      if (dataObject.attributes) {
        for (const attribute of dataObject.attributes) {
          if (attribute.dataExceptions) {
            for (const dataException of attribute.dataExceptions) {
              if (dataException.message.includes("cannot be a date after")) {
                exceptionUuids.push(dataException.uuid as string);
              } else if (dataException.message.includes("Invalid date format")) {
                exceptionUuids.push(dataException.uuid as string);
              }
            }
          }
        }
      }
    }
    return exceptionUuids;
  }
}

export class BillReadingValidator extends CassValidators {
  private billReadingTaxonComponent: Map<string, BillTaxonComponent> | undefined;

  getTaxonPath(): string {
    return "Bill/BillService/BillReading";
  }

  configure(taxonComponents: Map<string, BillTaxonComponent>) {
    this.billReadingTaxonComponent = taxonComponents;
  }

  validate(dataObject: DataObject) {
    if (!this.billReadingTaxonComponent) {
      return;
    }
    const {
      MeterNumber,
      BeginRead,
      EndRead,
      ReadDifference,
      ReadMultiplier,
      ReadQuantity,
      ReadBeginDate, ReadEndDate,
    }
      = Object.fromEntries(this.billReadingTaxonComponent);
    this.validateRequiredAttributes(dataObject);
    this.validateReadDates(dataObject);
    validateSpecialCharacters(MeterNumber, dataObject);
    const billReadingComputationValidator
      = new BillReadingComputationValidator(ReadQuantity, BeginRead, EndRead, ReadDifference, ReadMultiplier, dataObject);
    billReadingComputationValidator.validateReadQuantity(dataObject);
    validateEarlyDates(ReadBeginDate, ReadEndDate, dataObject);
    validateTodayDateReadDate(ReadBeginDate, dataObject, "Read begin date cannot be a date after today's date", 0);
    validateTodayDateReadDate(ReadEndDate, dataObject, "Read end date cannot be a date after today's date", 0);
    validateTodayDateReadDate(ReadBeginDate, dataObject, "Read Begin Date cannot be more than 180 days from today.", 180);
  }

  validateRequiredAttributes(dataObject: DataObject) {
    this.billReadingTaxonComponent?.forEach((item) => {
      const convertedTagString = item.label.replaceAll(" ", "_").toLowerCase();
      item.setRequiredComponent({requiredAttribute: false, description: `Missing ${convertedTagString}`});
      checkRequiredAttribute(item, dataObject);
    });
  }

  validateReadDates(dataObject) {
    if (!this.billReadingTaxonComponent) {
      return;
    }
    const {ReadBeginDate, ReadEndDate, BillingDays} = Object.fromEntries(this.billReadingTaxonComponent);
    validateReadDates(ReadBeginDate, ReadEndDate, BillingDays, dataObject);
  }

  validatedException(dataObject: DataObject): string[] {
    const exceptionUuids: string[] = [];
    // TODO clean this up
    // Get all the dataExceptions based on the messages
    if (dataObject.dataExceptions) {
      for (const dataException of dataObject.dataExceptions) {
        if (dataException.message.includes("Missing")) {
          exceptionUuids.push(dataException.uuid as string);
        } else if (dataException.message.includes("date is after end")) {
          exceptionUuids.push(dataException.uuid as string);
        } else if (dataException.message.includes("DOES NOT equal")) {
          exceptionUuids.push(dataException.uuid as string);
        } else if (dataException.message.includes("does not equal number")) {
          exceptionUuids.push(dataException.uuid as string);
        }
      }

      if (dataObject.attributes) {
        for (const attribute of dataObject.attributes) {
          if (attribute.dataExceptions) {
            for (const dataException of attribute.dataExceptions) {
              if (dataException.message.includes("cannot be a date after")) {
                exceptionUuids.push(dataException.uuid as string);
              } else if (dataException.message.includes("cannot be more than")) {
                exceptionUuids.push(dataException.uuid as string);
              } else if (dataException.message.includes("Invalid value for")) {
                exceptionUuids.push(dataException.uuid as string);
              } else if (dataException.message.includes("Invalid date format")) {
                exceptionUuids.push(dataException.uuid as string);
              }
            }
          }
        }
      }
    }
    return exceptionUuids;
  }
}

class BillReadingComputationValidator {
  constructor(private readQuantity: BillTaxonComponent, private beginRead: BillTaxonComponent, private endRead: BillTaxonComponent,
              private readDifference: BillTaxonComponent, private readMultiplier: BillTaxonComponent, private dataObject: DataObject) {
  }

  validateReadQuantity(dataObject: DataObject) {
    if (!this.readQuantity.value && !this.endRead.value && !this.beginRead.value) {
      return;
    }
    const computedReadDifference = this.getComputedReadDifference(this.endRead.value, this.beginRead.value);
    let notes = `\n\t + ${this.endRead.value} ${this.endRead.label}`;
    notes += ` \n\t - ${this.beginRead.value} ${this.beginRead.label}`;

    if (!computedReadDifference) {
      return;
    }
    if (this.readDifference.value && this.readMultiplier.value) {
      const computedReadQuantity = this.readDifference.value.times(this.readMultiplier.value);
      notes = `\n\t + ${this.readDifference.value} ${this.readDifference.label}`;
      notes += `\n\t * ${this.readMultiplier.value} ${this.readMultiplier.label}`;
      this.validateTotalAmount(computedReadQuantity, this.readQuantity, notes,
        "read_difference * read_multiplier DOES NOT equal read_quantity", dataObject);
    } else if (this.readMultiplier.value) {
      notes += `\n\t * ${this.readMultiplier.value} ${this.readMultiplier.label}`;
      const computedReadQuantity = computedReadDifference.times(this.readMultiplier.value);
      this.validateTotalAmount(computedReadQuantity, this.readQuantity, notes,
        "read_difference * read_multiplier DOES NOT equal read_quantity", dataObject);
    } else {
      this.validateTotalAmount(computedReadDifference, this.readQuantity, notes,
        "end_read - begin_read DOES NOT equal read_quantity", dataObject);
    }
  }

  validateReadDifference(dataObject) {
    const exceptionMessage = "read_difference DOES NOT equal read_quantity";
    if (!this.readDifference.value) {
      return;
    }
    const computedReadDifference = this.getComputedReadDifference(this.endRead.value, this.beginRead.value);

    if (!computedReadDifference) {
      return;
    }

    let notes = `\n\t+ ${this.endRead.value} ${this.endRead.label}`;
    notes += `\n\t- ${this.beginRead.value} ${this.beginRead.label}`;
    this.validateTotalAmount(computedReadDifference, this.readDifference, notes, exceptionMessage, dataObject);
  }

  getComputedReadDifference(endReadValue: Decimal | undefined, beginReadValue: Decimal | undefined) {
    if (!endReadValue || !beginReadValue) {
      return undefined;
    }
    return endReadValue.minus(beginReadValue);
  }

  validateTotalAmount(totalAmount: Decimal, totalTaxon: BillTaxonComponent, componentMath, description, dataObject) {
    const partialDataException: Partial<DataException> = {
      open: true,
      exceptionDetails: "",
      message: "",
    };
    const dataException = getDataException("DOES NOT equal read", dataObject);
    const difference = totalTaxon?.value?.minus(totalAmount);
    if (difference && totalTaxon.value && difference.abs().greaterThan(new Decimal(1))) {
      const componentTotal = totalTaxon.value.minus(totalAmount);
      const exceptionDetails = `Total amount ${totalTaxon.value?.toFixed(2)} is different by ${componentTotal.toFixed(2)}:\n${componentMath}\n\n\t= ${totalAmount.toFixed(2)} `;
      if (dataException) {
        partialDataException.open = true;
        partialDataException.exceptionDetails = exceptionDetails;
        partialDataException.message = description;
        appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
      } else {
        createDataException(undefined, exceptionDetails, description, dataObject);
      }
    } else {
      if (dataException) {
        partialDataException.open = false;
        partialDataException.exceptionDetails = dataException.exceptionDetails;
        partialDataException.message = description;
        appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
      }
    }
  }
}

interface RequiredComponent {
  requiredAttribute: boolean;
  description: string;
}

export class MatchingAggregationValidator extends CassValidators {
  private billTaxonComponent: Map<string, BillTaxonComponent> | undefined;
  private exceptionUuids: string[] = [];

  configure(billTaxonComponent: Map<string, BillTaxonComponent>) {
    const taxonComputationComponents = ["BalanceCarryForward", "PreviousBilledAmount", "PaymentsReceived",
      "TotalNewCharges", "TotalBilledAmount", "CreditAdjustments", "LateFee", "Deposit", "DepositInterest"];
    this.billTaxonComponent = new Map([...billTaxonComponent.entries()].filter(([taxon]) => taxonComputationComponents.includes(taxon)));
  }

  getTaxonPath() {
    return "Bill";
  }

  validate(dataObject: DataObject) {
    if (!this.billTaxonComponent) {
      return;
    }
    const {
      BalanceCarryForward,
      PreviousBilledAmount,
      PaymentsReceived,
      TotalNewCharges,
      TotalBilledAmount,
      CreditAdjustments,
      LateFee,
      Deposit,
      DepositInterest,
    }
      = Object.fromEntries(this.billTaxonComponent);

    const validatorList = [
      new BalanceCarryForwardValidator(BalanceCarryForward, PreviousBilledAmount, PaymentsReceived),
      new TotalBilledAmountValidator(TotalBilledAmount, PreviousBilledAmount, PaymentsReceived, BalanceCarryForward, LateFee, Deposit, DepositInterest, CreditAdjustments,
        TotalNewCharges),
      new TotalNewChargesValidator(TotalNewCharges, TotalBilledAmount, PreviousBilledAmount, PaymentsReceived,
        BalanceCarryForward, LateFee, Deposit, DepositInterest, CreditAdjustments),
    ];

    validatorList.forEach((validator) => {
      validator.computeTotalTaxon(dataObject);
      const dataExceptionUuid = validator.getDataExceptionUuid(dataObject);
      if (dataExceptionUuid && !this.exceptionUuids.includes(dataExceptionUuid)) {
        this.exceptionUuids.push(dataExceptionUuid);
      }
    });
  }

  validatedException(): string[] {
    return this.exceptionUuids;
  }
}

abstract class MatchingAggregationAbstractClass {
  protected abstract description: string;
  protected abstract totalTaxon: BillTaxonComponent;
  protected abstract dataException: DataException | undefined;
  protected abstract partialUpdatedException: Partial<DataException>;

  abstract computeTotalTaxon(dataObject: DataObject): void;

  createMergeException(attribute, exceptionDetails, description, dataObject): void {
    const uuid = uuidv4();
    const newException = {
      message: description,
      exceptionDetails,
      severity: "ERROR",
      open: true,
      uuid,
    };
    appStore.workspaceStore.mergeException(attribute, newException, dataObject);
  }

  validateTotalTaxon() {
    if (this.totalTaxon.attribute === undefined || this.totalTaxon.value === undefined) {
      return false;
    }
    return true;
  }

  validateTotalAmount(totalAmount: Decimal, componentMath, dataObject) {
    if (this.totalTaxon.value && totalAmount.comparedTo(this.totalTaxon.value) !== 0) {
      const componentTotal = this.totalTaxon.value.minus(totalAmount.absoluteValue());
      const exceptionDetails = `Total amount $${this.totalTaxon.value?.toFixed(2)} is different by $${componentTotal.toFixed(2)}:\n${componentMath}\n\n\t= $${totalAmount.toFixed(2)} `;
      if (this.dataException) {
        this.partialUpdatedException = {
          open: true,
          message: this.description,
          exceptionDetails,
        };
        appStore.workspaceStore.updateException(dataObject, this.partialUpdatedException, this.dataException.uuid, this.totalTaxon.attribute, true);
      } else {
        this.createMergeException(this.totalTaxon.attribute, exceptionDetails, this.description, dataObject);
      }
    } else {
      if (this.dataException) {
        this.partialUpdatedException = {
          open: false,
          message: this.description,
          exceptionDetails: this.dataException.exceptionDetails,
        };
        appStore.workspaceStore.updateException(dataObject, this.partialUpdatedException, this.dataException.uuid, this.totalTaxon.attribute, true);
      }
    }
  }
}

export class BalanceCarryForwardValidator extends MatchingAggregationAbstractClass {
  protected description = "BCF amount does not match";
  protected dataException: DataException | undefined;
  protected partialUpdatedException: Partial<DataException> = {open: false, exceptionDetails: "", message: ""};

  constructor(protected totalTaxon: BillTaxonComponent, private previousBilledAmount: BillTaxonComponent,
              private paymentsReceived: BillTaxonComponent) {
    super();
  }

  computeTotalTaxon(dataObject: DataObject) {
    if (!this.validateTotalTaxon()) {
      return;
    }

    this.dataException = getDataException(this.description.substring(0, this.description.length - 5), dataObject, this.totalTaxon.attribute, true);
    const fromDataObjectException = getDataException(this.description.substring(0, this.description.length - 3), dataObject);
    if (fromDataObjectException) {
      fromDataObjectException.open = false;
    }

    let totalAmount = new Decimal(0);
    let componentMath = "";
    if (this.previousBilledAmount.attribute !== undefined && this.paymentsReceived.attribute !== undefined && this.previousBilledAmount.value !== undefined && this.paymentsReceived.value !== undefined) {
      const previousBilledAmountNumber = this.previousBilledAmount.value;
      const paymentsReceivedNumber = this.paymentsReceived.value;
      componentMath = `\n\t + $${previousBilledAmountNumber} ${this.previousBilledAmount.label}`;
      componentMath += `\n\t - $${paymentsReceivedNumber} ${this.paymentsReceived.label}`;
      totalAmount = totalAmount.add(previousBilledAmountNumber.minus(paymentsReceivedNumber));
      this.validateTotalAmount(totalAmount, componentMath, dataObject);
    } else {
      if (this.dataException) {
        this.partialUpdatedException = {
          open: false,
          message: this.description,
          exceptionDetails: this.dataException.exceptionDetails,
        };
        appStore.workspaceStore.updateException(dataObject, this.partialUpdatedException, this.dataException.uuid, this.totalTaxon.attribute, true);
      }
    }
  }

  getDataExceptionUuid(dataObject): string | undefined {
    if (!this.dataException) {
      this.dataException = getDataException(this.description.substring(0, this.description.length - 5), dataObject, this.totalTaxon.attribute, true);
    }
    return this.dataException?.uuid as string;
  }
}

export class TotalBilledAmountValidator extends MatchingAggregationAbstractClass {
  protected description = "Total billed amount does not match";
  protected dataException: DataException | undefined;
  protected partialUpdatedException: Partial<DataException> = {open: false, exceptionDetails: "", message: ""};

  constructor(protected totalTaxon: BillTaxonComponent, private previousBilledAmount, private paymentsReceived, private balanceCarryForward,
              private lateFee, private deposit, private depositInterest,
              private creditAdjustments, private totalNewCharges) {
    super();
  }

  computeTotalTaxon(dataObject) {
    if (!this.validateTotalTaxon()) {
      return;
    }

    this.dataException = getDataException(this.description.substring(0, this.description.length - 5), dataObject, this.totalTaxon.attribute, true);
    const fromDataObjectException = getDataException(this.description.substring(0, this.description.length - 5), dataObject);
    if (fromDataObjectException) {
      fromDataObjectException.open = false;
    }
    let componentMath = "";
    let taxonToAdd: BillTaxonComponent[];
    if (this.balanceCarryForward.attribute !== undefined && this.balanceCarryForward.value !== undefined) {
      taxonToAdd
        = [this.totalNewCharges, this.balanceCarryForward, this.lateFee, this.deposit, this.depositInterest, this.creditAdjustments];
    } else {
      taxonToAdd
        = [this.totalNewCharges, this.previousBilledAmount, this.paymentsReceived, this.lateFee, this.deposit, this.depositInterest, this.creditAdjustments];
    }
    const totalAmount = taxonToAdd.reduce((sum, variable) => {
      if (variable.value === undefined) {
        return sum;
      }

      if (variable.label === "Payments Received") {
        componentMath += `\n\t + $-${variable.value} ${variable.label}`;
        return sum.minus(variable.value);
      }

      if (variable.value?.isNeg()) {
        componentMath += `\n\t + $${variable.value} ${variable.label}`;
        return sum.minus(variable.value.absoluteValue());
      } else {
        componentMath += `\n\t + $${variable.value} ${variable.label}`;
        return sum.add(variable.value.absoluteValue());
      }
    }, new Decimal(0));
    this.validateTotalAmount(totalAmount, componentMath, dataObject);
  }

  getDataExceptionUuid(dataObject): string | undefined {
    if (!this.dataException) {
      this.dataException = getDataException(this.description.substring(0, this.description.length - 5), dataObject, this.totalTaxon.attribute, true);
    }
    return this.dataException?.uuid as string;
  }
}

export class TotalNewChargesValidator extends MatchingAggregationAbstractClass {
  protected description = "Total new charges amount does not match";
  protected dataException: DataException | undefined;
  protected partialUpdatedException: Partial<DataException> = {open: false, exceptionDetails: "", message: ""};

  constructor(protected totalTaxon: BillTaxonComponent, private totalBilledAmount: BillTaxonComponent,
              private previousBilledAmount, private paymentsReceived, private balanceCarryForward,
              private lateFee, private deposit, private depositInterest,
              private creditAdjustments) {
    super();
  }

  computeTotalTaxon(dataObject: DataObject) {
    if (!this.validateTotalTaxon()) {
      return;
    }
    this.dataException = getDataException(this.description.substring(0, this.description.length - 5), dataObject, this.totalTaxon.attribute, true);
    const fromDataObjectException = getDataException(this.description.substring(0, this.description.length - 5), dataObject);
    if (fromDataObjectException) {
      fromDataObjectException.open = false;
    }
    let taxonToAdd: BillTaxonComponent[];
    if (this.balanceCarryForward.attribute !== undefined && this.balanceCarryForward.value !== undefined) {
      taxonToAdd
        = [this.totalBilledAmount, this.balanceCarryForward, this.lateFee, this.deposit, this.depositInterest, this.creditAdjustments];
    } else {
      taxonToAdd
        = [this.totalBilledAmount, this.previousBilledAmount, this.paymentsReceived, this.lateFee, this.deposit, this.depositInterest, this.creditAdjustments];
    }
    let componentMath = "";
    const totalAmount = taxonToAdd.reduce((sum, variable) => {
      if (variable.value === undefined) {
        return sum;
      }

      if (variable.label === "Total Billed Amount") {
        componentMath += `\n\t + $${variable.value} ${variable.label}`;
        return variable.value;
      }

      if (variable.label === "Payments Received") {
        componentMath += `\n\t - $-${variable.value} ${variable.label}`;
        return sum.add(variable.value);
      }

      if (variable.value.isNeg()) {
        componentMath += `\n\t - $${variable.value} ${variable.label}`;
        return sum.add(variable.value.absoluteValue());
      } else {
        componentMath += `\n\t - $${variable.value} ${variable.label}`;
        return sum.minus(variable.value.absoluteValue());
      }
    }, new Decimal(0));
    this.validateTotalAmount(totalAmount, componentMath, dataObject);
  }

  getDataExceptionUuid(dataObject): string | undefined {
    if (!this.dataException) {
      this.dataException = getDataException(this.description.substring(0, this.description.length - 5), dataObject, this.totalTaxon.attribute, true);
    }
    return this.dataException?.uuid as string;
  }
}

export class RequiredValidator extends CassValidators {
  private billTaxonComponent: Map<string, BillTaxonComponent> | undefined;
  private exceptionUuids: string[] = [];

  configure(billTaxonComponent: Map<string, BillTaxonComponent>) {
    const taxonComputationComponents = ["InvoiceNumber", "AccountNumber", "DueDate", "InvoiceDate", "TotalBilledAmount"];
    this.billTaxonComponent = new Map([...billTaxonComponent.entries()].filter(([taxon]) => taxonComputationComponents.includes(taxon)));
    const requiredAttributeList = [
      {InvoiceDate: "Missing invoice_date"},
      {DueDate: "Missing due_date"},
      {AccountNumber: "Missing account_number"},
      {TotalBilledAmount: "Missing total_billed_amount"},
    ];

    requiredAttributeList.forEach((item) => {
      const [key, value] = Object.entries(item)[0];
      const taxonComponent = billTaxonComponent.get(key);
      if (taxonComponent) {
        taxonComponent.setRequiredComponent({requiredAttribute: true, description: value});
      }
    });
  }

  getTaxonPath() {
    return "Bill";
  }

  validate(dataObject) {
    this.billTaxonComponent?.forEach((value) => {
      if (value.requiredComponent) {
        checkRequiredAttribute(value, dataObject);
      }
    });
    this.validateSpecialCharacters(dataObject);
  }

  validateSpecialCharacters(dataObject) {
    const validationTaxonList = ["InvoiceNumber", "AccountNumber"];
    validationTaxonList.forEach((taxon) => {
      const taxonComponent = this.billTaxonComponent?.get(taxon);
      validateSpecialCharacters(taxonComponent, dataObject);
    });
  }

  validatedException(dataObject: DataObject): string[] {
    if (dataObject.dataExceptions) {
      for (const dataException of dataObject.dataExceptions) {
        const message = dataException.message;
        if (message.includes("Missing invoice_date") || message.includes("Missing due") || message.includes("Missing account") || message.includes("Missing total_billed")) {
          this.exceptionUuids.push(dataException.uuid as string);
        }
      }
    }
    return this.exceptionUuids;
  }
}

export class DateValidator extends CassValidators {
  private billTaxonComponent: Map<string, BillTaxonComponent> | undefined;
  private exceptionUuids: string[] = [];

  getTaxonPath(): string {
    return "Bill";
  }

  configure(billTaxonComponent: Map<string, BillTaxonComponent>) {
    const taxonComputationComponents = ["DueDate", "InvoiceDate"];
    this.billTaxonComponent = new Map([...billTaxonComponent.entries()].filter(([taxon]) => taxonComputationComponents.includes(taxon)));
  }

  validate(dataObject) {
    if (!this.billTaxonComponent) {
      return;
    }
    const {DueDate, InvoiceDate}
      = Object.fromEntries(this.billTaxonComponent);
    const validateDateList = [new DueDateValidator(DueDate, InvoiceDate)];

    validateDateList.forEach((validator) => {
      validator.validateDate(dataObject);
    });
  }

  validatedException(dataObject: DataObject): string[] {
    if (dataObject.attributes) {
      for (const attribute of dataObject.attributes) {
        if (attribute.dataExceptions) {
          for (const dataException of attribute.dataExceptions) {
            if (dataException.message.includes("Invalid date format")) {
              this.exceptionUuids.push(dataException.uuid as string);
            }
          }
        }
      }
    }
    return this.exceptionUuids;
  }
}

abstract class DateComponentAbstractClass {
  protected abstract description: string;
  protected abstract dataException: DataException | undefined;
  protected abstract partialUpdatedException: Partial<DataException>;

  abstract validateDate(dataObject: DataObject): void;

  createMergeException(attribute, description, dataObject): void {
    if (this.dataException?.open === false) {
      return;
    }
    if (this.dataException) {
      this.partialUpdatedException = {
        open: true,
        message: this.description,
        exceptionDetails: this.dataException.exceptionDetails,
      };

      appStore.workspaceStore.updateException(dataObject, this.partialUpdatedException, this.dataException.uuid, attribute, true);
    } else {
      const uuid = uuidv4();
      const newException = {
        message: this.description,
        severity: "ERROR",
        open: true,
        uuid,
      };
      appStore.workspaceStore.mergeException(attribute, newException, dataObject);
    }
  }
}

export class DueDateValidator extends DateComponentAbstractClass {
  protected description = "Due date cannot be more than 100 days from invoice date.";
  protected dataException: DataException | undefined;
  protected partialUpdatedException: Partial<DataException> = {open: false, exceptionDetails: "", message: ""};

  constructor(private dueDate: BillTaxonComponent, private invoiceDate: BillTaxonComponent) {
    super();
  }

  validateDate(dataObject: DataObject) {
    if (this.dueDate.attribute === undefined) {
      return;
    }
    this.dataException = getDataException(this.description.substring(0, this.description.length - 5), dataObject, this.dueDate.attribute, true);
    const fromDataObjectException = getDataException(this.description.substring(0, this.description.length - 5), dataObject);
    if (fromDataObjectException) {
      fromDataObjectException.open = false;
    }
    if (!this.dueDate.attribute.stringValue
      || this.invoiceDate.attribute === undefined || !this.invoiceDate.attribute.dateValue) {
      if (this.dataException) {
        this.partialUpdatedException = {
          open: false,
          exceptionDetails: this.dataException.exceptionDetails,
          message: this.dataException.message,
        };
        appStore.workspaceStore.updateException(dataObject, this.dataException, this.dueDate.attribute, true);
      }
      return;
    }

    const dueDateString = this.dueDate.attribute.stringValue;
    const invoiceDateString = DateTime.fromISO(this.invoiceDate.attribute.dateValue).toFormat("yyyy-MM-dd");
    const diffDays = DateTime.fromFormat(dueDateString, "yyyy-MM-dd").diff(DateTime.fromFormat(invoiceDateString, "yyyy-MM-dd"), "days").toObject().days;

    if (diffDays !== undefined && Math.abs(diffDays) > 100) {
      console.log('looking if we can create an exception for this')
      this.createMergeException(this.dueDate.attribute, this.description, dataObject);
    } else {
      if (this.dataException) {
        this.partialUpdatedException = {
          open: false,
          exceptionDetails: this.dataException.exceptionDetails,
          message: this.dataException.message,
        };
        appStore.workspaceStore.updateException(dataObject, this.partialUpdatedException, this.dataException.uuid, this.dueDate.attribute, true);
      }
    }
  }

  getDataExceptionUuid(dataObject): string | undefined {
    if (!this.dataException) {
      this.dataException = getDataException(this.description, dataObject, this.dueDate.attribute, true);
    }

    return this.dataException?.uuid;
  }
}

// Checks only if the attribute has a 'Missing' Exception from the start
function checkRequiredAttribute(value: BillTaxonComponent, dataObject) {
  let valid = false;
  const exceptionMessage = value.requiredComponent?.description || "Missing description";
  const dataException = getDataException(exceptionMessage, dataObject);
  const partialDataException: Partial<DataException> = {
    open: true,
    message: "",
    exceptionDetails: "",
  };
  const attribute: DataAttribute | undefined = value?.attribute;

  if (!attribute) {
    valid = false;
  } else if (attribute?.typeAtCreation === "STRING" || attribute?.typeAtCreation === "SELECTION") {
    valid = attribute.stringValue !== undefined && attribute.stringValue !== "" && attribute.stringValue.trim() !== "";
  } else if (attribute?.typeAtCreation === "NUMBER") {
    valid = attribute.decimalValue !== undefined && attribute.decimalValue !== null;
  } else if (attribute?.typeAtCreation === "DATE") {
    valid = !!attribute.dateValue;
  } else if (attribute?.typeAtCreation === "BOOLEAN") {
    valid = !!attribute.booleanValue;
  } else if (attribute?.typeAtCreation === "DATE_TIME") {
    valid = !!attribute.dateValue;
  } else if (attribute?.typeAtCreation === "CURRENCY") {
    valid = attribute.decimalValue !== undefined && attribute.decimalValue !== null;
  }

  if (!valid) {
    if (dataException) {
      partialDataException.message = dataException.message;
      partialDataException.open = true;
      partialDataException.exceptionDetails = dataException.exceptionDetails;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
    } else if (value.requiredComponent?.requiredAttribute) {
      const uuid = uuidv4();
      const newException = {
        message: exceptionMessage,
        severity: "ERROR",
        open: true,
        uuid,
      };
      appStore.workspaceStore.mergeException([], newException, dataObject, true);
    }
  } else {
    if (dataException) {
      partialDataException.open = false;
      partialDataException.exceptionDetails = dataException.exceptionDetails;
      partialDataException.message = dataException.message;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
    }
  }
}

function createDataException(attribute, exceptionDetails, description, dataObject: DataObject): void {
  const uuid = uuidv4();
  const newException = {
    message: description,
    exceptionDetails,
    severity: "ERROR",
    open: true,
    uuid,
  };

  if (attribute) {
    appStore.workspaceStore.mergeException(attribute, newException, dataObject);
  } else {
    appStore.workspaceStore.mergeException([], newException, dataObject, true);
  }
}

function validateSpecialCharacters(taxonComponent: BillTaxonComponent | undefined, dataObject) {
  if (!taxonComponent || taxonComponent.attribute?.stringValue === undefined) {
    return;
  }

  const regexSpecialCharacters = /[,\-@%()]/g;
  const convertedTagString = taxonComponent.label.replaceAll(" ", "_").toLowerCase();
  const exceptionMessage = `${convertedTagString} contains special character`;
  if (regexSpecialCharacters.test(taxonComponent.attribute.stringValue)) {
    createDataException(taxonComponent.attribute, `Special character found ',-@%()' in ${taxonComponent.label}`, exceptionMessage, dataObject);
  } else {
    appStore.workspaceStore.removeException(taxonComponent.attribute, exceptionMessage);
  }
}

function validateReadDates(beginDate: BillTaxonComponent, endDate: BillTaxonComponent, periodDays: BillTaxonComponent, dataObject) {
  const partialDataException: Partial<DataException> = {
    open: true,
    exceptionDetails: "",
  };
  if (!beginDate.attribute?.dateValue || !endDate.attribute?.dateValue || !periodDays.attribute?.decimalValue) {
    return;
  }
  const exceptionMessage = "end_date - begin_date does not equal number of days (+/-2)";
  const dataException = getDataException(exceptionMessage.substring(0, exceptionMessage.length - 10), dataObject);

  const formattedServiceBeginDate = DateTime.fromISO(beginDate.attribute.dateValue);
  const formattedServiceEndDate = DateTime.fromISO(endDate.attribute.dateValue);
  const servicePeriodDays = new Decimal(periodDays.attribute.decimalValue as number);
  const diffDays = formattedServiceEndDate.diff(formattedServiceBeginDate, ["days"]).toObject().days;
  if (!diffDays) {
    return;
  }
  const decimalDays = new Decimal(diffDays);
  const totalDifference = decimalDays.minus(servicePeriodDays).abs();

  if (totalDifference.abs().greaterThan(2)) {
    let notes = `Total days ${servicePeriodDays.toString()} is different by ${totalDifference.toString()}:\n`;
    notes += `\n\t + ${formattedServiceEndDate.toFormat("LLL dd, yyyy")} ${endDate.label}`;
    notes += `\n\t - ${formattedServiceBeginDate.toFormat("LLL dd, yyyy")} ${beginDate.label}\n\n`;
    notes += `\n\t = ${decimalDays.toString()} Days`;
    if (dataException) {
      partialDataException.open = true;
      partialDataException.exceptionDetails = notes;
      partialDataException.message = dataException.message;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
    } else {
      createDataException(undefined, notes, exceptionMessage, dataObject);
    }
  } else {
    if (dataException) {
      partialDataException.open = false;
      partialDataException.exceptionDetails = dataException.exceptionDetails;
      partialDataException.message = dataException.message;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
    }
  }
}

function validateEarlyDates(beginDate: BillTaxonComponent, endDate: BillTaxonComponent, dataObject: DataObject) {
  const exceptionMessage = "Begin date is after end date";
  const dataException = getDataException(exceptionMessage.substring(0, exceptionMessage.length - 5), dataObject);
  const partialDataException: Partial<DataException> = {
    open: true,
    exceptionDetails: "",
  };
  if (!beginDate.attribute?.dateValue || !endDate.attribute?.dateValue) {
    if (dataException) {
      partialDataException.open = false;
      partialDataException.exceptionDetails = dataException.exceptionDetails;
      partialDataException.message = dataException.message;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
    }
    return;
  }

  const formattedEndDate = DateTime.fromISO(endDate.attribute.dateValue);
  const formattedBeginDate = DateTime.fromISO(beginDate.attribute.dateValue);
  const diffDays = formattedEndDate.diff(formattedBeginDate, "days").toObject().days;

  if (diffDays !== undefined && diffDays < 0) {
    let notes = "Read Date Computation:\n";
    notes += `\n\t + ${formattedEndDate.toFormat("LLL dd, yyyy")} ${endDate.label}`;
    notes += `\n\t - ${formattedBeginDate.toFormat("LLL dd, yyyy")} ${beginDate.label}\n\n`;
    notes += `\n\t = ${diffDays.toString()} Days`;
    if (dataException) {
      // Just update the data exception
      partialDataException.open = true;
      partialDataException.exceptionDetails = notes;
      partialDataException.message = dataException.message;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
    } else {
      createDataException(undefined, notes, exceptionMessage, dataObject);
    }
  } else {
    if (dataException) {
      partialDataException.open = false;
      partialDataException.exceptionDetails = dataException.exceptionDetails;
      partialDataException.message = dataException.message;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
    }
  }
}

function validateTodayDateReadDate(readDate: BillTaxonComponent, dataObject: DataObject, exceptionMessage: string, tolerance: number, overrideable = false) {
  const dataException = getDataException(exceptionMessage.substring(0, exceptionMessage.length - 5), dataObject, readDate.attribute, true);
  if (overrideable) {
    if (dataException?.open === false) {
      return;
    }
  }
  const partialDataException: Partial<DataException> = {
    open: true,
    exceptionDetails: "",
  };
  // Get the data exception of the exception message
  if (!readDate.attribute?.dateValue) {
    if (dataException) {
      partialDataException.open = false;
      partialDataException.exceptionDetails = dataException.exceptionDetails;
      partialDataException.message = dataException.message;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
    }
    return;
  }

  const formattedDate = DateTime.fromISO(readDate.attribute.dateValue);
  const dateToday = DateTime.now();

  const diffDays = dateToday.diff(formattedDate, "days").toObject().days;
  if (diffDays !== undefined && (((Math.abs(Math.round(diffDays)) > tolerance) && tolerance !== 0) || ((Math.round(diffDays) < tolerance) && tolerance === 0))) {
    let notes = "Read Date Computation:\n";
    notes += `\n\t + ${dateToday.toFormat("LLL dd, yyyy")} Date Today`;
    notes += `\n\t - ${formattedDate.toFormat("LLL dd, yyyy")} ${readDate.label}\n\n`;
    notes += `\n\t = ${Math.abs(Math.round(diffDays)).toString()} Days`;
    if (dataException) {
      // Just update the data exception
      partialDataException.open = true;
      partialDataException.exceptionDetails = notes;
      partialDataException.message = dataException.message;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid, readDate.attribute, true);
    } else {
      createDataException(readDate.attribute, notes, exceptionMessage, dataObject);
    }
  } else {
    if (dataException) {
      partialDataException.open = false;
      partialDataException.exceptionDetails = dataException.exceptionDetails;
      partialDataException.message = dataException.message;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid, readDate.attribute, true);
    }
  }
}

function validateUOM(labeledData: DataAttribute | undefined, validUom: string[], exceptionMessage: string, dataObject) {
  const exceptionDetails = `Valid UOMs: ${validUom.join(", ")}`;
  const partialDataException: Partial<DataException> = {
    open: true,
    message: exceptionMessage,
    exceptionDetails,
  };

  const dataException = getDataException(exceptionMessage.substring(0, exceptionMessage.length - 5), dataObject);
  if (labeledData?.stringValue && validUom.includes(labeledData?.stringValue.toUpperCase())) {
    if (dataException) {
      partialDataException.open = false;
      partialDataException.message = dataException?.message;
      partialDataException.exceptionDetails = dataException?.exceptionDetails;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
    }
  } else {
    if (!dataException) {
      const uuid = uuidv4();
      const newException = {
        message: exceptionMessage,
        exceptionDetails,
        severity: "ERROR",
        open: true,
        uuid,
      };
      appStore.workspaceStore.mergeException([], newException, dataObject, true);
    } else {
      partialDataException.open = true;
      partialDataException.message = exceptionMessage;
      partialDataException.exceptionDetails = exceptionDetails;
      appStore.workspaceStore.updateException(dataObject, partialDataException, dataException.uuid);
    }
  }
}
