/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListProductProjectTemplatesParams
} from '../../model/listProductProjectTemplatesParams'
import type {
  PageProductProjectTemplate
} from '../../model/pageProductProjectTemplate'
import type {
  ProductProjectTemplate
} from '../../model/productProjectTemplate'
import type {
  ProjectTemplate
} from '../../model/projectTemplate'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getProductProjectTemplate = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProductProjectTemplate>(
      {url: `/api/productProjectTemplates/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProductProjectTemplateQueryKey = (id: MaybeRef<string>,) => {
    return ['api','productProjectTemplates',id] as const;
    }

    
export const getGetProductProjectTemplateQueryOptions = <TData = Awaited<ReturnType<typeof getProductProjectTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductProjectTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProductProjectTemplateQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProductProjectTemplate>>> = ({ signal }) => getProductProjectTemplate(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProductProjectTemplate>>, TError, TData> 
}

export type GetProductProjectTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getProductProjectTemplate>>>
export type GetProductProjectTemplateQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetProductProjectTemplate = <TData = Awaited<ReturnType<typeof getProductProjectTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProductProjectTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProductProjectTemplateQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateProductProjectTemplate = (
    id: MaybeRef<string>,
    productProjectTemplate: MaybeRef<ProductProjectTemplate>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
productProjectTemplate = unref(productProjectTemplate);
      
      return customAxios<ProductProjectTemplate>(
      {url: `/api/productProjectTemplates/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: productProjectTemplate
    },
      options);
    }
  


export const getUpdateProductProjectTemplateMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProductProjectTemplate>>, TError,{id: string;data: ProductProjectTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProductProjectTemplate>>, TError,{id: string;data: ProductProjectTemplate}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProductProjectTemplate>>, {id: string;data: ProductProjectTemplate}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProductProjectTemplate(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateProductProjectTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof updateProductProjectTemplate>>>
    export type UpdateProductProjectTemplateMutationBody = ProductProjectTemplate
    export type UpdateProductProjectTemplateMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateProductProjectTemplate = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProductProjectTemplate>>, TError,{id: string;data: ProductProjectTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateProductProjectTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteProductProjectTemplate = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/productProjectTemplates/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProductProjectTemplateMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProductProjectTemplate>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProductProjectTemplate>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProductProjectTemplate>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteProductProjectTemplate(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteProductProjectTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProductProjectTemplate>>>
    
    export type DeleteProductProjectTemplateMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteProductProjectTemplate = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProductProjectTemplate>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteProductProjectTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listProductProjectTemplates = (
    params?: MaybeRef<ListProductProjectTemplatesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageProductProjectTemplate>(
      {url: `/api/productProjectTemplates`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListProductProjectTemplatesQueryKey = (params?: MaybeRef<ListProductProjectTemplatesParams>,) => {
    return ['api','productProjectTemplates', ...(params ? [params]: [])] as const;
    }

    
export const getListProductProjectTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof listProductProjectTemplates>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListProductProjectTemplatesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProductProjectTemplates>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListProductProjectTemplatesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProductProjectTemplates>>> = ({ signal }) => listProductProjectTemplates(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProductProjectTemplates>>, TError, TData> 
}

export type ListProductProjectTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof listProductProjectTemplates>>>
export type ListProductProjectTemplatesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListProductProjectTemplates = <TData = Awaited<ReturnType<typeof listProductProjectTemplates>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListProductProjectTemplatesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProductProjectTemplates>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListProductProjectTemplatesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createProductProjectTemplate = (
    productProjectTemplate: MaybeRef<ProductProjectTemplate>,
 options?: SecondParameter<typeof customAxios>,) => {
      productProjectTemplate = unref(productProjectTemplate);
      
      return customAxios<ProductProjectTemplate>(
      {url: `/api/productProjectTemplates`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: productProjectTemplate
    },
      options);
    }
  


export const getCreateProductProjectTemplateMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProductProjectTemplate>>, TError,{data: ProductProjectTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProductProjectTemplate>>, TError,{data: ProductProjectTemplate}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProductProjectTemplate>>, {data: ProductProjectTemplate}> = (props) => {
          const {data} = props ?? {};

          return  createProductProjectTemplate(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateProductProjectTemplateMutationResult = NonNullable<Awaited<ReturnType<typeof createProductProjectTemplate>>>
    export type CreateProductProjectTemplateMutationBody = ProductProjectTemplate
    export type CreateProductProjectTemplateMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateProductProjectTemplate = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProductProjectTemplate>>, TError,{data: ProductProjectTemplate}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateProductProjectTemplateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create an association between a product and a project template
 */
export const createProductProjectTemplates = (
    productId: MaybeRef<string>,
    projectTemplate: MaybeRef<ProjectTemplate[]>,
 options?: SecondParameter<typeof customAxios>,) => {
      productId = unref(productId);
projectTemplate = unref(projectTemplate);
      
      return customAxios<ProductProjectTemplate[]>(
      {url: `/api/productProjectTemplates/product/${productId}/projectTemplate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: projectTemplate
    },
      options);
    }
  


export const getCreateProductProjectTemplatesMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProductProjectTemplates>>, TError,{productId: string;data: ProjectTemplate[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProductProjectTemplates>>, TError,{productId: string;data: ProjectTemplate[]}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProductProjectTemplates>>, {productId: string;data: ProjectTemplate[]}> = (props) => {
          const {productId,data} = props ?? {};

          return  createProductProjectTemplates(productId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateProductProjectTemplatesMutationResult = NonNullable<Awaited<ReturnType<typeof createProductProjectTemplates>>>
    export type CreateProductProjectTemplatesMutationBody = ProjectTemplate[]
    export type CreateProductProjectTemplatesMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateProductProjectTemplates = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProductProjectTemplates>>, TError,{productId: string;data: ProjectTemplate[]}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateProductProjectTemplatesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForProductProjectTemplate = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/productProjectTemplates/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForProductProjectTemplateQueryKey = (id: MaybeRef<string>,) => {
    return ['api','productProjectTemplates',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForProductProjectTemplateQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForProductProjectTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProductProjectTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForProductProjectTemplateQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForProductProjectTemplate>>> = ({ signal }) => getChangeSequenceForProductProjectTemplate(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProductProjectTemplate>>, TError, TData> 
}

export type GetChangeSequenceForProductProjectTemplateQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForProductProjectTemplate>>>
export type GetChangeSequenceForProductProjectTemplateQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForProductProjectTemplate = <TData = Awaited<ReturnType<typeof getChangeSequenceForProductProjectTemplate>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProductProjectTemplate>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForProductProjectTemplateQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



