<script lang="ts" setup>
import type { Store } from "pinia";
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { Handle, Position } from "@vue-flow/core";
import appStore from "~/store";
import type { DataFlowNode } from "~/model";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["selected", "selectedStep"]);

const { modelStores } = storeToRefs(appStore.projectStore);

const modelStore = computed(() => {
  return modelStores.value.find((modelStore: Store) => props.node && modelStore.ref === props.node.id?.split("//")[1]);
});
</script>

<template>
  <div @click="emit('selected')">
    <img
      style="float:left" class="ml-2 mr-3 h-12 rounded-md"
      :src="modelStore.imageUrl" alt=""
    >
    <div class="pt-1 text-sm text-gray-700" style="width: 200px">
      {{ modelStore?.name || 'Model Store' }}
    </div>
    <div class="pl-2 text-xs text-gray-500" style="width: 200px">
      Model
    </div>
  </div>
  <Handle id="resourceConnection" type="source" :position="Position.Left" />
  <Handle id="publishes" type="source" :position="Position.Bottom" />
  <Handle id="consumes" type="target" :position="Position.Top" />
</template>

<style scoped>

</style>
