<script setup lang="ts">
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option } from "~/model";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);
const emit = defineEmits(["update:modelValue"]);
const {
  isShown,
  collapsed,
  type,
  localValue,
} = useConfigurationBase(props, emit);
</script>

<template>
  <div v-if="isShown">
    <!-- Handling the groups -->
    <div
      v-if="type === 'object' && item.properties && item.properties.collapsable === true"
      style="margin-top:2px;margin-left:2px; width: 100%"
    >
      <div class="text-md flex text-gray-700" @click="collapsed = !collapsed">
        <span class="mr-2">{{ item.label }}</span>
        <MaterialDesignIcon v-if="collapsed" name="upArrow" size="16" class="text-theme-primary mt-1" />
        <MaterialDesignIcon v-else name="downArrow" size="16" class="text-theme-primary mt-1" />
      </div>
      <div v-show="!collapsed">
        <div class="text-sm text-gray-700">
          {{ item.description }}
        </div>
        <div v-for="groupItem in item.groupOptions" :key="groupItem.name">
          <ConfigurationOption
            v-model="localValue" :content-object="contentObject" :errors="errors"
            :item="groupItem"
            :disabled="disabled"
          />
        </div>
      </div>
    </div>
    <div
      v-if="type === 'object' && (!item.properties || !item.properties.collapsable)"
      style="margin-top:2px;margin-left:2px; width: 100%"
    >
      <div class="text-md mt-3 text-gray-700">
        {{ item.label }}
      </div>
      <div class="text-sm text-gray-700">
        {{ item.description }}
      </div>
      <div v-for="groupItem in item.groupOptions" :key="groupItem.name">
        <ConfigurationOption
          v-model="localValue" :content-object="contentObject" :errors="errors"
          :item="groupItem"
          :disabled="disabled"
        />
      </div>
    </div>
    <!-- End Handling the groups -->
  </div>
</template>

<style scoped>

</style>
