<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import type { Ref } from "vue";
import appStore from "~/store";
import { createProjectTemplateFromRequest, createProjectTemplateRequest } from "~/api/projects/projects";
import type { ProjectTemplateRequest } from "~/model";

const emit = defineEmits(["confirm", "cancel", "update:modelValue"]);
const open = ref(true);

function close() {
  emit("cancel");
}

const projectCreationRequest: Ref<undefined | ProjectTemplateRequest> = ref(undefined);

onMounted(() => {
  const projectId = appStore.projectStore.project.id;
  createProjectTemplateRequest(projectId).then((response) => {
    projectCreationRequest.value = response;

  });
});

const saving = ref(false);

function createTemplate() {
  const projectId = appStore.projectStore.project.id;
  saving.value = true;
  createProjectTemplateFromRequest(projectId, projectCreationRequest.value).then((projectTemplate) => {
    emit("confirm", projectCreationRequest.value);
    saving.value = false;
  });
}
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" @close="close()">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 950px; height: 600px;"
              class="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  @click="emit('cancel')"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" />
                </button>
              </div>
              <DialogTitle class="flex text-lg font-medium leading-6 text-gray-900">
                <KodexaHeader
                  title="Create a Project Template"
                  sub-title="Make a new project template based on this project"
                />
              </DialogTitle>
              <div class="h-96">
                <div v-if="projectCreationRequest" class="flex p-1" style="z-index: 5000">
                  <!-- Project Details Side -->
                  <div class="flex-1 p-4">
                    <KodexaArticle article-id="9027817" text="Learn more about project templates" />
                    <div class="mb-4">
                      <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
                      <KodexaTextInput
                        id="projectTemplateName"
                        v-model="projectCreationRequest.name" name="projectTemplateName"
                      />
                    </div>
                    <div>
                      <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
                      <KodexaTextArea
                        id="projectTemplateDescription"
                        v-model="projectCreationRequest.description" name="description" :rows="8"
                      />
                    </div>
                  </div>

                  <!-- Options Side -->
                  <div class="h-96 flex-1 overflow-y-scroll p-4">
                    <div v-if="projectCreationRequest.options">
                      <h2 class="font-medium text-gray-900">
                        Options
                      </h2>
                      <div v-for="option in projectCreationRequest.options.options" :key="option.name">
                        <ConfigurationOption
                          v-model="projectCreationRequest.options.properties"
                          :item="option"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <KodexaSmallLoader class="mt-48" />
                </div>
              </div>
              <div class="mt-8 sm:flex sm:flex-row-reverse">
                <KodexaButton
                  id="createTemplate"
                  type="primary"
                  icon="auto-fix"
                  :loading="saving"
                  @click="createTemplate"
                >
                  Create Template
                </KodexaButton>
                <KodexaButton
                  id="cancelCreateTemplate"
                  type="secondary"
                  class="mr-2"
                  :disabled="saving"
                  icon="cancel"
                  @click="emit('cancel')"
                >
                  Cancel
                </KodexaButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
