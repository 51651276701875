<script setup lang="ts">
import { log } from "~/utils/logger";

const props = defineProps({
  color: String,
  size: {type: String, default: "w-8"},
  objectInstance: Object,
  objectType: String,
});

const showImage = ref(true);

const AVATARS: {
  [key: string]: string;
} = {
  project: "/assets/project.png",
  document: "/assets/header_icons/document.png",
  user: "/assets/header_icons/user.png",
  organization: "/assets/header_icons/organization.png",
  team: "/assets/header_icons/team.png",
  manage: "/assets/header_icons/manage.png",
  dataStore: "/assets/header_icons/datastore.png",
  documentStore: "/assets/header_icons/documentstore.png",
  recent: "/assets/header_icons/recent.png",
  platformManagement: "/assets/header_icons/platformmanagement.png",
  taxonomy: "/assets/header_icons/taxonomy.png",
};

function handleImageError() {
  log.info(`Error loading image ${imageSrc.value}`);
  showImage.value = false;
}

const imageSrc = computed(() => {

  if (props.objectInstance && props.objectInstance.hasImage) {
    return `/api/assets/${props.objectType}/${props.objectInstance.id}`;
  }

  if (props.objectType && AVATARS[props.objectType]) {
    return AVATARS[props.objectType];
  }
  return "";
});

const hasImage = computed(() => {
  return props.objectInstance && props.objectInstance.hasImage;
});
</script>

<template>
  <div class="mx-4 mt-1">
    <div class="avatar rounded-full" :class="[size]">
      <img
        v-if="objectType && AVATARS[objectType] && showImage"
        :src="imageSrc"
        alt="Image"
        @error="handleImageError"
      >
      <div v-else :class="[size]" class="text-white">
        <MaterialDesignIcon :name="objectType as string" size="42" class="ml-3 mt-2"/>
      </div>
    </div>
  </div>
</template>
