<script setup lang="ts">
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import {ref, computed, watch} from 'vue';
import {useCreateNotification, createNotification} from "~/api/notifications/notifications";
import {NotificationActivityType} from "~/model/notificationActivityType";
import {NotificationNotificationType} from "~/model/notificationNotificationType";
import type {Notification} from "~/model/notification";
import {updateHandler} from "~/utils/error-handler";
import {NotificationProperties} from "~/model";
import {storeToRefs} from "pinia";
import appStore from "~/store";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "refetch-notifications"]);

const {project} = storeToRefs(appStore.projectStore);
const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});


const newNotification = ref<Partial<Notification>>({
  activityType: undefined,
  notificationType: undefined, // Added this line
  name: '',
  properties: {},
  subscription: '',
});

// Refs for activityType and notificationType
const selectedActivityType = ref<string | undefined>(undefined);
const selectedNotificationType = ref<string | undefined>(undefined); // New ref for Notification Type

const requiredFieldsError = ref<Record<string, string>>({});

const createNotificationMutation = useCreateNotification();

function close() {
  newNotification.value = {
    activityType: undefined,
    notificationType: undefined, // Reset Notification Type
    name: '',
    properties: {},
    subscription: '',
  };
  selectedActivityType.value = undefined;
  selectedNotificationType.value = undefined; // Reset Notification Type
  requiredFieldsError.value = {};
  emit("update:modelValue", false);
}

async function createNewNotification() {
  requiredFields.forEach((field) => {
    const value = field.split('.').reduce((obj, key) => obj && obj[key], newNotification.value);
    if (field === 'notificationType') {
      if (!selectedNotificationType.value) {
        requiredFieldsError.value[field] = "This field is required";
      } else {
        delete requiredFieldsError.value[field];
      }
    } else if (!value) {
      requiredFieldsError.value[field] = "This field is required";
    } else {
      delete requiredFieldsError.value[field];
    }
  });
  console.log("Check for any errors", requiredFieldsError.value)

  if (Object.keys(requiredFieldsError.value).length !== 0) {
    return;
  }

  const notificationTypeEnumValue = notificationTypeMap[selectedNotificationType.value as keyof typeof notificationTypeMap];
  const activityTypeEnumValue = activityTypeMap[selectedActivityType.value as keyof typeof activityTypeMap];
  newNotification.value.properties = {
    ...newNotification.value.properties,
    includeAssignees: includeAssignee.value,
    type: "email",
  };
  const notificationData: Partial<Notification> = {
    project: project.value,
    activityType: activityTypeEnumValue,
    notificationType: notificationTypeEnumValue,
    name: newNotification.value.name,
    properties: newNotification.value.properties,
    subscription: newNotification.value.subscription,
  };

  console.log('Notification payload:', JSON.stringify(notificationData, null, 2));
  console.log('Converted Notification Type:', notificationTypeEnumValue);
  console.log('Converted Activity Type:', activityTypeEnumValue);

  try {
    await createNotificationMutation.mutateAsync({data: notificationData});
    updateHandler(Promise.resolve(), "Notification created successfully");
    close();
    emit("refetch-notifications");
  } catch (error) {
    console.error("Error creating notification:", error);

    let errorMessage = "Failed to create notification. Please try again later.";

    if (error.response) {
      console.log('Error response data:', error.response.data);

      if (error.response.status === 500) {
        errorMessage = "Server error (500): Something went wrong on our end. Please try again later.";
      } else {
        errorMessage += `: ${error.response.data.message || error.response.statusText}`;
      }
    }

    updateHandler(Promise.reject(error), errorMessage);
  }
}


const requiredFields = [
  "activityType",
  "notificationType",
  "name",
  "properties.recipients",
  "properties.subject",
  "properties.body"
];

function clearError(field: string) {
  if (requiredFieldsError.value[field]) {
    const newErrors = { ...requiredFieldsError.value };
    delete newErrors[field];
    requiredFieldsError.value = newErrors;
  }
}

const showSubscriptionField = computed(() => {
  return selectedMappedActivityType.value === NotificationActivityType.CUSTOM_SUBSCRIPTION;
});

const showIncludeAssigneeField = computed(() => {
  return (
    selectedMappedActivityType.value === NotificationActivityType.ASSIGNMENT_ADDED ||
    selectedMappedActivityType.value === NotificationActivityType.ASSIGNMENT_REMOVED
  );
});

const includeAssignee = ref(false);

const selectedMappedActivityType = ref<NotificationActivityType | undefined>(undefined);

watch(selectedActivityType, (newValue) => {
  const mappedValue = activityTypeMap[newValue as keyof typeof activityTypeMap];
  selectedMappedActivityType.value = mappedValue;

  if (mappedValue) {
    newNotification.value.activityType = mappedValue as NotificationActivityType;
    clearError('activityType');

    if (showSubscriptionField.value) {
      console.log("Subscription field should be visible."); // Add this log to verify
      if (!requiredFields.includes('subscription')) {
        requiredFields.push('subscription');
      }
    } else {
      console.log("Subscription field should be hidden."); // Add this log to verify
      const index = requiredFields.indexOf('subscription');
      if (index > -1) {
        requiredFields.splice(index, 1);
      }
      newNotification.value.subscription = '';
      clearError('subscription');
    }

    if (!showIncludeAssigneeField.value) {
      includeAssignee.value = false;
    }
  }
});


const activityTypeMap = {
  "New Upload": NotificationActivityType.NEW_UPLOAD,
  "New Data Available": NotificationActivityType.NEW_DATA_AVAILABLE,
  "Assignment Added": NotificationActivityType.ASSIGNMENT_ADDED,
  "Assignment Removed": NotificationActivityType.ASSIGNMENT_REMOVED,
  "Custom Subscription": NotificationActivityType.CUSTOM_SUBSCRIPTION,
};

const notificationTypeMap = {
  "Email Notification": NotificationNotificationType.EMAIL_NOTIFICATION,
};

const activityTypeItems = Object.keys(activityTypeMap).map((key) => ({
  name: key,
  value: key,
}));

const notificationTypeItems = Object.keys(notificationTypeMap).map((key) => ({
  name: key,
  value: key,
}));

</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0"/>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0" leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="max-h-full flex-1 overflow-y-auto">
                    <!-- Header -->
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            New Email Notification
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Get started by filling in the information below to create a new email notification.
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              id="cancelNewNotification" icon="cancel" type="secondary" size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="createNewNotification" icon="plus" type="primary" size="small"
                              @click="createNewNotification"
                            >
                              Create
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="min-h-0 flex-1 overflow-y-auto">
                      <!-- Divider container -->
                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <!-- Name -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="name"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Name</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="name"
                              v-model="newNotification.name"
                              name="name"
                              :errors="requiredFieldsError"
                              @input="clearError('name')"
                            />
                          </div>
                        </div>
                        <!-- Activity Type -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                          <div>
                            <label
                              for="activity-type"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Activity Type</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaAutocompleteInput
                              id="activity-type"
                              v-model="selectedActivityType"
                              :items="activityTypeItems"
                              text-field="name"
                              value-field="value"
                              name="activityType"
                              class="text-sm"
                              :errors="requiredFieldsError"
                              @input="clearError('activityType')"
                            />
                          </div>
                        </div>
                        <!-- Notification Type (New Field) -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                          <div>
                            <label
                              for="notification-type"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Notification Type</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaAutocompleteInput
                              id="notification-type"
                              v-model="selectedNotificationType"
                              :items="notificationTypeItems"
                              text-field="name"
                              value-field="value"
                              name="notificationType"
                              class="text-sm"
                              :errors="requiredFieldsError"
                              @input="clearError('notificationType')"
                            />
                          </div>
                        </div>
                        <!-- Subscription Field -->
                        <div v-if="showSubscriptionField"
                             class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="notif-subscription"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Subscription</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextArea
                              id="notif-subscription"
                              v-model="newNotification.subscription"
                              name="subscription"
                              :rows="8"
                            />
                            <KodexaArticle class="mb-5 mt-3" article-id="9743955" text="Learn more about custom subscriptions" :slide="false" />
                          </div>
                        </div>

                        <div v-if="notificationTypeMap[selectedNotificationType as keyof typeof notificationTypeMap]==NotificationNotificationType.EMAIL_NOTIFICATION">
                          <!-- Recipient -->
                          <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                for="recipient"
                                class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                              >Recipient</label>
                            </div>
                            <div class="sm:col-span-2">
                              <KodexaTextInput
                                id="recipients"
                                v-model="newNotification.properties.recipients"
                                name="recipients"
                                :errors="{ recipients: requiredFieldsError['properties.recipients'] }"
                                @input="clearError('properties.recipients')"
                              />
                            </div>
                          </div>

                          <!-- Subject -->
                          <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                for="subject"
                                class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                              >Subject</label>
                            </div>
                            <div class="sm:col-span-2">
                              <KodexaTextInput
                                id="subject"
                                v-model="newNotification.properties.subject"
                                name="subject"
                                :errors="{ subject: requiredFieldsError['properties.subject'] }"
                                @input="clearError('properties.subject')"
                              />
                            </div>
                          </div>
                          <!-- Content -->
                          <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                            <div>
                              <label
                                for="email-body"
                                class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                              >Email Body</label>
                            </div>
                            <div class="sm:col-span-2">
                              <KodexaTextArea
                                id="body"
                                v-model="newNotification.properties.body"
                                name="body"
                                :rows="8"
                                :errors="{ body: requiredFieldsError['properties.body'] }"
                                @input="clearError('properties.body')"
                              />
                            </div>
                          </div>
                        </div>
                        <!-- Include Assignee -->
                        <div v-if="showIncludeAssigneeField"
                             class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                          <div>
                            <label for="includeAssignee"
                                   class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Include
                              Assignee</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaCheckbox
                              v-model="includeAssignee"
                              name="includeAssignee"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
