<script setup lang="ts">
import { toRef } from "vue";
import { TextBox } from "@progress/kendo-vue-inputs";

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: "",
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    default: "text",
  },
  label: {
    type: String,
    required: false,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: "",
  },
  autocomplete: {
    type: String,
    default: "",
  },
  showClear: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  loadingPlaceholder: {
    type: String,
    default: "",
  },
  errors: {
    type: Object,
    default: () => {
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: "100%",
  },
  size: {
    type: String,
    default: "medium",
  },
  hint: {
    type: String,
    default: undefined,
  },
  readOnly: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "input", "blur", "on-enter", "on-esc"]);

const name = toRef(props, "name");

const localValue = computed({
  get: () => props.modelValue,
  set: value => emit("update:modelValue", value),
});
</script>

<template>
  <div>
    <div
      :class="{ 'has-error': errors && errors[name], 'success': errors && !errors[name] }"
    />
    <label v-if="label" for="name" class="kodexa-label"> {{ label }}</label>
    <TextBox
      :id="id || name"
      v-model="localValue"
      :size="size"
      :type="type"
      :style="{ width }"
      class="kodexa-input"
      :valid="!errors || errors && !errors[name]"
      :name="name"
      :show-loading-icon="loading"
      :placeholder="loading && loadingPlaceholder.length > 0 ? loadingPlaceholder : placeholder"
      :autocomplete="name"
      :disabled="disabled"
      :show-clear-button="showClear"
      :readonly="readOnly"
      input-suffix="suffix"
      @keyup.enter="emit('on-enter')"
      @keyup.escape="emit('on-esc')"
      @blur="emit('blur', $event)"
      @input="emit('input', $event)"
    >
      <template #suffix>
        <slot name="suffix" />
      </template>
    </TextBox>
    <p v-if="hint" class="text-sm text-gray-500">
      {{ hint }}
    </p>
    <p v-if="errors && errors[name]" class="mt-2 text-sm text-red-600">
      {{ errors[name] }}
    </p>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-sm text-gray-700 mb-1
}

.kodexa-input {
  @apply w-full appearance-none rounded-md border border-gray-300
  px-0 py-0 shadow-sm placeholder:text-gray-400 sm:text-sm
}
.kodexa-input:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
}
.kodexa-input:focus {
  @apply border-blue-500 ring-1 ring-blue-500
}
</style>
