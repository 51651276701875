<template>
  {{ truncatedString }}
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    length: {
      type: Number,
      required: true
    }
  },
  computed: {
    truncatedString() {
      if (this.text.length <= this.length) {
        return this.text;
      }
      const start = this.text.substring(0, this.length / 2);
      const end = this.text.substring(this.text.length - this.length / 2);
      return `${start}...${end}`;
    }
  }
};
</script>
