<script setup lang="ts">
import { storeToRefs } from "pinia";
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import type { PropType } from "vue";
import { use } from "echarts/core";
import { PieChart } from "echarts/charts";
import { LegendComponent, TooltipComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import VChart from "vue-echarts";
import type { TabStripTabProperties } from "@progress/kendo-vue-layout";
import { TabStrip } from "@progress/kendo-vue-layout";
import { getActivityStatistics } from "~/api/stores/stores";
import { RefHelper } from "~/utils/ref-utils";
import type { DashboardWidget, Membership, ProjectStatus } from "~/model";
import appStore from "~/store";

defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: true,
  },
});

use([TooltipComponent, LegendComponent, PieChart, CanvasRenderer]);

const {
  project,
  firstWorkspace,
  documentStores,
} = storeToRefs(appStore.projectStore);

const openingWorkspace = ref(false);

async function openWorkspace() {
  await appStore.workspaceStore.openWorkspace(firstWorkspace.value);
  openingWorkspace.value = false;
}

const localProject = ref(undefined);

watch(project, (newValue) => {
  localProject.value = newValue;
}, { deep: true, immediate: true });

watch(localProject, (newValue) => {
  if (newValue) {
    appStore.projectStore.updateCurrentProject(newValue);
  }
}, {
  deep: true,
});

function updateProjectStatus(projectStatus: ProjectStatus) {
  if (localProject.value) {
    localProject.value.status = projectStatus;
    appStore.projectStore.updateCurrentProject(localProject.value);
  }
}

function updateProjectMember(member: Membership) {
  localProject.value.owner = member?.user ? member.user : undefined;
  appStore.projectStore.updateCurrentProject(localProject.value);
}

const activityStats = asyncComputed(async () => {
  const allStats = [] as any[];
  const colorPalette = ["#00b04f", "#ff0000"];

  for (const store of documentStores.value) {
    if (store.storePurpose === "OPERATIONAL") {
      const refHelper = new RefHelper(store.ref);
      const stats = await getActivityStatistics(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion());
      const successRate = stats.totalDocuments === 0 ? 1 : (stats.totalDocuments - stats.exceptionDocuments) / stats.totalDocuments;
      allStats.push({
        text: `${store.name} (${(successRate * 100).toFixed(2)}% successful)`,
        legend: {
          orient: "vertical",
          left: "left",
          data: ["No Exceptions", "Exceptions"],
        },
        color: colorPalette,
        series: [
          {
            name: "Exception Rate",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["50%", "70%"],
            // adjust the start and end angle
            startAngle: 180,
            endAngle: 360,
            data: [
              { value: stats.totalDocuments - stats.exceptionDocuments, name: "No Exceptions" },
              { value: stats.exceptionDocuments, name: "Exceptions" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      });
    }
  }
  return allStats;
});

const tabs = ref([
  {
    title: "Statistics",
    content: "tabContent",
    titleRender: "titleRender",
  } as TabStripTabProperties,
  {
    title: "Options",
    content: "tabContent",
    titleRender: "titleRender",
  } as TabStripTabProperties,
]);

const currentTab = ref(0);

function changeTab(tab) {
  currentTab.value = tab.selected;
}
</script>

<template>
  <div>
    <div>
      <div v-if="localProject" class="mx-2">
        <KodexaHeader
          title="Project Overview" sub-title="Overview information for your project" :sidebar="false"
          class="ml-2"
        />
        <KodexaArticle class="ml-4 mt-7" article-id="8729166" text="Learn more about projects" :slide="false" />
        <Toolbar
          v-if="firstWorkspace && !widget.properties?.hideOpenWorkspace"
          class="absolute right-2 mb-1 border-0 bg-white"
        >
          <ToolbarSpacer />
          <KodexaButton
            id="openProjectWorkspace" :loading="openingWorkspace" icon="open" title="New Project"
            @click="openWorkspace"
          >
            <span class="ml-1">Open Workspace</span>
          </KodexaButton>
        </Toolbar>
        <div class="flex">
          <!-- Left Panel -->
          <div class="w-1/2 pr-4">
            <dl>
              <div class="mt-4 px-4 py-2">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  Name
                </dt>
                <KodexaClipboardable
                  id="projectName" class=" mt-1 text-sm leading-6 text-gray-700"
                  :content="project.name"
                />
              </div>
              <div class="px-4 py-2">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  Description
                </dt>
                <dd id="projectDescription" class="clipboardable mt-1 text-sm leading-6 text-gray-700">
                  {{ project.description }}
                </dd>
              </div>
              <div v-if="project.templateArticleId" class="px-4 py-2">
                <KodexaArticle
                  :article-id="project.templateArticleId"
                  text="This project was created from a template, click here to learn how to use it"
                  :slide="false"
                />
              </div>
              <div class="px-4 py-1">
                <dt class="text-sm font-medium text-gray-900">
                  Status
                </dt>
                <dd class="my-2 text-sm text-gray-700">
                  <KodexaProjectStatus :project="localProject" :save-value="false" @updated="updateProjectStatus" />
                </dd>
              </div>
              <div class="px-4 py-1">
                <dt class="text-sm font-medium ext-gray-900">
                  Owner
                </dt>
                <dd class="my-1 text-sm  text-gray-700">
                  <KodexaProjectOwner :project="localProject" :save-value="false" @updated="updateProjectMember" />
                </dd>
              </div>
              <div class="px-4 py-1">
                <dt class="text-sm font-medium leading-6 text-gray-900">
                  Notes
                </dt>
                <dd class="my-1 text-sm leading-6 text-gray-700">
                  <KodexaProjectNotes />
                </dd>
              </div>
            </dl>
          </div>
          <!-- Right Panel -->
          <div class="w-1/2 pl-4 -mt-20">
            <TabStrip :animation="true" :selected="currentTab" :tabs="tabs" @select="changeTab">
              <template #titleRender="{ props }">
                <div class="flex">
                  <div class="mt-1 text-xs font-medium" :id="`tab${ props.title }`">
                    {{ props.title }}
                  </div>
                  <MaterialDesignIcon class="ml-2" name="close" size="16" />
                </div>
              </template>
              <template #tabContent>
                <div style="height: calc(100vh - 15rem); overflow-y:scroll; width: 100%">
                  <div v-if="currentTab === 0">
                    <dl>
                      <div v-for="(stat, idx) in activityStats" :key="idx">
                        <h3 class="text-lg font-medium text-gray-900 pb-4">
                          {{ stat.text }}
                        </h3>
                        <VChart :option="stat" style="height: 400px; width: 500px" />
                      </div>
                    </dl>
                  </div>
                  <div v-if="currentTab === 1">
                    <dl>
                      <div v-if="localProject.options.options" class="px-4">
                        <dt class="text-sm font-medium leading-6 text-gray-900">
                          Options
                        </dt>
                      </div>
                      <div v-for="option in localProject.options.options" :key="option.name" class="mb-2 px-4">
                        <ConfigurationOption v-model="localProject.options.properties" :item="option" />
                      </div>
                    </dl>
                  </div>
                </div>
              </template>
            </TabStrip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
