<script setup lang="ts">
import type { Ref } from "vue";
import { Toolbar } from "@progress/kendo-vue-buttons";
import { parse } from "yaml";
import appStore from "~/store";
import type { ExecutionLogEntry } from "~/model";
import { getExecutionLogs } from "~/api/executions/executions";
import { copyToClipboard } from "~/utils/general";
import { log } from "~/utils/logger";
import { updateDocumentFamilyExternalData } from "~/api/document-families/document-families";

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
  kioskMode: {
    type: Boolean,
    default: false,
    required: false,
  },
});

defineEmits(["close"]);
const logs: Ref<string | undefined> = ref(undefined);

const textOverride = ref("");
const view = appStore.workspaceStore.getViewById(props.viewId);

const text = computed({
  get: () => {
    if (textOverride.value !== "") {
      return textOverride.value;
    }

    if (logs.value !== undefined) {
      return logs.value;
    } else if (view.executionId === undefined) {
      return view.text;
    } else {
      loadLogs();
    }

    return view.text;
  },
  set: (value: string) => {
    textOverride.value = value;
  },
});

const currentPage = ref(1);

async function loadLogs() {
  const view = appStore.workspaceStore.getViewById(props.viewId);
  if (view) {
    const response = await getExecutionLogs(view.executionId as string, {
      page: currentPage.value,
      pageSize: 100,
      sort: "logSequence:asc",
    });
    if (logs.value === undefined) {
      logs.value = "";
    }
    if (response.content && response.content.length > 0) {
      logs.value = `${logs.value}\n${response.content
        ? response.content.map((log: ExecutionLogEntry) => {
          return `${log.logDate}: ${log.entry}`;
        }).join("\n")
        : ""}`;
    }
    currentPage.value++;
  }

  setTimeout(() => {
    loadLogs();
  }, 5);
}

function copyIt() {
  copyToClipboard(text.value, "Copied to clipboard");
}

function download() {
  const blob = new Blob([text.value], { type: "text/plain" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = view.title;
  a.click();
  URL.revokeObjectURL(url);
}

async function updateResource() {
  try {
    if (view.language === "yaml") {
      const resource = parse(textOverride.value);
      log.info("Updating resource from YAML");

      if (view.resourceRef.startsWith("external:")) {
        await updateDocumentFamilyExternalData(view.resourceRef.split(":")[1], resource);
      } else {
        appStore.projectStore.updateDataFormByRef(view.resourceRef as string, resource);
      }
    } else {
      const resource = JSON.parse(textOverride.value);
      if (view.resourceRef.startsWith("external:")) {
        await updateDocumentFamilyExternalData(view.resourceRef.split(":")[1], resource);
      } else {
        appStore.projectStore.updateDataFormByRef(view.resourceRef as string, resource);
      }
    }
  } catch (e) {
    console.error("Error parsing resource");
    alert("Error parsing resource");
  }
}
</script>

<template>
  <div>
    <Toolbar class="mb-1 border-0 bg-white">
      <KodexaButton icon="content-copy" size="small" type="secondary" @click="copyIt">
        Copy to Clipboard
      </KodexaButton>
      <KodexaButton icon="download" size="small" type="secondary" @click="download">
        Download
      </KodexaButton>
      <KodexaButton v-if="view.resourceRef" icon="content-save" size="small" type="secondary" @click="updateResource">
        Update
      </KodexaButton>
    </Toolbar>
    <KodexaCodeEditor
      v-if="text" v-model="text" style="height: calc(100vh - 8rem); width: 100%" :readonly="true"
      :language="view.language || 'python'"
    />
  </div>
</template>
