<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ref, computed, watch, PropType } from 'vue';
import { useDeleteAssignmentRule, useUpdateAssignmentRule } from "~/api/assignment-rule/assignment-rule";
import { AssignmentRuleAssignmentType } from "~/model/assignmentRuleAssignmentType";
import type { AssignmentRule } from "~/model/assignmentRule";
import { updateHandler } from "~/utils/error-handler";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { notify } from "notiwind";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  assignment: {
    type: Object as PropType<AssignmentRule>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "refetch-assignments", "assignment-deleted"]);

const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const editedAssignment = ref(JSON.parse(JSON.stringify(props.assignment)) as typeof props.assignment);

const isDynamicUser = ref(editedAssignment.value.assignmentType === AssignmentRuleAssignmentType.DYNAMIC_USER);

const requiredFieldsError = ref<Record<string, string>>({});

const updateAssignmentMutation = useUpdateAssignmentRule();

function close() {
  requiredFieldsError.value = {};
  emit("update:modelValue", false);
}

const requiredFields = ["name", "condition", "userExpression", "priority"];

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

function validateEmail(email: string): boolean {
  return emailRegex.test(email);
}

async function updateAssignment() {
  requiredFields.forEach((field) => {
    if (field === 'priority') {
      if (editedAssignment.value[field] === undefined || editedAssignment.value[field] < 1) {
        requiredFieldsError.value[field] = "Priority must be 1 or greater";
      } else {
        delete requiredFieldsError.value[field];
      }
    } else if (!editedAssignment.value[field]) {
      requiredFieldsError.value[field] = "This field is required";
    } else if (field === 'userExpression' && !isDynamicUser.value) {
      if (!validateEmail(editedAssignment.value[field])) {
        requiredFieldsError.value[field] = "Please enter a valid email address";
      } else {
        delete requiredFieldsError.value[field];
      }
    } else {
      delete requiredFieldsError.value[field];
    }
  });

  if (Object.keys(requiredFieldsError.value).length !== 0) {
    return;
  }

  const assignmentData: Partial<AssignmentRule> = {
    ...editedAssignment.value,
    assignmentType: isDynamicUser.value
      ? AssignmentRuleAssignmentType.DYNAMIC_USER
      : AssignmentRuleAssignmentType.USER,
  };

  try {
    await updateAssignmentMutation.mutateAsync({
      id: editedAssignment.value.id as string,
      data: assignmentData
    });

    updateHandler(Promise.resolve(), "Assignment rule updated successfully");
    emit("refetch-assignments");
    close();
  } catch (error) {
    console.error("Error updating assignment rule:", error);
    updateHandler(Promise.reject(error), "Failed to update assignment rule");
  }
}

function clearError(field: string) {
  if (requiredFieldsError.value[field]) {
    delete requiredFieldsError.value[field];
    requiredFieldsError.value = { ...requiredFieldsError.value };
  }
}

const showAssignToField = computed(() => !isDynamicUser.value);

watch(isDynamicUser, (newValue) => {
  editedAssignment.value.assignmentType = newValue
    ? AssignmentRuleAssignmentType.DYNAMIC_USER
    : AssignmentRuleAssignmentType.USER;
});

const deleteAssignmentMutation = useDeleteAssignmentRule();

async function deleteAssignment() {
  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const { isCanceled } = await deleteConfirmDialog.reveal({
    title: `Delete assignment rule ${editedAssignment.value.name}?`,
    message: "This action cannot be undone.",
  });

  if (!isCanceled) {
    try {
      await deleteAssignmentMutation.mutateAsync({ id: editedAssignment.value.id as string });
      notify({
        group: "generic",
        title: "Success",
        text: "Assignment rule deleted successfully",
      }, 3000);
      emit("assignment-deleted", editedAssignment.value.id);
      close();
    } catch (error) {
      console.error("Error deleting assignment rule:", error);
      updateHandler(Promise.reject(error), "Failed to delete assignment rule");
    }
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0" />
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0" leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="max-h-full flex-1 overflow-y-auto">
                    <!-- Header -->
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            Update Document Assignment
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Change the details required and then choose Save Changes
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              id="cancelEditAssignment" icon="cancel" type="secondary" size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="updateAssignment" icon="content-save" type="primary" size="small"
                              @click="updateAssignment"
                            >
                              Save Changes
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="min-h-0 flex-1 overflow-y-auto">
                      <!-- Divider container -->
                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <!-- Name -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label for="name" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Name</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="name"
                              v-model="editedAssignment.name"
                              name="name"
                              :errors="requiredFieldsError"
                              @input="clearError('name')"
                            />
                          </div>
                        </div>

                        <!-- Condition -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label for="condition" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Condition</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextArea
                              id="condition"
                              v-model="editedAssignment.condition"
                              name="condition"
                              :rows="10"
                              :errors="requiredFieldsError"
                              @input="clearError('condition')"
                            />
                          </div>
                        </div>

                        <!-- Dynamic User Checkbox -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label for="dynamicUser" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Dynamic User</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaCheckbox
                              id="dynamicUser"
                              v-model="isDynamicUser"
                              name="dynamicUser"
                            />
                          </div>
                        </div>

                        <!-- Assign To (visible when not Dynamic User) -->
                        <div v-if="showAssignToField" class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                          <div>
                            <label for="assignTo" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Assign to</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="assignTo"
                              v-model="editedAssignment.userExpression"
                              name="userExpression"
                              :errors="requiredFieldsError"
                              @input="clearError('userExpression')"
                              @blur="validateEmail(editedAssignment.userExpression) || (requiredFieldsError.userExpression = 'Please enter a valid email address')"
                            />
                          </div>
                        </div>

                        <!-- Dynamic User Formula (visible when Dynamic User) -->
                        <div v-else class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label for="userExpression" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Dynamic User Formula</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextArea
                              id="userExpression"
                              v-model="editedAssignment.userExpression"
                              name="userExpression"
                              :rows="10"
                              :errors="requiredFieldsError"
                              @input="clearError('userExpression')"
                            />
                          </div>
                        </div>

                        <!-- Priority -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                          <div>
                            <label for="priority" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Priority</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaNumericInput
                              id="priority"
                              v-model="editedAssignment.priority"
                              name="priority"
                              :errors="requiredFieldsError"
                              @input="clearError('priority')"
                              :min="1"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- Delete Assignment Rule -->
                      <div class="mb-6 border-2 border-red-200 bg-white shadow sm:m-6 sm:mt-3 sm:rounded-lg">
                        <div class="px-4 py-5 sm:p-6">
                          <h3 class="text-base font-semibold leading-6 text-red-600">
                            Delete Assignment Rule
                          </h3>
                          <div class="mt-2 items-center justify-center sm:flex">
                            Are you sure you want to delete this assignment rule? This cannot be undone.
                            <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center">
                              <KodexaButton id="deleteAssignment" type="danger" @click="deleteAssignment">
                                Delete
                              </KodexaButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
