<script setup lang="ts">
import type { PropType } from "vue";
import KodexaRecentExecutionCard from "~/components/document/kodexa-recent-execution-card.vue";
import type { DocumentFamily } from "~/model";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: true,
  },
  size: {
    type: String,
    default: "default",
  },
});

const emit = defineEmits(["changed"]);

const recentExecutions = computed(() => {
  if (props.documentFamily.statistics?.recentExecutions) {
    const recentExecutions = [...props.documentFamily.statistics.recentExecutions];
    return recentExecutions.sort((a, b) => {
      return new Date(a.execution.startDate).getTime() - new Date(b.execution.startDate).getTime();
    });
  } else {
    return [];
  }
});
</script>

<template>
  <div v-if="documentFamily" class="isolate flex -space-x-1 overflow-hidden">
    <ol class="items-center sm:flex">
      <li v-for="(recentExecution, idx) in recentExecutions" :key="idx" class="relative mb-6 sm:mb-0">
        <div class="flex items-center">
          <VMenu :popper-triggers="['hover']">
            <div class="z-10 flex size-6 shrink-0 items-center justify-center rounded-full">
              <KodexaStatusIcon :status="recentExecution.execution.status" :size="size === 'default' ? '20' : '18'" />
            </div>
            <template #popper>
              <div v-if="recentExecution?.execution">
                <KodexaRecentExecutionCard
                  :key="`${recentExecution.execution.id}=${documentFamily.changeSequence}`"
                  :recent-execution="recentExecution"
                  :document-family="documentFamily"
                  @changed="emit('changed')"
                />
              </div>
            </template>
          </VMenu>
          <div v-if="idx < recentExecutions.length - 1" class="hidden h-0.5 w-full bg-gray-200 dark:bg-gray-700 sm:flex" />
        </div>
        <div v-if="size === 'default'" class="mt-1 sm:pr-8">
          <h3 class="text-xs text-gray-900 dark:text-white">
            {{ recentExecution.assistant.name }}
          </h3>
        </div>
        <div v-else class="w-10" />
      </li>
    </ol>
  </div>
</template>
