<script setup lang="ts">
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { createGridHelper } from "~/store/useGridHelper";
import { deleteTask, useListTasks } from "~/api/activity/activity";

const gridHelper = createGridHelper("project-tasks");
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListTasks(gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const columns = [
  { title: "Title", field: "title", width: "500px" },
  { title: "Description", field: "description" },
  { title: "Loupe Link", field: "loupeLink", cell: "loupe" },
  { title: "", field: "description", cell: "actions" },
];

const showNewTask = ref(false);

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});

const computedQuery = computed({
  get() {
    return gridHelper.query;
  },
  set(value) {
    gridHelper.setQuery(value);
  },
});

function doDeleteTask(task) {
  deleteTask(task.id);
  refetch();
}
</script>

<template>
  <div>
    <div class="mx-4">
      <Toolbar class="mb-1 border-0 bg-white">
        <label for="query" class="sr-only">Query</label>
        <KodexaTextInput
          id="query"
          v-model="computedQuery" :show-clear="true" type="text" name="query"
          class="block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" placeholder="Query..."
        />
        <Button
          class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          icon="filter" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()"
        />
        <ToolbarSpacer />
        <KodexaButton id="newTask" icon="plus" title="New Task" @click="showNewTask = true">
          New Task
        </KodexaButton>
      </Toolbar>
      <Grid
        style="height: calc(100vh - 9rem);"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :take="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error" :is-loading="isLoading" :is-error="isError"
            no-records-message="No tasks found"
          />
        </GridNoRecords>
        <template #loupe="{ props }">
          <td>
            <a :href="`/loupe/${props.dataItem.id}`" target="_blank" rel="noopener noreferrer" class="flex h-10 items-center text-xs capitalize text-gray-500 sm:mr-6 hover:text-gray-700">
              <MaterialDesignIcon
                name="open-in-new"
                class="mr-3 h-5 w-5 shrink-0 text-gray-400"
                aria-hidden="true"
              />
              Open in Loupe
            </a>
          </td>
        </template>
        <template #actions="{ props }">
          <td>
            <div class="flex justify-end space-x-2">
              <KodexaButton
                icon="pencil"
                title="Edit Task"
                size="small"
                @click="showNewTask = true"
              >
              </KodexaButton>
              <KodexaButton
                icon="delete"
                title="Delete Task"
                size="small"
                type="danger"
                @click="doDeleteTask(props.dataItem)"
              >
              </KodexaButton>
            </div>
          </td>
        </template>
      </Grid>
      <NewTaskPopover v-model="showNewTask" @task-created="refetch" />
    </div>
  </div>
</template>
