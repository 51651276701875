<script lang="ts" setup>
import { storeToRefs } from "pinia";
import type { Ref } from "vue";
import { ref } from "vue";
import { notify } from "notiwind";
import { errors } from "@progress/kendo-intl";
import appStore from "~/store";
import type { Dashboard } from "~/model";
import { updateOrganization } from "~/api/organizations/organizations";
import { useRouter, useRoute } from 'vue-router';

const {
  currentOrganization,
  organizationLoading,
} = storeToRefs(appStore.organizationStore);

const currentNavigation: Ref<Partial<Dashboard> | undefined> = ref(undefined);

const orderedDashboards = computedAsync(async () => {
  const dashboards = [
    {
      ref: "organizationManage",
      name: "Overview",
      singleWidget: {
        type: "organizationManage",
      },
    },
    {
      ref: "manageMembers",
      name: "Members",
      singleWidget: {
        type: "manageMembers",
      },
    },
    {
      ref: "subscriptions",
      name: "Subscriptions",
      singleWidget: {
        type: "manageSubscriptions",
      },
    },
    {
      ref: "organizationResources",
      name: "Project Templates",
      singleWidget: {
        type: "organizationResources",
      },
    },
    {
      ref: "manageProjectStatus",
      name: "Project Status",
      singleWidget: {
        type: "manageProjectStatus",
      },
    },
    {
      ref: "manageProjectTags",
      name: "Project Tags",
      singleWidget: {
        type: "manageProjectTags",
      },
    },
    {
      ref: "manageDocumentTags",
      name: "Document Tags",
      singleWidget: {
        type: "manageDocumentTags",
      },
    },
  ] as any[];
  const isCreditFeatureEnabled = await appStore.organizationStore.isFeatureEnabled('organization.credits');
  if(isCreditFeatureEnabled){
    dashboards.push({
      ref: "manageCredits",
      name: "Credits",
      singleWidget: {
        type: "manageCredits",
      },
    });
  }
  return dashboards
});

// TODO remove this one in the future when we have a proper way to save the changes
const saveMethods = {
  organizationManage: updateManageOrganization,
};

async function updateManageOrganization() {
  try {
    currentOrganization.value = await updateOrganization(currentOrganization.value.id, currentOrganization.value);
  } catch (error: any) {
    errors.value = error.response.data.errors;
    notify({
      group: "error",
      title: "Error",
      text: "Organization update failed.",
    }, 3000);
  }
  notify({
    group: "generic",
    title: "Organization updated",
  }, 500);
}

async function saveChanges() {
  if (!currentNavigation?.value?.ref) {
    return;
  }
  await saveMethods[currentNavigation.value.ref]();
}

const router = useRouter();
const route = useRoute();

watch([route, orderedDashboards], () => {
  if (orderedDashboards.value?.length > 0) {
    const tabRef = route.query.q as string;
    const tab = orderedDashboards.value.find((item) => item.ref === tabRef);
    if (tab) {
      currentNavigation.value = tab;
    } else {
      currentNavigation.value = orderedDashboards.value[0];
    }
  }
}, { immediate: true });

function updateUrlWithTab(tab: Partial<Dashboard>) {
  router.push({
    query: { ...route.query, q: tab.ref }
  });
}
</script>

<template>
  <div v-if="currentOrganization" class="bg-white">
    <header>
      <div>
        <header>
          <div
            v-if="currentNavigation?.ref && saveMethods.hasOwnProperty(currentNavigation.ref)"
            class="float-right mt-4 pr-6"
          >
            <KodexaButton
              v-if="currentNavigation?.ref !== 'projectEmailNotification'"
              id="orgSaveChanges"
              class="mr-1"
              icon="content-save"
              size="small"
              @click="saveChanges"
            >
              Save Changes
            </KodexaButton>
          </div>
          <div class="border-b border-gray-200 dark:border-gray-700">
            <ul class="-mb-px ml-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
              <li
                v-for="item in orderedDashboards" :key="item.ref"
                class="mr-2"
                @click="currentNavigation = item; updateUrlWithTab(item)"
              >
                <a
                  :id="item.ref"
                  :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
                  class="text-md"
                >
                  <MaterialDesignIcon
                    v-if="item?.icon" :name="item.icon" class="text-theme-primary mr-3"
                    size="18"
                  />
                  {{ item?.name }}
                </a>
              </li>
            </ul>
          </div>
        </header>
      </div>
    </header>
    <div>
      <KodexaSmallLoader v-if="organizationLoading" class="mt-64" message="Loading Organization Dashboards" />
      <KodexaDashboard
        v-if="currentNavigation"
        :model-value="currentNavigation"
        :setup-mode="false"
        entry-point="organization"
      />
    </div>
  </div>
</template>
