<script setup lang="ts">
import { Button, SplitButton, Toolbar } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { ref } from "vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { notify } from "notiwind";
import {
  arrowRotateCwIcon,
  checkboxCheckedIcon,
  clipboardIcon,
  filterIcon,
  uploadIcon,
} from "@progress/kendo-svg-icons";
import KodexaDocumentExtractedData from "~/components/document/kodexa-document-extracted-data.vue";
import KodexaDateTimeView from "~/components/util/kodexa-date-time-view.vue";
import appStore from "~/store";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import { copyToClipboard } from "~/utils/general";
import KodexaBulkDeletePopup from "~/components/kodexa-bulk-delete-popup.vue";
import KodexaBulkReprocessPopup from "~/components/kodexa-bulk-reprocess-popup.vue";
import KodexaBulkLockPopup from "~/components/kodexa-bulk-lock-popup.vue";
import type { DashboardWidget, DocumentFamily, Store } from "~/model";
import { useListDocumentFamilies } from "~/api/document-families/document-families";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

const {
  project,
  documentStores,
} = storeToRefs(appStore.projectStore);

const documentStore = computed(() => {
  return documentStores.value ? documentStores.value.find((ds: Store) => ds.ref === props.widget.properties.documentStoreRef) : undefined;
});

// Setup our grid helpers
const gridHelper = createGridHelper(`project-documents-${project.value.id}`);

const {
  query,
  pageSettings,
  sort,
  filter,
  gridQuery,
  areAllSelected,
  selected,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListDocumentFamilies(gridQuery, { query: { refetchInterval: 5000, enabled: documentStore.value !== undefined } });

const finalData = computed(() => {
  if (data) {
    return gridHelper.buildData(data.value, selected.value);
  }
  return [];
});

watch(documentStore, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`store.id:'${newValue._id}'`);
    refetch();
  }
}, { immediate: true, deep: true });

const columns = computed(() => {
  return [
    {
      field: "selected",
      width: "50px",
      headerSelectionValue: areAllSelected.value,
      cell: "selectCell",
      headerCell: "selectCellHeader",
    },
    {
      title: "Path",
      field: "path",
      cell: "path",
      width: "500px",
    },
    {
      title: "Activity",
      field: "activity",
      cell: "activity",
      width: "400px",
      filterable: false,
      sortable: false,
    },
    {
      title: "Extracted Data",
      field: "activity",
      cell: "extractedData",
      width: "250px",
      filterable: false,
      sortable: false,
    },
    {
      title: "",
      cell: "actions",
      filterable: false,
      sortable: false,
      width: "150px",
    },
  ];
});

const localQuery = computed({
  get() {
    return query.value;
  },
  set(value) {
    gridHelper.setQuery(value);
    gridHelper.pageChangeHandler({ page: 1 });
  },
});

function updateQuery() {
  refetch();
}

const selectedDocumentFamilies = computed(() => {
  return finalData.value.filter((df: any) => df.selected);
});

const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);

async function openDeleteFamilyConfirm(documentFamily: DocumentFamily) {
  deleteConfirmDialog.onConfirm(() => {
    appStore.workspaceStore.deleteDocumentFamilyById(documentFamily.id).then(() => {
      refetch();
      notify({
        title: "Document Family Deleted",
        message: `The document family ${documentFamily.path} has been deleted`,
        group: "generic",
      });
    });
  });
  await deleteConfirmDialog.reveal({
    title: `Delete ${documentFamily.path} ?`,
    message: "Are you sure you want to delete the uploaded document, and its related content?",
  });
}

const showUpload = ref(false);
const showBulkCopy = ref(false);
const showBulkDelete = ref(false);
const showBulkReprocess = ref(false);
const showBulkLock = ref(false);
const isLocking = ref(false);
const showDetails = ref(false);

const bulkActions = [
  // {icon: "copy", text: "Copy", action: "copy"},
  { icon: "refresh", text: "Reprocess", action: "reprocess" },
  // {icon: "unlock", text: "Unlock", action: "unlock"},
  // {icon: "lock", text: "Lock", action: "lock"},
  { icon: "delete", text: "Delete", action: "delete" },
];

function executeBulkAction(event: any) {
  switch (event.item.action) {
    case "copy":
      showBulkCopy.value = true;
      break;
    case "reprocess":
      showBulkReprocess.value = true;
      break;
    case "unlock":
      isLocking.value = false;
      showBulkLock.value = true;
      break;
    case "lock":
      isLocking.value = true;
      showBulkLock.value = true;
      break;
    case "delete":
      showBulkDelete.value = true;
      break;
  }
}

const selectedDocumentFamily = ref<DocumentFamily>();
function openDetails(documentFamily: DocumentFamily) {
  selectedDocumentFamily.value = documentFamily;
  showDetails.value = true;
}

function toggleLock(documentFamily: DocumentFamily) {
  if (documentFamily.locked) {
    appStore.workspaceStore.unlockDocumentFamily(documentFamily).then(() => {
      refetch();
    });
  } else {
    appStore.workspaceStore.lockDocumentFamily(documentFamily).then(() => {
      refetch();
    });
  }
}

function isSelected(documentFamily: DocumentFamily) {
  return selected.value.has(documentFamily.id);
}

function toggleSelected(documentFamily: DocumentFamily) {
  if (selected.value.has(documentFamily.id)) {
    selected.value.delete(documentFamily.id);
  } else {
    selected.value.add(documentFamily.id);
  }
}
</script>

<template>
  <div v-if="documentStore" class="mx-2">
    <KodexaBulkCopyPopup v-model="showBulkCopy" :document-families="selectedDocumentFamilies" :store="documentStore" />
    <KodexaBulkDeletePopup v-model="showBulkDelete" :document-families="selectedDocumentFamilies" :store="documentStore" />
    <KodexaBulkReprocessPopup
      v-model="showBulkReprocess" :document-families="selectedDocumentFamilies"
      :store="documentStore"
    />
    <KodexaBulkLockPopup
      v-model="showBulkLock" :document-families="selectedDocumentFamilies" :lock="isLocking"
      :store="documentStore"
    />
    <KodexaDocumentFamilyDetailsPopup v-if="showDetails" :key="selectedDocumentFamily?.id" v-model="showDetails" :document-family="selectedDocumentFamily" />
    <Toolbar class="mb-1 border-0 bg-white">
      <Button :svg-icon="uploadIcon" title="Upload" :togglable="false" @click="showUpload = true" />
      <Button :svg-icon="arrowRotateCwIcon" title="Refresh" :togglable="false" @click="updateQuery()" />
      <Button :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()" />
      <SplitButton
        :items="bulkActions" title="Bulk Actions" :togglable="false"
        :svg-icon="checkboxCheckedIcon"
        :disabled="selected.size === 0" @itemclick="executeBulkAction"
      >
        Bulk Actions
      </SplitButton>
      <Button
        :svg-icon="clipboardIcon" title="Copy Reference to Clipboard" :togglable="false"
        @click="copyToClipboard(documentStore.re, 'Copied store reference to clipboard')"
      />
      <label for="query" class="sr-only">Query</label>
      <KodexaTextInput
        id="query" v-model="localQuery"
        name="query"
        show-clear placeholder="Filter..."
      />
    </Toolbar>
    <Grid
      style="height: calc(100vh - 12rem);"
      selected-field="selected"
      :data-items="finalData"
      :pageable="gridHelper.pageSettings"
      :loader="isLoading"
      :sort="sort"
      :skip="pageSettings.skip"
      :total="pageSettings.total"
      :page-size="pageSettings.take"
      :columns="columns"
      :filterable="gridHelper.showFilter"
      :filter="gridHelper.filter"
      :resizable="true"
      :sortable="true"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <GridNoRecords>
        <KodexaGridNoRecords
          :error="error" :is-loading="isLoading" :is-error="isError"
          no-records-message="No documents found"
        />
      </GridNoRecords>
      <template #selectCellHeader="{ props }">
        <KodexaCheckbox
          v-model="areAllSelected" name="selectCell" class="p-1" :no-emit="true"
          @click="gridHelper.toggleSelectAll(finalData)"
        />
      </template>
      <template #selectCell="{ props }">
        <td>
          <KodexaCheckbox
            v-model="props.dataItem.selected" name="selectCell" class="p-1" :no-emit="true"
            @click="gridHelper.toggleSelect(props.dataItem.id, finalData)"
          />
        </td>
      </template>
      <template #path="{ props }">
        <td>
          <KodexaDocumentFamilyCard
            :document-family="props.dataItem"
            :selected="isSelected(props.dataItem)"
            @toggle-selected="toggleSelected"
            @change="refetch"
          />
        </td>
      </template>
      <template #activity="{ props }">
        <td>
          <KodexaDocumentRecentActivity :document-family="props.dataItem" @changed="refetch" />
        </td>
      </template>
      <template #extractedData="{ props }">
        <td>
          <KodexaDocumentExtractedData :document-family="props.dataItem" />
        </td>
      </template>
      <template #actions="{ props }">
        <td>
          <KodexaDocumentFamilyMenu
            :document-family="props.dataItem" @change="refetch" @details="openDetails"
            @toggle-lock="toggleLock(props.dataItem)"
            @delete="openDeleteFamilyConfirm(props.dataItem)"
          />
        </td>
      </template>
      <template #modified="{ props }">
        <td>
          <KodexaDateTimeView :date="props.dataItem.modified" :ago="true" />
        </td>
      </template>
      <template #created="{ props }">
        <td>
          <KodexaDateTimeView :date="props.dataItem.created" :ago="true" />
        </td>
      </template>
      <template #size="{ props }">
        <td>
          <KodexaFileSizeView :size="props.dataItem.size" />
        </td>
      </template>
    </Grid>
    <KodexaDocumentStoreUpload v-model="showUpload" :document-store="documentStore" @change="refetch" />
  </div>
  <div v-else>
    <div class="mt-10 flex flex-col items-center justify-center">
      <MaterialDesignIcon name="database" size="48" class="text-gray-400" />
      <p class="mt-2 text-sm text-gray-500">
        Select a document store to view the data, it is either
        not set of the store no longer exists.
      </p>
      <p class="mt-2 text-sm text-gray-500">
        {{ props.widget?.properties?.documentStoreRef }}
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>
