<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { Button, Toolbar } from "@progress/kendo-vue-buttons";
import { TileLayout } from "@progress/kendo-vue-layout";
import { storeToRefs } from "pinia";
import type { PropType } from "vue";

import appStore from "~/store";
import type { TagMetadata } from "~/store/useProject";
import type { KeyboardShortcut } from "~/store/useKeyboard";
import type { ColorSchemeDesign, TaxonNavigationOption } from "~/components/dataForm/taxon-navigation-option";
import type { Card, DataAttribute, DataException, DataObject } from "~/model";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  designMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});
const emit = defineEmits(["update:card", "addCard", "selectCard", "removeCard", "addDataObject", "deleteDataObject", "moveDataObject"]);
const {
  dataForms,
  workspace,
} = storeToRefs(appStore.projectStore);
const useDataFormViewer = createDataFormViewerStore(props.viewId);

const {
  dataForm,
  dataObjects,
  needsFullReview,
  validatedExceptionUuids,
} = storeToRefs(useDataFormViewer);
const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const currentCard = computed({
  get: () => props.card,
  set: (value) => {
    emit("update:card", value);
  },
});

// ALT+x shortcut key to jump or scroll to section/block with [jump-on=true]
// and then 500ms will focus to its next input field
const currentScrollTo = ref(0);
const keyboardStore = useKeyboard();
let tick: any = 0;
function doScrollTo(isBack = false) {
  if (isBack) {
    currentScrollTo.value--;

    if (currentScrollTo.value < 0) {
      currentScrollTo.value = document.querySelectorAll("[jump-on=true]").length - 1;
    }
  } else {
    currentScrollTo.value++;
    if (currentScrollTo.value >= document.querySelectorAll("[jump-on=true]").length) {
      currentScrollTo.value = 0;
    }
  }
  const nextJumpTo = document.querySelectorAll("[jump-on=true]")[currentScrollTo.value];

  const dy = document.querySelectorAll("[jump-on=true]")[currentScrollTo.value].offsetTop - 64;
  document?.querySelector(".k-pane.k-scrollable")?.scroll({ top: dy, behavior: "smooth" });
  clearTimeout(tick);
  tick = setTimeout(() => nextJumpTo.querySelector("input")?.focus(), 500);
}

const scrollShortcut = {
  key: "alt+shift+n",
  altKey: "alt+shift+˜",
  description: "Scrolls to the next section",
  callback: () => {
    doScrollTo();
  },
} as KeyboardShortcut;
keyboardStore.addShortcut(scrollShortcut);

const currentScrollToException = ref(0);

function scrollToException(isBack = false) {
  if (isBack) {
    currentScrollToException.value--;

    if (currentScrollToException.value < 0) {
      currentScrollToException.value = document.querySelectorAll("[exception-scroll=true]").length - 1;
    }
  } else {
    currentScrollToException.value++;
    if (currentScrollToException.value >= document.querySelectorAll("[exception-scroll=true]").length) {
      currentScrollToException.value = 0;
    }
  }
  const dy = document.querySelectorAll("[exception-scroll=true]")[currentScrollToException.value].offsetTop - 64;
  document?.querySelector(".k-pane.k-scrollable")?.scroll({ top: dy, behavior: "smooth" });
}
const scrollExceptionShortcut = {
  key: "alt+shift+e",
  altKey: "alt+shift+´",
  description: "Scrolls to the next exception",
  callback: () => {
    scrollToException();
  },
} as KeyboardShortcut;
keyboardStore.addShortcut(scrollExceptionShortcut);

function addCard() {
  emit("addCard", currentCard.value);
}

function selectCard(card: Card) {
  emit("selectCard", card);
}

function handleReposition(updatedPositions: any) {
  useDataFormViewer.updateCardPositions(updatedPositions, props.card);
}

function getPositions() {
  return useDataFormViewer.getPositions(props.card);
}

function getCards() {
  return useDataFormViewer.getCards(props.card);
}

useDataFormViewer.setFullReview(props.parentDataObject, props.card.properties?.groupTaxon as string, false);

const tagMetadata = computed(() => {
  return tagMetadataMap.value.get(props.card.properties?.groupTaxon);
});

// watch(validatedExceptionUuids.value, () => {
// });

const hexBg1 = ref("#ffff00");
const hexBg2 = ref("#fafafa");

// We have the concept of data review,  here we track if we have
// reviewed the data
const reviewed = ref(false);
const path = ref("");

// Defaulting exceptionOpenFlag as true which means that a data exception is not resolved yet.
const dataObjectId = ref("");
const exceptionOpenFlag = ref(true);
// Then we will filter the data objects for the parent data object so we can get the
// attributes

const scopedDataObjects = computed(() => {
  if (!tagMetadata.value) {
    return [];
  }

  path.value = tagMetadata.value.path;

  let scopedDataObjects = tagMetadata.value
    ? dataObjects.value.filter((dataObject) => {
      return dataObject.path === tagMetadata.value.path;
    })
    : dataObjects.value;

  scopedDataObjects = props.parentTagMetadata
    ? scopedDataObjects.filter((dataObject) => {
      return dataObject.path === props.parentTagMetadata?.path;
    })
    : scopedDataObjects;

  if (props.parentDataObject) {
    scopedDataObjects = scopedDataObjects.filter((dataObject) => {
      // We need to be able to handle children that haven't been saved yet
      return dataObject.parentId ? dataObject.parentId === props.parentDataObject?.id : dataObject.parent.uuid === props.parentDataObject?.uuid;
    });
  }

  if (props.card?.properties?.background1) {
    hexBg1.value = props.card?.properties?.background1;
  }
  if (props.card?.properties?.background2) {
    hexBg2.value = props.card?.properties?.background2;
  }

  if (props.card?.properties?.filters) {
    scopedDataObjects = scopedDataObjects.filter((dataObject) => {
      if (props.card?.properties?.filters) {
        for (const filter of props.card?.properties?.filters) {
          if (filter.type === "hasExceptionMessage") {
            const exception = dataObject.dataExceptions?.find((dataException) => {
              return dataException.message === filter.value;
            });
            return exception !== undefined;
          }
        }
      }
      return true;
    });
  }
  scopedDataObjects = scopedDataObjects.sort((a: DataObject, b: DataObject) => {
    if (a.sourceOrdering === undefined) {
      return -1;
    }
    if (b.sourceOrdering === undefined) {
      return 1;
    }
    // get last 4 characters of source ordering, then convert to number
    const sourceOrderingA: number = +a.sourceOrdering.slice(-4);
    const sourceOrderingB: number = +b.sourceOrdering.slice(-4);

    return sourceOrderingA - sourceOrderingB;
  });

  return scopedDataObjects;
});

/**
 * Create a computed properties to show the possible destination movement for a
 * dataObject
 */
const parentDataObjectGroups = computed(() => {
  const parentDataObjectGroups: Map<string, DataObject> = new Map<string, DataObject>();
  if (!props.card?.properties?.dataMovement?.showMove) {
    return parentDataObjectGroups;
  }

  const dataMovementProps = props.card?.properties?.dataMovement;
  for (const dataObject of dataObjects.value) {
    if ((dataObject.path !== dataMovementProps?.sameDataObjectMovement?.identifierProps?.groupTaxonPath
      && dataObject.path !== dataMovementProps?.diffDataObjectMovement?.identifierProps?.groupTaxonPath) || !dataObject.attributes) {
      continue;
    }
    // Get the attribute taxon that will be used as a key
    const attributeIdentifier: DataAttribute | undefined = dataObject.attributes.find((attribute) => {
      return attribute.path === dataMovementProps.sameDataObjectMovement.identifierProps?.taxonAttribute
        || attribute.path === dataMovementProps.diffDataObjectMovement.identifierProps?.taxonAttribute;
    });
    if (dataObject.path === dataMovementProps?.diffDataObjectMovement?.identifierProps?.groupTaxonPath) {
      if (attributeIdentifier?.stringValue) {
        if (parentDataObjectGroups.has(attributeIdentifier?.stringValue)) {
          continue;
        }
        parentDataObjectGroups.set(attributeIdentifier.stringValue, dataObject);
      } else if (dataMovementProps.diffDataObjectMovement?.stringValue) {
        parentDataObjectGroups.set(dataMovementProps.diffDataObjectMovement.stringValue, dataObject);
      }
    } else {
      if (attributeIdentifier?.stringValue) {
        if (parentDataObjectGroups.has(attributeIdentifier?.stringValue)) {
          continue;
        }
        parentDataObjectGroups.set(attributeIdentifier.stringValue, dataObject);
      }
    }
  }

  return parentDataObjectGroups;
});

function getNavigations(dataObject: DataObject) {
  const taxonNavigation = props.card?.properties?.taxonNavigation as TaxonNavigationOption;
  // Find all childDataObject
  const allChildDataObject = appStore.workspaceStore.findChildDataObject(dataObject);
  const filteredChildDataObject: DataObject[] = allChildDataObject.filter(dataObject => dataObject.path === taxonNavigation.childGroupTaxonPath);
  const navList = [] as ColorSchemeDesign[];
  for (const childDataObject of filteredChildDataObject) {
    childDataObject.attributes?.forEach((attribute) => {
      if (attribute.path === taxonNavigation.taxonPath) {
        const colorSchemeValue = taxonNavigation.colorSchemeDesign.find((value) => {
          return value.value.toLowerCase() === attribute.value?.toLowerCase();
        });
        if (attribute.id) {
          navList.push({
            value: attribute.stringValue ? attribute.stringValue : "Empty Service Type",
            color: colorSchemeValue?.color,
            uuid: attribute.uuid,
          } as ColorSchemeDesign);
        } else {
          navList.unshift({
            value: attribute.stringValue ? attribute.stringValue : "Empty Service Type",
            color: colorSchemeValue?.color,
            uuid: attribute.uuid,
          } as ColorSchemeDesign);
        }
      }
    });
  }
  return navList;
}

const openDataExceptions = computed(() => {
  const allUnresolvedDataExceptions: DataException[] = [];
  function getOpenDataException(dataExceptions) {
    for (const dataException of dataExceptions) {
      if (dataException.open) {
        allUnresolvedDataExceptions.push(dataException);
      }
    }
  }
  for (const dataObject of scopedDataObjects.value) {
    if (dataObject.dataExceptions) {
      getOpenDataException(dataObject.dataExceptions);
    }
    if (dataObject.attributes) {
      for (const dataAttribute of dataObject?.attributes) {
        if (dataAttribute.dataExceptions) {
          getOpenDataException(dataAttribute.dataExceptions);
        }
      }
    }
  }
  return allUnresolvedDataExceptions;
});

function buildDataFormViewer(card: Card) {
  return useDataFormViewer.buildDataFormViewer(card);
}

function moveDataObject(eventHandler) {
  const destinationDataObject = eventHandler.destinationDataObject;
  const dataObject = eventHandler.dataObject;
  // We will be reassigning the parentDataObject
  if (props.card?.properties?.dataMovement?.diffDataObjectMovement?.identifierProps?.groupTaxonPath === eventHandler.destinationDataObject.path) {
    //   Create new data Object
    const destinationTaxonPath = props.card?.properties?.dataMovement?.diffDataObjectMovement?.destinationTaxonPath;
    const tagMetadata = tagMetadataMap.value.get(props.card?.properties?.dataMovement?.diffDataObjectMovement?.destinationPaths);
    const newDataObject = appStore.workspaceStore.addNewDataObject(tagMetadata, eventHandler.destinationDataObject.documentFamily, eventHandler.destinationDataObject);
    for (const attribute of dataObject.attributes) {
      const currentTaxonPath = destinationTaxonPath.find((value) => {
        return value.attributePath === attribute.path;
      });
      const taxonTagMetadata = tagMetadataMap.value.get(currentTaxonPath?.destinationPath);
      const newAttribute = useDataFormViewer.addAttribute(taxonTagMetadata, newDataObject);
      newAttribute.value = attribute.value;
      newAttribute.stringValue = attribute.stringValue;
      newAttribute.dateValue = attribute.dateValue;
      newAttribute.booleanValue = attribute.booleanValue;
      newAttribute.decimalValue = attribute.decimalValue;
    }
    deleteDataObject(eventHandler.dataObject);
  } else {
    const updatedPartialDataObject: Partial<DataObject> = {
      parentId: destinationDataObject.id,
      parent: destinationDataObject,
    };
    useDataFormViewer.updateDataObject(dataObject, updatedPartialDataObject);
  }
}
function addDataObject() {
  appStore.workspaceStore.addNewDataObject(tagMetadata.value, props.parentDataObject?.documentFamily, props.parentDataObject);
}

const collapsed = ref(false);

function toggleCollapsed() {
  collapsed.value = !collapsed.value;
}

function deleteDataObject(dataObject: DataObject) {
  appStore.workspaceStore.deleteDataObjectByUuid(dataObject.uuid);
  useDataFormViewer.removeFullReviewPath(dataObject, path.value);
}

function getExceptions(dataObject: DataObject) {
  const dataExceptions = new Map();
  if (dataObject.dataExceptions) {
    for (const dataException of dataObject.dataExceptions) {
      if (!dataException.open) {
        continue;
      }
      const exceptionCopy = { ...dataException };
      exceptionCopy.dataObject = dataObject;
      dataExceptions.set(dataException.uuid, exceptionCopy);
    }
    if (dataObject.attributes) {
      for (const attribute of dataObject.attributes) {
        if (attribute.dataExceptions) {
          for (const dataException of attribute.dataExceptions) {
            if (!dataException.open) {
              continue;
            }
            const exceptionCopy = { ...dataException };
            exceptionCopy.dataAttribute = attribute;
            exceptionCopy.dataObject = dataObject;
            dataExceptions.set(dataException.uuid, exceptionCopy);
          }
        }
      }
    }
  }
  return Array.from(dataExceptions.values());
}

function setReviewed() {
  // If this is full human review and has override exception on it add a function on overriding
  if (props?.card?.properties?.overrideException) {
    overrideException();
  }
  reviewed.value = true;
  useDataFormViewer.setFullReview(props.parentDataObject, path.value, reviewed.value);
}

function overrideExceptionUuid(dataObject: DataObject, dataException: DataException) {
  const partialException: Partial<DataException> = {
    open: false,
  };
  if (dataException.dataAttribute?.uuid) {
    appStore.workspaceStore.updateException(dataObject, partialException, dataException.uuid, dataException.dataAttribute, true);
  } else {
    appStore.workspaceStore.updateException(dataObject, partialException, dataException.uuid);
  }
}

function getGroupTaxonWithHex(attr) {
  const found = attr.find((o) => {
    return o.dataObject.path === props.card?.properties?.groupTaxon;
  });
  if (found) {
    return { path: found.path, hex: props.card?.properties?.hex };
  }
  return false;
}

function getIsParent(attr) {
  const found = attr.find(o => o.path === dataForm.value.cards[0].properties?.color_scheme?.taxon_basis);
  if (found) {
    return true;
  }
  return false;
}
/**
 * Used to flag the exception as resolved as true or false
 */
function overrideException() {
  exceptionOpenFlag.value = !exceptionOpenFlag.value;
  useDataFormViewer.setDataExceptionResolve(props?.parentDataObject, path.value, exceptionOpenFlag.value);
}

function navigationScroll(attributeUuid) {
  const element = document.querySelector(`[data-key-attribute="${attributeUuid}"]`);
  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
    });
  }
}

onMounted(() => {
  if (!scopedDataObjects.value.length) {
    if (props.card?.properties?.isEmptyAutoAdd) {
      addDataObject();
    }
  }

  nextTick(() => {
    const childTaxon = document.querySelectorAll("[group-taxon=\"Bill/BillService\"] > div > .child-taxon");
    childTaxon.forEach((child, n) => {
      child.className = `child-taxon mt-1 ${n % 2 ? "even" : "odd"}`;
      child.setAttribute("style", `background-color: ${n % 2 ? hexBg2.value : hexBg1.value}66;`);
    });
  });
});
</script>

<template>
  <div>
    <div v-if="designMode">
      <Toolbar class="my-1" @click="emit('selectCard', card)">
        <Button v-if="designMode" icon="plus" title="Add Card" :togglable="false" @click="addCard" />
        <span v-if="card.properties.showHeader">{{ card.properties.title }}</span>
      </Toolbar>
      <!-- We need to repeat this for each of the data objects -->
      <TileLayout
        :items="getCards()"
        :positions="getPositions()"
        style="width: 100%;"
        :columns="12"
        :row-height="50"
        auto-flow="unset"
        :gap="{
          rows: 5,
          columns: 5,
        }"
        @reposition="handleReposition"
      >
        <template #tile="{ props }">
          <KodexaFormCard
            :card="props.tile" :design-mode="designMode"
            :view-id="viewId"
            @add-card="emit('addCard', $event)" @select-card="emit('selectCard', $event)"
            @remove-card="emit('removeCard', $event)"
          />
        </template>
      </TileLayout>
    </div>
    <div
      v-else class="px-2"
      :group-taxon="card?.properties?.groupTaxon"
    >
      <h3
        v-if="card.properties.showHeader" class="my-3 flex text-lg font-semibold leading-6 text-gray-900"
        :jump-on="card.properties?.isJumpOn ? card.properties?.isJumpOn : false"
      >
        {{ card.properties.title }}
        <Button
          v-if="!card.properties.hideAdd && tagMetadata"
          :disabled="!exceptionOpenFlag"
          class="ml-8" fill-mode="flat"
          size="small" @click="addDataObject"
        >
          <MaterialDesignIcon
            name="plus" class="text-blue-400" size="18"
            :tooltip="`Add new ${tagMetadata?.taxon.label}`"
          />
        </Button>
        <Button
          v-if="!card.properties.hideCollapse && tagMetadata"
          fill-mode="flat"
          size="small" @click="toggleCollapsed"
        >
          <MaterialDesignIcon
            :name="collapsed ? 'upArrow' : 'downArrow'"
            class="text-blue-400" size="18"
            :tooltip="collapsed ? `Expand ${tagMetadata.taxon.label} panel` : `Collapse ${tagMetadata.taxon.label} panel`"
          />
        </Button>
      </h3>
      <p class="mt-1 border-b-2 text-sm text-gray-700">
        {{
          card.properties.subTitle
        }}
      </p>
      <div v-if="props.card?.properties.overrideException && !needsFullReview">
        <div class="rounded-md bg-blue-50 p-4">
          <div class="flex items-start">
            <div class="shrink-0">
              <MaterialDesignIcon name="information" class="mr-1 text-blue-400" size="28" />
            </div>
            <div class="ml-3 flex-1">
              <div class="flex items-center justify-between">
                <!-- Flex container for vertical alignment and edge alignment -->
                <p class="text-sm text-blue-700">
                  If all data in this section is correct, click the button to confirm.
                  <br>
                  Note: Confirming will disable addition of new service readings.
                </p>
                <button
                  type="button"
                  class="rounded-md px-2 py-1.5 text-sm font-medium text-white transition-colors duration-300 ease-in-out focus:outline-none focus:ring focus:ring-blue-300"
                  :class="{
                    'bg-green-500 hover:bg-green-600': !exceptionOpenFlag && openDataExceptions.length > 0,
                    'bg-red-500 hover:bg-red-600': exceptionOpenFlag && openDataExceptions.length > 0,
                    'cursor-not-allowed bg-red-500 opacity-50 hover:bg-red-600': exceptionOpenFlag && openDataExceptions.length === 0,
                    'cursor-not-allowed bg-green-500 opacity-50 hover:bg-green-600': !exceptionOpenFlag && openDataExceptions.length === 0,

                  }"
                  :disabled="openDataExceptions.length === 0"
                  @click="overrideException"
                >
                  {{ exceptionOpenFlag ? "Override Exception" : "Exception Overridden" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!collapsed && tagMetadata">
        <div v-if="needsFullReview">
          <div class="rounded-md bg-blue-50 p-4">
            <div class="flex">
              <div class="shrink-0">
                <MaterialDesignIcon name="information" class="mr-1 text-blue-400" size="28" />
              </div>
              <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="mt-2 text-sm text-theme-primary">
                  <strong>Full Data Review Required</strong>, once complete click the button to the right to confirm
                </p>
                <p class="mt-3 text-sm md:ml-6 md:mt-0">
                  <button
                    type="button" class="rounded-md px-2 py-1.5 text-sm font-medium text-green-800"
                    :class="reviewed ? ['bg-green-50', 'text-green-800'] : ['bg-red-50', 'text-red-800']"
                    @click="setReviewed"
                  >
                    {{ reviewed ? "Reviewed" : "Pending Review" }}
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          v-for="dataObject in scopedDataObjects" :key="dataObject.uuid"
          class="child-taxon mt-1"
          :child-taxon="getGroupTaxonWithHex(dataObject?.attributes).path"
          :child-taxon-hex="getGroupTaxonWithHex(dataObject?.attributes).hex"
          :is-parent="getIsParent(dataObject?.attributes)"
        >
          <div v-if="props.card?.properties?.taxonNavigation" class="flex flex-wrap">
            <div
              v-for="item in getNavigations(dataObject)"
              :key="item.value"
              class="mb-4"
            >
              <button
                class="mr-4 flex h-10 items-center justify-center rounded-md bg-white px-4 py-2 transition duration-300"
                :style="{ backgroundColor: item.color, minWidth: '140px !important' }"
                @click="navigationScroll(item.uuid)"
              >
                <div class="text-gray-800">
                  {{ item.value }}
                </div>
              </button>
            </div>
          </div>

          <div v-if="card.properties && !card.properties.hideExceptions" :class="{ 'exception-sticky': props.card?.properties?.exceptionSticky }">
            <div v-for="exception in getExceptions(dataObject)" :key="exception.id" :exception-scroll="true">
              <div v-if="exception.exceptionType !== 'Full Data Review'" class="flex">
                <VMenu>
                  <MaterialDesignIcon
                    name="alertbox" class="mr-1" :class="validatedExceptionUuids.includes(exception.uuid)
                      ? ['text-red-600'] : ['text-[#922FB7]']
                    " size="20"
                  />
                  <template #popper>
                    <div class="bg-white shadow sm:rounded-lg">
                      <div class="px-4 py-5 sm:p-6">
                        <div class="mt-2 max-w-xl text-sm text-gray-700">
                          <div v-if="exception.exceptionDetails">
                            <KodexaMarkdown :content="exception.exceptionDetails" style="overflow: auto; max-height: 300px;" />
                          </div>
                          <p v-else>
                            No additional information
                          </p>
                          <p class="mt-1 text-xs">
                            {{ exception.dataObject?.path }} <span v-if="exception.dataAttribute">/ {{ exception.dataAttribute?.tag }}</span>
                          </p>
                          <button
                            v-if="!validatedExceptionUuids.includes(exception.uuid)"
                            type="button"
                            class="mt-3 rounded-md bg-red-500 px-2 py-1.5 text-sm font-medium text-white transition-colors duration-300 ease-in-out hover:bg-red-600 focus:outline-none focus:ring focus:ring-blue-300"
                            @mousedown="overrideExceptionUuid(dataObject, exception)"
                          >
                            Override Exception
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                </VMenu>
                <div class="mt-1 text-gray-700">
                  {{ exception.message }}
                </div>
              </div>
            </div>
          </div>

          <KodexaRowLayout
            :rows="buildDataFormViewer(card)" :view-id="viewId"
            :parent-tag-metadata="parentTagMetadata"
            :parent-data-object="dataObject"
            :parent-data-object-groups="parentDataObjectGroups"
            @delete-data-object="deleteDataObject"
            @add-data-object="addDataObject"
            @move-data-object="moveDataObject"
          />
          <MaterialDesignIcon
            v-if="!card.properties.hideAdd" :tooltip="`Delete ${tagMetadata.taxon.label}`"
            name="delete" :class="card.properties.deleteIcon
              ? card.properties.deleteIcon
              : 'text-red-600'" size="20" @click="deleteDataObject(dataObject)"
          />
        </div>
      </div>
      <div v-else-if="!collapsed">
        <div class="exception-container">
          <div v-if="card.properties && !card.properties.hideExceptions" class="exception-sticky">
            <div v-for="exception in getExceptions(dataObject)" :key="exception.id" :exception-scroll="true">
              <div v-if="exception.exceptionType !== 'Full Data Review'" class="flex">
                <VMenu>
                  <MaterialDesignIcon
                    name="alertbox" class="mr-1" :class="validatedExceptionUuids.includes(exception.uuid)
                      ? ['text-red-600'] : ['text-[#922FB7]']
                    " size="20"
                  />
                  <template #popper>
                    <div class="bg-white shadow sm:rounded-lg">
                      <div class="px-4 py-5 sm:p-6">
                        <div class="mt-2 max-w-xl text-sm text-gray-700">
                          <div v-if="exception.exceptionDetails">
                            <KodexaMarkdown :content="exception.exceptionDetails" style="overflow: auto; max-height: 300px;" />
                          </div>
                          <p v-else>
                            No additional information
                          </p>
                          <p class="mt-1 text-xs">
                            {{ exception.dataObject?.path }} <span v-if="exception.dataAttribute">/ {{ exception.dataAttribute?.tag }}</span>
                          </p>
                          <button
                            v-if="!validatedExceptionUuids.includes(exception.uuid)"
                            type="button"
                            class="mt-3 rounded-md bg-red-500 px-2 py-1.5 text-sm font-medium text-white transition-colors duration-300 ease-in-out hover:bg-red-600 focus:outline-none focus:ring focus:ring-blue-300"
                            @mousedown="overrideExceptionUuid(dataObject, exception)"
                          >
                            Override Exception
                          </button>
                        </div>
                      </div>
                    </div>
                  </template>
                </VMenu>
                <div class="mt-1 text-gray-700">
                  {{ exception.message }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <KodexaRowLayout
          :rows="buildDataFormViewer(card)" :view-id="viewId"
          :parent-tag-metadata="parentTagMetadata"
          @delete-data-object="deleteDataObject"
          @add-data-object="addDataObject"
          @move-data-object="moveDataObject"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.exception-container {
  position: relative;
  overflow: auto;
  max-height: calc(100vh - 100px);
}

.exception-sticky {
  position: sticky;
  top: 0;
  background-color: white;
  top: 0;
  z-index: 10;
  padding: 10px 0;
}

[child-taxon]:not([child-taxon=false]) {
  background-color: var(--bg-color-basis);
}
[group-taxon="Bill/BillService"] > div > .child-taxon:after,
[group-taxon="Bill/BillService"] > div > [child-taxon]:after {
  content: '';
  position: relative;
  display: block;
  border-bottom: 2px solid rgb(229, 231, 235);
  bottom: -1em;
  margin-bottom: 2em;
}
[group-taxon="Bill/BillService"] > div > .odd {
  /* background-color: rgba(255, 255, 0, 40%); */
}
[group-taxon="Bill/BillService"] > div > .even {
  /* background-color: rgba(250,250,250, 100%); */
}
</style>
