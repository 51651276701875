<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { useTimeoutPoll } from "@vueuse/core";
import appStore from "~/store";
import { listAuditRevisions } from "~/api/audit-revisions/audit-revisions";
import { createNote, listNotes } from "~/api/notes/notes";
import type { Note } from "~/model";
import AuditNoteAvatar from "~/views/app/organizations/project/workspace/panels/audit-note-avatar.vue";

defineEmits(["closed"]);

const { focusedAttribute } = storeToRefs(appStore.workspaceStore);

const auditEvents = ref([]);
const notes = ref([]);

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const newNote = ref();

const lastAttribute = ref();
const lastUpdated = ref(new Date());

function refresh() {
  lastUpdated.value = new Date();

  if (!lastAttribute.value) {
    return;
  }

  listAuditRevisions({ filter: `dataAttributeAudits.id: '${lastAttribute.value.id}'` }, {})
    .then((response) => {
      let previousValue = null;
      auditEvents.value = response.content
        .map((event) => {
          const attributeAudit = event.dataAttributeAudits.find(audit => audit.id === lastAttribute.value.id);
          if (!attributeAudit) {
            return null;
          }
          const newValue = attributeAudit.value;
          const oldValue = previousValue;
          previousValue = newValue;
          return {
            id: event.id,
            auditEvent: true,
            attribute: attributeAudit,
            user: event.platformUser,
            createdOn: event.transactionEnd,
            oldValue,
            newValue,
          };
        })
        .filter(event => event !== null);
    })
    .catch((error) => {
      console.error("Error fetching audit revisions:", error);
      // Display an error message to the user
    });

  listNotes({ filter: `attribute.id: '${lastAttribute.value.id}'` })
    .then((response) => {
      notes.value = response.content;
    })
    .catch((error) => {
      console.error("Error fetching notes:", error);
      // Display an error message to the user
    });
}

const {
  pause,
  resume,
} = useTimeoutPoll(refresh, 5000); // Increased interval to 5 seconds

resume();

function formatValue(value, isInitial) {
  return isInitial ? value : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const timeline = computed(() => {
  const attributeAuditEvent = auditEvents.value.map((event, index) => {
    const isInitial = index === 0;
    return {
      id: event.id,
      auditEvent: true,
      attribute: event.attribute,
      user: event.user,
      createdOn: event.createdOn,
      oldValue: formatValue(event.oldValue, isInitial),
      newValue: formatValue(event.newValue, isInitial),
    };
  });

  const t = [...attributeAuditEvent.filter(event => event.attribute), ...notes.value];
  t.sort((a, b) => (a.createdOn > b.createdOn ? 1 : a.createdOn < b.createdOn ? -1 : 0));

  return t;
});

watch(() => focusedAttribute, () => {
  if (focusedAttribute.value) {
    lastAttribute.value = focusedAttribute.value;
    refresh();
  }
}, { immediate: true, deep: true });

const taxon = computed(() => {
  if (lastAttribute.value) {
    return tagMetadataMap.value.get(lastAttribute.value.path);
  }
  return undefined;
});

function createNewNote() {
  newNote.value = {
    content: "",
    attribute: lastAttribute.value,
  } as Note;
}

function saveNote() {
  if (newNote.value && newNote.value.content.trim() !== "") {
    createNote(newNote.value).then(() => {
      refresh(); // Ensure the notes are refreshed
      newNote.value = undefined; // Clear the form after saving
    }).catch((error) => {
      console.error("Error saving note:", error);
      console.error("Failed to save note:", newNote.value); // Log the note that failed to save
    });
  } else {
    console.log("Note content cannot be empty."); // Log if the note content is empty
  }
}

function cancelNote() {
  newNote.value = undefined;
}
</script>

<template>
  <div>
    <div class="flex h-full flex-col">
      <div v-if="taxon" class="flex-1 p-4">
        <div class="text-xl font-semibold mb-3">
          {{ taxon.label }}
        </div>
        <div class="text-sm text-gray-500 mb-3">
          {{ taxon.description }}
        </div>

        <ol class="relative border-s border-gray-200 dark:border-gray-700 px-2">
          <li v-for="entry in timeline" :key="entry.id" class="mb-10 ms-4">
            <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700" />
            <div v-if="entry.auditEvent">
              <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                <AuditNoteAvatar :user="entry.user" :date="entry.createdOn" />
              </time>
              <div class="text-md font-semibold text-gray-900 dark:text-white">
                <div v-if="entry.attribute?.auditType === 'MOD'" class="mt-2">
                  Modified value from '{{ entry.oldValue }}' to '{{ entry.newValue }}'
                </div>
                <div v-else-if="entry.attribute?.auditType === 'ADD'" class="mt-2">
                  Created with value '{{ entry.newValue }}'
                </div>
                <div v-else>
                  {{ entry }}
                </div>
              </div>
            </div>
            <div v-else>
              <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                <AuditNoteAvatar :user="entry.user" :date="entry.createdOn" />
              </time>
              <KodexaMarkdown :content="entry.content" />
            </div>
          </li>
          <li class="ms-4">
            <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700" />
            <p v-if="!newNote" class="text-base font-normal text-gray-500 dark:text-gray-400">
              <KodexaButton icon="plus" size="small" type="secondary" @click="createNewNote">
                Add Note
              </KodexaButton>
            </p>
            <div v-else class="mb-4">
              <KodexaTextArea v-model="newNote.content" name="newNote" :rows="8" />
              <div class="mt-2">
                <KodexaButton icon="plus" size="small" type="primary" class="mr-1" @click="saveNote">
                  Save Note
                </KodexaButton>
                <KodexaButton icon="cancel" size="small" type="secondary" @click="cancelNote">
                  Cancel
                </KodexaButton>
              </div>
            </div>
          </li>
        </ol>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add necessary styles here */
</style>
