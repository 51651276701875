/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  Assistant
} from '../../model/assistant'
import type {
  AssistantState
} from '../../model/assistantState'
import type {
  CreateProjectParams
} from '../../model/createProjectParams'
import type {
  Dashboard
} from '../../model/dashboard'
import type {
  DataForm
} from '../../model/dataForm'
import type {
  DeleteProjectParams
} from '../../model/deleteProjectParams'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  Execution
} from '../../model/execution'
import type {
  GetAssistantExecutionsParams
} from '../../model/getAssistantExecutionsParams'
import type {
  GetEventTypeOptions200
} from '../../model/getEventTypeOptions200'
import type {
  GetEventTypeOptionsParams
} from '../../model/getEventTypeOptionsParams'
import type {
  GuidanceSet
} from '../../model/guidanceSet'
import type {
  KodexaResourceUri
} from '../../model/kodexaResourceUri'
import type {
  ListProjectsParams
} from '../../model/listProjectsParams'
import type {
  PageExecution
} from '../../model/pageExecution'
import type {
  PageProject
} from '../../model/pageProject'
import type {
  ProcessAssistantEventBody
} from '../../model/processAssistantEventBody'
import type {
  ProcessAssistantEventParams
} from '../../model/processAssistantEventParams'
import type {
  Project
} from '../../model/project'
import type {
  ProjectDataFlow
} from '../../model/projectDataFlow'
import type {
  ProjectMemory
} from '../../model/projectMemory'
import type {
  ProjectResourcesUpdate
} from '../../model/projectResourcesUpdate'
import type {
  ProjectStatus
} from '../../model/projectStatus'
import type {
  ProjectTag
} from '../../model/projectTag'
import type {
  ProjectTemplate
} from '../../model/projectTemplate'
import type {
  ProjectTemplateRequest
} from '../../model/projectTemplateRequest'
import type {
  Store
} from '../../model/store'
import type {
  Taxonomy
} from '../../model/taxonomy'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import type {
  User
} from '../../model/user'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getProject = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Project>(
      {url: `/api/projects/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id] as const;
    }

    
export const getGetProjectQueryOptions = <TData = Awaited<ReturnType<typeof getProject>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProjectQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProject>>> = ({ signal }) => getProject(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProject>>, TError, TData> 
}

export type GetProjectQueryResult = NonNullable<Awaited<ReturnType<typeof getProject>>>
export type GetProjectQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetProject = <TData = Awaited<ReturnType<typeof getProject>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateProject = (
    id: MaybeRef<string>,
    project: MaybeRef<Project>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
project = unref(project);
      
      return customAxios<Project>(
      {url: `/api/projects/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: project
    },
      options);
    }
  


export const getUpdateProjectMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProject>>, TError,{id: string;data: Project}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProject>>, TError,{id: string;data: Project}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProject>>, {id: string;data: Project}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProject(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateProjectMutationResult = NonNullable<Awaited<ReturnType<typeof updateProject>>>
    export type UpdateProjectMutationBody = Project
    export type UpdateProjectMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateProject = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProject>>, TError,{id: string;data: Project}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the project with the provided ID and its associated resources
 */
export const deleteProject = (
    id: MaybeRef<string>,
    params: MaybeRef<DeleteProjectParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
params = unref(params);
      
      return customAxios<void>(
      {url: `/api/projects/${id}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteProjectMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProject>>, TError,{id: string;params: DeleteProjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProject>>, TError,{id: string;params: DeleteProjectParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProject>>, {id: string;params: DeleteProjectParams}> = (props) => {
          const {id,params} = props ?? {};

          return  deleteProject(id,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProject>>>
    
    export type DeleteProjectMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteProject = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProject>>, TError,{id: string;params: DeleteProjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the tags on a project
 */
export const getProjectTags = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProjectTag[]>(
      {url: `/api/projects/${id}/tags`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectTagsQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'tags'] as const;
    }

    
export const getGetProjectTagsQueryOptions = <TData = Awaited<ReturnType<typeof getProjectTags>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectTags>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProjectTagsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectTags>>> = ({ signal }) => getProjectTags(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectTags>>, TError, TData> 
}

export type GetProjectTagsQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectTags>>>
export type GetProjectTagsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetProjectTags = <TData = Awaited<ReturnType<typeof getProjectTags>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectTags>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectTagsQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the tags on a project
 */
export const updateProjectTags = (
    id: MaybeRef<string>,
    projectTag: MaybeRef<ProjectTag[]>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
projectTag = unref(projectTag);
      
      return customAxios<ProjectTag[]>(
      {url: `/api/projects/${id}/tags`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: projectTag
    },
      options);
    }
  


export const getUpdateProjectTagsMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectTags>>, TError,{id: string;data: ProjectTag[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProjectTags>>, TError,{id: string;data: ProjectTag[]}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProjectTags>>, {id: string;data: ProjectTag[]}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProjectTags(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateProjectTagsMutationResult = NonNullable<Awaited<ReturnType<typeof updateProjectTags>>>
    export type UpdateProjectTagsMutationBody = ProjectTag[]
    export type UpdateProjectTagsMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateProjectTags = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectTags>>, TError,{id: string;data: ProjectTag[]}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateProjectTagsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update the status of the project
 */
export const updateStatus = (
    id: MaybeRef<string>,
    projectStatus: MaybeRef<ProjectStatus>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
projectStatus = unref(projectStatus);
      
      return customAxios<Project>(
      {url: `/api/projects/${id}/status`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: projectStatus
    },
      options);
    }
  


export const getUpdateStatusMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStatus>>, TError,{id: string;data: ProjectStatus}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateStatus>>, TError,{id: string;data: ProjectStatus}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStatus>>, {id: string;data: ProjectStatus}> = (props) => {
          const {id,data} = props ?? {};

          return  updateStatus(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateStatusMutationResult = NonNullable<Awaited<ReturnType<typeof updateStatus>>>
    export type UpdateStatusMutationBody = ProjectStatus
    export type UpdateStatusMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateStatus = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStatus>>, TError,{id: string;data: ProjectStatus}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Remove the project status
 */
export const removeStatus = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<Project>(
      {url: `/api/projects/${id}/status`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveStatusMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeStatus>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeStatus>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeStatus>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  removeStatus(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RemoveStatusMutationResult = NonNullable<Awaited<ReturnType<typeof removeStatus>>>
    
    export type RemoveStatusMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useRemoveStatus = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeStatus>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getRemoveStatusMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the project resources
 */
export const getProjectResources = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<KodexaResourceUri[]>(
      {url: `/api/projects/${id}/resources`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectResourcesQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'resources'] as const;
    }

    
export const getGetProjectResourcesQueryOptions = <TData = Awaited<ReturnType<typeof getProjectResources>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectResources>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProjectResourcesQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectResources>>> = ({ signal }) => getProjectResources(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectResources>>, TError, TData> 
}

export type GetProjectResourcesQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectResources>>>
export type GetProjectResourcesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetProjectResources = <TData = Awaited<ReturnType<typeof getProjectResources>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectResources>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectResourcesQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update all the resources associated with this project
 */
export const updateResources = (
    id: MaybeRef<string>,
    projectResourcesUpdate: MaybeRef<ProjectResourcesUpdate>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
projectResourcesUpdate = unref(projectResourcesUpdate);
      
      return customAxios<ProjectResourcesUpdate>(
      {url: `/api/projects/${id}/resources`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: projectResourcesUpdate
    },
      options);
    }
  


export const getUpdateResourcesMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateResources>>, TError,{id: string;data: ProjectResourcesUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateResources>>, TError,{id: string;data: ProjectResourcesUpdate}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateResources>>, {id: string;data: ProjectResourcesUpdate}> = (props) => {
          const {id,data} = props ?? {};

          return  updateResources(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateResourcesMutationResult = NonNullable<Awaited<ReturnType<typeof updateResources>>>
    export type UpdateResourcesMutationBody = ProjectResourcesUpdate
    export type UpdateResourcesMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateResources = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateResources>>, TError,{id: string;data: ProjectResourcesUpdate}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateResourcesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update the owner of the project
 */
export const updateOwner = (
    id: MaybeRef<string>,
    user: MaybeRef<NonReadonly<User>>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
user = unref(user);
      
      return customAxios<Project>(
      {url: `/api/projects/${id}/owner`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: user
    },
      options);
    }
  


export const getUpdateOwnerMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOwner>>, TError,{id: string;data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateOwner>>, TError,{id: string;data: NonReadonly<User>}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateOwner>>, {id: string;data: NonReadonly<User>}> = (props) => {
          const {id,data} = props ?? {};

          return  updateOwner(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateOwnerMutationResult = NonNullable<Awaited<ReturnType<typeof updateOwner>>>
    export type UpdateOwnerMutationBody = NonReadonly<User>
    export type UpdateOwnerMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateOwner = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateOwner>>, TError,{id: string;data: NonReadonly<User>}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateOwnerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Remove the project owner
 */
export const removeOwner = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<Project>(
      {url: `/api/projects/${id}/owner`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveOwnerMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeOwner>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeOwner>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeOwner>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  removeOwner(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RemoveOwnerMutationResult = NonNullable<Awaited<ReturnType<typeof removeOwner>>>
    
    export type RemoveOwnerMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useRemoveOwner = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeOwner>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getRemoveOwnerMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a list of the stores (type document) associated with this project
 */
export const getModelStores = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Store[]>(
      {url: `/api/projects/${id}/modelStores`, method: 'GET', signal
    },
      options);
    }
  

export const getGetModelStoresQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'modelStores'] as const;
    }

    
export const getGetModelStoresQueryOptions = <TData = Awaited<ReturnType<typeof getModelStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModelStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetModelStoresQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModelStores>>> = ({ signal }) => getModelStores(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModelStores>>, TError, TData> 
}

export type GetModelStoresQueryResult = NonNullable<Awaited<ReturnType<typeof getModelStores>>>
export type GetModelStoresQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetModelStores = <TData = Awaited<ReturnType<typeof getModelStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModelStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetModelStoresQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the stores (type model) associated with this project
 */
export const updateModelStores = (
    id: MaybeRef<string>,
    store: MaybeRef<Store[]>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
store = unref(store);
      
      return customAxios<Store[]>(
      {url: `/api/projects/${id}/modelStores`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: store
    },
      options);
    }
  


export const getUpdateModelStoresMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateModelStores>>, TError,{id: string;data: Store[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateModelStores>>, TError,{id: string;data: Store[]}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateModelStores>>, {id: string;data: Store[]}> = (props) => {
          const {id,data} = props ?? {};

          return  updateModelStores(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateModelStoresMutationResult = NonNullable<Awaited<ReturnType<typeof updateModelStores>>>
    export type UpdateModelStoresMutationBody = Store[]
    export type UpdateModelStoresMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateModelStores = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateModelStores>>, TError,{id: string;data: Store[]}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateModelStoresMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the project memory
 */
export const getProjectMemory = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProjectMemory>(
      {url: `/api/projects/${id}/memory`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectMemoryQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'memory'] as const;
    }

    
export const getGetProjectMemoryQueryOptions = <TData = Awaited<ReturnType<typeof getProjectMemory>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectMemory>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProjectMemoryQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectMemory>>> = ({ signal }) => getProjectMemory(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectMemory>>, TError, TData> 
}

export type GetProjectMemoryQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectMemory>>>
export type GetProjectMemoryQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetProjectMemory = <TData = Awaited<ReturnType<typeof getProjectMemory>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectMemory>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectMemoryQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the project memory
 */
export const updateProjectMemory = (
    id: MaybeRef<string>,
    projectMemory: MaybeRef<ProjectMemory>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
projectMemory = unref(projectMemory);
      
      return customAxios<ProjectMemory>(
      {url: `/api/projects/${id}/memory`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: projectMemory
    },
      options);
    }
  


export const getUpdateProjectMemoryMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectMemory>>, TError,{id: string;data: ProjectMemory}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProjectMemory>>, TError,{id: string;data: ProjectMemory}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProjectMemory>>, {id: string;data: ProjectMemory}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProjectMemory(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateProjectMemoryMutationResult = NonNullable<Awaited<ReturnType<typeof updateProjectMemory>>>
    export type UpdateProjectMemoryMutationBody = ProjectMemory
    export type UpdateProjectMemoryMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateProjectMemory = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectMemory>>, TError,{id: string;data: ProjectMemory}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateProjectMemoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a list of the stores (type document) associated with this project
 */
export const getDocumentStores = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Store[]>(
      {url: `/api/projects/${id}/documentStores`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDocumentStoresQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'documentStores'] as const;
    }

    
export const getGetDocumentStoresQueryOptions = <TData = Awaited<ReturnType<typeof getDocumentStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDocumentStoresQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDocumentStores>>> = ({ signal }) => getDocumentStores(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDocumentStores>>, TError, TData> 
}

export type GetDocumentStoresQueryResult = NonNullable<Awaited<ReturnType<typeof getDocumentStores>>>
export type GetDocumentStoresQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetDocumentStores = <TData = Awaited<ReturnType<typeof getDocumentStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDocumentStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDocumentStoresQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the stores (type document) associated with this project
 */
export const updateDocumentStores = (
    id: MaybeRef<string>,
    store: MaybeRef<Store[]>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
store = unref(store);
      
      return customAxios<Store[]>(
      {url: `/api/projects/${id}/documentStores`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: store
    },
      options);
    }
  


export const getUpdateDocumentStoresMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDocumentStores>>, TError,{id: string;data: Store[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDocumentStores>>, TError,{id: string;data: Store[]}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDocumentStores>>, {id: string;data: Store[]}> = (props) => {
          const {id,data} = props ?? {};

          return  updateDocumentStores(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDocumentStoresMutationResult = NonNullable<Awaited<ReturnType<typeof updateDocumentStores>>>
    export type UpdateDocumentStoresMutationBody = Store[]
    export type UpdateDocumentStoresMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateDocumentStores = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDocumentStores>>, TError,{id: string;data: Store[]}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateDocumentStoresMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a list of the stores (type data) associated with this project
 */
export const getDataStores = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Store[]>(
      {url: `/api/projects/${id}/dataStores`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDataStoresQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'dataStores'] as const;
    }

    
export const getGetDataStoresQueryOptions = <TData = Awaited<ReturnType<typeof getDataStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDataStoresQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataStores>>> = ({ signal }) => getDataStores(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataStores>>, TError, TData> 
}

export type GetDataStoresQueryResult = NonNullable<Awaited<ReturnType<typeof getDataStores>>>
export type GetDataStoresQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetDataStores = <TData = Awaited<ReturnType<typeof getDataStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDataStoresQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the stores (type data) associated with this project
 */
export const updateDataStores = (
    id: MaybeRef<string>,
    store: MaybeRef<Store[]>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
store = unref(store);
      
      return customAxios<Store[]>(
      {url: `/api/projects/${id}/dataStores`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: store
    },
      options);
    }
  


export const getUpdateDataStoresMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataStores>>, TError,{id: string;data: Store[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDataStores>>, TError,{id: string;data: Store[]}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDataStores>>, {id: string;data: Store[]}> = (props) => {
          const {id,data} = props ?? {};

          return  updateDataStores(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDataStoresMutationResult = NonNullable<Awaited<ReturnType<typeof updateDataStores>>>
    export type UpdateDataStoresMutationBody = Store[]
    export type UpdateDataStoresMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateDataStores = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataStores>>, TError,{id: string;data: Store[]}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateDataStoresMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the data flow for a project
 */
export const getProjectDataFlow = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProjectDataFlow>(
      {url: `/api/projects/${id}/dataFlow`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectDataFlowQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'dataFlow'] as const;
    }

    
export const getGetProjectDataFlowQueryOptions = <TData = Awaited<ReturnType<typeof getProjectDataFlow>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectDataFlow>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProjectDataFlowQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectDataFlow>>> = ({ signal }) => getProjectDataFlow(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectDataFlow>>, TError, TData> 
}

export type GetProjectDataFlowQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectDataFlow>>>
export type GetProjectDataFlowQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetProjectDataFlow = <TData = Awaited<ReturnType<typeof getProjectDataFlow>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectDataFlow>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectDataFlowQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the tags on a project
 */
export const updateProjectDataFlow = (
    id: MaybeRef<string>,
    projectDataFlow: MaybeRef<ProjectDataFlow>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
projectDataFlow = unref(projectDataFlow);
      
      return customAxios<ProjectDataFlow>(
      {url: `/api/projects/${id}/dataFlow`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: projectDataFlow
    },
      options);
    }
  


export const getUpdateProjectDataFlowMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectDataFlow>>, TError,{id: string;data: ProjectDataFlow}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProjectDataFlow>>, TError,{id: string;data: ProjectDataFlow}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProjectDataFlow>>, {id: string;data: ProjectDataFlow}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProjectDataFlow(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateProjectDataFlowMutationResult = NonNullable<Awaited<ReturnType<typeof updateProjectDataFlow>>>
    export type UpdateProjectDataFlowMutationBody = ProjectDataFlow
    export type UpdateProjectDataFlowMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateProjectDataFlow = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectDataFlow>>, TError,{id: string;data: ProjectDataFlow}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateProjectDataFlowMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Paginated list of assistants that have been added to this project
 */
export const getAssistants = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Assistant[]>(
      {url: `/api/projects/${id}/assistants`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssistantsQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'assistants'] as const;
    }

    
export const getGetAssistantsQueryOptions = <TData = Awaited<ReturnType<typeof getAssistants>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistants>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssistantsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssistants>>> = ({ signal }) => getAssistants(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssistants>>, TError, TData> 
}

export type GetAssistantsQueryResult = NonNullable<Awaited<ReturnType<typeof getAssistants>>>
export type GetAssistantsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetAssistants = <TData = Awaited<ReturnType<typeof getAssistants>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistants>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssistantsQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the assistant in the given project
 */
export const updateProjectAssistant = (
    id: MaybeRef<string>,
    assistant: MaybeRef<Assistant>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistant = unref(assistant);
      
      return customAxios<Assistant>(
      {url: `/api/projects/${id}/assistants`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistant
    },
      options);
    }
  


export const getUpdateProjectAssistantMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectAssistant>>, TError,{id: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProjectAssistant>>, TError,{id: string;data: Assistant}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProjectAssistant>>, {id: string;data: Assistant}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProjectAssistant(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateProjectAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof updateProjectAssistant>>>
    export type UpdateProjectAssistantMutationBody = Assistant
    export type UpdateProjectAssistantMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateProjectAssistant = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProjectAssistant>>, TError,{id: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateProjectAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create and add assistant to project
 */
export const addAssistant = (
    id: MaybeRef<string>,
    assistant: MaybeRef<Assistant>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistant = unref(assistant);
      
      return customAxios<Assistant>(
      {url: `/api/projects/${id}/assistants`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assistant
    },
      options);
    }
  


export const getAddAssistantMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addAssistant>>, TError,{id: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addAssistant>>, TError,{id: string;data: Assistant}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addAssistant>>, {id: string;data: Assistant}> = (props) => {
          const {id,data} = props ?? {};

          return  addAssistant(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof addAssistant>>>
    export type AddAssistantMutationBody = Assistant
    export type AddAssistantMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useAddAssistant = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addAssistant>>, TError,{id: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getAddAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update the assistant in the given project
 */
export const updateAssistantWithId = (
    id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
    assistant: MaybeRef<Assistant>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistantId = unref(assistantId);
assistant = unref(assistant);
      
      return customAxios<Assistant>(
      {url: `/api/projects/${id}/assistants/${assistantId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistant
    },
      options);
    }
  


export const getUpdateAssistantWithIdMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantWithId>>, TError,{id: string;assistantId: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssistantWithId>>, TError,{id: string;assistantId: string;data: Assistant}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssistantWithId>>, {id: string;assistantId: string;data: Assistant}> = (props) => {
          const {id,assistantId,data} = props ?? {};

          return  updateAssistantWithId(id,assistantId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateAssistantWithIdMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssistantWithId>>>
    export type UpdateAssistantWithIdMutationBody = Assistant
    export type UpdateAssistantWithIdMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateAssistantWithId = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantWithId>>, TError,{id: string;assistantId: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateAssistantWithIdMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete specified assistant
 */
export const deleteProjectAssistant = (
    id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistantId = unref(assistantId);
      
      return customAxios<void>(
      {url: `/api/projects/${id}/assistants/${assistantId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProjectAssistantMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectAssistant>>, TError,{id: string;assistantId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProjectAssistant>>, TError,{id: string;assistantId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProjectAssistant>>, {id: string;assistantId: string}> = (props) => {
          const {id,assistantId} = props ?? {};

          return  deleteProjectAssistant(id,assistantId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteProjectAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProjectAssistant>>>
    
    export type DeleteProjectAssistantMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteProjectAssistant = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProjectAssistant>>, TError,{id: string;assistantId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteProjectAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List the assistant stores specified assistant
 */
export const getAssistantStores = (
    id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
assistantId = unref(assistantId);
      
      return customAxios<Store[]>(
      {url: `/api/projects/${id}/assistants/${assistantId}/stores`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssistantStoresQueryKey = (id: MaybeRef<string>,
    assistantId: MaybeRef<string>,) => {
    return ['api','projects',id,'assistants',assistantId,'stores'] as const;
    }

    
export const getGetAssistantStoresQueryOptions = <TData = Awaited<ReturnType<typeof getAssistantStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>,
    assistantId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssistantStoresQueryKey(id,assistantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssistantStores>>> = ({ signal }) => getAssistantStores(id,assistantId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id) && unref(assistantId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssistantStores>>, TError, TData> 
}

export type GetAssistantStoresQueryResult = NonNullable<Awaited<ReturnType<typeof getAssistantStores>>>
export type GetAssistantStoresQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetAssistantStores = <TData = Awaited<ReturnType<typeof getAssistantStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>,
    assistantId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssistantStoresQueryOptions(id,assistantId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the list of stores for an assistant
 */
export const updateAssistantStores = (
    id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
    store: MaybeRef<Store[]>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistantId = unref(assistantId);
store = unref(store);
      
      return customAxios<Assistant>(
      {url: `/api/projects/${id}/assistants/${assistantId}/stores`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: store
    },
      options);
    }
  


export const getUpdateAssistantStoresMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantStores>>, TError,{id: string;assistantId: string;data: Store[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssistantStores>>, TError,{id: string;assistantId: string;data: Store[]}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssistantStores>>, {id: string;assistantId: string;data: Store[]}> = (props) => {
          const {id,assistantId,data} = props ?? {};

          return  updateAssistantStores(id,assistantId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateAssistantStoresMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssistantStores>>>
    export type UpdateAssistantStoresMutationBody = Store[]
    export type UpdateAssistantStoresMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateAssistantStores = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantStores>>, TError,{id: string;assistantId: string;data: Store[]}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateAssistantStoresMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Send a schedule event now to this assistant
 */
export const scheduleAssistantNow = (
    id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistantId = unref(assistantId);
      
      return customAxios<void>(
      {url: `/api/projects/${id}/assistants/${assistantId}/schedule`, method: 'PUT'
    },
      options);
    }
  


export const getScheduleAssistantNowMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scheduleAssistantNow>>, TError,{id: string;assistantId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof scheduleAssistantNow>>, TError,{id: string;assistantId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof scheduleAssistantNow>>, {id: string;assistantId: string}> = (props) => {
          const {id,assistantId} = props ?? {};

          return  scheduleAssistantNow(id,assistantId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ScheduleAssistantNowMutationResult = NonNullable<Awaited<ReturnType<typeof scheduleAssistantNow>>>
    
    export type ScheduleAssistantNowMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useScheduleAssistantNow = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof scheduleAssistantNow>>, TError,{id: string;assistantId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getScheduleAssistantNowMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Deactivate the assistant (stopping it from getting content events)
 */
export const deactivateAssistant = (
    id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistantId = unref(assistantId);
      
      return customAxios<Assistant>(
      {url: `/api/projects/${id}/assistants/${assistantId}/deactivate`, method: 'PUT'
    },
      options);
    }
  


export const getDeactivateAssistantMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deactivateAssistant>>, TError,{id: string;assistantId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deactivateAssistant>>, TError,{id: string;assistantId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deactivateAssistant>>, {id: string;assistantId: string}> = (props) => {
          const {id,assistantId} = props ?? {};

          return  deactivateAssistant(id,assistantId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeactivateAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof deactivateAssistant>>>
    
    export type DeactivateAssistantMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeactivateAssistant = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deactivateAssistant>>, TError,{id: string;assistantId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeactivateAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Activate the assistant (ensuring it gets content events)
 */
export const activateAssistant = (
    id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistantId = unref(assistantId);
      
      return customAxios<Assistant>(
      {url: `/api/projects/${id}/assistants/${assistantId}/activate`, method: 'PUT'
    },
      options);
    }
  


export const getActivateAssistantMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activateAssistant>>, TError,{id: string;assistantId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof activateAssistant>>, TError,{id: string;assistantId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activateAssistant>>, {id: string;assistantId: string}> = (props) => {
          const {id,assistantId} = props ?? {};

          return  activateAssistant(id,assistantId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ActivateAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof activateAssistant>>>
    
    export type ActivateAssistantMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useActivateAssistant = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activateAssistant>>, TError,{id: string;assistantId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getActivateAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listProjects = (
    params?: MaybeRef<ListProjectsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageProject>(
      {url: `/api/projects`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListProjectsQueryKey = (params?: MaybeRef<ListProjectsParams>,) => {
    return ['api','projects', ...(params ? [params]: [])] as const;
    }

    
export const getListProjectsQueryOptions = <TData = Awaited<ReturnType<typeof listProjects>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListProjectsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListProjectsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProjects>>> = ({ signal }) => listProjects(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProjects>>, TError, TData> 
}

export type ListProjectsQueryResult = NonNullable<Awaited<ReturnType<typeof listProjects>>>
export type ListProjectsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListProjects = <TData = Awaited<ReturnType<typeof listProjects>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListProjectsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListProjectsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new project based on the template referenced
 */
export const createProject = (
    project: MaybeRef<Project>,
    params: MaybeRef<CreateProjectParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      project = unref(project);
params = unref(params);
      
      return customAxios<Project>(
      {url: `/api/projects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: project,
        params: unref(params)
    },
      options);
    }
  


export const getCreateProjectMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProject>>, TError,{data: Project;params: CreateProjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProject>>, TError,{data: Project;params: CreateProjectParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProject>>, {data: Project;params: CreateProjectParams}> = (props) => {
          const {data,params} = props ?? {};

          return  createProject(data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateProjectMutationResult = NonNullable<Awaited<ReturnType<typeof createProject>>>
    export type CreateProjectMutationBody = Project
    export type CreateProjectMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateProject = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProject>>, TError,{data: Project;params: CreateProjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a list of the taxonomies (type classification) associated with this project
 */
export const getTaxonomies = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Taxonomy[]>(
      {url: `/api/projects/${id}/taxonomies`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaxonomiesQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'taxonomies'] as const;
    }

    
export const getGetTaxonomiesQueryOptions = <TData = Awaited<ReturnType<typeof getTaxonomies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaxonomies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTaxonomiesQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaxonomies>>> = ({ signal }) => getTaxonomies(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaxonomies>>, TError, TData> 
}

export type GetTaxonomiesQueryResult = NonNullable<Awaited<ReturnType<typeof getTaxonomies>>>
export type GetTaxonomiesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetTaxonomies = <TData = Awaited<ReturnType<typeof getTaxonomies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaxonomies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTaxonomiesQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Add a taxonomy to the project
 */
export const addTaxonomyToProject = (
    id: MaybeRef<string>,
    taxonomy: MaybeRef<Taxonomy>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
taxonomy = unref(taxonomy);
      
      return customAxios<Project>(
      {url: `/api/projects/${id}/taxonomies`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: taxonomy
    },
      options);
    }
  


export const getAddTaxonomyToProjectMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTaxonomyToProject>>, TError,{id: string;data: Taxonomy}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addTaxonomyToProject>>, TError,{id: string;data: Taxonomy}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addTaxonomyToProject>>, {id: string;data: Taxonomy}> = (props) => {
          const {id,data} = props ?? {};

          return  addTaxonomyToProject(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddTaxonomyToProjectMutationResult = NonNullable<Awaited<ReturnType<typeof addTaxonomyToProject>>>
    export type AddTaxonomyToProjectMutationBody = Taxonomy
    export type AddTaxonomyToProjectMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useAddTaxonomyToProject = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTaxonomyToProject>>, TError,{id: string;data: Taxonomy}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getAddTaxonomyToProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a list of the stores associated with this project
 */
export const getStores = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Store[]>(
      {url: `/api/projects/${id}/stores`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStoresQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'stores'] as const;
    }

    
export const getGetStoresQueryOptions = <TData = Awaited<ReturnType<typeof getStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetStoresQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStores>>> = ({ signal }) => getStores(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStores>>, TError, TData> 
}

export type GetStoresQueryResult = NonNullable<Awaited<ReturnType<typeof getStores>>>
export type GetStoresQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetStores = <TData = Awaited<ReturnType<typeof getStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStoresQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Add a store to the project
 */
export const addStoreToProject = (
    id: MaybeRef<string>,
    store: MaybeRef<Store>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
store = unref(store);
      
      return customAxios<Project>(
      {url: `/api/projects/${id}/stores`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: store
    },
      options);
    }
  


export const getAddStoreToProjectMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addStoreToProject>>, TError,{id: string;data: Store}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addStoreToProject>>, TError,{id: string;data: Store}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addStoreToProject>>, {id: string;data: Store}> = (props) => {
          const {id,data} = props ?? {};

          return  addStoreToProject(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddStoreToProjectMutationResult = NonNullable<Awaited<ReturnType<typeof addStoreToProject>>>
    export type AddStoreToProjectMutationBody = Store
    export type AddStoreToProjectMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useAddStoreToProject = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addStoreToProject>>, TError,{id: string;data: Store}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getAddStoreToProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a list of the data forms associated with this project
 */
export const getDataForms = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<DataForm[]>(
      {url: `/api/projects/${id}/dataForms`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDataFormsQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'dataForms'] as const;
    }

    
export const getGetDataFormsQueryOptions = <TData = Awaited<ReturnType<typeof getDataForms>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataForms>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDataFormsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataForms>>> = ({ signal }) => getDataForms(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataForms>>, TError, TData> 
}

export type GetDataFormsQueryResult = NonNullable<Awaited<ReturnType<typeof getDataForms>>>
export type GetDataFormsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetDataForms = <TData = Awaited<ReturnType<typeof getDataForms>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataForms>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDataFormsQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Add a dataform to the project
 */
export const addDataFormToProject = (
    id: MaybeRef<string>,
    dataForm: MaybeRef<DataForm>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
dataForm = unref(dataForm);
      
      return customAxios<Project>(
      {url: `/api/projects/${id}/dataForms`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dataForm
    },
      options);
    }
  


export const getAddDataFormToProjectMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addDataFormToProject>>, TError,{id: string;data: DataForm}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addDataFormToProject>>, TError,{id: string;data: DataForm}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addDataFormToProject>>, {id: string;data: DataForm}> = (props) => {
          const {id,data} = props ?? {};

          return  addDataFormToProject(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddDataFormToProjectMutationResult = NonNullable<Awaited<ReturnType<typeof addDataFormToProject>>>
    export type AddDataFormToProjectMutationBody = DataForm
    export type AddDataFormToProjectMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useAddDataFormToProject = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addDataFormToProject>>, TError,{id: string;data: DataForm}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getAddDataFormToProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a list of the dashboards associated with this project
 */
export const getDashboards = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Dashboard[]>(
      {url: `/api/projects/${id}/dashboards`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDashboardsQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'dashboards'] as const;
    }

    
export const getGetDashboardsQueryOptions = <TData = Awaited<ReturnType<typeof getDashboards>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDashboards>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDashboardsQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDashboards>>> = ({ signal }) => getDashboards(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDashboards>>, TError, TData> 
}

export type GetDashboardsQueryResult = NonNullable<Awaited<ReturnType<typeof getDashboards>>>
export type GetDashboardsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetDashboards = <TData = Awaited<ReturnType<typeof getDashboards>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDashboards>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDashboardsQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Add a dashboard to the project
 */
export const addDashboardToProject = (
    id: MaybeRef<string>,
    dashboard: MaybeRef<Dashboard>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
dashboard = unref(dashboard);
      
      return customAxios<Project>(
      {url: `/api/projects/${id}/dashboards`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dashboard
    },
      options);
    }
  


export const getAddDashboardToProjectMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addDashboardToProject>>, TError,{id: string;data: Dashboard}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addDashboardToProject>>, TError,{id: string;data: Dashboard}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addDashboardToProject>>, {id: string;data: Dashboard}> = (props) => {
          const {id,data} = props ?? {};

          return  addDashboardToProject(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddDashboardToProjectMutationResult = NonNullable<Awaited<ReturnType<typeof addDashboardToProject>>>
    export type AddDashboardToProjectMutationBody = Dashboard
    export type AddDashboardToProjectMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useAddDashboardToProject = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addDashboardToProject>>, TError,{id: string;data: Dashboard}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getAddDashboardToProjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create a project template
 */
export const createProjectTemplateFromRequest = (
    id: MaybeRef<string>,
    projectTemplateRequest: MaybeRef<ProjectTemplateRequest>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
projectTemplateRequest = unref(projectTemplateRequest);
      
      return customAxios<ProjectTemplate>(
      {url: `/api/projects/${id}/createProjectTemplate`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: projectTemplateRequest
    },
      options);
    }
  


export const getCreateProjectTemplateFromRequestMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectTemplateFromRequest>>, TError,{id: string;data: ProjectTemplateRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProjectTemplateFromRequest>>, TError,{id: string;data: ProjectTemplateRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProjectTemplateFromRequest>>, {id: string;data: ProjectTemplateRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  createProjectTemplateFromRequest(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateProjectTemplateFromRequestMutationResult = NonNullable<Awaited<ReturnType<typeof createProjectTemplateFromRequest>>>
    export type CreateProjectTemplateFromRequestMutationBody = ProjectTemplateRequest
    export type CreateProjectTemplateFromRequestMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateProjectTemplateFromRequest = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProjectTemplateFromRequest>>, TError,{id: string;data: ProjectTemplateRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateProjectTemplateFromRequestMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Send a document to upload and send as an event to an assistant
 */
export const processAssistantEvent = (
    id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
    processAssistantEventBody: MaybeRef<ProcessAssistantEventBody>,
    params?: MaybeRef<ProcessAssistantEventParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistantId = unref(assistantId);
processAssistantEventBody = unref(processAssistantEventBody);
params = unref(params);
      
      return customAxios<Execution>(
      {url: `/api/projects/${id}/assistants/${assistantId}/events`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: processAssistantEventBody,
        params: unref(params)
    },
      options);
    }
  


export const getProcessAssistantEventMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof processAssistantEvent>>, TError,{id: string;assistantId: string;data: ProcessAssistantEventBody;params?: ProcessAssistantEventParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof processAssistantEvent>>, TError,{id: string;assistantId: string;data: ProcessAssistantEventBody;params?: ProcessAssistantEventParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof processAssistantEvent>>, {id: string;assistantId: string;data: ProcessAssistantEventBody;params?: ProcessAssistantEventParams}> = (props) => {
          const {id,assistantId,data,params} = props ?? {};

          return  processAssistantEvent(id,assistantId,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ProcessAssistantEventMutationResult = NonNullable<Awaited<ReturnType<typeof processAssistantEvent>>>
    export type ProcessAssistantEventMutationBody = ProcessAssistantEventBody
    export type ProcessAssistantEventMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useProcessAssistantEvent = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof processAssistantEvent>>, TError,{id: string;assistantId: string;data: ProcessAssistantEventBody;params?: ProcessAssistantEventParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getProcessAssistantEventMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForProject = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/projects/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForProjectQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForProjectQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForProject>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForProjectQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForProject>>> = ({ signal }) => getChangeSequenceForProject(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProject>>, TError, TData> 
}

export type GetChangeSequenceForProjectQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForProject>>>
export type GetChangeSequenceForProjectQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForProject = <TData = Awaited<ReturnType<typeof getChangeSequenceForProject>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProject>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForProjectQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the notes for the project
 */
export const getProjectNotes = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<string>(
      {url: `/api/projects/${id}/notes`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectNotesQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'notes'] as const;
    }

    
export const getGetProjectNotesQueryOptions = <TData = Awaited<ReturnType<typeof getProjectNotes>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectNotes>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProjectNotesQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectNotes>>> = ({ signal }) => getProjectNotes(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectNotes>>, TError, TData> 
}

export type GetProjectNotesQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectNotes>>>
export type GetProjectNotesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetProjectNotes = <TData = Awaited<ReturnType<typeof getProjectNotes>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectNotes>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectNotesQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get a list of the stores (type data) associated with this project
 */
export const getProjectGuidance = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<GuidanceSet[]>(
      {url: `/api/projects/${id}/guidance`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProjectGuidanceQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'guidance'] as const;
    }

    
export const getGetProjectGuidanceQueryOptions = <TData = Awaited<ReturnType<typeof getProjectGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProjectGuidanceQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProjectGuidance>>> = ({ signal }) => getProjectGuidance(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProjectGuidance>>, TError, TData> 
}

export type GetProjectGuidanceQueryResult = NonNullable<Awaited<ReturnType<typeof getProjectGuidance>>>
export type GetProjectGuidanceQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetProjectGuidance = <TData = Awaited<ReturnType<typeof getProjectGuidance>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProjectGuidance>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProjectGuidanceQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a project template request
 */
export const createProjectTemplateRequest = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<ProjectTemplateRequest>(
      {url: `/api/projects/${id}/createProjectTemplateRequest`, method: 'GET', signal
    },
      options);
    }
  

export const getCreateProjectTemplateRequestQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'createProjectTemplateRequest'] as const;
    }

    
export const getCreateProjectTemplateRequestQueryOptions = <TData = Awaited<ReturnType<typeof createProjectTemplateRequest>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof createProjectTemplateRequest>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getCreateProjectTemplateRequestQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof createProjectTemplateRequest>>> = ({ signal }) => createProjectTemplateRequest(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof createProjectTemplateRequest>>, TError, TData> 
}

export type CreateProjectTemplateRequestQueryResult = NonNullable<Awaited<ReturnType<typeof createProjectTemplateRequest>>>
export type CreateProjectTemplateRequestQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useCreateProjectTemplateRequest = <TData = Awaited<ReturnType<typeof createProjectTemplateRequest>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof createProjectTemplateRequest>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCreateProjectTemplateRequestQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get a list of the taxonomies (type content) associated with this project
 */
export const getContentTaxonomies = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Taxonomy[]>(
      {url: `/api/projects/${id}/contentTaxonomies`, method: 'GET', signal
    },
      options);
    }
  

export const getGetContentTaxonomiesQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'contentTaxonomies'] as const;
    }

    
export const getGetContentTaxonomiesQueryOptions = <TData = Awaited<ReturnType<typeof getContentTaxonomies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentTaxonomies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetContentTaxonomiesQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentTaxonomies>>> = ({ signal }) => getContentTaxonomies(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContentTaxonomies>>, TError, TData> 
}

export type GetContentTaxonomiesQueryResult = NonNullable<Awaited<ReturnType<typeof getContentTaxonomies>>>
export type GetContentTaxonomiesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetContentTaxonomies = <TData = Awaited<ReturnType<typeof getContentTaxonomies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentTaxonomies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContentTaxonomiesQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get a list of the taxonomies (type classification) associated with this project
 */
export const getClassificationTaxonomies = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Taxonomy[]>(
      {url: `/api/projects/${id}/classificationTaxonomies`, method: 'GET', signal
    },
      options);
    }
  

export const getGetClassificationTaxonomiesQueryKey = (id: MaybeRef<string>,) => {
    return ['api','projects',id,'classificationTaxonomies'] as const;
    }

    
export const getGetClassificationTaxonomiesQueryOptions = <TData = Awaited<ReturnType<typeof getClassificationTaxonomies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClassificationTaxonomies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetClassificationTaxonomiesQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getClassificationTaxonomies>>> = ({ signal }) => getClassificationTaxonomies(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getClassificationTaxonomies>>, TError, TData> 
}

export type GetClassificationTaxonomiesQueryResult = NonNullable<Awaited<ReturnType<typeof getClassificationTaxonomies>>>
export type GetClassificationTaxonomiesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetClassificationTaxonomies = <TData = Awaited<ReturnType<typeof getClassificationTaxonomies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getClassificationTaxonomies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetClassificationTaxonomiesQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the assistant in the given project
 */
export const getAssistantState = (
    id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
assistantId = unref(assistantId);
      
      return customAxios<AssistantState>(
      {url: `/api/projects/${id}/assistants/${assistantId}/status`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssistantStateQueryKey = (id: MaybeRef<string>,
    assistantId: MaybeRef<string>,) => {
    return ['api','projects',id,'assistants',assistantId,'status'] as const;
    }

    
export const getGetAssistantStateQueryOptions = <TData = Awaited<ReturnType<typeof getAssistantState>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>,
    assistantId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantState>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssistantStateQueryKey(id,assistantId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssistantState>>> = ({ signal }) => getAssistantState(id,assistantId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id) && unref(assistantId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssistantState>>, TError, TData> 
}

export type GetAssistantStateQueryResult = NonNullable<Awaited<ReturnType<typeof getAssistantState>>>
export type GetAssistantStateQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetAssistantState = <TData = Awaited<ReturnType<typeof getAssistantState>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>,
    assistantId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantState>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssistantStateQueryOptions(id,assistantId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Paginated list of assistant executions
 */
export const getAssistantExecutions = (
    id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
    params: MaybeRef<GetAssistantExecutionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
assistantId = unref(assistantId);
params = unref(params);
      
      return customAxios<PageExecution>(
      {url: `/api/projects/${id}/assistants/${assistantId}/executions`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetAssistantExecutionsQueryKey = (id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
    params: MaybeRef<GetAssistantExecutionsParams>,) => {
    return ['api','projects',id,'assistants',assistantId,'executions', ...(params ? [params]: [])] as const;
    }

    
export const getGetAssistantExecutionsQueryOptions = <TData = Awaited<ReturnType<typeof getAssistantExecutions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
    params: MaybeRef<GetAssistantExecutionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantExecutions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssistantExecutionsQueryKey(id,assistantId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssistantExecutions>>> = ({ signal }) => getAssistantExecutions(id,assistantId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id) && unref(assistantId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssistantExecutions>>, TError, TData> 
}

export type GetAssistantExecutionsQueryResult = NonNullable<Awaited<ReturnType<typeof getAssistantExecutions>>>
export type GetAssistantExecutionsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetAssistantExecutions = <TData = Awaited<ReturnType<typeof getAssistantExecutions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
    params: MaybeRef<GetAssistantExecutionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantExecutions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssistantExecutionsQueryOptions(id,assistantId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



export const getEventTypeOptions = (
    id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
    eventTypeName: MaybeRef<string>,
    params?: MaybeRef<GetEventTypeOptionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
assistantId = unref(assistantId);
eventTypeName = unref(eventTypeName);
params = unref(params);
      
      return customAxios<GetEventTypeOptions200>(
      {url: `/api/projects/${id}/assistants/${assistantId}/events/${eventTypeName}/options`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetEventTypeOptionsQueryKey = (id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
    eventTypeName: MaybeRef<string>,
    params?: MaybeRef<GetEventTypeOptionsParams>,) => {
    return ['api','projects',id,'assistants',assistantId,'events',eventTypeName,'options', ...(params ? [params]: [])] as const;
    }

    
export const getGetEventTypeOptionsQueryOptions = <TData = Awaited<ReturnType<typeof getEventTypeOptions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
    eventTypeName: MaybeRef<string>,
    params?: MaybeRef<GetEventTypeOptionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventTypeOptions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetEventTypeOptionsQueryKey(id,assistantId,eventTypeName,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getEventTypeOptions>>> = ({ signal }) => getEventTypeOptions(id,assistantId,eventTypeName,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id) && unref(assistantId) && unref(eventTypeName))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getEventTypeOptions>>, TError, TData> 
}

export type GetEventTypeOptionsQueryResult = NonNullable<Awaited<ReturnType<typeof getEventTypeOptions>>>
export type GetEventTypeOptionsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetEventTypeOptions = <TData = Awaited<ReturnType<typeof getEventTypeOptions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>,
    assistantId: MaybeRef<string>,
    eventTypeName: MaybeRef<string>,
    params?: MaybeRef<GetEventTypeOptionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getEventTypeOptions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetEventTypeOptionsQueryOptions(id,assistantId,eventTypeName,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



