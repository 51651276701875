<script setup lang="ts">
import { storeToRefs } from "pinia";
import appStore from "~/store";

const {
  project,
  documentStores,
  dataStores,
  modelStores,
} = storeToRefs(appStore.projectStore);
</script>

<template>
  <div>
    <div style="height: calc(100vh - 250px);" />
  </div>
</template>

<style scoped>

</style>
