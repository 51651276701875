/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListNotificationsParams
} from '../../model/listNotificationsParams'
import type {
  Notification
} from '../../model/notification'
import type {
  PageNotification
} from '../../model/pageNotification'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getNotification = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Notification>(
      {url: `/api/notifications/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetNotificationQueryKey = (id: MaybeRef<string>,) => {
    return ['api','notifications',id] as const;
    }

    
export const getGetNotificationQueryOptions = <TData = Awaited<ReturnType<typeof getNotification>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNotification>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetNotificationQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotification>>> = ({ signal }) => getNotification(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getNotification>>, TError, TData> 
}

export type GetNotificationQueryResult = NonNullable<Awaited<ReturnType<typeof getNotification>>>
export type GetNotificationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetNotification = <TData = Awaited<ReturnType<typeof getNotification>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNotification>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetNotificationQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateNotification = (
    id: MaybeRef<string>,
    notification: MaybeRef<Notification>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
notification = unref(notification);
      
      return customAxios<Notification>(
      {url: `/api/notifications/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: notification
    },
      options);
    }
  


export const getUpdateNotificationMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateNotification>>, TError,{id: string;data: Notification}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateNotification>>, TError,{id: string;data: Notification}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateNotification>>, {id: string;data: Notification}> = (props) => {
          const {id,data} = props ?? {};

          return  updateNotification(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof updateNotification>>>
    export type UpdateNotificationMutationBody = Notification
    export type UpdateNotificationMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateNotification = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateNotification>>, TError,{id: string;data: Notification}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateNotificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteNotification = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/notifications/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteNotificationMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNotification>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteNotification>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNotification>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteNotification(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNotification>>>
    
    export type DeleteNotificationMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteNotification = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNotification>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteNotificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listNotifications = (
    params?: MaybeRef<ListNotificationsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageNotification>(
      {url: `/api/notifications`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListNotificationsQueryKey = (params?: MaybeRef<ListNotificationsParams>,) => {
    return ['api','notifications', ...(params ? [params]: [])] as const;
    }

    
export const getListNotificationsQueryOptions = <TData = Awaited<ReturnType<typeof listNotifications>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListNotificationsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listNotifications>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListNotificationsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listNotifications>>> = ({ signal }) => listNotifications(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listNotifications>>, TError, TData> 
}

export type ListNotificationsQueryResult = NonNullable<Awaited<ReturnType<typeof listNotifications>>>
export type ListNotificationsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListNotifications = <TData = Awaited<ReturnType<typeof listNotifications>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListNotificationsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listNotifications>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListNotificationsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createNotification = (
    notification: MaybeRef<Notification>,
 options?: SecondParameter<typeof customAxios>,) => {
      notification = unref(notification);
      
      return customAxios<Notification>(
      {url: `/api/notifications`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: notification
    },
      options);
    }
  


export const getCreateNotificationMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNotification>>, TError,{data: Notification}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createNotification>>, TError,{data: Notification}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNotification>>, {data: Notification}> = (props) => {
          const {data} = props ?? {};

          return  createNotification(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof createNotification>>>
    export type CreateNotificationMutationBody = Notification
    export type CreateNotificationMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateNotification = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNotification>>, TError,{data: Notification}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateNotificationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForNotification = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/notifications/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForNotificationQueryKey = (id: MaybeRef<string>,) => {
    return ['api','notifications',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForNotificationQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForNotification>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForNotification>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForNotificationQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForNotification>>> = ({ signal }) => getChangeSequenceForNotification(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForNotification>>, TError, TData> 
}

export type GetChangeSequenceForNotificationQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForNotification>>>
export type GetChangeSequenceForNotificationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForNotification = <TData = Awaited<ReturnType<typeof getChangeSequenceForNotification>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForNotification>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForNotificationQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



