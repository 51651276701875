<script setup lang="ts">
import { computed, defineEmits, defineProps, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { DropDownTree } from "@progress/kendo-vue-dropdowns";
import { filterBy } from "@progress/kendo-vue-data-tools";
import { extendDataItem, mapTree } from "@progress/kendo-vue-common";
import type { Taxon, Taxonomy } from "~/model";
import { createKodexaFormulaService } from "~/store/useKodexaFormula";
import { supportedFunctions } from "~/formula/formula";

interface Props {
  modelValue: string;
  taxonomy: Taxonomy;
  taxon: Taxon;
  numRows: number | undefined;
  loading: { value: boolean };
}

const props = defineProps<Props>();
const emit = defineEmits(["update:model-value"]);
const isFocused = ref(false);
const modelValue = ref("");
const textareaRef = ref<HTMLTextAreaElement>();

const formulaStore = createKodexaFormulaService(ref(props.taxonomy));
const { formulaService } = storeToRefs(formulaStore);
const errors = computed(() => {
  try {
    formulaService.value.parseFormula(modelValue.value);
    return [];
  } catch (e: any) {
    return e.errors;
  }
});

watch(() => props.modelValue, (value) => {
  modelValue.value = value;
}, { immediate: true });

watch(modelValue, (value) => {
  emit("update:model-value", value);
}, { immediate: true });

// Calculate the Levenshtein distance between two strings
function levenshteinDistance(s1: string, s2: string) {
  if (s1.length === 0) {
    return s2.length;
  }
  if (s2.length === 0) {
    return s1.length;
  }

  const matrix = [];

  // Increment along the first column of each row
  for (let i = 0; i <= s2.length; i++) {
    matrix[i] = [i];
  }

  // Increment each column in the first row
  for (let j = 0; j <= s1.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (let i = 1; i <= s2.length; i++) {
    for (let j = 1; j <= s1.length; j++) {
      if (s2.charAt(i - 1) == s1.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          Math.min(
            matrix[i][j - 1] + 1, // insertion
            matrix[i - 1][j] + 1, // deletion
          ),
        );
      }
    }
  }

  return matrix[s2.length][s1.length];
}

// Find the closest matching function name
function suggestFunctionName(input: string) {
  let closestMatch = "";
  let lowestDistance = Number.POSITIVE_INFINITY;
  Object.keys(supportedFunctions).forEach((funcName) => {
    if (funcName.toLowerCase().includes(input.toLowerCase())) {
      closestMatch = funcName;
      lowestDistance = 0;
    }

    const distance = levenshteinDistance(input.toLowerCase(), funcName.toLowerCase());
    if (distance < lowestDistance) {
      lowestDistance = distance;
      closestMatch = funcName;
    }
  });

  return closestMatch;
}

const filter = ref("");

function onFilterChange(event) {
  filter.value = event.filter;
}

const selectedValue = ref(null);

function onSelectionChange(event) {
  modelValue.value += `{${event.value.externalName}}`;
}

function processTreeData(data, state, fields) {
  const { selectField, expandField, dataItemKey, subItemsField } = fields;
  const { expanded, value, filter } = state;
  const filtering = Boolean(filter && filter.value);

  return mapTree(
    filtering ? filterBy(data, [filter], subItemsField) : data,
    subItemsField,
    (item) => {
      const props = {
        [expandField]: expanded.includes(item[dataItemKey]),
        [selectField]: value && item[dataItemKey] === value[dataItemKey],
      };
      return filtering
        ? extendDataItem(item, subItemsField, props)
        : { ...item, ...props };
    },
  );
}

const fields = {
  dataItemKey: "name",
  subItemsField: "children",
  selectField: "selected",
  expandField: "expanded",
};

const expanded = ref([]);

const relativeAttributeTree = computed(() => {
  return processTreeData(formulaService.value.getRelativeAttributesAsTree(props.taxon), {
    expanded: expanded.value,
    value: selectedValue.value,
    filter: filter.value,
  }, fields);
});

function expandedState(item, dataItemKey, expanded) {
  const nextExpanded = expanded.slice();
  const itemKey = item[dataItemKey];
  const index = expanded.indexOf(itemKey);
  index === -1 ? nextExpanded.push(itemKey) : nextExpanded.splice(index, 1);

  return nextExpanded;
}

function onExpandChange(event) {
  expanded.value = (expandedState(event.item, "name", expanded.value));
}
</script>

<template>
  <div>
    <div
      :class="{
        'fm-colored-input__wrapper--focused': isFocused,
      }"
      class="fm-colored-input__wrapper"
    >
      <KodexaTextArea
        ref="textareaRef"
        v-model="modelValue"
        style="width: 100%"
        :loading="loading"
        name="name"
        :rows="numRows || 18"
        class="fm-colored-input__textarea"
        autocomplete="off"
        autocapitalize="off"
        spellcheck="false"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </div>
    <div
      v-for="(validationError, idx) in errors" :key="idx"
      class="fm-colored-input__validation mt-1 rounded-lg bg-red-100 p-2 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
      role="alert"
    >
      <MaterialDesignIcon name="alert" class="mr-1 text-red-600" size="12" />
      {{ validationError.message }} from on "{{ validationError.offendingSymbol?.text }}" at position
      {{ validationError.offendingSymbol?.start }}-{{ validationError.offendingSymbol?.stop }}
      <div v-if="validationError.errorType === 'InvalidFunction' && validationError.token" class="ml-2 mt-1">
        Did you mean the {{ suggestFunctionName(validationError.token.value) }} ?
      </div>
    </div>
    <div class="mt-2">
      <DropDownTree
        :value="selectedValue"
        data-item-key="id"
        placeholder="Available elements for formula"
        name="relativeAttributeTree"
        text-field="label"
        :filterable="true"
        sub-items-field="children"
        :data-items="relativeAttributeTree"
        expand-field="expanded"
        @filterchange="onFilterChange"
        @change="onSelectionChange"
        @expandchange="onExpandChange"
      />
    </div>
  </div>
</template>

<style scoped>
</style>
