import type { Store } from "pinia";
import { defineStore, storeToRefs } from "pinia";
import type { ComputedRef } from "vue";
import { MarkerType } from "@vue-flow/core";
import appStore from "~/store";
import type { Assistant, AssistantConnection, DataFlowEdge, DataFlowNode, ProjectDataFlow, Taxonomy } from "~/model";
import { log } from "~/utils/logger";

export function createDataFlowStore() {
  return defineStore("data-flow", () => {
    const {
      dataStores,
      documentStores,
      assistants,
      taxonomies,
      assistantConnections,
      isLoaded,
    } = storeToRefs(appStore.projectStore);

    const hideInactive = ref(false);

    function getAssistantEdges(assistant: Assistant, assistantNode: DataFlowNode, nodes: DataFlowNode[], optionType: string, componentType: string) {
      const edges: any[] = [];
      assistant.definition?.options?.forEach((option) => {
        if (option.type === optionType) {
          if (assistant.options && option.name in assistant.options) {
            if (assistant.options) {
              const targetNode = nodes?.find(node => node.id === `${componentType}://${assistant.options[option.name]}`);
              if (targetNode) {
                edges.push({
                  id: `${assistantNode.id}-${targetNode.id}`,
                  source: assistantNode.id,
                  target: targetNode.id,
                  type: "smoothstep",
                  label: "references",
                  markerEnd: MarkerType.Arrow,
                });
              }
            }
          }
        }

        if (option.listType === optionType) {
          if (assistant.options && option.name in assistant.options) {
            assistant.options[option.name]?.forEach((ref) => {
              const targetNode = nodes?.find(node => node.id === `${componentType}://${ref}`);
              if (targetNode) {
                edges.push({
                  id: `${assistantNode.id}-${targetNode.id}`,
                  source: assistantNode.id,
                  target: targetNode.id,
                  type: "smoothstep",
                  label: "references",
                  markerEnd: MarkerType.Arrow,
                });
              }
            });
          }
        }
      });
      return edges;
    }

    const currentDataFlow: ComputedRef<ProjectDataFlow> = computed(() => {
      if (!isLoaded.value) {
        log.info("Project is not loaded, not calculating current flow");
        return {
          nodes: [],
          edges: [],
        };
      }

      log.info("Calculating current flow");

      const nodes: DataFlowNode[] = [];
      const edges: DataFlowEdge[] = [];

      dataStores.value.forEach((store: Store) => {
        if (store.ref) {
          if (nodes.find(node => node.id === store.ref)) {
            // We have already added this store
            return;
          }
          nodes.push({
            id: `dataStore://${store.ref}`,
            type: "custom",
            position: {
              x: 0,
              y: 0,
            },
          } as DataFlowNode);
        }
      });

      documentStores.value.forEach((store: Store) => {
        if (store.ref) {
          if (nodes?.find(node => node.id === store.ref)) {
            // We have already added this store
            return;
          }
          nodes.push({
            id: `documentStore://${store.ref}`,
            component: "documentStore",
            type: "custom",
            position: {
              x: 0,
              y: 0,
            },
          } as DataFlowNode);
        }
      });

      assistants.value.forEach((assistant: Assistant) => {
        if (assistant.id && (hideInactive.value ? assistant.active : true)) {
          if (nodes?.find(node => node.id === assistant.id)) {
            // We have already added this assistant
            return;
          }
          nodes.push({
            id: `assistant://${assistant.id}`,
            type: "custom",
            position: {
              x: 0,
              y: 0,
            },
          } as DataFlowNode);
        }
      });

      taxonomies.value.forEach((taxonomy: Taxonomy) => {
        if (taxonomy.ref) {
          if (nodes?.find(node => node.id === taxonomy.ref)) {
            // We have already added this taxonomy
            return;
          }
          nodes.push({
            id: `taxonomy://${taxonomy.ref}`,
            type: "custom",
            position: {
              x: 0,
              y: 0,
            },
          } as DataFlowNode);
        }
      });

      // Assistants are the key here - we will need to look at the way the assistants are configured
      // to determine their relationships to the other components
      assistants.value.forEach((assistant: Assistant) => {
        if (assistant.id) {
          const assistantNode = nodes?.find(node => node.id === `assistant://${assistant.id}`);
          if (assistantNode) {
            // First we need to look at the stores that we are referencing from the assistant

            // We need to start by looking at the assistant definition, from there
            // we can work out if we have any options that relate us to another node
            edges.push(...getAssistantEdges(assistant, assistantNode, nodes, "tableStore", "dataStore"));
            edges.push(...getAssistantEdges(assistant, assistantNode, nodes, "documentStore", "documentStore"));
            edges.push(...getAssistantEdges(assistant, assistantNode, nodes, "modelStore", "modelStore"));
            edges.push(...getAssistantEdges(assistant, assistantNode, nodes, "taxonomy", "taxonomy"));
          }
        } else {
          log.info("Missing assistant "+assistant.id);
        }
      });

      if (assistantConnections.value) {
        assistantConnections.value.forEach((connection: AssistantConnection) => {
          let sourceNode: undefined | DataFlowNode;
          let targetNode: undefined | DataFlowNode;
          if (connection.sourceType === "STORE") {
            sourceNode = nodes?.find(node => node.id === `documentStore://${connection.sourceRef}`) ?? nodes?.find(node => node.id === `dataStore://${connection.sourceRef}`);
          }
          if (connection.sourceAssistant) {
            sourceNode = nodes?.find(node => node.id === `assistant://${connection.sourceAssistant?.id}`);
          }
          if (connection.targetType === "STORE") {
            targetNode = nodes?.find(node => node.id === `documentStore://${connection.sourceRef}`) ?? nodes?.find(node => node.id === `dataStore://${connection.sourceRef}`);
          }
          if (connection.targetAssistant) {
            targetNode = nodes?.find(node => node.id === `assistant://${connection.targetAssistant?.id}`);
          }
          if (sourceNode && targetNode) {
            edges.push({
              id: `${sourceNode.id}-${targetNode.id}`,
              source: sourceNode.id,
              target: targetNode.id,
              sourceHandle: "publishes",
              targetHandle: "consumes",
              type: "smoothstep",
              label: "consumes",
              markerEnd: MarkerType.Arrow,
            });
          }
        });
      }

      const currentDataFlow: ProjectDataFlow = {
        nodes,
        edges,
      };

      return currentDataFlow;
    });

    const flowNodes = computed(() => {
      return currentDataFlow.value.nodes;
    });

    const flowEdges = computed(() => {
      return currentDataFlow.value.edges;
    });

    function onConnect(params) {
      if (params.targetHandle === "consumes" && params.sourceHandle === "publishes") {
        // We need to look up the params.source and params.target
        // and then update the assistantConnections
        const source = params.source.split("//")[1];
        const sourceType = params.source.split("://")[0];
        const target = params.target.split("//")[1];
        const targetType = params.target.split("://")[0];
        appStore.projectStore.addAssistantConnection(source, sourceType, target, targetType);
      }
    }

    function updateEdge(params) {
      console.log("updateEdge", params);
    }

    function setToggleInactive(value) {
      hideInactive.value = value;
    }

    return {
      currentDataFlow,
      flowNodes,
      flowEdges,
      onConnect,
      updateEdge,
      setToggleInactive,
    };
  })();
}
