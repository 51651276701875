<script setup lang="ts">
import type { PropType } from "vue";
import type { ExtensionPack } from "~/model";
import appStore from "~/store";
import KodexaExtensionPackDeployPopup from "~/components/dashboard/widgets/app/extensions/kodexa-extension-pack-deploy-popup.vue";

const props = defineProps({
  extensionPack: {
    type: Object as PropType<ExtensionPack>,
    required: true,
  },
});

const emit = defineEmits(["change", "delete"]);

interface NavItem {
  text?: string;
  items?: NavItem[];
  run?: () => void;
  mdiIcon?: string;
  render?: string;
  divider?: boolean;
  color?: string;
}

const navigation = computed(() => {
  return [
    {
      text: "Deploy",
      render: "itemRender",
      icon: "rocket-launch",
    },
    { text: "Undeploy", render: "itemRender", icon: "rocket" },
    { text: "Cancel Running", render: "itemRender", icon: "cancel" },
    { divider: true },
    {
      text: "Delete",
      render: "itemRender",
      icon: "delete",
      color: "text-red-400",
      run: async () => {
        emit("delete");
      },
    },
  ] as NavItem[];
});

const showDeployPopup = ref(false);
const menuOpen = ref(false);

async function openNav(item: NavItem) {
  switch (item.text) {
    case "Deploy":
      showDeployPopup.value = true;

      break;
    case "Undeploy":
      appStore.platformStore.undeployExtensionPack(props.extensionPack);
      emit("change");
      menuOpen.value = false;
      break;
    case "Delete":
      appStore.platformStore.deleteExtensionPack(props.extensionPack);
      emit("change");
      menuOpen.value = false;
      break;
    case "Cancel Running":
      appStore.platformStore.cancelRunningExtensionPack(props.extensionPack);
      emit("change");
      menuOpen.value = false;
      break;
  }
}
</script>

<template>
  <div class="w-5">
    <VDropdown :arrow-overflow="false" :shown="menuOpen">
      <MaterialDesignIcon name="THREEDOTMENU" class="size-5 text-gray-500" aria-hidden="true" />
      <!-- This will be the content of the popover -->
      <template #popper>
        <nav class="space-y-1 p-2" aria-label="Sidebar">
          <template v-for="item in navigation" :key="item.name">
            <a
              v-if="!item.divider"
              v-close-popper
              class="flex items-center rounded-md px-3 py-2 text-xs font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900"
              :aria-current="item.current ? 'page' : undefined" @click="openNav(item)"
            >
              <MaterialDesignIcon
                :name="item.icon"
                class="-ml-1 mr-3 mt-1 shrink-0 text-gray-400" size="18" aria-hidden="true"
                :class="[item.color ? item.color : 'text-gray-400']"
              />
              <span class="truncate">{{ item.text }}</span>
            </a>
            <div v-else class="my-2 border-t border-gray-200" />
          </template>
        </nav>
      </template>
    </VDropdown>
    <KodexaExtensionPackDeployPopup v-model="showDeployPopup" :extension-pack="extensionPack" @confirm="emit('change')" />
  </div>
</template>
