<script setup lang="ts">
import { Combobox, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import Fuse from "fuse.js";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { MultiSelect } from "@progress/kendo-vue-dropdowns";
import { createDocumentViewerStore } from "~/store/useDocumentView";
import type { TagInstance } from "~/store/useWorkspace";
import appStore from "~/store";
import type { TagMetadata } from "~/store/useProject";

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});

const useDocumentViewStore = createDocumentViewerStore(props.viewId);
const { taggedInstances, page, selectionContext, documentFamily } = storeToRefs(useDocumentViewStore);

const rawQuery = ref("");

function onSelect(tagInstance: TagInstance) {
  useDocumentViewStore.gotoTagInstance(tagInstance);
}

const fuseOptions = {
  useExtendedSearch: true,
  keys: ["taxon.name"],
  findAllMatches: true,
};

const tagInstances = computed(() => taggedInstances.value);

const inverted = ref(false);

const { tagMetadataMap } = storeToRefs(appStore.projectStore);
const startingGroups = Array.from(tagMetadataMap.value.values()).filter(
  (tagMetadata: any) => tagMetadata.taxon.group && tagMetadata.parentPath === undefined,
);
const selectedGroups = ref<TagMetadata[]>(startingGroups as TagMetadata[]);

const availableDataGroups = computed(() => {
  return Array.from(tagMetadataMap.value.values()).filter(
    (tagMetadata: any) => (tagMetadata.taxon.group && tagMetadata.parentPath === undefined),
  );
});

const availableLabels = computed(() => {
  // Get all the labels, and exclude the groups
  const nonGroups = Array.from(tagMetadataMap.value.values()).filter(
    (tagMetadata: any) => !tagMetadata.taxon.group,
  );
  const selectedGroupPaths = selectedGroups.value.map(
    (group: TagMetadata) => group.taxon.path,
  );
  return nonGroups.filter((tagMetadata: any) =>
    selectedGroupPaths.includes(tagMetadata.parentPath));
});

function onChange(event) {
  selectedGroups.value = event.target.value;
}

const filteredTagInstances = computed(() => {
  const fuse = new Fuse(tagInstances.value, fuseOptions);
  const hits: Fuse.FuseResult<any>[] = fuse.search(rawQuery.value);
  const unorderedTags = rawQuery.value === "" ? [...tagInstances.value] : hits.map(hit => hit.item as TagInstance);

  // We need to make sure that we only show tags where the path starts with a
  // path from one of the selected groups
  const selectedGroupPaths = selectedGroups.value.map(
    (group: TagMetadata) => group.taxon.path,
  );

  const tagsToRemove = [];

  unorderedTags.forEach((tagInstance) => {
    const tagMetadata = tagMetadataMap.value.get(tagInstance.taxon.path);
    const keep = ref(false);
    selectedGroupPaths.forEach((selectedGroupPath) => {
      if (tagMetadata.taxon.path.startsWith(selectedGroupPath)) {
        keep.value = true;
      }
    });
    if (!keep.value) {
      tagsToRemove.push(tagInstance);
    }
  });

  tagsToRemove.forEach((tagInstance) => {
    const index = unorderedTags.indexOf(tagInstance);
    if (index > -1) {
      unorderedTags.splice(index, 1);
    }
  });

  // We want to order them by page number
  unorderedTags.sort((a, b) => {
    const aPage = a.pageNumber;
    const bPage = b.pageNumber;
    if (aPage < bPage) {
      return -1;
    }
    if (aPage > bPage) {
      return 1;
    }
    return 0;
  });

  if (limitToPage.value) {
    return unorderedTags.filter(tagInstance => tagInstance.pageNumber === page.value);
  }

  return unorderedTags;
});

const availableTagsNotUsed = computed(() => {
  const tagsNotUsed = [] as TagMetadata[];
  const selectedGroupPaths = selectedGroups.value.map(
    (group: TagMetadata) => group.taxon.path,
  );
  const allTags = Array.from(tagMetadataMap.value.values());
  allTags.forEach((tagMetadata: TagMetadata) => {
    if (tagMetadata.taxon.group) {
      return;
    }
    const keep = ref(false);
    selectedGroupPaths.forEach((selectedGroupPath) => {
      if (tagMetadata.taxon.path.startsWith(selectedGroupPath)) {
        keep.value = true;
      }
    });

    if (!keep.value) {
      return;
    }

    keep.value = true;

    // go through all the tags we have and see if we should keep it
    filteredTagInstances.value.forEach((tagInstance) => {
      if (tagInstance.taxon.path === tagMetadata.taxon.path) {
        keep.value = false;
      }
    });

    if (keep.value) {
      tagsNotUsed.push(tagMetadata);
    }
  });

  return tagsNotUsed;
});

const limitToPage = ref(false);

function addTag(tag: TagMetadata) {
  useDocumentViewStore?.addTag(tag, selectionContext.value.selectedNodes, {});
}
</script>

<template>
  <div>
    <Combobox>
      <div class="bg-gray-50">
        <KodexaTextInput
          v-model="rawQuery"
          name="filter"
          placeholder="Filter..."
        />
      </div>

      <p>
        <MultiSelect
          name="selectedGroups"
          :data-items="availableDataGroups"
          :style="{ width: '100%' }"
          :value="selectedGroups"
          class="mt-1"
          data-item-key="path"
          text-field="label"
          @change="onChange"
        />
        <KodexaCheckbox v-model="inverted" name="inverted" class="ml-2" label="Show labels with no value" />
        <KodexaCheckbox v-model="limitToPage" name="limitToPage" class="ml-2" label="Limit to page" />
      </p>
      <p>
        <KodexaArticle class="ml-2 mt-2" article-id="9136710" text="Learn more about label based navigation" :slide="false" />
      </p>
      <p v-if="filteredTagInstances && !inverted" class="ml-1 mt-1">
        {{ filteredTagInstances.length }} found.
      </p>
      <p v-if="availableTagsNotUsed && inverted" class="ml-1 mt-1">
        {{ availableTagsNotUsed.length }} available.
      </p>

      <div style="height: calc(100vh - 17rem); overflow: auto">
        <ComboboxOptions
          v-if="filteredTagInstances && filteredTagInstances.length > 0 && !inverted" static
          class="mx-1 scroll-py-10 scroll-pb-2 space-y-4 p-1 pb-2"
          style="height: calc(100vh - 12rem);"
        >
          <li v-if="filteredTagInstances.length > 0">
            <ul class="-mx-4 text-sm text-gray-700">
              <ComboboxOption
                v-for="filteredTagInstance in filteredTagInstances" :key="filteredTagInstance.uuid" v-slot="{ active }"
                as="template"
              >
                <li
                  class="flex cursor-default select-none rounded-xl p-3" :class="[active && 'bg-gray-100']"
                  @click="onSelect(filteredTagInstance)"
                >
                  <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg"
                    :style="{ 'background-color': filteredTagInstance.taxon.color }"
                  >
                    <MaterialDesignIcon :name="filteredTagInstance.isPage ? 'book-open-page-variant-outline' : 'tag'" class="h-6 pr-1 pt-1 w-6 text-white" aria-hidden="true" />
                  </div>
                  <div class="ml-4 flex-auto">
                    <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                      {{ filteredTagInstance.taxon.label }}
                    </p>
                    <p v-if="filteredTagInstance.parentLabel" class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                      {{ filteredTagInstance.parentLabel }}
                    </p>
                    <p class="text-sm font-light mb-1" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                      {{ filteredTagInstance.value }}
                    </p>
                    <p class="text-xs" :class="[active ? 'text-gray-700' : 'text-gray-500']">
                      Page {{ filteredTagInstance.pageNumber + 1 }}
                    </p>
                  </div>
                </li>
              </ComboboxOption>
            </ul>
          </li>
        </ComboboxOptions>
        <ComboboxOptions
          v-if="inverted" static
          class="mx-1 scroll-py-10 scroll-pb-2 space-y-4 p-1 pb-2"
          style="height: calc(100vh - 12rem);"
        >
          <li>
            <ul class="-mx-4 text-sm text-gray-700">
              <ComboboxOption
                v-for="availableTag in availableTagsNotUsed" :key="availableTag.taxon.id" v-slot="{ active }"
                as="template"
              >
                <li
                  class="flex cursor-default select-none rounded-xl p-3" :class="[active && 'bg-gray-100']"
                >
                  <div
                    class="flex h-10 w-10 flex-none items-center justify-center rounded-lg"
                    :style="{ 'background-color': availableTag.taxon.color }"
                  >
                    <MaterialDesignIcon name="plus" class="h-6 pr-1 pt-1 w-6 text-white" aria-hidden="true" @click="addTag(availableTag)" />
                  </div>
                  <div class="ml-4 flex-auto">
                    <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                      {{ availableTag.taxon.label }}
                    </p>
                    <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                      {{ availableTag.parentLabel }}
                    </p>
                  </div>
                </li>
              </ComboboxOption>
            </ul>
          </li>
        </ComboboxOptions>
      </div>

      <div v-if="rawQuery === '?'" class="px-6 py-14 text-center text-sm sm:px-14">
        <MaterialDesignIcon name="help" lass="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
        <p class="mt-4 font-semibold text-gray-900">
          Help with Labels
        </p>
        <p class="mt-2 text-gray-500">
          Use this tool to quickly search for users and projects across our entire platform. You can also use
          the search modifiers found in the footer below to limit the results to just users or projects.
        </p>
      </div>

      <div
        v-if="rawQuery !== '' && rawQuery !== '?' && filteredTagInstances.length === 0"
        class="px-6 py-14 text-center text-sm sm:px-14"
      >
        <MaterialDesignIcon name="alertBox" class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
        <p class="mt-4 font-semibold text-gray-900">
          No labels found
        </p>
        <p class="mt-2 text-gray-500">
          We couldn’t find anything with that term. Please try again.
        </p>
      </div>
    </Combobox>
  </div>
</template>

<style scoped>

</style>
