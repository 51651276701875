<script setup lang="ts">
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import type { Membership, Project } from "~/model";
import { useListMemberships } from "~/api/memberships/memberships";

const props = defineProps({
  modelValue: {
    type: Object as PropType<Membership | undefined>,
    required: false,
  },
  project: {
    type: Object as PropType<Project>,
    required: true,
  },
});

const emit = defineEmits(["updated", "clear"]);

const gridHelper = createGridHelper(`membership-${props.project?.organization?.id}`, {
  page: 1,
  pageSize: 50,
  filter: "",
  query: "",
  sort: "",
}, `organization.id:'${props.project?.organization?.id}'`);

const {
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  data,
} = useListMemberships(gridQuery.value);

const localMembership = ref<Membership | undefined>(props.modelValue);

function selectItem(dataItem: any, props) {
  localMembership.value = dataItem;
  emit("updated", localMembership.value);
}
</script>

<template>
  <div class="flex items-center space-x-4 rounded">
    <DropDownList
      id="projectOwnerDropdown"
      v-model="localMembership"
      :data-items="data?.content || []"
      data-item-key="user.id"
      value-field="user.id"
      text-field="user.id"
      :filterable="false"
      :value-primitive="false"
      fill-mode="flat"
      class="grow"
      :loading="isLoading"
      item-render="itemRender"
      value-render="valueRender"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <template #itemRender="{ props }">
        <div
          v-if="props.dataItem" id="selectProjectOwner" class="kodexa-input mx-2 mt-1 cursor-pointer"
          @click="selectItem(props.dataItem, props)"
        >
          <div style="width: 100%" class="py-1">
            <KodexaUserAvatar :user="props.dataItem.user" :show-name="true" />
          </div>
        </div>
      </template>
      <template #valueRender="{ props }">
        <div v-if="props.value" id="currentOwnerDropdown" style="width: 100%">
          <KodexaUserAvatar :user="props.value" :show-name="true" />
        </div>

        <div v-else id="ownerDropdown" class="py-1 w-full">
          <span class="text-gray-400">Select owner...</span>
        </div>
      </template>
    </DropDownList>
    <MaterialDesignIcon id="closeProjectOwner" name="close" @click="emit('clear')" />
  </div>
</template>
