<script setup lang="ts">
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import type { DocumentFamily, User } from "~/model";
import appStore from "~/store";
import { useListMemberships } from "~/api/memberships/memberships";
import { updateAssigneesWithVersion } from "~/api/stores/stores";
import { RefHelper } from "~/utils/ref-utils";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: true,
  },
});
const emit = defineEmits(["change"]);
const { currentOrganization } = storeToRefs(appStore.organizationStore);

const gridHelper = createGridHelper(`membership-${currentOrganization.value.id}`, {
  page: 1,
  pageSize: 50,
  filter: "",
  query: "",
  sort: "",
}, `organization.id:'${currentOrganization.value.id}'`);

const {
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  data,
} = useListMemberships(gridQuery.value);

const assignee = computed({
  get: () => props.documentFamily?.assignment || null,
  set: (value: User) => {
    const storeRef = new RefHelper(props.documentFamily.storeRef);
    updateAssigneesWithVersion(storeRef.getOrgSlug(), storeRef.getSlug(), storeRef.getVersion(), props.documentFamily.id, value);
    emit("change");
  },
});

const users = computed(() => {
  return data?.value?.content
    ? data.value.content.filter(u => u.user?.id !== assignee.value?.id)
    : [];
});
</script>

<template>
  <KodexaDropDown
    v-model="assignee"
    name="assigneeDropDown"
    :loading="isLoading"
    :items="users"
    value-field="id"
    text-field="user.firstName"
    item-render="assigneeRender"
    placeholder="Assignee"
    :auto-width="true"
  >
    <template #assigneeRender="{ props }">
      <div
        v-if="props.dataItem" class="kodexa-input mx-2 mt-1 cursor-pointer"
      >
        <div style="width: 100%" class="py-1">
          <KodexaUserAvatar :user="props.dataItem" :show-name="true" />
        </div>
      </div>
    </template>
  </KodexaDropDown>
</template>

<style scoped>

</style>
