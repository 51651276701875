import type { Ref } from "vue";
import { ref, watch } from "vue";

export function useGridScroll(tableElement: Ref<Element | null>) {
  const SCROLL_SPEED = 10; // Scroll speed
  const BUFFER = 20; // Buffer value

  const frameID: Ref<number | null> = ref(null);
  const elementRef: Ref<Element | null> = ref(null);

  // Function to clear the previous frame
  function clearPrevFrame() {
    if (frameID.value !== null) {
      cancelAnimationFrame(frameID.value);
      frameID.value = null;
    }
  }

  // Function to handle the scrolling
  function scroll({ direction }) {
    const element = elementRef.value;
    if (element) {
      frameID.value = requestAnimationFrame(() => scroll({ direction }));
      element.scrollBy((direction === "left" ? -1 : 1) * SCROLL_SPEED, 0);
    }
  }

  // Event handler function for mouse movement
  function handler(e) {
    const element = elementRef.value;
    if (element) {
      clearPrevFrame();
      const rect = element.getBoundingClientRect();
      if (e.clientX < rect.left + BUFFER) {
        scroll({ direction: "left" });
      } else if (e.clientX > rect.right - BUFFER) {
        scroll({ direction: "right" });
      }
    }
  }

  // Watcher to manage element reference and event listeners
  watch(tableElement, () => {
    if (tableElement.value) {
      elementRef.value = tableElement.value.querySelector(".k-grid-content");
      tableElement.value.addEventListener("mousemove", handler);
      tableElement.value.addEventListener("mouseout", clearPrevFrame);
    }
  }, { immediate: true, deep: true });

  // Cleanup on component unmount
  function unmount() {
    if (tableElement.value) {
      clearPrevFrame();
      tableElement.value.removeEventListener("mousemove", handler);
      tableElement.value.removeEventListener("mouseout", clearPrevFrame);
    }
  }

  return {
    tableElement,
    unmount,
  };
}
