<script lang="ts" setup>
import type { Store } from "pinia";
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { Handle, Position } from "@vue-flow/core";
import appStore from "~/store";
import type { DataFlowNode } from "~/model";

const props = defineProps({
  node: {
    type: Object as PropType<DataFlowNode>,
  },
});

const emit = defineEmits(["selected", "selectedStep"]);

const { dataStores } = storeToRefs(appStore.projectStore);

const dataStore = computed(() => {
  return dataStores.value.find((dataStore: Store) => props.node && dataStore.ref === props.node.id?.split("//")[1]);
});
</script>

<template>
  <div>
    <div @click="emit('selected')">
      <img
        style="float:left" class="ml-2 mr-3 h-16 rounded-md"
        src="/assets/icon-datastore.png" alt="Data Store"
      >
      <div class="pt-4 text-gray-700" style="width: 250px">
        {{ dataStore?.name || 'Data Store' }}
      </div>
      <div class="pl-2 text-xs text-gray-500" style="width: 250px">
        Data Store
      </div>
    </div>
  </div>
  <Handle id="resourceConnection" type="source" :position="Position.Left" />
  <Handle id="publishes" type="source" :position="Position.Bottom" />
  <Handle id="consumes" type="target" :position="Position.Top" />
</template>

<style scoped>

</style>
