<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import type { Assistant, ContentObject, Option } from "~/model";
import { useConfigurationBase } from "~/components/options/configuration-option-utils";
import appStore from "~/store";

const props = defineProps(
  {
    item: {
      type: Object as PropType<Option>,
      required: true,
    },
    modelValue: {
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    errors: Object,
    contentObject: Object as PropType<ContentObject | undefined>,
    assistant: Object as PropType<Assistant | undefined>,
  },
);

const emit = defineEmits(["update:modelValue"]);

const { workspaces } = storeToRefs(appStore.projectStore);

const {
  isShown,
  collapsed,
  type,
  localValue,
} = useConfigurationBase(props, emit);
</script>

<template>
  <div v-if="isShown && workspaces">
    <KodexaDropDown
      name="workspaceOption"
      :label="item.label"
      v-model="localValue"
      fill-mode="outline"
      text-field="name"
      value-field="id"
      :style="{ width: '100%' }"
      :items="workspaces"
    />
  </div>
</template>

<style>

</style>
