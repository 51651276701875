<script lang="ts" setup>
import { storeToRefs } from "pinia";
import appStore from "~/store";

const { project } = storeToRefs(appStore.projectStore);

const projectNotes = computed({
  get() {
    return project.value.notes;
  },
  set(value) {
    appStore.projectStore.updateProjectNotes(value);
  },
});
</script>

<template>
  <div>
    <KodexaTextArea
      v-model="projectNotes"
      name="projectNotes"
      :placeholder="`Add notes for ${project.name}`"
      :rows="10"
    />
  </div>
</template>
