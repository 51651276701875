/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListNotesParams
} from '../../model/listNotesParams'
import type {
  Note
} from '../../model/note'
import type {
  PageNote
} from '../../model/pageNote'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getNote = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Note>(
      {url: `/api/notes/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetNoteQueryKey = (id: MaybeRef<string>,) => {
    return ['api','notes',id] as const;
    }

    
export const getGetNoteQueryOptions = <TData = Awaited<ReturnType<typeof getNote>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNote>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetNoteQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNote>>> = ({ signal }) => getNote(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getNote>>, TError, TData> 
}

export type GetNoteQueryResult = NonNullable<Awaited<ReturnType<typeof getNote>>>
export type GetNoteQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetNote = <TData = Awaited<ReturnType<typeof getNote>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNote>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetNoteQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateNote = (
    id: MaybeRef<string>,
    note: MaybeRef<Note>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
note = unref(note);
      
      return customAxios<Note>(
      {url: `/api/notes/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: note
    },
      options);
    }
  


export const getUpdateNoteMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateNote>>, TError,{id: string;data: Note}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateNote>>, TError,{id: string;data: Note}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateNote>>, {id: string;data: Note}> = (props) => {
          const {id,data} = props ?? {};

          return  updateNote(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateNoteMutationResult = NonNullable<Awaited<ReturnType<typeof updateNote>>>
    export type UpdateNoteMutationBody = Note
    export type UpdateNoteMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateNote = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateNote>>, TError,{id: string;data: Note}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateNoteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteNote = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/notes/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteNoteMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNote>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteNote>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNote>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteNote(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteNoteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNote>>>
    
    export type DeleteNoteMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteNote = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNote>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteNoteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listNotes = (
    params?: MaybeRef<ListNotesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageNote>(
      {url: `/api/notes`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListNotesQueryKey = (params?: MaybeRef<ListNotesParams>,) => {
    return ['api','notes', ...(params ? [params]: [])] as const;
    }

    
export const getListNotesQueryOptions = <TData = Awaited<ReturnType<typeof listNotes>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListNotesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listNotes>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListNotesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listNotes>>> = ({ signal }) => listNotes(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listNotes>>, TError, TData> 
}

export type ListNotesQueryResult = NonNullable<Awaited<ReturnType<typeof listNotes>>>
export type ListNotesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListNotes = <TData = Awaited<ReturnType<typeof listNotes>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListNotesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listNotes>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListNotesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createNote = (
    note: MaybeRef<Note>,
 options?: SecondParameter<typeof customAxios>,) => {
      note = unref(note);
      
      return customAxios<Note>(
      {url: `/api/notes`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: note
    },
      options);
    }
  


export const getCreateNoteMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNote>>, TError,{data: Note}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createNote>>, TError,{data: Note}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNote>>, {data: Note}> = (props) => {
          const {data} = props ?? {};

          return  createNote(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateNoteMutationResult = NonNullable<Awaited<ReturnType<typeof createNote>>>
    export type CreateNoteMutationBody = Note
    export type CreateNoteMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateNote = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNote>>, TError,{data: Note}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateNoteMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForNote = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/notes/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForNoteQueryKey = (id: MaybeRef<string>,) => {
    return ['api','notes',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForNoteQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForNote>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForNote>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForNoteQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForNote>>> = ({ signal }) => getChangeSequenceForNote(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForNote>>, TError, TData> 
}

export type GetChangeSequenceForNoteQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForNote>>>
export type GetChangeSequenceForNoteQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForNote = <TData = Awaited<ReturnType<typeof getChangeSequenceForNote>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForNote>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForNoteQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



