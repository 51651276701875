import type { DataAttribute, Taxon } from "~/model";
import Decimal from "decimal.js";

export function getAggregationFromTaxons(taxons: Taxon[]) {
  return taxons.filter(taxon => taxon.taxonType === "CURRENCY" || taxon.taxonType === "NUMBER").map((taxon) => {
    return {
      field: taxon.path,
      aggregate: "sum",
    };
  }) || [];
}

export function formatAggregationByTaxonType(aggregate: any, taxon: Taxon) {
  switch (taxon.taxonType) {
    case "CURRENCY":
      if (aggregate.sum === null || aggregate.sum === undefined || isNaN(aggregate.sum)) {
        return undefined;
      } else {
        return new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
          style: "currency",
          currency: "USD",
        }).format(aggregate.sum || 0);
      }
    case "NUMBER":
      return aggregate.sum || 0;
    default:
      return undefined;
  }
}

export function getAttributeValueByTaxonType(attribute: DataAttribute) {
  switch (attribute.typeAtCreation) {
    case "CURRENCY":
      return attribute.decimalValue !== undefined ? new Decimal(attribute.decimalValue) : new Decimal(0);
    case "NUMBER":
      return attribute.decimalValue !== undefined ? new Decimal(attribute.decimalValue) : new Decimal(0);
    case "DATE":
      return attribute.value ? attribute.dateValue : null;
    case "DATE_TIME":
      return attribute.value ? attribute.dateValue : null;
    default:
      return attribute.stringValue;
  }
}
