<script setup lang="ts">
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { useListProjects } from "~/api/projects/projects";

const { user } = storeToRefs(appStore.userStore);
const { currentOrganization } = storeToRefs(appStore.organizationStore);

// Setup our grid helpers
const gridHelper = createGridHelper("recent-projects", {
  page: 1,
  pageSize: 1,
  filter: "",
  query: "",
  sort: "",
}, `userRecents.user.id: '${user.value ? user.value.id : "xxx"}'`, [{ field: "updatedOn", dir: "desc" }]);
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

watch(user, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`userRecents.user.id: '${newValue.id}'`);
  }
});

watch(() => [currentOrganization, user], (newValue) => {
  if (user.value) {
    if (currentOrganization.value) {
      gridHelper.setBaseFilter(`userRecents.project.organization.id:'${currentOrganization.value.id}' and userRecents.user.id: '${user.value.id}'`)
      gridHelper.pageChangeHandler({page: 1});
    } else {
      gridHelper.setBaseFilter(`userRecents.user.id: '${user.value.id}'`)
      gridHelper.pageChangeHandler({page: 1});
    }
  }
}, {
  immediate: true,
});

// Query
const {
  isLoading,
  isError,
  data,
  error,
} = useListProjects(gridQuery.value);

const project = computed(() => {
  if (data.value && data.value.content) {
    return data.value.content[0];
  }
  return undefined;
});
</script>

<template>
  <div v-if="project" class="mt-1 mr-2">
    <p class="ml-4 mt-2 text-lg text-gray-600 font-bold">
      Most Recent Project
    </p>
    <KodexaProjectCard class="ml-3 mt-2" :project="project" :include-organization="true" :include-tags="false" />
  </div>
  <div v-else-if="!isLoading">
    <div class="mb-2 ml-4 text-sm text-gray-500 mr-4">
      No recent projects found
    </div>
  </div>
  <div v-else class="mt-1">
    <p class="text-sm text-gray-500 mb-2 ml-4">
      Loading...
    </p>
  </div>
</template>
