/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListProductsParams
} from '../../model/listProductsParams'
import type {
  PageProduct
} from '../../model/pageProduct'
import type {
  Product
} from '../../model/product'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getProduct = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Product>(
      {url: `/api/products/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetProductQueryKey = (id: MaybeRef<string>,) => {
    return ['api','products',id] as const;
    }

    
export const getGetProductQueryOptions = <TData = Awaited<ReturnType<typeof getProduct>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProduct>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetProductQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getProduct>>> = ({ signal }) => getProduct(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getProduct>>, TError, TData> 
}

export type GetProductQueryResult = NonNullable<Awaited<ReturnType<typeof getProduct>>>
export type GetProductQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetProduct = <TData = Awaited<ReturnType<typeof getProduct>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getProduct>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetProductQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateProduct = (
    id: MaybeRef<string>,
    product: MaybeRef<Product>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
product = unref(product);
      
      return customAxios<Product>(
      {url: `/api/products/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: product
    },
      options);
    }
  


export const getUpdateProductMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProduct>>, TError,{id: string;data: Product}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateProduct>>, TError,{id: string;data: Product}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateProduct>>, {id: string;data: Product}> = (props) => {
          const {id,data} = props ?? {};

          return  updateProduct(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateProductMutationResult = NonNullable<Awaited<ReturnType<typeof updateProduct>>>
    export type UpdateProductMutationBody = Product
    export type UpdateProductMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateProduct = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateProduct>>, TError,{id: string;data: Product}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateProductMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteProduct = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/products/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteProductMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProduct>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteProduct>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteProduct>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteProduct(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteProductMutationResult = NonNullable<Awaited<ReturnType<typeof deleteProduct>>>
    
    export type DeleteProductMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteProduct = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteProduct>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteProductMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listProducts = (
    params?: MaybeRef<ListProductsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageProduct>(
      {url: `/api/products`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListProductsQueryKey = (params?: MaybeRef<ListProductsParams>,) => {
    return ['api','products', ...(params ? [params]: [])] as const;
    }

    
export const getListProductsQueryOptions = <TData = Awaited<ReturnType<typeof listProducts>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListProductsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProducts>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListProductsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listProducts>>> = ({ signal }) => listProducts(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listProducts>>, TError, TData> 
}

export type ListProductsQueryResult = NonNullable<Awaited<ReturnType<typeof listProducts>>>
export type ListProductsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListProducts = <TData = Awaited<ReturnType<typeof listProducts>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListProductsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listProducts>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListProductsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createProduct = (
    product: MaybeRef<Product>,
 options?: SecondParameter<typeof customAxios>,) => {
      product = unref(product);
      
      return customAxios<Product>(
      {url: `/api/products`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: product
    },
      options);
    }
  


export const getCreateProductMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProduct>>, TError,{data: Product}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createProduct>>, TError,{data: Product}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createProduct>>, {data: Product}> = (props) => {
          const {data} = props ?? {};

          return  createProduct(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateProductMutationResult = NonNullable<Awaited<ReturnType<typeof createProduct>>>
    export type CreateProductMutationBody = Product
    export type CreateProductMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateProduct = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createProduct>>, TError,{data: Product}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateProductMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForProduct = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/products/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForProductQueryKey = (id: MaybeRef<string>,) => {
    return ['api','products',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForProductQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForProduct>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProduct>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForProductQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForProduct>>> = ({ signal }) => getChangeSequenceForProduct(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProduct>>, TError, TData> 
}

export type GetChangeSequenceForProductQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForProduct>>>
export type GetChangeSequenceForProductQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForProduct = <TData = Awaited<ReturnType<typeof getChangeSequenceForProduct>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForProduct>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForProductQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



