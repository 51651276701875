/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  Assistant
} from '../../model/assistant'
import type {
  AssistantDefinition
} from '../../model/assistantDefinition'
import type {
  AssistantMemory
} from '../../model/assistantMemory'
import type {
  CreateAssistantDefinitionParams
} from '../../model/createAssistantDefinitionParams'
import type {
  DeleteAssistantDefinitionParams
} from '../../model/deleteAssistantDefinitionParams'
import type {
  DeleteVersionAssistantDefinitionParams
} from '../../model/deleteVersionAssistantDefinitionParams'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListAssistantDefinitionsForOrganizationParams
} from '../../model/listAssistantDefinitionsForOrganizationParams'
import type {
  ListAssistantDefinitionsParams
} from '../../model/listAssistantDefinitionsParams'
import type {
  ListAssistantsParams
} from '../../model/listAssistantsParams'
import type {
  PageAssistant
} from '../../model/pageAssistant'
import type {
  PageAssistantDefinition
} from '../../model/pageAssistantDefinition'
import type {
  StreamingResponseBody
} from '../../model/streamingResponseBody'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getAssistant = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Assistant>(
      {url: `/api/assistants/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssistantQueryKey = (id: MaybeRef<string>,) => {
    return ['api','assistants',id] as const;
    }

    
export const getGetAssistantQueryOptions = <TData = Awaited<ReturnType<typeof getAssistant>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistant>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssistantQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssistant>>> = ({ signal }) => getAssistant(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssistant>>, TError, TData> 
}

export type GetAssistantQueryResult = NonNullable<Awaited<ReturnType<typeof getAssistant>>>
export type GetAssistantQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetAssistant = <TData = Awaited<ReturnType<typeof getAssistant>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistant>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssistantQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateAssistant = (
    id: MaybeRef<string>,
    assistant: MaybeRef<Assistant>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
assistant = unref(assistant);
      
      return customAxios<Assistant>(
      {url: `/api/assistants/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistant
    },
      options);
    }
  


export const getUpdateAssistantMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistant>>, TError,{id: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssistant>>, TError,{id: string;data: Assistant}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssistant>>, {id: string;data: Assistant}> = (props) => {
          const {id,data} = props ?? {};

          return  updateAssistant(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssistant>>>
    export type UpdateAssistantMutationBody = Assistant
    export type UpdateAssistantMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateAssistant = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistant>>, TError,{id: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteAssistant = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/assistants/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteAssistantMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssistant>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAssistant>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAssistant>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteAssistant(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAssistant>>>
    
    export type DeleteAssistantMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteAssistant = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssistant>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get an assistant memory key
 */
export const getAssistantMemory = (
    id: MaybeRef<string>,
    key: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
key = unref(key);
      
      return customAxios<AssistantMemory>(
      {url: `/api/assistants/${id}/memory/${key}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssistantMemoryQueryKey = (id: MaybeRef<string>,
    key: MaybeRef<string>,) => {
    return ['api','assistants',id,'memory',key] as const;
    }

    
export const getGetAssistantMemoryQueryOptions = <TData = Awaited<ReturnType<typeof getAssistantMemory>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>,
    key: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantMemory>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssistantMemoryQueryKey(id,key);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssistantMemory>>> = ({ signal }) => getAssistantMemory(id,key, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id) && unref(key))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssistantMemory>>, TError, TData> 
}

export type GetAssistantMemoryQueryResult = NonNullable<Awaited<ReturnType<typeof getAssistantMemory>>>
export type GetAssistantMemoryQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetAssistantMemory = <TData = Awaited<ReturnType<typeof getAssistantMemory>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>,
    key: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantMemory>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssistantMemoryQueryOptions(id,key,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create or update a key memory for the assistant
 */
export const updateAssistantMemory = (
    id: MaybeRef<string>,
    key: MaybeRef<string>,
    assistantMemory: MaybeRef<AssistantMemory>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
key = unref(key);
assistantMemory = unref(assistantMemory);
      
      return customAxios<AssistantMemory>(
      {url: `/api/assistants/${id}/memory/${key}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistantMemory
    },
      options);
    }
  


export const getUpdateAssistantMemoryMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantMemory>>, TError,{id: string;key: string;data: AssistantMemory}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssistantMemory>>, TError,{id: string;key: string;data: AssistantMemory}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssistantMemory>>, {id: string;key: string;data: AssistantMemory}> = (props) => {
          const {id,key,data} = props ?? {};

          return  updateAssistantMemory(id,key,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateAssistantMemoryMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssistantMemory>>>
    export type UpdateAssistantMemoryMutationBody = AssistantMemory
    export type UpdateAssistantMemoryMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateAssistantMemory = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantMemory>>, TError,{id: string;key: string;data: AssistantMemory}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateAssistantMemoryMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the current version of the object with given slug
 */
export const getAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
      
      return customAxios<AssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetAssistantDefinitionQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,) => {
    return ['api','assistantDefinitions',organizationSlug,slug] as const;
    }

    
export const getGetAssistantDefinitionQueryOptions = <TData = Awaited<ReturnType<typeof getAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAssistantDefinitionQueryKey(organizationSlug,slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAssistantDefinition>>> = ({ signal }) => getAssistantDefinition(organizationSlug,slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAssistantDefinition>>, TError, TData> 
}

export type GetAssistantDefinitionQueryResult = NonNullable<Awaited<ReturnType<typeof getAssistantDefinition>>>
export type GetAssistantDefinitionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetAssistantDefinition = <TData = Awaited<ReturnType<typeof getAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAssistantDefinitionQueryOptions(organizationSlug,slug,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the current version object with given slug in the organization
 */
export const updateAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    assistantDefinition: MaybeRef<AssistantDefinition>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
assistantDefinition = unref(assistantDefinition);
      
      return customAxios<AssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistantDefinition
    },
      options);
    }
  


export const getUpdateAssistantDefinitionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantDefinition>>, TError,{organizationSlug: string;slug: string;data: AssistantDefinition}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssistantDefinition>>, TError,{organizationSlug: string;slug: string;data: AssistantDefinition}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssistantDefinition>>, {organizationSlug: string;slug: string;data: AssistantDefinition}> = (props) => {
          const {organizationSlug,slug,data} = props ?? {};

          return  updateAssistantDefinition(organizationSlug,slug,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateAssistantDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssistantDefinition>>>
    export type UpdateAssistantDefinitionMutationBody = AssistantDefinition
    export type UpdateAssistantDefinitionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateAssistantDefinition = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssistantDefinition>>, TError,{organizationSlug: string;slug: string;data: AssistantDefinition}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateAssistantDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the current version of the given object
 */
export const deleteAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<DeleteAssistantDefinitionParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<StreamingResponseBody>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteAssistantDefinitionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssistantDefinition>>, TError,{organizationSlug: string;slug: string;params?: DeleteAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteAssistantDefinition>>, TError,{organizationSlug: string;slug: string;params?: DeleteAssistantDefinitionParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteAssistantDefinition>>, {organizationSlug: string;slug: string;params?: DeleteAssistantDefinitionParams}> = (props) => {
          const {organizationSlug,slug,params} = props ?? {};

          return  deleteAssistantDefinition(organizationSlug,slug,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteAssistantDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteAssistantDefinition>>>
    
    export type DeleteAssistantDefinitionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteAssistantDefinition = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteAssistantDefinition>>, TError,{organizationSlug: string;slug: string;params?: DeleteAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteAssistantDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the specific version of the object with given slug
 */
export const getVersionAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<AssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}/${version}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetVersionAssistantDefinitionQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','assistantDefinitions',organizationSlug,slug,version] as const;
    }

    
export const getGetVersionAssistantDefinitionQueryOptions = <TData = Awaited<ReturnType<typeof getVersionAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetVersionAssistantDefinitionQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionAssistantDefinition>>> = ({ signal }) => getVersionAssistantDefinition(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionAssistantDefinition>>, TError, TData> 
}

export type GetVersionAssistantDefinitionQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionAssistantDefinition>>>
export type GetVersionAssistantDefinitionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetVersionAssistantDefinition = <TData = Awaited<ReturnType<typeof getVersionAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVersionAssistantDefinitionQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the object with given slug and version in the organization
 */
export const updateVersionAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    assistantDefinition: MaybeRef<AssistantDefinition>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
assistantDefinition = unref(assistantDefinition);
      
      return customAxios<AssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}/${version}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistantDefinition
    },
      options);
    }
  


export const getUpdateVersionAssistantDefinitionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;data: AssistantDefinition}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;data: AssistantDefinition}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateVersionAssistantDefinition>>, {organizationSlug: string;slug: string;version: string;data: AssistantDefinition}> = (props) => {
          const {organizationSlug,slug,version,data} = props ?? {};

          return  updateVersionAssistantDefinition(organizationSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateVersionAssistantDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof updateVersionAssistantDefinition>>>
    export type UpdateVersionAssistantDefinitionMutationBody = AssistantDefinition
    export type UpdateVersionAssistantDefinitionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateVersionAssistantDefinition = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;data: AssistantDefinition}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateVersionAssistantDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the specified version of the given object
 */
export const deleteVersionAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<DeleteVersionAssistantDefinitionParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<boolean>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}/${version}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteVersionAssistantDefinitionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionAssistantDefinitionParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVersionAssistantDefinition>>, {organizationSlug: string;slug: string;version: string;params?: DeleteVersionAssistantDefinitionParams}> = (props) => {
          const {organizationSlug,slug,version,params} = props ?? {};

          return  deleteVersionAssistantDefinition(organizationSlug,slug,version,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteVersionAssistantDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVersionAssistantDefinition>>>
    
    export type DeleteVersionAssistantDefinitionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteVersionAssistantDefinition = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionAssistantDefinition>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteVersionAssistantDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listAssistants = (
    params?: MaybeRef<ListAssistantsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageAssistant>(
      {url: `/api/assistants`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListAssistantsQueryKey = (params?: MaybeRef<ListAssistantsParams>,) => {
    return ['api','assistants', ...(params ? [params]: [])] as const;
    }

    
export const getListAssistantsQueryOptions = <TData = Awaited<ReturnType<typeof listAssistants>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListAssistantsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistants>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListAssistantsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAssistants>>> = ({ signal }) => listAssistants(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAssistants>>, TError, TData> 
}

export type ListAssistantsQueryResult = NonNullable<Awaited<ReturnType<typeof listAssistants>>>
export type ListAssistantsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListAssistants = <TData = Awaited<ReturnType<typeof listAssistants>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListAssistantsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistants>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAssistantsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createAssistant = (
    assistant: MaybeRef<Assistant>,
 options?: SecondParameter<typeof customAxios>,) => {
      assistant = unref(assistant);
      
      return customAxios<Assistant>(
      {url: `/api/assistants`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assistant
    },
      options);
    }
  


export const getCreateAssistantMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssistant>>, TError,{data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createAssistant>>, TError,{data: Assistant}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAssistant>>, {data: Assistant}> = (props) => {
          const {data} = props ?? {};

          return  createAssistant(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof createAssistant>>>
    export type CreateAssistantMutationBody = Assistant
    export type CreateAssistantMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateAssistant = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssistant>>, TError,{data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of the resources for an organization
 */
export const listAssistantDefinitionsForOrganization = (
    organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListAssistantDefinitionsForOrganizationParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
params = unref(params);
      
      return customAxios<PageAssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListAssistantDefinitionsForOrganizationQueryKey = (organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListAssistantDefinitionsForOrganizationParams>,) => {
    return ['api','assistantDefinitions',organizationSlug, ...(params ? [params]: [])] as const;
    }

    
export const getListAssistantDefinitionsForOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListAssistantDefinitionsForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListAssistantDefinitionsForOrganizationQueryKey(organizationSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>> = ({ signal }) => listAssistantDefinitionsForOrganization(organizationSlug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>, TError, TData> 
}

export type ListAssistantDefinitionsForOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>>
export type ListAssistantDefinitionsForOrganizationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListAssistantDefinitionsForOrganization = <TData = Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListAssistantDefinitionsForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitionsForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAssistantDefinitionsForOrganizationQueryOptions(organizationSlug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the object in the organization using the template with given ref
 */
export const createAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    assistantDefinition: MaybeRef<AssistantDefinition>,
    params: MaybeRef<CreateAssistantDefinitionParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
assistantDefinition = unref(assistantDefinition);
params = unref(params);
      
      return customAxios<AssistantDefinition>(
      {url: `/api/assistantDefinitions/${organizationSlug}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: assistantDefinition,
        params: unref(params)
    },
      options);
    }
  


export const getCreateAssistantDefinitionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssistantDefinition>>, TError,{organizationSlug: string;data: AssistantDefinition;params: CreateAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createAssistantDefinition>>, TError,{organizationSlug: string;data: AssistantDefinition;params: CreateAssistantDefinitionParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAssistantDefinition>>, {organizationSlug: string;data: AssistantDefinition;params: CreateAssistantDefinitionParams}> = (props) => {
          const {organizationSlug,data,params} = props ?? {};

          return  createAssistantDefinition(organizationSlug,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateAssistantDefinitionMutationResult = NonNullable<Awaited<ReturnType<typeof createAssistantDefinition>>>
    export type CreateAssistantDefinitionMutationBody = AssistantDefinition
    export type CreateAssistantDefinitionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateAssistantDefinition = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAssistantDefinition>>, TError,{organizationSlug: string;data: AssistantDefinition;params: CreateAssistantDefinitionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateAssistantDefinitionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForAssistant = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/assistants/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForAssistantQueryKey = (id: MaybeRef<string>,) => {
    return ['api','assistants',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForAssistantQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForAssistant>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssistant>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForAssistantQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForAssistant>>> = ({ signal }) => getChangeSequenceForAssistant(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssistant>>, TError, TData> 
}

export type GetChangeSequenceForAssistantQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForAssistant>>>
export type GetChangeSequenceForAssistantQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForAssistant = <TData = Awaited<ReturnType<typeof getChangeSequenceForAssistant>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForAssistant>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForAssistantQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get a paginated list of all resources
 */
export const listAssistantDefinitions = (
    params?: MaybeRef<ListAssistantDefinitionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageAssistantDefinition>(
      {url: `/api/assistantDefinitions`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListAssistantDefinitionsQueryKey = (params?: MaybeRef<ListAssistantDefinitionsParams>,) => {
    return ['api','assistantDefinitions', ...(params ? [params]: [])] as const;
    }

    
export const getListAssistantDefinitionsQueryOptions = <TData = Awaited<ReturnType<typeof listAssistantDefinitions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListAssistantDefinitionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListAssistantDefinitionsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listAssistantDefinitions>>> = ({ signal }) => listAssistantDefinitions(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitions>>, TError, TData> 
}

export type ListAssistantDefinitionsQueryResult = NonNullable<Awaited<ReturnType<typeof listAssistantDefinitions>>>
export type ListAssistantDefinitionsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListAssistantDefinitions = <TData = Awaited<ReturnType<typeof listAssistantDefinitions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListAssistantDefinitionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listAssistantDefinitions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListAssistantDefinitionsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the change sequence for resource with the provided slug and version
 */
export const getModifiedForAssistantDefinition = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<number>(
      {url: `/api/assistantDefinitions/${organizationSlug}/${slug}/${version}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetModifiedForAssistantDefinitionQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','assistantDefinitions',organizationSlug,slug,version,'sequence'] as const;
    }

    
export const getGetModifiedForAssistantDefinitionQueryOptions = <TData = Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetModifiedForAssistantDefinitionQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>> = ({ signal }) => getModifiedForAssistantDefinition(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>, TError, TData> 
}

export type GetModifiedForAssistantDefinitionQueryResult = NonNullable<Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>>
export type GetModifiedForAssistantDefinitionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetModifiedForAssistantDefinition = <TData = Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForAssistantDefinition>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetModifiedForAssistantDefinitionQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



