<script setup lang="ts">
import { computed, ref } from "vue";
import { copyToClipboard } from "~/utils/general";

const props = defineProps({
  content: String,
  showContent: {
    type: Boolean,
    default: true,
  },
  color: {
    type: String,
    default: "text-gray-700",
  },
  message: {
    type: String,
    default: null,
  },
  copyMessage: {
    type: String,
    default: "Copied",
  },
  iconSize: {
    type: String,
    default: "12",
  },
  trim: {
    type: Boolean,
    default: false,
  },
  trimLength: {
    type: Number,
    default: 25,
  },
});

const hasCopied = ref(false);
const isHovering = ref(false);

const trimmedContent = computed(() => {
  if (!props.content) {
    return "";
  }
  const content = props.content.toString().trim();
  if (props.trim && content.length > props.trimLength) {
    return `${content.slice(0, props.trimLength)}...`;
  }
  return content;
});

const copied = function () {
  copyToClipboard(props.content, undefined);
  hasCopied.value = true;
  setTimeout(() => {
    hasCopied.value = false;
  }, 1000);
};

function handleMouseOver() {
  isHovering.value = true;
}

function handleMouseLeave() {
  isHovering.value = false;
}
</script>

<template>
  <span @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
    <span v-if="showContent" id="clipboardableContent">{{ trimmedContent }}</span>
    <span v-if="!hasCopied && message">{{ message }}</span>
    <MaterialDesignIcon
      v-show="true"
      id="copyIcon"
      v-tooltip="hasCopied ? copyMessage : message"
      :classes="[color]"
      name="content-copy"
      style="margin-right:2px; margin-left: 5px;"
      :size="iconSize"
      @click="copied"
    />
    <Transition name="fade">
      <span v-if="hasCopied" id="copiedText" class="font-light">&nbsp;{{ copyMessage }}</span>
    </Transition>
  </span>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* starting and ending state */ {
  opacity: 0;
}
</style>
