<script setup lang="ts">
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { filterIcon } from "@progress/kendo-svg-icons";
import type {Label} from "~/model";
import appStore from "~/store";
import { useListLabels } from "~/api/labels/labels";

const { currentOrganization } = storeToRefs(appStore.organizationStore);

// Setup our grid helpers
const gridHelper = createGridHelper(`document-tags-${currentOrganization.value?.id}`, {
  pageSize: 10,
  filter: "",
  sort: "",
  query: "",
  page: 1,
}, `organization.id: '${currentOrganization.value.id}'`);
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListLabels(gridQuery.value);

watch(data, (newValue) => {
  if (newValue) {
    gridHelper.setTotal(newValue.totalElements as number);
    gridHelper.saveState();
  }
});

const columns = [
  {
    title: "Document Tag",
    field: "label",
    cell: "label",
  },
  {
    title: "Color",
    field: "color",
    cell: "documentTagColor",
  },
];
const showCreateDocumentTag = ref(false);
const showEditDocumentTag = ref(false);
const selectedDocumentTag = ref<Label>();
const computedQuery = computed({
  get() {
    return gridHelper.query;
  },
  set(value) {
    gridHelper.setQuery(value);
  },
});

function openNew() {
  showCreateDocumentTag.value = true;
}

function editDocumentTag(editDocumentTag: Label) {
  selectedDocumentTag.value = editDocumentTag;
  showEditDocumentTag.value = true;
}
</script>

<template>
  <div style="height: calc(100vh - 69px);">
    <div class="mx-4">
      <Toolbar class="border-0 bg-white">
        <Button :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()" />
<!--        <KodexaTextInput-->
<!--          id="query" v-model="computedQuery" type="text" name="query"-->
<!--          class="block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" placeholder="Query..."-->
<!--        />-->
        <KodexaArticle class="mt-1" article-id="9094757" text="Learn more about document tags" :slide="true" />

        <ToolbarSpacer />
        <KodexaButton id="newDocumentTag" icon="plus" @click="openNew">
          New Document Tag
        </KodexaButton>
      </Toolbar>
      <Grid
        style="height: calc(100vh - 15rem);"
        :data-items="data ? data.content : []"
        :pageable="pageSettings"
        :loader="isLoading"
        :sort="sort"
        :skip="pageSettings.skip as number"
        :total="pageSettings.total as number"
        :page-size="pageSettings.take as number"
        :columns="columns"
        :filterable="showFilter"
        :filter="filter"
        :resizable="true"
        :sortable="true"
        @pagechange="gridHelper.pageChangeHandler($event)"
        @filterchange="gridHelper.filterChange($event)"
        @sortchange="gridHelper.sortChange($event)"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :error="error" :is-loading="isLoading" :is-error="isError"
            no-records-message="No document tags found"
          />
        </GridNoRecords>
        <template #label="{ props }">
          <td>
            <div class="flex items-center">
              <div class="flex-0 cursor-pointer text-lg text-gray-900" @click="editDocumentTag(props.dataItem)">
                {{props.dataItem?.label}}
              </div>
            </div>
          </td>
        </template>
        <template #documentTagColor="{ props }">
          <td class="px-4 py-2">
            <div class="flex items-center">
              <div class="mr-2 size-6 rounded-full" :style="`background-color: ${props.dataItem.color}`" />
              <span class="text-sm text-gray-700" style="background-color: white">{{ props.dataItem.color }}</span>
            </div>
          </td>
        </template>
      </Grid>
      <NewDocumentTag v-model="showCreateDocumentTag" @refetch-document-tag="refetch" />
      <EditDocumentTag
        v-if="selectedDocumentTag" v-model="showEditDocumentTag"
        :label="selectedDocumentTag" @refetch-document-tag="refetch"
      />
    </div>
  </div>
</template>
