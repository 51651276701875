/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  CreateDataFormParams
} from '../../model/createDataFormParams'
import type {
  DataForm
} from '../../model/dataForm'
import type {
  DeleteDataFormParams
} from '../../model/deleteDataFormParams'
import type {
  DeleteVersionDataFormParams
} from '../../model/deleteVersionDataFormParams'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListDataFormsForOrganizationParams
} from '../../model/listDataFormsForOrganizationParams'
import type {
  ListDataFormsParams
} from '../../model/listDataFormsParams'
import type {
  PageDataForm
} from '../../model/pageDataForm'
import type {
  StreamingResponseBody
} from '../../model/streamingResponseBody'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get the current version of the object with given slug
 */
export const getDataForm = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
      
      return customAxios<DataForm>(
      {url: `/api/dataForms/${organizationSlug}/${slug}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetDataFormQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,) => {
    return ['api','dataForms',organizationSlug,slug] as const;
    }

    
export const getGetDataFormQueryOptions = <TData = Awaited<ReturnType<typeof getDataForm>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataForm>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDataFormQueryKey(organizationSlug,slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataForm>>> = ({ signal }) => getDataForm(organizationSlug,slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataForm>>, TError, TData> 
}

export type GetDataFormQueryResult = NonNullable<Awaited<ReturnType<typeof getDataForm>>>
export type GetDataFormQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetDataForm = <TData = Awaited<ReturnType<typeof getDataForm>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataForm>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDataFormQueryOptions(organizationSlug,slug,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the current version object with given slug in the organization
 */
export const updateDataForm = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    dataForm: MaybeRef<DataForm>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
dataForm = unref(dataForm);
      
      return customAxios<DataForm>(
      {url: `/api/dataForms/${organizationSlug}/${slug}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: dataForm
    },
      options);
    }
  


export const getUpdateDataFormMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataForm>>, TError,{organizationSlug: string;slug: string;data: DataForm}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDataForm>>, TError,{organizationSlug: string;slug: string;data: DataForm}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDataForm>>, {organizationSlug: string;slug: string;data: DataForm}> = (props) => {
          const {organizationSlug,slug,data} = props ?? {};

          return  updateDataForm(organizationSlug,slug,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDataFormMutationResult = NonNullable<Awaited<ReturnType<typeof updateDataForm>>>
    export type UpdateDataFormMutationBody = DataForm
    export type UpdateDataFormMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateDataForm = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataForm>>, TError,{organizationSlug: string;slug: string;data: DataForm}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateDataFormMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the current version of the given object
 */
export const deleteDataForm = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<DeleteDataFormParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<StreamingResponseBody>(
      {url: `/api/dataForms/${organizationSlug}/${slug}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteDataFormMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataForm>>, TError,{organizationSlug: string;slug: string;params?: DeleteDataFormParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDataForm>>, TError,{organizationSlug: string;slug: string;params?: DeleteDataFormParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDataForm>>, {organizationSlug: string;slug: string;params?: DeleteDataFormParams}> = (props) => {
          const {organizationSlug,slug,params} = props ?? {};

          return  deleteDataForm(organizationSlug,slug,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDataFormMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDataForm>>>
    
    export type DeleteDataFormMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteDataForm = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataForm>>, TError,{organizationSlug: string;slug: string;params?: DeleteDataFormParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteDataFormMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the specific version of the object with given slug
 */
export const getVersionDataForm = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<DataForm>(
      {url: `/api/dataForms/${organizationSlug}/${slug}/${version}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetVersionDataFormQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','dataForms',organizationSlug,slug,version] as const;
    }

    
export const getGetVersionDataFormQueryOptions = <TData = Awaited<ReturnType<typeof getVersionDataForm>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionDataForm>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetVersionDataFormQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionDataForm>>> = ({ signal }) => getVersionDataForm(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionDataForm>>, TError, TData> 
}

export type GetVersionDataFormQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionDataForm>>>
export type GetVersionDataFormQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetVersionDataForm = <TData = Awaited<ReturnType<typeof getVersionDataForm>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionDataForm>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVersionDataFormQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the object with given slug and version in the organization
 */
export const updateVersionDataForm = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    dataForm: MaybeRef<DataForm>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
dataForm = unref(dataForm);
      
      return customAxios<DataForm>(
      {url: `/api/dataForms/${organizationSlug}/${slug}/${version}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: dataForm
    },
      options);
    }
  


export const getUpdateVersionDataFormMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionDataForm>>, TError,{organizationSlug: string;slug: string;version: string;data: DataForm}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateVersionDataForm>>, TError,{organizationSlug: string;slug: string;version: string;data: DataForm}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateVersionDataForm>>, {organizationSlug: string;slug: string;version: string;data: DataForm}> = (props) => {
          const {organizationSlug,slug,version,data} = props ?? {};

          return  updateVersionDataForm(organizationSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateVersionDataFormMutationResult = NonNullable<Awaited<ReturnType<typeof updateVersionDataForm>>>
    export type UpdateVersionDataFormMutationBody = DataForm
    export type UpdateVersionDataFormMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateVersionDataForm = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionDataForm>>, TError,{organizationSlug: string;slug: string;version: string;data: DataForm}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateVersionDataFormMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the specified version of the given object
 */
export const deleteVersionDataForm = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<DeleteVersionDataFormParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<boolean>(
      {url: `/api/dataForms/${organizationSlug}/${slug}/${version}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteVersionDataFormMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionDataForm>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionDataFormParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteVersionDataForm>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionDataFormParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVersionDataForm>>, {organizationSlug: string;slug: string;version: string;params?: DeleteVersionDataFormParams}> = (props) => {
          const {organizationSlug,slug,version,params} = props ?? {};

          return  deleteVersionDataForm(organizationSlug,slug,version,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteVersionDataFormMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVersionDataForm>>>
    
    export type DeleteVersionDataFormMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteVersionDataForm = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionDataForm>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionDataFormParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteVersionDataFormMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of the resources for an organization
 */
export const listDataFormsForOrganization = (
    organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListDataFormsForOrganizationParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
params = unref(params);
      
      return customAxios<PageDataForm>(
      {url: `/api/dataForms/${organizationSlug}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListDataFormsForOrganizationQueryKey = (organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListDataFormsForOrganizationParams>,) => {
    return ['api','dataForms',organizationSlug, ...(params ? [params]: [])] as const;
    }

    
export const getListDataFormsForOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof listDataFormsForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListDataFormsForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDataFormsForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListDataFormsForOrganizationQueryKey(organizationSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDataFormsForOrganization>>> = ({ signal }) => listDataFormsForOrganization(organizationSlug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDataFormsForOrganization>>, TError, TData> 
}

export type ListDataFormsForOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof listDataFormsForOrganization>>>
export type ListDataFormsForOrganizationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListDataFormsForOrganization = <TData = Awaited<ReturnType<typeof listDataFormsForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListDataFormsForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDataFormsForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDataFormsForOrganizationQueryOptions(organizationSlug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the object in the organization using the template with given ref
 */
export const createDataForm = (
    organizationSlug: MaybeRef<string>,
    dataForm: MaybeRef<DataForm>,
    params: MaybeRef<CreateDataFormParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
dataForm = unref(dataForm);
params = unref(params);
      
      return customAxios<DataForm>(
      {url: `/api/dataForms/${organizationSlug}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dataForm,
        params: unref(params)
    },
      options);
    }
  


export const getCreateDataFormMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataForm>>, TError,{organizationSlug: string;data: DataForm;params: CreateDataFormParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createDataForm>>, TError,{organizationSlug: string;data: DataForm;params: CreateDataFormParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDataForm>>, {organizationSlug: string;data: DataForm;params: CreateDataFormParams}> = (props) => {
          const {organizationSlug,data,params} = props ?? {};

          return  createDataForm(organizationSlug,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDataFormMutationResult = NonNullable<Awaited<ReturnType<typeof createDataForm>>>
    export type CreateDataFormMutationBody = DataForm
    export type CreateDataFormMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateDataForm = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataForm>>, TError,{organizationSlug: string;data: DataForm;params: CreateDataFormParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateDataFormMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of all resources
 */
export const listDataForms = (
    params?: MaybeRef<ListDataFormsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageDataForm>(
      {url: `/api/dataForms`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListDataFormsQueryKey = (params?: MaybeRef<ListDataFormsParams>,) => {
    return ['api','dataForms', ...(params ? [params]: [])] as const;
    }

    
export const getListDataFormsQueryOptions = <TData = Awaited<ReturnType<typeof listDataForms>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListDataFormsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDataForms>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListDataFormsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listDataForms>>> = ({ signal }) => listDataForms(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listDataForms>>, TError, TData> 
}

export type ListDataFormsQueryResult = NonNullable<Awaited<ReturnType<typeof listDataForms>>>
export type ListDataFormsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListDataForms = <TData = Awaited<ReturnType<typeof listDataForms>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListDataFormsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listDataForms>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListDataFormsQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the change sequence for resource with the provided slug and version
 */
export const getModifiedForDataForm = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<number>(
      {url: `/api/dataForms/${organizationSlug}/${slug}/${version}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetModifiedForDataFormQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','dataForms',organizationSlug,slug,version,'sequence'] as const;
    }

    
export const getGetModifiedForDataFormQueryOptions = <TData = Awaited<ReturnType<typeof getModifiedForDataForm>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForDataForm>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetModifiedForDataFormQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModifiedForDataForm>>> = ({ signal }) => getModifiedForDataForm(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModifiedForDataForm>>, TError, TData> 
}

export type GetModifiedForDataFormQueryResult = NonNullable<Awaited<ReturnType<typeof getModifiedForDataForm>>>
export type GetModifiedForDataFormQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetModifiedForDataForm = <TData = Awaited<ReturnType<typeof getModifiedForDataForm>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForDataForm>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetModifiedForDataFormQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



