<script lang="ts" setup>
import { Combobox, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import Fuse from "fuse.js";
import { ref } from "vue";
import appStore from "~/store";
import type { TagInstance } from "~/store/useWorkspace";
import { log } from "~/utils/logger";

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["closed"]);

const exceptions = computed(() => {
  const view = appStore.workspaceStore.views.find((v: any) => v.id === props.viewId);

  if (view.viewType === "document") {
    // We need to get the content exceptions from the document
    const documentView = createDocumentViewerStore(props.viewId);
    return documentView.contentExceptions;
  } else if (view.viewType === "dataForm") {
    // We need to get the data exceptions from the data form
    const dataFormView = createDataFormViewerStore(props.viewId);
    return dataFormView.dataExceptions;
  }
});

const rawQuery = ref("");

function onSelect(tagInstance: TagInstance) {
  const useDocumentViewStore = createDocumentViewerStore(props.viewId);
  log.info("Selecting tag instance", tagInstance);
  useDocumentViewStore.gotoTagInstance(tagInstance);
}

const fuseOptions = {
  useExtendedSearch: true,
  keys: ["message", "exception_details", "exceptionDetails"],
  findAllMatches: true,
};

const filteredExceptions = computed(() => {
  if (rawQuery.value === "") {
    return exceptions.value;
  }
  const fuse = new Fuse(exceptions.value, fuseOptions);
  const hits: Fuse.FuseResult<any>[] = fuse.search(rawQuery.value);
  return hits.map(hit => hit.item as TagInstance);
});
</script>

<template>
  <div>
    <div class="flex flex-col">
      <div class="mb-2 flex-1 bg-gray-50 px-2 py-2">
        <Combobox>
          <div>
            <KodexaTextInput
              v-model="rawQuery"
              name="filter"
              placeholder="Filter..."
            />
          </div>
          <p class="mt-1 ml-1">
            {{ filteredExceptions && filteredExceptions.length ? filteredExceptions.length : '0' }} found. <KodexaArticle class="ml-2 mt-2" article-id="9136709" text="Learn more about exceptions" :slide="true" />
          </p>
          <div style="height: calc(100vh - 18rem); overflow: auto">
            <ComboboxOptions
              v-if="filteredExceptions && filteredExceptions.length > 0" class="mx-1 scroll-py-10 scroll-pb-2 space-y-4 p-4 pb-2"
              static style="margin-bottom: 100px"
            >
              <li v-if="filteredExceptions.length > 0">
                <ul class="-mx-4 text-sm text-gray-700">
                  <ComboboxOption
                    v-for="filteredException in filteredExceptions" :key="filteredException.uuid" v-slot="{ active }"
                    as="template"
                  >
                    <li
                      :class="[active && 'bg-gray-100']" class="flex cursor-default select-none rounded-xl p-3"
                      @click="onSelect(filteredException)"
                    >
                      <div
                        class="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-red-500"
                      >
                        <MaterialDesignIcon aria-hidden="true" class="h-6 w-6 text-white" name="exceptions" />
                      </div>
                      <div class="ml-4 flex-auto">
                        <p :class="[active ? 'text-gray-900' : 'text-gray-700']" class="font-bold">
                          {{ filteredException.message }}
                        </p>
                        <p v-if="filteredException.dataObject" class="font-light">
                          {{ filteredException.dataObject.documentFamily.path }}
                        </p>
                        <p :class="[active ? 'text-gray-700' : 'text-gray-500']" class="text-sm">
                          {{ filteredException.exception_details }}{{ filteredException.exceptionDetails }}
                        </p>
                      </div>
                    </li>
                  </ComboboxOption>
                </ul>
              </li>
            </ComboboxOptions>
          </div>

          <div v-if="rawQuery === '?'" class="px-6 py-14 text-center text-sm sm:px-14">
            <MaterialDesignIcon aria-hidden="true" lass="mx-auto h-6 w-6 text-gray-400" name="help" />
            <p class="mt-4 font-semibold text-gray-900">
              Help with Labels
            </p>
            <p class="mt-2 text-gray-500">
              Use this tool to quickly search for exceptions in the current document. You can also use
              the search modifiers found in the footer below to limit the results.
            </p>
          </div>

          <div
            v-if="rawQuery !== '' && rawQuery !== '?' && filteredExceptions.length === 0"
            class="px-6 py-14 text-center text-sm sm:px-14"
          >
            <MaterialDesignIcon aria-hidden="true" class="mx-auto h-6 w-6 text-gray-400" name="alertBox" />
            <p class="mt-4 font-semibold text-gray-900">
              No exceptions found
            </p>
            <p class="mt-2 text-gray-500">
              We couldn’t find anything with that term. Please try again.
            </p>
          </div>
        </Combobox>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
