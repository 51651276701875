<script setup lang="ts">
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import type { Channel, Message, MessageBlock } from "~/model";
import appStore from "~/store";

const props = defineProps({
  message: {
    type: Object as PropType<Message>,
    required: true,
  },
  channel: {
    type: Object as PropType<Channel>,
    required: false,
  },
  messageBlock: {
    type: Object as PropType<MessageBlock>,
    required: true,
  },
});
const { user } = storeToRefs(appStore.userStore);
</script>

<template>
  <div>
    <div class="w-100 my-2 ml-1 flex items-center space-x-2">
      <MaterialDesignIcon name="alert" class="text-red-500" />
      <div class="font-md ml-3">
        {{ messageBlock?.properties?.text }}
      </div>
    </div>
    <KodexaMarkdown
      v-if="messageBlock.properties.exceptionInfo && user.showDeveloperTools" :content="messageBlock.properties.exceptionInfo"
      :show-clipboard="true"
    />
  </div>
</template>

<style scoped>
</style>
