<script lang="ts" setup>
import router from "~/router/router";
function redirectHomePage() {
  router.push("/a/home");
}
</script>

<template>
  <KodexaTermsConditionPopup @redirect-home-page="redirectHomePage" />
</template>
