<script lang="ts" setup>
import {PropType, ref, watch} from "vue";
import {Taxon, TaxonConditionalFormat, Taxonomy} from "~/model";
import {ColorPicker} from "@progress/kendo-vue-inputs";
import {randomColor} from "~/utils/colors";

const props = defineProps({
  modelValue: {
    type: Array as PropType<TaxonConditionalFormat[]>,
    required: true,
  },
  taxon: {
    type: Object as PropType<Taxon>,
    required: true,
  },
  taxonomy: {
    type: Object as PropType<Taxonomy>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const items = ref<TaxonConditionalFormat[]>(props.modelValue || []);

watch(items, (newItems) => {
  emit("update:modelValue", newItems);
}, {deep: true});

function addConditionalFormat() {
  items.value.push({
    type: "cellBackground",
    condition: "",
    properties: {
      color: randomColor()
    }
  });
}

function removeItem(index: number) {
  items.value.splice(index, 1);
}

const availableFormatters = [
  {
    type: "backgroundColor",
    label: "Background Color",
  },
  {
    type: "outlineColor",
    label: "Outline Color",
  },
  {
    type: "textColor",
    label: "Text Color",
  },
  {
    type: "icon",
    label: "Icon",
  }
]

const icons = [{
  id: "alert-box-outline",
  text: "Alert Box Outline",
},
  {
    id: "alert-box",
    text: "Alert Box",
  },
  {
    id: "alert-circle-outline",
    text: "Alert Circle Outline",
  },
  {
    id: "alert-circle",
    text: "Alert Circle",
  }]
</script>

<template>
  <div>
    <div class="mb-1">
      <KodexaArticle article-id="9186039" text="Learn more about conditional formatting." :slide="false"/>
    </div>
    <div class="mb-2">
      <KodexaButton class="mt-2" type="secondary" size="small" icon="plus" @click="addConditionalFormat">
        Add Conditional Format
      </KodexaButton>
    </div>

    <div v-for="(item, index) in items" :key="index" class="mb-4">
      <div class="flex flex-col justify-between h-full">
        <!-- Type -->
        <div>
          <KodexaDropDown
            v-model="item.type" :items="availableFormatters" name="type" value-field="type" text-field="label"
            label="Type"/>
        </div>

        <!-- Condition -->
        <div class="mt-2">
          <FormulaInput v-model="item.condition"
                        :taxon="taxon"
                        :num-rows="5"
                        :toggle-available-elements="true"
                        :taxonomy="taxonomy"
                        name="formulaInput"/>
        </div>

        <!-- Color -->
        <div v-if="item.type?.includes('Color')" class="mt-2">
          <ColorPicker v-model="item.properties.color" name="color" label="Color"/>
        </div>

        <div v-if="item.type === 'icon'">
          <KodexaDropDown :items="icons" v-model="item.properties.icon" label="Icon" name="icon" class="mb-1"/>
          <ColorPicker v-model="item.properties.color" name="color" label="Color"/>
        </div>

        <!-- Spacer to push Delete button to the bottom -->
        <div class="flex-grow"></div>

        <!-- Delete Button at the bottom right -->
        <div class="self-end mt-2">
          <KodexaButton
            type="danger" size="small" icon="delete" @click="removeItem(index)"
          >
            Delete
          </KodexaButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
