<script setup lang="ts">
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import appStore from "~/store";
import type { IndexedContent } from "~/store/useDocumentSearch";
import { createDocumentSearch } from "~/store/useDocumentSearch";
import { log } from "~/utils/logger";

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["closed"]);
const {
  workspace,
  views
} = storeToRefs(appStore.projectStore);
const useDocumentSearch = createDocumentSearch(props.viewId);
const {
  tagMetadataMap,
  project
} = storeToRefs(appStore.projectStore);

const rawQuery = ref("");
const searchIndexHits = ref<IndexedContent[]>([]);
const message = ref("");

watch(rawQuery, (query) => {
  if (query) {
    searchIndexHits.value = useDocumentSearch ? useDocumentSearch.search(query) : [];
  }
});

function gotoSearchHit(hit: IndexedContent) {
  if (hit) {
    log.info("Goto search hit", hit.nodeUuid);
    appStore.workspaceStore.setFocusedNodeUuid(hit.nodeUuid);
  }
}
</script>

<template>
  <div>
    <Combobox>
      <div class="bg-gray-50 p-1">
        <KodexaTextInput
          v-model="rawQuery"
          name="search"
          placeholder="Search..."
        />
      </div>
      <div class="flex flex-wrap items-center bg-gray-50 px-4 py-2.5 text-xs text-gray-700">
        Type
        <kbd
          class="mx-1 flex h-5 w-5 items-center justify-center rounded border border-gray-400 bg-white font-semibold text-gray-900 sm:mx-2"
        >^</kbd>
        <span>for starts with</span>
        <kbd
          class="mx-1 flex h-5 w-5 items-center justify-center rounded border border-gray-400 bg-white font-semibold text-gray-900 sm:mx-2"
        >!</kbd>
        to negate the terms
        <kbd
          class="mx-1 flex h-5 w-5 items-center justify-center rounded border border-gray-400 bg-white font-semibold text-gray-900 sm:mx-2"
        >.</kbd>
        for ends with
        <kbd
          class="mx-1 flex h-5 w-5 items-center justify-center rounded border border-gray-400 bg-white font-semibold text-gray-900 sm:mx-2"
        >'</kbd>
        for includes
      </div>

      <p class="mx-2 ml-4 mt-3">
        {{ message }}
      </p>
      <div style="height: calc(100vh - 10rem); overflow: auto">
        <ComboboxOptions
          v-if="searchIndexHits.length > 0" static
          class="scroll-py-10 scroll-pb-2 space-y-4 p-4 pb-2"
          style="height: calc(100vh - 16rem);"
        >
          <li v-if="searchIndexHits.length > 0">
            <ul class="-mx-4 text-sm text-gray-700">
              <ComboboxOption
                v-for="searchHit in searchIndexHits" :key="searchHit.nodeUuid" v-slot="{ active }"
                as="template"
              >
                <li class="flex cursor-default select-none rounded-xl p-3" :class="[active && 'bg-gray-100']">
                  <div class="flex-auto" @click="gotoSearchHit(searchHit)">
                    <p class="text-sm font-medium" :class="[active ? 'text-gray-900' : 'text-gray-700']">
                      {{ searchHit.content }}
                    </p>
                    <p v-if="searchHit.page" class="text-sm" :class="[active ? 'text-gray-700' : 'text-gray-500']">
                      Page {{ searchHit.page + 1 }}
                    </p>
                  </div>
                </li>
              </ComboboxOption>
            </ul>
          </li>
        </ComboboxOptions>
      </div>

      <div v-if="rawQuery === '?'" class="px-6 py-14 text-center text-sm sm:px-14">
        <MaterialDesignIcon name="help" class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true"/>
        <p class="mt-4 font-semibold text-gray-900">
          Help with Labels
        </p>
        <p class="mt-2 text-gray-500">
          Use this tool to quickly search for users and projects across our entire platform. You can also use
          the search modifiers found in the footer below to limit the results to just users or projects.
        </p>
      </div>

      <div
        v-if="rawQuery !== '' && rawQuery !== '?' && searchIndexHits.length === 0"
        class="px-6 py-14 text-center text-sm sm:px-14"
      >
        <MaterialDesignIcon name="alertBox" class="mx-auto h-6 w-6 text-gray-400" aria-hidden="true"/>
        <p class="mt-4 font-semibold text-gray-900">
          No content found
        </p>
        <p class="mt-2 text-gray-500">
          We could not find anything with that term. Please try again.
        </p>
      </div>
    </Combobox>
  </div>
</template>

<style scoped>

</style>
