<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import appStore from "~/store";
import { createDataFormViewerStore } from "~/store/useDataFormView";
import type { TagMetadata } from "~/store/useProject";
import type { Card, DataObject } from "~/model";
import { useGridScroll } from "~/utils/useGridScroll";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  designMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const { documentFamilies } = storeToRefs(appStore.workspaceStore);
const useDataFormViewer = createDataFormViewerStore(props.viewId);
const {
  dataObjects,
} = storeToRefs(useDataFormViewer);

const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const isLoading = ref(false);
const isError = ref(false);
const error = ref("");
const keyFilter = ref("");

const groupTaxonMetadata = computed(() => {
  return tagMetadataMap.value.get(props.card.properties?.groupTaxon);
});

// Then we will filter the data objects for the parent data object so we can get the
// attributes
const scopedDataObjects = computed(() => {
  if (!groupTaxonMetadata.value) {
    return [];
  }
  let scopedDataObjects = dataObjects.value.filter((dataObject) => {
    return dataObject.path === groupTaxonMetadata.value.path;
  });

  if (props.parentDataObject) {
    scopedDataObjects = scopedDataObjects.filter((dataObject) => {
      return dataObject.parentId === props.parentDataObject?.id;
    });
  }
  return scopedDataObjects;
});

const dataObjectsByDocumentFamily = computed(() => {
  const dataObjectsByDocumentFamily = new Map<string, DataObject[]>();
  scopedDataObjects.value.forEach((dataObject) => {
    if (dataObject.documentFamily.id) {
      if (!dataObjectsByDocumentFamily.has(dataObject.documentFamily.id)) {
        dataObjectsByDocumentFamily.set(dataObject.documentFamily.id, []);
      }
      dataObjectsByDocumentFamily.get(dataObject.documentFamily.id)?.push(dataObject);
    }
  });
  return dataObjectsByDocumentFamily;
});

interface TransposedColumn {
  title: string;
  field?: string;
  width?: number;
  index?: number;
  locked?: boolean;
  documentFamilyId?: string;
  sortable?: boolean;
  resizable?: boolean;
  filterable?: boolean;
  headerAttributes?: any;
  children?: TransposedColumn[];
  attributes?: any;
  cell?: string | null;
  headerCell?: string;
}

const columns = computed(() => {
  const columns: TransposedColumn[] = [{
    title: " ",
    field: "name",
    width: 300,
    locked: true,
    sortable: true,
    resizable: true,
    filterable: true,
    children: [] as TransposedColumn[],
    headerAttributes: {
      style: "text-align: center;",
    },
    attributes: {
      style: "text-align: center;",
    },
    cell: null,
    headerCell: "keyHeader",
  }];

  Array.from(dataObjectsByDocumentFamily.value.keys())
    .sort((a, b) => {
      const a_path = documentFamilies.value.get(a)?.path;
      const b_path = documentFamilies.value.get(b)?.path;
      return a_path.localeCompare(b_path);
    }) // Sort alphabetically
    .forEach((documentFamilyId, docIdx) => {
      const familyDataObjects = dataObjectsByDocumentFamily.value.get(documentFamilyId);
      if (familyDataObjects && familyDataObjects.length > 1) {
        const multiHeader = {
          title: documentFamilies.value.get(documentFamilyId)?.path || documentFamilyId,
          children: [],
        } as TransposedColumn;
        familyDataObjects.forEach((dataObject, idx) => {
          multiHeader.children.push({
            field: `${documentFamilyId}_${idx}`,
            documentFamilyId,
            index: idx,
            title: `#${idx}`,
            width: 250,
            resizable: true,
            cell: "dataAttribute",
          });
        });
        columns.push(multiHeader);
      } else {
        columns.push({
          field: `${documentFamilyId}_0`,
          documentFamilyId,
          index: docIdx,
          title: documentFamilies.value.get(documentFamilyId)?.path || documentFamilyId,
          width: 250,
          resizable: true,
          cell: "dataAttribute",
        });
      }
    });
  return columns;
});

const dataItems = computed(() => {
  const attributes: Map<string, any> = new Map();
  for (const attributeTaxon of groupTaxonMetadata.value.taxon.children) {
    if (attributeTaxon.group || attributeTaxon.taxonType === "SECTION") {
      continue;
    }
    columns.value.forEach((column) => {
      if (column.children === undefined) {
        dataObjectsByDocumentFamily.value.get(column.documentFamilyId as string)?.forEach((dato) => {
          dato.attributes?.forEach((attribute) => {
            if (!attributes.has(attributeTaxon.path)) {
              attributes.set(attributeTaxon.path, {
                taxon: attributeTaxon,
                attribute,
                dataObjects: [dato],
              });
            } else {
              const existingDataObject = attributes.get(attributeTaxon.path).dataObjects.find(eDato => eDato.uuid === dato.uuid);
              if (!existingDataObject) {
                attributes.get(attributeTaxon.path).dataObjects.push(dato);
              }
            }
          });
        });
      } else if (column.children) {
        column.children.forEach((childColumn: any) => {
          dataObjectsByDocumentFamily.value.get(childColumn.documentFamilyId as string)?.forEach((dato: DataObject) => {
            dato.attributes?.forEach((attribute) => {
              if (!attributes.has(attributeTaxon.path)) {
                attributes.set(attributeTaxon.path, {
                  taxon: attributeTaxon,
                  attribute,
                  dataObjects: [dato],
                });
              } else {
                const existingDataObject = attributes.get(attributeTaxon.path).dataObjects.find(eDato => eDato.uuid === dato.uuid);
                if (!existingDataObject) {
                  attributes.get(attributeTaxon.path).dataObjects.push(dato);
                }
              }
            });
          });
        });
      }
    });
  }

  const items: any[] = [];

  function fastFindDataObjects(dataObjects: DataObject[], documentFamilyId: string, index: number): DataObject | undefined {
    let matchCount = 0;
    for (const dataObject of dataObjects) {
      if (dataObject.documentFamily.id === documentFamilyId && matchCount === index) {
        return dataObject;
      }
      matchCount++;
    }
    return undefined;
  }

  for (const key of attributes.keys()) {
    // If keyFilter is set then we need to fuzzy match the taxon.label to
    // only add those
    if (keyFilter.value && !attributes.get(key).taxon.label.toLowerCase().includes(keyFilter.value.toLowerCase())) {
      continue;
    }

    const item: any = {
      name: attributes.get(key).taxon.label,
      path: attributes.get(key).taxon.path,
      taxon: attributes.get(key).taxon,
      index: 0,
      open: false,
    };
    columns.value.forEach((column) => {
      if (column.children) {
        column.children.forEach((childColumn: any) => {
          const documentFamilyId = childColumn.documentFamilyId as string;
          const index = childColumn.index as number;
          item[childColumn.field as string] = fastFindDataObjects(attributes.get(key).dataObjects, documentFamilyId, index);
        });
      } else {
        const documentFamilyId = column.documentFamilyId as string;
        const index = column.index as number;
        item[column.field as string] = fastFindDataObjects(attributes.get(key).dataObjects, documentFamilyId, index);
      }
    });
    items.push(item);
  }
  return items;
});

function getDataObjectForField(dataItem, field) {
  return dataObjects.value.find(dataObject => dataObject.uuid === dataItem[field]?.uuid);
}

const tableElement = ref(null);
const gridScroll = useGridScroll(tableElement); // Use the composable
onUnmounted(() => {
  gridScroll.unmount();
});
</script>

<template>
  <div>
    <div v-if="groupTaxonMetadata" ref="tableElement" style="overflow-x: scroll">
      <Grid
        style="height: calc(100vh - 19rem);"
        :data-items="dataItems"
        :columns="columns"
        :pageable="false"
        :sortable="false"
        :filterable="false"
        :resizable="true"
        size="small"
      >
        <GridNoRecords>
          <KodexaGridNoRecords
            :is-error="isError" :is-loading="isLoading" :error="error"
            no-records-message="No data found"
          />
        </GridNoRecords>
        <template #keyHeader>
          <KodexaTextInput v-model="keyFilter" class="p-1" name="keyFilter" placeholder="Filter..." />
        </template>
        <template #dataAttribute="{ props: newProps }">
          <td>
            <div>
              <KodexaFormTransposedGridAggregatedCell
                v-if="getDataObjectForField(newProps.dataItem, newProps.field)"
                :data-object="getDataObjectForField(newProps.dataItem, newProps.field)"
                :taxon="newProps.dataItem.taxon" :view-id="viewId"
              />
            </div>
          </td>
        </template>
      </Grid>
    </div>
    <div v-else>
      <div class="flex flex-col items-center justify-center">
        <MaterialDesignIcon name="database" size="48" class="text-gray-400" />
        <p class="mt-2 text-sm text-gray-500">
          No data is available to display
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
