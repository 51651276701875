<script lang="ts" setup>
const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["closed"]);
</script>

<template>
  <div>
    <div class="flex h-full flex-col bg-white">
      <div class="flex-1">
        <!-- Header -->
        <div class="bg-gray-50 px-4 py-6 sm:px-6">
          <div class="flex items-start justify-between space-x-3">
            <div class="space-y-1">
              <p class="mt-2 text-sm text-gray-500">
                Search
              </p>
            </div>
            <div class="flex h-7 items-center">
              <button class="text-gray-400 hover:text-gray-500" type="button" @click="emit('closed')">
                <span class="sr-only">Close panel</span>
                <MaterialDesignIcon aria-hidden="true" class="h-6 w-6" name="close"/>
              </button>
            </div>
          </div>
        </div>
      </div>
      <KodexaDocumentSearch :view-id="props.viewId"/>
    </div>
  </div>
</template>

<style scoped>

</style>
