<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import appStore from "~/store";
import { createDataFormViewerStore } from "~/store/useDataFormView";
import type { TagMetadata } from "~/store/useProject";
import type { Card, DataObject } from "~/model";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  designMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["deleteDataObject", "addDataObject"]);

const useDataFormViewer = createDataFormViewerStore(props.viewId);
const { dataObjects, designMode } = storeToRefs(useDataFormViewer);
const { tagMetadataMap } = storeToRefs(appStore.projectStore);

// We need to get the taxon for the attribute, and then we need to get the
// instances of the attribute from the available data objects.
const tagMetadata = computed(() => {
  return tagMetadataMap.value.get(props.card.properties?.taxon);
});

// Then we will filter the data objects for the parent data object so we can get the
// attributes
const scopedDataObjects = computed(() => {
  let scopedDataObjects = dataObjects.value.filter((dataObject) => {
    return dataObject.path === tagMetadata.value.parentPath;
  });

  if (props.parentDataObject) {
    scopedDataObjects = scopedDataObjects.filter((dataObject) => {
      return dataObject.uuid === props.parentDataObject?.uuid;
    });
  }
  return scopedDataObjects;
});
</script>

<template>
  <div>
    <div v-if="tagMetadata">
      <p v-if="tagMetadata?.taxon?.taxonType !== 'BOOLEAN'" class="mb-1 text-gray-700">
        <span v-if="card.properties?.attributeLabel">{{ card.properties.attributeLabel }}</span>
        <span v-else>{{ tagMetadata && tagMetadata.label ? tagMetadata.label : tagMetadata.name }}</span>
      </p>
      <div v-if="!designMode">
        <div v-for="dataObject in scopedDataObjects" :key="dataObject.uuid">
          <KodexaDataAttributeEditor
            :tag-metadata="tagMetadata"
            :data-object="dataObject"
            :view-id="viewId"
            :taxon-override-options="props.card?.properties?.taxonOverrideOptions ? props.card.properties.taxonOverrideOptions : {}"
            :masked-text-options="props.card?.properties?.maskedTextOptions ? props.card.properties.maskedTextOptions : {}"
            :checkbox-options="props.card?.properties?.checkboxOptions ? props.card.properties.checkboxOptions : {}"
            :editor-options="props.card?.properties?.maskedTextOptions ? props.card.properties.maskedTextOptions : {}"
            @delete-data-object="emit('deleteDataObject', $event)"
            @add-data-object="emit('addDataObject', $event)"
          />
        </div>
      </div>
    </div>
    <div v-else>
      ...
    </div>
  </div>
</template>

<style scoped>
</style>
