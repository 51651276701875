<script setup lang="ts">
import type { TilePosition } from "@progress/kendo-vue-layout";
import { TileLayout } from "@progress/kendo-vue-layout";
import type { PropType, Ref } from "vue";
import type {
  TileLayoutRepositionEvent,
  TileStrictPosition,
} from "@progress/kendo-vue-layout/dist/npm/tilelayout/interfaces/main";
import { availableWidgets } from "~/components/dashboard/widgets/widgets";
import appStore from "~/store";
import KodexaDashboardWidgetPropertiesPopup from "~/components/dashboard/kodexa-dashboard-widget-properties-popup.vue";
import { Dashboard, DashboardWidget } from "~/model";

const props = defineProps({
  modelValue: Object as PropType<Dashboard>,
  setupMode: Boolean,
  entryPoint: String,
  allowDelete: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(["update:modelValue", "delete-dashboard", "save-dashboard"]);

const localDashboard = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const widgetReposition: Ref<TilePosition[]> = ref([]);

const singleWidget = computed({
  get() {
    widgetReposition.value = [];
    if (localDashboard.value?.singleWidget && localDashboard.value?.singleWidget.properties === undefined) {
      localDashboard.value.singleWidget.properties = {};
    }
    return localDashboard.value?.singleWidget;
  },
  set(value) {
    localDashboard.value.singleWidget = value;
  },
});

const tileItems = computed(() => {
  return localDashboard.value?.widgets?.map((widget) => {
    if (widget.properties) {
      const tile = JSON.parse(JSON.stringify(widget)) as any;
      if (!widgetReposition.value.some(position => position?.id === widget.id)) {
        const tilePosition = tile.properties.defaultPosition ?? { col: 0, colSpan: 1, row: 0, rowSpan: 1 };
        tilePosition.id = widget.id;
        widgetReposition.value.push(tilePosition);
      }
      if (props.setupMode) {
        tile.header = "tileHeader";
        tile.body = "tileTemplate";
      } else {
        tile.item = "tileTemplate";
      }
      tile.reorderable = props.setupMode;
      tile.resizable = props.setupMode;
      return tile;
    } else {
      return {};
    }
  });
});

function getWidgetImplementation(type) {
  return availableWidgets.find(widget => widget.type === type)?.implementation;
}

function getWidgetOptions(type) {
  return availableWidgets.find(widget => widget.type === type)?.options;
}

function deleteWidget(widget) {
  if (props.entryPoint === "organization") {
    appStore.organizationStore.deleteWidgetFromDashboard(localDashboard.value, widget);
  } else {
    appStore.projectStore.deleteWidgetFromDashboard(localDashboard.value, widget);
  }
}

function handleReposition(e: TileLayoutRepositionEvent) {
  const maxHeightInRows = 10; // Customize this value according to your need
  const tile: TileStrictPosition = e.value[e.index];
  if (tile.rowSpan > maxHeightInRows) {
    const excess = tile.rowSpan - maxHeightInRows;
    if (tile.rowSpan - excess >= 1) {
      tile.rowSpan -= excess;
    }
  }

  widgetReposition.value[e.index] = tile;

  // find and update the widget
  const widget = localDashboard.value?.widgets?.find(widget => widget.id === tile.id);
  if (widget) {
    if (!widget.properties) {
      widget.properties = {};
    }
    widget.properties.defaultPosition = tile;
    if (props.entryPoint === "organization") {
      appStore.organizationStore.updateWidgetInDashboard(localDashboard.value, widget);
    } else {
      appStore.projectStore.updateWidgetInDashboard(localDashboard.value, widget);
    }
  }
}

function saveAndUpdateWidgetDashboard() {
  if (widgetReposition.value) {
    for (const widgetPosition of widgetReposition.value) {
      const widget = localDashboard.value?.widgets?.find(widget => widget.id === widgetPosition.id);
      if (!widget) {
        continue;
      }
      if (!widget.properties) {
        widget.properties = {};
      }
      widget.properties.defaultPosition = widgetPosition;
      if (props.entryPoint === "organization") {
        appStore.organizationStore.updateWidgetInDashboard(localDashboard.value, widget);
      } else {
        appStore.projectStore.updateWidgetInDashboard(localDashboard.value, widget);
      }
    }
  }
  emit("save-dashboard", localDashboard.value);
}

const isSingle = computed({
  get() {
    return localDashboard.value.singleWidget !== undefined;
  },
  set(value) {
    if (value && singleWidget.value === undefined) {
      singleWidget.value = {
        id: "1",
        type: undefined,
      };
    } else {
      singleWidget.value = undefined;
    }
  },
});

const propertiesOpen = ref(false);

watch(() => props.setupMode, () => {
  if (!props.setupMode) {
    propertiesOpen.value = false;
  }
});

const dashboardWidgets = computed(() => {
  return availableWidgets.filter((widget) => {
    return widget.dashboard;
  });
});

const showWidgetProperties = ref(false);
const selectedWidget: Ref<DashboardWidget | undefined> = ref(undefined);

function editWidget(widget: DashboardWidget) {
  showWidgetProperties.value = true;
  selectedWidget.value = widget;
}

const refresher = ref(1);

function updateWidget(widget: DashboardWidget) {
  showWidgetProperties.value = false;
  selectedWidget.value = undefined;
  if (props.entryPoint === "organization") {
    appStore.organizationStore.updateWidgetInDashboard(localDashboard.value, widget);
  } else {
    appStore.projectStore.updateWidgetInDashboard(localDashboard.value, widget);
  }
  refresher.value = refresher.value + 1;
}


</script>

<template>
  <div v-if="propertiesOpen">
    <KodexaDashboardToolbar
      v-if="setupMode" v-model="propertiesOpen" :is-dashboard="true" :widgets="availableWidgets"
      :allow-delete="allowDelete"
      :entry-point="entryPoint"
      :dashboard="localDashboard" @delete="emit('delete-dashboard')" @save="saveAndUpdateWidgetDashboard"
    />

    <div class="mx-4 mt-2">
      <KodexaTextInput
        v-model="localDashboard.name" label="Name" name="Name"
        placeholder="Enter a name for your dashboard"
      />

      <div v-if="isSingle && singleWidget.type" class="mt-1" style="width: 70%">
        <KodexaDropDown
          v-model="singleWidget.type"
          class="mt-1"
          name="Widget"
          label="Widget Type"
          text-field="name"
          value-field="type"
          :items="dashboardWidgets"
        />
      </div>

      <div v-if="isSingle && getWidgetOptions(singleWidget.type)?.length > 0" class="mt-3" style="width: 70%">
        <ConfigurationOption
          v-for="option in getWidgetOptions(singleWidget.type)" :key="option.name"
          v-model="singleWidget.properties"
          :item="option"
        />
      </div>
      <div v-if="!singleWidget">
        <TileLayout
          v-if="tileItems && !singleWidget"
          :columns="10"
          :row-height="70"
          :positions="widgetReposition"
          :items="tileItems"
          :gap="{
            rows: 10,
            columns: 10,
          }"
          auto-flow="column dense"
          @reposition="handleReposition"
        >
          <template #tileHeader="{ props }">
            <div>
              {{ props.tile.title ?? getWidgetImplementation(props.tile.type)?.name }}
              <div v-if="setupMode" style="float: right" class="-mt-2">
                <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" @click="deleteWidget(props.tile)" />
              </div>
            </div>
          </template>
          <template #tileTemplate="{ props }">
            <Component :is="getWidgetImplementation(props.tile.type)" :widget="props.tile" />
          </template>
        </TileLayout>
      </div>
    </div>
  </div>
  <div v-else :class="singleWidget ? ['bg-white'] : ['bg-gray-50'] " style="height: calc(100vh - 8rem); overflow: auto">
    <KodexaDashboardToolbar
      v-if="setupMode"
      v-model="propertiesOpen" :entry-point="entryPoint" :dashboard="localDashboard" :is-dashboard="true"
      :allow-delete="allowDelete" @delete="emit('delete-dashboard')" @save="saveAndUpdateWidgetDashboard"
    />
    <div v-if="singleWidget">
      <Component :is="getWidgetImplementation(singleWidget.type)" :widget="singleWidget" />
    </div>
    <TileLayout
      v-if="tileItems && !singleWidget"
      :columns="10"
      :row-height="70"
      :positions="widgetReposition"
      :items="tileItems"
      :gap="{
        rows: 10,
        columns: 10,
      }"
      auto-flow="column"
      @reposition="handleReposition"
    >
      <template #tileHeader="{ props }">
        <div class="">
          {{ props.tile.title ?? props.tile.name }}

          <div v-if="setupMode" style="float: right" class="-mt-2">
            <MaterialDesignIcon
              id="deleteDashboardWidget" name="close" class="size-6" aria-hidden="true"
              @click="deleteWidget(props.tile)"
            />
          </div>
          <div v-if="setupMode" style="float: right" class="-mt-2">
            <MaterialDesignIcon
              id="editDashboardWidget" name="properties" class="size-6" aria-hidden="true"
              @click="editWidget(props.tile)"
            />
          </div>
        </div>
      </template>
      <template #tileTemplate="{ props }">
        <div class="animate-fadeIn p-2">
          <Component :is="getWidgetImplementation(props.tile.type)" :widget="props.tile" :refresher="refresher" :key="props.tile.id" />
        </div>
      </template>
    </TileLayout>
    <div class="mt-28" v-if="!singleWidget"/>
    <KodexaDashboardWidgetPropertiesPopup
      v-model="showWidgetProperties" :widget="selectedWidget" :key="selectedWidget?.id"
      @update-widget="updateWidget"
    />
  </div>
</template>

<style scoped>
</style>
