<script setup lang="ts">
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import type {
  DocumentFamily,
} from "~/model";
import { createGridHelper } from "~/store/useGridHelper";
import { RefHelper } from "~/utils/ref-utils";
import { getContentObjectContentWithVersion } from "~/api/stores/stores";
import { useListContentObjects } from "~/api/content-object/content-object";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
  },
});

const gridHelper = createGridHelper(`content-object-list-${props.documentFamily?.id}`, {
  page: 1,
  pageSize: 6,
  filter: `documentFamily.id:'${props.documentFamily?.id}'`,
  query: "",
  sort: "createdOn:desc",
});
const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
} = useListContentObjects(gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const columns = [
  { title: "Internal ID", field: "id" },
  { title: "Created", field: "createdOn" },
  { title: "Type", field: "contentType" },
  { title: "Size", field: "size" },
  { title: "Mixins", field: "mixins" },
  { title: "", field: "", cell: "actions" },
];

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});

function downloadContentObject(contentObject: any) {
  const refHelper = new RefHelper(props.documentFamily?.storeRef);
  getContentObjectContentWithVersion(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), props.documentFamily?.id as string, contentObject.id).then((blob: Blob) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${contentObject.id}.kddb`;
    link.click();
    URL.revokeObjectURL(link.href);
  });
}
</script>

<template>
  <div>
    <Grid
      style="height: 500px"
      :data-items="data ? data.content : []"
      :pageable="pageSettings"
      :loader="isLoading"
      :sort="sort"
      scrollable="scrollable"
      :skip="pageSettings.skip as number"
      :total="pageSettings.total as number"
      :page-size="pageSettings.take as number"
      :take="pageSettings.take as number"
      :columns="columns"
      :filterable="showFilter"
      :filter="filter"
      :resizable="true"
      :sortable="true"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <GridNoRecords>
        <KodexaGridNoRecords
          :error="error" :is-loading="isLoading" :is-error="isError"
          no-records-message="No projects found"
        />
      </GridNoRecords>
      <template #actions="{ props }">
        <td>
          <KodexaButton
            icon="download" name="download" type="secondary" size="small"
            @click="downloadContentObject(props.dataItem)"
          >
            Download
          </KodexaButton>
        </td>
      </template>
    </Grid>
  </div>
</template>
