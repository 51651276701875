<script setup lang="ts">

import { PropType } from "vue";
import { Channel, Message, MessageBlock } from "~/model";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps({
  message: {
    type: Object as PropType<Message>,
    required: true,
  },
  channel: {
    type: Object as PropType<Channel>,
    required: false,
  },
  messageBlock: {
    type: Object as PropType<MessageBlock>,
    required: true,
  },
});

async function addToTaxonomy() {
  const {contentTaxonomies} = storeToRefs(appStore.projectStore);
  if (contentTaxonomies.value.length === 1) {
    const localContentTaxonomy = {...contentTaxonomies.value[0]};
    if (props.messageBlock.properties) {

      localContentTaxonomy.taxons = [...localContentTaxonomy.taxons, ...props.messageBlock.properties.taxons];
      await appStore.projectStore.uploadLocalTaxonomy(localContentTaxonomy);
      appStore.platformStore.currentSidebar = "taxonomy";
    }
  } else {
    // TODO
  }
}

onMounted(() => {

  if (props.messageBlock.properties?.taxons) {
    const tempTaxonomy = {
      name: "Temp Taxonomy",
      description: "Temp Taxonomy",
      type: "taxonomy",
      taxonomyType: "CONTENT",
      enabled: true,
      slug: `temp-taxonomy-${props.message.id}`,
      taxons: props.messageBlock.properties.taxons,
    };

    // We need to make sure we don't get the readonly object
    appStore.projectStore.addTempContentTaxonomy(props.message.id, JSON.parse(JSON.stringify(tempTaxonomy)));
  }
});

onUnmounted(() => {
  appStore.projectStore.removeTempContentTaxonomy(props.message.id);
});
</script>

<template>
  <div class="mt-2 mb-2">
    <TaxonTree :taxons="messageBlock.properties.taxons"/>
    <KodexaButton type="secondary" size="small" icon="plus" class="mt-2" @click="addToTaxonomy">
      Add to Taxonomy
    </KodexaButton>
    <KodexaMarkdown :content="props.messageBlock.properties?.additionalInformation"/>
  </div>
</template>

<style scoped>
</style>
