<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { Editor, ProseMirror } from "@progress/kendo-vue-editor";
import { notify } from "notiwind";
import {createProduct, useListProducts} from "~/api/product-overview/product-overview";
import { useListPlatformUsers } from "~/api/users/users";
import { useListProductGroups } from "~/api/product-groups/product-groups";
import type { Product, ProductGroup } from "~/model";
import { updateHandler } from "~/utils/error-handler";
import { ref } from 'vue';

import {useListProjectTemplatesForOrganization} from "~/api/project-templates/project-templates";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue", "refetchProduct"]);

const {
  data,
} = useListProductGroups({ pageSize: 20 });

const {
  data: parentProducts,
} = useListProducts({ pageSize: 20 });

const {
  data: projectTemplate
} = useListProjectTemplatesForOrganization( "kodexa", {pageSize: 20})

const newProductPlaceholder = () => ({
  name: "",
  description: "",
  overviewMarkdown: "",
  priceId: "",
  price: undefined,
  productGroup: undefined,
  parent: undefined,
  hasQuantity: false,
  active: true,
  numberOfCredits: undefined,
});

const newProduct = ref(newProductPlaceholder());

const requiredFields = ["name", "price", "priceId", "productGroup"];
const requiredFieldsError = ref({});

const newProductGroup = ref("");

const parentProduct = ref("");

watchDebounced(() => newProductGroup, (newValue) => {
  if (newValue) {
    const productGroup = data?.value?.content?.find((productGroup: ProductGroup) => productGroup.name === newValue.value);
    newProduct.value.productGroup = productGroup || {};
    clearError("productGroup");
  }
}, {
  debounce: 1000,
  maxWait: 500,
  deep: true,
  immediate: true,
});

watchDebounced(() => parentProduct, (newValue) => {
  if (newValue) {
    const product = parentProducts?.value?.content?.find((product: Product) => product.name === newValue.value);
    newProduct.value.parent = product || undefined;
  }
}, {
  debounce: 1000,
  maxWait: 500,
  deep: true,
  immediate: true,
});

function close() {
  newProduct.value = newProductPlaceholder();
  newProductGroup.value = "";
  requiredFieldsError.value = {};
  emit("update:modelValue", false);
}

async function createNewProduct() {
  requiredFields.forEach((field) => {
    const value = newProduct.value[field];
    if (!value || (typeof value === "object" && Object.keys(value).length === 0)) {
      requiredFieldsError.value[field] = "This is required";
    } else if (requiredFieldsError.value[field]) {
      delete requiredFieldsError.value[field];
    }
  });

  if (Object.keys(requiredFieldsError.value).length !== 0) {
    return;
  }
  await updateHandler(createProduct(newProduct.value as Product), "Product created successfully");

  close();
  emit("refetchProduct");
}
function clearError(field) {
  const value = newProduct.value[field];
  if ((value && typeof value !== "object") || Object.keys(value).length !== 0) {
    if (requiredFieldsError.value[field]) {
      delete requiredFieldsError.value[field];
      requiredFieldsError.value = { ...requiredFieldsError.value };
    }
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0" leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="max-h-full flex-1 overflow-y-auto">
                    <!-- Header -->
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            New Product
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Get started by filling in the information below to create a new product.
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              id="cancelNewProduct" icon="cancel" type="secondary" size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="createNewProduct" icon="plus" type="primary" size="small"
                              @click="createNewProduct"
                            >
                              Create
                            </KodexaButton>
                          </div>
                        </div>
                        <!--                        <div class="flex h-7 items-center"> -->
                        <!--                          <button type="button" class="text-gray-400 hover:text-gray-500" @click="close"> -->
                        <!--                            <span class="sr-only">Close panel</span> -->
                        <!--                            <MaterialDesignIcon name="close" class="size-6" aria-hidden="true" /> -->
                        <!--                          </button> -->
                        <!--                        </div> -->
                      </div>
                    </div>
                    <div class="min-h-0 flex-1 overflow-y-auto">
                      <!-- Divider container -->
                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <!-- Product Name -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="product-name"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Product
                              Name</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextInput id="product-name" v-model="newProduct.name" name="name" :errors="requiredFieldsError" @input="clearError('name')" />
                          </div>
                        </div>

                        <!-- Product Description -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="product-desc"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Description</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextArea id="product-desc" v-model="newProduct.description" rows="2" name="product-desc" />
                          </div>
                        </div>

                        <!-- Product Overview Markdown -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="overview-markdown"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Overview
                              Markdown</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaMarkdownEditor v-model="newProduct.overviewMarkdown" name="overview-markdown" />
                          </div>
                        </div>
                      </div>

                      <!-- Product Price -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                        <div>
                          <label
                            for="product-price"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Price</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextInput
                            id="product-price" v-model="newProduct.price" name="price" :errors="requiredFieldsError" @input="clearError('price')"
                          />
                        </div>
                      </div>
                      <!-- Product Price ID -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                        <div>
                          <label
                            for="product-priceID"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Stripe Price ID</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextInput id="product-priceID" v-model="newProduct.priceId" name="priceId" :errors="requiredFieldsError" @input="clearError('priceId')"/>
                        </div>
                      </div>
                      <!-- Has Quantity -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                        <div>
                          <label for="hasQuantity" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">HasQuantity</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaCheckbox
                            v-model="newProduct.hasQuantity"
                            name="hasQuantity"
                            label=""
                          />
                        </div>
                      </div>

                      <!-- Product Number of Credits -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                        <div>
                          <label
                            for="product-numberOfCredits"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Number of Credits</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaNumericInput id="product-numberOfCredits" v-model="newProduct.numberOfCredits" name="numberOfCredits"/>
                        </div>
                      </div>

                      <!-- Product Group -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                        <div>
                          <label
                            for="product-name"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Product Group</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaAutocompleteInput
                            id="product-group" v-model="newProductGroup"
                            text-field="name" name="productGroup" class="text-sm" :items="data.content || []" :errors="requiredFieldsError"
                          />
                        </div>
                      </div>

                      <!-- Parent Product -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                        <div>
                          <label
                            for="parent-product"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >Parent Product</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaAutocompleteInput
                            id="product-group" v-model="parentProduct"
                            text-field="name" name="parentProduct" class="text-sm" :items="parentProducts.content || []" :errors="requiredFieldsError"
                          />
                        </div>
                      </div>

                      <!-- Status -->
                      <div class="mb-10 space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                        <div>
                          <label for="isActive" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">is Active</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaCheckbox
                            v-model="newProduct.active"
                            name="isActive"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
