import { defineStore } from "pinia";
import { customAxios } from "~/api/custom-axios";
import { log } from "~/utils/logger";
import ls from 'localstorage-slim';
import { RefHelper } from "~/utils/ref-utils";


interface ChangeTracked {
  changeSequence: number
}

export const useCache = defineStore("metadataCache", () => {

  /**
   * We will store metadata in the cache using a URI which will be
   * <type>:://orgSlug/slug/version or <type>://id
   */
  let cache = new Map<string, any>();
  /**
   * We will also have a list of URIs with the last sequence
   * number we have seen for that URI
   */
  let cacheSequence = new Map<string, number>();

  /**
   * We will also have a list of URIs that we are monitoring
   */
  let monitoredUris = [] as string[];

  /**
   * Initialize from local store
   */
  function loadFromLocalStore() {
    const cacheData = ls.get('cache') as string;
    if (cacheData) {
      const parsedArray = JSON.parse(cacheData);
      cache = new Map(parsedArray);
    }
    const cacheSequenceData = ls.get('cacheSequence') as string;
    if (cacheSequenceData) {
      const parsedArray = JSON.parse(cacheSequenceData);
      cacheSequence = new Map(parsedArray);
    }
  }

  function saveToLocalStore() {
    ls.set('cache', JSON.stringify(Array.from(cache.entries())));
    ls.set('cacheSequence', JSON.stringify(Array.from(cacheSequence.entries())));
  }

  const hasObject = (uri: string) => {
    uri = new RefHelper(uri).getUri();
    return cache.has(uri);
  }

  const setSequence = (uri: string, sequence: number) => {
    uri = new RefHelper(uri).getUri();
    cacheSequence.set(uri, sequence);
    saveToLocalStore();
  }

  const getObject = async (uri: string) => {
    const refHelper = new RefHelper(uri);
    if (!hasObject(refHelper.getUri())) {
      log.info("Cache miss for " + refHelper.getUri())
      await loadObject(refHelper.getUri());
    } else {
      // We need to change the change sequence to make sure we have the latest
      // metadata
      log.info(`Checking for updates to ${uri}`);
      const sequence = getSequence(refHelper.getUri()) as number;
      if (refHelper.isMetadataObject()) {
        const response: any = await customAxios({
          method: "GET",
          url: `/api/${refHelper.getUrl()}/sequence`,
        });

        log.info(`Current sequence is ${sequence} and remote sequence is ${response}`);
        if (sequence === undefined || response > sequence) {
          const obj = await loadObject(refHelper.getUri()) as ChangeTracked;
          setSequence(refHelper.getUri(), obj.changeSequence);
        }
      }

      if (refHelper.isEntity()) {
        const response: any = await customAxios({
          method: "GET",
          url: `/api/${refHelper.getUrl()}/sequence`,
        });

        log.info(`Current sequence is ${sequence} and remote sequence is ${response}`);
        if (sequence === undefined || response > sequence) {
          const obj = await loadObject(refHelper.getUri()) as ChangeTracked;
          setSequence(refHelper.getUri(), obj.changeSequence);
        }
      }

    }
    return cache.get(refHelper.getUri());
  };

  const getSequence = (uri: string) => {
    return cacheSequence.get(uri);
  };

  const setObject = (uri: string, object: any) => {
    log.info(`Setting ${uri} in cache`)
    cache.set(uri, object);
    saveToLocalStore();
  };

  async function loadObject(uri: string) {
    const refHelper = new RefHelper(uri);

    log.info(`Loading ${refHelper}`)

    if (!refHelper.isEntity() && !refHelper.isMetadataObject()) {
      log.error(`Invalid object type ${refHelper.getUri()}`);
      throw new Error(`Invalid object type ${refHelper.getUri()} ${refHelper.getType()}`);
    }

    if (refHelper.isMetadataObject()) {
      log.info(`Loading ${refHelper.getType()} ${refHelper.getUri()} from metadata`);
      const response: any = await customAxios({
        method: "GET",
        url: `/api/${refHelper.getUrl()}`,

      });
      setObject(refHelper.getUri(), response);
      return response;
    }

    if (refHelper.isEntity()) {
      log.info(`Loading ${refHelper.getType()} ${refHelper.getUri()} from entity`);
      const response: any = await customAxios({
        method: "GET",
        url: `/api/${refHelper.getUrl()}`,

      });
      setObject(refHelper.getUri(), response);
      return response;
    }

    throw new Error(`Invalid object type ${refHelper.getUri()} ${refHelper.getType()}`);
  }

  function monitor(uri: string) {
    uri = new RefHelper(uri).getUri();
    monitoredUris.push(uri as string);
  }

  function disregard(uri: string) {
    uri = new RefHelper(uri).getUri();
    monitoredUris = monitoredUris.filter(u => u !== uri);
  }

  // Load it up
  loadFromLocalStore();

  function clearCache() {
    cache = new Map();
    cacheSequence = new Map();
    monitoredUris = []
    saveToLocalStore();
  }

  return {
    hasObject,
    getObject,
    getSequence,
    setObject,
    monitor,
    disregard,
    loadFromLocalStore,
    saveToLocalStore,
    clearCache,
  };


});
