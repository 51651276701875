<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type {PropType} from "vue";
import {storeToRefs} from "pinia";
import type {TagMetadata} from "~/store/useProject";
import type {DataAttribute, DataObject, SelectionOption} from "~/model";
import {createDataAttributeHelper} from "~/store/useDataAttributeHelper";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const stringValue = ref(props.attribute?.stringValue);

watch(stringValue, (newValue) => {
  emit("update", {
    ...props.attribute,
    stringValue: newValue,
    value: newValue,
  });
});

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);
const valid = computed(() => {
  return props.attribute?.dataExceptions?.length === 0;
});

const selectionOptions = computed(() => {
  const selectionOptions: SelectionOption[] | undefined = props.tagMetadata?.taxon.selectionOptions;
  if (!selectionOptions?.find(selection => selection.label === props?.attribute?.stringValue) && props.attribute?.stringValue) {
    selectionOptions.push({
      id: 1,
      label: props.attribute.stringValue,
      description: "current attribute string",
    } as SelectionOption);
  }

  return selectionOptions;
});

function focus() {
  dataViewHelper.focus();
  emit("focus");
}

function onChange(event: any) {
  stringValue.value = event.target.value;
}

const {style, conditionalValidationIcon} = storeToRefs(dataViewHelper);
</script>

<template>
  <div @click="focus">
    <KodexaDropDown
      :id="`kdx-attribute-${props.attribute.uuid}`"
      v-model="stringValue"
      :style="style"
      class="kodexa-input"
      :name="props.tagMetadata?.taxon.label"
      :items="selectionOptions"
      value-field="label"
      text-field="label"
      :valid="valid"
      :disabled="props.dataObject?.documentFamily.locked === true"
      @focus="focus"
      @on-change="onChange"

      @blur="dataViewHelper.blur()"
    />
  </div>
</template>

<style scoped>
</style>
