<script lang="ts" setup>
import type { PropType } from "vue";
import type { DocumentFamily } from "~/model";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: true,
  },
});

const pathCounts = computed(() => {
  if (props.documentFamily && props.documentFamily.statistics) {
    return props.documentFamily.statistics.pathCounts;
  } else {
    return [];
  }
});
</script>

<template>
  <div>
    <div v-for="path in pathCounts" :key="path.id">
      <span class="mb-1 mr-1 inline-flex items-center rounded bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-800">
        {{ path.label }} {{ path.count }}
      </span>
    </div>
  </div>
</template>

<style scoped>

</style>
