import { DataObject } from "~/model";

/**
 * Builds simplified data objects by creating a hierarchical structure based on parent-child relationships.
 *
 * @param {DataObject[]} data - The input array of data objects.
 * @returns {any[]} - The array of root elements with the hierarchical structure.
 */
export function buildSimplifiedDataObjects(data: DataObject[]) {
  const parentToChildren: Map<string, any> = new Map();

  // Step 1: Identify Root Elements
  let rootElements: any[] = [];

  // Step 2: Create Parent-Children Mapping
  data.forEach(obj => {
    const parentId = obj.parent?.uuid; // Replace with the correct parent identifier
    if (parentId && !parentToChildren.has(parentId)) {
      parentToChildren.set(parentId, []);
    }
    if (parentId) {
      const attributes = {};
      obj.attributes?.forEach((attr: any) => {
        if (attr.tag in attributes) {
          attributes[attr.tag].push(attr.value);
        } else {
          attributes[attr.tag] = [attr.value];
        }
      });
      parentToChildren.get(parentId)!.push({
        id: obj.uuid,
        path: obj.path?.split("/").pop(),
        attributes,
      });
    }
  });

  // Step 3: Recursive Function to Build Hierarchical Structure
  function build(parentId: string): any[] {
    if (!parentToChildren.has(parentId)) {
      return [];
    }
    const children = parentToChildren.get(parentId)!;
    children.forEach(child => {
      child.children = build(child.uuid);
    });
    return children;
  }

  // Step 4: Build Hierarchical Structure from Root Elements
  rootElements = data.filter(obj => !obj.path?.includes('/')).map(obj => {
    const attributes = {};
    obj.attributes?.forEach((attr: any) => {
      if (attr.tag in attributes) {
        attributes[attr.tag].push(attr.value);
      } else {
        attributes[attr.tag] = [attr.value];
      }
    });
    return {
      id: obj.uuid,
      path: obj.path,
      attributes,
      children: obj.uuid ? build(obj.uuid) : [],
    };
  });

  return rootElements;
}

export function ensureNumber(value: any): number | undefined {
  // If the value is already a number, return it directly
  if (typeof value === 'number') {
    return value;
  }

  // Attempt to convert the value to a number
  const converted = Number(value);

  // Check if the conversion resulted in a valid number
  if (isNaN(converted)) {
    return undefined;
  }

  return converted;
}
