<script lang="ts" setup>
import type {PropType} from "vue";
import appStore from "~/store";

const props = defineProps({
  keyName: {
    type: String,
    required: true,
  },
  node: {
    type: Object as PropType<Node>,
  },
});

function openPrompt() {
  appStore.workspaceStore.createTextViewer(`${props.node.data.clean.label} Prompt`, props.node.data.clean.prompt);
}
</script>

<template>
  <KodexaButton name="openPrompt" size="small" style="width: 180px" icon="open-in-new" @click="openPrompt">
    View Prompt
  </KodexaButton>
</template>

<style scoped>

</style>
