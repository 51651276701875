/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  Kiosk
} from '../../model/kiosk'
import type {
  ListKiosksParams
} from '../../model/listKiosksParams'
import type {
  PageKiosk
} from '../../model/pageKiosk'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getKiosk = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Kiosk>(
      {url: `/api/kiosks/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetKioskQueryKey = (id: MaybeRef<string>,) => {
    return ['api','kiosks',id] as const;
    }

    
export const getGetKioskQueryOptions = <TData = Awaited<ReturnType<typeof getKiosk>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKiosk>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetKioskQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getKiosk>>> = ({ signal }) => getKiosk(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getKiosk>>, TError, TData> 
}

export type GetKioskQueryResult = NonNullable<Awaited<ReturnType<typeof getKiosk>>>
export type GetKioskQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetKiosk = <TData = Awaited<ReturnType<typeof getKiosk>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getKiosk>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetKioskQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateKiosk = (
    id: MaybeRef<string>,
    kiosk: MaybeRef<Kiosk>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
kiosk = unref(kiosk);
      
      return customAxios<Kiosk>(
      {url: `/api/kiosks/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: kiosk
    },
      options);
    }
  


export const getUpdateKioskMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateKiosk>>, TError,{id: string;data: Kiosk}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateKiosk>>, TError,{id: string;data: Kiosk}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateKiosk>>, {id: string;data: Kiosk}> = (props) => {
          const {id,data} = props ?? {};

          return  updateKiosk(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateKioskMutationResult = NonNullable<Awaited<ReturnType<typeof updateKiosk>>>
    export type UpdateKioskMutationBody = Kiosk
    export type UpdateKioskMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateKiosk = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateKiosk>>, TError,{id: string;data: Kiosk}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateKioskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteKiosk = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/kiosks/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteKioskMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteKiosk>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteKiosk>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteKiosk>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteKiosk(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteKioskMutationResult = NonNullable<Awaited<ReturnType<typeof deleteKiosk>>>
    
    export type DeleteKioskMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteKiosk = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteKiosk>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteKioskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listKiosks = (
    params?: MaybeRef<ListKiosksParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageKiosk>(
      {url: `/api/kiosks`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListKiosksQueryKey = (params?: MaybeRef<ListKiosksParams>,) => {
    return ['api','kiosks', ...(params ? [params]: [])] as const;
    }

    
export const getListKiosksQueryOptions = <TData = Awaited<ReturnType<typeof listKiosks>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListKiosksParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listKiosks>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListKiosksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listKiosks>>> = ({ signal }) => listKiosks(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listKiosks>>, TError, TData> 
}

export type ListKiosksQueryResult = NonNullable<Awaited<ReturnType<typeof listKiosks>>>
export type ListKiosksQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListKiosks = <TData = Awaited<ReturnType<typeof listKiosks>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListKiosksParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listKiosks>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListKiosksQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createKiosk = (
    kiosk: MaybeRef<Kiosk>,
 options?: SecondParameter<typeof customAxios>,) => {
      kiosk = unref(kiosk);
      
      return customAxios<Kiosk>(
      {url: `/api/kiosks`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: kiosk
    },
      options);
    }
  


export const getCreateKioskMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createKiosk>>, TError,{data: Kiosk}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createKiosk>>, TError,{data: Kiosk}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createKiosk>>, {data: Kiosk}> = (props) => {
          const {data} = props ?? {};

          return  createKiosk(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateKioskMutationResult = NonNullable<Awaited<ReturnType<typeof createKiosk>>>
    export type CreateKioskMutationBody = Kiosk
    export type CreateKioskMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateKiosk = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createKiosk>>, TError,{data: Kiosk}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateKioskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForKiosk = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/kiosks/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForKioskQueryKey = (id: MaybeRef<string>,) => {
    return ['api','kiosks',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForKioskQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForKiosk>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForKiosk>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForKioskQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForKiosk>>> = ({ signal }) => getChangeSequenceForKiosk(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForKiosk>>, TError, TData> 
}

export type GetChangeSequenceForKioskQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForKiosk>>>
export type GetChangeSequenceForKioskQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForKiosk = <TData = Awaited<ReturnType<typeof getChangeSequenceForKiosk>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForKiosk>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForKioskQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



