<script setup lang="ts">
import type { PropType } from "vue";
import type { DataAttribute, DataObject } from "~/model";
import { RefHelper } from "~/utils/ref-utils";
import { getPreviewForAttributeTagMetadata } from "~/api/stores/stores";

const props = defineProps({
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  viewId: {
    type: String,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: false,
  },
});

const attributeImage = ref<string>("");
if (props.dataObject && props.attribute) {
  const refHelper = new RefHelper(props.dataObject.storeRef);
  getPreviewForAttributeTagMetadata(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), props.dataObject.id as string, props.attribute.id as string, { size: 'full-width' }).then((blob) => {
    blob.arrayBuffer().then((arrayBuffer) => {
      const chunkSize = 0xffff; // You can play with this value to find the best one for your case
      const array = new Uint8Array(arrayBuffer);
      let result = '';
      for (let i = 0; i < array.length; i += chunkSize) {
        const subArray = array.subarray(i, i + chunkSize);
        result += String.fromCharCode.apply(null, subArray);
      }
      attributeImage.value = btoa(result);
    });
  });
}
</script>

<template>
  <div>
    <img
      v-if="attributeImage"
      class="ml-1 mr-2 shadow" :src="`data:image/png;base64,${attributeImage}`" width="600"
      alt="Attribute Preview"
    >
    <span v-else>Loading preview....</span>
  </div>
</template>

<style scoped>

</style>
