<script setup lang="ts">
import { storeToRefs } from "pinia";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import type { Execution } from "~/model";
import { getExecution } from "~/api/executions/executions";
import appStore from "~/store";
import KodexaExceptionDetailsPopup from "~/components/document/kodexa-exception-details-popup.vue";

const props = defineProps({
  executionId: {
    type: String,
    required: true,
  },
  documentFamily: {
    type: Object,
    required: true,
  },
});

const execution = ref(undefined);

function loadExecution() {
  getExecution(props.executionId).then((response) => {
    execution.value = response;
    setTimeout(() => {
      loadExecution();
    }, 5000);
  });
}

loadExecution();

const pipeline = computed(() => {
  if (execution.value && execution.value.pipeline && execution.value.pipeline.steps) {
    return execution.value.pipeline.steps;
  }
  return [];
});

const { user } = storeToRefs(appStore.userStore);

function borderColorClass(status: string) {
  switch (status) {
    case "SUCCEEDED":
      return "border-blue-600 hover:border-blue-800";
    case "RUNNING":
      return "border-green-300";
    case "FAILED":
    case "CANCELED":
      return "border-red-600 hover:border-red-800";
    default:
      return "border-gray-600 hover:border-gray-800";
  }
}

function openLogs(execution: Execution) {
  // Open a text viewer with the logs
  appStore.workspaceStore.createTextViewer(`${props.documentFamily.path} (${execution.id}).txt`, "", execution.id);
}

function openExceptionDetails() {
  const exceptionDetailsPopup = createConfirmDialog(KodexaExceptionDetailsPopup);
  exceptionDetailsPopup.reveal({
    exceptionDetails: execution.value.exceptionDetails,
  });
}

const showSkipped = ref(false);

const steps = computed(() => {
  if (execution.value && execution.value.pipeline && execution.value.pipeline.steps) {
    if (showSkipped.value) {
      return execution.value.pipeline.steps;
    }
    return execution.value.pipeline.steps.filter(step => step.status !== "SKIPPED");
  }
  return [];
});

const skipped = computed(() => {
  if (execution.value && execution.value.pipeline && execution.value.pipeline.steps) {
    return execution.value.pipeline.steps.filter(step => step.status === "SKIPPED");
  }
  return [];
});
</script>

<template>
  <div v-if="execution" class="mt-8">
    <div v-if="execution.loggingEnabled && user.showDeveloperTools" class="mt-2">
      <div class="text-xs text-gray-500 open-logs-link cursor-pointer" @click="openLogs(execution)">
        Open Logs
      </div>
    </div>
    <div v-if="skipped.length" class="mt-2">
      <div class="text-xs text-gray-500" @click="showSkipped = !showSkipped">
        {{ skipped.length }} skipped steps ({{ showSkipped ? "hide" : "show" }})
      </div>
    </div>
    <div
      v-if="(execution.exceptionDetails || execution.status === 'FAILED' || execution.status === 'CANCELED') && user.showDeveloperTools"
      id="openExceptionDetails"
      class="cursor-pointer text-xs mt-2"
      @click="openExceptionDetails"
    >
      <div class="text-xs text-gray-500 open-logs-link" @click="openLogs(execution)">
        Show Exception Details
      </div>
    </div>
    <div v-if="steps.length" class="mt-8 flex space-x-4 overflow-x-auto">
      <ol class="space-y-4 md:flex md:space-x-8 md:space-y-0">
        <li v-for="step in steps" :key="step.id" class="md:flex-1">
          <div :class="`border-t-2 ${borderColorClass(step.status)}`" />
          <p class="text-xs">
            {{ step.name }}
          </p>
          <p class="mt-2 text-xs text-gray-500">
            {{ step.status }}
          </p>
          <div
            v-if="step.progress && step.maxProgress"
            class="mb-4 h-1.5 w-full rounded-full bg-gray-200 dark:bg-gray-700"
          >
            <div
              class="h-1.5 rounded-full bg-blue-600 dark:bg-blue-500"
              :style="{ width: `${((step.progress / step.maxProgress) * 100)}%` }"
            />
          </div>
        </li>
      </ol>
    </div>
    <KodexaClipboardable
      v-if="user.showDeveloperTools && execution"
      class="mt-4 text-xs" :content="JSON.stringify(execution)"
      message="Copy Metadata" :show-content="false" :hidden="false"
    />

    {{ execution.parentId }}
  </div>
</template>

<style scoped>
.open-logs-link {
  color: #007bff; /* Change to your preferred link color */
  text-decoration: underline;
  cursor: pointer;
}

.open-logs-link:hover {
  color: #0056b3; /* Darker shade for hover effect */
}
</style>
