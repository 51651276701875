<script lang="ts" setup>
import type { PropType } from "vue";
import KodexaClipboardable from "~/components/util/kodexa-clipboardable.vue";

const props = defineProps({
  keyName: {
    type: String,
    required: true,
  },
  node: {
    type: Object as PropType<Node>,
  },
});

const value = computed(() => {
  // Filter the label out of the node data
  const { label, ...rest } = props.node?.data.clean;

  // Make sure that all the values in rest are strings
  for (const key in rest) {
    if (typeof rest[key] !== "string") {
      rest[key] = JSON.stringify(rest[key]);
    }
  }
  return rest[props.keyName];
});
</script>

<template>
  <dt class="text-gray-500">
    {{ keyName }}
  </dt>
  <dd class="text-gray-700">
    <KodexaClipboardable :content="value" :trim="true" />
  </dd>
</template>

<style scoped>

</style>
