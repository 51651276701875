<script lang="ts" setup>
import type { PropType } from "vue";
import type { ExtensionPack } from "~/model";
import { getExtensionPackStatus } from "~/api/extension-packs/extension-packs";
import { RefHelper } from "~/utils/ref-utils";

const props = defineProps({
  extensionPack: {
    type: Object as PropType<ExtensionPack>,
    required: true,
  },
});

const currentStatus = ref();

watch(() => props.extensionPack, async (newValue) => {
  if (newValue) {
    const refHelper = new RefHelper(newValue.ref);
    currentStatus.value = await getExtensionPackStatus(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion());
  }
}, { immediate: true });
</script>

<template>
  <div v-if="currentStatus">
    <p>Pending : {{ currentStatus.pending }}</p>
    <p>Running : {{ currentStatus.running }}</p>
  </div>
</template>

<style scoped>

</style>
