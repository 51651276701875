<script setup lang="ts">
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  size: {
    type: String,
    required: false,
    default: "24",
  },
  tooltip: {
    type: String,
    required: false,
    default: undefined,
  },
  classes: {
    type: Array,
    required: false,
    default: () => ([]),
  },
  spin: {
    type: Boolean,
    required: false,
    default: false,
  },
  color: {
    type: String,
    required: false,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const MDIList: {
  [key: string]: string;
} = {
  ACCOUNT: "account-cog",
  ACCESSTOKEN: "credit-card-lock",
  ABOUT: "information",
  ACTIVITY: "file-clock",
  PLUS: "plus",
  MINUS: "minus",
  OPEN: "folder-open",
  ALERTBOX: "alert-box",
  BACK: "keyboard-backspace",
  SETTINGS: "cogs",
  CHECK: "check",
  CLOSE: "close",
  UPARROW: "chevron-up",
  DOWNARROW: "chevron-down",
  LEFTARROW: "chevron-left",
  RIGHTARROW: "chevron-right",
  DATAFLOW: "ray-start-arrow",
  DATAFLOWASSISTANTNODE: "vector-circle-variant",
  DATAFLOWDATASTORENODE: "database-outline",
  DATAFLOWDOCSTORENODE: "file-cabinet",
  DATAFLOWMODELNODE: "school-outline",
  DATAFLOWTAXONOMYNODE: "graph-outline",
  DATAFORM: "form-select",
  DATASTORE: "table-multiple",
  DELETE: "delete",
  MINUSCIRCLE: "minus-circle",
  DOCUMENT: "file-pdf-box",
  DOCUMENTSTORE: "file-document",
  DOCUMENTSTOREOPERATIONAL: "folder-sync",
  DOCUMENTSTORETRAINING: "folder-wrench",
  ERROR: "close-circle-outline",
  EXCEPTIONS: "alert",
  EXPORTS: "export-variant",
  FOLDER: "folder-outline",
  FORMTEXTBOX: "form-textbox",
  GENERAL: "office-building-cog",
  HELP: "help",
  HOME: "home",
  INBOX: "inbox",
  INSIGHTS: "lightbulb",
  MANAGE: "folder-cog",
  MARKETPLACE: "cart",
  MODEL: "robot",
  AVAILABLE: "check-circle",
  AUDITTRAIL: "history",
  MONITOR: "monitor-dashboard",
  NAVIGATION: "directions",
  NEWS: "text-box-multiple",
  NOTES: "note-text-outline",
  DOCUMENTNOTES: "note-multiple-outline",
  NOTIFICATIONS: "bell-outline",
  ORGANIZATION: "office-building",
  PASSWORD: "key",
  PROJECT: "briefcase",
  PROJECTDOC: "archive-plus",
  PROPERTIES: "tune",
  RECENT: "history",
  SEARCH: "magnify",
  SIDECAR: "monitor-multiple",
  SUCCESSCIRCLE: "check-circle",
  TAG: "tag",
  TEAM: "account-group",
  USER: "account",
  WORKSPACEDOC: "archive",
  CANCELLED: "cancel",
  CANCELLING: "cancel",
  DEPLOYING: "loading",
  UNDEPLOYED: "cancel",
  RUNNING: "loading",
  PENDING_REPROCESSING: "loading",
  SUCCEEDED: "check-circle-outline",
  FAILED: "alert-circle-outline",
  PENDING: "loading",
  TABLE: "database",
  CHAT: "chat",
  THREEDOTMENU: "dots-vertical",
  COMPUTER: "laptop",
  DOWNLOAD: "cloud-download",
  COPY: "content-copy",
  LOCKED: "lock",
  UPDOWN: "unfold-more-horizontal",
  BROKEN_IMAGE: "image-broken-variant",
  REFRESH: "refresh",
  ASSISTANT: "assistant",
  WARNING: "alert-outline",
};

// Make sure that everything is computed so it is reactive
const MDIProp = computed(() => {
  return props.name?.toUpperCase();
});

const finalIcon = computed(() => {
  return `mdi-${MDIList[MDIProp.value] ? MDIList[MDIProp.value] : props.name}`;
});

const style = computed(() => {
  const innerStyle = { "font-size": `${props.size}px` };
  if (props.color) {
    innerStyle.color = props.color;
  }
  return innerStyle;
});
</script>

<template>
  <a class="icon">
    <i
      class="mdi" :class="[finalIcon, ...props.classes, spin ? ['mdi-spin'] : []]"
      :style="style"
    />
  </a>
</template>
