<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import {ref, computed, watch, PropType} from 'vue';
import {useDeleteNotification, useUpdateNotification} from "~/api/notifications/notifications";
import { NotificationActivityType } from "~/model/notificationActivityType";
import { NotificationNotificationType } from "~/model/notificationNotificationType";
import type { Notification } from "~/model/notification";
import { updateHandler } from "~/utils/error-handler";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import {notify} from "notiwind";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  notification: {
    type: Object as PropType<Notification>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "refetch-notifications", "notification-deleted"]);

const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit("update:modelValue", value),
});

const editedNotification = ref(
  props.notification
    ? JSON.parse(JSON.stringify(props.notification)) as typeof props.notification
    : {} as typeof props.notification
)

const notificationProperties = ref(
  props.notification && props.notification.properties
    ? JSON.parse(JSON.stringify(props.notification.properties))
    : {}
);


// Refs for activityType and notificationType
const selectedActivityType = ref<string | undefined>(undefined);
const selectedNotificationType = ref<string | undefined>(undefined);

const requiredFieldsError = ref<Record<string, string>>({});

const updateNotificationMutation = useUpdateNotification();

function close() {
  requiredFieldsError.value = {};
  emit("update:modelValue", false);
}

const requiredFields = [
  "activityType",
  "notificationType",
  "name",
  "properties.recipients",
  "properties.subject",
  "properties.body"
];

async function updateNotification() {
  requiredFields.forEach((field) => {
    const value = field.split('.').reduce((obj, key) => obj?.[key], editedNotification.value);
    if (!value) {
      requiredFieldsError.value[field] = "This field is required";
    } else {
      delete requiredFieldsError.value[field];
    }
  });

  if (Object.keys(requiredFieldsError.value).length !== 0) {
    return;
  }

  try {
    await updateNotificationMutation.mutateAsync({
      id: editedNotification.value.id as string,
      data: editedNotification.value
    });
    updateHandler(Promise.resolve(), "Notification updated successfully");
    emit("refetch-notifications");
    close();
  } catch (error) {
    console.error("Error updating notification:", error);
    updateHandler(Promise.reject(error), "Failed to update notification");
  }
}

function clearError(field: string) {
  if (requiredFieldsError.value[field]) {
    delete requiredFieldsError.value[field];
    requiredFieldsError.value = { ...requiredFieldsError.value };
  }
}

const showSubscriptionField = computed(() => {
  return editedNotification.value.activityType === NotificationActivityType.CUSTOM_SUBSCRIPTION;
});

const showIncludeAssigneeField = computed(() => {
  return (
    editedNotification.value.activityType === NotificationActivityType.ASSIGNMENT_ADDED ||
    editedNotification.value.activityType === NotificationActivityType.ASSIGNMENT_REMOVED
  );
});


const activityTypeMap = {
  "New Upload": NotificationActivityType.NEW_UPLOAD,
  "New Data Available": NotificationActivityType.NEW_DATA_AVAILABLE,
  "Assignment Added": NotificationActivityType.ASSIGNMENT_ADDED,
  "Assignment Removed": NotificationActivityType.ASSIGNMENT_REMOVED,
  "Custom Subscription": NotificationActivityType.CUSTOM_SUBSCRIPTION,
};

const notificationTypeMap = {
  "Email Notification": NotificationNotificationType.EMAIL_NOTIFICATION,
};

const activityTypeItems = Object.keys(activityTypeMap).map((key) => ({
  name: key,
  value: activityTypeMap[key as keyof typeof activityTypeMap],
}));

const notificationTypeItems = Object.keys(notificationTypeMap).map((key) => ({
  name: key,
  value: notificationTypeMap[key as keyof typeof notificationTypeMap],
}));

// Initialize selectedActivityType and selectedNotificationType
watch(() => props.notification, (newValue) => {
  if (newValue) {
    selectedActivityType.value = Object.keys(activityTypeMap).find(
      key => activityTypeMap[key as keyof typeof activityTypeMap] === newValue.activityType
    );
    selectedNotificationType.value = Object.keys(notificationTypeMap).find(
      key => notificationTypeMap[key as keyof typeof notificationTypeMap] === newValue.notificationType
    );
    editedNotification.value = {
      ...newValue,
      properties: notificationProperties.value
    };
  }
}, { immediate: true });

watch(selectedActivityType, (newValue) => {
  editedNotification.value.activityType = activityTypeMap[newValue as keyof typeof activityTypeMap];
});

watch(selectedNotificationType, (newValue) => {
  editedNotification.value.notificationType = notificationTypeMap[newValue as keyof typeof notificationTypeMap];
});

const deleteNotificationMutation = useDeleteNotification();

async function deleteNotification() {
  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const { isCanceled } = await deleteConfirmDialog.reveal({
    title: `Delete notification ${editedNotification.value.name}?`,
    message: "This action cannot be undone.",
  });

  if (!isCanceled) {
    try {
      await deleteNotificationMutation.mutateAsync({ id: editedNotification.value.id as string });
      notify({
        group: "generic",
        title: "Success",
        text: "Notification deleted successfully",
      }, 3000);
      emit("notification-deleted", editedNotification.value.id); // Emit this new event
      close();
    } catch (error) {
      console.error("Error deleting notification:", error);
      updateHandler(Promise.reject(error), "Failed to delete notification");
    }
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0"/>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0" leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="max-h-full flex-1 overflow-y-auto">
                    <!-- Header -->
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            Update Email Notification
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Change the details required and then choose Save Changes
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              id="cancelEditNotification" icon="cancel" type="secondary" size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="updateNotification" icon="content-save" type="primary" size="small"
                              @click="updateNotification"
                            >
                              Save Changes
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="min-h-0 flex-1 overflow-y-auto">
                      <!-- Divider container -->
                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <!-- Name -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="name"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Name</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="name"
                              v-model="editedNotification.name"
                              name="name"
                              :errors="requiredFieldsError"
                              @input="clearError('name')"
                            />
                          </div>
                        </div>
                        <!-- Activity Type -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                          <div>
                            <label
                              for="activity-type"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Activity Type</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaAutocompleteInput
                              id="activity-type"
                              v-model="selectedActivityType"
                              :items="activityTypeItems"
                              text-field="name"
                              value-field="value"
                              name="activityType"
                              class="text-sm"
                              :errors="requiredFieldsError"
                              @input="clearError('activityType')"
                            />
                          </div>
                        </div>
                        <!-- Notification Type -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                          <div>
                            <label
                              for="notification-type"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Notification Type</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaAutocompleteInput
                              id="notification-type"
                              v-model="selectedNotificationType"
                              :items="notificationTypeItems"
                              text-field="name"
                              value-field="value"
                              name="notificationType"
                              class="text-sm"
                              :errors="requiredFieldsError"
                              @input="clearError('notificationType')"
                            />
                          </div>
                        </div>
                        <!-- Subscription Field -->
                        <div v-if="showSubscriptionField"
                             class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="notif-subscription"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Subscription</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextArea
                              id="notif-subscription"
                              v-model="editedNotification.subscription"
                              name="subscription"
                              :rows="8"
                            />
                            <KodexaArticle class="mb-5 mt-3" article-id="9743955" text="Learn more about custom subscriptions" :slide="false" />
                          </div>
                        </div>
                        <!-- Recipient -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="recipients"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Recipient</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="recipients"
                              v-model="notificationProperties.recipients"
                              name="recipients"
                              :errors="{ recipients: requiredFieldsError['properties.recipients'] }"
                              @input="clearError('properties.recipients')"
                            />
                          </div>
                        </div>
                        <!-- Subject -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="subject"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Subject</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="subject"
                              v-model="notificationProperties.subject"
                              name="subject"
                              :errors="{ subject: requiredFieldsError['properties.subject'] }"
                              @input="clearError('properties.subject')"
                            />
                          </div>
                        </div>
                        <!-- Content -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label
                              for="body"
                              class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                            >Email Body</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextArea
                              id="email-body"
                              v-model="notificationProperties.body"
                              name="body"
                              :rows="8"
                              :errors="{ body: requiredFieldsError['properties.body'] }"
                              @input="clearError('properties.body')"
                            />
                          </div>
                        </div>
                        <!-- Include Assignee -->
                        <div v-if="showIncludeAssigneeField"
                             class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                          <div>
                            <label for="includeAssignee"
                                   class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Include Assignee</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaCheckbox
                              v-model="notificationProperties.includeAssignees"
                              name="includeAssignees"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- Delete Project Status -->
                      <div class="mb-6 border-2 border-red-200 bg-white shadow sm:m-6 sm:mt-3 sm:rounded-lg">
                        <div class=" px-4 py-5 sm:p-6">
                          <h3 class="text-base font-semibold leading-6 text-red-600">
                            Delete Email Notification
                          </h3>
                          <div class="mt-2 items-center justify-center sm:flex">
                            Are you sure you want to delete this email notification? This cannot be undone.
                            <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center">
                              <KodexaButton id="deleteNotification" type="danger" @click="deleteNotification">
                                Delete
                              </KodexaButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
