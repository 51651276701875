<script setup lang="ts">
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import type { Assistant, AssistantConnection } from "~/model";
import appStore from "~/store";

const props = defineProps({
  modelValue: {
    type: Object as PropType<AssistantConnection>,
    required: false,
  },
  assistant: {
    type: Object as PropType<Assistant>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const {
  documentStores,
  dataStores,
} = storeToRefs(appStore.projectStore);

const connectionTypes = [
  { name: "Workspace", value: "WORKSPACE", resource: false },
  { name: "Store", value: "STORE", resource: true },
  { name: "Channel", value: "CHANNEL", resource: false },
];

const localConnection = computed({
  get: () => {
    return props.modelValue;
  },
  set: (value) => {
    emit("update:modelValue", value);
    appStore.projectStore.saveAssistantConnection(value);
  },
});

const stores = computed(() => {
  return dataStores.value.concat(documentStores.value);
});
</script>

<template>
  <div>
    <div class="flex flex-col">
      <div class="flex flex-col">
        <div class="flex flex-row items-center">
          <div class="flex flex-col w-1/2">
            <label class="text-sm font-medium text-gray-700 dark:text-gray-300">Type</label>
            <KodexaDropDown
              v-model="localConnection.targetType"
              name="targetTypeDropdown"
              :items="connectionTypes"
              text-field="name"
              value-field="value"
            />
          </div>
        </div>
        <div v-if="localConnection.targetType === 'STORE'" class="flex flex-col">
          <label class="text-sm font-medium text-gray-700 dark:text-gray-300">Resource</label>
          <KodexaDropDown
            v-model="localConnection.targetRef"
            name="resourceDropdown"
            :items="stores"
            text-field="name"
            value-field="ref"
          />
          <KodexaButton @click="appStore.projectStore.removeConnection(localConnection)" type="danger" size="small" icon="delete" class="mt-2">
            Delete Connection
          </KodexaButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
