<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { PropType } from "vue/dist/vue";
import type { DashboardWidget, Store } from "~/model";
import appStore from "~/store";
import Kiosk from "~/views/kiosk/kiosk.vue";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

const emit = defineEmits(["update:widget"]);

const {
  documentStores,
} = storeToRefs(appStore.projectStore);

const workspaceId = ref("");
const dataFormRef = ref("");
const storeId = ref("");
const filters = ref("");
watch(
  () => props.widget,
  () => {
    if (!props.widget?.properties?.documentStoreRef || !props.widget?.properties?.dataFormRef || !props.widget?.properties?.workspace) {
      return;
    }
    workspaceId.value = props.widget.properties.workspace as unknown as string;
    dataFormRef.value = props.widget?.properties?.dataFormRef as unknown as string;
    const storeRef = props.widget?.properties?.documentStoreRef as unknown as string;
    storeId.value = documentStores.value.find((ds: Store) => ds.ref === storeRef)._id;
    filters.value = props.widget?.properties?.filters as unknown as string;
  },
  { immediate: true, deep: true },
);

function updateTitle(title: string) {
  const localWidget = JSON.parse(JSON.stringify(props.widget));
  localWidget.title = title;
  emit("update:widget", props.widget);
}
</script>

<template>
  <div v-if="documentStores && dataFormRef && storeId && workspaceId">
    <Kiosk :kiosk-options="{ workspaceId, dataFormRef, storeRef: storeId, filters, isWidget: true }" @update-title="updateTitle" />
  </div>
</template>

<style scoped>

</style>
