<script lang="ts" setup>
import type { PropType } from "vue";
import appStore from "~/store";

const props = defineProps({
  keyName: {
    type: String,
    required: true,
  },
  node: {
    type: Object as PropType<Node>,
  },
});

const title = computed(() => {
  if (props.keyName === "external_data") {
    return "External Data";
  }
  if (props.keyName === "final_llm_response") {
    return "Final LLM Response";
  }
  return "JSON Schema";
});

function openSchema() {
  appStore.workspaceStore.createTextViewer(`${props.node.data.clean.label} ${title.value}`, JSON.stringify(props.node.data.raw[props.keyName], null, 2));
}
</script>

<template>
  <KodexaButton name="openSchema" size="small" style="width: 180px" icon="open-in-new" @click="openSchema">
    View {{ title }}
  </KodexaButton>
</template>

<style scoped>

</style>
