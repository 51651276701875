export function getColorByBgColor(bgColor: string, lightColor = "#FFFFFF", darkColor = "#000000") {
  if (!bgColor) {
    return lightColor;
  }
  const color = (bgColor.charAt(0) === "#") ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186)
    ? darkColor
    : lightColor;
}

export function getStyleByBgColor(bgColor: string | undefined) {
  const backgroundColor = bgColor || "#2d2d2d";
  return {
    backgroundColor,
    color: getColorByBgColor(backgroundColor),
  };
}

// Function to generate a random color
export function randomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}
