<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import type { DataAttribute, DataObject } from "~/model";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";
import type { TagMetadata } from "~/store/useProject";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
});

const emit = defineEmits(["update", "focus", "deleteDataObject", "addDataObject"]);

const dateValue = computed({
  get() {
    if (props.attribute.dateValue) {
      return new Date(props.attribute.dateValue);
    } else {
      return undefined;
    }
  },
  set(value) {
    emit("update", {
      ...props.attribute,
      value,
      dateValue: value,
    });
  },
});

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);
const valid = computed(() => {
  return props.attribute?.dataExceptions?.length === 0;
});

function focus() {
  dataViewHelper.focus();
  emit("focus");
}

const { style, conditionalValidationIcon } = storeToRefs(dataViewHelper);
</script>

<template>
  <div @click="focus" class="flex items-center">
    <span v-if="conditionalValidationIcon" class="mr-2">
      <img :src="conditionalValidationIcon" alt="icon" />
    </span>
    <DatePicker
      :id="`kdx-attribute-${props.attribute.uuid}`"
      v-model="dateValue"
      :style="style"
      class="kodexa-input"
      :fill-mode="null"
      rounded="medium"
      :disabled="props.dataObject?.documentFamily.locked === true"
      :tabindex="props.tabIndex"
      :valid="valid"
      @focus="focus"
      @blur="dataViewHelper.blur()"
    />
  </div>
</template>
<style scoped>
.kodexa-label {
  @apply block text-sm text-gray-700 mb-1
}

.kodexa-input {
  @apply w-full appearance-none rounded-md border border-gray-300
  px-0 py-0 shadow-sm placeholder:text-gray-400 sm:text-sm
}

.kodexa-input:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
}
.kodexa-input:focus {
  @apply border-blue-500 ring-1 ring-blue-500
}
</style>
