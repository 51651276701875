// Generated from resources/KodexaFormula.g4 by ANTLR 4.13.1

import * as antlr from "antlr4ng";
import { Token } from "antlr4ng";


export class KodexaFormulaLexer extends antlr.Lexer {
    public static readonly T__0 = 1;
    public static readonly T__1 = 2;
    public static readonly T__2 = 3;
    public static readonly T__3 = 4;
    public static readonly T__4 = 5;
    public static readonly T__5 = 6;
    public static readonly NUMBER = 7;
    public static readonly STRING = 8;
    public static readonly IDENTIFIER = 9;
    public static readonly FUNCTION_NAME = 10;
    public static readonly TRUE = 11;
    public static readonly FALSE = 12;
    public static readonly WS = 13;
    public static readonly ADD = 14;
    public static readonly SUB = 15;
    public static readonly MUL = 16;
    public static readonly DIV = 17;
    public static readonly LT = 18;
    public static readonly GT = 19;
    public static readonly EQ = 20;
    public static readonly NEQ = 21;
    public static readonly LTE = 22;
    public static readonly GTE = 23;
    public static readonly AND = 24;
    public static readonly OR = 25;
    public static readonly NOT = 26;
    public static readonly CONCAT = 27;
    public static readonly POWER = 28;

    public static readonly channelNames = [
        "DEFAULT_TOKEN_CHANNEL", "HIDDEN"
    ];

    public static readonly literalNames = [
        null, "'('", "')'", "'['", "']'", "','", "'@'", null, null, null, 
        null, "'true'", "'false'", null, "'+'", "'-'", "'*'", "'/'", "'<'", 
        "'>'", "'='", "'!='", "'<='", "'>='", "'&&'", "'||'", "'!'", "'&'", 
        "'^'"
    ];

    public static readonly symbolicNames = [
        null, null, null, null, null, null, null, "NUMBER", "STRING", "IDENTIFIER", 
        "FUNCTION_NAME", "TRUE", "FALSE", "WS", "ADD", "SUB", "MUL", "DIV", 
        "LT", "GT", "EQ", "NEQ", "LTE", "GTE", "AND", "OR", "NOT", "CONCAT", 
        "POWER"
    ];

    public static readonly modeNames = [
        "DEFAULT_MODE",
    ];

    public static readonly ruleNames = [
        "T__0", "T__1", "T__2", "T__3", "T__4", "T__5", "NUMBER", "STRING", 
        "IDENTIFIER", "FUNCTION_NAME", "TRUE", "FALSE", "WS", "ADD", "SUB", 
        "MUL", "DIV", "LT", "GT", "EQ", "NEQ", "LTE", "GTE", "AND", "OR", 
        "NOT", "CONCAT", "POWER",
    ];


    public constructor(input: antlr.CharStream) {
        super(input);
        this.interpreter = new antlr.LexerATNSimulator(this, KodexaFormulaLexer._ATN, KodexaFormulaLexer.decisionsToDFA, new antlr.PredictionContextCache());
    }

    public get grammarFileName(): string { return "KodexaFormula.g4"; }

    public get literalNames(): (string | null)[] { return KodexaFormulaLexer.literalNames; }
    public get symbolicNames(): (string | null)[] { return KodexaFormulaLexer.symbolicNames; }
    public get ruleNames(): string[] { return KodexaFormulaLexer.ruleNames; }

    public get serializedATN(): number[] { return KodexaFormulaLexer._serializedATN; }

    public get channelNames(): string[] { return KodexaFormulaLexer.channelNames; }

    public get modeNames(): string[] { return KodexaFormulaLexer.modeNames; }

    public static readonly _serializedATN: number[] = [
        4,0,28,159,6,-1,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,
        2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,2,
        13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,
        19,2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,
        26,7,26,2,27,7,27,1,0,1,0,1,1,1,1,1,2,1,2,1,3,1,3,1,4,1,4,1,5,1,
        5,1,6,4,6,71,8,6,11,6,12,6,72,1,6,1,6,4,6,77,8,6,11,6,12,6,78,3,
        6,81,8,6,1,7,1,7,1,7,1,7,5,7,87,8,7,10,7,12,7,90,9,7,1,7,1,7,1,8,
        1,8,4,8,96,8,8,11,8,12,8,97,1,8,1,8,1,9,4,9,103,8,9,11,9,12,9,104,
        1,10,1,10,1,10,1,10,1,10,1,11,1,11,1,11,1,11,1,11,1,11,1,12,4,12,
        119,8,12,11,12,12,12,120,1,12,1,12,1,13,1,13,1,14,1,14,1,15,1,15,
        1,16,1,16,1,17,1,17,1,18,1,18,1,19,1,19,1,20,1,20,1,20,1,21,1,21,
        1,21,1,22,1,22,1,22,1,23,1,23,1,23,1,24,1,24,1,24,1,25,1,25,1,26,
        1,26,1,27,1,27,0,0,28,1,1,3,2,5,3,7,4,9,5,11,6,13,7,15,8,17,9,19,
        10,21,11,23,12,25,13,27,14,29,15,31,16,33,17,35,18,37,19,39,20,41,
        21,43,22,45,23,47,24,49,25,51,26,53,27,55,28,1,0,5,1,0,48,57,4,0,
        10,10,13,13,34,34,92,92,4,0,47,57,65,90,95,95,97,122,4,0,48,57,65,
        90,95,95,97,122,3,0,9,10,13,13,32,32,166,0,1,1,0,0,0,0,3,1,0,0,0,
        0,5,1,0,0,0,0,7,1,0,0,0,0,9,1,0,0,0,0,11,1,0,0,0,0,13,1,0,0,0,0,
        15,1,0,0,0,0,17,1,0,0,0,0,19,1,0,0,0,0,21,1,0,0,0,0,23,1,0,0,0,0,
        25,1,0,0,0,0,27,1,0,0,0,0,29,1,0,0,0,0,31,1,0,0,0,0,33,1,0,0,0,0,
        35,1,0,0,0,0,37,1,0,0,0,0,39,1,0,0,0,0,41,1,0,0,0,0,43,1,0,0,0,0,
        45,1,0,0,0,0,47,1,0,0,0,0,49,1,0,0,0,0,51,1,0,0,0,0,53,1,0,0,0,0,
        55,1,0,0,0,1,57,1,0,0,0,3,59,1,0,0,0,5,61,1,0,0,0,7,63,1,0,0,0,9,
        65,1,0,0,0,11,67,1,0,0,0,13,70,1,0,0,0,15,82,1,0,0,0,17,93,1,0,0,
        0,19,102,1,0,0,0,21,106,1,0,0,0,23,111,1,0,0,0,25,118,1,0,0,0,27,
        124,1,0,0,0,29,126,1,0,0,0,31,128,1,0,0,0,33,130,1,0,0,0,35,132,
        1,0,0,0,37,134,1,0,0,0,39,136,1,0,0,0,41,138,1,0,0,0,43,141,1,0,
        0,0,45,144,1,0,0,0,47,147,1,0,0,0,49,150,1,0,0,0,51,153,1,0,0,0,
        53,155,1,0,0,0,55,157,1,0,0,0,57,58,5,40,0,0,58,2,1,0,0,0,59,60,
        5,41,0,0,60,4,1,0,0,0,61,62,5,91,0,0,62,6,1,0,0,0,63,64,5,93,0,0,
        64,8,1,0,0,0,65,66,5,44,0,0,66,10,1,0,0,0,67,68,5,64,0,0,68,12,1,
        0,0,0,69,71,7,0,0,0,70,69,1,0,0,0,71,72,1,0,0,0,72,70,1,0,0,0,72,
        73,1,0,0,0,73,80,1,0,0,0,74,76,5,46,0,0,75,77,7,0,0,0,76,75,1,0,
        0,0,77,78,1,0,0,0,78,76,1,0,0,0,78,79,1,0,0,0,79,81,1,0,0,0,80,74,
        1,0,0,0,80,81,1,0,0,0,81,14,1,0,0,0,82,88,5,34,0,0,83,87,8,1,0,0,
        84,85,5,34,0,0,85,87,5,34,0,0,86,83,1,0,0,0,86,84,1,0,0,0,87,90,
        1,0,0,0,88,86,1,0,0,0,88,89,1,0,0,0,89,91,1,0,0,0,90,88,1,0,0,0,
        91,92,5,34,0,0,92,16,1,0,0,0,93,95,5,123,0,0,94,96,7,2,0,0,95,94,
        1,0,0,0,96,97,1,0,0,0,97,95,1,0,0,0,97,98,1,0,0,0,98,99,1,0,0,0,
        99,100,5,125,0,0,100,18,1,0,0,0,101,103,7,3,0,0,102,101,1,0,0,0,
        103,104,1,0,0,0,104,102,1,0,0,0,104,105,1,0,0,0,105,20,1,0,0,0,106,
        107,5,116,0,0,107,108,5,114,0,0,108,109,5,117,0,0,109,110,5,101,
        0,0,110,22,1,0,0,0,111,112,5,102,0,0,112,113,5,97,0,0,113,114,5,
        108,0,0,114,115,5,115,0,0,115,116,5,101,0,0,116,24,1,0,0,0,117,119,
        7,4,0,0,118,117,1,0,0,0,119,120,1,0,0,0,120,118,1,0,0,0,120,121,
        1,0,0,0,121,122,1,0,0,0,122,123,6,12,0,0,123,26,1,0,0,0,124,125,
        5,43,0,0,125,28,1,0,0,0,126,127,5,45,0,0,127,30,1,0,0,0,128,129,
        5,42,0,0,129,32,1,0,0,0,130,131,5,47,0,0,131,34,1,0,0,0,132,133,
        5,60,0,0,133,36,1,0,0,0,134,135,5,62,0,0,135,38,1,0,0,0,136,137,
        5,61,0,0,137,40,1,0,0,0,138,139,5,33,0,0,139,140,5,61,0,0,140,42,
        1,0,0,0,141,142,5,60,0,0,142,143,5,61,0,0,143,44,1,0,0,0,144,145,
        5,62,0,0,145,146,5,61,0,0,146,46,1,0,0,0,147,148,5,38,0,0,148,149,
        5,38,0,0,149,48,1,0,0,0,150,151,5,124,0,0,151,152,5,124,0,0,152,
        50,1,0,0,0,153,154,5,33,0,0,154,52,1,0,0,0,155,156,5,38,0,0,156,
        54,1,0,0,0,157,158,5,94,0,0,158,56,1,0,0,0,9,0,72,78,80,86,88,97,
        104,120,1,6,0,0
    ];

    private static __ATN: antlr.ATN;
    public static get _ATN(): antlr.ATN {
        if (!KodexaFormulaLexer.__ATN) {
            KodexaFormulaLexer.__ATN = new antlr.ATNDeserializer().deserialize(KodexaFormulaLexer._serializedATN);
        }

        return KodexaFormulaLexer.__ATN;
    }


    private static readonly vocabulary = new antlr.Vocabulary(KodexaFormulaLexer.literalNames, KodexaFormulaLexer.symbolicNames, []);

    public override get vocabulary(): antlr.Vocabulary {
        return KodexaFormulaLexer.vocabulary;
    }

    private static readonly decisionsToDFA = KodexaFormulaLexer._ATN.decisionToState.map( (ds: antlr.DecisionState, index: number) => new antlr.DFA(ds, index) );
}