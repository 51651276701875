<script lang="ts" setup>
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { createDataFormViewerStore } from "~/store/useDataFormView";

const props = defineProps({
  viewId: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["closed"]);

const useDataFormViewer = createDataFormViewerStore(props.viewId);
const { dataForm, dataObjects, availableCardTypes, selectedCard } = storeToRefs(useDataFormViewer);
const { tagMetadataMap } = storeToRefs(appStore.projectStore);

const cardToConfigure = computed({
  get: () => {
    return selectedCard.value;
  },
  set: (value: any) => {
    useDataFormViewer.updateCard(value);
  },
});

const cardProperties = computed({
  get: () => {
    return selectedCard.value?.properties || {};
  },
  set: (value: any) => {
    if (selectedCard.value && selectedCard.value.id) {
      const card = selectedCard.value;
      card.properties = value;
      if (card.id) {
        useDataFormViewer.findCard(card.id, card);
      }
    }
  },
});

const cardOptions = computed(() => {
  const cardType = cardToConfigure.value && cardToConfigure.value?.type ? availableCardTypes.value.find(cardType => cardType.name === cardToConfigure.value?.type) : undefined;
  return cardType ? cardType.options : [];
});
</script>

<template>
  <div>
    <div class="flex h-full flex-col bg-white">
      <div class="flex-1">
        <!-- Header -->
        <div class="bg-gray-50 px-4 py-6 sm:px-6">
          <div class="flex items-start justify-between space-x-3">
            <div class="space-y-1">
              <p class="mt-2 text-sm text-gray-500">
                Properties
              </p>
            </div>
            <div class="flex h-7 items-center">
              <button class="text-gray-400 hover:text-gray-500" type="button" @click="emit('closed')">
                <span class="sr-only">Close panel</span>
                <MaterialDesignIcon aria-hidden="true" class="h-6 w-6" name="close" />
              </button>
            </div>
          </div>
        </div>
        <div v-if="cardToConfigure" class="mx-1 mt-1">
          <div>
            <p>Card Type</p>
            <DropDownList
              v-model="cardToConfigure.type"
              :data-items="availableCardTypes"
              :style="{ width: '100%' }"
              :value-primitive="true"
              data-item-key="name"
              fill-mode="outline" text-field="label"
              value-field="name"
            />
          </div>
          <div v-if="cardOptions">
            <div v-for="cardOption in cardOptions" :key="cardOption.name">
              <ConfigurationOption v-model="cardProperties" :item="cardOption" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
