<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  dashboard: {
    type: Object,
    required: true,
  },
  entryPoint: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const {
  project,
  documentStores,
  dataStores,
  modelStores,
} = storeToRefs(appStore.projectStore);

const currentStep = ref(0);
const newResource = ref<SlugBasedMetadata>({} as SlugBasedMetadata);
const selectedTemplate = ref<SlugBasedMetadata | undefined>();

watch(() => props.modelValue, (value) => {
  if (value) {
    currentStep.value = 0;
    selectedTemplate.value = undefined;
    newResource.value = {} as SlugBasedMetadata;
  }
}, {
  immediate: true,
});

function close() {
  emit("update:modelValue", false);
}

const loading = ref(false);
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4  text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 80%; height: calc(100vh - 10rem)"
              class="relative overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all"
            >
              <KodexaDashboardWidgetLibrary :dashboard="dashboard" @closed="close()" :entry-point="entryPoint"/>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
