// Generated from resources/KodexaFormula.g4 by ANTLR 4.13.1

import * as antlr from "antlr4ng";
import { Token } from "antlr4ng";

import { KodexaFormulaListener } from "./KodexaFormulaListener.js";
import { KodexaFormulaVisitor } from "./KodexaFormulaVisitor.js";

// for running tests with parameters, TODO: discuss strategy for typed parameters in CI
// eslint-disable-next-line no-unused-vars
type int = number;


export class KodexaFormulaParser extends antlr.Parser {
    public static readonly T__0 = 1;
    public static readonly T__1 = 2;
    public static readonly T__2 = 3;
    public static readonly T__3 = 4;
    public static readonly T__4 = 5;
    public static readonly T__5 = 6;
    public static readonly NUMBER = 7;
    public static readonly STRING = 8;
    public static readonly IDENTIFIER = 9;
    public static readonly FUNCTION_NAME = 10;
    public static readonly TRUE = 11;
    public static readonly FALSE = 12;
    public static readonly WS = 13;
    public static readonly ADD = 14;
    public static readonly SUB = 15;
    public static readonly MUL = 16;
    public static readonly DIV = 17;
    public static readonly LT = 18;
    public static readonly GT = 19;
    public static readonly EQ = 20;
    public static readonly NEQ = 21;
    public static readonly LTE = 22;
    public static readonly GTE = 23;
    public static readonly AND = 24;
    public static readonly OR = 25;
    public static readonly NOT = 26;
    public static readonly CONCAT = 27;
    public static readonly POWER = 28;
    public static readonly RULE_formula = 0;
    public static readonly RULE_expression = 1;
    public static readonly RULE_number = 2;
    public static readonly RULE_arrayLiteral = 3;
    public static readonly RULE_argumentList = 4;
    public static readonly RULE_functionCall = 5;
    public static readonly RULE_attributeReference = 6;
    public static readonly RULE_attributeProperty = 7;

    public static readonly literalNames = [
        null, "'('", "')'", "'['", "']'", "','", "'@'", null, null, null, 
        null, "'true'", "'false'", null, "'+'", "'-'", "'*'", "'/'", "'<'", 
        "'>'", "'='", "'!='", "'<='", "'>='", "'&&'", "'||'", "'!'", "'&'", 
        "'^'"
    ];

    public static readonly symbolicNames = [
        null, null, null, null, null, null, null, "NUMBER", "STRING", "IDENTIFIER", 
        "FUNCTION_NAME", "TRUE", "FALSE", "WS", "ADD", "SUB", "MUL", "DIV", 
        "LT", "GT", "EQ", "NEQ", "LTE", "GTE", "AND", "OR", "NOT", "CONCAT", 
        "POWER"
    ];
    public static readonly ruleNames = [
        "formula", "expression", "number", "arrayLiteral", "argumentList", 
        "functionCall", "attributeReference", "attributeProperty",
    ];

    public get grammarFileName(): string { return "KodexaFormula.g4"; }
    public get literalNames(): (string | null)[] { return KodexaFormulaParser.literalNames; }
    public get symbolicNames(): (string | null)[] { return KodexaFormulaParser.symbolicNames; }
    public get ruleNames(): string[] { return KodexaFormulaParser.ruleNames; }
    public get serializedATN(): number[] { return KodexaFormulaParser._serializedATN; }

    protected createFailedPredicateException(predicate?: string, message?: string): antlr.FailedPredicateException {
        return new antlr.FailedPredicateException(this, predicate, message);
    }

    public constructor(input: antlr.TokenStream) {
        super(input);
        this.interpreter = new antlr.ParserATNSimulator(this, KodexaFormulaParser._ATN, KodexaFormulaParser.decisionsToDFA, new antlr.PredictionContextCache());
    }
    public formula(): FormulaContext {
        let localContext = new FormulaContext(this.context, this.state);
        this.enterRule(localContext, 0, KodexaFormulaParser.RULE_formula);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 16;
            this.expression(0);
            this.state = 17;
            this.match(KodexaFormulaParser.EOF);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }

    public expression(): ExpressionContext;
    public expression(_p: number): ExpressionContext;
    public expression(_p?: number): ExpressionContext {
        if (_p === undefined) {
            _p = 0;
        }

        let parentContext = this.context;
        let parentState = this.state;
        let localContext = new ExpressionContext(this.context, parentState);
        let previousContext = localContext;
        let _startState = 2;
        this.enterRecursionRule(localContext, 2, KodexaFormulaParser.RULE_expression, _p);
        try {
            let alternative: number;
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 36;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 0, this.context) ) {
            case 1:
                {
                localContext = new LogicalNotContext(localContext);
                this.context = localContext;
                previousContext = localContext;

                this.state = 20;
                this.match(KodexaFormulaParser.NOT);
                this.state = 21;
                this.expression(12);
                }
                break;
            case 2:
                {
                localContext = new AttributeRefContext(localContext);
                this.context = localContext;
                previousContext = localContext;
                this.state = 22;
                this.attributeReference();
                }
                break;
            case 3:
                {
                localContext = new ParensContext(localContext);
                this.context = localContext;
                previousContext = localContext;
                this.state = 23;
                this.match(KodexaFormulaParser.T__0);
                this.state = 24;
                this.expression(0);
                this.state = 25;
                this.match(KodexaFormulaParser.T__1);
                }
                break;
            case 4:
                {
                localContext = new FunctionContext(localContext);
                this.context = localContext;
                previousContext = localContext;
                this.state = 27;
                this.functionCall();
                }
                break;
            case 5:
                {
                localContext = new AttributePropContext(localContext);
                this.context = localContext;
                previousContext = localContext;
                this.state = 28;
                this.attributeProperty();
                }
                break;
            case 6:
                {
                localContext = new PositiveOrNegativeNumberContext(localContext);
                this.context = localContext;
                previousContext = localContext;
                this.state = 29;
                this.number_();
                }
                break;
            case 7:
                {
                localContext = new StringLiteralContext(localContext);
                this.context = localContext;
                previousContext = localContext;
                this.state = 30;
                this.match(KodexaFormulaParser.STRING);
                }
                break;
            case 8:
                {
                localContext = new TrueLiteralContext(localContext);
                this.context = localContext;
                previousContext = localContext;
                this.state = 31;
                this.match(KodexaFormulaParser.TRUE);
                }
                break;
            case 9:
                {
                localContext = new FalseLiteralContext(localContext);
                this.context = localContext;
                previousContext = localContext;
                this.state = 32;
                this.match(KodexaFormulaParser.FALSE);
                }
                break;
            case 10:
                {
                localContext = new UnaryMinusContext(localContext);
                this.context = localContext;
                previousContext = localContext;
                this.state = 33;
                this.match(KodexaFormulaParser.SUB);
                this.state = 34;
                this.expression(3);
                }
                break;
            case 11:
                {
                localContext = new ArrayLitContext(localContext);
                this.context = localContext;
                previousContext = localContext;
                this.state = 35;
                this.arrayLiteral();
                }
                break;
            }
            this.context!.stop = this.tokenStream.LT(-1);
            this.state = 81;
            this.errorHandler.sync(this);
            alternative = this.interpreter.adaptivePredict(this.tokenStream, 2, this.context);
            while (alternative !== 2 && alternative !== antlr.ATN.INVALID_ALT_NUMBER) {
                if (alternative === 1) {
                    if (this.parseListeners != null) {
                        this.triggerExitRuleEvent();
                    }
                    previousContext = localContext;
                    {
                    this.state = 79;
                    this.errorHandler.sync(this);
                    switch (this.interpreter.adaptivePredict(this.tokenStream, 1, this.context) ) {
                    case 1:
                        {
                        localContext = new MulContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 38;
                        if (!(this.precpred(this.context, 24))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 24)");
                        }
                        this.state = 39;
                        this.match(KodexaFormulaParser.MUL);
                        this.state = 40;
                        this.expression(25);
                        }
                        break;
                    case 2:
                        {
                        localContext = new AddContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 41;
                        if (!(this.precpred(this.context, 23))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 23)");
                        }
                        this.state = 42;
                        this.match(KodexaFormulaParser.ADD);
                        this.state = 43;
                        this.expression(24);
                        }
                        break;
                    case 3:
                        {
                        localContext = new SubContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 44;
                        if (!(this.precpred(this.context, 22))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 22)");
                        }
                        this.state = 45;
                        this.match(KodexaFormulaParser.SUB);
                        this.state = 46;
                        this.expression(23);
                        }
                        break;
                    case 4:
                        {
                        localContext = new DivContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 47;
                        if (!(this.precpred(this.context, 21))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 21)");
                        }
                        this.state = 48;
                        this.match(KodexaFormulaParser.DIV);
                        this.state = 49;
                        this.expression(22);
                        }
                        break;
                    case 5:
                        {
                        localContext = new LessThanContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 50;
                        if (!(this.precpred(this.context, 20))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 20)");
                        }
                        this.state = 51;
                        this.match(KodexaFormulaParser.LT);
                        this.state = 52;
                        this.expression(21);
                        }
                        break;
                    case 6:
                        {
                        localContext = new GreaterThanContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 53;
                        if (!(this.precpred(this.context, 19))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 19)");
                        }
                        this.state = 54;
                        this.match(KodexaFormulaParser.GT);
                        this.state = 55;
                        this.expression(20);
                        }
                        break;
                    case 7:
                        {
                        localContext = new EqualContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 56;
                        if (!(this.precpred(this.context, 18))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 18)");
                        }
                        this.state = 57;
                        this.match(KodexaFormulaParser.EQ);
                        this.state = 58;
                        this.expression(19);
                        }
                        break;
                    case 8:
                        {
                        localContext = new NotEqualContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 59;
                        if (!(this.precpred(this.context, 17))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 17)");
                        }
                        this.state = 60;
                        this.match(KodexaFormulaParser.NEQ);
                        this.state = 61;
                        this.expression(18);
                        }
                        break;
                    case 9:
                        {
                        localContext = new LessThanEqualContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 62;
                        if (!(this.precpred(this.context, 16))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 16)");
                        }
                        this.state = 63;
                        this.match(KodexaFormulaParser.LTE);
                        this.state = 64;
                        this.expression(17);
                        }
                        break;
                    case 10:
                        {
                        localContext = new GreaterThanEqualContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 65;
                        if (!(this.precpred(this.context, 15))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 15)");
                        }
                        this.state = 66;
                        this.match(KodexaFormulaParser.GTE);
                        this.state = 67;
                        this.expression(16);
                        }
                        break;
                    case 11:
                        {
                        localContext = new LogicalAndContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 68;
                        if (!(this.precpred(this.context, 14))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 14)");
                        }
                        this.state = 69;
                        this.match(KodexaFormulaParser.AND);
                        this.state = 70;
                        this.expression(15);
                        }
                        break;
                    case 12:
                        {
                        localContext = new LogicalOrContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 71;
                        if (!(this.precpred(this.context, 13))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 13)");
                        }
                        this.state = 72;
                        this.match(KodexaFormulaParser.OR);
                        this.state = 73;
                        this.expression(14);
                        }
                        break;
                    case 13:
                        {
                        localContext = new ArrayAccessContext(new ExpressionContext(parentContext, parentState));
                        this.pushNewRecursionContext(localContext, _startState, KodexaFormulaParser.RULE_expression);
                        this.state = 74;
                        if (!(this.precpred(this.context, 1))) {
                            throw this.createFailedPredicateException("this.precpred(this.context, 1)");
                        }
                        this.state = 75;
                        this.match(KodexaFormulaParser.T__2);
                        this.state = 76;
                        this.expression(0);
                        this.state = 77;
                        this.match(KodexaFormulaParser.T__3);
                        }
                        break;
                    }
                    }
                }
                this.state = 83;
                this.errorHandler.sync(this);
                alternative = this.interpreter.adaptivePredict(this.tokenStream, 2, this.context);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.unrollRecursionContexts(parentContext);
        }
        return localContext;
    }
    public number_(): NumberContext {
        let localContext = new NumberContext(this.context, this.state);
        this.enterRule(localContext, 4, KodexaFormulaParser.RULE_number);
        try {
            this.state = 87;
            this.errorHandler.sync(this);
            switch (this.tokenStream.LA(1)) {
            case KodexaFormulaParser.NUMBER:
                localContext = new PositiveNumberContext(localContext);
                this.enterOuterAlt(localContext, 1);
                {
                this.state = 84;
                this.match(KodexaFormulaParser.NUMBER);
                }
                break;
            case KodexaFormulaParser.SUB:
                localContext = new NegativeNumberContext(localContext);
                this.enterOuterAlt(localContext, 2);
                {
                this.state = 85;
                this.match(KodexaFormulaParser.SUB);
                this.state = 86;
                this.match(KodexaFormulaParser.NUMBER);
                }
                break;
            default:
                throw new antlr.NoViableAltException(this);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public arrayLiteral(): ArrayLiteralContext {
        let localContext = new ArrayLiteralContext(this.context, this.state);
        this.enterRule(localContext, 6, KodexaFormulaParser.RULE_arrayLiteral);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 89;
            this.match(KodexaFormulaParser.T__2);
            this.state = 91;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if ((((_la) & ~0x1F) === 0 && ((1 << _la) & 67149770) !== 0)) {
                {
                this.state = 90;
                this.argumentList();
                }
            }

            this.state = 93;
            this.match(KodexaFormulaParser.T__3);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public argumentList(): ArgumentListContext {
        let localContext = new ArgumentListContext(this.context, this.state);
        this.enterRule(localContext, 8, KodexaFormulaParser.RULE_argumentList);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 95;
            this.expression(0);
            this.state = 100;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            while (_la === 5) {
                {
                {
                this.state = 96;
                this.match(KodexaFormulaParser.T__4);
                this.state = 97;
                this.expression(0);
                }
                }
                this.state = 102;
                this.errorHandler.sync(this);
                _la = this.tokenStream.LA(1);
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public functionCall(): FunctionCallContext {
        let localContext = new FunctionCallContext(this.context, this.state);
        this.enterRule(localContext, 10, KodexaFormulaParser.RULE_functionCall);
        let _la: number;
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 103;
            this.match(KodexaFormulaParser.FUNCTION_NAME);
            this.state = 104;
            this.match(KodexaFormulaParser.T__0);
            this.state = 106;
            this.errorHandler.sync(this);
            _la = this.tokenStream.LA(1);
            if ((((_la) & ~0x1F) === 0 && ((1 << _la) & 67149770) !== 0)) {
                {
                this.state = 105;
                this.argumentList();
                }
            }

            this.state = 108;
            this.match(KodexaFormulaParser.T__1);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public attributeReference(): AttributeReferenceContext {
        let localContext = new AttributeReferenceContext(this.context, this.state);
        this.enterRule(localContext, 12, KodexaFormulaParser.RULE_attributeReference);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 110;
            this.match(KodexaFormulaParser.IDENTIFIER);
            this.state = 115;
            this.errorHandler.sync(this);
            switch (this.interpreter.adaptivePredict(this.tokenStream, 7, this.context) ) {
            case 1:
                {
                this.state = 111;
                this.match(KodexaFormulaParser.T__0);
                this.state = 112;
                this.expression(0);
                this.state = 113;
                this.match(KodexaFormulaParser.T__1);
                }
                break;
            }
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }
    public attributeProperty(): AttributePropertyContext {
        let localContext = new AttributePropertyContext(this.context, this.state);
        this.enterRule(localContext, 14, KodexaFormulaParser.RULE_attributeProperty);
        try {
            this.enterOuterAlt(localContext, 1);
            {
            this.state = 117;
            this.match(KodexaFormulaParser.T__5);
            this.state = 118;
            this.match(KodexaFormulaParser.FUNCTION_NAME);
            }
        }
        catch (re) {
            if (re instanceof antlr.RecognitionException) {
                this.errorHandler.reportError(this, re);
                this.errorHandler.recover(this, re);
            } else {
                throw re;
            }
        }
        finally {
            this.exitRule();
        }
        return localContext;
    }

    public override sempred(localContext: antlr.ParserRuleContext | null, ruleIndex: number, predIndex: number): boolean {
        switch (ruleIndex) {
        case 1:
            return this.expression_sempred(localContext as ExpressionContext, predIndex);
        }
        return true;
    }
    private expression_sempred(localContext: ExpressionContext | null, predIndex: number): boolean {
        switch (predIndex) {
        case 0:
            return this.precpred(this.context, 24);
        case 1:
            return this.precpred(this.context, 23);
        case 2:
            return this.precpred(this.context, 22);
        case 3:
            return this.precpred(this.context, 21);
        case 4:
            return this.precpred(this.context, 20);
        case 5:
            return this.precpred(this.context, 19);
        case 6:
            return this.precpred(this.context, 18);
        case 7:
            return this.precpred(this.context, 17);
        case 8:
            return this.precpred(this.context, 16);
        case 9:
            return this.precpred(this.context, 15);
        case 10:
            return this.precpred(this.context, 14);
        case 11:
            return this.precpred(this.context, 13);
        case 12:
            return this.precpred(this.context, 1);
        }
        return true;
    }

    public static readonly _serializedATN: number[] = [
        4,1,28,121,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,4,2,5,7,5,2,6,7,
        6,2,7,7,7,1,0,1,0,1,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,
        1,1,1,1,1,1,1,1,1,1,1,1,1,3,1,37,8,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,
        1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,
        1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,
        1,1,1,1,5,1,80,8,1,10,1,12,1,83,9,1,1,2,1,2,1,2,3,2,88,8,2,1,3,1,
        3,3,3,92,8,3,1,3,1,3,1,4,1,4,1,4,5,4,99,8,4,10,4,12,4,102,9,4,1,
        5,1,5,1,5,3,5,107,8,5,1,5,1,5,1,6,1,6,1,6,1,6,1,6,3,6,116,8,6,1,
        7,1,7,1,7,1,7,0,1,2,8,0,2,4,6,8,10,12,14,0,0,140,0,16,1,0,0,0,2,
        36,1,0,0,0,4,87,1,0,0,0,6,89,1,0,0,0,8,95,1,0,0,0,10,103,1,0,0,0,
        12,110,1,0,0,0,14,117,1,0,0,0,16,17,3,2,1,0,17,18,5,0,0,1,18,1,1,
        0,0,0,19,20,6,1,-1,0,20,21,5,26,0,0,21,37,3,2,1,12,22,37,3,12,6,
        0,23,24,5,1,0,0,24,25,3,2,1,0,25,26,5,2,0,0,26,37,1,0,0,0,27,37,
        3,10,5,0,28,37,3,14,7,0,29,37,3,4,2,0,30,37,5,8,0,0,31,37,5,11,0,
        0,32,37,5,12,0,0,33,34,5,15,0,0,34,37,3,2,1,3,35,37,3,6,3,0,36,19,
        1,0,0,0,36,22,1,0,0,0,36,23,1,0,0,0,36,27,1,0,0,0,36,28,1,0,0,0,
        36,29,1,0,0,0,36,30,1,0,0,0,36,31,1,0,0,0,36,32,1,0,0,0,36,33,1,
        0,0,0,36,35,1,0,0,0,37,81,1,0,0,0,38,39,10,24,0,0,39,40,5,16,0,0,
        40,80,3,2,1,25,41,42,10,23,0,0,42,43,5,14,0,0,43,80,3,2,1,24,44,
        45,10,22,0,0,45,46,5,15,0,0,46,80,3,2,1,23,47,48,10,21,0,0,48,49,
        5,17,0,0,49,80,3,2,1,22,50,51,10,20,0,0,51,52,5,18,0,0,52,80,3,2,
        1,21,53,54,10,19,0,0,54,55,5,19,0,0,55,80,3,2,1,20,56,57,10,18,0,
        0,57,58,5,20,0,0,58,80,3,2,1,19,59,60,10,17,0,0,60,61,5,21,0,0,61,
        80,3,2,1,18,62,63,10,16,0,0,63,64,5,22,0,0,64,80,3,2,1,17,65,66,
        10,15,0,0,66,67,5,23,0,0,67,80,3,2,1,16,68,69,10,14,0,0,69,70,5,
        24,0,0,70,80,3,2,1,15,71,72,10,13,0,0,72,73,5,25,0,0,73,80,3,2,1,
        14,74,75,10,1,0,0,75,76,5,3,0,0,76,77,3,2,1,0,77,78,5,4,0,0,78,80,
        1,0,0,0,79,38,1,0,0,0,79,41,1,0,0,0,79,44,1,0,0,0,79,47,1,0,0,0,
        79,50,1,0,0,0,79,53,1,0,0,0,79,56,1,0,0,0,79,59,1,0,0,0,79,62,1,
        0,0,0,79,65,1,0,0,0,79,68,1,0,0,0,79,71,1,0,0,0,79,74,1,0,0,0,80,
        83,1,0,0,0,81,79,1,0,0,0,81,82,1,0,0,0,82,3,1,0,0,0,83,81,1,0,0,
        0,84,88,5,7,0,0,85,86,5,15,0,0,86,88,5,7,0,0,87,84,1,0,0,0,87,85,
        1,0,0,0,88,5,1,0,0,0,89,91,5,3,0,0,90,92,3,8,4,0,91,90,1,0,0,0,91,
        92,1,0,0,0,92,93,1,0,0,0,93,94,5,4,0,0,94,7,1,0,0,0,95,100,3,2,1,
        0,96,97,5,5,0,0,97,99,3,2,1,0,98,96,1,0,0,0,99,102,1,0,0,0,100,98,
        1,0,0,0,100,101,1,0,0,0,101,9,1,0,0,0,102,100,1,0,0,0,103,104,5,
        10,0,0,104,106,5,1,0,0,105,107,3,8,4,0,106,105,1,0,0,0,106,107,1,
        0,0,0,107,108,1,0,0,0,108,109,5,2,0,0,109,11,1,0,0,0,110,115,5,9,
        0,0,111,112,5,1,0,0,112,113,3,2,1,0,113,114,5,2,0,0,114,116,1,0,
        0,0,115,111,1,0,0,0,115,116,1,0,0,0,116,13,1,0,0,0,117,118,5,6,0,
        0,118,119,5,10,0,0,119,15,1,0,0,0,8,36,79,81,87,91,100,106,115
    ];

    private static __ATN: antlr.ATN;
    public static get _ATN(): antlr.ATN {
        if (!KodexaFormulaParser.__ATN) {
            KodexaFormulaParser.__ATN = new antlr.ATNDeserializer().deserialize(KodexaFormulaParser._serializedATN);
        }

        return KodexaFormulaParser.__ATN;
    }


    private static readonly vocabulary = new antlr.Vocabulary(KodexaFormulaParser.literalNames, KodexaFormulaParser.symbolicNames, []);

    public override get vocabulary(): antlr.Vocabulary {
        return KodexaFormulaParser.vocabulary;
    }

    private static readonly decisionsToDFA = KodexaFormulaParser._ATN.decisionToState.map( (ds: antlr.DecisionState, index: number) => new antlr.DFA(ds, index) );
}

export class FormulaContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public expression(): ExpressionContext {
        return this.getRuleContext(0, ExpressionContext)!;
    }
    public EOF(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.EOF, 0)!;
    }
    public override get ruleIndex(): number {
        return KodexaFormulaParser.RULE_formula;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterFormula) {
             listener.enterFormula(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitFormula) {
             listener.exitFormula(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitFormula) {
            return visitor.visitFormula(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class ExpressionContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public override get ruleIndex(): number {
        return KodexaFormulaParser.RULE_expression;
    }
    public override copyFrom(ctx: ExpressionContext): void {
        super.copyFrom(ctx);
    }
}
export class AddContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public ADD(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.ADD, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterAdd) {
             listener.enterAdd(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitAdd) {
             listener.exitAdd(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitAdd) {
            return visitor.visitAdd(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class AttributePropContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public attributeProperty(): AttributePropertyContext {
        return this.getRuleContext(0, AttributePropertyContext)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterAttributeProp) {
             listener.enterAttributeProp(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitAttributeProp) {
             listener.exitAttributeProp(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitAttributeProp) {
            return visitor.visitAttributeProp(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class SubContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public SUB(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.SUB, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterSub) {
             listener.enterSub(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitSub) {
             listener.exitSub(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitSub) {
            return visitor.visitSub(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class NotEqualContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public NEQ(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.NEQ, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterNotEqual) {
             listener.enterNotEqual(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitNotEqual) {
             listener.exitNotEqual(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitNotEqual) {
            return visitor.visitNotEqual(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class AttributeRefContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public attributeReference(): AttributeReferenceContext {
        return this.getRuleContext(0, AttributeReferenceContext)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterAttributeRef) {
             listener.enterAttributeRef(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitAttributeRef) {
             listener.exitAttributeRef(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitAttributeRef) {
            return visitor.visitAttributeRef(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class MulContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public MUL(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.MUL, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterMul) {
             listener.enterMul(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitMul) {
             listener.exitMul(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitMul) {
            return visitor.visitMul(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class ParensContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext {
        return this.getRuleContext(0, ExpressionContext)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterParens) {
             listener.enterParens(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitParens) {
             listener.exitParens(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitParens) {
            return visitor.visitParens(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class GreaterThanEqualContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public GTE(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.GTE, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterGreaterThanEqual) {
             listener.enterGreaterThanEqual(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitGreaterThanEqual) {
             listener.exitGreaterThanEqual(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitGreaterThanEqual) {
            return visitor.visitGreaterThanEqual(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class PositiveOrNegativeNumberContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public number(): NumberContext {
        return this.getRuleContext(0, NumberContext)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterPositiveOrNegativeNumber) {
             listener.enterPositiveOrNegativeNumber(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitPositiveOrNegativeNumber) {
             listener.exitPositiveOrNegativeNumber(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitPositiveOrNegativeNumber) {
            return visitor.visitPositiveOrNegativeNumber(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class UnaryMinusContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public SUB(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.SUB, 0)!;
    }
    public expression(): ExpressionContext {
        return this.getRuleContext(0, ExpressionContext)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterUnaryMinus) {
             listener.enterUnaryMinus(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitUnaryMinus) {
             listener.exitUnaryMinus(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitUnaryMinus) {
            return visitor.visitUnaryMinus(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class LessThanEqualContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public LTE(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.LTE, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterLessThanEqual) {
             listener.enterLessThanEqual(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitLessThanEqual) {
             listener.exitLessThanEqual(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitLessThanEqual) {
            return visitor.visitLessThanEqual(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class LogicalOrContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public OR(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.OR, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterLogicalOr) {
             listener.enterLogicalOr(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitLogicalOr) {
             listener.exitLogicalOr(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitLogicalOr) {
            return visitor.visitLogicalOr(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class FalseLiteralContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public FALSE(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.FALSE, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterFalseLiteral) {
             listener.enterFalseLiteral(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitFalseLiteral) {
             listener.exitFalseLiteral(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitFalseLiteral) {
            return visitor.visitFalseLiteral(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class LogicalNotContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public NOT(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.NOT, 0)!;
    }
    public expression(): ExpressionContext {
        return this.getRuleContext(0, ExpressionContext)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterLogicalNot) {
             listener.enterLogicalNot(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitLogicalNot) {
             listener.exitLogicalNot(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitLogicalNot) {
            return visitor.visitLogicalNot(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class DivContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public DIV(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.DIV, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterDiv) {
             listener.enterDiv(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitDiv) {
             listener.exitDiv(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitDiv) {
            return visitor.visitDiv(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class FunctionContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public functionCall(): FunctionCallContext {
        return this.getRuleContext(0, FunctionCallContext)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterFunction) {
             listener.enterFunction(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitFunction) {
             listener.exitFunction(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitFunction) {
            return visitor.visitFunction(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class LessThanContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public LT(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.LT, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterLessThan) {
             listener.enterLessThan(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitLessThan) {
             listener.exitLessThan(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitLessThan) {
            return visitor.visitLessThan(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class ArrayAccessContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterArrayAccess) {
             listener.enterArrayAccess(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitArrayAccess) {
             listener.exitArrayAccess(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitArrayAccess) {
            return visitor.visitArrayAccess(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class StringLiteralContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public STRING(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.STRING, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterStringLiteral) {
             listener.enterStringLiteral(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitStringLiteral) {
             listener.exitStringLiteral(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitStringLiteral) {
            return visitor.visitStringLiteral(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class TrueLiteralContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public TRUE(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.TRUE, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterTrueLiteral) {
             listener.enterTrueLiteral(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitTrueLiteral) {
             listener.exitTrueLiteral(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitTrueLiteral) {
            return visitor.visitTrueLiteral(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class ArrayLitContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public arrayLiteral(): ArrayLiteralContext {
        return this.getRuleContext(0, ArrayLiteralContext)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterArrayLit) {
             listener.enterArrayLit(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitArrayLit) {
             listener.exitArrayLit(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitArrayLit) {
            return visitor.visitArrayLit(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class GreaterThanContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public GT(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.GT, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterGreaterThan) {
             listener.enterGreaterThan(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitGreaterThan) {
             listener.exitGreaterThan(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitGreaterThan) {
            return visitor.visitGreaterThan(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class EqualContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public EQ(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.EQ, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterEqual) {
             listener.enterEqual(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitEqual) {
             listener.exitEqual(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitEqual) {
            return visitor.visitEqual(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class LogicalAndContext extends ExpressionContext {
    public constructor(ctx: ExpressionContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public AND(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.AND, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterLogicalAnd) {
             listener.enterLogicalAnd(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitLogicalAnd) {
             listener.exitLogicalAnd(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitLogicalAnd) {
            return visitor.visitLogicalAnd(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class NumberContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public override get ruleIndex(): number {
        return KodexaFormulaParser.RULE_number;
    }
    public override copyFrom(ctx: NumberContext): void {
        super.copyFrom(ctx);
    }
}
export class PositiveNumberContext extends NumberContext {
    public constructor(ctx: NumberContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public NUMBER(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.NUMBER, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterPositiveNumber) {
             listener.enterPositiveNumber(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitPositiveNumber) {
             listener.exitPositiveNumber(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitPositiveNumber) {
            return visitor.visitPositiveNumber(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
export class NegativeNumberContext extends NumberContext {
    public constructor(ctx: NumberContext) {
        super(ctx.parent, ctx.invokingState);
        super.copyFrom(ctx);
    }
    public SUB(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.SUB, 0)!;
    }
    public NUMBER(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.NUMBER, 0)!;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterNegativeNumber) {
             listener.enterNegativeNumber(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitNegativeNumber) {
             listener.exitNegativeNumber(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitNegativeNumber) {
            return visitor.visitNegativeNumber(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class ArrayLiteralContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public argumentList(): ArgumentListContext | null {
        return this.getRuleContext(0, ArgumentListContext);
    }
    public override get ruleIndex(): number {
        return KodexaFormulaParser.RULE_arrayLiteral;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterArrayLiteral) {
             listener.enterArrayLiteral(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitArrayLiteral) {
             listener.exitArrayLiteral(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitArrayLiteral) {
            return visitor.visitArrayLiteral(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class ArgumentListContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public expression(): ExpressionContext[];
    public expression(i: number): ExpressionContext | null;
    public expression(i?: number): ExpressionContext[] | ExpressionContext | null {
        if (i === undefined) {
            return this.getRuleContexts(ExpressionContext);
        }

        return this.getRuleContext(i, ExpressionContext);
    }
    public override get ruleIndex(): number {
        return KodexaFormulaParser.RULE_argumentList;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterArgumentList) {
             listener.enterArgumentList(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitArgumentList) {
             listener.exitArgumentList(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitArgumentList) {
            return visitor.visitArgumentList(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class FunctionCallContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public FUNCTION_NAME(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.FUNCTION_NAME, 0)!;
    }
    public argumentList(): ArgumentListContext | null {
        return this.getRuleContext(0, ArgumentListContext);
    }
    public override get ruleIndex(): number {
        return KodexaFormulaParser.RULE_functionCall;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterFunctionCall) {
             listener.enterFunctionCall(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitFunctionCall) {
             listener.exitFunctionCall(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitFunctionCall) {
            return visitor.visitFunctionCall(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class AttributeReferenceContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public IDENTIFIER(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.IDENTIFIER, 0)!;
    }
    public expression(): ExpressionContext | null {
        return this.getRuleContext(0, ExpressionContext);
    }
    public override get ruleIndex(): number {
        return KodexaFormulaParser.RULE_attributeReference;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterAttributeReference) {
             listener.enterAttributeReference(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitAttributeReference) {
             listener.exitAttributeReference(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitAttributeReference) {
            return visitor.visitAttributeReference(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}


export class AttributePropertyContext extends antlr.ParserRuleContext {
    public constructor(parent: antlr.ParserRuleContext | null, invokingState: number) {
        super(parent, invokingState);
    }
    public FUNCTION_NAME(): antlr.TerminalNode {
        return this.getToken(KodexaFormulaParser.FUNCTION_NAME, 0)!;
    }
    public override get ruleIndex(): number {
        return KodexaFormulaParser.RULE_attributeProperty;
    }
    public override enterRule(listener: KodexaFormulaListener): void {
        if(listener.enterAttributeProperty) {
             listener.enterAttributeProperty(this);
        }
    }
    public override exitRule(listener: KodexaFormulaListener): void {
        if(listener.exitAttributeProperty) {
             listener.exitAttributeProperty(this);
        }
    }
    public override accept<Result>(visitor: KodexaFormulaVisitor<Result>): Result | null {
        if (visitor.visitAttributeProperty) {
            return visitor.visitAttributeProperty(this);
        } else {
            return visitor.visitChildren(this);
        }
    }
}
