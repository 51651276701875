<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { storeToRefs } from "pinia";
import type { PropType } from "vue";
import { createDataFormViewerStore } from "~/store/useDataFormView";
import type { TagMetadata } from "~/store/useProject";
import type { Card, DataObject } from "~/model";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  designMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const useDataFormViewer = createDataFormViewerStore(props.viewId);
const { dataForm, dataObjects } = storeToRefs(useDataFormViewer);
</script>

<template>
  <div>
    {{ card.properties.label ? card.properties.label : 'No label set' }}
  </div>
</template>

<style scoped>
</style>
