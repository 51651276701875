<script setup lang="ts">
import type { PropType } from "vue";
import type { Store } from "pinia";
import { storeToRefs } from "pinia";
import type { TagMetadata } from "~/store/useProject";
import { RefHelper } from "~/utils/ref-utils";
import type { GetExcelDownloadParams } from "~/model";
import { getExcelDownload } from "~/api/stores/stores";
import { updateHandler } from "~/utils/error-handler";
import appStore from "~/store";

const props = defineProps({
  dataStore: {
    type: Object as PropType<Store>,
    required: true,
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: undefined,
  },
  groupTaxons: {
    type: Array as PropType<TagMetadata[]>,
    required: false,
    default: undefined,
  },
});

const useCurrentFilters = ref(false);

const selectedTaxons = ref(new Set());

if (props.groupTaxons) {
  props.groupTaxons.forEach((taxon) => {
    selectedTaxons.value.add(taxon.taxon.id);
  });
}

function toggleSelection(itemId) {
  if (selectedTaxons.value.has(itemId)) {
    selectedTaxons.value.delete(itemId);
  } else {
    selectedTaxons.value.add(itemId);
  }
}

function isSelected(itemId) {
  return selectedTaxons.value.has(itemId);
}

const downloading = ref(false);

const { project } = storeToRefs(appStore.projectStore);

async function downloadExcel() {
  const refHelper = new RefHelper(props.dataStore.ref);
  const excelParams = {} as GetExcelDownloadParams;
  downloading.value = true;
  const blob = await updateHandler(getExcelDownload(refHelper.getOrgSlug(), refHelper.getSlug(), refHelper.getVersion(), excelParams), "Downloading Workbook");
  downloading.value = false;
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  const todayDateFormatted = new Date().toISOString().split("T")[0];
  link.download = `${project.value.name} ${todayDateFormatted}.xlsx`;
  link.click();
  URL.revokeObjectURL(link.href);
}
</script>

<template>
  <div class="flex h-screen">
    <!-- Left Panel -->
    <div class="w-1/2 space-y-4 p-5">
      <KodexaHeader title="Download as Excel Workbook" sub-title="Choose which data from your project to export" />
      <p>
        Easily export your workspace data from the Kodexa platform into an Excel spreadsheet.
        Select the sheets you wish to download, and opt to apply your current filtering and grouping settings for a
        customized export.
      </p>
      <KodexaArticle article-id="9110108" text="Learn more about exporting data to Microsoft Excel" :slide="false" />

      <div class="mt-36">
        <KodexaButton icon="microsoft-excel" :loading="downloading" @click="downloadExcel">
          Download As Excel Workbook
        </KodexaButton>
      </div>
    </div>

    <!-- Right Panel -->
    <div class="w-1/2 space-y-4 p-5">
      <div>
        <h3 class="text-lg font-semibold">
          Options
        </h3>
        <p class="text-sm text-gray-500">
          Select the sheets you wish to download and apply your current filtering and grouping settings.
        </p>
        <div class="mt-2">
          <KodexaCheckbox
            v-model="useCurrentFilters"
            :disabled="true" name="useCurrentFilters"
            label="Use the current filters, grouping"
            hint="Coming soon..."
          />
        </div>
        <div class="mt-4">
          <h4 class="font-medium">
            Sheets to Export:
          </h4>
          <div v-for="(taxon, index) in groupTaxons" :key="index" class="mt-2 overflow-y-scroll">
            <KodexaCheckbox
              :value="isSelected(taxon.taxon.id)" :name="taxon.taxon.id" :label="taxon.taxon.label"
              @click="toggleSelection(taxon.taxon.id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
/* Add custom styles if needed */
</style>
