<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { ref, computed, watch } from 'vue';
import { useCreateAssignmentRule } from '~/api/assignment-rule/assignment-rule';
import type { AssignmentRule } from '~/model/assignmentRule';
import { AssignmentRuleAssignmentType } from '~/model/assignmentRuleAssignmentType';
import { updateHandler } from "~/utils/error-handler";
import { storeToRefs } from "pinia";
import appStore from "~/store";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "refetch-assignments"]);

const { project } = storeToRefs(appStore.projectStore);

const newAssignment = ref<Partial<AssignmentRule>>({
  name: '',
  condition: '',
  assignmentType: AssignmentRuleAssignmentType.USER, // Set default to USER
  userExpression: '',
  priority: undefined,
});

const isDynamicUser = ref(false);
const requiredFieldsError = ref<Record<string, string>>({});

const createAssignmentMutation = useCreateAssignmentRule();

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

function validateEmail(email: string): boolean {
  return emailRegex.test(email);
}

function close() {
  newAssignment.value = {
    name: '',
    condition: '',
    assignmentType: AssignmentRuleAssignmentType.USER,
    userExpression: '',
    priority: undefined,
  };
  isDynamicUser.value = false;
  requiredFieldsError.value = {};
  emit("update:modelValue", false);
}

const requiredFields = ["name", "condition", "userExpression", "priority"];

async function createNewAssignment() {
  requiredFields.forEach((field) => {
    if (field === 'priority') {
      if (newAssignment.value[field] === undefined || newAssignment.value[field] < 1) {
        requiredFieldsError.value[field] = "Priority must be 1 or greater";
      } else {
        delete requiredFieldsError.value[field];
      }
    } else if (!newAssignment.value[field]) {
      requiredFieldsError.value[field] = "This field is required";
    } else if (field === 'userExpression' && !isDynamicUser.value) {
      if (!validateEmail(newAssignment.value[field])) {
        requiredFieldsError.value[field] = "Please enter a valid email address";
      } else {
        delete requiredFieldsError.value[field];
      }
    } else {
      delete requiredFieldsError.value[field];
    }
  });

  if (Object.keys(requiredFieldsError.value).length !== 0) {
    console.log("Validation failed:", requiredFieldsError.value);
    return;
  }

  newAssignment.value.assignmentType = isDynamicUser.value
    ? AssignmentRuleAssignmentType.DYNAMIC_USER
    : AssignmentRuleAssignmentType.USER;

  const assignmentData: Partial<AssignmentRule> = {
    ...newAssignment.value,
    project: { id: project.value.id },
  };

  console.log('Assignment payload:', JSON.stringify(assignmentData, null, 2));

  try {
    const result = await createAssignmentMutation.mutateAsync({ data: assignmentData });
    updateHandler(Promise.resolve(), "Assignment rule created successfully");
    console.log('Created assignment rule:', result);
    close();
    emit("refetch-assignments");
  } catch (error) {
    console.error("Error creating assignment rule:", error);

    let errorMessage = "Failed to create assignment rule. Please try again later.";

    if (error.response) {
      console.log('Error response data:', error.response.data);

      if (error.response.status === 500) {
        errorMessage = "Server error (500): Something went wrong on our end. Please try again later.";
      } else {
        errorMessage += `: ${error.response.data.message || error.response.statusText}`;
      }
    }

    updateHandler(Promise.reject(error), errorMessage);
  }
}

function clearError(field: string) {
  if (requiredFieldsError.value[field]) {
    delete requiredFieldsError.value[field];
    requiredFieldsError.value = { ...requiredFieldsError.value };
  }
}

const showAssignToField = computed(() => !isDynamicUser.value);

watch(isDynamicUser, (newValue) => {
  newAssignment.value.assignmentType = newValue
    ? AssignmentRuleAssignmentType.DYNAMIC_USER
    : AssignmentRuleAssignmentType.USER;
});
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0" leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="max-h-full flex-1 overflow-y-auto">
                    <!-- Header -->
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            New Document Assignment
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Get started by filling in the information below to create a new document assignment.
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              id="cancelNewAssignment" icon="cancel" type="secondary" size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="createNewAssignment" icon="plus" type="primary" size="small"
                              @click="createNewAssignment"
                            >
                              Create
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="min-h-0 flex-1 overflow-y-auto">
                      <!-- Divider container -->
                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <!-- Name -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label for="name" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Name</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="name"
                              v-model="newAssignment.name"
                              name="name"
                              :errors="requiredFieldsError"
                              @input="clearError('name')"
                            />
                          </div>
                        </div>

                        <!-- Condition -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label for="condition" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Condition</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextArea
                              id="condition"
                              v-model="newAssignment.condition"
                              name="condition"
                              :rows="10"
                              :errors="requiredFieldsError"
                              @input="clearError('condition')"
                            />
                          </div>
                        </div>

                        <!-- Dynamic User Checkbox -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label for="dynamicUser" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Dynamic User</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaCheckbox
                              id="dynamicUser"
                              v-model="isDynamicUser"
                              name="dynamicUser"
                            />
                          </div>
                        </div>

                        <!-- Assign To (visible when not Dynamic User) -->
                        <div v-if="showAssignToField" class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                          <div>
                            <label for="assignTo" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Assign to</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="assignTo"
                              v-model="newAssignment.userExpression"
                              name="userExpression"
                              :errors="requiredFieldsError"
                              @input="clearError('userExpression')"
                              @blur="validateEmail(newAssignment.userExpression) || (requiredFieldsError.userExpression = 'Please enter a valid email address')"
                            />
                          </div>
                        </div>

                        <!-- Dynamic User Formula (visible when Dynamic User) -->
                        <div v-else class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <div>
                            <label for="userExpression" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Dynamic User Formula</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaTextArea
                              id="userExpression"
                              v-model="newAssignment.userExpression"
                              name="userExpression"
                              :rows="10"
                              :errors="requiredFieldsError"
                              @input="clearError('userExpression')"
                            />
                          </div>
                        </div>

                        <!-- Priority -->
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-3">
                          <div>
                            <label for="priority" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Priority</label>
                          </div>
                          <div class="sm:col-span-2">
                            <KodexaNumericInput
                              id="priority"
                              v-model="newAssignment.priority"
                              name="priority"
                              :errors="requiredFieldsError"
                              @input="clearError('priority')"
                              :min="1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
