const METADATA_OBJECT_TYPES = [
  "store",
  "pipeline",
  "assistant-definition",
  "dashboard",
  "taxonomy",
  "project-template"];

const ENTITY_TYPES = [
  "assistant",
  "document-family",
  "project",
  "organization",
  "store-metadata",
  "data-object"]

const URL_PATHS = {
  "store": "stores",
  "pipeline": "pipelines",
  "assistant-definition": "assistantDefinitions",
  "dashboard": "dashboards",
  "taxonomy": "taxonomies",
  "project-template": "projectTemplates",
  "assistant": "assistants",
  "document-family": "documentFamilies",
  "project": "projects",
  "organization": "organizations",
  "store-metadata": "stores",
  "dataObject": "dataObjects"
}


export class RefHelper {
  private ref: string;
  private type: string | undefined;
  private parentOrgSlug: string | undefined;
  private id: string | undefined;

  constructor(ref: string | undefined, parentOrgSlug: string | undefined = undefined) {
    if (!ref) {
      throw new Error("Ref is required");
    }

    if (ref.split("://").length == 2) {
      // We have a type
      this.ref = ref.split("://")[1];
      this.type = ref.split("://")[0];

      if (this.isEntity()) {
        this.id = ref.split("://")[1];
      }
    } else {
      this.ref = ref;
      this.type = "metadata";
    }

    this.parentOrgSlug = parentOrgSlug;
  }

  getId(): string {
    if (this.id) {
      return this.id;
    } else {
      throw new Error(`This is not an entity ${this.getUri()}`);
    }
  }

  isMetadataObject(): boolean {
    return METADATA_OBJECT_TYPES.includes(this.type as string);
  }

  isEntity(): boolean {
    return ENTITY_TYPES.includes(this.type as string);
  }

  isRelative(): boolean {
    return this.ref.split("/")[0] === ".";
  }

  getType(): string {
    return this.type as string;
  }

  getFinalRef(): string {
    if (this.isRelative()) {
      if (this.parentOrgSlug) {
        return `${this.parentOrgSlug}/${this.ref.substring(2)}`;
      } else {
        throw new Error("Parent org slug is required, this reference is relative");
      }
    } else {
      if (this.isMetadataObject()) {
        if (this.type) {
          return this.getType() + "://+" + this.getOrgSlug() + "/" + this.getSlug() + ":" + this.getVersion();
        } else {
          return this.getOrgSlug() + "/" + this.getSlug() + ":" + this.getVersion();
        }

      }
      if (this.isEntity()) {
        return this.getType() + "/" + this.getSlug();
      }

      return this.ref;
    }
  }

  getUrl(): string {
    if (this.isRelative()) {
      if (this.parentOrgSlug) {
        return `${this.parentOrgSlug}/${this.ref.substring(2)}`;
      } else {
        throw new Error("Parent org slug is required, this reference is relative");
      }
    } else {
      if (this.isMetadataObject()) {
        if (this.type) {
          if (this.type === "store-metadata") {
            return URL_PATHS[this.getType()]+ "/" + this.getOrgSlug() + "/" + this.getSlug() + "/" + this.getVersion() + "/metadata"
          } else {
            return URL_PATHS[this.getType()] + "/" + this.getOrgSlug() + "/" + this.getSlug() + "/" + this.getVersion();
          }
        } else {
          return this.getOrgSlug() + "/" + this.getSlug() + "/" + this.getVersion();
        }

      }
      if (this.isEntity()) {
        return URL_PATHS[this.getType()] + "/" + this.getId();
      }

      return this.ref;
    }
  }

  getUri(): string {
    if (this.isRelative()) {
      if (this.parentOrgSlug) {
        return `${this.parentOrgSlug}/${this.ref.substring(2)}`;
      } else {
        throw new Error("Parent org slug is required, this reference is relative");
      }
    } else {
      if (this.isMetadataObject()) {
        if (this.type) {
          if (this.type === "store-metadata") {
            return this.getType() + "://" + this.getOrgSlug() + "/" + this.getSlug() + "/" + this.getVersion() + "/metadata"
          } else {
            return this.getType() + "://" + this.getOrgSlug() + "/" + this.getSlug() + "/" + this.getVersion();
          }
        } else {
          return this.getOrgSlug() + "/" + this.getSlug() + "/" + this.getVersion();
        }

      }
      if (this.isEntity()) {
        return this.getType() + "://" + this.getId();
      }

      return this.ref;
    }
  }

  pluralize(word: string) {
    if (word.endsWith('y')) {
      return word.slice(0, -1) + 'ies';
    } else {
      return word + 's';
    }
  }

  getOrgSlug(): string {
    const orgSlug = this.ref.split("/")[0];
    if (this.isRelative()) {
      if (this.parentOrgSlug) {
        return this.parentOrgSlug;
      } else {
        throw new Error("Parent org slug is required, this reference is relative");
      }
    } else {
      return orgSlug;
    }
  }

  getSlug(): string {
    if (this.isEntity()) {
      return this.ref.split("/")[1];
    } else {
      return this.ref.split("/")[1].split(":")[0];
    }

  }

  getVersion(): string {
    const version = this.ref.split("/")[1].split(":");
    if (version && version.length > 1) {
      return version[1];
    }
    return "1.0.0";
  }
}
