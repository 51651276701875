<script>
import { getIcon } from "material-file-icons";
export default {
  name: "FileIcon",
  props: ["filename"],
  computed: {
    svg() {
      return getIcon(this.filename).svg;
    },
  },
};
</script>

<template>
  <div v-html="svg" />
</template>
