<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import { Button, Toolbar } from "@progress/kendo-vue-buttons";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { TabStrip } from "@progress/kendo-vue-layout";
import { storeToRefs } from "pinia";
import { v4 as uuidv4 } from "uuid";
import type { PropType, Ref } from "vue";
import { plusIcon } from "@progress/kendo-svg-icons";
import { createDataFormViewerStore } from "~/store/useDataFormView";
import type { TagMetadata } from "~/store/useProject";
import type { Card, DataObject } from "~/model";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  designMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewId: {
    type: String,
    required: true,
  },
  parentDataObject: {
    type: Object as PropType<DataObject>,
    required: false,
    default: undefined,
  },
  parentTagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["addCard", "selectCard", "removeCard"]);

const useDataFormViewer = createDataFormViewerStore(props.viewId);
const {
  availableCardTypes,
} = storeToRefs(useDataFormViewer);

const tabs = computed(() => {
  const children = props.card.children || [];
  return children.map((child: Card) => {
    return {
      id: child.id,
      title: child.properties?.title,
      icon: child.properties?.icon,
      content: "tabContent",
      titleRender: "titleRender",
    };
  });
});

function addTab() {
  const newTab = {
    id: uuidv4(),
    type: "tab",
    properties: {
      title: "New Tab",
      icon: "folder",
    },
    children: [],
  };

  useDataFormViewer.addChildCard(props.card.id as string, newTab);
}

const currentTab: Ref<number> = ref(0);

function onSelect(e: any) {
  currentTab.value = e.selected;
}

function getCard(id: string) {
  return useDataFormViewer.findCard(id);
}

function updateCardType(id: string, event: any) {
  const cardCopy = { ...getCard(id) };
  cardCopy.type = event.target.value;
  useDataFormViewer.updateCard(cardCopy);
}
</script>

<template>
  <div>
    <Toolbar v-if="designMode">
      <Button :svg-icon="plusIcon" title="Add Card" :togglable="false" @click="addTab" />
    </Toolbar>
    <TabStrip :selected="currentTab" :tabs="tabs" class="-mt-1" @select="onSelect">
      <template #tabContent="{ props }">
        <div>
          <Toolbar v-if="designMode" class="my-1">
            <DropDownList
              :value="getCard(props.dataItem.id).type"
              fill-mode="outline"
              text-field="label"
              value-field="name"
              :value-primitive="true"
              data-item-key="name" :style="{ width: '350px' }"
              :data-items="availableCardTypes"
              @change="updateCardType(props.dataItem.id, $event)"
            />
          </Toolbar>
          <KodexaFormCard
            style="width: 100%"
            :card="getCard(props.dataItem.id)" :design-mode="designMode"
            :view-id="viewId"
            :parent-data-object="parentDataObject"
            :parent-tag-metadata="parentTagMetadata"
            @add-card="emit('addCard', $event)" @select-card="emit('selectCard', $event)"
            @remove-card="emit('removeCard', $event)"
          />
        </div>
      </template>
      <template #titleRender="{ props }">
        <span class="k-item k-state-default">
          <span class="text-sm p-0">{{ props.title }}</span>
          <span v-if="designMode" class="k-link">
            <span class="k-icon k-i-x" />
          </span>
        </span>
      </template>
    </TabStrip>
  </div>
</template>

<style scoped>
</style>
