<script setup lang="ts">
import type { PropType, Ref } from "vue";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import { storeToRefs } from "pinia";
import { notify } from "notiwind";
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import type { Taxon, TaxonFeatures, Taxonomy } from "~/model";
import { log } from "~/utils/logger";
import appStore from "~/store";
import { suggestTaxon } from "~/api/taxonomies/taxonomies";

const props = defineProps({
  modelValue: {
    type: Object as PropType<Taxon>,
    required: true,
  },
  taxonomy: {
    type: Object as PropType<Taxonomy>,
    required: true,
  },
  defaultTab: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits(["update:modelValue", "delete"]);

const currentNavigation = ref(undefined);

const tabs = computed(() => {
  const base = [
    {
      name: "Overview",
      ref: "overview",
      icon: "info",
    },
  ];

  if (props.modelValue?.taxonType !== "SECTION") {
    let title = "Prompt";
    if (props.modelValue?.valuePath === "FORMULA") {
      title = "Formula";
    }
    if (props.modelValue?.valuePath === "REVIEW") {
      title = "Review";
    }
    if (props.modelValue?.valuePath === "EXTERNAL") {
      title = "External";
    }
    if (props.modelValue?.valuePath === "EXPRESSION") {
      title = "Expression";
    }

    base.push({
      name: title,
      ref: "semantics",
      icon: "ignore",
    });
  }
  if (props.modelValue?.taxonType !== "SECTION" && !props.modelValue?.group) {
    base.push({
      name: "Normalization",
      ref: "normalization",
      icon: "ignore",
    });
    base.push({
      name: "Formatting",
      ref: "conditionalFormatting",
      icon: "ignore",
    });
  }

  // if (props.modelValue?.taxonType !== "SECTION") {
  //   base.push({
  //     name: "Rules",
  //     ref: "validationRules",
  //     icon: "ignore",
  //   });
  // }

  base.push({
    name: "Features",
    ref: "features",
    icon: "ignore",
  });

  base.push({
    name: "Properties",
    ref: "properties",
    icon: "ignore",
  });

  if (props.modelValue?.taxonType === "SELECTION") {
    base.push({
      name: "Values",
      ref: "selection",
      icon: "ignore",
    });
  } else {
    if (currentNavigation.value?.ref === "selection") {
      currentNavigation.value = base[0];
    }
  }

  if (currentNavigation.value === undefined) {
    currentNavigation.value = base[props.defaultTab || 0];
  }

  return base;
});

const typeFeatures = ref({});

const localTaxon: Ref<Taxon | undefined> = computed(() => {
  if (props.modelValue?.typeFeatures) {
    typeFeatures.value = props.modelValue.typeFeatures;
  }
  return props.modelValue;
});

watch(typeFeatures, () => {
  if (localTaxon.value) {
    localTaxon.value.typeFeatures = typeFeatures.value;
  }
}, { deep: true });

watch(localTaxon, () => {
  log.info("Local taxon changed");

  if (!localTaxon.value?.label) {
    localTaxon.value.label = "New Data Element";
  }

  emit("update:modelValue", localTaxon.value);
}, { deep: true });

const valuePaths = computed(() => {
  return [
    {
      id: "VALUE_OR_ALL_CONTENT",
      label: "Document",
    },
    {
      id: "METADATA",
      label: "Metadata",
    },
    {
      id: "DERIVED",
      label: "Derived",
    },
    {
      id: "FORMULA",
      label: "Formula",
    },
    {
      id: "EXPRESSION",
      label: "Expression",
    },
    {
      id: "REVIEW",
      label: "Review",
    },
    {
      id: "EXTERNAL",
      label: "External",
    },
  ];
});

// Taxon Types can be one of STRING, DATE, DATE_TIME, NUMBER, BOOLEAN, CURRENCY, URL, EMAIL_ADDRESS, PHONE_NUMBER, SELECTION

const taxonTypes = [
  {
    id: "STRING",
    label: "String",
  },
  {
    id: "DATE",
    label: "Date",
  },
  {
    id: "DATE_TIME",
    label: "Date Time",
  },
  {
    id: "NUMBER",
    label: "Number",
  },
  {
    id: "BOOLEAN",
    label: "Boolean",
  },
  {
    id: "CURRENCY",
    label: "Currency",
  },
  {
    id: "URL",
    label: "URL",
  },
  {
    id: "EMAIL_ADDRESS",
    label: "Email Address",
  },
  {
    id: "PHONE_NUMBER",
    label: "Phone Number",
  },
  {
    id: "PERCENTAGE",
    label: "Percentage",
  },
  {
    id: "SELECTION",
    label: "Selection",
  },
  {
    id: "SECTION",
    label: "Element Grouping",
  },
];

const metadataTypes = [{ id: "FILENAME", name: "Filename" }, {
  id: "TRANSACTION_UUID",
  name: "Transaction UUID",
}, { id: "CREATED_DATETIME", name: "Created Datetime" }, {
  id: "DOCUMENT_LABELS",
  name: "Document Labels",
}, { id: "OWNER_NAME", name: "Document Owner" }, { id: "DOCUMENT_STATUS", name: "Document Status" }];

const deleteTaxon = function () {
  emit("delete");
};

watch(() => localTaxon.value?.label, (newValue) => {
  if (newValue && localTaxon.value) {
    // We want to replace the externalName with the label, removing any spaces and special characters
    localTaxon.value.externalName = newValue.replace(/[^a-z0-9]/gi, "");
  }
});

const supportsLongText = computed(() => {
  const notSupported = ["SECTION", "DATE", "DATE_TIME", "NUMBER", "BOOLEAN", "CURRENCY", "URL", "EMAIL_ADDRESS", "PHONE_NUMBER", "PERCENTAGE"];
  return localTaxon.value && localTaxon.value.taxonType && !notSupported.includes(localTaxon.value.taxonType) && !localTaxon.value.group;
});

const groupExternal = computed({
  get: () => localTaxon.value?.valuePath === "EXTERNAL",
  set: (value) => {
    if (localTaxon.value) {
      localTaxon.value.valuePath = value ? "EXTERNAL" : "VALUE_OR_ALL_CONTENT";
    }
  },
});

const { modelMetadata } = storeToRefs(appStore.projectStore);

const additionalTaxonFeatures = computed(() => {
  // We need to load up all the additional taxon features
  // from the models and assistant definitions
  const finalFeatures = [] as TaxonFeatures[];
  Array.from(modelMetadata.value.values()).forEach((modelMetadata) => {
    modelMetadata?.taxonFeatures?.forEach((taxonFeature) => {
      if (localTaxon.value?.group && taxonFeature.groupOnly) {
        finalFeatures.push(taxonFeature);
      } else if (!localTaxon.value?.group && !taxonFeature.groupOnly) {
        finalFeatures.push(taxonFeature);
      }
    });
  });
  return finalFeatures;
});

const suggesting = ref(false);

function extractGroovyCode(response: string): string | null {
  const groovyRegex = /```groovy([\s\S]*?)```/;
  const match = response.match(groovyRegex);

  if (match) {
    return match[1].trim(); // Return the Groovy code block without the ```groovy markers
  } else {
    return null; // No Groovy code block found
  }
}

async function suggest() {
  if (localTaxon.value) {
    suggesting.value = true;
    try {
      const { contentTaxonomies } = storeToRefs(appStore.projectStore);

      const taxonomies = {};

      contentTaxonomies.value.forEach((taxonomy) => {
        taxonomies[taxonomy.name] = taxonomy;
      });

      const suggestionResponse = await suggestTaxon({ taxonomies, taxon: localTaxon.value });

      log.info(`Suggestion prompt: ${suggestionResponse.prompt}`);

      const suggestion = suggestionResponse.suggestion;

      suggesting.value = false;

      log.info(`Suggested ${suggestion}`);
      if (suggestion) {
        if (localTaxon.value.valuePath === "FORMULA") {
          localTaxon.value.semanticDefinition = suggestion;
        } else if (localTaxon.value.valuePath === "REVIEW") {
          localTaxon.value.semanticDefinition = suggestion;
        } else if (localTaxon.value.valuePath === "VALUE_ONLY" || localTaxon.value.valuePath === "VALUE_OR_ALL_CONTENT") {
          localTaxon.value.semanticDefinition = suggestion;
        } else {
          localTaxon.value.expression = extractGroovyCode(suggestion) || suggestion;
        }
      }
    } catch (e) {
      notify({
        group: "error",
        title: "Unable to suggest at this time",
        text: "We were unable to suggest a semantic definition for this data element",
      }, 5000);
      suggesting.value = false;
    }
  }
}

const items = [
  { id: "string", text: "String" },
  { id: "number", text: "Number" },
  { id: "boolean", text: "Boolean" },
];

function addOption() {
  if (!localTaxon.value) {
    localTaxon.value.options = [];
  }

  localTaxon.value?.options?.push({ name: "", label: "", type: "string", description: "" });
}
function deleteOption(idx: number) {
  localTaxon.value?.options?.splice(idx, 1);
}

function addValue(option: any) {
  option.values.push({ label: "", value: "" });
}

function removeValue(option: any, index: number) {
  option.values.splice(index, 1);
}

const newSynonym = ref("");

function addSynonym(synonym: string) {
  localTaxon.value.synonyms = localTaxon.value.synonyms || [];
  if (!localTaxon.value.synonyms.includes(synonym)) {
    localTaxon.value.synonyms.push(synonym);
    newSynonym.value = "";
  }
}

function removeSynonym(synonym: string) {
  localTaxon.value.synonyms = localTaxon.value.synonyms || [];
  localTaxon.value.synonyms = localTaxon.value.synonyms.filter(s => s !== synonym);
}
</script>

<template>
  <div>
    <div class="mt-2 border-b border-gray-200 dark:border-gray-700">
      <div class="overflow-x-auto">
        <ul v-if="localTaxon" class="-mb-px ml-2 flex text-center text-sm font-medium text-gray-500 dark:text-gray-400">
          <li
            v-for="(item) in tabs" :key="item.ref"
            class="mr-2"
            @click="currentNavigation = item"
          >
            <a
              class="text-sm"
              :class="item.ref === currentNavigation?.ref ? 'inline-flex items-center justify-center p-2 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-2 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
            >
              <MaterialDesignIcon
                v-if="item?.icon" :name="item.icon" size="12"
                class="text-theme-primary mr-1"
              />
              {{ item?.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="currentNavigation.ref === 'overview'" class="mx-2 mt-2">
      <KodexaArticle article-id="9117975" text="Learn about data elements" :slide="false" />

      <KodexaTextInput id="taxonName" v-model="localTaxon.label" label="Name" type="text" name="name" />
      <KodexaTextArea v-model="localTaxon.description" label="Description" class="mt-2" name="description" :rows="10" />
      <KodexaButton
        v-if="modelValue.valuePath === 'FORMULA' || modelValue.valuePath === 'REVIEW' || modelValue.valuePath === 'EXTERNAL' || modelValue.valuePath === 'EXPRESSION' || modelValue.valuePath === 'VALUE_ONLY' || modelValue.valuePath === 'VALUE_OR_ALL_CONTENT'"
        size="small" type="secondary" class="mt-2" icon="creation-outline" :loading="suggesting" @click="suggest"
      >
        Generate {{ modelValue.valuePath === 'FORMULA' ? 'Formula' : modelValue.valuePath === 'REVIEW' ? 'Review' : (modelValue.valuePath === 'VALUE_ONLY' || modelValue.valuePath === 'VALUE_OR_ALL_CONTENT') ? 'Prompt' : 'Expression' }}
      </KodexaButton>
      <KodexaCheckbox
        v-model="localTaxon.enabled"
        label="Enabled" name="group" class="mt-2"
        hint="This data element is enabled and can be used in the system."
      />
      <KodexaCheckbox
        v-if="localTaxon.taxonType !== 'SECTION'"
        v-model="localTaxon.group" :disabled="localTaxon.children && localTaxon.children.length > 0"
        label="Data Group" name="group" class="mt-2"
        hint="A group has no inherent data, it is a container for other data elements."
      />
      <ColorPicker
        v-if="localTaxon.taxonType !== 'SECTION' && !localTaxon.group && localTaxon.valuePath !== 'METADATA' && localTaxon.valuePath !== 'FORMULA'"
        v-model="localTaxon.color"
        title="Color" class="mt-2" view="gradient"
      />
      <KodexaDropDown
        v-if="!localTaxon.group"
        v-model="localTaxon.valuePath"
        class="mt-1"
        label="Source" name="valuePath" :items="valuePaths"
        hint="Where do you get the data from?"
        value-field="id" text-field="label"
      />
      <KodexaDropDown
        v-if="localTaxon.valuePath !== 'METADATA' && !localTaxon.group"
        v-model="localTaxon.taxonType" class="mt-2"
        label="Data Type" name="taxonType" :items="taxonTypes"
        value-field="id" text-field="label"
        :filterable="true"
      />

      <KodexaCheckbox
        v-if="localTaxon.group"
        id="groupExternal"
        v-model="groupExternal" label="Create using External Data"
        name="isExpression" class="mt-2"
      />

      <KodexaDropDown
        v-if="localTaxon.valuePath === 'METADATA' && !localTaxon.group"
        v-model="localTaxon.metadataValue" label="Metadata Value"
        class="mt-2"
        name="metadataValue"
        value-field="id" text-field="name"
        hint="Choose the metadata you wish to use"
        :items="metadataTypes"
      />
      <KodexaCheckbox
        v-if="supportsLongText" v-model="typeFeatures.longText"
        label="Long Text" name="longText" class="mt-2"
        hint="Present this information as a long text field"
      />
      <KodexaCheckbox
        v-if="supportsLongText" v-model="typeFeatures.markdown"
        label="Allow Markdown" name="longText" class="mt-2"
        hint="Use markdown to help with the formatting of the text"
      />
      <KodexaCheckbox
        v-if="supportsLongText" v-model="typeFeatures.summarize"
        label="Summarize" name="summarize" class="mt-2"
        hint="Summarize content before capture"
      />
      <KodexaCheckbox
        v-if="!localTaxon.group && !localTaxon.formula && localTaxon.taxonType !== 'SECTION'"
        v-model="typeFeatures.expected"
        label="Expected" name="expected" class="mt-2"
        hint="This data element is expected to be present"
      />
      <KodexaCheckbox
        v-model="localTaxon.generateName" label="Use Generated ID"
        name="generateName" class="mt-2"
      />
      <KodexaTextInput
        v-if="!localTaxon.generateName" id="taxonInternalID" v-model="localTaxon.name"
        label="Internal ID" type="text" class="mt-2" name="name"
      />
      <KodexaTextInput
        id="taxonExternalName" v-model="localTaxon.externalName" label="External Name"
        type="text" class="mt-2" name="externalName"
      />
      <KodexaButton size="small" type="danger" class="mt-8" icon="delete" @click="deleteTaxon">
        Delete Data Element
      </KodexaButton>
    </div>
    <div v-if="currentNavigation.ref === 'semantics'" class="mx-2 mt-2">
      <KodexaArticle
        v-if="modelValue.valuePath === 'FORMULA'" article-id="9117970" text="Learn about using a formula"
        :slide="false"
      />
      <KodexaArticle
        v-else-if="modelValue.valuePath === 'REVIEW'" article-id="9702349" text="Learn about using a review templates"
        :slide="false"
      />
      <KodexaArticle
        v-else-if="modelValue.valuePath === 'EXPRESSION'" article-id="9702349" text="Learn about using a review templates"
        :slide="false"
      />
      <KodexaArticle
        v-else-if="modelValue.valuePath === 'EXTERNAL'" article-id="9702066" text="Learn more about external data"
        class="mt-1"
        :slide="false"
      />
      <KodexaArticle
        v-else article-id="9117973" text="Learn how you can use a prompt to improve data capture"
        :slide="false"
      />
      <KodexaCodeEditor
        v-if="localTaxon.valuePath === 'EXPRESSION'" v-model="localTaxon.expression"
        label="Expression" name="expression" :rows="20" style="height: calc(100vh - 400px)" language="groovy"
        :loading="suggesting"
      />
      <KodexaCodeEditor
        v-else-if="localTaxon.valuePath === 'EXTERNAL'" v-model="localTaxon.expression" language="groovy"
        label="External Data Expression" name="expression" :rows="20" style="height: calc(100vh - 400px)" :minimap="false"
        :loading="suggesting"
      />
      <FormulaInput
        v-else-if="modelValue.valuePath === 'FORMULA'"
        v-model="localTaxon.semanticDefinition"
        :loading="suggesting"
        :taxon="localTaxon"
        :taxonomy="taxonomy"
        :num-rows="18"
        :toggle-available-elements="false"
      />
      <KodexaCodeEditor
        v-else-if="modelValue.valuePath === 'REVIEW'"
        v-model="localTaxon.semanticDefinition"
        :loading="suggesting"
        language="jinja"
        style="height: calc(100vh - 400px)"
        name="semanticDefinition"
      />
      <KodexaTextArea
        v-else
        v-model="localTaxon.semanticDefinition"
        :loading="suggesting"
        :rows="18"
        name="semanticDefinition"
      />
      <div v-if="(localTaxon?.valuePath === 'VALUE_ONLY' || localTaxon.valuePath === 'VALUE_OR_ALL_CONTENT') || localTaxon.group" class="synonyms-section space-y-4">
        <div class="mt-3">
          <p class="font-bold">
            Additional Context
          </p>
          <p>In this section, you can provide additional information about the data element. This will be used in processes like classification or when identifying record groupings.</p>
        </div>

        <ul class="space-y-2">
          <li v-for="(synonym, idx) in localTaxon.synonyms" :key="idx" class="flex items-start space-x-2">
            <KodexaTextArea
              v-model="localTaxon.synonyms[idx]"
              class="flex-grow"
              :rows="6"
            />
            <KodexaButton
              type="danger"
              size="small"
              icon="delete"
              class="flex-shrink-0"
              @click="removeSynonym(synonym)"
            />
          </li>
        </ul>

        <div class="space-y-2">
          <KodexaButton
            type="secondary"
            size="small"
            icon="plus"
            class="w-full sm:w-auto"
            @click="addSynonym(newSynonym)"
          >
            Add Additional Context
          </KodexaButton>
        </div>
      </div>
    </div>
    <div v-if="currentNavigation.ref === 'features'" class="mx-2 mt-2">
      <!-- We will include the options from models that added them to taxons -->
      <div v-if="additionalTaxonFeatures">
        <div v-for="additionalTaxonFeature in additionalTaxonFeatures">
          <ConfigurationOption
            v-for="option in additionalTaxonFeature.options"
            :key="option.name"
            v-model="typeFeatures"
            :item="option"
          />
        </div>
      </div>
      <div v-else>
        <div class="mt-2 text-gray-600">
          Not additional features
        </div>
      </div>
    </div>
    <div v-if="currentNavigation.ref === 'normalization'" class="mx-2 mt-2">
      <KodexaArticle article-id="9117988" text="Learn about normalizing data" :slide="false" />

      <KodexaCheckbox v-model="localTaxon.nullable" label="Nullable" name="nullable" class="mt-2" />

      <KodexaCheckbox
        v-model="localTaxon.enableFallbackExpression" label="Enable Fallback Expression"
        name="enableFallbackExpression" class="mt-2"
      />

      <KodexaCodeEditor
        v-if="localTaxon.enableFallbackExpression" v-model="localTaxon.fallbackExpression" language="groovy"
        label="Fallback Expression" name="fallbackExpression" class="mt-2 h-80"
      />

      <KodexaCheckbox v-model="localTaxon.multiValue" label="Multivalue" name="multivalue" class="mt-2" />

      <KodexaCheckbox v-model="localTaxon.userEditable" label="User Editable" name="userEditable" class="mt-2" />

      <KodexaCheckbox
        v-model="localTaxon.notUserLabelled" label="Not User Labelled" name="notUserLabelled"
        class="mt-2"
      />
    </div>
    <div v-if="currentNavigation.ref === 'selection'" class="mx-2 mt-2">
      <SelectionOptions v-model="localTaxon.selectionOptions" />
    </div>
    <div v-if="currentNavigation.ref === 'conditionalFormatting'" class="mx-2 mt-2" style="height: calc(100vh - 200px)">
      <ConditionalFormatting
        v-model="localTaxon.conditionalFormats"
        :taxon="localTaxon"
        :taxonomy="taxonomy"
      />
    </div>
    <div v-if="currentNavigation.ref === 'properties'" class="mx-2 mt-2">
      <Toolbar class="border-0">
        <ToolbarSpacer />
        <KodexaButton
          name="add" icon="plus"
          size="small"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" @click="addOption"
        >
          Add Option
        </KodexaButton>
      </Toolbar>
      <div class="p-4 space-y-4">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div v-for="(option, idx) in localTaxon.options" :key="idx" class="p-4 shadow-lg rounded-lg">
            <KodexaTextInput
              v-model="option.name"
              class="mt-2"
              name="name"
              label="Name"
            />
            <KodexaTextInput
              v-model="option.label"
              class="mt-2"
              name="label"
              label="Label"
            />
            <KodexaTextInput
              v-model="option.description"
              class="mt-2"
              name="description"
              label="Description"
            />
            <KodexaDropDown
              v-model="option.type"
              class="mt-2"
              name="type"
              :items="items"
              label="Type"
            />

            <div v-if="option.type === 'string'" class="mt-4">
              <h4 class="font-semibold mb-2">
                Possible Values
              </h4>
              <div v-for="(value, index) in values" :key="index" class="flex items-center mb-2">
                <KodexaTextInput
                  v-model="value.label"
                  class="mr-2"
                  name="label"
                  label="Label"
                />
                <KodexaTextInput
                  v-model="value.value"
                  class="mr-2"
                  name="value"
                  label="Value"
                />
                <KodexaButton name="delete" icon="delete" type="danger" size="small" @click="removeValue(option, index)">
                  Delete
                </KodexaButton>
              </div>
              <KodexaButton name="add" icon="add" type="primary" size="small" @click="addValue(option)">
                Add Value
              </KodexaButton>
            </div>

            <KodexaButton name="delete" icon="delete" type="danger" class="mt-2" size="small" @click="deleteOption(idx)">
              Delete
            </KodexaButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
