<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import type { PropType } from "vue";
import type { ExtensionPack } from "~/model";
import appStore from "~/store";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  extensionPack: {
    type: Object as PropType<ExtensionPack>,
    required: true,
  },
});
const emit = defineEmits(["confirm", "cancel", "update:modelValue"]);

const deployCopy = ref(undefined);

const localDeployment = computed({
  get() {
    if (deployCopy.value === undefined) {
      deployCopy.value = JSON.parse(JSON.stringify(props.extensionPack.deployment));
    }
    return deployCopy.value;
  },
  set(value) {
    deployCopy.value = value;
  },
});

const deploymentType = computed({
  get() {
    return localDeployment.value.deploymentType;
  },
  set(value) {
    deployCopy.value.deploymentType = value;
  },
});

function close() {
  emit("update:modelValue", false);
}

const deploying = ref(false);

async function deployExtensionPack() {
  deploying.value = true;
  await appStore.platformStore.deployExtensionPack(props.extensionPack, localDeployment.value);
  deploying.value = false;
  emit("confirm");
  emit("update:modelValue", false);
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative" style="z-index: 100" @close="emit('update:modelValue', false)">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 950px; height: 440px"
              class="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-4"
            >
              <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                <button
                  type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  @click="emit('update:modelValue', false)"
                >
                  <span class="sr-only">Close</span>
                  <MaterialDesignIcon name="close" class="h-4 w-4" aria-hidden="true" />
                </button>
              </div>
              <DialogTitle class="flex text-lg font-medium leading-4 text-gray-900">
                <div class="sm:flex sm:items-center sm:justify-between">
                  <div class="sm:flex sm:space-x-5">
                    <div class="shrink-0" />
                    <div class="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                      <p class="text-xl font-bold text-gray-900 sm:text-2xl">
                        Deploying {{ extensionPack.name }}
                      </p>
                      <p class="text-sm font-medium text-gray-400">
                        Confirm your deployment settings for the extension pack
                      </p>
                    </div>
                  </div>
                </div>
              </DialogTitle>
              <div class="p-5">
                <KodexaDropDown
                  v-model="deploymentType"
                  name="deploymentType" :items="[{
                    text: 'AWS Lambda Deployment',
                    id: 'AWS_LAMBDA',
                  }, {
                    text: 'Kubernetes Deployment',
                    id: 'KUBERNETES',
                  }]" class="w-3/6" label="Deployment Type"
                />

                <div v-if="deploymentType === 'KUBERNETES'">
                  <KodexaNumericInput
                    v-model="localDeployment.minReplicas" name="minReplicas"
                    label="Minimum Replicas"
                    class="w-3/6 mt-2"
                  />
                  <KodexaNumericInput
                    v-model="localDeployment.maxReplicas" name="maxReplicas"
                    label="Maximum Replicas"
                    class="w-3/6 mt-2"
                  />
                </div>
                <div v-if="deploymentType === 'AWS_LAMBDA'">
                  <KodexaNumericInput
                    v-model="localDeployment.reservedConcurrency"
                    name="reservedConcurrency"
                    label="Reserved Concurrency" class="w-3/6 mt-2"
                  />
                  <KodexaNumericInput
                    v-model="localDeployment.memoryAssigned" name="memoryAssigned"
                    label="Memory Assigned"
                    class="w-3/6 mt-2"
                  />
                </div>
                <KodexaCheckbox
                  v-model="localDeployment.childProcess" name="childProcess"
                  label="Use Child Process" class="w-3/6 mt-2"/>
              </div>
              <div>
                <div
                  class="mr-4 mt-8 sm:flex sm:flex-row-reverse"
                >
                  <KodexaButton
                    icon="rocket"
                    :loading="deploying"
                    @click="deployExtensionPack"
                  >
                    Deploy
                  </KodexaButton>
                  <KodexaButton
                    class="mr-2"
                    icon="cancel" type="secondary"
                    :disabled="deploying"
                    @click="close"
                  >
                    Cancel
                  </KodexaButton>
                </div>
              </div>
            </dialogpanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
