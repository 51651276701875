<script setup lang="ts">
import type { PropType, Ref } from "vue";
import appStore from "~/store";
import type { Guidance, GuidanceSet } from "~/model";

const props = defineProps({

  guidance: {
    type: Object as PropType<Guidance>,
    required: false,
  },
  guidanceSet: {
    type: Object as PropType<GuidanceSet>,
    required: false,
  },
});
const emit = defineEmits(["confirm", "cancel", "close"]);

const localCopy: Ref<Guidance | undefined> = ref(undefined);

const localGuidance = computed({
  get() {
    if (localCopy.value) return localCopy.value;
    return props.guidance;
  },
  set(value) {
    localCopy.value = value;
  },
});

function save() {

  // We will use the name to replace the guidance in the guidance set
  if (localCopy.value?.name) {
    localCopy.value.name = localCopy.value.name.trim();
  }



  appStore.projectStore.updateGuidanceInGuidanceSet(props.guidanceSet?.ref, localCopy.value);
  emit('close')
}

const options = [
  { type: "string",
    name: "name",
    label: "Name" },
  { type: "string",
    name: "userInstructions",
    label: "User Instructions" },
  { type: "object",
    name: "userInstructionsProperties",
    groupOptions: [
      { type: "string",
        name: "type_of_correction",
        label: "Type of Correction" },
    ]},
  { type: "object",
    name: "compiledGuidance",
    groupOptions: [
      { type: "code",
        name: "generated_code",
        label: "Generated Code",
        properties: {
          language: "python",
          height: "500px",
        }}
    ]}
];
</script>

<template>
  <div v-if="localGuidance" style="width: 900px; height: 520px" class="mt-1 overflow-scroll px-1">
    <KodexaCheckbox v-model="localGuidance.active" name="active" label="Active" class="mt-1" />
    <ConfigurationOption v-for="(option, idx) in options" :key="idx" v-model="localGuidance" :item="option" />
  </div>

  <div class="right-0 mt-2">
    <KodexaButton type="primary" class="mr-1" @click="save">
      Save
    </KodexaButton>
    <KodexaButton type="secondary" @click="emit('close')">
      Cancel
    </KodexaButton>
  </div>
</template>
