<script setup lang="ts">
const props = defineProps({
  status: String,
  size: {
    type: String,
    default: "24",
  },
});

const statusColors = {
  CANCELLED: "text-red-400",
  CANCELLING: "text-red-400",
  RUNNING: "text-green-400",
  PENDING_REPROCESSING: "text-yellow-400",
  SUCCEEDED: "text-green-400",
  FAILED: "text-red-400",
  PENDING: "text-yellow-400",
  AVAILABLE: "text-green-400",
  DEPLOYING: "text-yellow-400",
  UNDEPLOYED: "text-yellow-400",
};

const classes = computed(() => {
  const classes = [];
  if (props.status) {
    classes.push(statusColors[props.status]);
    if (props.status === "RUNNING" || props.status === "PENDING" || props.status === "PENDING_REPROCESSING" || props.status === "DEPLOYING") {
      classes.push("mdi-spin");
    }
  }
  return classes;
});
</script>

<template>
  <div>
    <MaterialDesignIcon
      :classes="classes" :name="props.status"
      :size="size"
    />
  </div>
</template>

<style scoped>

</style>
