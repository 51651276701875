import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import type { ILogObj } from "tslog";
import { Logger } from "tslog";
import type { Ref } from "vue";
import { createPlatformUser, deletePlatformUser, updatePlatformUser } from "~/api/users/users";
import { auth0 } from "~/main";
import { customAxios } from "~/api/custom-axios";
import type { Membership, Project, User, UserRecent } from "~/model";
import { getAccountMemberships, getMe, updateRecentEntity } from "~/api/account/account";
import appStore from "~/store/index";

export const useAccount = defineStore("user", () => {
  const log: Logger<ILogObj> = new Logger({ name: "accountLogger" });
  const userJwtToken: Ref<string | undefined> = ref(useStorage("userJwtToken", ""));
  const user = ref<User>();
  const memberships = ref<Membership[]>([]);
  const platformAdmin = ref<boolean>(false);
  const hasChanges = ref<boolean>(false);
  function setHasChanges(value: boolean) {
    hasChanges.value = value;
  }

  function isLoggedIn(): Promise<boolean> {
    return new Promise((resolve) => {
      if (userJwtToken.value && user.value) {
        return resolve(true);
      } else {
        loadUser().then(() => {
          log.info("User loaded");
          appStore.platformStore.loadPlatformOverview();
          resolve(true);
        }).catch(() => {
          userJwtToken.value = undefined;
          user.value = undefined;
          resolve(false);
        });
      }
    });
  }

  function addRecentProject(project: Project) {
    log.info(`Adding recent project ${project.id}`);
    const userRecent = {
      user: user.value,
      project,
    } as UserRecent;
    updateRecentEntity(userRecent).then(() => {
      log.info("Recent project updated");
    });
  }

  function logout() {
    return new Promise((resolve) => {
      userJwtToken.value = undefined;
      user.value = undefined;
      memberships.value = [];

      if (auth0) {
        auth0.logout({
          logoutParams: {
            returnTo: `${window.location.protocol}//${window.location.host}/`,
          },
        }).then();
      }
      resolve(true);
    });
  }

  function loadUser() {
    return new Promise((resolve, reject) => {
      memberships.value = [];
      getMe().then((response) => {
        user.value = response;

        // We need to get the membership details for the user
        getAccountMemberships().then((userMemberships) => {
          memberships.value = userMemberships;

          // We need to determine if user is a platform admin
          customAxios({
            url: "/api/account/admin",
            method: "GET",
          }).then((response) => {
            platformAdmin.value = response as boolean;
            resolve(user.value);
          });
        });
      }).catch((error: any) => {
        log.error("Error getting user");
        reject(error);
      });
    });
  }

  function hasNoMemberships(): boolean {
    // Check if the user has a membership, if not proceed to create one by going to the
    // payment page
    return !platformAdmin.value && (!memberships.value || memberships.value.length === 0);
  }

  function hasNoUserDetails(): boolean {
    // Check if the user has a first name and last name, if not proceed to create one by going to the
    // user profile popup
    return !user.value?.firstName || !user.value?.lastName;
  }

  function singleMembershipPath(): string | undefined {
    if (!platformAdmin.value && memberships.value && memberships.value.length === 1) {
      return `/a/o/${memberships.value[0].organization?.id}/home`;
    } else {
      return undefined;
    }
  }

  function needsToAcceptTerms(): boolean {
    return !!user.value?.hasAcceptedTerms;
  }

  // Update that the user has accepted the terms and conditions.
  // This is needed to prevent the user from being redirected to the terms and conditions page
  function updateHasAcceptedTerms() {
    if (user.value) {
      user.value.hasAcceptedTerms = true;
    }
  }

  function login(signUp: boolean): Promise<boolean> {
    return new Promise((resolve, reject) => {
      log.info("Starting authentication");
      if (!auth0) {
        log.error("Auth0 not initialized");
        reject(new Error("Auth0 not initialized"));
      } else {
        const lasVisitedUrl = localStorage.getItem('lastVisitedUrl');
        auth0.loginWithRedirect({
          appState: {
            target: lasVisitedUrl == null ? "/a/home" : lasVisitedUrl,
          },
          authorizationParams: {
            prompt: "login",
            screen_hint: signUp ? "signup" : "login",
          },
        }).then(() => {
          loadUser().then(() => {
            resolve(true);
            log.info("User loaded");
            appStore.platformStore.loadPlatformOverview();
          }).catch((error) => {
            log.error("Error getting user");
            reject(error);
          });
        });
      }
    });
  }

  function updateCurrentUser(user: User) {
    return new Promise<User>((resolve, reject) => {
      if (user.id != null) {
        updatePlatformUser(user.id, user).then((updatedUser) => {
          log.info("User updated", updatedUser);
          setHasChanges(true);
          resolve(updatedUser);
        }).catch((error) => {
          log.info("Error updating user");
          reject(error);
        });
      } else {
        reject(new Error("User id is null"));
      }
    });
  }

  // Admin-specific user functions
  async function createNewUser(user: User): Promise<User> {
    try {
      if (!user) {
        throw new Error("User details undefined");
      }
      const newUser: User = await createPlatformUser(user);
      log.info("User successfully created", newUser);
      return newUser;
    } catch (error) {
      log.info("Error creating user");
      throw error;
    }
  }

  async function deleteUser(user: User): Promise<void> {
    try {
      if (!user) {
        throw new Error("User details undefined");
      }
      if (user.id) {
        await deletePlatformUser(user.id);
        log.info("User successfully deleted", user);
      }
    } catch (error) {
      log.info("Error deleting user");
      throw error;
    }
  }

  async function updateUser(updatedUser: User): Promise<void> {
    try {
      const result = await updatePlatformUser(updatedUser.id as string, updatedUser);
      // Do not update hasAcceptedTerms
      if (result) {
        user.value = { ...result, hasAcceptedTerms: user.value?.hasAcceptedTerms };
        setHasChanges(false);
      }
      log.info("User successfully updated", updatedUser);
    } catch (error) {
      log.info("Error updating user", error);
      throw error;
    }
  }

  function toggleDeveloperTools() {
    if (user.value) {
      user.value.showDeveloperTools = !user.value?.showDeveloperTools;
    }
  }

  return {
    login,
    userJwtToken,
    logout,
    user,
    addRecentProject,
    isLoggedIn,
    updateCurrentUser,
    createNewUser,
    deleteUser,
    memberships,
    needsToAcceptTerms,
    updateHasAcceptedTerms,
    hasNoMemberships,
    hasNoUserDetails,
    singleMembershipPath,
    updateUser,
    loadUser,
    platformAdmin,
    toggleDeveloperTools,
    hasChanges,
    setHasChanges,
  };
});
