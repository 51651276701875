/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  Activity
} from '../../model/activity'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  ListActivitiesParams
} from '../../model/listActivitiesParams'
import type {
  ListTasksParams
} from '../../model/listTasksParams'
import type {
  PageActivity
} from '../../model/pageActivity'
import type {
  PageTask
} from '../../model/pageTask'
import type {
  Task
} from '../../model/task'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get a resource with the provided ID
 */
export const getTask = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Task>(
      {url: `/api/tasks/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaskQueryKey = (id: MaybeRef<string>,) => {
    return ['api','tasks',id] as const;
    }

    
export const getGetTaskQueryOptions = <TData = Awaited<ReturnType<typeof getTask>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTask>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTaskQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTask>>> = ({ signal }) => getTask(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTask>>, TError, TData> 
}

export type GetTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getTask>>>
export type GetTaskQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetTask = <TData = Awaited<ReturnType<typeof getTask>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTask>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTaskQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateTask = (
    id: MaybeRef<string>,
    task: MaybeRef<Task>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
task = unref(task);
      
      return customAxios<Task>(
      {url: `/api/tasks/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: task
    },
      options);
    }
  


export const getUpdateTaskMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTask>>, TError,{id: string;data: Task}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTask>>, TError,{id: string;data: Task}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTask>>, {id: string;data: Task}> = (props) => {
          const {id,data} = props ?? {};

          return  updateTask(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateTaskMutationResult = NonNullable<Awaited<ReturnType<typeof updateTask>>>
    export type UpdateTaskMutationBody = Task
    export type UpdateTaskMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateTask = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTask>>, TError,{id: string;data: Task}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteTask = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/tasks/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTaskMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTask>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTask>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTask>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteTask(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteTaskMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTask>>>
    
    export type DeleteTaskMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteTask = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTask>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a resource with the provided ID
 */
export const getActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<Activity>(
      {url: `/api/activities/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetActivityQueryKey = (id: MaybeRef<string>,) => {
    return ['api','activities',id] as const;
    }

    
export const getGetActivityQueryOptions = <TData = Awaited<ReturnType<typeof getActivity>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetActivityQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivity>>> = ({ signal }) => getActivity(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getActivity>>, TError, TData> 
}

export type GetActivityQueryResult = NonNullable<Awaited<ReturnType<typeof getActivity>>>
export type GetActivityQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetActivity = <TData = Awaited<ReturnType<typeof getActivity>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetActivityQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the given instance
 */
export const updateActivity = (
    id: MaybeRef<string>,
    activity: MaybeRef<Activity>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
activity = unref(activity);
      
      return customAxios<Activity>(
      {url: `/api/activities/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: activity
    },
      options);
    }
  


export const getUpdateActivityMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateActivity>>, TError,{id: string;data: Activity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateActivity>>, TError,{id: string;data: Activity}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateActivity>>, {id: string;data: Activity}> = (props) => {
          const {id,data} = props ?? {};

          return  updateActivity(id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateActivityMutationResult = NonNullable<Awaited<ReturnType<typeof updateActivity>>>
    export type UpdateActivityMutationBody = Activity
    export type UpdateActivityMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateActivity = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateActivity>>, TError,{id: string;data: Activity}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the resource with the provided ID
 */
export const deleteActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      id = unref(id);
      
      return customAxios<void>(
      {url: `/api/activities/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteActivityMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteActivity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteActivity>>, TError,{id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteActivity>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  deleteActivity(id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteActivityMutationResult = NonNullable<Awaited<ReturnType<typeof deleteActivity>>>
    
    export type DeleteActivityMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteActivity = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteActivity>>, TError,{id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listTasks = (
    params?: MaybeRef<ListTasksParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageTask>(
      {url: `/api/tasks`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListTasksQueryKey = (params?: MaybeRef<ListTasksParams>,) => {
    return ['api','tasks', ...(params ? [params]: [])] as const;
    }

    
export const getListTasksQueryOptions = <TData = Awaited<ReturnType<typeof listTasks>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListTasksParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTasks>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListTasksQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTasks>>> = ({ signal }) => listTasks(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTasks>>, TError, TData> 
}

export type ListTasksQueryResult = NonNullable<Awaited<ReturnType<typeof listTasks>>>
export type ListTasksQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListTasks = <TData = Awaited<ReturnType<typeof listTasks>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListTasksParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTasks>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTasksQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createTask = (
    task: MaybeRef<Task>,
 options?: SecondParameter<typeof customAxios>,) => {
      task = unref(task);
      
      return customAxios<Task>(
      {url: `/api/tasks`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: task
    },
      options);
    }
  


export const getCreateTaskMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTask>>, TError,{data: Task}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createTask>>, TError,{data: Task}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createTask>>, {data: Task}> = (props) => {
          const {data} = props ?? {};

          return  createTask(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateTaskMutationResult = NonNullable<Awaited<ReturnType<typeof createTask>>>
    export type CreateTaskMutationBody = Task
    export type CreateTaskMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateTask = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createTask>>, TError,{data: Task}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateTaskMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List a page of the resources
 */
export const listActivities = (
    params?: MaybeRef<ListActivitiesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageActivity>(
      {url: `/api/activities`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListActivitiesQueryKey = (params?: MaybeRef<ListActivitiesParams>,) => {
    return ['api','activities', ...(params ? [params]: [])] as const;
    }

    
export const getListActivitiesQueryOptions = <TData = Awaited<ReturnType<typeof listActivities>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListActivitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listActivities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListActivitiesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listActivities>>> = ({ signal }) => listActivities(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listActivities>>, TError, TData> 
}

export type ListActivitiesQueryResult = NonNullable<Awaited<ReturnType<typeof listActivities>>>
export type ListActivitiesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListActivities = <TData = Awaited<ReturnType<typeof listActivities>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListActivitiesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listActivities>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListActivitiesQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the resource
 */
export const createActivity = (
    activity: MaybeRef<Activity>,
 options?: SecondParameter<typeof customAxios>,) => {
      activity = unref(activity);
      
      return customAxios<Activity>(
      {url: `/api/activities`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: activity
    },
      options);
    }
  


export const getCreateActivityMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createActivity>>, TError,{data: Activity}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createActivity>>, TError,{data: Activity}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createActivity>>, {data: Activity}> = (props) => {
          const {data} = props ?? {};

          return  createActivity(data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateActivityMutationResult = NonNullable<Awaited<ReturnType<typeof createActivity>>>
    export type CreateActivityMutationBody = Activity
    export type CreateActivityMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateActivity = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createActivity>>, TError,{data: Activity}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateActivityMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForTask = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/tasks/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForTaskQueryKey = (id: MaybeRef<string>,) => {
    return ['api','tasks',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForTaskQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForTask>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTask>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForTaskQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForTask>>> = ({ signal }) => getChangeSequenceForTask(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTask>>, TError, TData> 
}

export type GetChangeSequenceForTaskQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForTask>>>
export type GetChangeSequenceForTaskQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForTask = <TData = Awaited<ReturnType<typeof getChangeSequenceForTask>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForTask>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForTaskQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the change sequence for resource with the provided ID
 */
export const getChangeSequenceForActivity = (
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      id = unref(id);
      
      return customAxios<number>(
      {url: `/api/activities/${id}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetChangeSequenceForActivityQueryKey = (id: MaybeRef<string>,) => {
    return ['api','activities',id,'sequence'] as const;
    }

    
export const getGetChangeSequenceForActivityQueryOptions = <TData = Awaited<ReturnType<typeof getChangeSequenceForActivity>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetChangeSequenceForActivityQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChangeSequenceForActivity>>> = ({ signal }) => getChangeSequenceForActivity(id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForActivity>>, TError, TData> 
}

export type GetChangeSequenceForActivityQueryResult = NonNullable<Awaited<ReturnType<typeof getChangeSequenceForActivity>>>
export type GetChangeSequenceForActivityQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetChangeSequenceForActivity = <TData = Awaited<ReturnType<typeof getChangeSequenceForActivity>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChangeSequenceForActivity>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetChangeSequenceForActivityQueryOptions(id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



