<script lang="ts" setup>
import { storeToRefs } from "pinia";
import appStore from "~/store";
import type { Assistant, CustomEvent } from "~/model";

defineEmits(["closed"]);

const { assistants } = storeToRefs(appStore.projectStore);

const selectedAssistant = ref<Assistant | undefined>(undefined);
const selectedEventType = ref<CustomEvent | undefined>(undefined);

function selectAssistant(assistant: Assistant, eventType: CustomEvent) {
  selectedAssistant.value = assistant;
  selectedEventType.value = eventType;
}

function unselectAssistant() {
  selectedAssistant.value = undefined;
  selectedEventType.value = undefined;
}

const { activeView } = storeToRefs(appStore.workspaceStore);

function isDisabled(eventType: CustomEvent) {
  return (eventType.contentObject && activeView.value && activeView.value.viewType === "document") || !eventType.contentObject;
}

const availableAssistants = computed(() => {
  return assistants.value
    .filter((assistant: Assistant) => {
      return assistant.showInTraining;
    })
    .sort((a, b) => {
      // Compare assistant names alphabetically
      return a.name.localeCompare(b.name);
    });
});

function getAssistantEventTypes(assistant: Assistant) {
  if (!assistant.definition?.eventTypes) {
    return [];
  }

  return assistant.definition.eventTypes.filter((eventType: CustomEvent) => {
    return !eventType.contentObject;
  });
}

watch(availableAssistants, () => {
  const assistantEventTypes = [];
  availableAssistants.value.forEach((assistant) => {
    getAssistantEventTypes(assistant).forEach((eventType) => {
      assistantEventTypes.push({ assistant, eventType });
    });
  });

  if (assistantEventTypes.length == 1) {
    selectAssistant(assistantEventTypes[0].assistant, assistantEventTypes[0].eventType);
  }
}, { deep: true, immediate: true });
</script>

<template>
  <div>
    <div class="flex h-full flex-col bg-white">
      <div class="flex-1">
        <!-- Header -->
        <div class="bg-gray-50 px-4">
          <div class="flex items-start justify-between space-x-3">
            <div class="space-y-1">
              <p v-if="selectedAssistant" class="mt-4 flex text-lg text-gray-700">
                <MaterialDesignIcon
                  aria-hidden="true" name="leftarrow"
                  @click="unselectAssistant"
                />
                Return to {{ selectedAssistant.name }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!selectedAssistant">
      <ul class="divide-y divide-gray-200" role="list">
        <li v-for="assistant in availableAssistants" :key="assistant.id">
          <div class="flex p-4">
            <div class="min-w-0">
              <div>
                <div class="flex text-sm">
                  <p class="text-xl text-gray-700">
                    {{ assistant.name }}
                  </p>
                </div>
                <div class="mt-2 flex">
                  <div class="flex items-center text-sm text-gray-500">
                    <p class="text-gray-500">
                      {{ assistant.description ? assistant.description : assistant.definition.description }}
                    </p>
                  </div>
                </div>
                <div class="mt-4">
                  <template v-for="eventType in getAssistantEventTypes(assistant)" :key="assistant.id + eventType.name">
                    <div class="mb-2 mt-2">
                      <div>
                        <h3 class="font-medium text-gray-700 text-lg">
                          {{ eventType.label }}
                        </h3>
                        <p class="text-gray-500 mt-1">
                          {{ eventType.description }}
                        </p>
                      </div>
                    </div>
                    <div>
                      <KodexaButton
                        v-if="isDisabled(eventType)"
                        size="small"
                        icon="play"
                        class="mt-2 w-36"
                        type="secondary"
                        @click="selectAssistant(assistant, eventType)"
                      >
                        Choose {{ eventType.label }}
                      </KodexaButton>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="selectedAssistant" class="mt-2 p-3">
      <KodexaAssistantEventType :assistant="selectedAssistant" :event-type="selectedEventType" height="calc(100vh - 25rem)" :is-panel="true" />
    </div>
  </div>
</template>

<style scoped>

</style>
