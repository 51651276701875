<script setup lang="ts">
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { notify } from "notiwind";
import type { DocumentFamily } from "~/model";
import appStore from "~/store";
import KodexaDuplicateDocumentFamily from "~/components/kodexa-duplicate-document-family.vue";
import type { ProcessingStepViewer } from "~/store/useWorkspace";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: true,
  },
  inWorkspace: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["addToWorkspace", "removeFromWorkspace", "open", "delete", "toggleLock", "rename", "details"]);

const reactiveInWorkspace = ref(props.inWorkspace);

interface NavItem {
  text?: string | undefined;
  icon?: string | undefined;
  name?: string | undefined;
  current?: boolean | undefined;
  items?: NavItem[] | undefined;
  mdiIcon?: string | undefined;
  render?: string | undefined;
  divider?: boolean | undefined;
  classes?: string[] | undefined;
  emit?: string | undefined;
  hidden?: boolean | undefined;
  disabled?: boolean | undefined;
  run?: () => void |
    undefined;
}

const { documentStores, dataForms } = storeToRefs(appStore.projectStore);

const openExport = async function (format: string) {
  // we want to get the format for the document family and then show it
  // however we want to do it based on the document objects we have
  let output;
  let resourceRef: string | undefined;
  if (format === "XML") {
    output = await appStore.workspaceStore.getDataObjectsWithFormat(props.documentFamily, "xml");
    format = "xml";
  } else if (format === "XML (Friendly)") {
    output = await appStore.workspaceStore.getDataObjectsWithFormat(props.documentFamily, "xml(friendly)");
    format = "xml";
  } else if (format === "JSON") {
    output = await appStore.workspaceStore.getDataObjectsWithFormat(props.documentFamily, "json");
    format = "json";
  } else if (format === "External") {
    output = await appStore.workspaceStore.getExternalData(props.documentFamily);
    output = JSON.stringify(output, null, 2);
    resourceRef = `external:${props.documentFamily.id}`;
    format = "json";
  }
  if (output !== "") {
    appStore.workspaceStore.createTextViewer(`${props.documentFamily.path} (${format})`, output, undefined, format, resourceRef);
  } else {
    notify({
      title: "No Data",
      message: "There is no data to export",
      group: "error",
    });
  }
};

const viewDataExpanded = ref(false);
const viewFormExpanded = ref(false);

function openProcessingSteps() {
  const processStepView = {
    viewType: "processingStep",
    id: `processing-step-view-${props.documentFamily.id}`,
    documentFamilyId: props.documentFamily.id,
    title: `${props.documentFamily.path} (Explain)`,
  } as ProcessingStepViewer;
  appStore.workspaceStore.addView(processStepView);
}

const navigation = computed(() => {
  const finalNavigation = [
    {
      icon: "open",
      render: "itemRender",
      text: "Open",
      emit: "open",
    },
    { divider: true },
  ];

  if (reactiveInWorkspace.value) {
    finalNavigation.push({
      icon: "minus",
      render: "itemRender",
      text: "Remove from data form",
      emit: "removeFromWorkspace",
    });
  } else {
    finalNavigation.push({
      icon: "plus",
      render: "itemRender",
      text: "Add to data form",
      emit: "addToWorkspace",
    });
  }

  finalNavigation.push(...[
    { divider: true },
    {
      text: "Duplicate",
      render: "itemRender",
      icon: "content-duplicate",
      run: async () => {
        const deleteConfirmDialog = createConfirmDialog(KodexaDuplicateDocumentFamily);
        const { isCanceled } = await deleteConfirmDialog.reveal({
          documentFamily: props.documentFamily,
          modelValue: true,
        });
        if (!isCanceled) {
          notify({
            group: "generic",
            title: "Success",
            text: "Document duplicated",
          }, 1000);
        }
      },
    },
    {
      icon: "rename",
      render: "itemRender",
      text: "Rename",
      emit: "rename",
    },
    {
      icon: "lock",
      render: "itemRender",
      text: props.documentFamily.locked ? "Unlock" : "Lock",
      emit: "toggleLock",
    },
    {
      icon: "graph",
      render: "itemRender",
      text: "View Explain Plan",
      run: () => {
        openProcessingSteps();
      },
    },
    { divider: true },
    {
      text: "View Data",
      render: "itemRender",
      icon: "code-json",
      run: () => {
        viewDataExpanded.value = !viewDataExpanded.value;
      },
      items: [
        {
          text: "XML (friendly)",
          icon: "xml",
          isData: true,
          run: () => {
            openExport("XML (Friendly)");
            viewDataExpanded.value = false;
          },
        },
        {
          text: "XML",
          isData: true,
          icon: "xml",
          run: () => {
            openExport("XML");
            viewDataExpanded.value = false;
          },
        },
        {
          text: "JSON",
          isData: true,
          icon: "code-json",
          run: () => {
            openExport("JSON");
            viewDataExpanded.value = false;
          },
        },
        {
          text: "External",
          isData: true,
          icon: "code-json",
          run: () => {
            openExport("External");
            viewDataExpanded.value = false;
          },
        },
      ],
    },
  ]);

  if (dataForms.value.length > 0) {
    finalNavigation.push({
      text: "Open with Data Form",
      render: "itemRender",
      icon: "form-select",
      run: () => {
        viewFormExpanded.value = !viewFormExpanded.value;
      },
      items: dataForms.value.map((dataForm) => {
        return {
          text: dataForm.name,
          icon: "form-select",
          isForm: true,
          run: () => {
            appStore.workspaceStore.openDataForm(dataForm, props.documentFamily);
            viewFormExpanded.value = false;
          },
        };
      }),
    });
    finalNavigation.push({ divider: true } as NavItem);
  }

  if (documentStores.value.length > 1) {
    finalNavigation.push({ divider: true } as NavItem);
    for (const documentStore of documentStores.value) {
      if (documentStore.ref === props.documentFamily.storeRef) {
        continue;
      }
      finalNavigation.push({
        text: `Copy to ${documentStore.name}`,
        render: "itemRender",
        icon: "content-duplicate",
        run: () => {
          appStore.projectStore.copyDocumentFamily(props.documentFamily, documentStore.ref);
        },
      } as NavItem);
    }
  }

  finalNavigation.push(...[
    { divider: true },
    {
      icon: "properties",
      text: "Details",
      render: "itemRender",
      emit: "details",
    },
    {
      icon: "download",
      render: "itemRender",
      text: "Download",
      emit: "download",
    },
    { divider: true },
    {
      icon: "delete",
      render: "itemRender",
      text: "Delete",
      classes: ["text-red-400"],
      emit: "delete",
      disabled: props.documentFamily.locked,
    },
  ] as NavItem[]);

  return finalNavigation;
});

function openNav(item: NavItem) {
  if (item.emit && !item.disabled) {
    emit(item.emit);
  }
  if (item.run) {
    item.run();
  }
}
</script>

<template>
  <div>
    <VDropdown :arrow-overflow="false">
      <!-- This will be the popover target (for the events and position) -->
      <MaterialDesignIcon name="THREEDOTMENU" class="size-5 pt-2 text-gray-500" aria-hidden="true" />
      <!-- This will be the content of the popover -->
      <template #popper="{ hide }">
        <nav class="space-y-1 p-2" aria-label="Sidebar">
          <div v-for="item in navigation" :key="item.name">
            <a
              v-if="!item.divider && !item.items"
              v-close-popper
              :class="[item.disabled ? 'cursor-not-allowed text-opacity-25' : 'cursor-pointer text-opacity-100']"
              class="flex select-none items-center rounded-md px-3 py-2 text-xs font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900"
              :aria-current="item.current && !item.disabled ? 'page' : undefined" @click="openNav(item) && hide()"
            >
              <MaterialDesignIcon
                v-if="item.icon"
                :name="item.icon"
                class="-ml-1 mr-2 mt-1 shrink-0"
                :classes="item.classes ? item.classes : []"
                aria-hidden="true"
                size="18"
              />
              <span class="truncate">{{ item.text }}</span>
            </a>
            <template v-else-if="item.items" class="pl-4">
              <a
                :class="[item.disabled ? 'cursor-not-allowed text-opacity-25' : 'cursor-pointer text-opacity-100']"
                class="flex select-none items-center rounded-md px-3 py-2 text-xs font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900"
                :aria-current="item.current && !item.disabled ? 'page' : undefined" @click="openNav(item)"
              >
                <MaterialDesignIcon
                  v-if="item.icon"
                  :name="item.icon"
                  class="-ml-1 mr-2 mt-1 shrink-0"
                  :classes="item.classes ? item.classes : []"
                  aria-hidden="true"
                  size="18"
                />
                <span class="truncate">{{ item.text }}</span>
              </a>
              <Transition name="slide-fade">
                <div v-show="viewDataExpanded">
                  <template v-for="subItem in item.items" :key="subItem.text">
                    <a
                      v-if="subItem.isData"
                      v-close-popper
                      :class="[subItem.disabled ? 'cursor-not-allowed text-opacity-25' : 'cursor-pointer text-opacity-100']"
                      class="flex select-none items-center rounded-md px-3 py-2 text-xs font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900 ml-2"
                      :aria-current="subItem.current && !subItem.disabled ? 'page' : undefined" @click="openNav(subItem) && hide()"
                    >
                      <MaterialDesignIcon
                        v-if="subItem.icon"
                        :name="subItem.icon"
                        class="-ml-1 mr-2 mt-1 shrink-0"
                        :classes="subItem.classes ? subItem.classes : []"
                        aria-hidden="true"
                        size="18"
                      />
                      <span class="truncate">{{ subItem.text }}</span>
                    </a>
                  </template>
                </div>
              </Transition>
              <Transition name="slide-fade">
                <div v-show="viewFormExpanded">
                  <template v-for="subItem in item.items" :key="subItem.text">
                    <a
                      v-if="subItem.isForm"
                      v-close-popper
                      :class="[subItem.disabled ? 'cursor-not-allowed text-opacity-25' : 'cursor-pointer text-opacity-100']"
                      class="flex select-none items-center rounded-md px-3 py-2 text-xs font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900 ml-2"
                      :aria-current="subItem.current && !subItem.disabled ? 'page' : undefined" @click="openNav(subItem) && hide()"
                    >
                      <MaterialDesignIcon
                        v-if="subItem.icon"
                        :name="subItem.icon"
                        class="-ml-1 mr-2 mt-1 shrink-0"
                        :classes="subItem.classes ? subItem.classes : []"
                        aria-hidden="true"
                        size="18"
                      />
                      <span class="truncate">{{ subItem.text }}</span>
                    </a>
                  </template>
                </div>
              </Transition>
            </template>
            <div v-else class="my-2 border-t border-gray-200" />
          </div>
        </nav>
      </template>
    </VDropdown>
  </div>
</template>

<style scoped>
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}
</style>
