<script setup lang="ts">
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import { EllipsisHorizontalIcon } from "@heroicons/vue/20/solid";
import { Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { storeToRefs } from "pinia";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { notify } from "notiwind";
import appStore from "~/store";
import ProjectAddDataform from "~/components/dashboard/widgets/project/project-add-dataform.vue";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import type { DataForm } from "~/model";
import { deleteDataForm } from "~/api/data-forms/data-forms";
import {updateHandler} from "~/utils/error-handler";

const { dataForms } = storeToRefs(appStore.projectStore);

const showAddDataForm = ref(false);

async function dataFormDelete(dataForm: DataForm) {
  const confirmLostChanges = createConfirmDialog(KodexaConfirm);
  const result = await confirmLostChanges.reveal({
    icon: "alert-circle-outline",
    title: "Unsaved Changes",
    message: "You have unsaved changes, if you continue they will be lost!",
    notes: "Changes to resources will require the project to reload. Do you want to discard the changes, or stay in the project to save them",
    confirmText: "Discard Changes",
    confirmIcon: "alert-circle-outline",
    cancelText: "Return to Data Flow",
    cancelIcon: "close",
    type: "danger",
  });

  if (result.isCanceled) {
    return false;
  }
  await appStore.projectStore.unlinkProjectResource(dataForm, true);
}

async function unlinkDataForm(dataForm: DataForm) {
  const confirmLostChanges = createConfirmDialog(KodexaConfirm);
  const result = await confirmLostChanges.reveal({
    icon: "alert-circle-outline",
    title: "Unsaved Changes",
    message: "You have unsaved changes, if you continue they will be lost!",
    notes: "Changes to resources will require the project to reload. Do you want to discard the changes, or stay in the project to save them",
    confirmText: "Discard Changes",
    confirmIcon: "alert-circle-outline",
    cancelText: "Return to Data Flow",
    cancelIcon: "close",
    type: "danger",
  });

  if (result.isCanceled) {
    return false;
  }
  await appStore.projectStore.unlinkProjectResource(dataForm, false);
}
</script>

<template>
  <div class="mx-4">
    <Toolbar class="border-0 bg-white">
      <KodexaArticle article-id="9787707" text="Learn more about data forms" :slide="false" />
      <ToolbarSpacer />
      <KodexaButton id="createNewNotification" icon="plus" @click="showAddDataForm = true">
        Add Data Form
      </KodexaButton>
    </Toolbar>
    <ul role="list" class="grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
      <li v-for="dataForm in dataForms" :key="dataForm.id" class="overflow-hidden rounded-xl border border-gray-200">
        <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
          <MaterialDesignIcon name="form-select" class="text-gray-400" size="20" />
          <div class="text-sm font-medium leading-6 text-gray-900">
            {{ dataForm.name }}
          </div>
          <Menu as="div" class="relative ml-auto">
            <MenuButton class="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
              <span class="sr-only">Open options</span>
              <EllipsisHorizontalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <Transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-0.5 w-52 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
              >
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    class="block px-3 py-1 text-sm leading-6 text-gray-900" :class="[active ? 'bg-gray-50' : '']"
                    @click="unlinkDataForm(dataForm)"
                  >
                    <MaterialDesignIcon name="link" class="h-5 w-5 text-gray-500 mr-1" />
                    Unlink from Project<span class="sr-only">, {{ dataForm.name }}</span>
                  </a>
                </MenuItem>
                <MenuItem v-slot="{ active }">
                  <a
                    href="#"
                    class="block px-3 py-1 text-sm leading-6 text-gray-900" :class="[active ? 'bg-gray-50' : '']"
                    @click="dataFormDelete(dataForm)"
                  >
                    <MaterialDesignIcon name="delete" class="h-5 w-5 text-red-500 mr-1" />
                    Delete<span class="sr-only">, {{ dataForm.name }}</span>
                  </a>
                </MenuItem>
              </MenuItems>
            </Transition>
          </Menu>
        </div>
        <dl class="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
          <div class="flex justify-between gap-x-4 py-3">
            {{ dataForm.description }}
          </div>
          <div class="flex justify-between gap-x-4 py-3">
            <dt class="text-gray-500">
              Entrypoints
            </dt>
            <dd class="text-gray-700">
              <span v-for="entrypoint in dataForm.entrypoints" :key="entrypoint" class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ entrypoint }}</span>
            </dd>
          </div>
        </dl>
      </li>
    </ul>
    <ProjectAddDataform v-model="showAddDataForm" @closed="showAddDataForm = false" />
  </div>
</template>
