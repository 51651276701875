// This exists due to issues with fixed headers and scrollIntoView

import { log } from "~/utils/logger";

export function scrollIntoView(element: Element, parent: Element | undefined) {
  if (parent && element) {
    log.info("Scrolling parent");
    element.scrollTop = element.offsetTop - parent.offsetTop;
  } else {
    if (element) {
      log.info("Scrolling window");
      element.scrollIntoView();
    }
  }
}

export function truncatedString(text: string, length: number) {
  if (text.length <= length) {
    return text;
  }
  const start = text.substring(0, length / 2);
  const end = text.substring(text.length - length / 2);
  return `${start}...${end}`;
}
