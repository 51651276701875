<script setup lang="ts">
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import { notify } from "notiwind";
import appStore from "~/store";
import type { DocumentFamily } from "~/model";
import KodexaDuplicateDocumentFamily from "~/components/kodexa-duplicate-document-family.vue";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: true,
  },
});

const emit = defineEmits(["change", "delete", "details", "toggle-lock"]);

const { documentStores } = storeToRefs(appStore.projectStore);

interface NavItem {
  text?: string;
  items?: NavItem[];
  run?: () => void;
  mdiIcon?: string;
  icon?: string;
  name?: string;
  current?: boolean;
  render?: string;
  divider?: boolean;
  classes?: string[];
  disabled?: boolean;
}

const dropdownOpen = ref(false);

const navigation = computed(() => {
  const finalNavigation = [
    {
      text: "Duplicate",
      render: "itemRender",
      icon: "duplicate",
      run: async () => {
        const deleteConfirmDialog = createConfirmDialog(KodexaDuplicateDocumentFamily);
        const {
          isCanceled,
        } = await deleteConfirmDialog.reveal({
          documentFamily: props.documentFamily,
        });
        if (!isCanceled) {
          // await appStore.userStore.deleteUser(localUser.value);
          // log.info(localUser);
          notify({
            group: "generic",
            title: "Success",
            text: "Document duplicated",
          }, 1000);
        }
      },
    },
    {
      text: "Download",
      render: "itemRender",
      icon: "DOWNLOAD",
      run: async () => {
        const content = await appStore.projectStore.getNativeContent(props.documentFamily);
        const fileURL = window.URL.createObjectURL(content);
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", props.documentFamily.path);
        document.body.appendChild(fileLink);
        fileLink.click();
      },
    },
    {
      text: "Details",
      render: "itemRender",
      icon: "properties",
      run: async () => {
        dropdownOpen.value = false;
        emit("details", props.documentFamily);
      },
    },
  ] as NavItem[];

  // Check if we have other document stores in the project than
  // this as add the copy option
  if (documentStores.value.length > 1) {
    finalNavigation.push({ divider: true } as NavItem);
    for (const documentStore of documentStores.value) {
      if (documentStore.ref === props.documentFamily.storeRef) {
        continue;
      }
      finalNavigation.push({
        text: `Copy to ${documentStore.name}`,
        render: "itemRender",
        icon: "content-duplicate",
        run: async () => {
          dropdownOpen.value = false;
          appStore.projectStore.copyDocumentFamily(props.documentFamily, documentStore.ref);
        },
      } as NavItem);
    }
    finalNavigation.push({ divider: true } as NavItem);
  }

  finalNavigation.push(...[
    {
      text: props.documentFamily.locked ? "Unlock" : "Lock",
      render: "itemRender",
      icon: props.documentFamily.locked ? "lock-open" : "lock",
      run: async () => {
        dropdownOpen.value = false;
        emit("toggleLock");
      },
    },
    { divider: true },
    {
      text: "Delete",
      render: "itemRender",
      icon: "delete",
      classes: ["text-red-400"],
      disabled: props.documentFamily.locked,
      run: async () => {
        dropdownOpen.value = false;
        emit("delete");
      },
    },
  ]);

  return finalNavigation;
});

async function openNav(item: NavItem) {
  if (item.run && !item.disabled) {
    await item.run();
  }
}
</script>

<template>
  <div class="w-5">
    <VDropdown :arrow-overflow="true" :shown="dropdownOpen">
      <MaterialDesignIcon name="THREEDOTMENU" class="size-5 text-gray-500" aria-hidden="true" />
      <!-- This will be the content of the popover -->
      <template #popper>
        <nav class="space-y-1 p-2" aria-label="Sidebar">
          <template v-for="item in navigation" :key="item.name">
            <a
              v-if="!item.divider"
              :class="[item.disabled ? 'cursor-not-allowed text-opacity-25' : 'cursor-pointer text-opacity-100']"
              class="flex select-none items-center rounded-md px-3 py-2 text-xs font-medium text-gray-600 hover:bg-gray-100 hover:text-gray-900"
              :aria-current="item.current && !item.disabled ? 'page' : undefined" @click="openNav(item)"
            >
              <MaterialDesignIcon
                v-if="item.icon"
                :name="item.icon"
                class="-ml-1 mr-2 mt-1 shrink-0"
                :classes="item.classes ? item.classes : []"
                aria-hidden="true"
                size="18"
              />
              <span class="truncate">{{ item.text }}</span>
            </a>
            <div v-else class="my-2 border-t border-gray-200" />
          </template>
        </nav>
      </template>
    </VDropdown>
  </div>
</template>
