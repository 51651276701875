<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";

import { createConfirmDialog } from "vuejs-confirm-dialog";
import { notify } from "notiwind";
import type { PropType } from "vue";
import { ColorPicker } from "@progress/kendo-vue-inputs";
import KodexaDeleteConfirm from "~/components/kodexa-confirm.vue";
import type { Label } from "~/model";
import { handleError } from "~/utils/error-handler";
import { deleteLabel, updateLabel } from "~/api/labels/labels";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  label: {
    type: Object as PropType<Label>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "refetchDocumentTag"]);

const localLabel = ref(JSON.parse(JSON.stringify(props.label)) as Label);

watch(() => props.modelValue, () => {
  localLabel.value = JSON.parse(JSON.stringify(props.label)) as Label;
});

async function deleteDocumentTag() {
  const deleteConfirmDialog = createConfirmDialog(KodexaDeleteConfirm);
  const {
    isCanceled,
  } = await deleteConfirmDialog.reveal({
    title: `Delete
    document tag
    ${localLabel?.value?.label}
    from
    this
    organization
    ?`,
    message: "This action cannot be undone.",
  });
  if (!isCanceled) {
    await deleteLabel(localLabel.value.id as string);
    notify({
      group: "generic",
      title: "Success",
      text: "Document tag deleted successfully",
    }, 1000);
  }
  emit("refetchDocumentTag");
  close();
}

const errors = ref({});

async function doUpdateLabel() {
  if (localLabel.value) {
    try {
      await updateLabel(localLabel.value.id as string, localLabel.value);
    } catch (err: any) {
      errors.value = err.response.data.errors;
      await handleError(err);
    }
  }
  notify({
    group: "generic",
    title: "Success",
    text: "Document tag updated successfully",
  }, 3000);
  emit("refetchDocumentTag");
  close();
}

function close() {
  emit("update:modelValue", false);
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="flex-1">
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            Update Document Tag
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Change the details required and then choose Save Changes
                          </p>
                        </div>
                        <!-- Action buttons -->
                        <div class="shrink-0 border-gray-200 px-4 py-5 pr-0">
                          <div class="flex justify-end space-x-3">
                            <KodexaButton
                              id="cancelEditDocumentTag"
                              type="secondary"
                              icon="cancel"
                              size="small"
                              @click="close"
                            >
                              Cancel
                            </KodexaButton>
                            <KodexaButton
                              id="saveDocumentTag"
                              type="primary"
                              icon="content-save"
                              size="small"
                              @click="doUpdateLabel"
                            >
                              Save Changes
                            </KodexaButton>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mt-2">
                      <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                          <label
                            for="documentTagLabel"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >
                            Document Tag
                          </label>
                          <div class="sm:col-span-2">
                            <KodexaTextInput
                              id="editDocumentTagName" v-model="localLabel.label"
                              name="editDocumentTagName"
                            />
                          </div>
                          <label
                            for="documentTagColor"
                            class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5"
                          >
                            Color
                          </label>
                          <div class="sm:col-span-2">
                            <ColorPicker
                              id="editDocumentTagColor" v-model="localLabel.color"
                              name="editDocumentTagColor"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- Delete Document Tag -->
                      <div class="border-2 border-red-200 bg-white shadow sm:m-6 sm:mt-3 sm:rounded-lg">
                        <div class=" px-4 py-5 sm:p-6">
                          <h3 class="text-base font-semibold leading-6 text-red-600">
                            Delete Document Tag
                          </h3>
                          <div class="mt-2 items-center justify-center sm:flex">
                            Are you sure you want to delete this document tag? This cannot be undone.
                            <div class="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:shrink-0 sm:items-center">
                              <KodexaButton id="deleteDocumentTag" type="danger" @click="deleteDocumentTag">
                                Delete Document Tag
                              </KodexaButton>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
