import { defineStore, storeToRefs } from "pinia";
import type { ILogObj } from "tslog";
import { Logger } from "tslog";
import { customAxios } from "~/api/custom-axios";
import appStore from "~/store/index";
import type { Store } from "~/api/";

export interface QueryRequest {
  query: string;
  queryType: "NATURAL_LANGUAGE" | "HQL";
  organizationId: string;
  projectId: string;
  taxonomyRefs: string[];
}

export interface Column {
  name: string;
  type: string;
}

export interface QueryResponse {
  content: any[];
  pageSize: number;
  page: number;
  totalPages: number;
  totalElements: number;
  hql?: string;
  columns: Column[];
}

export const useLlmQuery = defineStore("query", () => {
  const log: Logger<ILogObj> = new Logger({ name: "useLlmQuery" });

  async function platformQuery(queryRequest: QueryRequest) {
    log.info("executeQuery");
    const url = "/api/query";

    const response = await customAxios(
      {
        url,
        method: "post",
        data: queryRequest,
      },
      {});

    const queryResponse = {
      content: response.content,
      pageSize: response.pageSize,
      page: response.page,
      totalPages: response.totalPages,
      totalElements: response.totalElements,
      hql: response.hql,
      columns: [],
    } as QueryResponse;

    // Take the objects from data.content and create a list of columns
    const columns = new Map<string, any>();
    queryResponse.content.forEach((row) => {
      Object.keys(row).forEach((key) => {
        columns.set(key, {
          field: key,
          title: key,
        });
      });
    });

    queryResponse.columns = Array.from(columns.values());
    return queryResponse;
  }

  async function organizationQuery(queryRequest: QueryRequest) {
    log.info("executeQuery");
    const { currentOrganization } = storeToRefs(appStore.organizationStore);
    const url = `/api/${currentOrganization.value.id}/query`;
    const response = await customAxios(
      {
        url,
        method: "post",
        data: queryRequest,
      },
      {});

    const queryResponse = {
      content: response.content,
      pageSize: response.pageSize,
      page: response.page,
      totalPages: response.totalPages,
      totalElements: response.totalElements,
      hql: response.hql,
      columns: [],
    } as QueryResponse;

    // Take the objects from data.content and create a list of columns
    const columns = new Map<string, any>();
    queryResponse.content.forEach((row) => {
      Object.keys(row).forEach((key) => {
        columns.set(key, {
          field: key,
          title: key,
        });
      });
    });

    queryResponse.columns = Array.from(columns.values());
    return queryResponse;
  }

  async function dataStoreQuery(store: Store, queryRequest: QueryRequest) {
    log.info("executeQuery");

    const { contentTaxonomies } = storeToRefs(appStore.projectStore);
    const url = `/api/stores/${store.orgSlug}/${store.slug}/${store.version}/dataObjects/query`;

    if (contentTaxonomies.value) {
      queryRequest.taxonomyRefs = contentTaxonomies.value.map((taxonomy) => {
        return taxonomy.ref;
      });
    }

    const response = await customAxios(
      {
        url,
        method: "post",
        data: queryRequest,
      },
      {});

    const queryResponse = {
      content: response.content,
      pageSize: response.pageSize,
      page: response.size,
      totalPages: response.totalPages,
      totalElements: response.totalElements,
      hql: response.hql,
      columns: [],
    } as QueryResponse;

    // Take the objects from data.content and create a list of columns
    const columns = new Map<string, any>();
    queryResponse.content.forEach((row) => {
      Object.keys(row).forEach((key) => {
        columns.set(key, {
          field: key,
          title: key,
        });
      });
    });

    queryResponse.columns = Array.from(columns.values());
    return queryResponse;
  }

  return {
    platformQuery,
    dataStoreQuery,
    organizationQuery,
  };
});
