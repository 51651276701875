<script setup lang="ts">
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
import type {PropType} from "vue";
import type {SelectedTag} from "~/components/document/document";

const props = defineProps({
  selectedTag: Object as PropType<SelectedTag>,
});
const emit = defineEmits(["confirm", "cancel"]);
const open = ref(true);

const featureJson = computed(() => JSON.stringify(props.selectedTag?.feature, null, 4));
</script>

<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel style="width:80%; height: 600px"
              class="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6"
            >
              <div>
                <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button" class="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    @click="open = false"
                  >
                    <span class="sr-only">Close</span>
                    <MaterialDesignIcon name="close" size="16" aria-hidden="true" @click="emit('cancel')"/>
                  </button>
                </div>
                <div class="sm:flex sm:items-start" >
                  <KodexaCodeEditor :model-value="featureJson" language="json" style="width:92%; height: 570px"/>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
