<script setup lang="ts">
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import KodexaProjectTagify from "~/components/kodexa-project-tagify.vue";
import { updateProjectTags } from "~/api/projects/projects";
import type { Project } from "~/model";

const props = defineProps({
  project: {
    type: Object as PropType<Project>,
    required: true,
  },
});

const emit = defineEmits(["updated"]);

const {
  projectTags,
} = storeToRefs(appStore.organizationStore);

const projectTagList = computed(() => {
  return JSON.parse(JSON.stringify(props.project.tags)) ?? [];
});

function addTag(tag) {
  projectTagList.value.push(tag);
  updateProjectTags(props.project.id as string, projectTagList.value).then((e) => {
    emit("updated");
  });
}

function removeTag(tag) {
  updateProjectTags(props.project.id as string, projectTagList.value.filter(t => t.name !== tag),
  ).then(() => {
    emit("updated");
  });
}
</script>

<template>
  <div v-if="project" style="width: 100%">
    <KodexaProjectTagify
      :tags="projectTagList" :available-tags="projectTags" @add-tag="addTag" @remove-tag="removeTag"
    />
  </div>
</template>

<style scoped>

</style>
