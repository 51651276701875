<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import type { DataAttribute, DataException, DataObject } from "~/model";
import type { TagMetadata } from "~/store/useProject";
import { createDataAttributeHelper } from "~/store/useDataAttributeHelper";
import { createKodexaFormulaResolver } from "~/store/useKodexaFormula";
import { log } from "~/utils/logger";
import { getAttributeValueByTaxonType } from "~/components/util/attribute-utils";

const props = defineProps({
  tagMetadata: {
    type: Object as PropType<TagMetadata>,
    required: true,
  },
  dataObject: {
    type: Object as PropType<DataObject>,
    required: true,
  },
  attribute: {
    type: Object as PropType<DataAttribute>,
    required: true,
  },
  dataExceptions: {
    type: Object as PropType<DataException[]>,
    required: false,
    default: () => {
      return [] as DataException[];
    },
  },
  viewId: {
    type: String as PropType<string>,
    required: false,
    default: null,
  },
});

log.info(`Formula using view ${props.viewId}`);
const useDataFormViewer = createDataFormViewerStore(props.viewId);
const { dataObjects } = storeToRefs(useDataFormViewer);

const localDataObjects = ref([] as DataObject[]);
const dataObject = ref(undefined);
const formulaError = ref(undefined);
const debugInfo = ref(undefined);
const formattedResult = ref(undefined);

watch(() => dataObjects, () => {
  log.info(`${dataObjects.value.length} data objects received`);
  localDataObjects.value = dataObjects.value;
}, { immediate: true, deep: true });

watch(() => props.dataObject, (newVal) => {
  if (!newVal) {
    dataObject.value = undefined;
  } else if (localDataObjects.value) {
    dataObject.value = localDataObjects.value.find(dato => dato.uuid === newVal.uuid);
  }
}, { immediate: true, deep: true });

watch(() => [props.tagMetadata.taxon.taxonType, dataObject.value, localDataObjects.value], () => {
  if (!dataObject.value) {
    formattedResult.value = getAttributeValueByTaxonType(props.attribute, props.tagMetadata.taxon.taxonType);
  } else {
    const type = props.tagMetadata.taxon.taxonType;
    let finalResult;
    log.info(`Resolving formula for ${props.attribute.tag} with ${localDataObjects.value.length} data objects`);
    const useFormula = createKodexaFormulaResolver(ref(props.tagMetadata.taxonomy), dataObject);
    useFormula.setDataObjects(localDataObjects.value);
    const { formulaResult, debugInfo: debugInfoStore, formulaErrors } = storeToRefs(useFormula);
    if (debugInfoStore.value.has(props.attribute.tag)) {
      log.info(`Found debug info for ${props.attribute.tag} "${debugInfoStore.value.get(props.attribute.tag)}"`);
      debugInfo.value = debugInfoStore.value.get(props.attribute.tag);
    } else {
      log.info(`No debug info for ${props.attribute.tag}`);
      debugInfo.value = undefined;
    }
    if (formulaErrors.value.has(props.attribute.tag)) {
      formulaError.value = formulaErrors.value.get(props.attribute.tag);
    } else {
      formulaError.value = undefined;
    }
    if (formulaResult.value.has(props.attribute.tag)) {
      finalResult = formulaResult.value.get(props.attribute.tag);
    } else {
      formattedResult.value = "- not found -";
      return;
    }

    if (finalResult === undefined) {
      formattedResult.value = "- null -";
      return;
    }

    switch (type) {
      case "NUMBER":
        formattedResult.value = new Intl.NumberFormat().format(finalResult);
        break;
      case "CURRENCY":
        formattedResult.value = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(finalResult);
        break;
      case "DATE":
        formattedResult.value = new Date(finalResult).toLocaleDateString();
        break;
      default:
        formattedResult.value = finalResult;
    }
  }
}, { immediate: true, deep: true });

const dataViewHelper = createDataAttributeHelper(ref(props.attribute), ref(props.dataObject), props.viewId);

const { style, conditionalValidationIcon } = storeToRefs(dataViewHelper);
</script>

<template>
  <div>
    <div v-if="formulaError" class="text-red-500 flex items-center">
      <VMenu :popper-triggers="['hover']">
        <MaterialDesignIcon
          size="22"
          class="mr-3 inline-flex text-red-500"
          name="exceptions"
        />
        Formula is invalid
        <template #popper>
          <div class="bg-white shadow-lg rounded-lg p-4" style="width: 300px; height: 300px">
            <div
              v-for="(validationError, idx) in formulaError" :key="idx"
              class="fm-colored-input__validation mt-1 rounded-lg p-2 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
              role="alert"
            >
              <MaterialDesignIcon name="alert" class="mr-1 text-red-600" size="12" />
              {{ validationError.message }} from on "{{ validationError.offendingSymbol?.text }}" at position
              {{ validationError.offendingSymbol?.start }}-{{ validationError.offendingSymbol?.stop }}
            </div>
          </div>
        </template>
      </VMenu>
    </div>
    <div v-else>
      <VMenu :popper-triggers="['hover']" :delay="{ show: 1000, hide: 100 }">
        <div class="flex items-center">
          <MaterialDesignIcon
            v-if="conditionalValidationIcon.icon" :name="conditionalValidationIcon.icon"
            :color="conditionalValidationIcon.color" class="mt-1 inline-flex"
          />
          <div :class="{ 'ml-1': conditionalValidationIcon.icon }" class="mt-1 text-lg w-full rounded" :style="style">
            <div class="px-1 w-full">
              {{ formattedResult }}
            </div>
          </div>
        </div>
        <template #popper>
          <div class="bg-white shadow-lg rounded-lg p-4" style="width: 600px; max-height: 300px">
            <KodexaFormulaExplain
              v-if="debugInfo" :key="attribute.uuid" :debug-info="debugInfo"
              :formula="props.tagMetadata.taxon.semanticDefinition as string"
              :tag-metadata="tagMetadata"
            />
            <div v-else class="text-gray-500">
              No debug information available
            </div>
          </div>
        </template>
      </VMenu>
    </div>
  </div>
</template>

<style scoped>
</style>
