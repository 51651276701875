<script setup lang="ts">
import { useRegisterSW } from "virtual:pwa-register/vue";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import KodexaConfirm from "~/components/kodexa-confirm.vue";
import appStore from "~/store";
import type { LoadingEvent } from "~/store/usePlatform";

const {
  offlineReady,
  needRefresh,
  updateServiceWorker,
} = useRegisterSW();

const ignore = ref(false);

async function close() {
  ignore.value = true;
}

// Clear cache in Chrome and Edge
function clearCache() {
  const loadingEvent = {
    id: "readload-1",
    title: "Reloading Application",
    progressMessage: "If this takes too long please reload this page.",
  } as LoadingEvent;
  appStore.platformStore.addLoadingEvent(loadingEvent);

  if (window.chrome) {
    // For Chrome and Edge
    const promises: Promise<boolean>[] = [];
    caches.keys().then((names) => {
      for (const name of names) {
        promises.push(caches.delete(name));
      }
    });

    // wait until all promises are resolved
    Promise.all(promises).then(() => {
      updateServiceWorker().then(() => {
        setTimeout(() => {
          appStore.platformStore.removeLoadingEvent(loadingEvent.id);
          window.location.reload();
        }, 5000);
      });
    });
  } else {
    // For other browsers, you can't clear cache programmatically
    console.warn("Cache clearing is not supported in this browser.");

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.register("/service-worker.js").then((registration) => {
        // Check if a new service worker is waiting to activate
        if (registration.waiting) {
          // Send message to service worker to skip waiting
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }

        registration.addEventListener("updatefound", () => {
          const newWorker = registration.installing;
          newWorker.addEventListener("statechange", () => {
            if (newWorker.state === "installed" && navigator.serviceWorker.controller) {
              // New update available, send message to service worker to skip waiting
              newWorker.postMessage({ type: "SKIP_WAITING" });
            }
          });
        });
      });
    }

    updateServiceWorker().then(() => {
      setTimeout(() => {
        appStore.platformStore.removeLoadingEvent(loadingEvent.id);
        window.location.reload();
      }, 10000);
    });
  }
}

const { reveal, onConfirm, onCancel } = createConfirmDialog(KodexaConfirm);

watch(needRefresh, (newValue) => {
  if (newValue && ignore.value === false) {
    reveal({
      title: "New Release Available",
      message: "A new release is available. If you have unsaved changes, ensure you cancel the reload and save your work.  Click on reload button to update.",
      confirmText: "Reload",
      confirmIcon: "rocket-launch",
      cancelText: "Close",
      cancelIcon: "close",
      type: "primary",
      acceptClasses: ["bg-blue-600"],
      icon: "information",
      iconClasses: ["text-blue-600"],
    });

    onConfirm(() => {
      clearCache();
    });

    onCancel(() => {
      close();
    });
  }
}, { immediate: true });
</script>

<template>
</template>

<style scoped>

</style>
