<script setup lang="ts">
import {DropDownList} from "@progress/kendo-vue-dropdowns";

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  textField: {
    type: String,
    required: false,
    default: "text",
  },
  valueField: {
    type: String,
    required: false,
    default: "id",
  },
  items: {
    type: Array,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: false,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: "",
  },
  errors: {
    type: Object,
    default: () => {
    },
  },
  filterable: {
    type: Boolean,
    default: false,
  },
  defaultItem: {
    type: Object,
    default: () => {
      return undefined;
    },
  },
});

const emit = defineEmits(["update:modelValue", "filterChange", "open"]);

const selectedValue = computed({
  get: () => {
    const result = props.items.find((item: any) => item[props.valueField] === props.modelValue);
    return result === undefined ? null : result;
  },
  set: (value: any) => {
    emit("update:modelValue", value[props.valueField]);
  },
});

const filteredItems = computed(() => {
  if (filter.value && filter.value.length > 0) {
    return props.items.filter((item: any) => item[props.textField].toLowerCase().includes(filter.value.toLowerCase()));
  } else {
    return props.items;
  }
});

const filter = ref("");

function onFilterChange(event: any) {
  emit("filterChange", event.filter.value);
  filter.value = event.filter.value;
}

function onChange(event: any) {
  emit("update:modelValue", event.value[props.valueField]);
}

function selectItem(dataItem: any, props) {
  emit("update:modelValue", dataItem[props.valueField]);
  props.onClick({});
}
</script>

<template>
  <div>
    <div
      :class="{ 'has-error': errors && errors[name], 'success': errors && !errors[name] }"
    />
    <label v-if="label" for="name" class="kodexa-label">{{ label }}</label>
    <DropDownList
      class="w-full truncate overflow-ellipsis dropdown-input"
      :placeholder="placeholder"
      :value="selectedValue"
      :data-items="filteredItems"
      :text-field="textField"
      :data-item-key="valueField"
      :filterable="filterable"
      :loading="loading"
      rounded="large"
      :default-item="defaultItem"
      item-render="itemRender"
      value-render="valueRender"
      @open="emit('open')"
      @filterchange="onFilterChange"
      @change="onChange"
    >
      <template #itemRender="{ props }">
        <div
          v-if="props.dataItem" class="k-input-value-text cursor-pointer mx-2"
          @click="selectItem(props.dataItem, props)"
        >
          <slot name="item-render" :props="props">
            {{ props.dataItem[textField] }}
          </slot>
        </div>
      </template>
      <template #valueRender="{ props }">
        <div v-if="props.value" class="k-input-value-text p-2">
          <slot name="value-render" :props="props">
            {{ props.value[textField] }}
          </slot>
        </div>
        <div v-else class="text-gray-400 k-input-value-text p-2">
          <span>{{ placeholder }}</span>
        </div>
      </template>
    </DropDownList>
    <p v-if="errors && errors[name]" class="mt-2 text-sm text-red-600">
      {{ errors[name] }}
    </p>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-gray-700 mb-1;
}

:deep(.dropdown-input) {
  height: 32px !important;
  padding-top: 0 !important;
  display: flex;
  align-items: center;
}

:deep(.k-input-value-text) {
  display: flex;
  align-items: center;
  height: 100%;
}

:deep(button.k-button.k-button-md.k-icon-button.k-button-solid.k-button-solid-base.k-input-button) {
  box-shadow: none !important;
}

:deep(.k-button:hover) {
  background-color: transparent !important;
}

:deep(.k-picker-solid) {
  border-color: #D1D5DB !important;
  background-color: white !important;
  background-image: none !important;
}

:deep(.k-input-inner) {
  display: flex;
  align-items: center;
  height: 100%;
}
</style>

