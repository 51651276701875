<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { handleError } from "~/utils/error-handler";
import appStore from "~/store";
import { createTeam } from "~/api/teams/teams";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const newTeam = ref();

function close() {
  emit("update:modelValue", false);
}

watch(() => props.modelValue, (newValue) => {
  if (newValue) {
    newTeam.value = {
      name: "",
      description: "",
      organization: appStore.organizationStore.currentOrganization,
    };
  }
});

async function createNewTeam() {
  try {
    await createTeam(newTeam.value);
    close();
  } catch (e) {
    await handleError(e);
    console.error(e);
  }
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <div class="flex h-full flex-col bg-white shadow-xl">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">
                            New Team
                          </DialogTitle>
                          <p class="text-sm text-gray-500">
                            Get started by filling in the information below to create your new team.
                          </p>
                        </div>
                        <div class="flex h-7 items-center">
                          <button type="button" class="text-gray-400 hover:text-gray-500" @click="close">
                            <span class="sr-only">Close panel</span>
                            <MaterialDesignIcon name="close" class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <!-- Divider container -->
                    <div class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <!-- Project name -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label for="team-name" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Team name</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextInput id="team-name" v-model="newTeam.name" name="team-name" />
                        </div>
                      </div>

                      <!-- Project description -->
                      <div class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5">
                        <div>
                          <label for="team-description" class="block text-sm font-medium leading-6 text-gray-900 sm:mt-1.5">Description</label>
                        </div>
                        <div class="sm:col-span-2">
                          <KodexaTextArea id="team-description" v-model="newTeam.description" name="team-description" :rows="10" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Action buttons -->
                  <div class="shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div class="flex justify-end space-x-3 pr-16">
                      <KodexaButton id="cancelCreateNewTeam" icon="cancel" type="secondary" @click="close">
                        Cancel
                      </KodexaButton>
                      <KodexaButton id="createNewTeam" icon="plus" type="primary" @click="createNewTeam">
                        Create
                      </KodexaButton>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
