<script setup lang="ts">
import { TreeView, TreeViewDragClue, processTreeViewItems } from "@progress/kendo-vue-treeview";
import type { PropType, Ref } from "vue";
import type { Taxon } from "~/model";

const props = defineProps({
  taxons: {
    type: Object as PropType<Taxon[]>,
    required: true,
  },
});

const emit = defineEmits(["selectTaxon"]);

const expand = ref({
  ids: [],
  idField: "id",
});

const treeDataItems = computed(() => {
  // We need to handle the taxonomy not being set
  if (props.taxons === undefined) {
    return [];
  }

  return processTreeViewItems(props.taxons, {
    expand: expand.value,
    childrenField: "children",
  });
});

function onExpandChange(event: any) {
  const index = expand.value.ids.indexOf(event.item.id);
  index === -1 ? expand.value.ids.push(event.item.id) : expand.value.ids.splice(index, 1);
}

const dragClue: Ref<any | null> = ref(null);

const selectTaxon = function (taxon: any) {
  emit("selectTaxon", taxon);
};

const editTaxonIds = ref([] as string[]);
const editTaxon = function (taxon: Taxon) {
  editTaxonIds.value.push(taxon.id as string);
};
</script>

<template>
  <div>
    <TreeViewDragClue ref="dragClue" />
    <TreeView
      class="mt-1"
      :data-items="treeDataItems"
      text-field="label"
      children-field="children"
      :expand-icons="true"
      :draggable="true"
      item="item"
      @expandchange="onExpandChange"
    >
      <template #item="{ props }">
        <div @dblclick="editTaxon(props.item)" @click="selectTaxon(props.item)">
          <MaterialDesignIcon
            v-if="editTaxonIds.indexOf(props.item.id) < 0"
            name="tag" size="14" class="mr-1" :style="{
              color: props.item.color,
            }"
          />
          <span v-if="editTaxonIds.indexOf(props.item.id) < 0">{{ props.item.label }} </span>
          <KodexaTextInput
            v-else v-model="props.item.label" name="taxonLabel"
            @on-enter="stopEditTaxon(props.item)"
            @on-esc="stopEditTaxon(props.item)"
            @blur="stopEditTaxon(props.item)"
          />
          <MaterialDesignIcon v-if="editTaxonIds.indexOf(props.item.id) < 0 && props.item.group" class="ml-4" name="plus" size="12" @click="addChildTaxon(props.item)" />
        </div>
      </template>
    </TreeView>
  </div>
</template>

<style scoped>

</style>
