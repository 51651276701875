<script setup lang="ts">
import type { PropType } from "vue";
import { storeToRefs } from "pinia";
import { createConfirmDialog } from "vuejs-confirm-dialog";
import type { ContentObject, Execution, ExecutionEvent, ExecutionLogEntry } from "~/model";
import { getExecutionLogs, listExecutionEventsForExecutionId } from "~/api/executions/executions";
import appStore from "~/store";
import { getExecutionContentObject } from "~/api/sessions/sessions";
import KodexaExceptionDetailsPopup from "~/components/document/kodexa-exception-details-popup.vue";
import KodexaLogsPopup from "~/components/logs-view/kodexa-logs-popup.vue";
import { log } from "~/utils/logger";

const props = defineProps({
  execution: {
    type: Object as PropType<Execution>,
    required: true,
  },
  popupLogs: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const pipeline = computed(() => {
  if (props.execution && props.execution.pipeline && props.execution.pipeline.steps) {
    return props.execution.pipeline.steps;
  }
  return [];
});

const executionEvents = ref([] as ExecutionEvent[]);

watch(props.execution, (newValue) => {
  if (newValue) {
    listExecutionEventsForExecutionId(newValue.id as string, {}).then((eventPage) => {
      executionEvents.value = eventPage.content;
    });
  }
}, {
  immediate: true,
});
const logs = ref(undefined as string | undefined);

const text = computed(() => {
  if (logs.value !== undefined) {
    return logs.value;
  } else {
    loadLogs();
  }

  return logs.value;
});

const currentPage = ref(1);

async function loadLogs() {
  const response = await getExecutionLogs(props.execution.id as string, {
    page: currentPage.value,
    pageSize: 30,
    sort: "logSequence:asc",
  });
  if (logs.value === undefined) {
    logs.value = "";
  }

  if (response.content && response.content.length > 0) {
    logs.value = `${logs.value}\n${response.content ? response.content.map((log: ExecutionLogEntry) => `${log.logDate}: ${log.entry}`).join("\n") : ""}`;
    currentPage.value++;
  }

  setTimeout(async () => {
    await loadLogs();
  }, 150);
}

const { user } = storeToRefs(appStore.userStore);

function borderColorClass(status: string) {
  switch (status) {
    case "SUCCEEDED":
      return "border-blue-600 hover:border-blue-800";
    case "RUNNING":
      return "border-green-300";
    case "FAILED":
    case "CANCELED":
      return "border-red-600 hover:border-red-800";
    default:
      return "border-gray-600 hover:border-gray-800";
  }
}

async function downloadContentObject(contentObjectId: string) {
  const blob: Blob = await getExecutionContentObject(props.execution?.session?.id as string, props.execution.id as string, contentObjectId);
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${contentObjectId}.kddb`;
  link.click();
  URL.revokeObjectURL(link.href);
}

function getContentObjectFromExecution(contentObjectId: string) {
  let identifiedContentObject: ContentObject | undefined;
  props.execution?.contentObjects?.forEach((contentObject) => {
    if (contentObject.id === contentObjectId) {
      identifiedContentObject = contentObject;
    }
  });
  return identifiedContentObject;
}

function openLogs(execution: Execution) {
  // Open a text viewer with the logs
  log.info(`Opening logs ${props.popupLogs}`);
  if (props.popupLogs) {
    log.info("Using popup logs");
    const executionLogsPopup = createConfirmDialog(KodexaLogsPopup);
    executionLogsPopup.reveal({
      execution,
    });
  } else {
    appStore.workspaceStore.createTextViewer(`${execution.description} (logs)`, "", execution.id);
  }
}

function openExceptionDetails() {
  const exceptionDetailsPopup = createConfirmDialog(KodexaExceptionDetailsPopup);
  exceptionDetailsPopup.reveal({
    exceptionDetails: props.execution.exceptionDetails,
  });
}
</script>

<template>
  <div class="ml-4">
    <div class="mt-10 inline-flex items-center rounded-md text-sm text-gray-600">
      <KodexaStatusIcon :status="execution.status" size="42" />
      <span class="-mt-2 ml-2 text-lg font-bold">{{ execution.description }} </span>
    </div>
    <div>
      <KodexaClipboardable
        v-if="user.showDeveloperTools && execution"
        class="mb-2 ml-12 text-xs" :content="JSON.stringify(execution)"
        :show-content="false"
        message="Execution Metadata"
      />
    </div>
    <div v-if="execution.loggingEnabled && user.showDeveloperTools">
      <div
        id="openLogs"
        class="ml-11 cursor-pointer text-xs"
        @click="openLogs(execution)"
      >
        <MaterialDesignIcon
          name="text-box-outline"
          style="margin-right:5px; margin-left: 5px;"
          size="12"
        />
        Open Logs
      </div>
    </div>
    <div
      v-if="(execution.exceptionDetails || execution.status === 'FAILED' || execution.status === 'CANCELLED') && user.showDeveloperTools"
    >
      <div
        id="openExceptionDetails"
        class="ml-11 cursor-pointer text-xs"
        @click="openExceptionDetails"
      >
        <MaterialDesignIcon
          name="alert-circle-outline"
          class="accent-red-700"
          style="margin-right:5px; margin-left: 5px;"
          size="12"
        />
        Show Exception Details
      </div>
    </div>
    <div
      v-if="execution.pipeline?.steps && execution.pipeline.steps.length > 0"
      class="ml-9 flex items-center space-x-4 overflow-x-auto p-4"
    >
      <nav aria-label="Progress">
        <ol role="list" class="space-y-4 md:flex md:space-x-8 md:space-y-0">
          <li v-for="(step) in execution.pipeline.steps" :key="step.id" class="md:flex-1">
            <div
              class="group flex flex-col border-l-4 py-2 pl-4 md:border-l-0 md:border-t-4 md:pb-0 md:pl-0 md:pt-4"
              :class="borderColorClass(step.status)"
              :aria-current="step.status === 'RUNNING' ? 'step' : false"
            >
              <div class="h-28">
                <span class="text-sm font-medium">{{ step.name }}</span>
                <div class="mt-2 text-xs text-gray-500">
                  {{ step.status }}
                </div>
                <div v-if="step.progress" class="mb-4 h-1.5 w-full rounded-full bg-gray-200 dark:bg-gray-700">
                  <div class="h-1.5 rounded-full bg-blue-600 dark:bg-blue-500" :style="{ width: `${((step.progress / step.maxProgress) * 100)}%` }" />
                </div>
              </div>

              <div v-if="step.inputId && user.showDeveloperTools" class="text-xs text-gray-500" @click="downloadContentObject(step.inputId)">
                <MaterialDesignIcon name="cloud-download" size="12" class="mr-1" />
                Input Content Object (<KodexaFileSizeView :size="getContentObjectFromExecution(step.inputId).size" />)
              </div>
              <span v-if="step.outputId && user.showDeveloperTools" class="text-xs text-gray-500" @click="downloadContentObject(step.outputId)">
                <MaterialDesignIcon
                  name="cloud-download" size="12"
                  class="mr-1"
                />  Output Content Object  (<KodexaFileSizeView :size="getContentObjectFromExecution(step.outputId).size" />)

              </span>
            </div>
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<style scoped>
</style>
