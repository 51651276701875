<!--
  - Copyright (C) 2024 Kodexa Inc - All Rights Reserved
  -
  - Unauthorized copying of this file, via any medium is strictly prohibited.
  - Proprietary and confidential.
  -->

<script setup lang="ts">
import VueOfficeExcel from "@vue-office/excel";
import "@vue-office/excel/lib/index.css";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import { createDocumentViewerStore } from "~/store/useDocumentView";

const props = defineProps<{ viewId: string; kioskMode: boolean }>();

const {
  dataForms,
  workspace,
  assistants,
  tagMetadataMap,
} = storeToRefs(appStore.projectStore);

const useDocumentViewStore = createDocumentViewerStore(props.viewId);
const { nativeDocument } = storeToRefs(useDocumentViewStore);

const excel = computed(() => {
  if (!nativeDocument.value) {
    return null;
  }
  return new Blob([nativeDocument.value]);
});

onMounted(async () => {
  await useDocumentViewStore.loadNative();
});
</script>

<template>
  <div style="height: calc(100vh - 220px); overflow-y: scroll; width: 100%;o">
    <VueOfficeExcel
      v-if="nativeDocument"
      :src="excel"
    />
  </div>
</template>

<style>
</style>
