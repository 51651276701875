<script setup lang="ts">
import {Dialog, DialogPanel, TransitionChild, TransitionRoot} from "@headlessui/vue";
import type {PropType} from "vue";
import {DocumentFamily, Store} from "~/model";
import appStore from "~/store";

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  documentFamilies: {
    type: Array as PropType<DocumentFamily[]>,
    required: true,
  },
  lock: {
    type: Boolean,
    required: true,
  },
  store: {
    type: Object as PropType<Store>,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue","clearSelection"]);

function close() {
  emit("update:modelValue", false);
}

const impactedDocuments = computed(() => {
  return props.documentFamilies?.length;
});

const selectAll = ref(false);


const loading = ref(false);
async function doAction() {
  loading.value = true;
  if (props.lock) {
    await appStore.projectStore.lockDocumentFamilies(props.documentFamilies);
  } else {
    await appStore.projectStore.unlockDocumentFamilies(props.documentFamilies);
  }
  loading.value = false;
  emit("clearSelection");
}
</script>

<template>
  <TransitionRoot as="template" :show="modelValue">
    <Dialog as="div" class="relative z-10" @close="close">
      <TransitionChild
        as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
        leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed inset-0 z-10">
        <div class="flex min-h-full items-end justify-center p-4  text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template" enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              style="width: 700px; height: 380px"
              class="relative overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all"
            >
              <div class="ml-4 pb-2">
                <KodexaHeader :title="lock ? 'Bulk Lock Documents' : 'Bulk Unlock Documents'"
                              :sub-title="lock ? 'Lock the selected documents' : 'Unlock the selected documents'"/>
              </div>
              <div class="mx-6 mt-8 mb-24">
                <p>
                  You have selected <span class="font-bold">{{ selectAll ? "all" : impactedDocuments }}</span>
                  documents from the store <span class="font-bold">{{ props.store.name }}</span>.
                </p>
                <p>
                  <KodexaCheckbox
                    name="selectAll"
                    class="mt-6"
                    label="Select All Documents"
                    hint="Checking this option will select all documents in the store"
                    v-model="selectAll"/>
                </p>
              </div>
              <div
                class="mx-4 mt-12 sm:flex sm:flex-row-reverse">
                <KodexaButton :icon="lock ? 'lock' : 'lock-open'" width="180px" @click="doAction" :loading="loading">
                  {{ lock ? 'Lock Documents' : 'Unlock Documents' }}
                </KodexaButton>
                <KodexaButton
                  class="mr-2" width="120px"
                  icon="cancel" type="secondary"
                  :disabled="loading"
                  @click="close">
                  Cancel
                </KodexaButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
