<script setup lang="ts">
import {type Ref, ref} from 'vue';
import {type Store, storeToRefs} from "pinia";
import KodexaButton from "~/components/inputs/kodexa-button.vue";
import appStore from "~/store";
import {nextTick} from 'vue';
import type {CheckoutRequest, LineItem, Product} from "~/model";
import {loadStripe, type StripeEmbeddedCheckout} from "@stripe/stripe-js";
import {prepareCheckout} from "~/api/stripe-overview/stripe-overview";
import {useListProductSubscriptions} from "~/api/product-subscriptions/product-subscriptions";
import {useListProducts} from "~/api/product-overview/product-overview";
import {createGridHelper} from "~/store/useGridHelper";


const {
  creditStatus, currentOrganization
} = storeToRefs(appStore.organizationStore);

const productSubscriptionGridHelper = createGridHelper("product-subscription-list", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "",
}, `organization.id: '${currentOrganization.value.id}'`);

const {
  gridQuery: productSubscriptionQuery,
} = storeToRefs(productSubscriptionGridHelper);

const {data: productSubscriptions, refetch} = useListProductSubscriptions(productSubscriptionQuery.value);


const productGridHelper = createGridHelper("product-list", {
  page: 1,
  pageSize: 10,
  filter: "",
  query: "",
  sort: "order",
});


watch(productSubscriptions, (newValue) => {
  if (newValue?.content) {
    productGridHelper.setBaseFilter(`parent.id in (${newValue.content.map((subscription) => `'${subscription.product.id}'`).join(",")})`)
  }
}, {immediate: true});

const {
  gridQuery: productQuery,
} = storeToRefs(productGridHelper);


const {data: products} = useListProducts(productQuery);


const remainingCredits = computed(() => {
  if(!creditStatus.value?.amount) {
    return 0;
  }
  return creditStatus.value.amount.toFixed(2);
});


const count = ref(0);
const showCheckout = ref(false);

const initialCredits = computed(() => {
  if(!products?.value?.content){
    return 0;
  }else{
    return products.value.content[0].price;
  }
})

const totalCredits = computed(() => {
  const total = count.value * productAddOn.value?.numberOfCredits;
  return total.toFixed(2);
});

const overallCredits = computed(() => {
  const amount = creditStatus.value?.amount ? creditStatus.value.amount : 0;
  const overall = parseFloat(totalCredits.value) + parseFloat(amount);
  return overall.toFixed(2);
});


const loadingCheckout = ref(false);
const checkout: Ref<StripeEmbeddedCheckout | null> = ref(null);
const instance = getCurrentInstance();
const stripeKey = instance?.appContext.config.globalProperties.stripeKey;

async function createCheckoutSession() {
  if(!products.value?.content){
    return;
  }
  showCheckout.value = true
  loadingCheckout.value = true;
  const updatedLineItems = [{productId: products.value.content[0].id, quantity: count.value}];
  const stripe = await loadStripe(stripeKey);
  const checkoutRequest = {
    newOrganization: currentOrganization.value,
    organizationId: currentOrganization.value.id,
    lineItems: updatedLineItems as LineItem[],
    subscription: false
  } as CheckoutRequest;

  try {
    const response = await prepareCheckout(checkoutRequest);
    const clientSecret = response.clientSecret;
    checkout.value = await stripe.initEmbeddedCheckout({
      clientSecret,
    });

    // Mount Checkout
    checkout.value.mount("#stripe-checkout");
    showCheckout.value = true;
  } catch (e) {
    console.error(e);
  }
  loadingCheckout.value = false;
}

const productAddOn = computed(() => {
  if(!products.value?.content){
    return null;
  }else{
    return products.value.content[0];
  }
})

onUnmounted(() => {
  checkout.value?.destroy()
});

function handleGoBack(){
  showCheckout.value = false;
  checkout.value?.unmount();
  checkout.value?.destroy();
  checkout.value = null;
}

</script>

<template>
  <div v-if="!showCheckout" class="addCredits">
    <div class="flex grid-cols-1 justify-center">
      <div class="col-span-1 text-center">
        <h2 class="text-2xl font-semibold text-gray-600 mt-10">
          Top-up Credits
        </h2>
        <p class="px-50 mt-1 text-sm text-gray-500 w-3/5 d-block m-auto">
          Welcome to our top-up credits page, where you can effortlessly replenish your Organization’s AI credits and
          unlock a world of convenience and accessibility.
        </p>
      </div>
    </div>
    <div>
      <p class="remaining-credits w-4/12 text-xs font-light d-block m-auto mt-4">
        <MaterialDesignIcon name="credit-card" size="14" aria-hidden="true" class="mr-1"/>
        You currently have {{ remainingCredits }} AI Credits. You can top-up your credits below.
      </p>
    </div>
    <div class="credit-container">
      <h3 class="text-sm text-gray-400">
        AI Credits
      </h3>
      <div class="flex justify-between">
        <p class="credit-count">
          {{ productAddOn?.numberOfCredits}}
        </p>
        <p class="credit-price">
          ${{ productAddOn?.price}}
        </p>
      </div>
      <div class="counter-container flex justify-center mt-4">
        <div class="mr-3">
          <button id="minusButton" class="minus-btn" @click="count > 0 ? count-- : null">
            <MaterialDesignIcon name="minus" size="15"></MaterialDesignIcon>
          </button>
        </div>
        <div class="">
          <h1 id="quantityCount">{{ count }}</h1>
        </div>
        <div class="ml-3">
          <button id="plusButton" class="plus-btn" @click="count++">
            <MaterialDesignIcon name="plus" size="15"></MaterialDesignIcon>
          </button>
        </div>
      </div>
      <div class="text-xs text-gray-400 text-center">
        Quantity
      </div>
    </div>

    <div class="credit-breakdown w-4/12 d-block m-auto">
      <p style="color:#1359A5;" class="font-light text-sm mt-7">Here's a breakdown of your expected credits after
        checkout.</p>
      <div class="flex justify-between text-sm text-gray-400 mt-3">
        <p>{{ productAddOn?.numberOfCredits}} AI Credits - X{{ count }}</p>
        <p id="totalCredits">{{ totalCredits }}</p>
      </div>
      <div class="flex justify-between text-sm text-gray-400">
        <p>Remaining AI Credits</p>
        <p id="remainingCredits">{{ remainingCredits }}</p>
      </div>
      <hr class="mt-1 mb-1">
      <div class="flex justify-between text-sm text-gray-400">
        <p>Total</p>
        <p id="overallCredits">{{ overallCredits }}</p>
      </div>
    </div>


    <div class="mt-16">
      <KodexaButton id="checkoutButton" @click="createCheckoutSession" class="checkoutBtn" width="150px">
        Checkout
      </KodexaButton>
    </div>
  </div>

    <div v-if="showCheckout" class="stripe-payment mt-5 w-full">
      <div class="mb-16" id="stripe-checkout" style="overflow-y: scroll"/>
      <KodexaButton id="goBackButton" v-if="checkout" @click="handleGoBack" type="secondary" width="150px" class="backBtn">
        Go Back
      </KodexaButton>
    </div>
</template>

<style scoped>
.credit-container {
  width: 170px;
  height: auto;
  border-radius: 10px;
  border: 1px solid #F6F6F6;
  background: #FFF;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.15);
  display: block;
  margin: auto;
  margin-top: 30px;
  padding: 15px
}

.remaining-credits {
  border-radius: 5px;
  background: linear-gradient(90deg, #00B0F0 0%, #0957AA 100%);
  padding: 10px;
  color: white;
}

.credit-count {
  color: #1359A5;
  font-size: 30px;
  font-weight: 500;
  display: block;
  margin-top: auto;
}

.credit-price {
  color: #757575;
  font-size: 14px;
  font-weight: 300;
  display: block;
  margin-top: auto;
  margin-bottom: auto;
}

.minus-btn, .plus-btn {
  width: 30px;
  height: 30px;
  background-color: #1359A5;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  border-radius: 20px;
  color: white;
}

.checkoutBtn, .backBtn {
  display: block;
  margin: auto;
}
</style>
