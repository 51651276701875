/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */
import {
  useMutation,
  useQuery
} from '@tanstack/vue-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryReturnType
} from '@tanstack/vue-query'
import {
  computed,
  unref
} from 'vue'
import type {
  MaybeRef
} from 'vue'
import type {
  ActivityStatistics
} from '../../model/activityStatistics'
import type {
  AddContentObjectToFamilyBody
} from '../../model/addContentObjectToFamilyBody'
import type {
  AddContentObjectToFamilyParams
} from '../../model/addContentObjectToFamilyParams'
import type {
  AddLabelToDocumentFamilyParams
} from '../../model/addLabelToDocumentFamilyParams'
import type {
  Assistant
} from '../../model/assistant'
import type {
  BulkCopy
} from '../../model/bulkCopy'
import type {
  BulkDelete
} from '../../model/bulkDelete'
import type {
  BulkLock
} from '../../model/bulkLock'
import type {
  ContentObject
} from '../../model/contentObject'
import type {
  CreateContentObjectWithVersionBody
} from '../../model/createContentObjectWithVersionBody'
import type {
  CreateContentObjectWithVersionParams
} from '../../model/createContentObjectWithVersionParams'
import type {
  CreateStoreParams
} from '../../model/createStoreParams'
import type {
  DataAttribute
} from '../../model/dataAttribute'
import type {
  DataException
} from '../../model/dataException'
import type {
  DataObject
} from '../../model/dataObject'
import type {
  DeleteFamilyByPathParams
} from '../../model/deleteFamilyByPathParams'
import type {
  DeleteFamilyByPathWithVersionParams
} from '../../model/deleteFamilyByPathWithVersionParams'
import type {
  DeleteStoreParams
} from '../../model/deleteStoreParams'
import type {
  DeleteVersionStoreParams
} from '../../model/deleteVersionStoreParams'
import type {
  DocumentAssignment
} from '../../model/documentAssignment'
import type {
  DocumentFamily
} from '../../model/documentFamily'
import type {
  DocumentStatus
} from '../../model/documentStatus'
import type {
  ExceptionResponse
} from '../../model/exceptionResponse'
import type {
  FamilyRename
} from '../../model/familyRename'
import type {
  FeatureSet
} from '../../model/featureSet'
import type {
  GetActivityStatisticsParams
} from '../../model/getActivityStatisticsParams'
import type {
  GetContentByFsAndVersionParams
} from '../../model/getContentByFsAndVersionParams'
import type {
  GetContentByFsParams
} from '../../model/getContentByFsParams'
import type {
  GetContentObjectImageParams
} from '../../model/getContentObjectImageParams'
import type {
  GetContentObjectImageWithVersionParams
} from '../../model/getContentObjectImageWithVersionParams'
import type {
  GetDataAttributesParams
} from '../../model/getDataAttributesParams'
import type {
  GetDataObjectChildrenParams
} from '../../model/getDataObjectChildrenParams'
import type {
  GetDataObjectChildrenWithVersionParams
} from '../../model/getDataObjectChildrenWithVersionParams'
import type {
  GetDataObjects200
} from '../../model/getDataObjects200'
import type {
  GetDataObjectsParams
} from '../../model/getDataObjectsParams'
import type {
  GetDataObjectsWithVersion200
} from '../../model/getDataObjectsWithVersion200'
import type {
  GetDataObjectsWithVersionParams
} from '../../model/getDataObjectsWithVersionParams'
import type {
  GetExceptionsParams
} from '../../model/getExceptionsParams'
import type {
  GetExecutionsForDocumentFamilyWithVersionParams
} from '../../model/getExecutionsForDocumentFamilyWithVersionParams'
import type {
  GetFamilyByPathParams
} from '../../model/getFamilyByPathParams'
import type {
  GetFamilyByPathWithVersionParams
} from '../../model/getFamilyByPathWithVersionParams'
import type {
  GetFamilyExtractedDataStructureWithVersionParams
} from '../../model/getFamilyExtractedDataStructureWithVersionParams'
import type {
  GetFamilyExtractedDataWithVersion200
} from '../../model/getFamilyExtractedDataWithVersion200'
import type {
  GetFamilyExtractedDataWithVersionParams
} from '../../model/getFamilyExtractedDataWithVersionParams'
import type {
  GetPreviewForAttributeTagMetadataParams
} from '../../model/getPreviewForAttributeTagMetadataParams'
import type {
  GetStoreMetadata200
} from '../../model/getStoreMetadata200'
import type {
  GetWithVersionExceptionsParams
} from '../../model/getWithVersionExceptionsParams'
import type {
  ImportFamilyBody
} from '../../model/importFamilyBody'
import type {
  ImportFamilyParams
} from '../../model/importFamilyParams'
import type {
  Label
} from '../../model/label'
import type {
  ListFamiliesParams
} from '../../model/listFamiliesParams'
import type {
  ListFamiliesWithVersionParams
} from '../../model/listFamiliesWithVersionParams'
import type {
  ListStoresForOrganizationParams
} from '../../model/listStoresForOrganizationParams'
import type {
  ListStoresParams
} from '../../model/listStoresParams'
import type {
  ListTrainingsParams
} from '../../model/listTrainingsParams'
import type {
  ModelTraining
} from '../../model/modelTraining'
import type {
  PageDataAttribute
} from '../../model/pageDataAttribute'
import type {
  PageDataException
} from '../../model/pageDataException'
import type {
  PageDataObject
} from '../../model/pageDataObject'
import type {
  PageDocumentFamily
} from '../../model/pageDocumentFamily'
import type {
  PageExecution
} from '../../model/pageExecution'
import type {
  PageModelTraining
} from '../../model/pageModelTraining'
import type {
  PageStore
} from '../../model/pageStore'
import type {
  PathExtractedData
} from '../../model/pathExtractedData'
import type {
  RemoveLabelFromDocumentFamilyParams
} from '../../model/removeLabelFromDocumentFamilyParams'
import type {
  RenameFamilyParams
} from '../../model/renameFamilyParams'
import type {
  RenameFamilyWithVersionParams
} from '../../model/renameFamilyWithVersionParams'
import type {
  ReprocessDocumentFamilyParams
} from '../../model/reprocessDocumentFamilyParams'
import type {
  ReprocessFamilyWithVersionParams
} from '../../model/reprocessFamilyWithVersionParams'
import type {
  ReprocessRequest
} from '../../model/reprocessRequest'
import type {
  Store
} from '../../model/store'
import type {
  StoreViewOptions
} from '../../model/storeViewOptions'
import type {
  StreamingResponseBody
} from '../../model/streamingResponseBody'
import type {
  Taxonomy
} from '../../model/taxonomy'
import type {
  ThrowableProblem
} from '../../model/throwableProblem'
import type {
  UpdateAssigneesWithVersionParams
} from '../../model/updateAssigneesWithVersionParams'
import type {
  UpdateContentObjectInFamilyBody
} from '../../model/updateContentObjectInFamilyBody'
import type {
  UpdateStoreMetadata200
} from '../../model/updateStoreMetadata200'
import type {
  UpdateStoreMetadataBody
} from '../../model/updateStoreMetadataBody'
import type {
  UpdateTagsOnContentObjectParams
} from '../../model/updateTagsOnContentObjectParams'
import type {
  UploadModelImplementationBody
} from '../../model/uploadModelImplementationBody'
import type {
  UploadToPathBody
} from '../../model/uploadToPathBody'
import type {
  UploadToPathParams
} from '../../model/uploadToPathParams'
import type {
  UploadToPathWithVersionBody
} from '../../model/uploadToPathWithVersionBody'
import type {
  UploadToPathWithVersionParams
} from '../../model/uploadToPathWithVersionParams'
import type {
  UploadingTrainingContentBody
} from '../../model/uploadingTrainingContentBody'
import type {
  User
} from '../../model/user'
import type {
  ViewPreset
} from '../../model/viewPreset'
import { customAxios } from '.././custom-axios';
import type { ErrorType } from '.././custom-axios';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;


/**
 * Get the current version of the object with given slug
 */
export const getStore = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
      
      return customAxios<Store>(
      {url: `/api/stores/${organizationSlug}/${slug}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStoreQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,) => {
    return ['api','stores',organizationSlug,slug] as const;
    }

    
export const getGetStoreQueryOptions = <TData = Awaited<ReturnType<typeof getStore>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStore>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetStoreQueryKey(organizationSlug,slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStore>>> = ({ signal }) => getStore(organizationSlug,slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStore>>, TError, TData> 
}

export type GetStoreQueryResult = NonNullable<Awaited<ReturnType<typeof getStore>>>
export type GetStoreQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetStore = <TData = Awaited<ReturnType<typeof getStore>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStore>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStoreQueryOptions(organizationSlug,slug,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the current version object with given slug in the organization
 */
export const updateStore = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    store: MaybeRef<Store>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
store = unref(store);
      
      return customAxios<Store>(
      {url: `/api/stores/${organizationSlug}/${slug}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: store
    },
      options);
    }
  


export const getUpdateStoreMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStore>>, TError,{organizationSlug: string;slug: string;data: Store}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateStore>>, TError,{organizationSlug: string;slug: string;data: Store}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStore>>, {organizationSlug: string;slug: string;data: Store}> = (props) => {
          const {organizationSlug,slug,data} = props ?? {};

          return  updateStore(organizationSlug,slug,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateStoreMutationResult = NonNullable<Awaited<ReturnType<typeof updateStore>>>
    export type UpdateStoreMutationBody = Store
    export type UpdateStoreMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateStore = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStore>>, TError,{organizationSlug: string;slug: string;data: Store}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateStoreMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the current version of the given object
 */
export const deleteStore = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<DeleteStoreParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<StreamingResponseBody>(
      {url: `/api/stores/${organizationSlug}/${slug}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteStoreMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStore>>, TError,{organizationSlug: string;slug: string;params?: DeleteStoreParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteStore>>, TError,{organizationSlug: string;slug: string;params?: DeleteStoreParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteStore>>, {organizationSlug: string;slug: string;params?: DeleteStoreParams}> = (props) => {
          const {organizationSlug,slug,params} = props ?? {};

          return  deleteStore(organizationSlug,slug,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteStoreMutationResult = NonNullable<Awaited<ReturnType<typeof deleteStore>>>
    
    export type DeleteStoreMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteStore = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStore>>, TError,{organizationSlug: string;slug: string;params?: DeleteStoreParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteStoreMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the specific version of the object with given slug
 */
export const getVersionStore = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<Store>(
      {url: `/api/stores/${organizationSlug}/${slug}/${version}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetVersionStoreQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','stores',organizationSlug,slug,version] as const;
    }

    
export const getGetVersionStoreQueryOptions = <TData = Awaited<ReturnType<typeof getVersionStore>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionStore>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetVersionStoreQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getVersionStore>>> = ({ signal }) => getVersionStore(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getVersionStore>>, TError, TData> 
}

export type GetVersionStoreQueryResult = NonNullable<Awaited<ReturnType<typeof getVersionStore>>>
export type GetVersionStoreQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetVersionStore = <TData = Awaited<ReturnType<typeof getVersionStore>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getVersionStore>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetVersionStoreQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the object with given slug and version in the organization
 */
export const updateVersionStore = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    store: MaybeRef<Store>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
store = unref(store);
      
      return customAxios<Store>(
      {url: `/api/stores/${organizationSlug}/${slug}/${version}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: store
    },
      options);
    }
  


export const getUpdateVersionStoreMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionStore>>, TError,{organizationSlug: string;slug: string;version: string;data: Store}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateVersionStore>>, TError,{organizationSlug: string;slug: string;version: string;data: Store}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateVersionStore>>, {organizationSlug: string;slug: string;version: string;data: Store}> = (props) => {
          const {organizationSlug,slug,version,data} = props ?? {};

          return  updateVersionStore(organizationSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateVersionStoreMutationResult = NonNullable<Awaited<ReturnType<typeof updateVersionStore>>>
    export type UpdateVersionStoreMutationBody = Store
    export type UpdateVersionStoreMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateVersionStore = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateVersionStore>>, TError,{organizationSlug: string;slug: string;version: string;data: Store}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateVersionStoreMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the specified version of the given object
 */
export const deleteVersionStore = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<DeleteVersionStoreParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<boolean>(
      {url: `/api/stores/${organizationSlug}/${slug}/${version}`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteVersionStoreMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionStore>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionStoreParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteVersionStore>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionStoreParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteVersionStore>>, {organizationSlug: string;slug: string;version: string;params?: DeleteVersionStoreParams}> = (props) => {
          const {organizationSlug,slug,version,params} = props ?? {};

          return  deleteVersionStore(organizationSlug,slug,version,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteVersionStoreMutationResult = NonNullable<Awaited<ReturnType<typeof deleteVersionStore>>>
    
    export type DeleteVersionStoreMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteVersionStore = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteVersionStore>>, TError,{organizationSlug: string;slug: string;version: string;params?: DeleteVersionStoreParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteVersionStoreMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update the store view options
 */
export const updateStoreViewOptions = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    storeViewOptions: MaybeRef<StoreViewOptions>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
storeViewOptions = unref(storeViewOptions);
      
      return customAxios<Store>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/viewOptions`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: storeViewOptions
    },
      options);
    }
  


export const getUpdateStoreViewOptionsMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStoreViewOptions>>, TError,{orgSlug: string;slug: string;version: string;data: StoreViewOptions}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateStoreViewOptions>>, TError,{orgSlug: string;slug: string;version: string;data: StoreViewOptions}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStoreViewOptions>>, {orgSlug: string;slug: string;version: string;data: StoreViewOptions}> = (props) => {
          const {orgSlug,slug,version,data} = props ?? {};

          return  updateStoreViewOptions(orgSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateStoreViewOptionsMutationResult = NonNullable<Awaited<ReturnType<typeof updateStoreViewOptions>>>
    export type UpdateStoreViewOptionsMutationBody = StoreViewOptions
    export type UpdateStoreViewOptionsMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateStoreViewOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStoreViewOptions>>, TError,{orgSlug: string;slug: string;version: string;data: StoreViewOptions}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateStoreViewOptionsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List trainings in the current version of the model store
 */
export const getTraining = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
      
      return customAxios<ModelTraining>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/trainings/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTrainingQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'trainings',id] as const;
    }

    
export const getGetTrainingQueryOptions = <TData = Awaited<ReturnType<typeof getTraining>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTraining>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTrainingQueryKey(orgSlug,slug,version,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTraining>>> = ({ signal }) => getTraining(orgSlug,slug,version,id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTraining>>, TError, TData> 
}

export type GetTrainingQueryResult = NonNullable<Awaited<ReturnType<typeof getTraining>>>
export type GetTrainingQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetTraining = <TData = Awaited<ReturnType<typeof getTraining>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTraining>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTrainingQueryOptions(orgSlug,slug,version,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Delete a training in the current version of the model store
 */
export const updateTraining = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    modelTraining: MaybeRef<ModelTraining>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
modelTraining = unref(modelTraining);
      
      return customAxios<ModelTraining>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/trainings/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: modelTraining
    },
      options);
    }
  


export const getUpdateTrainingMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTraining>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: ModelTraining}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTraining>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: ModelTraining}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTraining>>, {orgSlug: string;slug: string;version: string;id: string;data: ModelTraining}> = (props) => {
          const {orgSlug,slug,version,id,data} = props ?? {};

          return  updateTraining(orgSlug,slug,version,id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateTrainingMutationResult = NonNullable<Awaited<ReturnType<typeof updateTraining>>>
    export type UpdateTrainingMutationBody = ModelTraining
    export type UpdateTrainingMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateTraining = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTraining>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: ModelTraining}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateTrainingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete a training in the current version of the model store
 */
export const deleteTraining = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
      
      return customAxios<boolean>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/trainings/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteTrainingMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTraining>>, TError,{orgSlug: string;slug: string;version: string;id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteTraining>>, TError,{orgSlug: string;slug: string;version: string;id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTraining>>, {orgSlug: string;slug: string;version: string;id: string}> = (props) => {
          const {orgSlug,slug,version,id} = props ?? {};

          return  deleteTraining(orgSlug,slug,version,id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteTrainingMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTraining>>>
    
    export type DeleteTrainingMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteTraining = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTraining>>, TError,{orgSlug: string;slug: string;version: string;id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteTrainingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Reprocess content in the store, allow you to provide a list of the family ID's
 */
export const reprocessWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    reprocessRequest: MaybeRef<ReprocessRequest>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
reprocessRequest = unref(reprocessRequest);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/reprocess`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: reprocessRequest
    },
      options);
    }
  


export const getReprocessWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reprocessWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: ReprocessRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof reprocessWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: ReprocessRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reprocessWithVersion>>, {orgSlug: string;slug: string;version: string;data: ReprocessRequest}> = (props) => {
          const {orgSlug,slug,version,data} = props ?? {};

          return  reprocessWithVersion(orgSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ReprocessWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof reprocessWithVersion>>>
    export type ReprocessWithVersionMutationBody = ReprocessRequest
    export type ReprocessWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useReprocessWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reprocessWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: ReprocessRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getReprocessWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a saved view preset by id
 */
export const getStoreViewPreset = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
      
      return customAxios<ViewPreset>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/presets/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStoreViewPresetQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'presets',id] as const;
    }

    
export const getGetStoreViewPresetQueryOptions = <TData = Awaited<ReturnType<typeof getStoreViewPreset>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStoreViewPreset>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetStoreViewPresetQueryKey(orgSlug,slug,version,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStoreViewPreset>>> = ({ signal }) => getStoreViewPreset(orgSlug,slug,version,id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStoreViewPreset>>, TError, TData> 
}

export type GetStoreViewPresetQueryResult = NonNullable<Awaited<ReturnType<typeof getStoreViewPreset>>>
export type GetStoreViewPresetQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetStoreViewPreset = <TData = Awaited<ReturnType<typeof getStoreViewPreset>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStoreViewPreset>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStoreViewPresetQueryOptions(orgSlug,slug,version,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the saved view presets
 */
export const updateStoreViewPreset = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    viewPreset: MaybeRef<ViewPreset>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
viewPreset = unref(viewPreset);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/presets/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: viewPreset
    },
      options);
    }
  


export const getUpdateStoreViewPresetMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStoreViewPreset>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: ViewPreset}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateStoreViewPreset>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: ViewPreset}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStoreViewPreset>>, {orgSlug: string;slug: string;version: string;id: string;data: ViewPreset}> = (props) => {
          const {orgSlug,slug,version,id,data} = props ?? {};

          return  updateStoreViewPreset(orgSlug,slug,version,id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateStoreViewPresetMutationResult = NonNullable<Awaited<ReturnType<typeof updateStoreViewPreset>>>
    export type UpdateStoreViewPresetMutationBody = ViewPreset
    export type UpdateStoreViewPresetMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateStoreViewPreset = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStoreViewPreset>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: ViewPreset}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateStoreViewPresetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete a saved view preset by id
 */
export const deleteStoreViewPreset = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/presets/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteStoreViewPresetMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStoreViewPreset>>, TError,{orgSlug: string;slug: string;version: string;id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteStoreViewPreset>>, TError,{orgSlug: string;slug: string;version: string;id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteStoreViewPreset>>, {orgSlug: string;slug: string;version: string;id: string}> = (props) => {
          const {orgSlug,slug,version,id} = props ?? {};

          return  deleteStoreViewPreset(orgSlug,slug,version,id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteStoreViewPresetMutationResult = NonNullable<Awaited<ReturnType<typeof deleteStoreViewPreset>>>
    
    export type DeleteStoreViewPresetMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteStoreViewPreset = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteStoreViewPreset>>, TError,{orgSlug: string;slug: string;version: string;id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteStoreViewPresetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the metadata associated with this store
 */
export const getStoreMetadata = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<GetStoreMetadata200>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/metadata`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStoreMetadataQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'metadata'] as const;
    }

    
export const getGetStoreMetadataQueryOptions = <TData = Awaited<ReturnType<typeof getStoreMetadata>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStoreMetadata>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetStoreMetadataQueryKey(orgSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStoreMetadata>>> = ({ signal }) => getStoreMetadata(orgSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStoreMetadata>>, TError, TData> 
}

export type GetStoreMetadataQueryResult = NonNullable<Awaited<ReturnType<typeof getStoreMetadata>>>
export type GetStoreMetadataQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetStoreMetadata = <TData = Awaited<ReturnType<typeof getStoreMetadata>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStoreMetadata>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStoreMetadataQueryOptions(orgSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Update the content metadata for this store
 */
export const updateStoreMetadata = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    updateStoreMetadataBody: MaybeRef<UpdateStoreMetadataBody>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
updateStoreMetadataBody = unref(updateStoreMetadataBody);
      
      return customAxios<UpdateStoreMetadata200>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/metadata`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateStoreMetadataBody
    },
      options);
    }
  


export const getUpdateStoreMetadataMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStoreMetadata>>, TError,{orgSlug: string;slug: string;version: string;data: UpdateStoreMetadataBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateStoreMetadata>>, TError,{orgSlug: string;slug: string;version: string;data: UpdateStoreMetadataBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStoreMetadata>>, {orgSlug: string;slug: string;version: string;data: UpdateStoreMetadataBody}> = (props) => {
          const {orgSlug,slug,version,data} = props ?? {};

          return  updateStoreMetadata(orgSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateStoreMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof updateStoreMetadata>>>
    export type UpdateStoreMetadataMutationBody = UpdateStoreMetadataBody
    export type UpdateStoreMetadataMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateStoreMetadata = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStoreMetadata>>, TError,{orgSlug: string;slug: string;version: string;data: UpdateStoreMetadataBody}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateStoreMetadataMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getContentByFsAndVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetContentByFsAndVersionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/fs`, method: 'GET',
        params: unref(params),
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetContentByFsAndVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetContentByFsAndVersionParams>,) => {
    return ['api','stores',orgSlug,slug,version,'fs', ...(params ? [params]: [])] as const;
    }

    
export const getGetContentByFsAndVersionQueryOptions = <TData = Awaited<ReturnType<typeof getContentByFsAndVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetContentByFsAndVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentByFsAndVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetContentByFsAndVersionQueryKey(orgSlug,slug,version,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentByFsAndVersion>>> = ({ signal }) => getContentByFsAndVersion(orgSlug,slug,version,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContentByFsAndVersion>>, TError, TData> 
}

export type GetContentByFsAndVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getContentByFsAndVersion>>>
export type GetContentByFsAndVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetContentByFsAndVersion = <TData = Awaited<ReturnType<typeof getContentByFsAndVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetContentByFsAndVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentByFsAndVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContentByFsAndVersionQueryOptions(orgSlug,slug,version,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Rename the path of a document family in the current version of the store
 */
export const renameFamilyWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<RenameFamilyWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/fs`, method: 'PUT',
        params: unref(params)
    },
      options);
    }
  


export const getRenameFamilyWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;params?: RenameFamilyWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof renameFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;params?: RenameFamilyWithVersionParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameFamilyWithVersion>>, {orgSlug: string;slug: string;version: string;params?: RenameFamilyWithVersionParams}> = (props) => {
          const {orgSlug,slug,version,params} = props ?? {};

          return  renameFamilyWithVersion(orgSlug,slug,version,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RenameFamilyWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof renameFamilyWithVersion>>>
    
    export type RenameFamilyWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useRenameFamilyWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;params?: RenameFamilyWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getRenameFamilyWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a specific document family in a version of the store
 */
export const getFamilyWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFamilyWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'families',id] as const;
    }

    
export const getGetFamilyWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof getFamilyWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFamilyWithVersionQueryKey(orgSlug,slug,version,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFamilyWithVersion>>> = ({ signal }) => getFamilyWithVersion(orgSlug,slug,version,id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFamilyWithVersion>>, TError, TData> 
}

export type GetFamilyWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getFamilyWithVersion>>>
export type GetFamilyWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetFamilyWithVersion = <TData = Awaited<ReturnType<typeof getFamilyWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFamilyWithVersionQueryOptions(orgSlug,slug,version,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get a specific document family in the current version of the store
 */
export const updateFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    familyRename: MaybeRef<FamilyRename>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
familyRename = unref(familyRename);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: familyRename
    },
      options);
    }
  


export const getUpdateFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: FamilyRename}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: FamilyRename}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFamily>>, {orgSlug: string;slug: string;version: string;id: string;data: FamilyRename}> = (props) => {
          const {orgSlug,slug,version,id,data} = props ?? {};

          return  updateFamily(orgSlug,slug,version,id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof updateFamily>>>
    export type UpdateFamilyMutationBody = FamilyRename
    export type UpdateFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: FamilyRename}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete a specific document family in a version of a store
 */
export const deleteFamilyIDWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
      
      return customAxios<boolean>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFamilyIDWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyIDWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyIDWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFamilyIDWithVersion>>, {orgSlug: string;slug: string;version: string;id: string}> = (props) => {
          const {orgSlug,slug,version,id} = props ?? {};

          return  deleteFamilyIDWithVersion(orgSlug,slug,version,id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteFamilyIDWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFamilyIDWithVersion>>>
    
    export type DeleteFamilyIDWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteFamilyIDWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyIDWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteFamilyIDWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update the document status
 */
export const updateStatusWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    documentStatus: MaybeRef<DocumentStatus>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
documentStatus = unref(documentStatus);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/status`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: documentStatus
    },
      options);
    }
  


export const getUpdateStatusWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStatusWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: DocumentStatus}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateStatusWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: DocumentStatus}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateStatusWithVersion>>, {orgSlug: string;slug: string;version: string;id: string;data: DocumentStatus}> = (props) => {
          const {orgSlug,slug,version,id,data} = props ?? {};

          return  updateStatusWithVersion(orgSlug,slug,version,id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateStatusWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof updateStatusWithVersion>>>
    export type UpdateStatusWithVersionMutationBody = DocumentStatus
    export type UpdateStatusWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateStatusWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateStatusWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: DocumentStatus}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateStatusWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Reprocess content in this family for a specific set of assistants in a version of a store
 */
export const reprocessFamilyWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    params: MaybeRef<ReprocessFamilyWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
params = unref(params);
      
      return customAxios<StreamingResponseBody>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/reprocess`, method: 'PUT',
        params: unref(params)
    },
      options);
    }
  


export const getReprocessFamilyWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reprocessFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string;params: ReprocessFamilyWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof reprocessFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string;params: ReprocessFamilyWithVersionParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reprocessFamilyWithVersion>>, {orgSlug: string;slug: string;version: string;id: string;params: ReprocessFamilyWithVersionParams}> = (props) => {
          const {orgSlug,slug,version,id,params} = props ?? {};

          return  reprocessFamilyWithVersion(orgSlug,slug,version,id,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ReprocessFamilyWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof reprocessFamilyWithVersion>>>
    
    export type ReprocessFamilyWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useReprocessFamilyWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reprocessFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string;params: ReprocessFamilyWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getReprocessFamilyWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Remove a label to this specific document and its latest content object
 */
export const removeLabelFromDocumentFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    params: MaybeRef<RemoveLabelFromDocumentFamilyParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
params = unref(params);
      
      return customAxios<Label[]>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/removeLabel`, method: 'PUT',
        params: unref(params)
    },
      options);
    }
  


export const getRemoveLabelFromDocumentFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeLabelFromDocumentFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;params: RemoveLabelFromDocumentFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeLabelFromDocumentFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;params: RemoveLabelFromDocumentFamilyParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeLabelFromDocumentFamily>>, {orgSlug: string;slug: string;version: string;id: string;params: RemoveLabelFromDocumentFamilyParams}> = (props) => {
          const {orgSlug,slug,version,id,params} = props ?? {};

          return  removeLabelFromDocumentFamily(orgSlug,slug,version,id,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RemoveLabelFromDocumentFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof removeLabelFromDocumentFamily>>>
    
    export type RemoveLabelFromDocumentFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useRemoveLabelFromDocumentFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeLabelFromDocumentFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;params: RemoveLabelFromDocumentFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getRemoveLabelFromDocumentFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update the contents of an object in a family (note that this will overwrite any content and not store a transition)
 */
export const updateTagsOnContentObject = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    objectId: MaybeRef<string>,
    featureSet: MaybeRef<FeatureSet>,
    params?: MaybeRef<UpdateTagsOnContentObjectParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
objectId = unref(objectId);
featureSet = unref(featureSet);
params = unref(params);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/objects/${objectId}/_replaceTags`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: featureSet,
        params: unref(params)
    },
      options);
    }
  


export const getUpdateTagsOnContentObjectMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTagsOnContentObject>>, TError,{orgSlug: string;slug: string;version: string;id: string;objectId: string;data: FeatureSet;params?: UpdateTagsOnContentObjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateTagsOnContentObject>>, TError,{orgSlug: string;slug: string;version: string;id: string;objectId: string;data: FeatureSet;params?: UpdateTagsOnContentObjectParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateTagsOnContentObject>>, {orgSlug: string;slug: string;version: string;id: string;objectId: string;data: FeatureSet;params?: UpdateTagsOnContentObjectParams}> = (props) => {
          const {orgSlug,slug,version,id,objectId,data,params} = props ?? {};

          return  updateTagsOnContentObject(orgSlug,slug,version,id,objectId,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateTagsOnContentObjectMutationResult = NonNullable<Awaited<ReturnType<typeof updateTagsOnContentObject>>>
    export type UpdateTagsOnContentObjectMutationBody = FeatureSet
    export type UpdateTagsOnContentObjectMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateTagsOnContentObject = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateTagsOnContentObject>>, TError,{orgSlug: string;slug: string;version: string;id: string;objectId: string;data: FeatureSet;params?: UpdateTagsOnContentObjectParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateTagsOnContentObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update the document assignee
 */
export const updateAssigneesWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    user: MaybeRef<NonReadonly<User>>,
    params?: MaybeRef<UpdateAssigneesWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
user = unref(user);
params = unref(params);
      
      return customAxios<DocumentAssignment>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/assignee`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: user,
        params: unref(params)
    },
      options);
    }
  


export const getUpdateAssigneesWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssigneesWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: NonReadonly<User>;params?: UpdateAssigneesWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateAssigneesWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: NonReadonly<User>;params?: UpdateAssigneesWithVersionParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateAssigneesWithVersion>>, {orgSlug: string;slug: string;version: string;id: string;data: NonReadonly<User>;params?: UpdateAssigneesWithVersionParams}> = (props) => {
          const {orgSlug,slug,version,id,data,params} = props ?? {};

          return  updateAssigneesWithVersion(orgSlug,slug,version,id,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateAssigneesWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof updateAssigneesWithVersion>>>
    export type UpdateAssigneesWithVersionMutationBody = NonReadonly<User>
    export type UpdateAssigneesWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateAssigneesWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateAssigneesWithVersion>>, TError,{orgSlug: string;slug: string;version: string;id: string;data: NonReadonly<User>;params?: UpdateAssigneesWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateAssigneesWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Add a label to this specific document and its latest content object
 */
export const addLabelToDocumentFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    params: MaybeRef<AddLabelToDocumentFamilyParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
params = unref(params);
      
      return customAxios<Label[]>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/addLabel`, method: 'PUT',
        params: unref(params)
    },
      options);
    }
  


export const getAddLabelToDocumentFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelToDocumentFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;params: AddLabelToDocumentFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addLabelToDocumentFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;params: AddLabelToDocumentFamilyParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addLabelToDocumentFamily>>, {orgSlug: string;slug: string;version: string;id: string;params: AddLabelToDocumentFamilyParams}> = (props) => {
          const {orgSlug,slug,version,id,params} = props ?? {};

          return  addLabelToDocumentFamily(orgSlug,slug,version,id,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddLabelToDocumentFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof addLabelToDocumentFamily>>>
    
    export type AddLabelToDocumentFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useAddLabelToDocumentFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addLabelToDocumentFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;params: AddLabelToDocumentFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getAddLabelToDocumentFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Unlock the given family in the current version of the store
 */
export const unlockFamilyWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    familyId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
familyId = unref(familyId);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${familyId}/unlock`, method: 'PUT'
    },
      options);
    }
  


export const getUnlockFamilyWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unlockFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;familyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof unlockFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;familyId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unlockFamilyWithVersion>>, {orgSlug: string;slug: string;version: string;familyId: string}> = (props) => {
          const {orgSlug,slug,version,familyId} = props ?? {};

          return  unlockFamilyWithVersion(orgSlug,slug,version,familyId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UnlockFamilyWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof unlockFamilyWithVersion>>>
    
    export type UnlockFamilyWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUnlockFamilyWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unlockFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;familyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUnlockFamilyWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Lock the given family in the given version of the store
 */
export const lockFamilyWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    familyId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
familyId = unref(familyId);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${familyId}/lock`, method: 'PUT'
    },
      options);
    }
  


export const getLockFamilyWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof lockFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;familyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof lockFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;familyId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof lockFamilyWithVersion>>, {orgSlug: string;slug: string;version: string;familyId: string}> = (props) => {
          const {orgSlug,slug,version,familyId} = props ?? {};

          return  lockFamilyWithVersion(orgSlug,slug,version,familyId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type LockFamilyWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof lockFamilyWithVersion>>>
    
    export type LockFamilyWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useLockFamilyWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof lockFamilyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;familyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getLockFamilyWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Set the active assistant for the given family in the given version of the store
 */
export const setActiveAssistant = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    familyId: MaybeRef<string>,
    assistant: MaybeRef<Assistant>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
familyId = unref(familyId);
assistant = unref(assistant);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${familyId}/activeAssistant`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: assistant
    },
      options);
    }
  


export const getSetActiveAssistantMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setActiveAssistant>>, TError,{orgSlug: string;slug: string;version: string;familyId: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof setActiveAssistant>>, TError,{orgSlug: string;slug: string;version: string;familyId: string;data: Assistant}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setActiveAssistant>>, {orgSlug: string;slug: string;version: string;familyId: string;data: Assistant}> = (props) => {
          const {orgSlug,slug,version,familyId,data} = props ?? {};

          return  setActiveAssistant(orgSlug,slug,version,familyId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type SetActiveAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof setActiveAssistant>>>
    export type SetActiveAssistantMutationBody = Assistant
    export type SetActiveAssistantMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useSetActiveAssistant = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setActiveAssistant>>, TError,{orgSlug: string;slug: string;version: string;familyId: string;data: Assistant}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getSetActiveAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Clear the active assistant for the given family in the given version of the store
 */
export const clearActiveAssistant = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    familyId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
familyId = unref(familyId);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${familyId}/activeAssistant`, method: 'DELETE'
    },
      options);
    }
  


export const getClearActiveAssistantMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clearActiveAssistant>>, TError,{orgSlug: string;slug: string;version: string;familyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof clearActiveAssistant>>, TError,{orgSlug: string;slug: string;version: string;familyId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof clearActiveAssistant>>, {orgSlug: string;slug: string;version: string;familyId: string}> = (props) => {
          const {orgSlug,slug,version,familyId} = props ?? {};

          return  clearActiveAssistant(orgSlug,slug,version,familyId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ClearActiveAssistantMutationResult = NonNullable<Awaited<ReturnType<typeof clearActiveAssistant>>>
    
    export type ClearActiveAssistantMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useClearActiveAssistant = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof clearActiveAssistant>>, TError,{orgSlug: string;slug: string;version: string;familyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getClearActiveAssistantMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Bulk set the lock on a list of document families
 */
export const bulkSetLockWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    bulkLock: MaybeRef<BulkLock>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
bulkLock = unref(bulkLock);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/lock`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bulkLock
    },
      options);
    }
  


export const getBulkSetLockWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof bulkSetLockWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: BulkLock}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof bulkSetLockWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: BulkLock}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkSetLockWithVersion>>, {orgSlug: string;slug: string;version: string;data: BulkLock}> = (props) => {
          const {orgSlug,slug,version,data} = props ?? {};

          return  bulkSetLockWithVersion(orgSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type BulkSetLockWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof bulkSetLockWithVersion>>>
    export type BulkSetLockWithVersionMutationBody = BulkLock
    export type BulkSetLockWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useBulkSetLockWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof bulkSetLockWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: BulkLock}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getBulkSetLockWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Bulk copy on a list of document families
 */
export const bulkCopyWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    bulkCopy: MaybeRef<BulkCopy>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
bulkCopy = unref(bulkCopy);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/copy`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bulkCopy
    },
      options);
    }
  


export const getBulkCopyWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof bulkCopyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: BulkCopy}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof bulkCopyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: BulkCopy}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkCopyWithVersion>>, {orgSlug: string;slug: string;version: string;data: BulkCopy}> = (props) => {
          const {orgSlug,slug,version,data} = props ?? {};

          return  bulkCopyWithVersion(orgSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type BulkCopyWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof bulkCopyWithVersion>>>
    export type BulkCopyWithVersionMutationBody = BulkCopy
    export type BulkCopyWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useBulkCopyWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof bulkCopyWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: BulkCopy}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getBulkCopyWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update a specific attribute in a data object
 */
export const updateWithVersionDataObjectAttribute = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    attributeId: MaybeRef<string>,
    dataAttribute: MaybeRef<DataAttribute>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
parentId = unref(parentId);
attributeId = unref(attributeId);
dataAttribute = unref(dataAttribute);
      
      return customAxios<DataObject>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects/${parentId}/attributes/${attributeId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: dataAttribute
    },
      options);
    }
  


export const getUpdateWithVersionDataObjectAttributeMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWithVersionDataObjectAttribute>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;data: DataAttribute}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateWithVersionDataObjectAttribute>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;data: DataAttribute}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWithVersionDataObjectAttribute>>, {orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;data: DataAttribute}> = (props) => {
          const {orgSlug,slug,version,parentId,attributeId,data} = props ?? {};

          return  updateWithVersionDataObjectAttribute(orgSlug,slug,version,parentId,attributeId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateWithVersionDataObjectAttributeMutationResult = NonNullable<Awaited<ReturnType<typeof updateWithVersionDataObjectAttribute>>>
    export type UpdateWithVersionDataObjectAttributeMutationBody = DataAttribute
    export type UpdateWithVersionDataObjectAttributeMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateWithVersionDataObjectAttribute = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWithVersionDataObjectAttribute>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;data: DataAttribute}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateWithVersionDataObjectAttributeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete a specific attribute in a data object
 */
export const removeAttributeFromDataObject = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    attributeId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
parentId = unref(parentId);
attributeId = unref(attributeId);
      
      return customAxios<DataObject>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects/${parentId}/attributes/${attributeId}`, method: 'DELETE'
    },
      options);
    }
  


export const getRemoveAttributeFromDataObjectMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeAttributeFromDataObject>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof removeAttributeFromDataObject>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeAttributeFromDataObject>>, {orgSlug: string;slug: string;version: string;parentId: string;attributeId: string}> = (props) => {
          const {orgSlug,slug,version,parentId,attributeId} = props ?? {};

          return  removeAttributeFromDataObject(orgSlug,slug,version,parentId,attributeId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RemoveAttributeFromDataObjectMutationResult = NonNullable<Awaited<ReturnType<typeof removeAttributeFromDataObject>>>
    
    export type RemoveAttributeFromDataObjectMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useRemoveAttributeFromDataObject = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeAttributeFromDataObject>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getRemoveAttributeFromDataObjectMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update a specific attribute exception in a data object
 */
export const updateWithVersionDataObjectAttributeException = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    attributeId: MaybeRef<string>,
    exceptionId: MaybeRef<string>,
    dataException: MaybeRef<DataException>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
parentId = unref(parentId);
attributeId = unref(attributeId);
exceptionId = unref(exceptionId);
dataException = unref(dataException);
      
      return customAxios<DataException>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects/${parentId}/attributes/${attributeId}/exceptions/${exceptionId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: dataException
    },
      options);
    }
  


export const getUpdateWithVersionDataObjectAttributeExceptionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWithVersionDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;exceptionId: string;data: DataException}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateWithVersionDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;exceptionId: string;data: DataException}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateWithVersionDataObjectAttributeException>>, {orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;exceptionId: string;data: DataException}> = (props) => {
          const {orgSlug,slug,version,parentId,attributeId,exceptionId,data} = props ?? {};

          return  updateWithVersionDataObjectAttributeException(orgSlug,slug,version,parentId,attributeId,exceptionId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateWithVersionDataObjectAttributeExceptionMutationResult = NonNullable<Awaited<ReturnType<typeof updateWithVersionDataObjectAttributeException>>>
    export type UpdateWithVersionDataObjectAttributeExceptionMutationBody = DataException
    export type UpdateWithVersionDataObjectAttributeExceptionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateWithVersionDataObjectAttributeException = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateWithVersionDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;exceptionId: string;data: DataException}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateWithVersionDataObjectAttributeExceptionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete a specific attribute exception in a data object
 */
export const deleteWithVersionDataObjectAttributeException = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    attributeId: MaybeRef<string>,
    exceptionId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
parentId = unref(parentId);
attributeId = unref(attributeId);
exceptionId = unref(exceptionId);
      
      return customAxios<boolean>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects/${parentId}/attributes/${attributeId}/exceptions/${exceptionId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteWithVersionDataObjectAttributeExceptionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWithVersionDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;exceptionId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteWithVersionDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;exceptionId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteWithVersionDataObjectAttributeException>>, {orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;exceptionId: string}> = (props) => {
          const {orgSlug,slug,version,parentId,attributeId,exceptionId} = props ?? {};

          return  deleteWithVersionDataObjectAttributeException(orgSlug,slug,version,parentId,attributeId,exceptionId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteWithVersionDataObjectAttributeExceptionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteWithVersionDataObjectAttributeException>>>
    
    export type DeleteWithVersionDataObjectAttributeExceptionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteWithVersionDataObjectAttributeException = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteWithVersionDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;exceptionId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteWithVersionDataObjectAttributeExceptionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update a given dataObject
 */
export const updateDataObjectWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    dataObjectId: MaybeRef<string>,
    dataObject: MaybeRef<DataObject>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
dataObjectId = unref(dataObjectId);
dataObject = unref(dataObject);
      
      return customAxios<DataObject>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects/${dataObjectId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: dataObject
    },
      options);
    }
  


export const getUpdateDataObjectWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;dataObjectId: string;data: DataObject}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;dataObjectId: string;data: DataObject}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDataObjectWithVersion>>, {orgSlug: string;slug: string;version: string;dataObjectId: string;data: DataObject}> = (props) => {
          const {orgSlug,slug,version,dataObjectId,data} = props ?? {};

          return  updateDataObjectWithVersion(orgSlug,slug,version,dataObjectId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDataObjectWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof updateDataObjectWithVersion>>>
    export type UpdateDataObjectWithVersionMutationBody = DataObject
    export type UpdateDataObjectWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateDataObjectWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;dataObjectId: string;data: DataObject}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateDataObjectWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete a given data object
 */
export const deleteDataObjectWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    dataObjectId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
dataObjectId = unref(dataObjectId);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects/${dataObjectId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDataObjectWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;dataObjectId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;dataObjectId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDataObjectWithVersion>>, {orgSlug: string;slug: string;version: string;dataObjectId: string}> = (props) => {
          const {orgSlug,slug,version,dataObjectId} = props ?? {};

          return  deleteDataObjectWithVersion(orgSlug,slug,version,dataObjectId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDataObjectWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDataObjectWithVersion>>>
    
    export type DeleteDataObjectWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteDataObjectWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;dataObjectId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteDataObjectWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Reprocess content in the store, allow you to provide a list of the family ID's
 */
export const reprocess = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    reprocessRequest: MaybeRef<ReprocessRequest>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
reprocessRequest = unref(reprocessRequest);
      
      return customAxios<StreamingResponseBody>(
      {url: `/api/stores/${orgSlug}/${slug}/reprocess`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: reprocessRequest
    },
      options);
    }
  


export const getReprocessMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reprocess>>, TError,{orgSlug: string;slug: string;data: ReprocessRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof reprocess>>, TError,{orgSlug: string;slug: string;data: ReprocessRequest}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reprocess>>, {orgSlug: string;slug: string;data: ReprocessRequest}> = (props) => {
          const {orgSlug,slug,data} = props ?? {};

          return  reprocess(orgSlug,slug,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ReprocessMutationResult = NonNullable<Awaited<ReturnType<typeof reprocess>>>
    export type ReprocessMutationBody = ReprocessRequest
    export type ReprocessMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useReprocess = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reprocess>>, TError,{orgSlug: string;slug: string;data: ReprocessRequest}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getReprocessMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const getContentByFs = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetContentByFsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/fs`, method: 'GET',
        params: unref(params),
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetContentByFsQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetContentByFsParams>,) => {
    return ['api','stores',orgSlug,slug,'fs', ...(params ? [params]: [])] as const;
    }

    
export const getGetContentByFsQueryOptions = <TData = Awaited<ReturnType<typeof getContentByFs>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetContentByFsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentByFs>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetContentByFsQueryKey(orgSlug,slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentByFs>>> = ({ signal }) => getContentByFs(orgSlug,slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContentByFs>>, TError, TData> 
}

export type GetContentByFsQueryResult = NonNullable<Awaited<ReturnType<typeof getContentByFs>>>
export type GetContentByFsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetContentByFs = <TData = Awaited<ReturnType<typeof getContentByFs>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetContentByFsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentByFs>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContentByFsQueryOptions(orgSlug,slug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Rename the document family
 */
export const renameFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<RenameFamilyParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/fs`, method: 'PUT',
        params: unref(params)
    },
      options);
    }
  


export const getRenameFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameFamily>>, TError,{orgSlug: string;slug: string;params?: RenameFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof renameFamily>>, TError,{orgSlug: string;slug: string;params?: RenameFamilyParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof renameFamily>>, {orgSlug: string;slug: string;params?: RenameFamilyParams}> = (props) => {
          const {orgSlug,slug,params} = props ?? {};

          return  renameFamily(orgSlug,slug,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type RenameFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof renameFamily>>>
    
    export type RenameFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useRenameFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof renameFamily>>, TError,{orgSlug: string;slug: string;params?: RenameFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getRenameFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a specific document family in the current version of the store
 */
export const getFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
id = unref(id);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/families/${id}`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFamilyQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    id: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,'families',id] as const;
    }

    
export const getGetFamilyQueryOptions = <TData = Awaited<ReturnType<typeof getFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFamilyQueryKey(orgSlug,slug,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFamily>>> = ({ signal }) => getFamily(orgSlug,slug,id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFamily>>, TError, TData> 
}

export type GetFamilyQueryResult = NonNullable<Awaited<ReturnType<typeof getFamily>>>
export type GetFamilyQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetFamily = <TData = Awaited<ReturnType<typeof getFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFamilyQueryOptions(orgSlug,slug,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get a specific document family in the current version of the store
 */
export const updateFamilyPath = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    id: MaybeRef<string>,
    familyRename: MaybeRef<FamilyRename>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
id = unref(id);
familyRename = unref(familyRename);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/families/${id}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: familyRename
    },
      options);
    }
  


export const getUpdateFamilyPathMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFamilyPath>>, TError,{orgSlug: string;slug: string;id: string;data: FamilyRename}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateFamilyPath>>, TError,{orgSlug: string;slug: string;id: string;data: FamilyRename}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateFamilyPath>>, {orgSlug: string;slug: string;id: string;data: FamilyRename}> = (props) => {
          const {orgSlug,slug,id,data} = props ?? {};

          return  updateFamilyPath(orgSlug,slug,id,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateFamilyPathMutationResult = NonNullable<Awaited<ReturnType<typeof updateFamilyPath>>>
    export type UpdateFamilyPathMutationBody = FamilyRename
    export type UpdateFamilyPathMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateFamilyPath = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateFamilyPath>>, TError,{orgSlug: string;slug: string;id: string;data: FamilyRename}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateFamilyPathMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete a specific document family in the current version of a store
 */
export const deleteFamilyByID = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
id = unref(id);
      
      return customAxios<boolean>(
      {url: `/api/stores/${orgSlug}/${slug}/families/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteFamilyByIDMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyByID>>, TError,{orgSlug: string;slug: string;id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyByID>>, TError,{orgSlug: string;slug: string;id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFamilyByID>>, {orgSlug: string;slug: string;id: string}> = (props) => {
          const {orgSlug,slug,id} = props ?? {};

          return  deleteFamilyByID(orgSlug,slug,id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteFamilyByIDMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFamilyByID>>>
    
    export type DeleteFamilyByIDMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteFamilyByID = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyByID>>, TError,{orgSlug: string;slug: string;id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteFamilyByIDMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Reprocess content in this family for a specific set of assistants in the current version of a store
 */
export const reprocessDocumentFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    id: MaybeRef<string>,
    params: MaybeRef<ReprocessDocumentFamilyParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
id = unref(id);
params = unref(params);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/families/${id}/reprocess`, method: 'PUT',
        params: unref(params)
    },
      options);
    }
  


export const getReprocessDocumentFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reprocessDocumentFamily>>, TError,{orgSlug: string;slug: string;id: string;params: ReprocessDocumentFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof reprocessDocumentFamily>>, TError,{orgSlug: string;slug: string;id: string;params: ReprocessDocumentFamilyParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reprocessDocumentFamily>>, {orgSlug: string;slug: string;id: string;params: ReprocessDocumentFamilyParams}> = (props) => {
          const {orgSlug,slug,id,params} = props ?? {};

          return  reprocessDocumentFamily(orgSlug,slug,id,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ReprocessDocumentFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof reprocessDocumentFamily>>>
    
    export type ReprocessDocumentFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useReprocessDocumentFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reprocessDocumentFamily>>, TError,{orgSlug: string;slug: string;id: string;params: ReprocessDocumentFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getReprocessDocumentFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Unlock the given family in the current version of the store
 */
export const unlockFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    familyId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
familyId = unref(familyId);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/families/${familyId}/unlock`, method: 'PUT'
    },
      options);
    }
  


export const getUnlockFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unlockFamily>>, TError,{orgSlug: string;slug: string;familyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof unlockFamily>>, TError,{orgSlug: string;slug: string;familyId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unlockFamily>>, {orgSlug: string;slug: string;familyId: string}> = (props) => {
          const {orgSlug,slug,familyId} = props ?? {};

          return  unlockFamily(orgSlug,slug,familyId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UnlockFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof unlockFamily>>>
    
    export type UnlockFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUnlockFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unlockFamily>>, TError,{orgSlug: string;slug: string;familyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUnlockFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Lock the given family in the current version of the store
 */
export const lockFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    familyId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
familyId = unref(familyId);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/families/${familyId}/lock`, method: 'PUT'
    },
      options);
    }
  


export const getLockFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof lockFamily>>, TError,{orgSlug: string;slug: string;familyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof lockFamily>>, TError,{orgSlug: string;slug: string;familyId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof lockFamily>>, {orgSlug: string;slug: string;familyId: string}> = (props) => {
          const {orgSlug,slug,familyId} = props ?? {};

          return  lockFamily(orgSlug,slug,familyId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type LockFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof lockFamily>>>
    
    export type LockFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useLockFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof lockFamily>>, TError,{orgSlug: string;slug: string;familyId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getLockFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Bulk set the lock on a list of document families
 */
export const bulkSetLock = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    bulkLock: MaybeRef<BulkLock>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
bulkLock = unref(bulkLock);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/families/lock`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bulkLock
    },
      options);
    }
  


export const getBulkSetLockMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof bulkSetLock>>, TError,{orgSlug: string;slug: string;data: BulkLock}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof bulkSetLock>>, TError,{orgSlug: string;slug: string;data: BulkLock}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkSetLock>>, {orgSlug: string;slug: string;data: BulkLock}> = (props) => {
          const {orgSlug,slug,data} = props ?? {};

          return  bulkSetLock(orgSlug,slug,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type BulkSetLockMutationResult = NonNullable<Awaited<ReturnType<typeof bulkSetLock>>>
    export type BulkSetLockMutationBody = BulkLock
    export type BulkSetLockMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useBulkSetLock = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof bulkSetLock>>, TError,{orgSlug: string;slug: string;data: BulkLock}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getBulkSetLockMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Bulk copy on a list of document families
 */
export const bulkCopy = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    bulkCopy: MaybeRef<BulkCopy>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
bulkCopy = unref(bulkCopy);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/families/copy`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: bulkCopy
    },
      options);
    }
  


export const getBulkCopyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof bulkCopy>>, TError,{orgSlug: string;slug: string;data: BulkCopy}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof bulkCopy>>, TError,{orgSlug: string;slug: string;data: BulkCopy}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof bulkCopy>>, {orgSlug: string;slug: string;data: BulkCopy}> = (props) => {
          const {orgSlug,slug,data} = props ?? {};

          return  bulkCopy(orgSlug,slug,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type BulkCopyMutationResult = NonNullable<Awaited<ReturnType<typeof bulkCopy>>>
    export type BulkCopyMutationBody = BulkCopy
    export type BulkCopyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useBulkCopy = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof bulkCopy>>, TError,{orgSlug: string;slug: string;data: BulkCopy}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getBulkCopyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update a specific attribute in a data object
 */
export const updateDataObjectAttribute = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    parentId: MaybeRef<string>,
    attributeId: MaybeRef<string>,
    dataAttribute: MaybeRef<DataAttribute>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
parentId = unref(parentId);
attributeId = unref(attributeId);
dataAttribute = unref(dataAttribute);
      
      return customAxios<DataObject>(
      {url: `/api/stores/${orgSlug}/${slug}/dataObjects/${parentId}/attributes/${attributeId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: dataAttribute
    },
      options);
    }
  


export const getUpdateDataObjectAttributeMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectAttribute>>, TError,{orgSlug: string;slug: string;parentId: string;attributeId: string;data: DataAttribute}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectAttribute>>, TError,{orgSlug: string;slug: string;parentId: string;attributeId: string;data: DataAttribute}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDataObjectAttribute>>, {orgSlug: string;slug: string;parentId: string;attributeId: string;data: DataAttribute}> = (props) => {
          const {orgSlug,slug,parentId,attributeId,data} = props ?? {};

          return  updateDataObjectAttribute(orgSlug,slug,parentId,attributeId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDataObjectAttributeMutationResult = NonNullable<Awaited<ReturnType<typeof updateDataObjectAttribute>>>
    export type UpdateDataObjectAttributeMutationBody = DataAttribute
    export type UpdateDataObjectAttributeMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateDataObjectAttribute = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectAttribute>>, TError,{orgSlug: string;slug: string;parentId: string;attributeId: string;data: DataAttribute}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateDataObjectAttributeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update a specific attribute exception in a data object
 */
export const updateDataObjectAttributeException = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    parentId: MaybeRef<string>,
    attributeId: MaybeRef<string>,
    exceptionId: MaybeRef<string>,
    dataException: MaybeRef<DataException>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
parentId = unref(parentId);
attributeId = unref(attributeId);
exceptionId = unref(exceptionId);
dataException = unref(dataException);
      
      return customAxios<DataException>(
      {url: `/api/stores/${orgSlug}/${slug}/dataObjects/${parentId}/attributes/${attributeId}/exceptions/${exceptionId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: dataException
    },
      options);
    }
  


export const getUpdateDataObjectAttributeExceptionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;parentId: string;attributeId: string;exceptionId: string;data: DataException}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;parentId: string;attributeId: string;exceptionId: string;data: DataException}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDataObjectAttributeException>>, {orgSlug: string;slug: string;parentId: string;attributeId: string;exceptionId: string;data: DataException}> = (props) => {
          const {orgSlug,slug,parentId,attributeId,exceptionId,data} = props ?? {};

          return  updateDataObjectAttributeException(orgSlug,slug,parentId,attributeId,exceptionId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDataObjectAttributeExceptionMutationResult = NonNullable<Awaited<ReturnType<typeof updateDataObjectAttributeException>>>
    export type UpdateDataObjectAttributeExceptionMutationBody = DataException
    export type UpdateDataObjectAttributeExceptionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateDataObjectAttributeException = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;parentId: string;attributeId: string;exceptionId: string;data: DataException}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateDataObjectAttributeExceptionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update a given dataObject in the latest version of the store
 */
export const updateDataObjectWithoutVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    dataObjectId: MaybeRef<string>,
    dataObject: MaybeRef<DataObject>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
dataObjectId = unref(dataObjectId);
dataObject = unref(dataObject);
      
      return customAxios<DataObject>(
      {url: `/api/stores/${orgSlug}/${slug}/dataObjects/${dataObjectId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: dataObject
    },
      options);
    }
  


export const getUpdateDataObjectWithoutVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectWithoutVersion>>, TError,{orgSlug: string;slug: string;dataObjectId: string;data: DataObject}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectWithoutVersion>>, TError,{orgSlug: string;slug: string;dataObjectId: string;data: DataObject}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDataObjectWithoutVersion>>, {orgSlug: string;slug: string;dataObjectId: string;data: DataObject}> = (props) => {
          const {orgSlug,slug,dataObjectId,data} = props ?? {};

          return  updateDataObjectWithoutVersion(orgSlug,slug,dataObjectId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDataObjectWithoutVersionMutationResult = NonNullable<Awaited<ReturnType<typeof updateDataObjectWithoutVersion>>>
    export type UpdateDataObjectWithoutVersionMutationBody = DataObject
    export type UpdateDataObjectWithoutVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateDataObjectWithoutVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDataObjectWithoutVersion>>, TError,{orgSlug: string;slug: string;dataObjectId: string;data: DataObject}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateDataObjectWithoutVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete a given data object
 */
export const deleteDataObjectWithoutVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    dataObjectId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
dataObjectId = unref(dataObjectId);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/dataObjects/${dataObjectId}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDataObjectWithoutVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjectWithoutVersion>>, TError,{orgSlug: string;slug: string;dataObjectId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjectWithoutVersion>>, TError,{orgSlug: string;slug: string;dataObjectId: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDataObjectWithoutVersion>>, {orgSlug: string;slug: string;dataObjectId: string}> = (props) => {
          const {orgSlug,slug,dataObjectId} = props ?? {};

          return  deleteDataObjectWithoutVersion(orgSlug,slug,dataObjectId,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDataObjectWithoutVersionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDataObjectWithoutVersion>>>
    
    export type DeleteDataObjectWithoutVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteDataObjectWithoutVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjectWithoutVersion>>, TError,{orgSlug: string;slug: string;dataObjectId: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteDataObjectWithoutVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of the resources for an organization
 */
export const listStoresForOrganization = (
    organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListStoresForOrganizationParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
params = unref(params);
      
      return customAxios<PageStore>(
      {url: `/api/stores/${organizationSlug}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListStoresForOrganizationQueryKey = (organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListStoresForOrganizationParams>,) => {
    return ['api','stores',organizationSlug, ...(params ? [params]: [])] as const;
    }

    
export const getListStoresForOrganizationQueryOptions = <TData = Awaited<ReturnType<typeof listStoresForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListStoresForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listStoresForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListStoresForOrganizationQueryKey(organizationSlug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listStoresForOrganization>>> = ({ signal }) => listStoresForOrganization(organizationSlug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listStoresForOrganization>>, TError, TData> 
}

export type ListStoresForOrganizationQueryResult = NonNullable<Awaited<ReturnType<typeof listStoresForOrganization>>>
export type ListStoresForOrganizationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListStoresForOrganization = <TData = Awaited<ReturnType<typeof listStoresForOrganization>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    params?: MaybeRef<ListStoresForOrganizationParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listStoresForOrganization>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListStoresForOrganizationQueryOptions(organizationSlug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new instance of the object in the organization using the template with given ref
 */
export const createStore = (
    organizationSlug: MaybeRef<string>,
    store: MaybeRef<Store>,
    params: MaybeRef<CreateStoreParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      organizationSlug = unref(organizationSlug);
store = unref(store);
params = unref(params);
      
      return customAxios<Store>(
      {url: `/api/stores/${organizationSlug}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: store,
        params: unref(params)
    },
      options);
    }
  


export const getCreateStoreMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStore>>, TError,{organizationSlug: string;data: Store;params: CreateStoreParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createStore>>, TError,{organizationSlug: string;data: Store;params: CreateStoreParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createStore>>, {organizationSlug: string;data: Store;params: CreateStoreParams}> = (props) => {
          const {organizationSlug,data,params} = props ?? {};

          return  createStore(organizationSlug,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateStoreMutationResult = NonNullable<Awaited<ReturnType<typeof createStore>>>
    export type CreateStoreMutationBody = Store
    export type CreateStoreMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateStore = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStore>>, TError,{organizationSlug: string;data: Store;params: CreateStoreParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateStoreMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create a new training in the current version of the model store
 */
export const listTrainings = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<ListTrainingsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<PageModelTraining>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/trainings`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListTrainingsQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<ListTrainingsParams>,) => {
    return ['api','stores',orgSlug,slug,version,'trainings', ...(params ? [params]: [])] as const;
    }

    
export const getListTrainingsQueryOptions = <TData = Awaited<ReturnType<typeof listTrainings>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<ListTrainingsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTrainings>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListTrainingsQueryKey(orgSlug,slug,version,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listTrainings>>> = ({ signal }) => listTrainings(orgSlug,slug,version,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listTrainings>>, TError, TData> 
}

export type ListTrainingsQueryResult = NonNullable<Awaited<ReturnType<typeof listTrainings>>>
export type ListTrainingsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListTrainings = <TData = Awaited<ReturnType<typeof listTrainings>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<ListTrainingsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listTrainings>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListTrainingsQueryOptions(orgSlug,slug,version,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Create a new training in the current version of the model store
 */
export const createNewTraining = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    modelTraining: MaybeRef<ModelTraining>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
modelTraining = unref(modelTraining);
      
      return customAxios<ModelTraining>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/trainings`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: modelTraining
    },
      options);
    }
  


export const getCreateNewTrainingMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNewTraining>>, TError,{orgSlug: string;slug: string;version: string;data: ModelTraining}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createNewTraining>>, TError,{orgSlug: string;slug: string;version: string;data: ModelTraining}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNewTraining>>, {orgSlug: string;slug: string;version: string;data: ModelTraining}> = (props) => {
          const {orgSlug,slug,version,data} = props ?? {};

          return  createNewTraining(orgSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateNewTrainingMutationResult = NonNullable<Awaited<ReturnType<typeof createNewTraining>>>
    export type CreateNewTrainingMutationBody = ModelTraining
    export type CreateNewTrainingMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateNewTraining = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNewTraining>>, TError,{orgSlug: string;slug: string;version: string;data: ModelTraining}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateNewTrainingMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Download model training materials
 */
export const downloadTrainingContent = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    trainingId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
trainingId = unref(trainingId);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/trainings/${trainingId}/content`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getDownloadTrainingContentQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    trainingId: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'trainings',trainingId,'content'] as const;
    }

    
export const getDownloadTrainingContentQueryOptions = <TData = Awaited<ReturnType<typeof downloadTrainingContent>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    trainingId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadTrainingContent>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getDownloadTrainingContentQueryKey(orgSlug,slug,version,trainingId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadTrainingContent>>> = ({ signal }) => downloadTrainingContent(orgSlug,slug,version,trainingId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(trainingId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadTrainingContent>>, TError, TData> 
}

export type DownloadTrainingContentQueryResult = NonNullable<Awaited<ReturnType<typeof downloadTrainingContent>>>
export type DownloadTrainingContentQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useDownloadTrainingContent = <TData = Awaited<ReturnType<typeof downloadTrainingContent>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    trainingId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadTrainingContent>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadTrainingContentQueryOptions(orgSlug,slug,version,trainingId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Upload model training materials
 */
export const uploadingTrainingContent = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    trainingId: MaybeRef<string>,
    uploadingTrainingContentBody: MaybeRef<UploadingTrainingContentBody>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
trainingId = unref(trainingId);
uploadingTrainingContentBody = unref(uploadingTrainingContentBody);
      
      return customAxios<Store>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/trainings/${trainingId}/content`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: uploadingTrainingContentBody
    },
      options);
    }
  


export const getUploadingTrainingContentMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadingTrainingContent>>, TError,{orgSlug: string;slug: string;version: string;trainingId: string;data: UploadingTrainingContentBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadingTrainingContent>>, TError,{orgSlug: string;slug: string;version: string;trainingId: string;data: UploadingTrainingContentBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadingTrainingContent>>, {orgSlug: string;slug: string;version: string;trainingId: string;data: UploadingTrainingContentBody}> = (props) => {
          const {orgSlug,slug,version,trainingId,data} = props ?? {};

          return  uploadingTrainingContent(orgSlug,slug,version,trainingId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadingTrainingContentMutationResult = NonNullable<Awaited<ReturnType<typeof uploadingTrainingContent>>>
    export type UploadingTrainingContentMutationBody = UploadingTrainingContentBody
    export type UploadingTrainingContentMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUploadingTrainingContent = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadingTrainingContent>>, TError,{orgSlug: string;slug: string;version: string;trainingId: string;data: UploadingTrainingContentBody}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUploadingTrainingContentMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the saved view presets
 */
export const getStoreViewPresets = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<ViewPreset[]>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/presets`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStoreViewPresetsQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'presets'] as const;
    }

    
export const getGetStoreViewPresetsQueryOptions = <TData = Awaited<ReturnType<typeof getStoreViewPresets>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStoreViewPresets>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetStoreViewPresetsQueryKey(orgSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStoreViewPresets>>> = ({ signal }) => getStoreViewPresets(orgSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStoreViewPresets>>, TError, TData> 
}

export type GetStoreViewPresetsQueryResult = NonNullable<Awaited<ReturnType<typeof getStoreViewPresets>>>
export type GetStoreViewPresetsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetStoreViewPresets = <TData = Awaited<ReturnType<typeof getStoreViewPresets>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStoreViewPresets>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStoreViewPresetsQueryOptions(orgSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the saved view presets
 */
export const createStoreViewPreset = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    viewPreset: MaybeRef<ViewPreset>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
viewPreset = unref(viewPreset);
      
      return customAxios<ViewPreset>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/presets`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: viewPreset
    },
      options);
    }
  


export const getCreateStoreViewPresetMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStoreViewPreset>>, TError,{orgSlug: string;slug: string;version: string;data: ViewPreset}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createStoreViewPreset>>, TError,{orgSlug: string;slug: string;version: string;data: ViewPreset}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createStoreViewPreset>>, {orgSlug: string;slug: string;version: string;data: ViewPreset}> = (props) => {
          const {orgSlug,slug,version,data} = props ?? {};

          return  createStoreViewPreset(orgSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateStoreViewPresetMutationResult = NonNullable<Awaited<ReturnType<typeof createStoreViewPreset>>>
    export type CreateStoreViewPresetMutationBody = ViewPreset
    export type CreateStoreViewPresetMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateStoreViewPreset = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createStoreViewPreset>>, TError,{orgSlug: string;slug: string;version: string;data: ViewPreset}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateStoreViewPresetMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Download the model implementation
 */
export const downloadModelImplementation = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/implementation`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getDownloadModelImplementationQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'implementation'] as const;
    }

    
export const getDownloadModelImplementationQueryOptions = <TData = Awaited<ReturnType<typeof downloadModelImplementation>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadModelImplementation>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getDownloadModelImplementationQueryKey(orgSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof downloadModelImplementation>>> = ({ signal }) => downloadModelImplementation(orgSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof downloadModelImplementation>>, TError, TData> 
}

export type DownloadModelImplementationQueryResult = NonNullable<Awaited<ReturnType<typeof downloadModelImplementation>>>
export type DownloadModelImplementationQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useDownloadModelImplementation = <TData = Awaited<ReturnType<typeof downloadModelImplementation>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof downloadModelImplementation>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDownloadModelImplementationQueryOptions(orgSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Upload the model implementation
 */
export const uploadModelImplementation = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    uploadModelImplementationBody: MaybeRef<UploadModelImplementationBody>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
uploadModelImplementationBody = unref(uploadModelImplementationBody);
      
      return customAxios<Store>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/implementation`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: uploadModelImplementationBody
    },
      options);
    }
  


export const getUploadModelImplementationMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadModelImplementation>>, TError,{orgSlug: string;slug: string;version: string;data: UploadModelImplementationBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadModelImplementation>>, TError,{orgSlug: string;slug: string;version: string;data: UploadModelImplementationBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadModelImplementation>>, {orgSlug: string;slug: string;version: string;data: UploadModelImplementationBody}> = (props) => {
          const {orgSlug,slug,version,data} = props ?? {};

          return  uploadModelImplementation(orgSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadModelImplementationMutationResult = NonNullable<Awaited<ReturnType<typeof uploadModelImplementation>>>
    export type UploadModelImplementationMutationBody = UploadModelImplementationBody
    export type UploadModelImplementationMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUploadModelImplementation = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadModelImplementation>>, TError,{orgSlug: string;slug: string;version: string;data: UploadModelImplementationBody}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUploadModelImplementationMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the document family for a path in the specific version of the store
 */
export const getFamilyByPathWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetFamilyByPathWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/fs/**`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetFamilyByPathWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetFamilyByPathWithVersionParams>,) => {
    return ['api','stores',orgSlug,slug,version,'fs','**', ...(params ? [params]: [])] as const;
    }

    
export const getGetFamilyByPathWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof getFamilyByPathWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetFamilyByPathWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyByPathWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFamilyByPathWithVersionQueryKey(orgSlug,slug,version,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFamilyByPathWithVersion>>> = ({ signal }) => getFamilyByPathWithVersion(orgSlug,slug,version,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFamilyByPathWithVersion>>, TError, TData> 
}

export type GetFamilyByPathWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getFamilyByPathWithVersion>>>
export type GetFamilyByPathWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetFamilyByPathWithVersion = <TData = Awaited<ReturnType<typeof getFamilyByPathWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetFamilyByPathWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyByPathWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFamilyByPathWithVersionQueryOptions(orgSlug,slug,version,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



export const uploadToPathWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    uploadToPathWithVersionBody: MaybeRef<UploadToPathWithVersionBody>,
    params: MaybeRef<UploadToPathWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
uploadToPathWithVersionBody = unref(uploadToPathWithVersionBody);
params = unref(params);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/fs/**`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: uploadToPathWithVersionBody,
        params: unref(params)
    },
      options);
    }
  


export const getUploadToPathWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadToPathWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: UploadToPathWithVersionBody;params: UploadToPathWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadToPathWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: UploadToPathWithVersionBody;params: UploadToPathWithVersionParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadToPathWithVersion>>, {orgSlug: string;slug: string;version: string;data: UploadToPathWithVersionBody;params: UploadToPathWithVersionParams}> = (props) => {
          const {orgSlug,slug,version,data,params} = props ?? {};

          return  uploadToPathWithVersion(orgSlug,slug,version,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadToPathWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof uploadToPathWithVersion>>>
    export type UploadToPathWithVersionMutationBody = UploadToPathWithVersionBody
    export type UploadToPathWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUploadToPathWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadToPathWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: UploadToPathWithVersionBody;params: UploadToPathWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUploadToPathWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete document family by path in a specific version of the store
 */
export const deleteFamilyByPathWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<DeleteFamilyByPathWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<boolean>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/fs/**`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteFamilyByPathWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyByPathWithVersion>>, TError,{orgSlug: string;slug: string;version: string;params?: DeleteFamilyByPathWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyByPathWithVersion>>, TError,{orgSlug: string;slug: string;version: string;params?: DeleteFamilyByPathWithVersionParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFamilyByPathWithVersion>>, {orgSlug: string;slug: string;version: string;params?: DeleteFamilyByPathWithVersionParams}> = (props) => {
          const {orgSlug,slug,version,params} = props ?? {};

          return  deleteFamilyByPathWithVersion(orgSlug,slug,version,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteFamilyByPathWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFamilyByPathWithVersion>>>
    
    export type DeleteFamilyByPathWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteFamilyByPathWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyByPathWithVersion>>, TError,{orgSlug: string;slug: string;version: string;params?: DeleteFamilyByPathWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteFamilyByPathWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * List (with pagination) the enriched (include transition events, and extracted data counts) document families in a specific version of the store
 */
export const listFamiliesWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<ListFamiliesWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<PageDocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListFamiliesWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<ListFamiliesWithVersionParams>,) => {
    return ['api','stores',orgSlug,slug,version,'families', ...(params ? [params]: [])] as const;
    }

    
export const getListFamiliesWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof listFamiliesWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<ListFamiliesWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFamiliesWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListFamiliesWithVersionQueryKey(orgSlug,slug,version,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFamiliesWithVersion>>> = ({ signal }) => listFamiliesWithVersion(orgSlug,slug,version,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listFamiliesWithVersion>>, TError, TData> 
}

export type ListFamiliesWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof listFamiliesWithVersion>>>
export type ListFamiliesWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListFamiliesWithVersion = <TData = Awaited<ReturnType<typeof listFamiliesWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<ListFamiliesWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFamiliesWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFamiliesWithVersionQueryOptions(orgSlug,slug,version,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



export const importFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    importFamilyBody: MaybeRef<ImportFamilyBody>,
    params?: MaybeRef<ImportFamilyParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
importFamilyBody = unref(importFamilyBody);
params = unref(params);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: importFamilyBody,
        params: unref(params)
    },
      options);
    }
  


export const getImportFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importFamily>>, TError,{orgSlug: string;slug: string;version: string;data: ImportFamilyBody;params?: ImportFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof importFamily>>, TError,{orgSlug: string;slug: string;version: string;data: ImportFamilyBody;params?: ImportFamilyParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof importFamily>>, {orgSlug: string;slug: string;version: string;data: ImportFamilyBody;params?: ImportFamilyParams}> = (props) => {
          const {orgSlug,slug,version,data,params} = props ?? {};

          return  importFamily(orgSlug,slug,version,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ImportFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof importFamily>>>
    export type ImportFamilyMutationBody = ImportFamilyBody
    export type ImportFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useImportFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importFamily>>, TError,{orgSlug: string;slug: string;version: string;data: ImportFamilyBody;params?: ImportFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getImportFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete all document families in store version
 */
export const deleteContentObjectsWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    bulkDelete: MaybeRef<BulkDelete>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
bulkDelete = unref(bulkDelete);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: bulkDelete
    },
      options);
    }
  


export const getDeleteContentObjectsWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteContentObjectsWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: BulkDelete}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteContentObjectsWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: BulkDelete}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContentObjectsWithVersion>>, {orgSlug: string;slug: string;version: string;data: BulkDelete}> = (props) => {
          const {orgSlug,slug,version,data} = props ?? {};

          return  deleteContentObjectsWithVersion(orgSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteContentObjectsWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContentObjectsWithVersion>>>
    export type DeleteContentObjectsWithVersionMutationBody = BulkDelete
    export type DeleteContentObjectsWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteContentObjectsWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteContentObjectsWithVersion>>, TError,{orgSlug: string;slug: string;version: string;data: BulkDelete}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteContentObjectsWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update the contents of an object in a family (note that this will overwrite any content and not store a transition)
 */
export const updateContentObjectInFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    objectId: MaybeRef<string>,
    updateContentObjectInFamilyBody: MaybeRef<UpdateContentObjectInFamilyBody>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
objectId = unref(objectId);
updateContentObjectInFamilyBody = unref(updateContentObjectInFamilyBody);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/objects/${objectId}/content`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: updateContentObjectInFamilyBody
    },
      options);
    }
  


export const getUpdateContentObjectInFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateContentObjectInFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;objectId: string;data: UpdateContentObjectInFamilyBody}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof updateContentObjectInFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;objectId: string;data: UpdateContentObjectInFamilyBody}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateContentObjectInFamily>>, {orgSlug: string;slug: string;version: string;id: string;objectId: string;data: UpdateContentObjectInFamilyBody}> = (props) => {
          const {orgSlug,slug,version,id,objectId,data} = props ?? {};

          return  updateContentObjectInFamily(orgSlug,slug,version,id,objectId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateContentObjectInFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof updateContentObjectInFamily>>>
    export type UpdateContentObjectInFamilyMutationBody = UpdateContentObjectInFamilyBody
    export type UpdateContentObjectInFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUpdateContentObjectInFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateContentObjectInFamily>>, TError,{orgSlug: string;slug: string;version: string;id: string;objectId: string;data: UpdateContentObjectInFamilyBody}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUpdateContentObjectInFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Add a new content object to the family
 */
export const createContentObjectWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    createContentObjectWithVersionBody: MaybeRef<CreateContentObjectWithVersionBody>,
    params: MaybeRef<CreateContentObjectWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
documentFamilyId = unref(documentFamilyId);
createContentObjectWithVersionBody = unref(createContentObjectWithVersionBody);
params = unref(params);
      
      return customAxios<ContentObject>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${documentFamilyId}/objects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createContentObjectWithVersionBody,
        params: unref(params)
    },
      options);
    }
  


export const getCreateContentObjectWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContentObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;documentFamilyId: string;data: CreateContentObjectWithVersionBody;params: CreateContentObjectWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createContentObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;documentFamilyId: string;data: CreateContentObjectWithVersionBody;params: CreateContentObjectWithVersionParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createContentObjectWithVersion>>, {orgSlug: string;slug: string;version: string;documentFamilyId: string;data: CreateContentObjectWithVersionBody;params: CreateContentObjectWithVersionParams}> = (props) => {
          const {orgSlug,slug,version,documentFamilyId,data,params} = props ?? {};

          return  createContentObjectWithVersion(orgSlug,slug,version,documentFamilyId,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateContentObjectWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof createContentObjectWithVersion>>>
    export type CreateContentObjectWithVersionMutationBody = CreateContentObjectWithVersionBody
    export type CreateContentObjectWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateContentObjectWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createContentObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;documentFamilyId: string;data: CreateContentObjectWithVersionBody;params: CreateContentObjectWithVersionParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateContentObjectWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the query results for the data objects in a specific format
 */
export const getDataObjectsWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectsWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<GetDataObjectsWithVersion200>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetDataObjectsWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectsWithVersionParams>,) => {
    return ['api','stores',orgSlug,slug,version,'dataObjects', ...(params ? [params]: [])] as const;
    }

    
export const getGetDataObjectsWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof getDataObjectsWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectsWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataObjectsWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDataObjectsWithVersionQueryKey(orgSlug,slug,version,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataObjectsWithVersion>>> = ({ signal }) => getDataObjectsWithVersion(orgSlug,slug,version,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataObjectsWithVersion>>, TError, TData> 
}

export type GetDataObjectsWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getDataObjectsWithVersion>>>
export type GetDataObjectsWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetDataObjectsWithVersion = <TData = Awaited<ReturnType<typeof getDataObjectsWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectsWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataObjectsWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDataObjectsWithVersionQueryOptions(orgSlug,slug,version,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Add a list of dataObjects to a specific version of the store (import mode)
 */
export const createDataObjectsForVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    dataObject: MaybeRef<DataObject[]>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
dataObject = unref(dataObject);
      
      return customAxios<DataObject[]>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dataObject
    },
      options);
    }
  


export const getCreateDataObjectsForVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataObjectsForVersion>>, TError,{orgSlug: string;slug: string;version: string;data: DataObject[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createDataObjectsForVersion>>, TError,{orgSlug: string;slug: string;version: string;data: DataObject[]}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDataObjectsForVersion>>, {orgSlug: string;slug: string;version: string;data: DataObject[]}> = (props) => {
          const {orgSlug,slug,version,data} = props ?? {};

          return  createDataObjectsForVersion(orgSlug,slug,version,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDataObjectsForVersionMutationResult = NonNullable<Awaited<ReturnType<typeof createDataObjectsForVersion>>>
    export type CreateDataObjectsForVersionMutationBody = DataObject[]
    export type CreateDataObjectsForVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateDataObjectsForVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataObjectsForVersion>>, TError,{orgSlug: string;slug: string;version: string;data: DataObject[]}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateDataObjectsForVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the dataObjects from a specific version of the store
 */
export const deleteDataObjectsWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDataObjectsWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjectsWithVersion>>, TError,{orgSlug: string;slug: string;version: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjectsWithVersion>>, TError,{orgSlug: string;slug: string;version: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDataObjectsWithVersion>>, {orgSlug: string;slug: string;version: string}> = (props) => {
          const {orgSlug,slug,version} = props ?? {};

          return  deleteDataObjectsWithVersion(orgSlug,slug,version,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDataObjectsWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDataObjectsWithVersion>>>
    
    export type DeleteDataObjectsWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteDataObjectsWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjectsWithVersion>>, TError,{orgSlug: string;slug: string;version: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteDataObjectsWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create a new data object exception
 */
export const createWithVersionDataObjectException = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    dataException: MaybeRef<DataException>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
parentId = unref(parentId);
dataException = unref(dataException);
      
      return customAxios<DataException>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects/${parentId}/exceptions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dataException
    },
      options);
    }
  


export const getCreateWithVersionDataObjectExceptionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWithVersionDataObjectException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;data: DataException}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createWithVersionDataObjectException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;data: DataException}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createWithVersionDataObjectException>>, {orgSlug: string;slug: string;version: string;parentId: string;data: DataException}> = (props) => {
          const {orgSlug,slug,version,parentId,data} = props ?? {};

          return  createWithVersionDataObjectException(orgSlug,slug,version,parentId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateWithVersionDataObjectExceptionMutationResult = NonNullable<Awaited<ReturnType<typeof createWithVersionDataObjectException>>>
    export type CreateWithVersionDataObjectExceptionMutationBody = DataException
    export type CreateWithVersionDataObjectExceptionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateWithVersionDataObjectException = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWithVersionDataObjectException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;data: DataException}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateWithVersionDataObjectExceptionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create a specific attribute in a data object
 */
export const createDataObjectAttribute = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    dataAttribute: MaybeRef<DataAttribute>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
parentId = unref(parentId);
dataAttribute = unref(dataAttribute);
      
      return customAxios<DataAttribute>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects/${parentId}/attributes`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dataAttribute
    },
      options);
    }
  


export const getCreateDataObjectAttributeMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataObjectAttribute>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;data: DataAttribute}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createDataObjectAttribute>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;data: DataAttribute}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDataObjectAttribute>>, {orgSlug: string;slug: string;version: string;parentId: string;data: DataAttribute}> = (props) => {
          const {orgSlug,slug,version,parentId,data} = props ?? {};

          return  createDataObjectAttribute(orgSlug,slug,version,parentId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDataObjectAttributeMutationResult = NonNullable<Awaited<ReturnType<typeof createDataObjectAttribute>>>
    export type CreateDataObjectAttributeMutationBody = DataAttribute
    export type CreateDataObjectAttributeMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateDataObjectAttribute = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataObjectAttribute>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;data: DataAttribute}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateDataObjectAttributeMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Create a new attribute exception in a data object
 */
export const createWithVersionDataObjectAttributeException = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    attributeId: MaybeRef<string>,
    dataException: MaybeRef<DataException>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
parentId = unref(parentId);
attributeId = unref(attributeId);
dataException = unref(dataException);
      
      return customAxios<DataException>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects/${parentId}/attributes/${attributeId}/exceptions`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dataException
    },
      options);
    }
  


export const getCreateWithVersionDataObjectAttributeExceptionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWithVersionDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;data: DataException}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createWithVersionDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;data: DataException}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createWithVersionDataObjectAttributeException>>, {orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;data: DataException}> = (props) => {
          const {orgSlug,slug,version,parentId,attributeId,data} = props ?? {};

          return  createWithVersionDataObjectAttributeException(orgSlug,slug,version,parentId,attributeId,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateWithVersionDataObjectAttributeExceptionMutationResult = NonNullable<Awaited<ReturnType<typeof createWithVersionDataObjectAttributeException>>>
    export type CreateWithVersionDataObjectAttributeExceptionMutationBody = DataException
    export type CreateWithVersionDataObjectAttributeExceptionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateWithVersionDataObjectAttributeException = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createWithVersionDataObjectAttributeException>>, TError,{orgSlug: string;slug: string;version: string;parentId: string;attributeId: string;data: DataException}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateWithVersionDataObjectAttributeExceptionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const addContentObjectToFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    addContentObjectToFamilyBody: MaybeRef<AddContentObjectToFamilyBody>,
    params: MaybeRef<AddContentObjectToFamilyParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
documentFamilyId = unref(documentFamilyId);
addContentObjectToFamilyBody = unref(addContentObjectToFamilyBody);
params = unref(params);
      
      return customAxios<ContentObject>(
      {url: `/api/stores/${orgSlug}/${slug}/${documentFamilyId}/objects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: addContentObjectToFamilyBody,
        params: unref(params)
    },
      options);
    }
  


export const getAddContentObjectToFamilyMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addContentObjectToFamily>>, TError,{orgSlug: string;slug: string;documentFamilyId: string;data: AddContentObjectToFamilyBody;params: AddContentObjectToFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof addContentObjectToFamily>>, TError,{orgSlug: string;slug: string;documentFamilyId: string;data: AddContentObjectToFamilyBody;params: AddContentObjectToFamilyParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addContentObjectToFamily>>, {orgSlug: string;slug: string;documentFamilyId: string;data: AddContentObjectToFamilyBody;params: AddContentObjectToFamilyParams}> = (props) => {
          const {orgSlug,slug,documentFamilyId,data,params} = props ?? {};

          return  addContentObjectToFamily(orgSlug,slug,documentFamilyId,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddContentObjectToFamilyMutationResult = NonNullable<Awaited<ReturnType<typeof addContentObjectToFamily>>>
    export type AddContentObjectToFamilyMutationBody = AddContentObjectToFamilyBody
    export type AddContentObjectToFamilyMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useAddContentObjectToFamily = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addContentObjectToFamily>>, TError,{orgSlug: string;slug: string;documentFamilyId: string;data: AddContentObjectToFamilyBody;params: AddContentObjectToFamilyParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getAddContentObjectToFamilyMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the document family for a path in the current version of the store
 */
export const getFamilyByPath = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetFamilyByPathParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/fs/**`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetFamilyByPathQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetFamilyByPathParams>,) => {
    return ['api','stores',orgSlug,slug,'fs','**', ...(params ? [params]: [])] as const;
    }

    
export const getGetFamilyByPathQueryOptions = <TData = Awaited<ReturnType<typeof getFamilyByPath>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetFamilyByPathParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyByPath>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFamilyByPathQueryKey(orgSlug,slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFamilyByPath>>> = ({ signal }) => getFamilyByPath(orgSlug,slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFamilyByPath>>, TError, TData> 
}

export type GetFamilyByPathQueryResult = NonNullable<Awaited<ReturnType<typeof getFamilyByPath>>>
export type GetFamilyByPathQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetFamilyByPath = <TData = Awaited<ReturnType<typeof getFamilyByPath>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetFamilyByPathParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyByPath>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFamilyByPathQueryOptions(orgSlug,slug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



export const uploadToPath = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    uploadToPathBody: MaybeRef<UploadToPathBody>,
    params: MaybeRef<UploadToPathParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
uploadToPathBody = unref(uploadToPathBody);
params = unref(params);
      
      return customAxios<DocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/fs/**`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: uploadToPathBody,
        params: unref(params)
    },
      options);
    }
  


export const getUploadToPathMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadToPath>>, TError,{orgSlug: string;slug: string;data: UploadToPathBody;params: UploadToPathParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof uploadToPath>>, TError,{orgSlug: string;slug: string;data: UploadToPathBody;params: UploadToPathParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof uploadToPath>>, {orgSlug: string;slug: string;data: UploadToPathBody;params: UploadToPathParams}> = (props) => {
          const {orgSlug,slug,data,params} = props ?? {};

          return  uploadToPath(orgSlug,slug,data,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UploadToPathMutationResult = NonNullable<Awaited<ReturnType<typeof uploadToPath>>>
    export type UploadToPathMutationBody = UploadToPathBody
    export type UploadToPathMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useUploadToPath = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof uploadToPath>>, TError,{orgSlug: string;slug: string;data: UploadToPathBody;params: UploadToPathParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getUploadToPathMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete document family by path in the current version of the store
 */
export const deleteFamilyByPath = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<DeleteFamilyByPathParams>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<boolean>(
      {url: `/api/stores/${orgSlug}/${slug}/fs/**`, method: 'DELETE',
        params: unref(params)
    },
      options);
    }
  


export const getDeleteFamilyByPathMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyByPath>>, TError,{orgSlug: string;slug: string;params?: DeleteFamilyByPathParams}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyByPath>>, TError,{orgSlug: string;slug: string;params?: DeleteFamilyByPathParams}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFamilyByPath>>, {orgSlug: string;slug: string;params?: DeleteFamilyByPathParams}> = (props) => {
          const {orgSlug,slug,params} = props ?? {};

          return  deleteFamilyByPath(orgSlug,slug,params,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteFamilyByPathMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFamilyByPath>>>
    
    export type DeleteFamilyByPathMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteFamilyByPath = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFamilyByPath>>, TError,{orgSlug: string;slug: string;params?: DeleteFamilyByPathParams}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteFamilyByPathMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get the paginated dataObjects from the current version of the store
 */
export const getDataObjects = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<GetDataObjects200>(
      {url: `/api/stores/${orgSlug}/${slug}/dataObjects`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetDataObjectsQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectsParams>,) => {
    return ['api','stores',orgSlug,slug,'dataObjects', ...(params ? [params]: [])] as const;
    }

    
export const getGetDataObjectsQueryOptions = <TData = Awaited<ReturnType<typeof getDataObjects>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataObjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDataObjectsQueryKey(orgSlug,slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataObjects>>> = ({ signal }) => getDataObjects(orgSlug,slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataObjects>>, TError, TData> 
}

export type GetDataObjectsQueryResult = NonNullable<Awaited<ReturnType<typeof getDataObjects>>>
export type GetDataObjectsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetDataObjects = <TData = Awaited<ReturnType<typeof getDataObjects>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataObjects>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDataObjectsQueryOptions(orgSlug,slug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Add dataObjects to the the current version of the store
 */
export const createDataObjects = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    dataObject: MaybeRef<DataObject[]>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
dataObject = unref(dataObject);
      
      return customAxios<DataObject[]>(
      {url: `/api/stores/${orgSlug}/${slug}/dataObjects`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: dataObject
    },
      options);
    }
  


export const getCreateDataObjectsMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataObjects>>, TError,{orgSlug: string;slug: string;data: DataObject[]}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof createDataObjects>>, TError,{orgSlug: string;slug: string;data: DataObject[]}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createDataObjects>>, {orgSlug: string;slug: string;data: DataObject[]}> = (props) => {
          const {orgSlug,slug,data} = props ?? {};

          return  createDataObjects(orgSlug,slug,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateDataObjectsMutationResult = NonNullable<Awaited<ReturnType<typeof createDataObjects>>>
    export type CreateDataObjectsMutationBody = DataObject[]
    export type CreateDataObjectsMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useCreateDataObjects = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createDataObjects>>, TError,{orgSlug: string;slug: string;data: DataObject[]}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getCreateDataObjectsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete the dataObjects from the current version of the store
 */
export const deleteDataObjects = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/dataObjects`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteDataObjectsMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjects>>, TError,{orgSlug: string;slug: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjects>>, TError,{orgSlug: string;slug: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDataObjects>>, {orgSlug: string;slug: string}> = (props) => {
          const {orgSlug,slug} = props ?? {};

          return  deleteDataObjects(orgSlug,slug,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDataObjectsMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDataObjects>>>
    
    export type DeleteDataObjectsMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteDataObjects = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDataObjects>>, TError,{orgSlug: string;slug: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteDataObjectsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get a paginated list of all resources
 */
export const listStores = (
    params?: MaybeRef<ListStoresParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      params = unref(params);
      
      return customAxios<PageStore>(
      {url: `/api/stores`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListStoresQueryKey = (params?: MaybeRef<ListStoresParams>,) => {
    return ['api','stores', ...(params ? [params]: [])] as const;
    }

    
export const getListStoresQueryOptions = <TData = Awaited<ReturnType<typeof listStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(params?: MaybeRef<ListStoresParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListStoresQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listStores>>> = ({ signal }) => listStores(params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listStores>>, TError, TData> 
}

export type ListStoresQueryResult = NonNullable<Awaited<ReturnType<typeof listStores>>>
export type ListStoresQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListStores = <TData = Awaited<ReturnType<typeof listStores>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 params?: MaybeRef<ListStoresParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listStores>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListStoresQueryOptions(params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the change sequence for resource with the provided slug and version
 */
export const getModifiedForStore = (
    organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      organizationSlug = unref(organizationSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<number>(
      {url: `/api/stores/${organizationSlug}/${slug}/${version}/sequence`, method: 'GET', signal
    },
      options);
    }
  

export const getGetModifiedForStoreQueryKey = (organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','stores',organizationSlug,slug,version,'sequence'] as const;
    }

    
export const getGetModifiedForStoreQueryOptions = <TData = Awaited<ReturnType<typeof getModifiedForStore>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForStore>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetModifiedForStoreQueryKey(organizationSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getModifiedForStore>>> = ({ signal }) => getModifiedForStore(organizationSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(organizationSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getModifiedForStore>>, TError, TData> 
}

export type GetModifiedForStoreQueryResult = NonNullable<Awaited<ReturnType<typeof getModifiedForStore>>>
export type GetModifiedForStoreQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetModifiedForStore = <TData = Awaited<ReturnType<typeof getModifiedForStore>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 organizationSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getModifiedForStore>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetModifiedForStoreQueryOptions(organizationSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the taxonomies that are referenced (data store) in a specific version of the store
 */
export const getTaxonomiesByVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<Taxonomy[]>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/taxonomies`, method: 'GET', signal
    },
      options);
    }
  

export const getGetTaxonomiesByVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'taxonomies'] as const;
    }

    
export const getGetTaxonomiesByVersionQueryOptions = <TData = Awaited<ReturnType<typeof getTaxonomiesByVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaxonomiesByVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetTaxonomiesByVersionQueryKey(orgSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTaxonomiesByVersion>>> = ({ signal }) => getTaxonomiesByVersion(orgSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTaxonomiesByVersion>>, TError, TData> 
}

export type GetTaxonomiesByVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getTaxonomiesByVersion>>>
export type GetTaxonomiesByVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetTaxonomiesByVersion = <TData = Awaited<ReturnType<typeof getTaxonomiesByVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getTaxonomiesByVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTaxonomiesByVersionQueryOptions(orgSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the counts of extracted data, by parent taxon, for a specific family
 */
export const getFamilyTableCounts = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
      
      return customAxios<PathExtractedData[]>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/pathCounts`, method: 'GET', signal
    },
      options);
    }
  

export const getGetFamilyTableCountsQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'families',id,'pathCounts'] as const;
    }

    
export const getGetFamilyTableCountsQueryOptions = <TData = Awaited<ReturnType<typeof getFamilyTableCounts>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyTableCounts>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFamilyTableCountsQueryKey(orgSlug,slug,version,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFamilyTableCounts>>> = ({ signal }) => getFamilyTableCounts(orgSlug,slug,version,id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFamilyTableCounts>>, TError, TData> 
}

export type GetFamilyTableCountsQueryResult = NonNullable<Awaited<ReturnType<typeof getFamilyTableCounts>>>
export type GetFamilyTableCountsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetFamilyTableCounts = <TData = Awaited<ReturnType<typeof getFamilyTableCounts>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyTableCounts>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFamilyTableCountsQueryOptions(orgSlug,slug,version,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Export a .dfm file for this document family in a specific version of the store
 */
export const exportDocumentFamily = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/export`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getExportDocumentFamilyQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'families',id,'export'] as const;
    }

    
export const getExportDocumentFamilyQueryOptions = <TData = Awaited<ReturnType<typeof exportDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getExportDocumentFamilyQueryKey(orgSlug,slug,version,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exportDocumentFamily>>> = ({ signal }) => exportDocumentFamily(orgSlug,slug,version,id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exportDocumentFamily>>, TError, TData> 
}

export type ExportDocumentFamilyQueryResult = NonNullable<Awaited<ReturnType<typeof exportDocumentFamily>>>
export type ExportDocumentFamilyQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useExportDocumentFamily = <TData = Awaited<ReturnType<typeof exportDocumentFamily>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof exportDocumentFamily>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getExportDocumentFamilyQueryOptions(orgSlug,slug,version,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the executions for this document family in a specific version of the store
 */
export const getExecutionsForDocumentFamilyWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    params?: MaybeRef<GetExecutionsForDocumentFamilyWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
params = unref(params);
      
      return customAxios<PageExecution>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/executions`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetExecutionsForDocumentFamilyWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    params?: MaybeRef<GetExecutionsForDocumentFamilyWithVersionParams>,) => {
    return ['api','stores',orgSlug,slug,version,'families',id,'executions', ...(params ? [params]: [])] as const;
    }

    
export const getGetExecutionsForDocumentFamilyWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof getExecutionsForDocumentFamilyWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    params?: MaybeRef<GetExecutionsForDocumentFamilyWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecutionsForDocumentFamilyWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetExecutionsForDocumentFamilyWithVersionQueryKey(orgSlug,slug,version,id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExecutionsForDocumentFamilyWithVersion>>> = ({ signal }) => getExecutionsForDocumentFamilyWithVersion(orgSlug,slug,version,id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExecutionsForDocumentFamilyWithVersion>>, TError, TData> 
}

export type GetExecutionsForDocumentFamilyWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getExecutionsForDocumentFamilyWithVersion>>>
export type GetExecutionsForDocumentFamilyWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetExecutionsForDocumentFamilyWithVersion = <TData = Awaited<ReturnType<typeof getExecutionsForDocumentFamilyWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    params?: MaybeRef<GetExecutionsForDocumentFamilyWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExecutionsForDocumentFamilyWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExecutionsForDocumentFamilyWithVersionQueryOptions(orgSlug,slug,version,id,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get data objects for a specific document family in a version of the store for a specific project and format
 */
export const getFamilyExtractedDataWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    params?: MaybeRef<GetFamilyExtractedDataWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
params = unref(params);
      
      return customAxios<GetFamilyExtractedDataWithVersion200>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/dataObjects`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetFamilyExtractedDataWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    params?: MaybeRef<GetFamilyExtractedDataWithVersionParams>,) => {
    return ['api','stores',orgSlug,slug,version,'families',id,'dataObjects', ...(params ? [params]: [])] as const;
    }

    
export const getGetFamilyExtractedDataWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof getFamilyExtractedDataWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    params?: MaybeRef<GetFamilyExtractedDataWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyExtractedDataWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFamilyExtractedDataWithVersionQueryKey(orgSlug,slug,version,id,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFamilyExtractedDataWithVersion>>> = ({ signal }) => getFamilyExtractedDataWithVersion(orgSlug,slug,version,id,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFamilyExtractedDataWithVersion>>, TError, TData> 
}

export type GetFamilyExtractedDataWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getFamilyExtractedDataWithVersion>>>
export type GetFamilyExtractedDataWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetFamilyExtractedDataWithVersion = <TData = Awaited<ReturnType<typeof getFamilyExtractedDataWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    params?: MaybeRef<GetFamilyExtractedDataWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyExtractedDataWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFamilyExtractedDataWithVersionQueryOptions(orgSlug,slug,version,id,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the document structure for this document, based on its ID and the project through which we are viewing it
 */
export const getFamilyExtractedDataStructureWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    projectId: MaybeRef<string>,
    params?: MaybeRef<GetFamilyExtractedDataStructureWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
id = unref(id);
projectId = unref(projectId);
params = unref(params);
      
      return customAxios<DataObject[]>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${id}/dataObjects/${projectId}`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetFamilyExtractedDataStructureWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    projectId: MaybeRef<string>,
    params?: MaybeRef<GetFamilyExtractedDataStructureWithVersionParams>,) => {
    return ['api','stores',orgSlug,slug,version,'families',id,'dataObjects',projectId, ...(params ? [params]: [])] as const;
    }

    
export const getGetFamilyExtractedDataStructureWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof getFamilyExtractedDataStructureWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    projectId: MaybeRef<string>,
    params?: MaybeRef<GetFamilyExtractedDataStructureWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyExtractedDataStructureWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetFamilyExtractedDataStructureWithVersionQueryKey(orgSlug,slug,version,id,projectId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFamilyExtractedDataStructureWithVersion>>> = ({ signal }) => getFamilyExtractedDataStructureWithVersion(orgSlug,slug,version,id,projectId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(id) && unref(projectId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFamilyExtractedDataStructureWithVersion>>, TError, TData> 
}

export type GetFamilyExtractedDataStructureWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getFamilyExtractedDataStructureWithVersion>>>
export type GetFamilyExtractedDataStructureWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetFamilyExtractedDataStructureWithVersion = <TData = Awaited<ReturnType<typeof getFamilyExtractedDataStructureWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    id: MaybeRef<string>,
    projectId: MaybeRef<string>,
    params?: MaybeRef<GetFamilyExtractedDataStructureWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getFamilyExtractedDataStructureWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFamilyExtractedDataStructureWithVersionQueryOptions(orgSlug,slug,version,id,projectId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Generate a preview for a page of the latest content in a document family in a specific version of the store
 */
export const getContentObjectImageWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    page: MaybeRef<number>,
    params?: MaybeRef<GetContentObjectImageWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
documentFamilyId = unref(documentFamilyId);
page = unref(page);
params = unref(params);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${documentFamilyId}/preview/${page}`, method: 'GET',
        params: unref(params),
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetContentObjectImageWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    page: MaybeRef<number>,
    params?: MaybeRef<GetContentObjectImageWithVersionParams>,) => {
    return ['api','stores',orgSlug,slug,version,'families',documentFamilyId,'preview',page, ...(params ? [params]: [])] as const;
    }

    
export const getGetContentObjectImageWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof getContentObjectImageWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    page: MaybeRef<number>,
    params?: MaybeRef<GetContentObjectImageWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentObjectImageWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetContentObjectImageWithVersionQueryKey(orgSlug,slug,version,documentFamilyId,page,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentObjectImageWithVersion>>> = ({ signal }) => getContentObjectImageWithVersion(orgSlug,slug,version,documentFamilyId,page,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(documentFamilyId) && unref(page))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContentObjectImageWithVersion>>, TError, TData> 
}

export type GetContentObjectImageWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getContentObjectImageWithVersion>>>
export type GetContentObjectImageWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetContentObjectImageWithVersion = <TData = Awaited<ReturnType<typeof getContentObjectImageWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    page: MaybeRef<number>,
    params?: MaybeRef<GetContentObjectImageWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentObjectImageWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContentObjectImageWithVersionQueryOptions(orgSlug,slug,version,documentFamilyId,page,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the contents of a specific object, only if it is a native format
 */
export const getNativeContentObjectContentWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
documentFamilyId = unref(documentFamilyId);
id = unref(id);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${documentFamilyId}/objects/${id}/native`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetNativeContentObjectContentWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'families',documentFamilyId,'objects',id,'native'] as const;
    }

    
export const getGetNativeContentObjectContentWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof getNativeContentObjectContentWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNativeContentObjectContentWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetNativeContentObjectContentWithVersionQueryKey(orgSlug,slug,version,documentFamilyId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNativeContentObjectContentWithVersion>>> = ({ signal }) => getNativeContentObjectContentWithVersion(orgSlug,slug,version,documentFamilyId,id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(documentFamilyId) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getNativeContentObjectContentWithVersion>>, TError, TData> 
}

export type GetNativeContentObjectContentWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getNativeContentObjectContentWithVersion>>>
export type GetNativeContentObjectContentWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetNativeContentObjectContentWithVersion = <TData = Awaited<ReturnType<typeof getNativeContentObjectContentWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNativeContentObjectContentWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetNativeContentObjectContentWithVersionQueryOptions(orgSlug,slug,version,documentFamilyId,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the contents of a specific object
 */
export const getContentObjectContentWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
documentFamilyId = unref(documentFamilyId);
id = unref(id);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${documentFamilyId}/objects/${id}/content`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetContentObjectContentWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'families',documentFamilyId,'objects',id,'content'] as const;
    }

    
export const getGetContentObjectContentWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof getContentObjectContentWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentObjectContentWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetContentObjectContentWithVersionQueryKey(orgSlug,slug,version,documentFamilyId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentObjectContentWithVersion>>> = ({ signal }) => getContentObjectContentWithVersion(orgSlug,slug,version,documentFamilyId,id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(documentFamilyId) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContentObjectContentWithVersion>>, TError, TData> 
}

export type GetContentObjectContentWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getContentObjectContentWithVersion>>>
export type GetContentObjectContentWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetContentObjectContentWithVersion = <TData = Awaited<ReturnType<typeof getContentObjectContentWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentObjectContentWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContentObjectContentWithVersionQueryOptions(orgSlug,slug,version,documentFamilyId,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Generate an annotated version of the latest content in a document family in a specific version of the store
 */
export const getAnnotatedPDFWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
documentFamilyId = unref(documentFamilyId);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${documentFamilyId}/annotated`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetAnnotatedPDFWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'families',documentFamilyId,'annotated'] as const;
    }

    
export const getGetAnnotatedPDFWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof getAnnotatedPDFWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAnnotatedPDFWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAnnotatedPDFWithVersionQueryKey(orgSlug,slug,version,documentFamilyId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnnotatedPDFWithVersion>>> = ({ signal }) => getAnnotatedPDFWithVersion(orgSlug,slug,version,documentFamilyId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(documentFamilyId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAnnotatedPDFWithVersion>>, TError, TData> 
}

export type GetAnnotatedPDFWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getAnnotatedPDFWithVersion>>>
export type GetAnnotatedPDFWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetAnnotatedPDFWithVersion = <TData = Awaited<ReturnType<typeof getAnnotatedPDFWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAnnotatedPDFWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAnnotatedPDFWithVersionQueryOptions(orgSlug,slug,version,documentFamilyId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the paginated exceptions from the current version of the store
 */
export const getWithVersionExceptions = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetWithVersionExceptionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<PageDataException>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/exceptions`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetWithVersionExceptionsQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetWithVersionExceptionsParams>,) => {
    return ['api','stores',orgSlug,slug,version,'exceptions', ...(params ? [params]: [])] as const;
    }

    
export const getGetWithVersionExceptionsQueryOptions = <TData = Awaited<ReturnType<typeof getWithVersionExceptions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetWithVersionExceptionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWithVersionExceptions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetWithVersionExceptionsQueryKey(orgSlug,slug,version,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getWithVersionExceptions>>> = ({ signal }) => getWithVersionExceptions(orgSlug,slug,version,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getWithVersionExceptions>>, TError, TData> 
}

export type GetWithVersionExceptionsQueryResult = NonNullable<Awaited<ReturnType<typeof getWithVersionExceptions>>>
export type GetWithVersionExceptionsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetWithVersionExceptions = <TData = Awaited<ReturnType<typeof getWithVersionExceptions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetWithVersionExceptionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getWithVersionExceptions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetWithVersionExceptionsQueryOptions(orgSlug,slug,version,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



export const getExcelDownload = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/excel`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetExcelDownloadQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,version,'excel'] as const;
    }

    
export const getGetExcelDownloadQueryOptions = <TData = Awaited<ReturnType<typeof getExcelDownload>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExcelDownload>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetExcelDownloadQueryKey(orgSlug,slug,version);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExcelDownload>>> = ({ signal }) => getExcelDownload(orgSlug,slug,version, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExcelDownload>>, TError, TData> 
}

export type GetExcelDownloadQueryResult = NonNullable<Awaited<ReturnType<typeof getExcelDownload>>>
export type GetExcelDownloadQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetExcelDownload = <TData = Awaited<ReturnType<typeof getExcelDownload>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExcelDownload>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExcelDownloadQueryOptions(orgSlug,slug,version,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the paginated dataObjects from the current version of the store
 */
export const getDataObjectChildrenWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectChildrenWithVersionParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
parentId = unref(parentId);
params = unref(params);
      
      return customAxios<PageDataObject>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects/${parentId}/children`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetDataObjectChildrenWithVersionQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectChildrenWithVersionParams>,) => {
    return ['api','stores',orgSlug,slug,version,'dataObjects',parentId,'children', ...(params ? [params]: [])] as const;
    }

    
export const getGetDataObjectChildrenWithVersionQueryOptions = <TData = Awaited<ReturnType<typeof getDataObjectChildrenWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectChildrenWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataObjectChildrenWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDataObjectChildrenWithVersionQueryKey(orgSlug,slug,version,parentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataObjectChildrenWithVersion>>> = ({ signal }) => getDataObjectChildrenWithVersion(orgSlug,slug,version,parentId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(parentId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataObjectChildrenWithVersion>>, TError, TData> 
}

export type GetDataObjectChildrenWithVersionQueryResult = NonNullable<Awaited<ReturnType<typeof getDataObjectChildrenWithVersion>>>
export type GetDataObjectChildrenWithVersionQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetDataObjectChildrenWithVersion = <TData = Awaited<ReturnType<typeof getDataObjectChildrenWithVersion>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectChildrenWithVersionParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataObjectChildrenWithVersion>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDataObjectChildrenWithVersionQueryOptions(orgSlug,slug,version,parentId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get a Preview Image for the attribute if is from a spatial document
 */
export const getPreviewForAttributeTagMetadata = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    attributeId: MaybeRef<string>,
    params?: MaybeRef<GetPreviewForAttributeTagMetadataParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
parentId = unref(parentId);
attributeId = unref(attributeId);
params = unref(params);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/dataObjects/${parentId}/attributes/${attributeId}/preview`, method: 'GET',
        params: unref(params),
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetPreviewForAttributeTagMetadataQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    attributeId: MaybeRef<string>,
    params?: MaybeRef<GetPreviewForAttributeTagMetadataParams>,) => {
    return ['api','stores',orgSlug,slug,version,'dataObjects',parentId,'attributes',attributeId,'preview', ...(params ? [params]: [])] as const;
    }

    
export const getGetPreviewForAttributeTagMetadataQueryOptions = <TData = Awaited<ReturnType<typeof getPreviewForAttributeTagMetadata>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    attributeId: MaybeRef<string>,
    params?: MaybeRef<GetPreviewForAttributeTagMetadataParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPreviewForAttributeTagMetadata>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetPreviewForAttributeTagMetadataQueryKey(orgSlug,slug,version,parentId,attributeId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getPreviewForAttributeTagMetadata>>> = ({ signal }) => getPreviewForAttributeTagMetadata(orgSlug,slug,version,parentId,attributeId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version) && unref(parentId) && unref(attributeId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getPreviewForAttributeTagMetadata>>, TError, TData> 
}

export type GetPreviewForAttributeTagMetadataQueryResult = NonNullable<Awaited<ReturnType<typeof getPreviewForAttributeTagMetadata>>>
export type GetPreviewForAttributeTagMetadataQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetPreviewForAttributeTagMetadata = <TData = Awaited<ReturnType<typeof getPreviewForAttributeTagMetadata>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    parentId: MaybeRef<string>,
    attributeId: MaybeRef<string>,
    params?: MaybeRef<GetPreviewForAttributeTagMetadataParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getPreviewForAttributeTagMetadata>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetPreviewForAttributeTagMetadataQueryOptions(orgSlug,slug,version,parentId,attributeId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the paginated data attributes from the version of the store
 */
export const getDataAttributes = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetDataAttributesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<PageDataAttribute>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/attributes`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetDataAttributesQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetDataAttributesParams>,) => {
    return ['api','stores',orgSlug,slug,version,'attributes', ...(params ? [params]: [])] as const;
    }

    
export const getGetDataAttributesQueryOptions = <TData = Awaited<ReturnType<typeof getDataAttributes>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetDataAttributesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataAttributes>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDataAttributesQueryKey(orgSlug,slug,version,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataAttributes>>> = ({ signal }) => getDataAttributes(orgSlug,slug,version,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataAttributes>>, TError, TData> 
}

export type GetDataAttributesQueryResult = NonNullable<Awaited<ReturnType<typeof getDataAttributes>>>
export type GetDataAttributesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetDataAttributes = <TData = Awaited<ReturnType<typeof getDataAttributes>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetDataAttributesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataAttributes>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDataAttributesQueryOptions(orgSlug,slug,version,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * The activity statistics for the store provides information on the processing
 */
export const getActivityStatistics = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetActivityStatisticsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
params = unref(params);
      
      return customAxios<ActivityStatistics>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/activityStats`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetActivityStatisticsQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetActivityStatisticsParams>,) => {
    return ['api','stores',orgSlug,slug,version,'activityStats', ...(params ? [params]: [])] as const;
    }

    
export const getGetActivityStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof getActivityStatistics>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetActivityStatisticsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getActivityStatistics>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetActivityStatisticsQueryKey(orgSlug,slug,version,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getActivityStatistics>>> = ({ signal }) => getActivityStatistics(orgSlug,slug,version,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(version))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getActivityStatistics>>, TError, TData> 
}

export type GetActivityStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof getActivityStatistics>>>
export type GetActivityStatisticsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetActivityStatistics = <TData = Awaited<ReturnType<typeof getActivityStatistics>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    params?: MaybeRef<GetActivityStatisticsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getActivityStatistics>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetActivityStatisticsQueryOptions(orgSlug,slug,version,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the taxonomies that are referenced (data store) in the current version of the store
 */
export const getStoreTaxonomies = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
      
      return customAxios<Taxonomy[]>(
      {url: `/api/stores/${orgSlug}/${slug}/taxonomies`, method: 'GET', signal
    },
      options);
    }
  

export const getGetStoreTaxonomiesQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,'taxonomies'] as const;
    }

    
export const getGetStoreTaxonomiesQueryOptions = <TData = Awaited<ReturnType<typeof getStoreTaxonomies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStoreTaxonomies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetStoreTaxonomiesQueryKey(orgSlug,slug);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getStoreTaxonomies>>> = ({ signal }) => getStoreTaxonomies(orgSlug,slug, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getStoreTaxonomies>>, TError, TData> 
}

export type GetStoreTaxonomiesQueryResult = NonNullable<Awaited<ReturnType<typeof getStoreTaxonomies>>>
export type GetStoreTaxonomiesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetStoreTaxonomies = <TData = Awaited<ReturnType<typeof getStoreTaxonomies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getStoreTaxonomies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetStoreTaxonomiesQueryOptions(orgSlug,slug,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * List (with pagination) the document families in the current version of the store
 */
export const listFamilies = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<ListFamiliesParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<PageDocumentFamily>(
      {url: `/api/stores/${orgSlug}/${slug}/families`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getListFamiliesQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<ListFamiliesParams>,) => {
    return ['api','stores',orgSlug,slug,'families', ...(params ? [params]: [])] as const;
    }

    
export const getListFamiliesQueryOptions = <TData = Awaited<ReturnType<typeof listFamilies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<ListFamiliesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFamilies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getListFamiliesQueryKey(orgSlug,slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof listFamilies>>> = ({ signal }) => listFamilies(orgSlug,slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof listFamilies>>, TError, TData> 
}

export type ListFamiliesQueryResult = NonNullable<Awaited<ReturnType<typeof listFamilies>>>
export type ListFamiliesQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useListFamilies = <TData = Awaited<ReturnType<typeof listFamilies>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<ListFamiliesParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof listFamilies>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getListFamiliesQueryOptions(orgSlug,slug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Bulk delete a set of document families in the current version
 */
export const deleteFamilies = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    bulkDelete: MaybeRef<BulkDelete>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
bulkDelete = unref(bulkDelete);
      
      return customAxios<void>(
      {url: `/api/stores/${orgSlug}/${slug}/families`, method: 'DELETE',
      headers: {'Content-Type': 'application/json', },
      data: bulkDelete
    },
      options);
    }
  


export const getDeleteFamiliesMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFamilies>>, TError,{orgSlug: string;slug: string;data: BulkDelete}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteFamilies>>, TError,{orgSlug: string;slug: string;data: BulkDelete}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFamilies>>, {orgSlug: string;slug: string;data: BulkDelete}> = (props) => {
          const {orgSlug,slug,data} = props ?? {};

          return  deleteFamilies(orgSlug,slug,data,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteFamiliesMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFamilies>>>
    export type DeleteFamiliesMutationBody = BulkDelete
    export type DeleteFamiliesMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteFamilies = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFamilies>>, TError,{orgSlug: string;slug: string;data: BulkDelete}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteFamiliesMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Generate a preview for a page of the latest content in a document family in the current version of the store
 */
export const getContentObjectImage = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    page: MaybeRef<number>,
    params?: MaybeRef<GetContentObjectImageParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
documentFamilyId = unref(documentFamilyId);
page = unref(page);
params = unref(params);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/families/${documentFamilyId}/preview/${page}`, method: 'GET',
        params: unref(params),
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetContentObjectImageQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    page: MaybeRef<number>,
    params?: MaybeRef<GetContentObjectImageParams>,) => {
    return ['api','stores',orgSlug,slug,'families',documentFamilyId,'preview',page, ...(params ? [params]: [])] as const;
    }

    
export const getGetContentObjectImageQueryOptions = <TData = Awaited<ReturnType<typeof getContentObjectImage>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    page: MaybeRef<number>,
    params?: MaybeRef<GetContentObjectImageParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentObjectImage>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetContentObjectImageQueryKey(orgSlug,slug,documentFamilyId,page,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentObjectImage>>> = ({ signal }) => getContentObjectImage(orgSlug,slug,documentFamilyId,page,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(documentFamilyId) && unref(page))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContentObjectImage>>, TError, TData> 
}

export type GetContentObjectImageQueryResult = NonNullable<Awaited<ReturnType<typeof getContentObjectImage>>>
export type GetContentObjectImageQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetContentObjectImage = <TData = Awaited<ReturnType<typeof getContentObjectImage>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    page: MaybeRef<number>,
    params?: MaybeRef<GetContentObjectImageParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentObjectImage>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContentObjectImageQueryOptions(orgSlug,slug,documentFamilyId,page,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Download the native content of a specific object
 */
export const getNativeContentObjectContent = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
documentFamilyId = unref(documentFamilyId);
id = unref(id);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/families/${documentFamilyId}/objects/${id}/native`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetNativeContentObjectContentQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,'families',documentFamilyId,'objects',id,'native'] as const;
    }

    
export const getGetNativeContentObjectContentQueryOptions = <TData = Awaited<ReturnType<typeof getNativeContentObjectContent>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNativeContentObjectContent>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetNativeContentObjectContentQueryKey(orgSlug,slug,documentFamilyId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getNativeContentObjectContent>>> = ({ signal }) => getNativeContentObjectContent(orgSlug,slug,documentFamilyId,id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(documentFamilyId) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getNativeContentObjectContent>>, TError, TData> 
}

export type GetNativeContentObjectContentQueryResult = NonNullable<Awaited<ReturnType<typeof getNativeContentObjectContent>>>
export type GetNativeContentObjectContentQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetNativeContentObjectContent = <TData = Awaited<ReturnType<typeof getNativeContentObjectContent>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getNativeContentObjectContent>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetNativeContentObjectContentQueryOptions(orgSlug,slug,documentFamilyId,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



export const getContentObjectContent = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
documentFamilyId = unref(documentFamilyId);
id = unref(id);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/families/${documentFamilyId}/objects/${id}/content`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetContentObjectContentQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,'families',documentFamilyId,'objects',id,'content'] as const;
    }

    
export const getGetContentObjectContentQueryOptions = <TData = Awaited<ReturnType<typeof getContentObjectContent>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentObjectContent>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetContentObjectContentQueryKey(orgSlug,slug,documentFamilyId,id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getContentObjectContent>>> = ({ signal }) => getContentObjectContent(orgSlug,slug,documentFamilyId,id, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(documentFamilyId) && unref(id))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getContentObjectContent>>, TError, TData> 
}

export type GetContentObjectContentQueryResult = NonNullable<Awaited<ReturnType<typeof getContentObjectContent>>>
export type GetContentObjectContentQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetContentObjectContent = <TData = Awaited<ReturnType<typeof getContentObjectContent>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getContentObjectContent>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetContentObjectContentQueryOptions(orgSlug,slug,documentFamilyId,id,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Generate an annotated version of the latest content in a document family in the current version of the store
 */
export const getAnnotatedPDF = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
documentFamilyId = unref(documentFamilyId);
      
      return customAxios<Blob>(
      {url: `/api/stores/${orgSlug}/${slug}/families/${documentFamilyId}/annotated`, method: 'GET',
        responseType: 'blob', signal
    },
      options);
    }
  

export const getGetAnnotatedPDFQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,) => {
    return ['api','stores',orgSlug,slug,'families',documentFamilyId,'annotated'] as const;
    }

    
export const getGetAnnotatedPDFQueryOptions = <TData = Awaited<ReturnType<typeof getAnnotatedPDF>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAnnotatedPDF>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetAnnotatedPDFQueryKey(orgSlug,slug,documentFamilyId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getAnnotatedPDF>>> = ({ signal }) => getAnnotatedPDF(orgSlug,slug,documentFamilyId, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(documentFamilyId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getAnnotatedPDF>>, TError, TData> 
}

export type GetAnnotatedPDFQueryResult = NonNullable<Awaited<ReturnType<typeof getAnnotatedPDF>>>
export type GetAnnotatedPDFQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetAnnotatedPDF = <TData = Awaited<ReturnType<typeof getAnnotatedPDF>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getAnnotatedPDF>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetAnnotatedPDFQueryOptions(orgSlug,slug,documentFamilyId,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the paginated exceptions from the current version of the store
 */
export const getExceptions = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetExceptionsParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
params = unref(params);
      
      return customAxios<PageDataException>(
      {url: `/api/stores/${orgSlug}/${slug}/exceptions`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetExceptionsQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetExceptionsParams>,) => {
    return ['api','stores',orgSlug,slug,'exceptions', ...(params ? [params]: [])] as const;
    }

    
export const getGetExceptionsQueryOptions = <TData = Awaited<ReturnType<typeof getExceptions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetExceptionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExceptions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetExceptionsQueryKey(orgSlug,slug,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getExceptions>>> = ({ signal }) => getExceptions(orgSlug,slug,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getExceptions>>, TError, TData> 
}

export type GetExceptionsQueryResult = NonNullable<Awaited<ReturnType<typeof getExceptions>>>
export type GetExceptionsQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetExceptions = <TData = Awaited<ReturnType<typeof getExceptions>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    params?: MaybeRef<GetExceptionsParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getExceptions>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetExceptionsQueryOptions(orgSlug,slug,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Get the paginated dataObjects from the current version of the store
 */
export const getDataObjectChildren = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    parentId: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectChildrenParams>,
 options?: SecondParameter<typeof customAxios>,signal?: AbortSignal
) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
parentId = unref(parentId);
params = unref(params);
      
      return customAxios<PageDataObject>(
      {url: `/api/stores/${orgSlug}/${slug}/dataObjects/${parentId}/children`, method: 'GET',
        params: unref(params), signal
    },
      options);
    }
  

export const getGetDataObjectChildrenQueryKey = (orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    parentId: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectChildrenParams>,) => {
    return ['api','stores',orgSlug,slug,'dataObjects',parentId,'children', ...(params ? [params]: [])] as const;
    }

    
export const getGetDataObjectChildrenQueryOptions = <TData = Awaited<ReturnType<typeof getDataObjectChildren>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    parentId: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectChildrenParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataObjectChildren>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  getGetDataObjectChildrenQueryKey(orgSlug,slug,parentId,params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getDataObjectChildren>>> = ({ signal }) => getDataObjectChildren(orgSlug,slug,parentId,params, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: computed(() => !!(unref(orgSlug) && unref(slug) && unref(parentId))), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getDataObjectChildren>>, TError, TData> 
}

export type GetDataObjectChildrenQueryResult = NonNullable<Awaited<ReturnType<typeof getDataObjectChildren>>>
export type GetDataObjectChildrenQueryError = ErrorType<ExceptionResponse | ThrowableProblem>

export const useGetDataObjectChildren = <TData = Awaited<ReturnType<typeof getDataObjectChildren>>, TError = ErrorType<ExceptionResponse | ThrowableProblem>>(
 orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    parentId: MaybeRef<string>,
    params?: MaybeRef<GetDataObjectChildrenParams>, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getDataObjectChildren>>, TError, TData>>, request?: SecondParameter<typeof customAxios>}

  ): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetDataObjectChildrenQueryOptions(orgSlug,slug,parentId,params,options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey as QueryKey;

  return query;
}



/**
 * Delete specific content object from document family
 */
export const deleteContentObjectWithVersion = (
    orgSlug: MaybeRef<string>,
    slug: MaybeRef<string>,
    version: MaybeRef<string>,
    documentFamilyId: MaybeRef<string>,
    id: MaybeRef<string>,
 options?: SecondParameter<typeof customAxios>,) => {
      orgSlug = unref(orgSlug);
slug = unref(slug);
version = unref(version);
documentFamilyId = unref(documentFamilyId);
id = unref(id);
      
      return customAxios<boolean>(
      {url: `/api/stores/${orgSlug}/${slug}/${version}/families/${documentFamilyId}/objects/${id}`, method: 'DELETE'
    },
      options);
    }
  


export const getDeleteContentObjectWithVersionMutationOptions = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteContentObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;documentFamilyId: string;id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
): UseMutationOptions<Awaited<ReturnType<typeof deleteContentObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;documentFamilyId: string;id: string}, TContext> => {
 const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteContentObjectWithVersion>>, {orgSlug: string;slug: string;version: string;documentFamilyId: string;id: string}> = (props) => {
          const {orgSlug,slug,version,documentFamilyId,id} = props ?? {};

          return  deleteContentObjectWithVersion(orgSlug,slug,version,documentFamilyId,id,requestOptions)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteContentObjectWithVersionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteContentObjectWithVersion>>>
    
    export type DeleteContentObjectWithVersionMutationError = ErrorType<ExceptionResponse | ThrowableProblem>

    export const useDeleteContentObjectWithVersion = <TError = ErrorType<ExceptionResponse | ThrowableProblem>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteContentObjectWithVersion>>, TError,{orgSlug: string;slug: string;version: string;documentFamilyId: string;id: string}, TContext>, request?: SecondParameter<typeof customAxios>}
) => {

      const mutationOptions = getDeleteContentObjectWithVersionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    