<script lang="ts">
import { defineComponent, ref, toRefs, watch } from "vue";

interface SelectionOption {
  description?: string;
  id: string;
  label?: string;
}

export default defineComponent({
  name: "SelectionOptionsList",
  props: {
    modelValue: Array as () => SelectionOption[],
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const items = ref<SelectionOption[]>(props.modelValue || []);
    const tempItem = ref<SelectionOption>({ description: "", id: "", label: "" });
    const editingIndex = ref(-1);

    watch(items, (newItems) => {
      emit("update:modelValue", newItems);
    }, { deep: true });

    const resetForm = () => {
      tempItem.value = { description: "", id: "", label: "" };
      editingIndex.value = -1;
    };

    const addOrUpdateItem = () => {
      if (editingIndex.value === -1) {
        const newItem: SelectionOption = { ...tempItem.value, id: Date.now().toString() };
        items.value.push(newItem);
      } else {
        items.value[editingIndex.value] = { ...tempItem.value };
        editingIndex.value = -1;
      }
      resetForm();
    };

    const editItem = (index: number) => {
      tempItem.value = { ...items.value[index] };
      editingIndex.value = index;
    };

    const removeItem = (index: number) => {
      items.value.splice(index, 1);
      if (editingIndex.value === index) {
        resetForm();
      }
    };

    return {
      ...toRefs(props),
      items,
      tempItem,
      addOrUpdateItem,
      editItem,
      removeItem,
    };
  },
});
</script>

<template>
  <div class="p-4">
    <div class="mb-4">
      <KodexaTextInput
        v-model="tempItem.label"
        name="label" label="Label" placeholder="Enter label"
      />
      <KodexaTextArea
        v-model="tempItem.description" label="Description" placeholder="Enter description" class="mt-1"
        :rows="5" name="description"
      />
      <KodexaButton class="mt-4" type="secondary" size="small" icon="plus" :disabled="!tempItem.label" @click="addOrUpdateItem">
        Add/Update
      </KodexaButton>
    </div>

    <ul class="list-disc pl-5">
      <li v-for="(item, index) in items" :key="item.id" class="mb-4">
        <div class="flex items-start justify-between">
          <div class="grow">
            <p class="text-sm font-semibold">
              {{ item.label }}
            </p>
            <p class="text-xs">
              {{ item.description }}
            </p>
          </div>
          <div class="flex shrink-0">
            <KodexaButton
              class="mr-2" type="secondary" size="small" icon="edit" @click="editItem(index)"
            >
              Edit
            </KodexaButton>
            <KodexaButton
              type="danger" size="small" icon="delete" @click="removeItem(index)"
            >
              Delete
            </KodexaButton>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<style scoped>
</style>
