<script setup lang="ts">
import { Button, Toolbar, ToolbarSpacer } from "@progress/kendo-vue-buttons";
import { Grid, GridNoRecords } from "@progress/kendo-vue-grid";
import type { PropType } from "vue";
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { filterIcon } from "@progress/kendo-svg-icons";
import type { DashboardWidget } from "~/model";
import KodexaProjectCard from "~/components/kodexa-project-card.vue";
import { createGridHelper } from "~/store/useGridHelper";
import appStore from "~/store";
import { useListProjects } from "~/api/projects/projects";
import router from "~/router/router";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
  },
});

const {
  currentOrganization,
} = storeToRefs(appStore.organizationStore);

const widget = ref(props.widget);

const gridHelper = createGridHelper("projects-list", {
  page: 1,
  pageSize: 6,
  filter: "",
  query: "",
  sort: "createdOn:desc",
});

const {
  pageSettings,
  sort,
  showFilter,
  filter,
  gridQuery,
} = storeToRefs(gridHelper);

// Query
const {
  isLoading,
  isError,
  data,
  error,
  refetch,
} = useListProjects(gridQuery.value);

watch(data, (newValue) => {
  if (newValue && newValue.totalElements) {
    gridHelper.setTotal(newValue.totalElements);
    gridHelper.saveState();
  }
});

const columns = computed(() => {
  const baseColumns = [
    { title: "Name", field: "name", cell: "name", width: "500px" },
    { title: "Status", field: "status.status", cell: "status" },
    { title: "Owner", field: "owner.firstName", cell: "owner" },
    { title: "Created", field: "createdOn", cell: "created" },
  ];

  if (!currentOrganization.value) {
    baseColumns.splice(1, 0, { title: "Organization", field: "organization.name", cell: "organization" });
  }

  return baseColumns;
});

watch(currentOrganization, (newValue) => {
  if (newValue) {
    gridHelper.setBaseFilter(`organization.id:'${newValue.id}'`);
    gridHelper.setPage(1);
  } else {
    gridHelper.setBaseFilter("");
    gridHelper.setPage(1);
  }
}, {
  immediate: true,
});

watch(gridHelper.pageSettings, (newPageSettings) => {
  if (newPageSettings) {
    gridQuery.value.pageSize = newPageSettings.take as number;
  }
});

const computedQuery = computed({
  get() {
    return gridHelper.query;
  },
  set(value) {
    gridHelper.setQuery(value);
  },
});

const el = ref(null);
const { width, height } = useElementSize(el);

const gridStyle = computed(() => {
  const rowSpan = widget.value?.properties?.defaultPosition.rowSpan || 6;
  const height = (rowSpan * 85) - 130;
  return {
    rowSpan: `${rowSpan}`,
    height: `${height}px`,
  };
});

function gotoOrg(org) {
  router.push(`/a/o/${org.id}/home`);
}
</script>

<template>
  <div ref="el" class="mx-4">
    <Toolbar class="mb-1 border-0 bg-white">
      <label for="query" class="sr-only">Query</label>
      <KodexaTextInput
        id="filterProjects"
        v-model="computedQuery" :show-clear="true" type="text" name="filterProjects"
        class="-ml-1 block w-96 rounded-md border-gray-300 shadow-sm sm:text-sm" placeholder="Filter projects"
      />
      <Button
        class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        :svg-icon="filterIcon" title="Filter" :togglable="true" @click="gridHelper.toggleFilter()"
      />
      <KodexaArticle class="ml-2 mt-2" article-id="8729166" text="Learn more about projects" :slide="true" />
      <ToolbarSpacer />
    </Toolbar>
    <Grid
      class="h-full"
      :style="gridStyle"
      :data-items="data ? data.content : []"
      :pageable="pageSettings"
      :loader="isLoading"
      :sort="sort"
      scrollable="scrollable"
      :skip="pageSettings.skip as number"
      :total="pageSettings.total as number"
      :page-size="pageSettings.take as number"
      :take="pageSettings.take as number"
      :columns="columns"
      :filterable="showFilter"
      :filter="filter"
      :resizable="true"
      :sortable="true"
      @pagechange="gridHelper.pageChangeHandler($event)"
      @filterchange="gridHelper.filterChange($event)"
      @sortchange="gridHelper.sortChange($event)"
    >
      <GridNoRecords>
        <KodexaGridNoRecords
          :error="error" :is-loading="isLoading" :is-error="isError"
          no-records-message="No projects found"
        />
      </GridNoRecords>
      <template #organization="{ props }">
        <td>
          <div class="open-org-link cursor-pointer" @click="gotoOrg(props.dataItem.organization)">
            {{ props.dataItem.organization.name }}
          </div>
        </td>
      </template>
      <template #name="{ props }">
        <td>
          <KodexaProjectCard :project="props.dataItem" @updated="refetch()" />
        </td>
      </template>
      <template #status="{ props }">
        <td>
          <KodexaProjectStatus :project="props.dataItem" @updated="refetch" />
        </td>
      </template>
      <template #owner="{ props }">
        <td>
          <KodexaProjectOwner :project="props.dataItem" @updated="refetch" />
        </td>
      </template>
      <template #created="{ props }">
        <td>
          <KodexaDateTimeView :date="props.dataItem.createdOn" :ago="true" />
        </td>
      </template>
    </Grid>
  </div>
</template>

<style scoped>
.open-org-link {
  color: #007bff; /* Change to your preferred link color */
  text-decoration: underline;
  cursor: pointer;
}

.open-org-link:hover {
  color: #0056b3; /* Darker shade for hover effect */
}
</style>
