<script lang="ts" setup>
import { DragPreviewImage, useDrag } from "vue3-dnd";
import { toRefs } from "@vueuse/core";
import { createDocumentViewerStore } from "~/store/useDocumentView";
import type { TagInstance } from "~/store/useWorkspace";

const props = defineProps<{
  showCopyIcon?: boolean;
  tagInstance: TagInstance;
  viewId: string;
}>();

const [collect, drag, preview] = useDrag(() => ({
  type: "label",
  item: {
    tagInstance: props.tagInstance,
  },
  options: {
    dropEffect: props.showCopyIcon ? "copy" : "move",
  },
  collect: monitor => ({
    opacity: monitor.isDragging() ? 0.8 : 1,
  }),
}));
const { opacity } = toRefs(collect);

const matchingTagInstances = computed(() => {
  const useDocumentStore = createDocumentViewerStore(props.viewId);
  const { taggedInstances } = toRefs(useDocumentStore);
  return taggedInstances.value.filter(tagInstance => tagInstance.taxon.path === props.tagInstance.taxon.path);
});
</script>

<template>
  <div>
    <DragPreviewImage :connect="preview" src="/assets/tag.png" size="12" class="drag"/>
    <div :ref="drag" :style="{ opacity }">
      <li class="flex cursor-default select-none rounded-xl p-3">
        <div
          class="flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-green-600"
          :style="{ 'background-color': tagInstance.taxon.color }"
        >
          <MaterialDesignIcon name="tag" class="h-6 w-6 text-white" aria-hidden="true" />
        </div>
        <div class="ml-4 flex-auto">
          <p class="text-md font-bold">
            {{ tagInstance.label }}
          </p>
          <p class="text-sm text-gray-700">
            <span
              v-if="matchingTagInstances.length > 0"
              class="rounded bg-blue-500 px-2 py-1 text-xs font-light leading-loose text-white" title=""
            >
              <MaterialDesignIcon name="tag" size="10" /> {{ matchingTagInstances.length }}
            </span> {{ tagInstance.parentLabel }}
          </p>
        </div>
      </li>
    </div>
  </div>
</template>

<style>
.drag
{
  cursor: none;
  user-select: none;
}
</style>
