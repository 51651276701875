<script setup lang="ts">
import { AutoComplete } from "@progress/kendo-vue-dropdowns";
import type { FilterDescriptor } from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";
import { filterBy } from "@progress/kendo-data-query";

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: true,
  },
  textField: {
    type: String,
    required: false,
    default: "text",
  },
  valueField: {
    type: String,
    required: false,
    default: "id",
  },
  items: {
    type: Array,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    required: false,
    default: undefined,
  },
  description: {
    type: String,
    required: false,
    default: undefined,
  },
  placeholder: {
    type: String,
    default: "",
  },
  errors: {
    type: Object,
    default: () => {
    },
  },
  filterable: {
    type: Boolean,
    default: false,
  },
  defaultItem: {
    type: Object,
    default: () => {
      return undefined;
    },
  },
});

const emit = defineEmits(["update:modelValue", "filter-change", "select"]);

const opened = ref(false);

const selectedValue = computed({
  get: () => {
    return props.modelValue;
  },
  set: (value: any) => {
    emit("update:modelValue", value);
  },
});

const dataItems = computed(() => {
  const filters = {
    field: props.textField,
    value: selectedValue.value,
    operator: "startswith",
    ignoreCase: true,
  } as FilterDescriptor;

  return selectedValue.value ? filterBy(props.items, filters) : props.items;
});
</script>

<template>
  <div>
    <div
      :class="{ 'has-error': errors && errors[name], 'success': errors && !errors[name] }"
    />
    <label v-if="label" for="name" class="kodexa-label"> {{ label }}</label>
    <AutoComplete
      v-model="selectedValue"
      :opened="opened"
      :placeholder="placeholder"
      :data-items="dataItems" fill-mode="outline"
      :text-field="textField"
      :data-item-key="valueField"
      :filterable="filterable"
      :loading="loading"
      :valid="!errors || errors && !errors[name]"
      rounded="large"
      :default-item="defaultItem"
      @focus="opened = true"
      @blur="opened = false"
      @select="emit('select', $event)"
    />
    <div v-if="description" class="text-sm text-gray-500">
      {{ description }}
    </div>
    <p v-if="errors && errors[name]" class="mt-2 text-sm text-red-600">
      {{ errors[name] }}
    </p>
  </div>
</template>

<style scoped>
.kodexa-label {
  @apply block text-gray-700 mb-1
}

:deep(.k-input-inner) {
  @apply text-sm
}

.kodexa-input * {
  @apply w-full appearance-none rounded-md border border-gray-300
  px-2 py-1 shadow-sm placeholder:text-gray-400 sm:text-sm !important
}

.kodexa-input:hover {
  @apply bg-white text-gray-600 border-blue-500 ring-1 ring-blue-500  !important
}

.kodexa-input:focus-within {
  @apply border-blue-500 ring-1 ring-blue-500
}
.kodexa-input:focus {
  @apply border-blue-500 ring-1 ring-blue-500  !important
}

.k-input-outline {
  @apply border-gray-300
}
</style>
