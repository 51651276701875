<script setup lang="ts">
import CryptoJS from "crypto-js";
import { log } from "~/utils/logger";

const props = defineProps({
  color: String,
  size: { type: String, default: "8" },
  user: Object,
  showName: { type: Boolean, default: false },
});

const imageSrc = ref<string>("");

function handleImageError() {
  log.info(`Error loading image ${imageSrc.value}`);
  if (props.user?.email) {
    imageSrc.value = getGravatarURL(props.user.email);
  } else {
    imageSrc.value = getGravatarURL("test@example.com");
  }
}

function getGravatarURL(email: string): string {
  const trimmedEmail = email.trim().toLowerCase();
  const hash = CryptoJS.MD5(trimmedEmail).toString();
  return `https://www.gravatar.com/avatar/${hash}?d=retro`;
}

watch(() => props.user, () => {
  if (props.user?.image) {
    imageSrc.value = props.user.image;
  } else if (props.user?.email) {
    imageSrc.value = getGravatarURL(props.user.email);
  } else {
    imageSrc.value = "/assets/header_icons/user.png";
  }
}, { immediate: true });
</script>

<template>
  <div class="flex">
    <div :class="[`w-${size}`, `h-${size}`, `min-h-${size}`]">
      <img
        class="inline-block rounded-md grow-0 bg-gray-200"
        :src="imageSrc"
        alt="Image"
        @error="handleImageError"
      >
    </div>
    <span v-if="showName" class="text-md ml-2 mt-1 whitespace-nowrap">{{ props.user?.firstName }} {{ props.user?.lastName }}</span>
  </div>
</template>
