<script setup lang="ts">
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LegendComponent, TitleComponent, TooltipComponent } from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import type { PropType } from "vue";
import { provide } from "vue";
import type { DashboardWidget } from "~/model";
import appStore from "~/store";
import { log } from "~/utils/logger";

const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

provide(THEME_KEY, "light");

const aggregationResult = ref({});
const xAxis = ref([]);
const data = ref([]);

appStore.aggregationStore.runQuery("activityStats?fields[activityStats]=createdOn,instanceCount&filter[activityStats]=createdOn=ge='2023-09-01'", "data").then((result) => {
  if (result && result.length > 0) {
    aggregationResult.value = result[0].forEach((item: any) => {
      xAxis.value.push(item.attributes.createdOn);
      data.value.push(item.attributes.instanceCount);
    });
  }
}).catch((error) => {
  console.log(error);
  log.error("Error running query");
});

const chartOptions = computed(() => {
  return {
    xAxis: {
      type: "category",
      data: xAxis.value,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: data.value,
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
      },
    ],
  };
});
</script>

<template>
  <div>
    <p class="font-xl ml-3 mt-3 font-medium">
      Activity by Day
    </p>
  </div>
  <div class="-mt-5" style="height: 320px">
    <VChart :option="chartOptions" autoresize />
  </div>
</template>

<style scoped>

</style>
