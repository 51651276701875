<script lang="ts" setup>
import KodexaDocumentNavigation from "~/components/document/kodexa-document-navigation.vue";

const props = defineProps({
  viewId: {
    type: String,
    required: false,
  },
});
</script>

<template>
  <div v-if="viewId">
    <div class="flex flex-col">
      <div class="mb-2 flex-1 bg-gray-50 px-2 py-2">
        <KodexaDocumentNavigation
          :view-id="props.viewId"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
