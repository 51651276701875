<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref } from "vue";
import appStore from "~/store";
import router from "~/router/router";

const {
  currentOrganization,
  dashboards,
  creditStatus,
  mutateStatus,
} = storeToRefs(appStore.organizationStore);

const previousCreditStatus = ref(null);
const creditCountStatus = computed(() => {
  if (mutateStatus.value === "pending") {
    return previousCreditStatus.value;
  }
  previousCreditStatus.value = creditStatus.value;
  return creditStatus.value;
});

const amountFormatted = computed(() => {
  const amount = creditCountStatus.value?.amount;
  if (amount !== undefined) {
    return Number(amount).toLocaleString("en-US", { minimumFractionDigits: 1, maximumFractionDigits: 1 });
  }
});

function getStatusClass(creditStatus: any) {
  if (creditStatus.status === "GREEN") {
    return "bg-green-500 text-white";
  } else if (creditStatus.status === "AMBER") {
    return "bg-yellow-500 text-white";
  } else if (creditStatus.status === "RED") {
    return "bg-red-500 text-white";
  }
}

function getStatusLabel(creditStatus: any) {
  if (creditStatus.status === "GREEN") {
    return "Available credits";
  } else if (creditStatus.status === "AMBER") {
    return "Low on credits";
  } else if (creditStatus.status === "RED") {
    return "Out of credits";
  }
}

function gotoOrganization() {
  router.push(`/a/o/${currentOrganization.value.id}/admin?q=manageCredits`);
}

const widget = ref(null);
const { width, height } = useElementSize(widget);
</script>

<template>
  <div ref="widget" class="relative">
    <!-- Added relative positioning here -->
    <div>
      <p class="ml-4 mt-2 text-lg text-gray-600 font-bold">
        Credits Available
      </p>
    </div>
    <div v-if="creditCountStatus" class="ml-3 mt-3 flex items-center">
      <div id="creditAmount" class="text-4xl font-bold text-gray-900">
        {{ amountFormatted }}
      </div>
      <div v-if="width > 200" class="ml-2">
        <span id="creditStatus" class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium" :class="getStatusClass(creditCountStatus)">{{ getStatusLabel(creditCountStatus) }}</span>
      </div>
    </div>

    <div class="ml-1 mt-2 flex">
      <KodexaArticle
        class="school-icon ml-2 mt-2" article-id="9165324" text="Learn more about AI credits"
        :slide="width < 200"
      />
    </div>

    <!-- Button added in the top-right corner -->
    <KodexaButton id="addCreditBtn" class="absolute top-0 right-0" size="small" icon="cash" type="secondary" @click="gotoOrganization">
      Add Credits
    </KodexaButton>
  </div>
</template>

<style scoped>
.school-icon {
  font-size: 12px !important;
  font-weight: normal;
}
</style>
