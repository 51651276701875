<script setup lang="ts">
import { storeToRefs } from "pinia";
import { ref } from "vue";
import type { PropType } from "vue/dist/vue";
import appStore from "~/store";
import type { TagMetadata } from "~/store/useProject";
import type { Card, GetExcelDownloadParams } from "~/model";
import { RefHelper } from "~/utils/ref-utils";
import { getContentObjectContentWithVersion, getExcelDownload } from "~/api/stores/stores";

const props = defineProps({
  card: {
    type: Object as PropType<Card>,
    required: true,
  },
  designMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  viewId: {
    type: String,
    required: true,
  },
});

const { tagMetadataMap, project, dataStores } = storeToRefs(appStore.projectStore);

const dataStore = computed(() => {
  return dataStores.value.find((dataStore: any) => {
    return dataStore.ref === props.card?.properties?.dataStoreRef;
  });
});

const groupTaxons = computed(() => {
  return Array.from(tagMetadataMap.value.values()).filter((tagMetadata: any) => {
    return tagMetadata.taxon.group && tagMetadata.taxonomy.taxonomyType === "CONTENT" && !tagMetadata.taxon.path.includes("/")
  });
});

const currentTaxon = ref<TagMetadata | undefined>(undefined);

watch(groupTaxons, () => {
  if (groupTaxons.value.length > 0) {
    currentTaxon.value = groupTaxons.value[0];
  }
}, { immediate: true, deep: true });
</script>

<template>
  <div>
    <div v-if="dataStore">
      <ul class="-mb-px -mt-2 flex flex-wrap text-center text-sm font-medium text-gray-500 dark:text-gray-400">
        <li
          v-for="groupTaxon in groupTaxons"
          :key="groupTaxon.taxon.id"
          class="mr-2"
          @click="currentTaxon = groupTaxon"
        >
          <a
            class="text-md"
            :class="currentTaxon?.taxon.id === groupTaxon.taxon.id ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
          >
            {{ groupTaxon.label }}
          </a>
        </li>
        <li
          class="mr-2"
          @click="currentTaxon = undefined"
        >
          <a
            class="text-md"
            :class="currentTaxon === undefined ? 'inline-flex items-center justify-center p-4 text-theme-primary border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500 group' : 'inline-flex items-center justify-center p-4 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group'"
          >
            <MaterialDesignIcon name="download" size="20" class="mr-1" />
            Download as Excel
          </a>
        </li>
      </ul>
      <KodexaDataObjectGridContainer
        v-if="currentTaxon"
        :key="currentTaxon?.taxon.id" :view-id="viewId" :data-store="dataStore"
        :group-taxon-metadata="currentTaxon" class="mt-1 px-1"
      />
      <div v-else>
        <KodexaDataStoreDownloadToExcel :data-store="dataStore" :group-taxons="groupTaxons" />
      </div>
    </div>
    <div v-else>
      <div class="mt-10 flex flex-col items-center justify-center">
        <MaterialDesignIcon name="database" size="48" class="text-gray-400" />
        <p class="mt-2 text-sm text-gray-500">
          Select a data store to view the data, it is either
          not set of the store no longer exists.
        </p>
        <p class="mt-2 text-sm text-gray-500">
          {{ props.card?.properties?.dataStoreRef }}
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
