<script lang="ts" setup>
import { storeToRefs } from "pinia";
import appStore from "~/store";
import type { DocumentFamily } from "~/model";

const props = defineProps({
  kioskMode: {
    type: Boolean,
    required: false,
    default: false,
  },
  noClose: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["closed", "saveWorkspace"]);
const {
  views,
  dataObjects,
  currentWorkspaceId,
} = storeToRefs(appStore.workspaceStore);

const sidecarStore = createSidecar(currentWorkspaceId.value);

const {
  sidecarView,
  loading,
  title,
} = storeToRefs(sidecarStore);

const {
  project,
  assistants,
} = storeToRefs(appStore.projectStore);
const { currentSidebar } = storeToRefs(appStore.platformStore);

function closeSidecar() {
  if (sidecarView.value) {
    appStore.workspaceStore.removeViewById(sidecarView.value.id);
  }
  emit("closed");
}

const documentFamily = ref();

function documentFamilyChanged(family: DocumentFamily) {
  documentFamily.value = family;
}

const viewReady = computed(() => {
  return sidecarView.value && sidecarView.value.id && appStore.workspaceStore.getViewById(sidecarView.value.id);
});
</script>

<template>
  <div>
    <div class="flex h-full flex-col bg-white">
      <div class="flex-1">
        <!-- Header -->
        <div class="px-4 py-3 sm:px-6">
          <div class="flex items-start justify-between space-x-3">
            <div>
              <p class="text-md mt-2 flex text-gray-500">
                {{ documentFamily ? documentFamily.path : "" }}
              </p>
            </div>
            <div class="flex h-7 items-center">
              <button v-if="!noClose" class="text-gray-400 hover:text-gray-500" type="button" @click="closeSidecar">
                <span class="sr-only">Close panel</span>
                <MaterialDesignIcon aria-hidden="true" class="size-6" name="close" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div v-if="viewReady && sidecarView" class="p-1">
        <KodexaDocumentView
          :key="sidecarView.id"
          :kiosk-mode="kioskMode"
          :view-id="sidecarView.id" @document-family-changed="documentFamilyChanged"
        />
        <KodexaTagPopup
          v-if="kioskMode"
          :key="sidecarView.id" :active-selection-view="{ viewId: sidecarView.id, sidecar: true }"
          :kiosk-mode="true"
        />
      </div>
      <div v-else>
        <div class="mt-40 flex items-center justify-center" style="height: calc(100vh - 40rem)">
          <div class="text-center">
            <MaterialDesignIcon aria-hidden="true" class="size-12 text-gray-400" name="gesture-tap-button" />
            <h2 class="text-base font-semibold leading-6 text-gray-900">
              Nothing Focused
            </h2>
            <p class="mt-4 px-10 text-sm text-gray-500">
              Select an attribute to view the source document
            </p>
            <KodexaArticle class="mt-8 justify-center" article-id="9034579" text="Learn more about document sidecars" :slide="false" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
