import { defineStore, storeToRefs } from "pinia";
import type { Ref } from "vue";
import type { DataObject, TaxonConditionalFormat, Taxonomy } from "~/model";
import type { FormulaSyntaxError } from "~/formula/formula";
import { KodexaFormulaService } from "~/formula/formula";
import { log } from "~/utils/logger";
import appStore from "~/store/index";

export function createKodexaFormulaService(taxonomy: Ref<Taxonomy>) {
  return defineStore(`formula-service-${taxonomy.value.ref}}`, () => {
    const formulaService = computed(() => {
      return new KodexaFormulaService(taxonomy.value);
    });

    return {
      formulaService,
    };
  })();
}

export function createKodexaFormulaResolver(taxonomy: Ref<Taxonomy>, dataObject: Ref<DataObject>) {
  return defineStore(`formula-store-${taxonomy.value.ref}-${dataObject?.value?.uuid}-${appStore.workspaceStore.currentWorkspace.changeSequence}`, () => {
    const useFormulaService = createKodexaFormulaService(taxonomy);
    const { formulaService } = storeToRefs(useFormulaService);
    const dataObjects = ref([] as DataObject[]);

    const formulaErrors = ref(new Map<string, FormulaSyntaxError[]>());
    const debugInfo = ref(new Map<string, any>());

    function setDataObjects(objects: DataObject[]) {
      dataObjects.value = objects;
    }

    const formulaResult = computed(() => {
      // We want to evaluate all the formulas in this taxonomy
      const finalResult = new Map<string, any>();

      const taxon = formulaService.value.pathToTaxonMap.get(dataObject.value.path);
      log.info(`Evaluating formulas for ${dataObject?.value.path} ${dataObject.value.changeSequence}`);
      if (taxon) {
        taxon.children?.forEach((childTaxon) => {
          if (childTaxon.valuePath === "FORMULA") {
            try {
              const result = formulaService.value.evaluateFormula(childTaxon.semanticDefinition, taxon, dataObject.value, dataObjects.value);
              finalResult.set(childTaxon.name, result?.result);

              if (result?.debugInfo) {
                debugInfo.value.set(childTaxon.name, result.debugInfo);
              } else {
                debugInfo.value.delete(childTaxon.name);
              }

              formulaErrors.value.delete(childTaxon.name);
            } catch (e: any) {
              log.error(`Error evaluating formula for ${childTaxon.path}`, e);
              formulaErrors.value.set(childTaxon.name, e.errors);
            }
          }
        });
      }
      return finalResult;
    });

    const errors = computed(() => {
      const finalErrors = new Map<string, FormulaSyntaxError[]>();
      const taxon = formulaService.value.pathToTaxonMap.get(dataObject?.value.path);
      if (taxon) {
        taxon.children?.forEach((childTaxon) => {
          if (childTaxon.valuePath === "FORMULA") {
            try {
              formulaService.value.parseFormula(childTaxon.semanticDefinition as string);
            } catch (e: any) {
              if (e.errors) {
                finalErrors.set(childTaxon.path as string, e.errors);
              }
            }
          }
        });
      }
      return finalErrors;
    });

    const availableAttributes = computed(() => {
      return formulaService.value.availableAttributes();
    });

    const formatters = computed(() => {
      // Formatters is a map of conditional formats in child taxons
      // that aren't groups, the tag is the key and the value is the list
      // of conditional formats

      const formatters = new Map<string, TaxonConditionalFormat[]>();
      const taxon = formulaService.value.pathToTaxonMap.get(dataObject?.value.path);

      if (taxon) {
        taxon.children?.forEach((childTaxon) => {
          childTaxon.conditionalFormats?.forEach((conditionalFormat) => {
            try {
              const result = formulaService.value.evaluateFormula(conditionalFormat.condition as string, taxon, dataObject?.value, dataObjects.value);
              if (result.result) {
                if (!formatters.has(childTaxon.name)) {
                  formatters.set(childTaxon.name, []);
                }
                formatters.get(childTaxon.name)?.push(conditionalFormat);
              }
            } catch (error) {
              log.error(`Error evaluating conditional format for ${childTaxon.path}`);
            }
          });
        });
      }
      return formatters;
    });

    return {
      formulaResult,
      availableAttributes,
      errors,
      formatters,
      formulaErrors,
      debugInfo,
      setDataObjects,
    };
  })();
}
