<script setup lang="ts">
import type { PropType } from "vue";
import type { DashboardWidget } from "~/model";
import VChart from "vue-echarts";
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart, PieChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
} from 'echarts/components';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  LineChart,
  GridComponent,
  ToolboxComponent,
]);
const props = defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
  refresher: {
    type: Number,
    required: false,
    default: 1,
  },
});

const activeOptions = ref({});

watch(() => props.refresher, (newValue) => {
  if (props.widget?.properties?.chartOptions) {
    try {
      activeOptions.value = JSON.parse(props.widget?.properties?.chartOptions);
    } catch (e) {
      console.log("error", e);
    }
  }
}, {immediate: true});

const widgetStyle = computed(() => {
  if (props.widget?.properties?.style !== undefined) {
    return props.widget?.properties?.style;
  } else {
    const rowSpan = props.widget?.properties?.defaultPosition.rowSpan || 6;
    const height = (rowSpan * 80) - 130;
    return {
      "height": `${height}px`,
      "width": "100%",
    };
  }
});

</script>
<template>
  <div :style="widgetStyle">
    <div class="text-center">
      <h2 class="text-base font-semibold text-gray-900">
        {{ widget?.properties?.title || 'No Title' }}
      </h2>
    </div>
    <VChart v-if="activeOptions" autoresize :option="activeOptions"/>
  </div>
</template>
