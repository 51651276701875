<script setup lang="ts">
const props = defineProps({
  text: {
    type: String,
    required: true,
  },
});

const textWithEllipsis = computed(() => {
  return props.text.length > 50 ? `${props.text.substring(0, 50)}...` : props.text;
});
</script>

<template>
  <div>
    {{ textWithEllipsis }}
  </div>
</template>

<style scoped>

</style>
