import { notify } from "notiwind";
import type { Taxon } from "~/model";

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export function findLastIndex<T>(array: Array<T>, predicate: (value: T, index: number, obj: T[]) => boolean): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) {
      return l;
    }
  }
  return -1;
}

export function toCamelCase(str: string): string {
  if (!str) {
    return "";
  }

  return str
    .replace(/[^A-Za-z0-9 ]+/g, " ") // Remove special characters like comma, dash, etc., and replace them with space
    .replace(/\s+/g, " ") // Replace multiple spaces with a single space
    .trim() // Trim leading and trailing white spaces
    .toLowerCase() // Convert the entire string to lowercase
    .replace(/(?:^\w|[A-Za-z0-9]|\b\w|\s+)/g, (match, index) => {
      // Match various patterns like start of string, any alphabetic character, word boundary or whitespace
      if (+match === 0) {
        return "";
      } // Remove spaces
      return index === 0 ? match.toUpperCase() : match.toLowerCase();
    });
}

export function copyToClipboard(str: string, notification: string) {
  const el = document.createElement("textarea");
  if (document.getSelection() !== null) {
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    const selected
            = document.getSelection().rangeCount > 0
              ? document.getSelection().getRangeAt(0)
              : false;
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }

    if (notification) {
      notify({
        group: "generic",
        title: notification,
      }, 3000);
    }
  }
}

export function getColumnsFromTaxon(groupTaxon: Taxon) {
  let currentSection;
  const columns = [];

  groupTaxon.children?.forEach((taxon) => {

    if (taxon.group) {
      return;
    }

    if (taxon.taxonType === "SECTION") {
      if (currentSection !== undefined) {
        columns.push(currentSection);
      }
      currentSection = {
        title: taxon.label,
        children: [],
      };
      return;
    }

    let filter = "text";

    switch (taxon.taxonType) {
      case "CURRENCY":
      case "NUMBER":
        filter = "numeric";
        break;
      case "DATE":
        filter = "date";
        break;
      case "DATE_TIME":
        filter = "datetime";
        break;
    }

    const newColumn = {
      field: taxon.path,
      title: taxon.label,
      width: taxon.typeFeatures?.longText === true ? 500 : 250,
      resizable: true,
      filter,
      groupable: true,
      sortable: true,
    };

    if (currentSection === undefined) {
      columns.push(newColumn);
    } else {
      currentSection.children.push(newColumn);
    }
  });

  if (currentSection !== undefined) {
    columns.push(currentSection);
  }

  return columns;
}
