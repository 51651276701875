<script setup lang="ts">
const showAddFromMarketplace = ref(false);
function openLibrary() {
  // We need to open the library
  showAddFromMarketplace.value = true;
}
</script>

<template>
  <div class="mx-4">
    <div>
      <KodexaDataFlow @open-library="openLibrary" />
      <ProjectAddResource v-model="showAddFromMarketplace" />
    </div>
  </div>
</template>

<style scoped>

</style>
