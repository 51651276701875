<script setup lang="ts">
import router from "~/router/router";

const stack = ref<Array<any>>([]);

function addPage(route: any, first?: any) {
  stack.value.push({
    name: route.value.path,
    label: route.value.meta?.breadcrumb?.label || route.value.path.replace(".", " "),
    class: `crumbs__item--${typeof first === "undefined" ? "current" : "parent"}`,
  });
  // if (route.value.meta.breadcrumb && route.value.meta.breadcrumb.parent) {
  //   const parent = getRoute(route.value.meta.breadcrumb.parent);
  //   addPage(parent, true);
  // }
}

function buildStack() {
  stack.value = [];
  addPage(router.currentRoute, undefined);
  stack.value = stack.value.reverse();
}

function getRoute(name: string) {
  return router.options.routes.find(route => route.name === name);
}

onMounted(() => {
  buildStack();
});
</script>

<template>
  <nav class="mt-3 flex" aria-label="Breadcrumb">
    <ol v-if="false" role="list" class="flex items-center space-x-1">
      <li>
        <div>
          <a href="#" class="text-gray-400 hover:text-gray-500">
            <MaterialDesignIcon name="home" class="h-5 w-5 shrink-0" aria-hidden="true" />
            <span class="sr-only">Home</span>
          </a>
        </div>
      </li>
      <li v-for="page in stack" :key="page.label">
        <div class="flex items-center">
          <svg
            class="h-5 w-2 shrink-0 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
            viewBox="0 0 20 20" aria-hidden="true"
          >
            <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
          </svg>
          <a
            :href="page.href" class="ml-1 text-sm font-medium text-gray-500 hover:text-gray-700"
            :aria-current="page.current ? 'page' : undefined"
          >{{ page.label }}</a>
        </div>
      </li>
    </ol>
  </nav>
</template>
