<script lang="ts" setup>
import type { PropType } from "vue";
import appStore from "~/store";

const props = defineProps({
  keyName: {
    type: String,
    required: true,
  },
  node: {
    type: Object as PropType<Node>,
  },
});

function openResponse() {
  appStore.workspaceStore.createTextViewer(`${props.node.data.clean.label} Response`, JSON.stringify(props.node.data.raw[props.keyName], null, 2));
}
</script>

<template>
  <KodexaButton name="openPrompt" size="small" style="width: 180px" icon="open-in-new" @click="openResponse">
    View Response
  </KodexaButton>
</template>

<style scoped>

</style>
