<script setup>
import { onMounted, ref } from "vue";
import Tagify from "@yaireo/tagify";
import { getColorByBgColor, randomColor } from "~/utils/colors";

const props = defineProps(["tags", "availableTags", "size"]);

const emits = defineEmits(["addTag", "removeTag"]);

const reactiveTags = ref(props.tags);
const reactiveAvailableTags = ref(props.availableTags);

const inputRef = ref();
const tagify = ref();

const allTags = computed(() => {
  const tags = [];
  for (const tag of reactiveTags.value) {
    const formattedTag = {
      value: tag.name,
      color: tag.color,
    };
    tags.push(formattedTag);
  }
  return tags;
});

function addTag(event) {
  const tag = event.detail.data.value;
  // Determine if it is one of the available tags - if not add it
  const availableTag = reactiveAvailableTags.value.find(availableTag => availableTag.label === tag);
  if (!availableTag) {
    const newTag = {
      name: tag,
      label: tag,
      color: randomColor(),
    };
    emits("addTag", newTag);
    // Temporary add it in the availableTags
  } else {
    emits("addTag", availableTag);
  }
}

const fontSize = computed(() => {
  if (props.size === "small") {
    return "0.4em";
  } else if (props.size === "large") {
    return "1em";
  } else {
    return ".8em";
  }
});

function removeTag(event) {
  const tag = event.detail.data.value;
  emits("removeTag", tag);
}

function transformTags(tagData) {
  // Temporary add a randomColor on it if it doesn't have one
  if (!tagData.color) {
    tagData.color = randomColor();
  }
  // For some reason it doesn't capture the color need to create a filter
  tagData.style = `--tag-bg:${tagData.color}; --tag-text-color:${getColorByBgColor(tagData.color)}; --tag-hover:black;`;
  return tagData;
}

onMounted(() => {
  const whiteListTags = props.availableTags.map((tag) => {
    const newTag = JSON.parse(JSON.stringify(tag));
    newTag.value = tag.label;
    return newTag;
  });

  tagify.value = new Tagify(inputRef.value, {
    delimiters: null,
    whitelist: whiteListTags,
    enforceWhitelist: false,
    editTags: false, // <- do not add any tags (because 'tags' is already populated)
    transformTag: transformTags,
    dropdown: {
      maxItems: 10, // <- mixumum allowed rendered suggestions
      classname: "tags-look", // <- custom classname for this dropdown, so it could be targeted
      enabled: 0, // <- show suggestions on focus
      closeOnSelect: true, // <- do not hide the suggestions dropdown once an item has been selected
    },
    templates: {
      tag(tagData) {
        tagData.style = `--tag-bg:${tagData.color}; --tag-text-color:${getColorByBgColor(tagData.color)};`;
        try {
          return `<div title='${tagData.value}' contenteditable='false' spellcheck="false" class='tagify__tag ${tagData.class ? tagData.class : ""}' ${this.getAttributes(tagData)}>
                        <x title='remove tag' class='tagify__tag__removeBtn' style="color: ${getColorByBgColor(tagData.color)}"></x>
                        <div>
                            <span class='tagify__tag-text'>${tagData.value}</span>
                        </div>
                    </div>`;
        } catch (err) {
        }
      },
    },
  });
  tagify.value.addTags(allTags.value);

  tagify.value.on("add", addTag);
  tagify.value.on("remove", removeTag);
});

watch(allTags, (newValue) => {
  tagify.value.removeAllTags();
  tagify.value.addTags(newValue);
});
</script>

<template>
  <input ref="inputRef" placeholder="Add tags" class="w-full">
</template>

<style scoped>
.tagify {
  border: 0 !important;
  font-size: v-bind(fontSize) !important;
}

.tags-look .tagify__dropdown__item{
  display: inline-block;
  vertical-align: middle;
  border-radius: 0;
  padding: .1em .1em;
  background: #F3F3F3;
  margin: .1em;
  font-size: v-bind(fontSize) !important;
  color: black;
  transition: 0s;
}

.tags-look .tagify__dropdown__item--active{
  color: black;
}

.tags-look .tagify__dropdown__item:hover{
  background: lightyellow;
}

.tags-look .tagify__dropdown__item--hidden {
  max-width: 0;
  max-height: initial;
  padding: .1em 0;
  margin: .0em 0;
  white-space: nowrap;
  text-indent: -20px;
  border: 0;
}

</style>
