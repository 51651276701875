<script setup lang="ts">
import type { PropType } from "vue";
import type { DocumentFamily } from "~/model";

const props = defineProps({
  documentFamily: {
    type: Object as PropType<DocumentFamily>,
    required: true,
  },
  size: {
    type: String,
    default: "default",
  },
});

const recentExecutions = computed(() => {
  if (props.documentFamily.statistics?.recentExecutions) {
    const recentExecutions = [...props.documentFamily.statistics.recentExecutions];
    return recentExecutions.sort((a, b) => {
      return new Date(a.execution.startDate).getTime() - new Date(b.execution.startDate).getTime();
    });
  } else {
    return [];
  }
});
</script>

<template>
  <div v-if="documentFamily" class="isolate flex -space-x-1 overflow-hidden">
    <KodexaDocumentRecentActivity :document-family="documentFamily" size="small" class="mr-1" />
    <VMenu v-if="documentFamily.numberOfContentExceptions > 0" :popperTriggers="['hover']">
    <MaterialDesignIcon

       size="18"
      class="mr-3 inline-flex text-red-500"
      name="exceptions"
    />
      <template #popper>
        <div class="bg-white shadow-lg rounded-lg p-4" style="width: 300px; height: 300px">
          <div v-for="(contentException, idx) in documentFamily.contentExceptions" :key="idx" class="mb-2">
            <div class="flex items-center text-sm text-gray-500">
              <!-- Icon Container -->
              <div class="mr-4">
                <MaterialDesignIcon v-if="contentException.severity === 'ERROR'" name="alert-box-outline" class="text-red-600"></MaterialDesignIcon>
                <MaterialDesignIcon v-else-if="contentException.severity === 'WARNING'" name="alert-rhombus-outline" class="text-yellow-600"></MaterialDesignIcon>
              </div>

              <!-- Title and Message Container -->
              <div>
                <!-- Exception Type as Title -->
                <div class="text-sm font-medium">{{ contentException.exceptionType }}</div>

                <!-- Message -->
                <div class="text-xs">{{ contentException.message }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </VMenu>
    <MaterialDesignIcon
      v-if="documentFamily.numberOfInsights > 0"
      v-tooltip="`${documentFamily.numberOfInsights} insights available`" size="18"
      class="inline-flex text-blue-500"
      style="margin-right: 0.55rem;"
      name="insights"
    />


  </div>
</template>
