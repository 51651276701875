<script lang="ts" setup>
import type { Ref } from "vue";
import { ref } from "vue";
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { notify } from "notiwind";
import { storeToRefs } from "pinia";
import { getTerms } from "~/api/platform-overview/platform-overview";
import type { TermsProperties } from "~/model";
import { acceptTerms } from "~/api/account/account";
import appStore from "~/store";

const emit = defineEmits(["redirectHomePage"]);

const { user, platformAdmin } = storeToRefs(appStore.userStore);

const termsProperties: Ref<TermsProperties | undefined> = ref(undefined);
const open = ref(true);
const acceptedClick = ref(false);
const loading = ref(false);

onMounted(() => {
  setTermsProperties();
});

async function setTermsProperties() {
  termsProperties.value = await getTerms();
}

async function accept() {
  loading.value = true;
  if (!termsProperties.value) {
    return;
  }

  await acceptTerms(termsProperties.value);
  appStore.userStore.updateHasAcceptedTerms();
  loading.value = false;
  open.value = false;

  notify({
    group: "generic",
    title: "Terms and Conditions",
    text: "You have accepted the terms and conditions.",
  }, 3000);
  emit("redirectHomePage");
}

</script>

<template>
  <TransitionRoot v-if="termsProperties" as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500/75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              v-if="termsProperties"
              style="width: 40%; height: auto"
              class="relative mt-8 size-full overflow-hidden overflow-y-scroll rounded-lg bg-white text-left shadow-lg transition-all sm:mt-0 sm:size-3/4"
            >
              <KodexaHeader
                :title="termsProperties.title"
                class="ml-2"
              >
                <template #subTitle>
                  {{ termsProperties.subTitle }}
                </template>
              </KodexaHeader>

              <div class="scrollable-container overflow-y-auto p-2 px-4">
                <KodexaMarkdown :content="termsProperties.markdown" />
              </div>

              <div class="mt-4 flex px-4">
                <KodexaCheckbox
                  v-model="acceptedClick"
                  name="acceptedClick"
                  class="mr-2"
                  :label="termsProperties.acceptanceText"
                />
              </div>

              <div class="mt-2 flex justify-end p-4">
                <KodexaButton icon="check" :disabled="!acceptedClick" :loading="loading" class="px-4 py-2" @click="accept">
                  Submit
                </KodexaButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot >
</template>

<style scoped>
.scrollable-container {
  max-height: 300px; /* Adjust based on your needs */
  overflow-y: auto; /* Enables vertical scrolling */
  position: relative;
  margin-top: 5px;
}

.scrollable-container::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px; /* Height of the fade effect */
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)); /* Adjust color based on your container's background */
}
</style>
