<script lang="ts" setup>
import type { PropType } from "vue";
import type { Project, User } from "~/model";
import KodexaProjectOwnerDropdown from "~/components/projects/kodexa-project-owner-dropdown.vue";
import { updateHandler } from "~/utils/error-handler";
import { removeOwner, updateOwner } from "~/api/projects/projects";

const props = defineProps({
  project: {
    type: Object as PropType<Project>,
    required: true,
  },
  saveValue: {
    type: Boolean,
    required: false,
    default: true,
  },
});

const emit = defineEmits(["updated"]);

const selectedMembership = ref<User | undefined>(props.project?.owner);
const editing = ref(false);

watch(() => props.project?.owner, (newValue) => {
  selectedMembership.value = newValue;
}, { immediate: true });

async function saveChanges() {
  await updateHandler(updateOwner(props.project.id as string, selectedMembership.value?.user as User), "Owner updated");
}
async function updated(member) {
  selectedMembership.value = member;
  if (props.saveValue) {
    await saveChanges();
  }
  editing.value = false;
  emit("updated", selectedMembership.value);
}

async function clear() {
  selectedMembership.value = undefined;
  editing.value = false;
  if (props.saveValue) {
    await updateHandler(removeOwner(props.project.id as string), "Owner cleared");
  }
  emit("updated", undefined);
}
</script>

<template>
  <div v-if="editing">
    <KodexaProjectOwnerDropdown :key="project.id" v-model="selectedMembership" :project="project" @clear="clear" @updated="updated" />
  </div>
  <div v-else-if="project.owner">
    <KodexaUserAvatar id="currentProjectOwner" :user="selectedMembership" :show-name="true" style="height: 87px; display: flex; align-items: center;" @click="editing = true" />
  </div>
  <div v-else id="editProjectOwner" style="height: 87px; display: flex; align-items: center;" @click="editing = true">
    <span>-</span>
  </div>
</template>

<style scoped>

</style>
