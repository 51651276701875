/**
 * Generated by orval v6.23.0 🍺
 * Do not edit manually.
 * Kodexa AI
 * Document AI Platform
 * OpenAPI spec version: ${git.build.version}
 */

export type ExecutionStatus = typeof ExecutionStatus[keyof typeof ExecutionStatus];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExecutionStatus = {
  PENDING: 'PENDING',
  RUNNING: 'RUNNING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  REQUESTED: 'REQUESTED',
  SKIPPED: 'SKIPPED',
  CANCELLING: 'CANCELLING',
  CANCELLED: 'CANCELLED',
  PENDING_REPROCESSING: 'PENDING_REPROCESSING',
} as const;
