<script setup lang="ts">
const props = defineProps({
  articleId: {
    type: [String, Number],
    required: true,
  },
  text: {
    type: String,
    required: true,
  },
  slide: {
    type: Boolean,
    required: false,
    default: false,
  },
  iconColor: {
    type: String,
    required: false,
    default: "#0f4c8c",
  },
  size: {
    type: String,
    required: false,
    default: "24",
  },
});

function openArticle() {
  useIntercom().showArticle(`${props.articleId}`);
}
</script>

<template>
  <div v-if="slide" class="article-container my-1 flex text-sm" @click="openArticle">
    <MaterialDesignIcon name="school" class="article-icon mr-2" :size="size" :style="{'color':iconColor}" />
    <div class="article-text">
      {{ text }}
    </div>
  </div>
  <div v-else class="my-1 flex text-sm" @click="openArticle">
    <MaterialDesignIcon name="school" class="mr-2" :size="size" :style="{'color':iconColor}"/>
    <div>
      {{ text }}
    </div>
  </div>
</template>

<style scoped>
.article-container {
  display: inline-flex;
  align-items: center;
  overflow: hidden; /* Prevents text from being visible outside its container */
}

.article-icon {
  cursor: pointer;
}

.article-text {
  transform: translateX(-100%);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.article-container:hover .article-text {
  transform: translateX(0%);
  opacity: 100;
}
</style>
