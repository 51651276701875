<script setup lang="ts">
import type { PropType, Ref } from "vue";
import { storeToRefs } from "pinia";
import appStore from "~/store";
import type { DashboardWidget, PlatformStatistics } from "~/model";
import { getPlatformStatistics } from "~/api/platform-overview/platform-overview";

defineProps({
  widget: {
    type: Object as PropType<DashboardWidget>,
    required: false,
  },
});

const platformStats: Ref<PlatformStatistics | null> = ref(null);
const { currentOrganization } = storeToRefs(appStore.organizationStore);
const { user } = storeToRefs(appStore.userStore);

watch([currentOrganization, user], () => {
  if (!user) {
    return;
  }

  if (!currentOrganization.value) {
    getPlatformStatistics().then((response) => {
      platformStats.value = response;
    });
  } else {
    getPlatformStatistics({ organizationId: currentOrganization.value.id }).then((response) => {
      platformStats.value = response;
    });
  }
}, {
  immediate: true,
  deep: true,
});
</script>

<template>
  <div>
    <div>
      <p class="ml-4 mt-2 text-lg text-gray-600 font-bold">
        {{ currentOrganization ? currentOrganization.name : '' }} Overview
        <span v-if="currentOrganization" class="ml-4 text-lg tracking-tight text-gray-500 font-light">
          // {{ currentOrganization.enterpriseOrganization ? 'Enterprise Account' : 'Cloud Account' }}
        </span>
      </p>
    </div>
    <div class="ml-4 mt-4 flex items-baseline">
      <div class="m-1 text-4xl font-bold tracking-tight text-gray-900">
        {{ platformStats?.totalProjects?.toLocaleString() ?? '-' }}
      </div>
      <div class="text-2xlg m-1 mr-4 font-light text-gray-600">
        PROJECTS
      </div>
      <div class="m-1  text-4xl font-bold tracking-tight text-gray-900">
        {{ platformStats?.totalDocuments?.toLocaleString() ?? '-' }}
      </div>
      <div class="text-2xlg m-1 mr-4  font-light text-gray-600">
        DOCUMENTS
      </div>
      <div class="m-1 text-4xl font-bold tracking-tight text-gray-900">
        <KodexaFileSizeView :size="platformStats?.totalSize" />
      </div>
      <div class="text-2xlg m-1 font-light text-gray-600">
        STORAGE
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>
